import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BussionMainService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
  ) { }


  getBussionMain(fileType:string) {
    let param = {
      Token: this.authService.getToken(),
      FileType: fileType
    };
    return this.http.post(Endpoints.getBussionMain, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }

  setBussionMain(Content: String, fileType: string) {
    let param = {
      Token: this.authService.getToken(),
      Content: Content,
      FileType: fileType
    };
    return this.http.post(Endpoints.setBussionMain, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }

  getBussionLogin(fileType: string) {
    return this.http.post(Endpoints.getBussionLogin, { FileType: fileType }).pipe(
      map((res: any) => {
        return res.message;
      })
    )
  }

  setBussionLogin(Content: string, fileType: string) {
    let param = {
      Token: this.authService.getToken(),
      Content: Content,
      FileType: fileType
    };
    return this.http.post(Endpoints.setBussionLogin, param).pipe(
      map((res: any) => {
        return res.message;
      })
    )
  }


}
