<div class="widget-container" *ngIf="selectedDashboard?.hideBorders=='true'">
    <div class="widget-header" style="padding:0;padding-right: 0.5rem;">

        <div class="widget-action" *ngIf="showMenu" style="display: flex;justify-content: flex-end;">
            <mat-icon matListIcon [matMenuTriggerFor]="optionList">more_horiz</mat-icon>

        </div>

    </div>
    <div class="widget-content" style="height: 100%;" id="{{widget.designerContainer.widgetId}}"></div>
</div>


<div *ngIf="selectedDashboard?.hideBorders=='false'" class="widget-container"
    [ngClass]="{'no-subtitle':widget.designerContainer.widget.subTitle==''}" #widgetContent>
    <div class="widget-header"
        [ngStyle]="{'padding':widget.designerContainer.widget.type.toLowerCase().includes('filter')?'10px 10px':''}">
        <div *ngIf="showTitle" class="title"
            [ngStyle]="{'justify-content':widget.designerContainer.widget.type.toLowerCase().includes('filter')?'flex-end':''}">
            <span
                *ngIf="!widget.designerContainer.widget.type.toLowerCase().includes('filter')">{{widget.designerContainer.widget.title}}</span>
            <div class="widget-action" *ngIf="showMenu">
                <mat-icon style="transform: scale(0.8);" *ngIf="widget?.designerContainer?.widget?.drillDownLink"
                    [svgIcon]="'eye-svgrepo-com'" [matTooltip]="'WidgetFormPage.Detail'|translate"></mat-icon>
                <mat-icon matListIcon [matMenuTriggerFor]="optionList"
                    (click)="groupedDataKeys.length>0?DownloadFilter({checked:true}):''">{{widget.designerContainer.widget.type.toLowerCase().includes('filter')?'more_horiz':'more_vert'}}</mat-icon>
            </div>

        </div>
        <div class="sub-title"
            *ngIf="widget.designerContainer.widget.subTitle.length != 0&&!widget.designerContainer.widget.type.toLowerCase().includes('filter')">
            {{widget.designerContainer.widget.subTitle}}</div>

        <div class="sub-title filter-area" *ngIf="groupedDataKeys.length>0&&showFilter">
            <mat-icon [svgIcon]="'filter'"></mat-icon>
            <div class="filter-container" *ngFor="let item of getObjectKeys(groupedData)">
                <span class="filternamespan">
                    {{item}}</span>
                <div style="display: flex;" *ngFor="let op of getObjectKeys(groupedData[item])">
                    <span style="font-weight: bold;margin-right: 5px;">{{("FilterComponent."+op|translate)}}</span>
                    <div *ngFor="let d of groupedData[item][op];let i = index">
                        {{d.fieldValue}}<span>,</span>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="widget-content" id="{{widget.designerContainer.widgetId}}">
        <div *ngIf="widget.designerContainer.widgetData.length<=0 && widget.designerContainer.widget.widgetId"
            class="no-data-content">
            <img src="/assets/images/no-data.png" />
            <span>{{"MainPage.NoData"|translate}}</span>
        </div>
    </div>


</div>

<div id="download">
    <img #canvas>
    <a #downloadLink></a>
</div>


<mat-menu class="context-menu-elements" #optionList="matMenu"
    (closed)="groupedDataKeys.length>0?DownloadFilter({checked:false}):''">
    <span mat-menu-item
        *ngIf="showShare && (permissions.includes('WidgetShare')||permissions.includes('DashboardShare'))"
        (click)="shareWidget(widget.designerContainer.widgetId, 'Widget')">
        <mat-icon matListIcon svgIcon="share"></mat-icon> {{ 'WidgetButtons.Share' | translate }}
    </span>
    <span mat-menu-item *ngIf="showZoom" (click)="expandWidget(widget)">
        <mat-icon matListIcon svgIcon="zoom1"></mat-icon> {{ 'WidgetButtons.Zoom' | translate }}
    </span>
    <span mat-menu-item *ngIf="showEdit" (click)="openWidgetForm(widget.designerContainer.widget, 'update')">
        <mat-icon matListIcon svgIcon="edit1"></mat-icon> {{ 'WidgetButtons.Edit' | translate }}
    </span>
    <div mat-menu-item [matMenuTriggerFor]="downloadOption"
        style="display: flex;align-items: center;">

        <mat-icon matListIcon svgIcon="download1"></mat-icon>
        <div style="display: flex;flex-direction: column;line-height: 0.2;">
            <span> {{ 'WidgetButtons.Download' | translate }}</span>
        </div>

    </div>
    <span mat-menu-item *ngIf="showDelete" (click)="removeWidget(widget)">
        <mat-icon matListIcon svgIcon="delete-box"></mat-icon> {{ 'WidgetButtons.Delete' | translate }}
    </span>
</mat-menu>


<mat-menu class="context-menu-elements download-options" #downloadOption="matMenu">
   
    <button mat-menu-item (click)="downloadWidget(widget,'csv')">
        <mat-icon matListIcon svgIcon="csv"></mat-icon>
        CSV
    </button>
    <button mat-menu-item (click)="downloadWidget(widget,'png')">
        <mat-icon matListIcon svgIcon="image"></mat-icon>
        Image
        <mat-checkbox *ngIf="groupedDataKeys.length>0" (change)="DownloadFilter($event)" style="font-size: smaller;"
            class="example-margin downloadfiltercheckbox" (click)="$event.stopPropagation()" [ngModel]="showFilter">{{
            'WidgetButtons.DownloadWithFilter' | translate }}</mat-checkbox>
    </button>
</mat-menu>