<div class="flow-rule-modal-container">
    <div class="title-area">
        <span>{{'FlowPage.AddParameter'|translate}}</span>
        <mat-icon (click)="closeDialog()">clear</mat-icon>
    </div>
    <div class="body">
        <form [formGroup]="parameterFormGroup">
            <div style="display: flex;align-items: center;" *ngFor="let item of this.data.parameters">
                <mat-form-field>
                    <mat-label>{{ item.key}}</mat-label>
                    <div *ngIf="Idkeys.includes(item.key)">
                        <mat-select [formControlName]="item.key">
                            <mat-option *ngFor="let opt of options[item.key]" [value]="opt.value">
                                {{opt.key}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div *ngIf="!Idkeys.includes(item.key)"
                        style="display: flex;justify-content: space-between;align-items: center;">
                        <input [type]="item.showForForm ?item.typeForForm:'password'" matInput
                            [formControlName]="item.key">
                        <mat-icon *ngIf="!item.showForForm" [svgIcon]="'eye-svgrepo-com'"
                            (click)="item.showForForm=!item.showForForm"></mat-icon>
                    </div>
                    <mat-hint *ngIf="item.description">*{{item.description}}</mat-hint>
                </mat-form-field>
                <mat-icon (click)="openModal(item.key,item.value)"
                    *ngIf="(item.key=='ConnectorId'&&permissions.includes('BussionConnectorManagement'))||(item.key=='DataStoreId'&&permissions.includes('DataStoreManagement'))||(item.key=='NodeId'&&permissions.includes('NodeManagement'))"
                    [svgIcon]="'edit-box'"></mat-icon>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div style="display: flex;justify-content: end;">
            <button mat-dialog-close mat-stroked-button class="custom-primary-stroked-button mr-10">
                {{ 'Common.Cancel' | translate }}
            </button>
            <button (click)="saveParameters()" mat-raised-button class="custom-primary-button">
                {{ 'Common.Save' | translate }}
            </button>
        </div>
    </div>

</div>