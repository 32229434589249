<h4 mat-dialog-title style="margin:7%"> Please select column type</h4>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Column Type</mat-label>
        <mat-select [(ngModel)]="selectedType" name="type">
            <mat-option *ngFor="let type of data.types" [value]="type">{{ type }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions style="    display: flex;
flex-direction: row-reverse;
">
    <button mat-button [mat-dialog-close]="selectedType" cdkFocusInitial>Save</button>
    <button mat-button (click)="onCancelClick()">Cancel</button>
</div>