<div class="data-table-content">
    <div class="header">
        <div class="title">
            <span>{{title}}</span>
        </div>

        <div *ngIf="buttonActive" class="action-button-group">
            <button style="margin-right: 0rem;" *ngIf="exportLogButton" class="button add-button" mat-button
                (click)="export(null)">
                {{addButtonText}}
                <mat-icon svgIcon="export"></mat-icon>
            </button>
            <button (click)="secondButton()" *ngIf="secondButtonText" class="button add-button" mat-button>
                <mat-icon style="transform: scale(0.7);" [svgIcon]="'flow'"></mat-icon>
                {{secondButtonText}}
            </button>
            <button class="button add-button" mat-button (click)="open(null, 'create')">
                {{addButtonText}}
                <mat-icon [svgIcon]="butonIcon"></mat-icon>
            </button>

        </div>
    </div>

    <div class="search-content">
        <input (keyup)="applyFilter($event)" placeholder="{{filterPlaceholder}}">
    </div>
    <div *ngIf="listTypeButton" style="display: flex;align-items: center;justify-content: end;margin-bottom: 10px;">
        <mat-button-toggle-group [(ngModel)]="listStyleButton" (ngModelChange)="changeListStyle()">
            <mat-button-toggle value="list"><mat-icon svgIcon="list"></mat-icon></mat-button-toggle>
            <mat-button-toggle value="folder"><mat-icon svgIcon="folder"></mat-icon></mat-button-toggle>
        </mat-button-toggle-group>

    </div>
    <div class="table-content">
        <div>
            <mat-accordion class="expand-table">
                <mat-expansion-panel *ngFor="let item of _treeData" (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{item.name}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <table mat-table [dataSource]="item.children" matSort>
                            <ng-container *ngFor="let item of displayedColumns; let i = index" matColumnDef="{{item}}"
                                ngSwitch>
                                <div class="avatar" *ngIf="item == 'avatar' || item == 'icon'">
                                    <th  mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <div *ngIf="item=='avatar'">Avatar</div>
                                        <div *ngIf="item=='icon'">İcon</div>
                                    </th>
                                    <td mat-cell *matCellDef="let row" (click)="!showDesigner?open(row, 'update'):''">
                                        <div class="avatar" (click)="showDesigner?open(row, 'update'):''">
                                            <img [style.width]="imageWidth" [style.height]="imageHeight"
                                                *ngIf="item=='avatar'" class="avatar" src="{{baseUrl}}{{row[item]}}" />
                                            <img [style.width]="imageWidth" [style.height]="imageHeight"
                                                *ngIf="item=='icon'" class="avatar" src="{{baseUrl}}{{row[item]}}"
                                                onError="this.src ='/assets/images/icons/errorImage.jpeg'" />
                                        </div>
                                    </td>
                                </div>

                                <div class="custom"
                                    *ngIf="item != 'avatar' && item != 'action'&& item != 'errorIcon'&&item!='palettes'">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        [style.Width]="columnWidth.length>0?columnWidth[i]:''">
                                        {{displayedColumnsName[i]}} </th>
                                    <td mat-cell *matCellDef="let row"
                                        (click)="showDesigner ? openDashboard(row) : open(row, 'update')">
                                        {{row[item]}}
                                    </td>
                                </div>

                                <div class="custom" *ngIf="item =='palettes'">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        [style.Width]="columnWidth.length>0?columnWidth[i]:''">
                                        {{displayedColumnsName[i]}} </th>
                                    <td mat-cell *matCellDef="let row"
                                        (click)="showDesigner ? openDashboard(row) : open(row, 'update')">
                                        <div style="display: flex;">
                                            <div *ngFor="let color of row[item]" class="color-palette-prev">
                                                <div [style.BackgroundColor]="color"></div>
                                            </div>
                                        </div>
                                    </td>
                                </div>

                                <div class="custom" *ngIf="item == 'errorIcon'">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{displayedColumnsName[i]}}
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <mat-icon
                                            *ngIf="(+row['disableThreshold'])>0&& (+row['errorCount'])>=(+row['disableThreshold'])">error_outline</mat-icon>
                                    <td>
                                </div>

                                <div class="action" *ngIf="item == 'action'">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                                    <td mat-cell *matCellDef="let row">
                                        <mat-icon style="margin-right: 0.5rem;" matListIcon *ngIf="showDesigner"
                                            color="primary" (click)="export(row)">backup</mat-icon>
                                        <!-- <mat-icon matListIcon *ngIf="showDesigner" color="primary" (click)="export(row)" svgIcon="download"></mat-icon> -->

                                        <mat-icon matListIcon *ngIf="showDesigner" color="primary"
                                            (click)="open(row,'update')" svgIcon="edit"></mat-icon>

                                        <!-- <mat-icon matListIcon color="primary" *ngIf="showDesigner"
                                            [routerLink]="['designer', row.dashboardId]" [state]=row>
                                            table_chart</mat-icon> -->
                                        <mat-icon matListIcon color="primary" *ngIf="showRunTask" (click)="runTask(row)"
                                            title="Run Task">ondemand_video</mat-icon>
                                        <mat-icon matListIcon color="warning" *ngIf="showStopTask" title="Stop Task"
                                            (click)="stopTask(row)">stop</mat-icon>

                                        <mat-icon style="margin-left: 1rem;" matListIcon color="warn"
                                            (click)="delete(row)" svgIcon="delete"></mat-icon>
                                    </td>
                                </div>


                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                            </tr>
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>


        <!-- <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator> -->
    </div>
</div>