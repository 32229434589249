<div class="toolbar" role="banner">
    <div style="display: flex;align-items: center;width: 30%;">
        <a (click)="redirectHomePage()">
            <img src="/assets/images/bussionmini.png" *ngIf="!closeButton" />
        </a>
        <div class="title-box" [style]="closeButton?'margin-left:10px ;':''">
            <span [ngClass]="{'font-md': isMobile}" matTooltip="{{selectedDashboard.title}}"> {{
                selectedDashboard.title}}</span>
        </div>
    </div>

    <div style="display: flex; align-items: center;justify-content: end;width: 70%;">
        <div style="width: 90%;" class="dashboard-button-groups-container">
            <div class="dashboard-button-groups"
                [ngStyle]="{ 'width': '100%', 'justify-content': disableFilter ? 'end' : '' }">

                <div [matTooltip]="'HomePage.MyFavorites'|translate" class="dashboard-title" *ngIf="!disableDashboardList"
                    [matMenuTriggerFor]="dashboardListMenu">
                    <span>
                        <mat-icon svgIcon="favorite"></mat-icon>
                    </span>
                    <mat-icon>
                        keyboard_arrow_down
                    </mat-icon>

                </div>
                <div class="aifilter-container" *ngIf="!disableFilter">
                    <Input [value]="inputvalue" type="text"
                        placeholder="{{'FilterComponent.inputPlaceholder'|translate}}"
                        (keyup.enter)="getFilteredDashboard($event)" class="aifilter" #aifilterinput />

                    <!-- <div class="mic-container">
                        <app-speech-microphone *ngIf="micenable" [functionList]="functionList"></app-speech-microphone>
                    </div> -->
                    <mat-icon (click)="getFilteredDashboard({target:{value:aifilterinput.value}})">search</mat-icon>
                </div>



                <button *ngIf="!selectedDashboard.hideAdvancedQueryPanel&&!disableFilter" class="button" mat-button
                    (click)="openDashboardFilter()">
                    {{ 'MainPage.FiltersButton' | translate }}
                    <mat-icon svgIcon="filter"></mat-icon>
                </button>
                <!-- <div *ngIf="!disableViews" style="display: flex;flex-direction:column">
                    <button [matMenuTriggerFor]="viewsListMenu" class="button dropdown-button" mat-button>

                        {{ 'MainPage.ViewsButton' | translate }}
                        <mat-icon svgIcon="eye-svgrepo-com"></mat-icon>

                    </button>
                </div> -->
                <app-view-button
                    *ngIf="!disableViews&& (permissions.includes('WidgetShare')||permissions.includes('DashboardShare'))"
                    [changeViewList]="changeViewList" [selectedDashboard]="selectedDashboard"
                    (applyFilterForViews)="applyFilterForViews($event)"></app-view-button>


                <button [matTooltip]="'HeaderComponent.ScaleScreen'|translate" class="button" mat-button
                    *ngIf="!disableFit" [matMenuTriggerFor]="gridType">
                    <mat-icon svgIcon="scale_screen"></mat-icon>
                </button>
                <button [matTooltip]="'MainPage.MessageButton'|translate" class="button message-btn"
                    *ngIf="!disableMessages && permissions.includes('DashboardMessage')" (click)="openSideNav()">
                    <mat-icon svgIcon='message'></mat-icon>
                </button>
                <button *ngIf="!disableDownload" [matMenuTriggerFor]="exportListMenu" class="button">
                    <mat-icon svgIcon='download'></mat-icon>
                </button>

            </div>
        </div>

        <div class="action-groups"
            [ngStyle]="{ 'width': disableAvatar ? '' : '30%' ,'margin-left':disableAvatar ? '7px' : 'auto' }">
            <div class="user-info" *ngIf="!disableAvatar" [matMenuTriggerFor]="usermanage">
                <span style="width: 100%;overflow: hidden;text-overflow: ellipsis;white-space: no-wrap;">{{
                    'HeaderComponent.Hello' | translate }}, {{firstName}} {{lastName}}</span>
                <img src="{{avatar}}" />
            </div>
            <ng-content select="[close-button]"></ng-content>
        </div>

    </div>
</div>
<div class="mobile-ai" *ngIf="isShownFilterAI">
    <div class="aifilter-container">
        <Input [(ngModel)]="inputvalue" [value]="inputvalue" type="text"
            placeholder="{{'FilterComponent.inputPlaceholder'|translate}}" (keyup.enter)="getFilteredDashboard($event)"
            class="aifilter" #aifilterinput />

        <!-- <div class="mic-container">
            <app-speech-microphone *ngIf="micenable" [functionList]="functionList"></app-speech-microphone>
        </div> -->
        <mat-icon (click)="getFilteredDashboard({target:{value:aifilterinput.value}})">search</mat-icon>
    </div>
</div>
<div class="selected-filters" *ngIf="selectedFilterGroup.length>0&&!disableFilter">


    <div style="display: flex;margin-left: 15px;">
        <div *ngFor="let item of selectedFilterGroup">
            <app-filter-badge [selectedFilters]="selectedFilters"
                (clearSelectedFilterGroup)="clearSelectedFilterGroupFunc($event)"
                (applyDropdownFilter)="applyDropdownFilterFunc($event)" [selectedDashboard]="selectedDashboard"
                [selectedFilter]="groupedData[item]"></app-filter-badge>
        </div>
    </div>
    <div (click)="resetFilter()" class="clear-icon" *ngIf="selectedFilterGroup.length>0"
        style="margin-right: 10px;margin-left: 20px;">
        <mat-icon [matTooltip]="'FilterComponent.Reset'|translate" svgIcon="clear_all" style="width: 20px;"></mat-icon>
    </div>
</div>

<mat-menu #usermanage="matMenu">
    <span mat-menu-item (click)="showUserInfo()">{{ 'HeaderComponent.UpdateInfo' | translate }}</span>
    <span mat-menu-item *ngIf="roles.includes('Admin')" (click)="goManage()">{{ 'HeaderComponent.Manage' | translate
        }}</span>
    <span mat-menu-item (click)="logout()">{{ 'HeaderComponent.Logout' | translate }}</span>
</mat-menu>

<mat-menu #gridType="matMenu">
    <span mat-menu-item [ngClass]="{'selectedmenuitem': this.selectedDashboard?.layout=='Fit'}"
        (click)="changeDashboardLayoutFunc('Fit')">{{ 'MainPage.FitToScreen' | translate
        }}</span>
    <span mat-menu-item [ngClass]="{'selectedmenuitem': this.selectedDashboard?.layout=='ScrollVertical'}"
        (click)="changeDashboardLayoutFunc('ScrollVertical')">{{ 'MainPage.ScrollVertical' |
        translate }}</span>
    <span mat-menu-item (click)="showFullScreen(false)">{{ 'MainPage.FullScreen' | translate }}</span>
</mat-menu>

<mat-menu #dashboardListMenu="matMenu">
    <div [matTooltip]="'MainPage.SelectDashboard'|translate" (click)="addActiveDashboard()"
        style="display: flex;align-items: center;justify-content: center;"><mat-icon
            style="width:20px;margin-right: 5px;" svgIcon="add_dashboard"></mat-icon></div>
    <span mat-menu-item *ngFor="let dashboard of dashboardList" matTooltip="{{dashboard.title}}"
        (click)="dashboardSelected(dashboard);">{{dashboard.title}}</span>
</mat-menu>

<mat-menu #exportListMenu="matMenu">
    <span style="display: flex;align-items: center;gap: 1;" mat-menu-item (click)="downloadDashboard('pdf')">
        <img width="20" src="/assets/images/pdf.png" />
        pdf
    </span>
    <span style="display: flex;align-items: center;gap: 1;" mat-menu-item (click)="downloadDashboard('pptx')">
        <img width="20" src="/assets/images/pptx.png" />
        pptx
    </span>

</mat-menu>

<!-- <mat-menu #exportListMenu="matMenu" class="views-menu">
    <div *ngIf="_mySharesForList.length<=0"
        style="width: 100%;display:flex;align-items:center;justify-content: center;">
        <div class="media-heading">{{'ShareComponent.noShares'|translate}}</div>
    </div>
    <app-list-item-with-icon (itemOnclickEvent)="applyFilterForViews($event)" [buttonsList]="buttonsList"
        *ngFor="let share of _mySharesForList" [item]="share" [class]="'inside-comp'"></app-list-item-with-icon>
</mat-menu> -->