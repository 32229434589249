import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CodeEditorComponent } from 'src/app/components/code-editor/code-editor.component';
import { FlowScriptRequest, FlowScriptResponse, FlowSriptParameter } from 'src/app/models/responses/flowScript';
import { CoreService } from 'src/app/services/core/core.service';
import { FlowScriptService } from 'src/app/services/flow-scripts/flow-script.service';

@Component({
  selector: 'app-script-form',
  templateUrl: './script-form.component.html',
  styleUrls: ['./script-form.component.scss']
})
export class ScriptFormComponent implements OnInit {

  editorOptions = { theme: 'vs-dark', language: 'python' };
  selectedTabIndex: number = 0
  showConsole: boolean = false


  scriptForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    package: new FormControl('', [Validators.required]),
    timeout: new FormControl(0, [Validators.required]),
    pythonExecuteable: new FormControl('', [Validators.required]),
    stopIfFailed: new FormControl(false, [Validators.required])
  });

  parameterForm = new FormGroup({
    key: new FormControl('', [Validators.required]),
    value: new FormControl(''),
    description: new FormControl(''),
    type: new FormControl('', [Validators.required]),
    shown: new FormControl(false),
    mandatory: new FormControl(false)
  });

  scriptObject: FlowScriptRequest = {
    scriptId: '',
    icon: '',
    name: '',
    description: '',
    package: '',
    code: '',
    parameters: [],
    pythonExecuteable: '',
    stopIfFailed: 0,
    timeout: 0
  }
  parameterList: FlowSriptParameter[] = []
  pythonPackages: string[] = []
  pythonPackagesfilteredOptions: Observable<string[]>;

  visibleParameterForm: boolean = false
  parameterIsUpdate: boolean = false

  options: string[] = ['ConnectorId', 'NodeId', 'DataStoreId'];
  filteredOptions: Observable<string[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { script: FlowScriptResponse, type: string, deleteFunction: Function },
    private flowScriptService: FlowScriptService,
    private coreService: CoreService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ScriptFormComponent>) { }

  ngOnInit(): void {

    if (this.data) {
      Object.assign(this.scriptObject, this.data.script)
      if (this.data.script) {
        this.editScript(this.data.script)

        if (this.data.script.parameters) {
          this.parameterList = this.data.script.parameters
        }
      }

    }

    this.getPythonScriptTemplate()

    this.createFilteredOptions()
    this.getPythonPackages()

  }

  getPythonPackages() {
    this.flowScriptService.GetPythonPackages().subscribe(res => {
      this.pythonPackages = res

      this.pythonPackagesfilteredOptions = this.scriptForm.get('package').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '', this.pythonPackages)),
      );
    })
  }

  getPythonScriptTemplate() {
    if (!this.data.script && this.flowScriptService.pythonScriptTemplate.getValue() == '')
      this.flowScriptService.GetPythonScriptTemplate().subscribe(res => {

        this.scriptObject.code = this.flowScriptService.pythonScriptTemplate.getValue()

      })
  }


  createFilteredOptions() {
    this.filteredOptions = this.parameterForm.get('key').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.options)),
    );
  }

  private _filter(value: string, options: any[]): string[] {
    const filterValue = value.toLowerCase();

    return options.filter(option => option.toLowerCase().includes(filterValue));
  }


  selectIdKey() {
    this.parameterForm.patchValue({
      type: 'System.String'
    })
  }

  editorInit(eventInit: any) {
    (window as any).monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: true
    });

    (window as any).monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES2016,
      allowNonTsExtensions: true
    });

  }

  editScript(selectedScript: FlowScriptResponse) {
    this.scriptForm.patchValue({
      name: selectedScript.name,
      description: selectedScript.description,
      package: selectedScript.package,
      pythonExecuteable: selectedScript.pythonExecuteable,
      stopIfFailed: selectedScript.stopIfFailed == 0 ? false : true,
      timeout: selectedScript.timeout
    })
  }


  upsertScript(type?: string) {
    if (type == 'saveAs') {
      this.scriptObject.scriptId = ''
    }
    Object.assign(this.scriptObject, this.scriptForm.value)
    this.scriptObject.parameters = this.parameterList
    this.scriptObject.stopIfFailed = this.scriptForm.value.stopIfFailed == false ? 0 : 1

    if (!this.data.type.includes('flow')) {
      if (!this.scriptForm.valid) {
        this.coreService.showFormFillError()
        return;
      }

      this.flowScriptService.upsertScript(this.scriptObject).subscribe(res => {
        this.dialogRef.close(true)
      })
    } else {
      this.dialogRef.close(this.scriptObject)
    }

  }


  addParameterElement() {
    if (this.parameterForm.valid) {
      var obj: FlowSriptParameter = {
        key: '',
        value: '',
        description: '',
        type: '',
        shown: 0,
        mandatory: 0,
      }
      Object.assign(obj, this.parameterForm.value)

      if (!this.parameterIsUpdate && this.parameterList.some(x => x.key == obj.key)) {
        this.coreService.showErrorMessage('Bu key ile tanımlanmış bir parametre mevcuttur.Lütfen farklı bir key değeri kullanın!')
        return;
      } else if (this.parameterIsUpdate && this.parameterList.filter(x => x.key == obj.key).length > 1) {
        this.coreService.showErrorMessage('Bu key ile tanımlanmış bir parametre mevcuttur.Lütfen farklı bir key değeri kullanın!')
        return;
      }

      if (this.parameterIsUpdate) {
        this.parameterList = this.parameterList.filter(x => x.key !== obj.key)

      }

      obj.mandatory = this.parameterForm.value.mandatory == false ? 0 : 1
      obj.shown = this.parameterForm.value.shown == false ? 1 : 0



      this.parameterList.push(obj)

      // this.parameterForm.reset()
      this.parameterForm.patchValue({
        shown: false,
        mandatory: false,
        description: '',
        value: '',
        key: '',
        type: ''
      })

      this.visibleParameterForm = false
      this.parameterIsUpdate = false
    }
    else {
      this.coreService.showFormFillError()
    }


  }

  deleteParameter(event: Event, selectedItem: FlowSriptParameter) {
    event.stopPropagation();
    this.parameterList = this.parameterList.filter(x => x.key !== selectedItem.key)

  }

  editParameter(selectedItem: FlowSriptParameter, event?: Event,) {
    event.stopPropagation();
    this.visibleParameterForm = true;

    this.parameterForm.patchValue({
      key: selectedItem.key,
      value: selectedItem.value,
      description: selectedItem.description,
      type: selectedItem.type,
      mandatory: selectedItem.mandatory == 1 ? true : false,
      shown: selectedItem.shown == 0 ? true : false
    })
    this.parameterIsUpdate = true


  }

  openBiggerEditor() {
    const dialogRef = this.dialog.open(CodeEditorComponent, {
      height: '100%',
      width: '100%',
      data: {
        code: this.scriptObject.code,
        widget: this.scriptObject,
        debug: () => { this.selectedTabIndex = 2 },
        editorOptions: { theme: 'vs-dark', language: 'python' }
      },
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.scriptObject.code = result;
      }
    });
  }

  getStarCount(item): number {
    // String içindeki harf sayısını döndüren bir fonksiyon
    if (item?.value) {
      if (item.value.length <= 0) {
        return 1
      }
      else {
        return item.value.replace(/ /g, '').length;
      }
    } else {
      return 1
    }
  }

  closeModal() {
    this.dialogRef.close()
  }

  uploadIconImage(fileInput: any) {
    this.scriptObject.icon = fileInput
  }

  deleteElement() {
    this.data.deleteFunction(this.data.script)

  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.parameterList, event.previousIndex, event.currentIndex);
  }



}
