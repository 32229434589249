import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../constants/endpoints';
import { map } from 'rxjs/operators';
import { App, AppResponse } from 'src/app/models/responses/appsResponse';
import { CoreService } from '../core/core.service';
import { DashboardService } from '../dashboard/dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class DeployAppService {

  apps: App[]

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private coreService: CoreService,
    private dashboardService: DashboardService
  ) { }

  upSertApp(app: App) {
    const body = {
      Token: this.authService.getToken(),
      Application: app
    }

    return this.http.post(Endpoints.upsertApp, body).pipe(
      map((response: AppResponse) => {
        if (response.result) {
          this.coreService.showSuccessMessage('App Succesfully Created');
        }
        return response
      })
    );
  }

  GetAuthorizedApp() {
    let body = {
      'Token': this.authService.getToken()
    }
    return this.http.post(Endpoints.getAuthorizedApps, body).pipe(
      map((response: any) => {
        this.dashboardService.cachedData.next({ ...this.dashboardService.cachedData.getValue(), authorizedApps: response.message })
        return response.message
      })
    );
  }

  getApps() {
    let body = {
      Token: this.authService.getToken()
    }
    return this.http.post(Endpoints.getApps, body).pipe(
      map((response: AppResponse) => {
        this.apps = response.message
        return response.message
      })
    );
  }

  deleteApp(AppId: number) {
    let body = {
      'Token': this.authService.getToken(),
      'ApplicationId': AppId
    }
    return this.http.post(Endpoints.deleteApps, body).pipe(
      map((response: any) => {
        return response.message
      })
    );
  }

  deployApp(Form: FormData) {
    let body = {
      'Token': this.authService.getToken(),
      'File': File,
      'ApplicationLink': 'test'
    }

    return this.http.post(Endpoints.deployApp, Form).pipe(
      map((response: any) => {
        return response.message
      })
    );
  }
}
