import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { App } from "src/app/models/responses/appsResponse";
import { DeployAppService } from "src/app/services/deploy-app/deploy-app.service";
import { ModalService } from "src/app/services/modal/modal.service";
import { RoleFormComponent } from "../role/role-form/role-form.component";
import { CreateAppComponent } from "./create-app/create-app.component";

@Component({
    selector: 'app-deploy-app',
    templateUrl: './deploy-app.component.html',
    styleUrls: ['deploy-app.component.scss']
})
export class DeployApp implements OnInit {
    Applications: any
    displayedColumns: string[] = ['icon', 'name', 'owner', 'action',];
    displayedColumnsName = [this.translate.instant("Common.Icon"), this.translate.instant("AppPage.Name"), this.translate.instant("AppPage.Owner"), this.translate.instant("AppPage.Action")]



    constructor(private deployAppService: DeployAppService, private router: Router
        , private modalService: ModalService,
        private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.init()
    }

    init() {
        this.getApps()
    }

    getApps() {
        this.deployAppService.getApps().subscribe(apps => {
            this.Applications = apps
            // this.Applications = this.deployAppService.apps

        })
    }

    openApp(event: any) {
        let data = {
            type: event.type as string,
            app: event.data as App
        }
        this.modalService.openModal(data, CreateAppComponent, 'auto', '1000px').subscribe(result => {
            // if (result === true) {
                this.getApps();
                // this.permissionService.getUserPermissions(true).subscribe(r => { });
            // }
        });
    }

    selectApp(App: any) {
        if (App.type == 'app') {
            this.router.navigate(["/"], { state: App });
        } else if (App.type == 'deploy') {
            this.router.navigate(["/upload"], { state: App });
        }
    }


    deleteApp(App: any) {
        this.modalService.openDeleteConfirm().subscribe(selection => {
            if (selection === true) {
                this.deployAppService.deleteApp(App.applicationId).subscribe(res => {
                    this.getApps();
                })
            }
        })

    }
}