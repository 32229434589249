import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Endpoints } from 'src/app/constants/endpoints';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  getWidgetById(widgetId:string[], dashboardId, filter?) {
    let param = {
      Token: this.authService.getToken(),
      WidgetId: widgetId,
      DashboardId: dashboardId,
      Filter: filter
    };
    return this.http.post(Endpoints.getWidgetById, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }
  
  getWidgetRepository(query:string){
    let param = {
      Token: this.authService.getToken(),
      OnlyMain:1,
      Query:query
    };
    return this.http.post(Endpoints.GetWidgetRepository, param).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
