import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ColorPalette } from 'src/app/models/responses/colorPaletteResponse';
import { ColorItem } from 'src/app/models/responses/widgetLibraryResponse';
import { ColorPaletteService } from 'src/app/services/color-palette/color-palette.service';

@Component({
  selector: 'app-custom-color-picker',
  templateUrl: './custom-color-picker.component.html',
  styleUrls: ['./custom-color-picker.component.scss']
})
export class CustomColorPickerComponent implements OnInit {
  @Input() colorList: { color: string, key: string, selected?: boolean }[] = [
    { color: 'red', key: '1', selected: false },
    { color: 'blue', key: '2', selected: false },
    { color: 'purple', key: '3', selected: false },
    { color: 'yellow', key: '4', selected: false },
    { color: 'green', key: '5', selected: false },
    { color: 'red', key: '6', selected: false },

  ]

  @Input() selectedColorsForUpdate: { type: number, colors: ColorItem[] } = { type: 0, colors: [] }

  colorTypes = [
    { type: 'samecolor', value: 0 },
    { type: 'highttolow', value: 1 },
    { type: 'lowtohigh', value: 2 },
    { type: 'multicolor', value: 3 }]

  selectedColorType: number = 0

  @Output() selectColor: EventEmitter<any> = new EventEmitter<any>()
  @Output() getPaletColors: EventEmitter<any> = new EventEmitter<any>()




  rgbForm = new FormGroup({
    r: new FormControl('', [Validators.required]),
    g: new FormControl('', [Validators.required]),
    b: new FormControl('', [Validators.required]),
  });

  hexForm = new FormGroup({
    hex: new FormControl('', [Validators.required]),
  });

  createdColors: { color: string, key: string, selected?: boolean }[] = []

  colorType: string = 'rgb'
  colorPalettesVisible: boolean = true

  colorPalettes: ColorPalette[] = []

  selectedColorPalettes: ColorPalette[] = []
  selectedPaletColors: { paletId: string, color: string, key: string }[] = []
  selectedColors: { color: string, key: string, paletId?: string, selected?: boolean }[] = []

  constructor(private colorPaletteService: ColorPaletteService) { }

  ngOnInit(): void {
    this.getColorPalettes()



  }

  SetForUpdate() {

    this.selectedColorType = this.selectedColorsForUpdate.type
    this.selectedColorsForUpdate.colors.map(item => {
      var colorpalet = this.colorPalettes.find(x => x.colorPaletteId == item.palette)
      if (colorpalet) {
        if (!this.selectedColorPalettes.some(x => x.colorPaletteId == item.palette)) {
          this.selectedColorPalettes.push(colorpalet)
          this.selectedPaletColors.push({ key: item.text, color: item.color, paletId: item.palette })
        } else {
          this.selectedPaletColors.push({ key: item.text, color: item.color, paletId: item.palette })
        }
      }

    })
    this.sendColorPaletForLoader()
  }


  getColorPalettes() {
    this.colorPaletteService.getColorPalettes().subscribe(res => {
      this.colorPalettes = res
      if (this.selectedColorsForUpdate)
        this.SetForUpdate()
    })
  }


  changeColor(event: any) {
    var concatList = this.createdColors.concat(this.colorList)
    var biggest = concatList.reduce((max, obj) => (parseInt(obj.key) > parseInt(max.key) ? obj : max), concatList[0]).key;
    var newKey = parseInt(biggest) + 1



    if (this.colorType == 'rgb' && this.rgbForm.valid) {
      this.createdColors.push({ key: newKey.toString(), color: `rgb(${this.rgbForm.value.r},${this.rgbForm.value.g},${this.rgbForm.value.b})`, selected: false })
    } else if (this.hexForm.valid) {
      this.createdColors.push({ key: newKey.toString(), color: `#${this.hexForm.value.hex}`, selected: false })
    }
  }

  selectColorFunc(selectedcolor: { color: string, key: string, selected?: boolean }, listname: any) {
    if (this.selectedColorType !== 3) {
      this.colorList = []
      this.createdColors = []
      this.selectedPaletColors = []
    }
    if (listname == 'favorite') {
      this.colorList.find(x => x.key == selectedcolor.key).selected = !this.colorList.find(x => x.key == selectedcolor.key).selected
    } else if (listname == 'created') {
      this.createdColors.find(x => x.key == selectedcolor.key).selected = !this.createdColors.find(x => x.key == selectedcolor.key).selected
    } else {
      var palet = this.selectedColorPalettes.find(x => x.colorPaletteId == selectedcolor.key)
      if (this.selectedPaletColors.some(x => x.paletId == palet.colorPaletteId && x.color == selectedcolor.color)) {
        this.selectedPaletColors = this.selectedPaletColors.filter(x => !(x.paletId == palet.colorPaletteId && x.color == selectedcolor.color))
      } else {
        this.selectedPaletColors.push({ paletId: selectedcolor.key, color: selectedcolor.color, key: selectedcolor.key })
      }

    }

    var selectedcolorlist = this.colorList.filter(x => x.selected == true)
    var selectedcreatedcolorlist = this.createdColors.filter(x => x.selected == true)

    var selectedColorsConcatted = selectedcolorlist.concat(selectedcreatedcolorlist)

    this.selectColor.emit({ selectedPaletColor: this.selectedPaletColors, selectedColor: selectedColorsConcatted, colorType: this.selectedColorType })

  }

  selectColorType(item) {
    this.selectedColorType = item.value

    this.selectColor.emit({ selectedColor: this.selectedPaletColors, colorType: this.selectedColorType })
  }

  changeColorPaletteSelect(event) {
    this.selectedColorPalettes = event

    this.sendColorPaletForLoader()
    // console.log(this.selectedColorPalette,event)
  }

  sendColorPaletForLoader() {
    var visibleColors = []
    this.selectedColorPalettes.map(palet => {
      return palet.palettes.map(cl => {
        return visibleColors.push({ paletId: palet.colorPaletteId, color: cl, key: palet.colorPaletteId })
      })
    })

    this.getPaletColors.emit(visibleColors)
  }


  isSelected(paletId: string, color: string) {
    if (this.selectedPaletColors.some(x => x.paletId == paletId && x.color == color)) {
      return true
    } else {
      return false
    }
  }
}

