import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PasswordPolicy } from 'src/app/models/requests/passwordPolicy';
import { CoreService } from 'src/app/services/core/core.service';
import { LicenseService } from 'src/app/services/license/license.service';

@Component({
  selector: 'app-password-policy',
  templateUrl: './password-policy.component.html',
  styleUrls: ['./password-policy.component.scss']
})
export class PasswordPolicyComponent implements OnInit {

  pageForm: UntypedFormGroup;

  passwordPolicies: PasswordPolicy[] = [];
  constructor(
    private licenseService: LicenseService,
    private coreService: CoreService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.getPasswordPolicy();

    this.pageForm = new UntypedFormGroup({
      minLength: new UntypedFormControl([Validators.required, Validators.min(8)])
    });
  }

  private getPasswordPolicy() {
    this.licenseService.getPasswordPolicy().subscribe(result => {
      this.passwordPolicies = result;
    });
  }


  updatePasswordPolicy() {
    if (this.checkValidation()) {
      this.licenseService.updatePasswordPolicy(this.passwordPolicies).subscribe(result => {
        
      });
    }
    else {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.PasswordPolicyMinLength"));
    }
    
  }

  private checkValidation(): Boolean {
    var result = true
    this.passwordPolicies.forEach(p => { 
      if (p.minLength < 4) {
        result = false
      }
    });
    return result
  }

}