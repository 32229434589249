import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { SelfServiceDesignerComponent } from '../../self-service-designer/self-service-designer.component';
import { environment } from "src/environments/environment";
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { deleteCssFile } from 'src/app/core/utils';
import { Router } from '@angular/router';
import { GlobalFunctionService } from 'src/app/services/global-function/global-function.service';
import { SharePersonFormComponent } from '../../share-person-form/share-person-form.component';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-self-service-dashboard-list',
  templateUrl: './self-service-dashboard-list.component.html',
  styleUrls: ['./self-service-dashboard-list.component.scss']
})
export class SelfServiceDashboardListComponent implements OnInit {

  constructor(
    private modalService: ModalService,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService,
    private router: Router,
    private translate: TranslateService,
    private coreService: CoreService,
    private globalFunctionService: GlobalFunctionService
  ) { }

  searchColumns: string[] = ['title']
  paginatedDashboards: Dashboard[] = [];
  baseUrl = environment.imageUrl;
  dashboards: Dashboard[] = [];
  allDashboards: Dashboard[] = []
  currentPage = 0;
  itemsPerPage = 5;
  totalPages = 0;
  userDash: string;
  routeList = [{ path: "/selfservice", name: this.translate.instant("SelfServicePage.SelfServiceHome") }, { path: "/selfservice/dashboard-list", name: this.translate.instant("SelfServicePage.DashboardList") }]
  displayLoading = false; // Başlangıçta yüklenme ekranını gizle
  showNoDashboards = false;

  ngOnInit(): void {
    this.displayLoading = true; // Yükleme ekranını göster
    this.getDashboards()
  }
  avatarKeys = { avatar: 'avatar', tooltip: 'firstName lastName', avatartext: 'firstName', avatartext2: 'lastName' }

  columns: any[] = [{ title: '', width: '5%', selector: 'icon', mobile: true }, { title: 'DataStorePage.title', width: '30%', selector: 'title', mobile: true },
  { title: 'DataStorePage.Description', width: '40%', selector: 'description', mobile: false }, { title: 'Shared', width: '15%', selector: 'avatar', avatarselector: 'users', avatarKeys: this.avatarKeys, mobile: true }, { title: '', width: '5%', selector: 'actionicon', mobile: true },
  { title: 'DataStorePage.Action', width: '5%', selector: 'action', mobile: true }]


  openDesigner(dashboardId?: Dashboard) {
    if (dashboardId)
      this.router.navigate(['selfservice/self-service-design', dashboardId]);

    else
      this.router.navigate(['selfservice/self-service-design']);
  }

  deleteDashboard(dashboard: Dashboard) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.dashboardService.deleteDashboard(dashboard).subscribe(result => {
          this.spinner.hide();
          this.getDashboards();
        })
      }
    });
  }

  getDashboards() {
    this.displayLoading = true;

    this.dashboardService.getAuthorizedDashboards().subscribe(result => {
      // this.dashboards = result;
      this.userDash = localStorage?.getItem('userId');
      const filteredDashboards = result.filter(dashboard => dashboard.ownerId == this.userDash && dashboard.isSelfService == 1).map(item => {
        return { ...item, users: item.users ? JSON.parse(item.users) : '' }
      });

      this.dashboards = filteredDashboards
      this.allDashboards = filteredDashboards



      this.displayLoading = false;
      this.showNoDashboards = true;


      this.updatePaginationDashboards();

    });
  }



  paginateDashboards(event: any) {
    this.currentPage = event.pageIndex;
    this.paginatedDashboards = this.dashboards.slice(
      event.pageIndex * event.pageSize,
      (event.pageIndex + 1) * event.pageSize
    );
  }

  updatePaginationDashboards() {
    this.totalPages = Math.ceil(this.dashboards.length / this.itemsPerPage);
    this.currentPage = 0;
    this.paginatedDashboards = this.dashboards.slice(
      this.currentPage * this.itemsPerPage,
      (this.currentPage + 1) * this.itemsPerPage
    );
  }


  filterData(filteredDatas: Dashboard[]) {
    this.dashboards = filteredDatas
    this.updatePaginationDashboards();
  }

  // getRouteElements() {
  //   return [{ path: "/selfservice", name: "Self Service Home" }, { path: "/selfservice/dashboard-list", name: "Dashboard List" }]
  // }

  updateDashboardUsers(dashboard: Dashboard) {
    this.dashboardService.upsertDashboard(dashboard).subscribe(result => {
      if (result) {
        this.coreService.showSuccessMessage(result.message);
        this.getDashboards()
      }
    });
  }

  openShareForm(selected: Dashboard) {
    var data = {
      selectedUsers: selected.users,
      title: 'UserCard.ShareDashboard'
    }
    this.modalService.openModalBeforeClosed(data, SharePersonFormComponent, '90%', '40%', 'responsive-modal').subscribe(result => {

      if (result.length > 0) {
        var dashboard = { ...selected, users: JSON.stringify(result) }
        this.updateDashboardUsers(dashboard)

      } else {
        var dashboard = { ...selected, users: '[]' }
        this.updateDashboardUsers(dashboard)
      }
    });
  }

  actionButtonsClick(data: any) {
    if (data.type == 'edit')
      this.openDesigner(data.data.dashboardId)
    else if (data.type == 'delete')
      this.deleteDashboard(data.data)
    else if (data.type == 'share')
      this.openShareForm(data.data)
  }
}
