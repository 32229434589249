import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CoreService } from 'src/app/services/core/core.service';
import { SystemParametersService } from 'src/app/services/system-parameters/system-parameters.service';

@Component({
  selector: 'app-upload-configuration-form',
  templateUrl: './upload-configuration-form.component.html',
  styleUrls: ['./upload-configuration-form.component.scss']
})
export class UploadConfigurationFormComponent implements OnInit {

  constructor(
    private systemParametersService: SystemParametersService,
    private authService:AuthService,
    private dialogRef:MatDialog,
    private coreService:CoreService
  ) { }

  ngOnInit(): void {
  }
  file: File
  filename:string
  changeFile(event: any) {
    this.file = <File>event.target.files[0];
    this.filename=this.file.name

  }

  formData = new FormData()

  uploadFile() {
    if (this.file) {
      this.formData.append('File', this.file)
        this.formData.append('Token', this.authService.getToken())
      this.systemParametersService.uploadConfiguration(this.formData).subscribe(response => {
          this.coreService.showSuccessMessage('İşlem Başarılı');
          this.dialogRef.closeAll()

      }, error => {
        console.error('Error uploading file', error);
        this.coreService.showErrorMessage('İşlem Başarısız')
      });
    }
  }


}
