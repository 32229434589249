<div class="drawflow-container">
    <div
        style="height: 40px;width: 100%;padding-right: 20px;padding-left: 20px;position: absolute;left: 0;box-sizing: border-box;">
        <div [class]="'top-area '+ titleClass">
            <h3>{{flowTitle|uppercase}}</h3>
            <div style="display: flex;justify-content: flex-end;align-items: center;max-width: 60%;">
                <div class="mr-10" style="display: flex;align-items: center;">
                    <mat-icon *ngIf="runlock" class="rotate-image mr-10" [svgIcon]="'setting-custom'"></mat-icon>

                    <button (click)="routeToFLow()" [matTooltip]="'SelfServicePage.Flow'|translate" mat-raised-button
                        class="custom-primary-button mr-10">
                        <mat-icon svgIcon="home"></mat-icon>
                    </button>

                    <button *ngIf="flowImportObject!==''" (click)="runFlow()"
                        [matTooltip]="'FlowPage.RunFlow'|translate" mat-raised-button
                        class="custom-primary-button mr-10" [disabled]="runlock">
                        <mat-icon svgIcon="play"></mat-icon>
                    </button>
                    <button *ngIf="flowImportObject!==''" (click)="stopFlow()"
                        [matTooltip]="'FlowPage.StopFlow'|translate" mat-raised-button
                        class="custom-primary-button mr-10" [disabled]="!runlock">
                        <mat-icon svgIcon="stop"></mat-icon>
                    </button>
                    <button *ngIf="flowImportObject!==''" (click)="getExecutionLog()" mat-raised-button
                        class="custom-primary-button mr-10">
                        <span>{{"FlowPage.ExecutionLog"|translate}}</span>
                    </button>
                    <button (click)="saveFlow()" mat-raised-button class="custom-primary-button mr-10">
                        <span>{{flowImportObject!==''?('FlowPage.UpdateFlow'|translate):('FlowPage.SaveFlow'|translate)}}</span>
                    </button>
                    <button mat-stroked-button class="custom-primary-stroked-button" (click)="clearFlow()">
                        {{ 'Common.Clear' | translate }}
                    </button>
                </div>
                <div class="preview-button-2">
                    <div [ngClass]="{'active':true}" (click)="zoom('in')">
                        <mat-icon style="transform: scale(0.8);" svgIcon="zoom-in"></mat-icon>
                    </div>
                    <div [ngClass]="{'active': false}" (click)="zoom('out')">
                        <mat-icon style="transform: scale(0.8);" svgIcon="zoom-out"></mat-icon>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="runlock" style="width: 100%;">
            <mat-progress-bar mode="buffer" [value]="loaderValue"></mat-progress-bar>
        </div>
    </div>
    <div id="drawflowDiv" style="top: 45px;"></div>
</div>