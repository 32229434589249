import { Component, OnInit, ViewChild } from '@angular/core';
import { ConnectorFormComponent } from './connector-form/connector-form.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConnectorService } from 'src/app/services/connector/connector.service';
import { Node } from 'src/app/models/responses/nodeResponse';
import { BussionConnector } from 'src/app/models/responses/bussionConncetorResponse';
import { CoreService } from 'src/app/services/core/core.service';
import { environment } from 'src/environments/environment';
import { ModalService } from 'src/app/services/modal/modal.service';
import { LogComponent } from 'src/app/components/modals/log/log.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { tap } from 'rxjs/operators';
import { MatTreeNestedDataSource } from '@angular/material/tree';

enum ConnectorType {
  Batch = 0,
  Periodically = 1,
  Direct = 2
}

@Component({
  selector: 'app-connector',
  templateUrl: './connector.component.html',
  styleUrls: ['./connector.component.scss']
})
export class ConnectorComponent implements OnInit {


  baseUrl: string = environment.imageUrl;
  displayedColumns: string[] = ['icon', 'errorIcon', 'connectorId', 'name', 'type', 'state', 'status', 'nextRunTime', 'action'];
  columnsWidth: any = { icon: '1%', errorIcon: '1%', connectorId: '18%', name: '30%', type: '5%', state: '10%', status: '10%', nextRunTime: '15%', action: '15%' }
  displayedColumnsName: string[] = [this.translate.instant("Common.Icon"), this.translate.instant("ConnectorPage.Error"), this.translate.instant("ConnectorPage.ConnectorId"), this.translate.instant("ConnectorPage.Name"), this.translate.instant("ConnectorPage.Type"), this.translate.instant("ConnectorPage.State"), this.translate.instant("ConnectorPage.Status"), "Next Run", this.translate.instant("ConnectorPage.Action")];
  connectors: BussionConnector[] = [];
  roles: string[] = this.authService.getPermissions();
  folderList: string[] = []

  treeDataSource: any[] = [];

  liststyle: string = 'list'

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private connectorService: ConnectorService,
    private coreService: CoreService,
    private modalService: ModalService,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getBussionConnectorTasks()
  }

  customSort(a: any, b: any): number {
    if (parseInt(a.disableThreshold) > 0 && parseInt(a.errorCount) >= parseInt(a.disableThreshold)) {
      return -1; // a, 'B' ise a'yı b'den önce getir
    } else if (parseInt(b.disableThreshold) > 0 && parseInt(b.errorCount) >= parseInt(b.disableThreshold)) {
      return 1; // b, 'B' ise b'yi a'dan önce getir
    } else if (a.state == 'Working') {
      return -1
    } else if (b.state == 'Working') {
      return 1
    } else if (a.nextRunTime > b.nextRunTime) {
      return -1
    } else if (b.nextRunTime > a.nextRunTime) {
      return 1
    } else {
      a.name - b.name
    }
  }

  getBussionConnectorTasks() {
    this.connectorService.getConnectorTasks().subscribe(result => {
      result.map(r => {
        r.status = r.status == 1 ? "Active" : "Passive";
        r.state = r.state == 0 ? "Not Working" : "Working";

        r.type = ConnectorType[r.type];
        return r;
      });


      this.connectors = result;
      this.connectors = this.connectors.sort(this.customSort)

      this.folderList = Array.from(new Set(this.connectors.map(item => item.folder)))
      // .filter(x => x !== '');

      this.treeDataSource = this.folderList.map(item => {

        return { name: item == '' ? this.translate.instant('ConnectorPage.NoFolder') : item, children: this.connectors.filter(x => x.folder == item) }
      })

      
      this.connectors.map(c => { c.icon = '/BussionImages/Pictures/BussionConnector.png'; return c; });
    });
  }


  deleteConnectorComponent(task: BussionConnector) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.connectorService.deleteConnectorTask(task.connectorId).pipe(
          tap(res => {
            if (task.sourceType == 10) {
              this.connectorService.DeleteExternalEngine(task.bussionConnector, task.connectionString).subscribe(res => {

              })
            }
          })
        ).subscribe(result => {
          this.getBussionConnectorTasks();
          this.spinner.hide();
        });
      }
    });
  }

  openConnector(event: any) {
    let data = {
      type: event.type as string,
      task: event.data as BussionConnector,
      folderList: this.folderList
    }
    this.modalService.openModal(data, ConnectorFormComponent, '900px', '1000px').subscribe(result => {
      if (result === true) {
        this.getBussionConnectorTasks();
      }
    });
  }

  runTask(task: BussionConnector) {
    this.connectorService.runTask(task.connectorId).subscribe(taskId => {
      this.coreService.showSuccessMessage('Task is running now!');
      this.openTaskLogs(taskId);
    });
  }

  private openTaskLogs(taskId: string) {
    let data = {
      taskId: taskId,
      visibleButton:true
    }
    this.modalService.openModal(data, LogComponent, '600px', '600px','overflow-hidden', 'center').subscribe(status => {
      this.getBussionConnectorTasks();
    });
  }

  stopTask(task: BussionConnector) {
    this.connectorService.stopTask(task.connectorId).subscribe(message => {
      this.coreService.showSuccessMessage(message);
      this.getBussionConnectorTasks();
    });
  }

  navigateToFlow() {
    this.router.navigate(['/flow'])
  }

  changeListStyle(style: string) {
    this.liststyle = style
  }
}
