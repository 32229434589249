import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ShareService } from 'src/app/services/share/share.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { App } from 'src/app/models/responses/appsResponse';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-share-preview-modal',
  templateUrl: './share-preview-modal.component.html',
  styleUrls: ['./share-preview-modal.component.scss']
})
export class SharePreviewModalComponent implements OnInit {




  url: string = "";
  urlSafe: SafeResourceUrl;
  width: string = ''
  height: string = ''
  right: number = 0
  top: number = 0
  iframeactive: boolean = false
  dashboardList: Dashboard[] = []
  env: string = environment.imageUrl
  dashboardWidgets: any[] = []
  selectedDashboard: Dashboard
  domain: string = this.authService.getUserDomain()
  loadingHeader: boolean = true


  constructor(
    private scriptLoader: ScriptLoaderService,
    private translate: TranslateService,
    public sanitizer: DomSanitizer,
    private authService: AuthService,
    private dashboardService: DashboardService,
    public dialogRef: MatDialogRef<SharePreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      app: App
    }

  ) {
    this.url = scriptLoader.url
    if (this.scriptLoader.screenOptions.isapp == 'true') {
      this.iframeactive = true
    } else {
      this.iframeactive = false

    }
    if (this.scriptLoader.screenOptions.position == 'leftwindow')
      this.right = 100 - parseInt(this.scriptLoader.screenOptions.width.split('%')[0])
    if (this.scriptLoader.screenOptions.position == 'rightwindow')
      this.right = 0
    if (this.scriptLoader.screenOptions.position == 'centerwindow')
      this.right = ((100 - parseInt(this.scriptLoader.screenOptions.width.split('%')[0])) / 2)

    this.top = ((100 - parseInt(this.scriptLoader.screenOptions.height.split('%')[0])) / 2)


  }

  iframestack: {
    url: string, screenOptions: {
      width: string,
      height: string,
      position: string
    }
  }[] = []

  title: string

  ngOnInit(): void {



    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.sanitizer.sanitize(SecurityContext.URL, this.url))

    setTimeout(() => {
      this.loadingHeader = false
    }, 300);
  }




}
