import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Flow } from 'src/app/models/responses/flowScript';
import { Node } from 'src/app/models/responses/nodeResponse';
import { ConnectorService } from 'src/app/services/connector/connector.service';
import { CoreService } from 'src/app/services/core/core.service';
import { FlowScriptService } from 'src/app/services/flow-scripts/flow-script.service';

@Component({
  selector: 'app-flow-form',
  templateUrl: './flow-form.component.html',
  styleUrls: ['./flow-form.component.scss']
})
export class FlowFormComponent implements OnInit {

  flowForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    bussionConnectorId: new FormControl('', [Validators.required]),
    type: new FormControl(false),
    updateScriptsBeforeRun: new FormControl(false),

  });
  connectors: Node[] = []


  constructor(@Inject(MAT_DIALOG_DATA) public data: {flow:Flow,deleteFunction:Function},
    private flowScriptService: FlowScriptService,
    private dialogRef: MatDialogRef<FlowFormComponent>, private coreService: CoreService,
    private connectorService: ConnectorService
  ) { }



  ngOnInit(): void {
    this.getConnectors();
    if (this.data.flow) {
      this.flowForm.patchValue({
        bussionConnectorId: this.data.flow.bussionConnectorId,
        description: this.data.flow.description,
        name: this.data.flow.name,
        type: this.data.flow.type && this.data.flow.type == 1 ? true : false,
        updateScriptsBeforeRun: this.data.flow.updateScriptsBeforeRun && this.data.flow.updateScriptsBeforeRun == 1 ? true : false,
      })
    }
  }

  getConnectors() {
    this.connectorService.getConnectors().subscribe(res => {
      this.connectors = res
    })
  }

  deleteElement(){
    this.data.deleteFunction(this.data.flow)
  }

  upsertFlow(type?:string) {
    if (!this.flowForm.valid) {
      this.coreService.showFormFillError()
      return;
    }
    var upsertObject: Flow = Object.assign(this.data.flow, this.flowForm.value);
    upsertObject.type = this.flowForm.value.type ? 1 : 0;
    upsertObject.updateScriptsBeforeRun = this.flowForm.value.updateScriptsBeforeRun ? 1 : 0;
    
    if (upsertObject.flowId == '')
      upsertObject.version = 1
    if(type&&type=='copy'){
      upsertObject.flowId=''
    }

    this.flowScriptService.upsertFlow(upsertObject).subscribe(res => {
      if (res) {
        this.dialogRef.close(res)
      }
    })

  }

  closeModal(){
    this.dialogRef.close()
  }


}
