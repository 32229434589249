import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth/auth.service';
import { BussionErrorTypes } from './error.handler';
import { CoreService } from '../services/core/core.service';
//import { NgxSpinnerService } from 'ngx-spinner';


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private coreService: CoreService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    var token = this.authService.getToken();
    var requestURL = request.url
    if (requestURL == "/assets/i18n/en.json" || requestURL == "/assets/i18n/tr.json") {
      
    }
    else {
      request = request.clone({
        url: `${request.url}`,
        setHeaders: {
          //'Content-Type': 'application/json',
          //Authorization: `Bearer ${token}`
        }
      });
    }
    

    //return next.handle(request);

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (!(event instanceof HttpResponse)) {
            return;
          }

          if(event.body.result === true && event.body.messageId !== 0) {
            this.coreService.showSuccessMessage(event.body.message);
          }

          if(event.body.result === false) {
            let error = new Error()
            error.name = BussionErrorTypes.Server;
            error.message = event.body.message;
            error.stack = event.body.messageId.toString();
            throw error;
          }
        },
        (error: Error) => {
          // hide spinner
          //this.spinner.hide();
        }
      )
    );
  }


}
