import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpeechProcces, SpeechStatus } from 'src/app/core/speechTypes';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ChatbotService } from 'src/app/services/chatbot/chatbot.service';
declare let window: any;


@Component({
  selector: 'app-speech-microphone',
  templateUrl: './speech-microphone.component.html',
  styleUrls: ['./speech-microphone.component.scss']
})


export class SpeechMicrophoneComponent implements OnInit {
  mic: boolean = false
  processingCommand: boolean = false
  proccessText: string = ''
  proceessCommand: string = ''
  hearing: boolean = false
  @Input() activeAssistan: boolean = false
  @Input() functionList: { type: number, function: Function }[]
  QueryTextList: string[] = ['sorg', 'quer', 'get']
  permissions: string[] = this.authService.getPermissions()

  constructor(protected chatbotService: ChatbotService, public router: Router, private authService: AuthService) { }


  ngOnInit(): void {

    this.openSpeech()
    this.mic = localStorage.getItem('micActive') == 'true' ? true : false
    this.hearing = this.mic
    if (this.chatbotService.recognition) {
      this.chatbotService.recognition.onend = (e) => {
        if (this.proceessCommand.toLowerCase() !== 'kapat') {
          localStorage.setItem('micActive', 'true')
          this.mic = true
          this.chatbotService.recognition.start();
        } else {
          this.hearing = false
          localStorage.setItem('micActive', 'false')
          this.mic = false
        }

      }
    } else {
      const { webkitSpeechRecognition } = window 
      if ('SpeechRecognition' in window) {
      

        var recognition = new webkitSpeechRecognition();
        this.chatbotService.recognition = recognition
        this.chatbotService.recognition.lang = `${this.authService.getUserLangName()}-${this.authService.getUserLangName().toUpperCase()}`
        if (localStorage.getItem('micActive') == 'true')
          this.openSpeech()
      }
    }
  }

  startSpeech() {
    this.mic = true
    this.hearing = true
    try {
      this.chatbotService.recognition.start();
    } catch (error) {
      console.error(error)
    }
  }

  openSpeech() {
    // var recognition = this.chatbotService.openSpeech()
    // this.chatbotService.recognition
    // this.mic = true
    // this.hearing=true
    if (this.chatbotService.recognition)
      this.chatbotService.recognition.onresult = (result) => {
        this.chatbotService.speechStatus.next(SpeechStatus.Processing)
        this.proccessText = result.results[0][0].transcript
        this.proceessCommand = result.results[0][0].transcript
        this.speechCommands(result.results[0][0].transcript)
      }

  }

  closeSpeech(value) {
    this.mic = false
    this.hearing = false
    this.proceessCommand = 'kapat'
    this.chatbotService.closeSpeech(value)
    setTimeout(() => {
      this.proccessText = ''
    }, 3000);
  }

  speechCommands(sentences) {


    var word = sentences.split(' ')[0].toLowerCase()

    switch (word) {
      case 'sil':
      case 'si':
      case 'delete':
        this.functionList.find(x => x.type == SpeechProcces.Delete).function()
        break;
      case 'anasayfa':
      case 'anasay':
      case 'ana sayfa':
      case 'homepage':
        if (this.functionList.some(x => x.type == SpeechProcces.HomePage))
          this.functionList.find(x => x.type == SpeechProcces.HomePage).function()
        this.router.navigate(['/'])
        break;
      case 'yenile':
      case 'yeni':
      case 'refresh':
      case 'reset':
      case 'sıfırla':
      case 'sıfırl':
        this.functionList.find(x => x.type == SpeechProcces.Refresh).function()
        break;
      case 'yardım':
      case 'yard':
      case 'help':
        this.functionList.find(x => x.type == SpeechProcces.Help).function()
        break;
      case 'kapat':
      case 'kap':
      case 'close':
        if (this.functionList.some(x => x.type == SpeechProcces.Close))
          this.functionList.find(x => x.type == SpeechProcces.Close).function()
        this.mic = false
        this.hearing = false
        this.chatbotService.closeSpeech('kapat')
        break;
      // case 'asistan':
      // case 'asist':
      // case 'assistant':
      //   this.activeAssistan = true
      //   if (this.functionList.some(x => x.type == SpeechProcces.AssistantOpen))
      //     this.functionList.find(x => x.type == SpeechProcces.AssistantOpen).function()
      //   break;

      default:
        this.otherFunction(sentences)
        break;
    }

    this.mic = false
  }

  otherFunction(sentences: string) {

    if (this.activeAssistan) {
      if (sentences.includes('temi')) {
        if (this.functionList.some(x => x.type == SpeechProcces.Delete)) {
          this.functionList.find(x => x.type == SpeechProcces.Delete).function(sentences)
        }
      } else if (this.functionList.some(x => x.type == SpeechProcces.TextToChatBot)) {
        this.functionList.find(x => x.type == SpeechProcces.TextToChatBot).function(sentences)
      }
    }
    if (this.router.url == '/') {
      if (this.functionList.some(x => x.type == SpeechProcces.OpenDashboard)) {
        this.functionList.find(x => x.type == SpeechProcces.OpenDashboard).function(sentences)
      }
    } else if (this.router.url == '/main') {
      if (this.functionList.some(x => x.type == SpeechProcces.FilterDashboard) && this.QueryTextList.some(kelime => sentences.includes(kelime))) {
        this.functionList.find(x => x.type == SpeechProcces.FilterDashboard).function(sentences)
      }
    }
  }



}
