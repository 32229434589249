<div class="flow-rule-modal-container">
    <div class="title-area">
        <span>Bir Kural ekleyin</span>
        <mat-icon mat-dialog-close>clear</mat-icon>
    </div>
    <div class="body">
        <form [formGroup]="ruleForm">
            <mat-form-field>
                <mat-label>{{ 'FlowForm.RuleName' | translate }}</mat-label>
                <input matInput formControlName="name">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'FlowForm.Rule' | translate }}</mat-label>
                <input type="number" matInput formControlName="exitCode">
            </mat-form-field>
        </form>
    </div>
    <div class="modal-footer">
        <div style="display: flex;justify-content: end;">
            <button mat-dialog-close mat-stroked-button class="custom-primary-stroked-button mr-10">
                {{ 'Common.Cancel' | translate }}
            </button>
            <button (click)="saveRule()" mat-raised-button class="custom-primary-button">
                {{ 'Common.Save' | translate }}
            </button>
        </div>
    </div>
</div>