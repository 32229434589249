<!-- <app-modal-title [title]="title"></app-modal-title>
<mat-dialog-content class="mat-typography">

    <app-title title="{{ 'ColorPaletteFormPage.About' | translate }}"></app-title>

    <form [formGroup]="pageForm">
        <mat-form-field>
            <mat-label>{{ 'ColorPaletteFormPage.Name' | translate }}</mat-label>
            <input matInput [(ngModel)]="colorPalette.name" formControlName="name" required>
        </mat-form-field>
        <br>
    </form>

    <app-title title="{{ 'ColorPaletteFormPage.Colors' | translate }}"
        buttonTitle="+ {{ 'ColorPaletteFormPage.AddNewColor' | translate }}"
        (btnEvent)="isShownForm = !isShownForm"></app-title>

    <div class="modal-form-container" *ngIf="isShownForm">
        <div class="modal-form-content">
            <color-sketch></color-sketch>
            <input class="color-palette-item" [cpOutputFormat]="'hex'" [value]="color" [style.background]="color"
                [(colorPicker)]="color" />
        </div>
        <div class="modal-form-action-content">
            <button mat-button class="b-btn" (click)="isShownForm = !isShownForm">{{ 'Common.Close' | translate
                }}</button>
            <button mat-button class="b-btn b-success" cdkFocusInitial (click)="addColor()">{{ 'Common.Add' | translate
                }}</button>
        </div>
    </div>

    <div class="color-palette-content">
        <div class="color-item" *ngFor="let color of colorPalette.palettes; let i = index;"
            [ngStyle]="{'background-color': color}">
            <span>{{color}}</span>
            <mat-icon (click)="deleteColor(i)" svgIcon="delete"></mat-icon>
        </div>
    </div>
    <br>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="!isCreateForm" (click)="upsertColorPalette()">{{
        'Common.Update' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="isCreateForm" (click)="upsertColorPalette()">{{
        'Common.Create' | translate }}</button>
</mat-dialog-actions> -->


<app-custom-modal [title]="title" [subtitle]="'VisualCompFormPage.SubText'" img="/assets/images/visual-component.png">
    <div body style="padding: 0px 20px;max-height: 100%;height: 100%;">
        <div style="overflow: auto;height: 85%;">
            <form [formGroup]="pageForm">
                <mat-form-field>
                    <mat-label>{{ 'ColorPaletteFormPage.Name' | translate }}</mat-label>
                    <input matInput [(ngModel)]="colorPalette.name" formControlName="name" required>
                </mat-form-field>
                <br>
            </form>
            <div class="color-section">
                <div class="color-selection">
                    <color-sketch [presetColors]="colorSuggestionForPicker" [(ngModel)]="color"
                        (colorChange)="changeSuggestionForPicker()"></color-sketch>
                    <button mat-stroked-button class="custom-primary-stroked-button" (click)="addColor()">
                        {{ 'Common.Add' | translate }}
                    </button>
                </div>
                <div>
                    <div *ngIf="colorSuggestion.length>0">
                        <app-title title="{{ 'ColorPaletteFormPage.SuggestionColors' | translate }}"
                            [buttonTitle]="'Seçenek Sayısını Artır'"
                            (btnEvent)="colorSuggestCount=colorSuggestCount+1;createSuggestion(colorSuggestCount)"
                            buttonTitle2="Seçenek Sayısını Azalt"
                            (btnEvent2)="colorSuggestCount=colorSuggestCount-1;createSuggestion(colorSuggestCount)"></app-title>
                        <div class="color-palette-content">
                            <div class="color-item" *ngFor="let color of colorSuggestion; let i = index;"
                                [ngStyle]="{'background-color': color}">
                                <span>{{color}}</span>
                                <mat-icon (click)="addColor(color)">control_point</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="colorPalette.palettes.length>0">
                        <app-title title="{{ 'ColorPaletteFormPage.SelectedColors' | translate }}"></app-title>
                        <div class="color-palette-content">

                            <div (click)="selectColorForSuggestion(color)" matBadge="✔" matBadgePosition="after" matBadgeColor="accent" [matBadgeHidden]="!selectedColorsForSuggestion.includes(color)" class="color-item"
                                *ngFor="let color of colorPalette.palettes; let i = index;"
                                [ngStyle]="{'background-color': color}">
                                <span>{{color}}</span>
                                <mat-icon (click)="deleteColor(i)" svgIcon="delete"></mat-icon>
                            </div>
                        
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <mat-dialog-actions align="end">
            <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
            <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="!isCreateForm"
                (click)="upsertColorPalette()">{{
                'Common.Update' | translate }}</button>
            <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="isCreateForm"
                (click)="upsertColorPalette()">{{
                'Common.Create' | translate }}</button>
        </mat-dialog-actions>

    </div>
</app-custom-modal>