<div class="advanced-code-button custom-outline-button">
    <div [matMenuTriggerFor]="menu">
        <img width="20px" src="/assets/images/photoGalerry.png" />
    </div>
    <mat-menu class="w-150" #menu="matMenu">
        <div style="padding: 5px;">
            <button mat-raised-button class="custom-primary-button button-md" (click)="fileInput.click()">
                <span>{{"WidgetFormPage.AddNewImage"|translate}}</span>
            </button>
            <input (change)="imageSelect($event)" type="file"
                style="visibility: hidden;width: 0;height: 0;position: absolute;" #fileInput />
        </div>
        <div *ngFor="let item of images" mat-menu-item
            style="display: flex;align-items: center;justify-content: space-between;">
            <div style="display: flex;
            align-items: center;font-size: x-small;">
                <img width="30" [src]="imageBaseUrl+item" />
                <!-- <span style="margin-left: 5px;">{{item?.split('/')[2]}}</span> -->
            </div>
            <div>
                <mat-icon style="transform: scale(0.7);margin-right: 0;"
                    (click)="deleteImage(item);$event.stopPropagation();" svgIcon="delete-box"
                    [matTooltip]="'Common.Delete'|translate"></mat-icon>
                <mat-icon style="transform: scale(0.6);margin-right: 0;"
                    (click)="openImage(item);$event.stopPropagation();"
                    [matTooltip]="'Common.Show'|translate">open_in_new</mat-icon>
                <mat-icon style="transform: scale(0.7);margin-right: 0;"
                    (click)="copyLink(item);$event.stopPropagation();"
                    [matTooltip]="'Common.Copy'|translate">link</mat-icon>

            </div>



        </div>
    </mat-menu>
</div>


<ng-template #imageDialog>
    <div [align]="'end'" style="padding: 5px;" matDialogClose>
        <mat-icon style="cursor: pointer;">clear</mat-icon>
    </div>
    <mat-dialog-content>
        <img [src]="selectedImage" />
    </mat-dialog-content>
</ng-template>