<div class="media">
    <div class="media-body" (click)="itemOnclick(item.core,$event)">
        <div class="media-heading">{{item.listitem.text}}</div>
        <small class="notification-text">
            <span *ngIf="smallTitle==''">Created By:</span>
            <span *ngIf="smallTitle!==''">{{smallTitle}}:</span>
            {{item.listitem.small}} miliseconds</small>
    </div>
    <div>
        <div *ngFor="let bt of buttonsList" class="avatar {{bt.class}}" (click)="bt.function(item.core)">
            <div class="avatar-content">
                <img *ngIf="bt.type=='img'" height="20px" [src]="'/assets/images/icons/'+bt.icon" />
                <mat-icon *ngIf="bt.type=='mat-icon'" [svgIcon]="bt.icon"></mat-icon>
            </div>
        </div>
    </div>
</div>