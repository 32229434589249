<div class="page">
    <!-- <app-title [title]="'Aplikasyon Listele'"
      [subtitle]="'Daha önce oluşturduğunuz uygulamaları görebilir ve silebilirsiniz'">
    </app-title> -->
    <app-data-table addButtonText="{{ 'AppPage.ButtonText' | translate }}" title="{{ 'AppPage.Title' | translate }}" filterPlaceholder="Ex. App"
      (openModal)="openApp($event)"
    (deleteRow)="deleteApp($event)" (updateRow)="selectApp($event)" [displayedColumnsName]="displayedColumnsName"
      [displayedColumns]="displayedColumns" [data]="Applications">
    </app-data-table>
    
  
  </div>