import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatbotComponent } from 'src/app/components/chatbot/chatbot.component';
import { PermissionService } from 'src/app/services/permission/permission.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {

  tile: string = 'lightgreen';
  links = ["Users", "Groups", "Roles"];
  opened: boolean = false;
  permissions: string[] = [];

  constructor(
    private permissionService: PermissionService,
   
  ) { }

  ngOnInit(): void {
    this.getUserRoleList();
  }

  getUserRoleList() {
    this.permissionService.getUserRoleList().subscribe(roleList => {
      this.permissions = roleList;
    });
  }



}
