import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ExecutionRule } from 'src/app/models/responses/flowScript';
import { CoreService } from 'src/app/services/core/core.service';

@Component({
  selector: 'app-flow-line-rule-form',
  templateUrl: './flow-line-rule-form.component.html',
  styleUrls: ['./flow-line-rule-form.component.scss']
})
export class FlowLineRuleFormComponent implements OnInit {

  executionRule: ExecutionRule = {
    name: '',
    exitCode: 0,
    scriptId: ''
  }

  ruleForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    exitCode: new FormControl(0, [Validators.required])
  });

  constructor(private coreService: CoreService,
    @Inject(MAT_DIALOG_DATA) public data: {
      selectedRule: {
        rule: ExecutionRule;
        input_id: string;
        output_id: string;
        ruleid: number;
      }, selectedConnection: {
        output_id: string,
        input_id: string,
        output_class: string,
        input_class: string
      }, ruleList: { rule: ExecutionRule, input_id: string, output_id: string, ruleid: number; }[]
    },
    private dialogRef: MatDialogRef<FlowLineRuleFormComponent>,
    private translate: TranslateService) { }

  ngOnInit(): void {
    if (this.data.selectedRule) {
      Object.assign(this.executionRule, this.data)
      this.ruleForm.patchValue({
        name: this.data.selectedRule.rule.name,
        exitCode: this.data.selectedRule.rule.exitCode

      })
    }
  }


  saveRule() {
    if (!this.ruleForm.valid) {
      this.coreService.showFormFillError()
      return;
    }
    //aynı output kodu ile kural tanımlanamaz
    // var sameexitcode = this.data.ruleList.find(x => x.output_id == this.data.selectedConnection.output_id && x.rule.exitCode == this.ruleForm.value.exitCode)

    // if (this.data.selectedRule && sameexitcode && sameexitcode.ruleid !== this.data.selectedRule.ruleid) {
    //   this.coreService.showErrorMessage(this.translate.instant("FlowPage.RuleError"))
    //   return;
    // } else if (!this.data.selectedRule && this.data.ruleList.some(x => x.output_id == this.data.selectedConnection.output_id && x.rule.exitCode == this.ruleForm.value.exitCode)) {
    //   this.coreService.showErrorMessage(this.translate.instant("FlowPage.RuleError"))
    //   return;
    // }

    this.dialogRef.close(this.ruleForm.value)

  }

}
