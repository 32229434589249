import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-self-service-analyze',
  templateUrl: './self-service-analyze.component.html',
  styleUrls: ['./self-service-analyze.component.scss']
})
export class SelfServiceAnalyzeComponent implements OnInit {
  routeList = [{ path: "/selfservice", name: this.translate.instant("SelfServicePage.SelfServiceHome") },  { path: "/selfservice", name: this.translate.instant("SelfServicePage.VisualAnalytics") }]

  constructor(private translate:TranslateService) { }

  ngOnInit(): void {
  }

}
