import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/models/responses/loginResponse';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-share-person-form',
  templateUrl: './share-person-form.component.html',
  styleUrls: ['./share-person-form.component.scss']
})
export class SharePersonFormComponent implements OnInit {


  constructor(private userService: UserService, private dialogRef: MatDialogRef<SharePersonFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      selectedUsers: any[],
      title: string
    }) { }
  allUsers: any[] = []
  FilteredUsers: any[] = []
  searchColumns = ['firstName', 'lastName']
  selectedUserList: any[] = []

  ngOnInit(): void {
    
    if (this.data.selectedUsers.length > 0) {
      this.selectedUserList = this.data.selectedUsers
      this.visibleSelected = true
    }


  }

  getUsers(searchText: string) {
    this.userService.getUsersWithSearchText(searchText).subscribe(res => {
      this.allUsers = res.map(item => {
        var isAvailable = this.selectedUserList.some(x => x.userId == item.userId)
        return { ...item, selected: isAvailable ? this.selectedUserList.find(x => x.userId == item.userId).selected : 0 }
      })
      this.FilteredUsers = this.allUsers
    })
  }

  filterData(filteredDatas) {
    this.FilteredUsers = filteredDatas
  }

  visibleSelected: boolean = false

  serversideSearch(text) {
    if (text == '' || text.length == 0) {
      this.visibleSelected = true
    } else {
      this.visibleSelected = false
    }
    if (text.length < 3)
      return
    else if (text.length == 3)
      this.getUsers(text)
    else
      return

  }


  saveSharedUsers() {
    // if (this.selectedUserList.length > 0) {
    //   var selecteduserlistText = ''
    //   selecteduserlistText = this.selectedUserList.map(item => item.userId).join(', ')
    // }
    this.dialogRef.close(this.selectedUserList)
  }

  selectUser(selectedUser: any) {
    var user = this.FilteredUsers.find(x => x.userId == selectedUser.userId) ?? this.selectedUserList.find(x => x.userId == selectedUser.userId)
    if (user.selected == 1) {
      this.selectedUserList = this.selectedUserList.filter(x => x.userId !== user.userId)
      if (this.FilteredUsers.length > 0)
        this.FilteredUsers.find(x => x.userId == selectedUser.userId).selected = 0
    } else {
      this.selectedUserList.push(user)
      if (this.FilteredUsers.length > 0)
        this.FilteredUsers.find(x => x.userId == selectedUser.userId).selected = 1
    }
  }

}
