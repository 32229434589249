import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filterString } from 'src/app/core/utils';
import { Share } from 'src/app/models/responses/shareResponse';
import { ModalService } from 'src/app/services/modal/modal.service';
import { MailSceduleFormComponent } from '../mail-scedule-form/mail-scedule-form.component';
import { ShareService } from 'src/app/services/share/share.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { CoreService } from 'src/app/services/core/core.service';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { ShareFormComponent } from '../modals/share-form/share-form.component';

@Component({
  selector: 'app-view-button',
  templateUrl: './view-button.component.html',
  styleUrls: ['./view-button.component.scss']
})
export class ViewButtonComponent implements OnInit {
  _mySharesForList: { core: Share, listitem: { text: string, small: string } }[] = []

  @Output() applyFilterForViews: EventEmitter<any> = new EventEmitter<any>()

  // @Output() getShares: EventEmitter<any> = new EventEmitter<any>()

  @Input() buttonClass: string = ''

  _selectedDashboard: Dashboard
  @Input() set selectedDashboard(value: Dashboard) {
    if (value.dashboardId) {
      this._selectedDashboard = value;
      this.getShares()

    }

  }

  @Input() set changeViewList(value: boolean) {
    if (this._selectedDashboard.dashboardId) {
      this.getShares()
    }
  }

  buttonsList: { icon: string, function: Function, tooltip: string, class: string, type: string }[] = [
    { icon: 'mail.svg', function: (e) => this.sendMail(e), tooltip: '', class: 'bg-light-success' , type: 'img'},
    { icon: 'copy.svg', function: (e) => this.copyshareLink(e), tooltip: '', class: 'bg-light-info' , type: 'img'},
    { icon: 'editShare.svg', function: (e) => this.updateShare(e), tooltip: '', class: 'bg-light-info', type: 'img' },
    { icon: 'deleteShare.svg', function: (e) => this.deleteShare(e), tooltip: '', class: 'bg-light-danger', type: 'img' }
  ]

  constructor(private modalService: ModalService, private clipboard: Clipboard,
    private shareService: ShareService, private coreService: CoreService) { }

  ngOnInit(): void {
  }




  getShares() {
    this.shareService.getMyShare(this._selectedDashboard.dashboardId).subscribe(res => {
      this._mySharesForList = res.map(item => {
        return { core: item, listitem: { small: item.sharedBy, text: item.name } }
      })
    })
  }


  applyFilterForViewsFunction(filter) {
    this.applyFilterForViews.emit(filter)
  }


  sendMail(item: Share) {
    var data = {
      url: window.origin + `/#/share/${item.shareKey}?filters=${filterString(item.filter)}`,
      dashboardId: item.dashboardId
    }
    this.modalService.openModal(data, MailSceduleFormComponent, '80%', '60%').subscribe(res => {

    })
  }

  copyshareLink(item: Share) {
    item.filter = item.filter.map(item => {
      return {
        fieldName: item.fieldName,
        fieldValue: item.fieldValue,
        operator: item.operator
      }
    })
    let filter = filterString(item.filter)
    var filterencode = encodeURIComponent(filter)
    const result = this.clipboard.copy(window.origin + `/#/share/${item.shareKey}?filters=` + filterencode).valueOf()

    if (result)
      this.coreService.showSuccessMessage('Copied!');




  }

  deleteShare(item: Share) {
    this.modalService.openDeleteConfirm().subscribe(isOk => {
      if (isOk) {
        this.shareService.deleteShare(item.shareId).subscribe(result => {
          this.getShares();
        });
      }
    })
  }

  updateShare(item: Share) {
    let data = {
      objectType: item.objectType,
      objectId: item.sharedObjectId,
      dashboardId: this._selectedDashboard.dashboardId,
      filter: this._selectedDashboard.filter,
      function: () => {
       
       },
      data: item
    };

    this.modalService.openModal(data, ShareFormComponent, 'auto', '600px').subscribe(result => {
      if (result) {
        this.getShares();
      }
    })
  }

}
