import { Component, OnInit, Inject } from '@angular/core';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard-selection',
  templateUrl: './dashboard-selection.component.html',
  styleUrls: ['./dashboard-selection.component.scss']
})
export class DashboardSelectionComponent implements OnInit {

  dashboards: any[] = ["asdad", "asdasda"];
  selectedDashboards: string[] = [];
  compareFunctionForDashboard = (o1: any, o2: any) => o1 === o2;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DashboardSelectionComponent>,
  ) { }

  ngOnInit(): void {
    if (this.data.dashboards) {
      this.dashboards = this.data.dashboards;
      this.selectedDashboards = this.data.activeDashboardIds
    }

  }

  addSelection() {


    this.dialogRef.close(this.selectedDashboards);
  }
}
