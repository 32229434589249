import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cerateCssFile, deleteCssFile, idKeyword } from 'src/app/core/utils';
import { App } from 'src/app/models/responses/appsResponse';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-data-app-design',
  templateUrl: './data-app-design.component.html',
  styleUrls: ['./data-app-design.component.scss']
})
export class DataAppDesignComponent implements OnInit, OnDestroy {

  @Input() data: { app: App } = {
    app: {
      name: '',
      icon: '',
      appCode: '',
      applicationStatus: '',
      description: '',
      domain: '',
      access: 0,
      owner: '',
      ownerId: '',
      groups: [],
      applicationId: '',
      openerMethod: 0,
      applicationLink: '',
      dashboards: [],
      appType: 0,
      creationDate: '',
      appCSS: '',
      options: ''
    }
  }
  @Input() ismodal: boolean = false;
  appLink: string = ''

  contentIdKeyword = idKeyword.app

  dashboardList: (Dashboard & { tagList: string[] })[] = []
  filteredDashboardList: (Dashboard & { tagList: string[] })[] = []
  widgetCount: number = 0
  appId: string = ''
  env: string = environment.imageUrl
  dashboardWidgets: any[] = []
  selectedDashboard: Dashboard
  avatar: string = ''
  showGrid: boolean = false
  isTopSectionShrinked: boolean = false
  tabs: Dashboard[] = []
  permissions: string[] = []

  constructor(private dashboardService: DashboardService, private activatedRoute: ActivatedRoute,
    private authService: AuthService, private permissionService: PermissionService, private navigation: Router) { }

  ngOnInit(): void {
    this.appId = this.activatedRoute.snapshot.paramMap.get('appId');
    if (this.appId) {
      this.data.app = JSON.parse(localStorage.getItem('app'))
      this.appLink = '/data-app/' + this.appId
    } else {
      this.appId = this.data.app.applicationId
    }
    this.getAllDashboards()
    this.avatar = environment.imageUrl + this.authService.getUserAvatar();
    this.getUserRoleList()

    cerateCssFile(this.data.app.appCSS, this.data.app.applicationId, this.contentIdKeyword)
  }

  ngOnDestroy(): void {
    if (this.appId)
      deleteCssFile(this.appId)
  }


  getUserRoleList() {
    this.permissionService.getUserRoleList().subscribe(roleList => {
      this.permissions = roleList;
    });
  }




  getAllDashboards() {
    if (this.dashboardService.cachedData.getValue().authorizedDashboard.length > 0) {
      this.sortedDashboardList(this.dashboardService.cachedData.getValue().authorizedDashboard)


      this.filteredDashboardList = this.dashboardList
      this.tabs = this.dashboardList
    } else {
      this.dashboardService.getAuthorizedDashboards().subscribe(res => {
        this.sortedDashboardList(res)

        this.filteredDashboardList = this.dashboardList
        this.tabs = this.dashboardList
      })
    }


  }

  sortedDashboardList(list: Dashboard[]) {
    this.data.app.dashboards.map(item => {
      var selectedobject = list.find(x => x.dashboardId == item)
      if (selectedobject) {
        this.dashboardList.push({ ...selectedobject, tagList: selectedobject.tags.split(' ').filter(x => x !== '') })
        this.widgetCount = this.widgetCount + selectedobject.widgets.length
      }
    })
  }


  changeSelectedDashboard(dashboard: Dashboard) {
    this.isTopSectionShrinked = true
    this.showGrid = true
    this.selectedDashboard = dashboard

    if (this.data.app.openerMethod == 2) {
      this.navigation.navigate(['/main/' + this.data.app.applicationId], { state: this.selectedDashboard })
    } else {
      sessionStorage.setItem('selectedDashboard', JSON.stringify(this.selectedDashboard))
    }



    // if (!this.tabs.some(x => x.dashboardId == this.selectedDashboard.dashboardId))
    //   this.tabs.push(this.selectedDashboard)
    // this.navigation.navigate(['/main/' + this.data.app.applicationId], { state: this.selectedDashboard })
  }

  searchTextChange(text) {
    if (text == '') {
      this.filteredDashboardList = this.dashboardList
    } else
      this.filteredDashboardList = this.dashboardList.filter(x => x.title.toLowerCase().includes(text.toLowerCase()))
  }


  gotoAppsPage() {
    this.navigation.navigate(['/landing/deploy-app'])
  }


  returnApp() {
    this.showGrid = false
  }

}
