import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { ColorPaletteResponse, ColorPalette} from 'src/app/models/responses/colorPaletteResponse';

@Injectable({
  providedIn: 'root'
})
export class ColorPaletteService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getColorPalettes() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getAllColorPalettes, param).pipe(
      map((response: ColorPaletteResponse) => {
        return response.message;
      })
    );
  }

  upsertColorPalette(colorPalette: ColorPalette) {
    let param = { 
      Token: this.authService.getToken(),
      ColorPalette: colorPalette
    };
    return this.http.post(Endpoints.upsertColorPalette, param).pipe(
      map((response: ColorPaletteResponse) => {
        return response.message;
      })
    );
  }

  deleteColorPalette(colorPalette: ColorPalette) {
    let param = { 
      Token: this.authService.getToken(),
      ColorPaletteId: colorPalette.colorPaletteId
    };
    return this.http.post(Endpoints.deleteColorPalette, param).pipe(
      map((response: ColorPaletteResponse) => {
        return response.message;
      })
    );
  }

}
