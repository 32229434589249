import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { AuthGuard, LoginGuard } from './core/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { ManageComponent } from './pages/manage/manage.component';
import { UserComponent } from './pages/manage/user/user.component';
import { GroupComponent } from './pages/manage/group/group.component';
import { RoleComponent } from './pages/manage/role/role.component';
import { WidgetLibraryComponent } from './pages/manage/widget-library/widget-library.component';
import { VisualComponent } from './pages/manage/visual/visual.component';
import { DatastoreComponent } from './pages/manage/datastore/datastore.component';
import { NodeComponent } from './pages/manage/node/node.component';
import { ColorPaletteComponent } from './pages/manage/color-palette/color-palette.component';
import { DashboardComponent } from './pages/manage/dashboard/dashboard.component';
import { DashboardDesignerComponent } from './pages/manage/dashboard/dashboard-designer/dashboard-designer.component';
import { GlobalFunctionComponent } from './pages/manage/global-function/global-function.component';
import { ConnectorComponent } from './pages/manage/connector/connector.component';
import { SharePreviewComponent } from './pages/share-preview/share-preview.component';
import { PermissionGuard } from './core/permission.guard';
import { LicenseComponent } from './pages/manage/license/license.component';
import { LdapConfigComponent } from './pages/manage/ldap-config/ldap-config.component';
import { PasswordPolicyComponent } from './pages/manage/password-policy/password-policy.component';
import { DeployApp } from './pages/manage/deploy-app/deploy-app.component';
import { HomeComponent } from './pages/home/home.component';
import { ScriptsComponent } from './pages/manage/scripts/scripts.component';
import { LandingComponent } from './pages/manage/landing/landing/landing.component';
import { SystemParametersComponent } from './pages/manage/system-parameters/system-parameters.component';
import { BussionLogsComponent } from './pages/manage/bussion-logs/bussion-logs.component';
import { DashboardOpenerComponent } from './components/dashboard-opener/dashboard-opener.component';
import { BussionMainComponent } from './components/bussion-main/bussion-main.component';
import { BussionLoginComponent } from './components/bussion-login/bussion-login.component';
import { SelfServiceMainComponent } from './components/self-service-main/self-service-main.component';
import { SelfServiceDesignerComponent } from './components/self-service-designer/self-service-designer.component';
import { SelfServiceDataStoreListComponent } from './components/self-service-dataStoreList/self-service-data-store-list/self-service-data-store-list.component';
import { SelfServiceDashboardListComponent } from './components/self-service-dashboardList/self-service-dashboard-list/self-service-dashboard-list.component';
import { SelfServiceComponent } from './pages/self-service/self-service.component';
import { SelfServiceAnalyzeComponent } from './components/self-service/self-service-analyze/self-service-analyze.component';

import { FlowDesignerComponent } from './pages/flow/flow-designer/flow-designer.component';
import { SharePreviewModalComponent } from './components/share-preview-modal/share-preview-modal.component';
import { DataAppDesignComponent } from './components/data-app-design/data-app-design.component';
import { LanguageManagementComponent } from './components/language-management/language-management.component';
import { ServerArchComponent } from './pages/manage/node/server-arch/server-arch.component';
import { EditTableComponent } from './components/edit-table/edit-table.component';
import { GridAnalyticsComponent } from './pages/self-service/grid-analytics/grid-analytics.component';
import { FlowListComponent } from './pages/flow/flow-list/flow-list.component';
import { FlowComponent } from './pages/flow/flow.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard, PermissionGuard]
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'flow',
    component: FlowComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: FlowListComponent, canActivate: [AuthGuard], },
      { path: 'flow-designer', component: FlowDesignerComponent, canActivate: [AuthGuard, PermissionGuard], },
      { path: 'flow-designer/:flowId', component: FlowDesignerComponent, canActivate: [AuthGuard, PermissionGuard] },
    ]

  },
  {
    path: 'selfservice',
    component: SelfServiceComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'data-store-list', component: SelfServiceDataStoreListComponent, canActivate: [AuthGuard, PermissionGuard], },
      { path: 'dashboard-list', component: SelfServiceDashboardListComponent, canActivate: [AuthGuard, PermissionGuard], },
      { path: 'self-service-design/:dashboardId', component: SelfServiceDesignerComponent, canActivate: [AuthGuard, PermissionGuard], },
      { path: 'self-service-design', component: SelfServiceDesignerComponent, canActivate: [AuthGuard, PermissionGuard], },
      { path: 'analyze', component: SelfServiceAnalyzeComponent, canActivate: [AuthGuard, PermissionGuard], },
     
      { path: 'analyze/grid-analytics/:dataStoreId', component: GridAnalyticsComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: '', component: SelfServiceMainComponent, canActivate: [AuthGuard], },
    ]

  },
  {
    path: 'landing',
    component: LandingComponent,
    canActivate: [AuthGuard, PermissionGuard],
    children: [
      { path: 'user', component: UserComponent, canActivate: [AuthGuard, PermissionGuard], },
      { path: 'group', component: GroupComponent, canActivate: [AuthGuard, PermissionGuard], },
      { path: 'role', component: RoleComponent, canActivate: [AuthGuard, PermissionGuard], },
      { path: 'deploy-app', component: DeployApp, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'widget-library', component: WidgetLibraryComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'visual', component: VisualComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'datastore', component: DatastoreComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'node', component: NodeComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'color-palette', component: ColorPaletteComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'dashboard/designer/:dashboardId', component: DashboardDesignerComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'global-functions', component: GlobalFunctionComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'scripts', component: ScriptsComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'connectors', component: ConnectorComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'license', component: LicenseComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'ldap-config', component: LdapConfigComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'password-policy', component: PasswordPolicyComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'system-parameters', component: SystemParametersComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'bussion-logs', component: BussionLogsComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'bussion-main', component: BussionMainComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'bussion-login', component: BussionLoginComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'language-setting', component: LanguageManagementComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'architecture', component: ServerArchComponent, canActivate: [AuthGuard, PermissionGuard] }





    ]
  },

  {
    path: 'manage',
    component: ManageComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'user', component: UserComponent, canActivate: [AuthGuard, PermissionGuard], },
      { path: 'group', component: GroupComponent, canActivate: [AuthGuard, PermissionGuard], },
      { path: 'role', component: RoleComponent, canActivate: [AuthGuard, PermissionGuard], },
      { path: 'deploy-app', component: DeployApp, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'widget-library', component: WidgetLibraryComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'visual', component: VisualComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'datastore', component: DatastoreComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'node', component: NodeComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'color-palette', component: ColorPaletteComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'dashboard/designer/:dashboardId', component: DashboardDesignerComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'global-functions', component: GlobalFunctionComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'scripts', component: ScriptsComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'connectors', component: ConnectorComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'license', component: LicenseComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'ldap-config', component: LdapConfigComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'password-policy', component: PasswordPolicyComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'system-parameters', component: SystemParametersComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'bussion-logs', component: BussionLogsComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'bussion-main', component: BussionMainComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'bussion-login', component: BussionLoginComponent, canActivate: [AuthGuard, PermissionGuard] },
      { path: 'architecture', component: ServerArchComponent, canActivate: [AuthGuard, PermissionGuard] }


    ]
  },
  {
    path: 'share/:shareId/:filter',
    component: SharePreviewComponent
  },
  {
    path: 'dashboard-opener/:dashboardId',
    component: DashboardOpenerComponent
  },
  {
    path: 'share/:shareId',
    component: SharePreviewComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'data-app/:appId',
    component: DataAppDesignComponent,
    canActivate: [AuthGuard, PermissionGuard]
  },
  {
    path: 'main/:appId',
    component: MainComponent,
    canActivate: [AuthGuard, PermissionGuard]
  },
  {
    path: '**',
    component: MainComponent,
    canActivate: [AuthGuard]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
