import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languagePacts } from 'src/app/models/responses/languagePacts';
import { ModalService } from 'src/app/services/modal/modal.service';
import { SystemParametersService } from 'src/app/services/system-parameters/system-parameters.service';
import { LanguageFormComponent } from '../language-form/language-form.component';
import { CoreService } from 'src/app/services/core/core.service';

@Component({
  selector: 'app-language-management',
  templateUrl: './language-management.component.html',
  styleUrls: ['./language-management.component.scss']
})
export class LanguageManagementComponent implements OnInit {

  languages: languagePacts[] = []

  displayedColumns: string[] = ['name', 'type', 'action'];
  columnWidth: string[] = ['30%', '40%', '30%']
  displayedColumnsName: string[] = [this.translate.instant("LanguageForm.LanguageName"), this.translate.instant("LanguageForm.Type")];

  constructor(private systemService: SystemParametersService,
    private modalService: ModalService,
    private coreService: CoreService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.getLanguages()
  }


  getLanguages() {
    this.systemService.getLanguagePacts().subscribe(res => {
      this.languages = res
    })
  }

  openLanguageForm(selectedLanguage: any) {
    let data = {
      type: event.type as string,
      language: selectedLanguage.data
    }

    this.modalService.openModal(data, LanguageFormComponent, '80%', '60%', 'overflow-hidden').subscribe(result => {
      if (result) {
        this.getLanguages()
      }
    });
  }


  deleteLanguage(lang: languagePacts) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result) {
        this.systemService.deleteLanguage(lang.name).subscribe(res => {
          if (res.result) {
            this.coreService.showSuccessMessage(this.translate.instant("Common.Success"))
            this.getLanguages()
          } else {
            this.coreService.showErrorMessage(res.message)
          }
        })
      }
    })

  }

}
