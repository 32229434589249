import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { SettingAreas, dropdownMenus } from 'src/app/constants/enums';
import { customDropdownProps, customDropdownSettingProps } from 'src/app/models/globals/customDropdownProps';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';

@Component({
  selector: 'app-setting-section',
  templateUrl: './setting-section.component.html',
  styleUrls: ['./setting-section.component.scss']
})
export class SettingSectionComponent implements OnInit, OnChanges {

  selectedTab: { name: string, icon: string, selected: boolean, contentTemplate: TemplateRef<any>, dropdown?: { setting: customDropdownSettingProps, data: customDropdownProps[] } };



  errorValues: any = {
    [SettingAreas.data]: { text: '', active: false },
    [SettingAreas.agregation]: { text: '', active: false },
    [SettingAreas.visual]: { text: '', active: false },
    [SettingAreas.event]: { text: '', active: false },

  }


  @Input() dropdownSettings: customDropdownSettingProps;
  @Input() dropdownMenuData: customDropdownProps;
  @Input() selectedSettingArea: SettingAreas = SettingAreas.data
  _tabs: { name: string, icon: string, selected: boolean, contentTemplate: TemplateRef<any>, dropdown?: { setting: customDropdownSettingProps, data: customDropdownProps[] } }[] = []
  @Input() set tabs(value) {
    if (value) {
      this._tabs = value
      this.selectedTab = value[0]
    }


  }




  @Output() setSelectedSettingAreaFunction: EventEmitter<any> = new EventEmitter<any>()


  constructor(private scriptLoader: ScriptLoaderService) { }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges): void {
    console.log('değişti', changes)
  }


  setSelectedSettingArea(settingArea: SettingAreas) {
    this.setSelectedSettingAreaFunction.emit(settingArea)
  }


  selectTab(item: { selected: boolean, icon: string, name: string, contentTemplate: any }) {

    this._tabs.find(x => x.name == item.name).selected = true
    this.selectedTab = item

    this._tabs.filter(x => x.name !== item.name).map(tab => {
      tab.selected = false
    })

    this.scriptLoader._selectedTabForClick.next(this.selectedTab.name)
  }



}
