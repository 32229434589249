<div class="dialog-content" #widgetContent>
    <div class="widget-header" *ngIf="widget">
        <div class="title">
            <span>{{widget.title}}</span>
            <div class="widget-action">
                <mat-icon style="transform: scale(0.8);" *ngIf="widget?.drillDownLink"
                [svgIcon]="'eye-svgrepo-com'" [matTooltip]="'WidgetFormPage.Detail'|translate"></mat-icon>

                <mat-icon [matMenuTriggerFor]="menu" matListIcon color="primary"  (click)="groupedDataKeys.length>0?DownloadFilter({checked:true}):''">more_vert</mat-icon>
                <mat-menu #menu="matMenu" (closed)="groupedDataKeys.length>0?DownloadFilter({checked:false}):''">
                    <span mat-menu-item *ngIf="permissions.includes('WidgetShare')||permissions.includes('DashboardShare')" (click)="shareWidget()">
                        <mat-icon matListIcon svgIcon="share"></mat-icon> {{ 'WidgetButtons.Share' | translate }}
                    </span>
                    <div mat-menu-item (click)="downloadWidget(widget)" style="display: flex;align-items: center;">

                        <mat-icon matListIcon>get_app</mat-icon>
                        <div style="display: flex;flex-direction: column;line-height: 0.2;">
                            <span> {{ 'WidgetButtons.Download' | translate }}</span>

                            <mat-checkbox *ngIf="groupedDataKeys.length>0" (change)="DownloadFilter($event)"
                                style="font-size: smaller;" class="example-margin downloadfiltercheckbox" [ngModel]="showFilter" (click)="$event.stopPropagation()">{{
                                'WidgetButtons.DownloadWithFilter' | translate }}</mat-checkbox>
                        </div>

                    </div>
                </mat-menu>
                <mat-icon matListIcon color="primary" (click)="action(false)">close</mat-icon>

            </div>
        </div>
        <div class="sub-title" *ngIf="widget.subTitle.length != 0">
            {{widget.subTitle}}</div>

    </div>
    <div style="height: 100%;" >
        <div class="sub-title filter-area" *ngIf="groupedDataKeys.length>0&&showFilter">
            <mat-icon [svgIcon]="'filter'"></mat-icon>
            <div class="filter-container" *ngFor="let item of getObjectKeys(groupedData)">
                <span class="filternamespan">
                    {{item}}</span>
                <div style="display: flex;" *ngFor="let op of getObjectKeys(groupedData[item])">
                    <span style="font-weight: bold;margin-right: 5px;">{{("FilterComponent."+op|translate)}}</span>
                    <div *ngFor="let d of groupedData[item][op];let i = index">
                        {{d.fieldValue}}<span>,</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="widget-content" id="{{this.canvasId}}"></div>
    </div>

</div>


<div id="download">
    <img #canvas>
    <a #downloadLink></a>
</div>