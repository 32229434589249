import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { Widget } from 'src/app/models/responses/widgetLibraryResponse';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';

@Component({
  selector: 'app-expanded-widget',
  templateUrl: './expanded-widget.component.html',
  styleUrls: ['./expanded-widget.component.scss']
})
export class ExpandedWidgetComponent implements OnInit {
  @ViewChild('widgetContent') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  downloadLoading: boolean = false

  showFilter:boolean=false

  groupedData = {};
  groupedDataKeys: string[] = []

  canvasId: string = "id";
  widgetData: any;
  permissions: string[] = []
  widget: Widget;
  coreWidgetId: string = ''
  constructor(
    public dialogRef: MatDialogRef<ExpandedWidgetComponent>,
    private scriptLoader: ScriptLoaderService, private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        widgetData: any,
        shareModalOpen: Function,
        selectedFilters: BussionFilterDefinition[],
        selectedDashboard: Dashboard
      }
  ) { }

  ngOnInit(): void {
    if (this.data.widgetData) {
      this.widgetData = Object.assign({}, this.data.widgetData);
      this.widget = this.data.widgetData.widget;
      this.coreWidgetId = this.widget.widgetId
      this.createWidget();
    }
    this.permissions = this.authService.getPermissions()
    this.groupFilters()
  }

  private createWidget() {
    this.widgetData.widget.widgetId = "widget-" + this.widgetData.widget.widgetId;
    this.widgetData.widgetId = this.widgetData.widget.widgetId;
    this.canvasId = this.widgetData.widget.widgetId
    this.scriptLoader.addScriptFileToHead(this.widgetData.widgetLibraries);
    this.scriptLoader.runWidgetCode(this.widgetData);
  }


  action(type: boolean): void {
    this.dialogRef.close(type);
  }


  shareWidget() {
    if (this.data.shareModalOpen)
      this.data.shareModalOpen({ objectId: this.coreWidgetId, objectType: 'Widget' })
  }

  downloadWidget(widget: Widget) {
    html2canvas(this.screen.nativeElement).then(canvas => {


      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = widget.title + '.png';
      this.downloadLink.nativeElement.click();
      this.downloadLoading = true

    }).finally(() => {

      this.downloadLoading = false

    });
  }

  groupFilters() {
    this.data.selectedFilters.forEach(item => {
      var visibleName = this.data.selectedDashboard.dimensions.find(x => x.name == item.fieldName)?.visibleName ?? item.fieldName
      if (!this.groupedData[visibleName]) {
        this.groupedData[visibleName] = {};
      }



      // this.groupedData[item.fieldName].push(item);

      if (!this.groupedData[visibleName][item.operator]) {
        this.groupedData[visibleName][item.operator] = [];
      }
      this.groupedData[visibleName][item.operator].push(item);

    });


    this.groupedDataKeys = Object.keys(this.groupedData)
  }

  DownloadFilter(checkbox) {
    this.showFilter = checkbox.checked
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

}
