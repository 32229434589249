<div class="dialog-header">
    <div class="dialog-title">
        <div class="img-section">
            <img [src]="img" />
        </div>
        <div class="text-section">
            <div>{{title|translate}}</div>
            <small>{{subtitle|translate}}</small>
        </div>
    </div>
    <div>
        <ng-content select="[header-buttons]"></ng-content>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer;">
        <mat-icon (click)="closeDialog()">clear</mat-icon>
    </div>



</div>
<div class="dialog-body" [style.Height]="!ref.innerHTML.trim()?'90%':'80%'">
    <ng-content select="[body]"></ng-content>
</div>
<div class="dialog-actions" #ref>
    <ng-content select="[footer]"></ng-content>
</div>