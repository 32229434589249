import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { DataStoreResponse, DataStore } from 'src/app/models/responses/dataStoreResponse';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getDatasources() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getDataStore, param).pipe(
      map((response: DataStoreResponse) => {
        return response.message;
      })
    );
  }

  getDatasourceById(datastoreId:string){
    let param = { Token: this.authService.getToken(),DSId:datastoreId };
    return this.http.post(Endpoints.getDataStoreById, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getDataSourceForUser() {
    let param = {
      Token: this.authService.getToken()
    };
    return this.http.post(Endpoints.getDataStoreForUser, param).pipe(
      map((response: DataStoreResponse) => {
        return response.message;
      })
    );
  }

  upsertDataStore(dataStore: DataStore) {
    let param = {
      Token: this.authService.getToken(),
      DataStore: dataStore
    };
    return this.http.post(Endpoints.upsertDataStore, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  deleteDataStore(dataStore: DataStore) {
    let param = {
      Token: this.authService.getToken(),
      DataStoreId: dataStore.dataStoreId
    };
    return this.http.post(Endpoints.deleteDataStore, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }


  getUniverses(host: String) {
    let param = {
      Token: this.authService.getToken(),
      Node: host
    };
    return this.http.post(Endpoints.getUniverses, param).pipe(
      map((response: any) => {
        return response.message as string[];
      })
    );
  }

  getCollections(host: String, universe: string) {
    let param = {
      Token: this.authService.getToken(),
      Node: host,
      Universe: universe
    };
    return this.http.post(Endpoints.getCollections, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getColumns(host: String, universe: string, collection: string) {
    let param = {
      Token: this.authService.getToken(),
      Node: host,
      Universe: universe,
      Collection: collection
    };
    return this.http.post(Endpoints.getColumns, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getColumnsByNodeId(host: String, universe: string, collection: string) {
    let param = {
      Token: this.authService.getToken(),
      Node: host,
      Universe: universe,
      Collection: collection
    };
    return this.http.post(Endpoints.getColumnsByNode, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getColomnsForDataStore(dataStoreId: string) {
    let param = {
      Token: this.authService.getToken(),
      DataStoreId: dataStoreId
    };
    return this.http.post(Endpoints.getColumnsForDataSourceById, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getValuesForField(dataStoreId: string, fieldName: string, searchValue: string) {
    let param = {
      Token: this.authService.getToken(),
      DataStoreId: dataStoreId,
      FieldName: fieldName,
      SearchValue: searchValue
    };
    return this.http.post(Endpoints.getValuesForField, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getValuesForFieldByUniverse(node: string, universe: string, collection: string, fieldName: string, searchValue: string) {
    let param = {
      Token: this.authService.getToken(),
      Node: node,
      Universe: universe,
      Collection: collection,
      FieldName: fieldName,
      SearchValue: searchValue
    };
    return this.http.post(Endpoints.getValuesForFieldByUniverse, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  notifyDatastore(dataStoreId: string) {
    let param = {
      Domain: this.authService.getUserDomain(),
      DSId: dataStoreId
    };
    return this.http.post(Endpoints.NotifyDataStore, param).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getSampleData(DatastoreId: string, sampleDataCount?: number) {
    let param = {
      Token: this.authService.getToken(),
      DataStoreId: DatastoreId,
      SampleCount: sampleDataCount ?? 50
    }
    return this.http.post(Endpoints.GetSampleDataForDataStore, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }


  createIndex(DatastoreId: string, Fields: string[]) {
    let param = {
      Token: this.authService.getToken(),
      DataStoreId: DatastoreId,
      Fields: Fields
    }
    return this.http.post(Endpoints.CreateIndex, param).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getIndexColumns(DatastoreId: string) {
    let param = {
      Token: this.authService.getToken(),
      DataStoreId: DatastoreId
    }
    return this.http.post(Endpoints.GetIndex, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }


  DropIndex(DatastoreId: string, Fields: string[]) {
    let param = {
      Token: this.authService.getToken(),
      DataStoreId: DatastoreId,
      Fields: Fields
    }
    return this.http.post(Endpoints.DropIndex, param).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

}
