<div style="height: 100%;"   [id]="contentIdKeyword+appId">

    <div>
        <app-header [disableUserMenu]="ismodal?true:false" *ngIf="!showGrid" [disableMic]="true"
            [navigateTo]="appLink"></app-header>
    </div>


    <div *ngIf="data?.app?.appType==0" class="application-dashboard">
        <div *ngIf="showGrid" class="dasboard-grid-area">
            <!-- <app-gridster [dashboard]="selectedDashboard" [dashboardWidgets]="dashboardWidgets">
        </app-gridster> -->
            <app-main [appId]="data.app.applicationId" (returnApp)="returnApp()"></app-main>

        </div>
        <div class="top-section" *ngIf="!showGrid">


            <div class="app-info-container">
                <div class="app-info">
                    <div class="app-img-name">
                        <img [src]="env+data.app.icon" />
                    </div>
                    <div
                        style="display: flex;flex-direction: column;justify-content: space-between;margin-left: 30px;width: 80%;">
                        <div style="max-height: 90%;">
                            <div class="app-name">{{data.app.name}}</div>
                            <p class="app-desc">
                                {{data.app.description}}
                            </p>
                        </div>
                        <div class="app-info-detail">

                            <div>
                                <mat-icon svgIcon="DateTime"></mat-icon>
                                <span>{{data.app.creationDate.split(' ')[0]}}</span>
                            </div>
                            <div>
                                <mat-icon svgIcon="person"></mat-icon>
                                <span>{{data.app.owner}}</span>
                            </div>
                            <div>
                                <mat-icon svgIcon="dashboards"></mat-icon>
                                <span>{{dashboardList.length}} {{"AppPage.Dashboards"|translate}}</span>
                            </div>
                            <div>
                                <mat-icon svgIcon="chart2"></mat-icon>
                                <span>{{widgetCount}} {{"AppPage.Widgets"|translate}}</span>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
        <div class="bottom-section" *ngIf="!showGrid">
            <div style="width: 100%;">
                <div class="search-title">
                    <h3>{{"AppPage.DashboardList"|translate}}</h3>
                    <div style="width: 40%;">
                        <app-search-input (changeText)="searchTextChange($event)"></app-search-input>
                    </div>
                </div>
                <div class="list-area">
                    <div class="list-flex">
                        <div class="dashboard-card" (click)="changeSelectedDashboard(item)"
                            *ngFor="let item of filteredDashboardList">
                            <div class="dasboard-icon">
                                <img [src]="env+item.icon" />
                            </div>
                            <div class="headerdivider"></div>
                            <div class="dashboard-title">
                                <p class="text-continer">{{item.title}}</p>
                                <p class="dashboard-desc">{{item.description}}</p>
                                <div class="tags" *ngIf="item.tags.length>0">
                                    <div class="tag-badge" *ngFor="let tg of item.tagList">{{tg}}</div>
                                </div>
                            </div>
                            <div class="headerdivider"></div>
                            <div class="info-section">
                                <div>
                                    <mat-icon svgIcon="DateTime"></mat-icon>
                                    <span>{{item.creationDate.split(' ')[0]}}</span>
                                </div>
                                <div>
                                    <mat-icon svgIcon="person"></mat-icon>
                                    <span>{{item.owner}}</span>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
        <!-- <div class="info-area">
        <div class="info">
            <div class="app-info">
                <img [src]="env+data.app.icon" />
                <h3>{{data.app.name}}</h3>
            </div>
            <div class="desc-text">
                {{data.app.description}}
            </div>
        </div>
        <div class="dashboard-menu-area">
            <div *ngFor="let item of dashboardList" class="dashboard-menu" (click)="changeSelectedDashboard(item)">
                <div class="img-container">
                    <img [src]="env+item.icon" />
                </div>
                <div class="text-continer">{{item.title}}</div>
            </div>
        </div>
    </div> -->
        <!-- <div class="left-area">
        <div class="hello-section">
        </div>
        <div class="dasboard-grid-area">
            <app-gridster [dashboard]="selectedDashboard" [dashboardWidgets]="dashboardWidgets">
            </app-gridster>

        </div>

    </div> -->
    </div>

</div>