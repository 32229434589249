<app-custom-modal [title]="'DataStoreFormPage.IndexFormTitle'" [subtitle]="'DataStoreFormPage.IndexFormSubtitle'"
    img="/assets/images/hashtag.png">
    <div body class="ds-indexform-body" [style.position]="loadingDatastores?'relative':''">
        <app-custom-loading *ngIf="loadingDatastores"></app-custom-loading>

        <form class="ds-info-form" *ngIf="!loadingDatastores">
            <mat-form-field>
                <mat-label>{{ 'DataStoreFormPage.Name' | translate }}</mat-label>
                <input matInput [(value)]="data.dataStore.name" required disabled="true">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'DataStoreFormPage.Type' | translate }}</mat-label>
                <input matInput [(value)]="data.dataStore.type" required disabled="true">

            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'DataStoreFormPage.Universes' | translate }}</mat-label>
                <input matInput [(value)]="data.dataStore.universe" required disabled="true">

            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'DataStoreFormPage.Collections' | translate }}</mat-label>
                <input matInput [(value)]="data.dataStore.collection" required disabled="true">

            </mat-form-field>


            <div style="display: flex;justify-content: space-between; width: 100%;">
                <div style="flex-basis: 45%;">
                    <span style="font-size: 16px;font-weight: bold;color: rgb(138,138,138);margin-bottom: 5px;">{{
                        'DataStoreFormPage.Columns' | translate }}</span>
                    <mat-selection-list 
                        style="height: 194px;overflow: auto;" [compareWith]="compareFunctionForSelectList"
                        (selectionChange)="selectionChangeSelect($event)">
                        <mat-list-option [selected]="false" [value]="column.fieldName"
                            *ngFor="let column of columnsForList" >
                            <div style="line-height: 1.3;display: flex;flex-direction: column;">
                                {{column.fieldName}}
                                <small style="color: rgb(138,138,138);font-size: 8px;">{{column.fieldType}}</small>
                            </div>

                        </mat-list-option>
                    </mat-selection-list>
                </div>
                <div style="flex-basis: 45%;">

                    <span style="font-size: 16px;font-weight: bold;color: rgb(138,138,138);margin-bottom: 5px;">{{
                        'DataStoreFormPage.IndexedColumns' | translate }}</span>
                    <mat-selection-list 
                        style="height: 194px;overflow: auto;" [compareWith]="compareFunctionForSelectList"
                        (selectionChange)="selectionChangeUnSelect($event)">
                        <mat-list-option [selected]="true"  [value]="column.fieldName"
                            *ngFor="let column of indexedColumnsForList">
                            <div style="line-height: 1.3;display: flex;flex-direction: column;">
                                {{column.fieldName}}
                                <small style="color: rgb(138,138,138);font-size: 8px;">{{column.fieldType}}</small>
                            </div>

                        </mat-list-option>
                    </mat-selection-list>
                </div>
            </div>




        </form>
        <div style="display: flex;justify-content: end;">
            <button mat-dialog-close mat-stroked-button class="custom-primary-stroked-button"
                style="margin-left: 10px;">
                <span> {{ 'Common.Cancel' | translate }}</span>
            </button>
            <button mat-raised-button class="custom-primary-button" style="margin-left: 10px;"
                (click)="createIndexes()">
                <mat-icon>save</mat-icon>
                <span> {{ 'Common.Save' | translate }}</span>
            </button>
        </div>
    </div>


</app-custom-modal>