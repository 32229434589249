<div style="height: 85%;">
    <div style="display: flex;align-items: center;justify-content: end;">
        <div style="margin-right: 20px;width: 35%;">
            <app-search-input (changeText)="searchData($event)" [height]="'36px'"></app-search-input>
        </div>
        <button (click)="routeToDesigner()" mat-raised-button class="custom-primary-button button-md w-100 mt-10">
            <mat-icon>add</mat-icon>
            <span>{{'FlowPage.AddFlow'|translate}}</span>
        </button>
    </div>
    <div  class="cards-section">
        <app-card-with-actions *ngFor="let item of paginatedFlowList" [item]="item" [keys]="cardKeys"
            [imageAvatarKeys]="imageAvatarKeys" [icons]="createCardInfo(item.ownerId)"></app-card-with-actions>
    </div>

</div>
<div style="height: 15%;">
    <mat-paginator [length]="pageVariables.listLength" [pageSize]="pageVariables.itemPerPage"
        (page)="handlePageEvent($event)"  [pageSizeOptions]="[]" aria-label="Select page">
    </mat-paginator>
</div>