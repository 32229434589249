import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ModalService } from 'src/app/services/modal/modal.service';
import { CreateUserComponent } from 'src/app/pages/manage/user/create-user/create-user.component';
import { CoreService } from 'src/app/services/core/core.service';
import { User } from 'src/app/models/responses/loginResponse';
import { UserService } from 'src/app/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ChatbotComponent } from '../chatbot/chatbot.component';
import { MatDialog } from '@angular/material/dialog';
import { ChatbotService } from 'src/app/services/chatbot/chatbot.service';
import { SpeechProcces } from 'src/app/core/speechTypes';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  firstName: string = "";
  lastName: string = "";
  avatar: string = "";
  @Input() disableMic:boolean=false
  @ViewChild('sidenav') sidenav: MatSidenav;
  @Input() disableUserMenu:boolean=false
  
  selectedDashboard: any
  permissions: string[] = []
  visibleManagement:boolean=false
  mic: boolean = false
  micenable:boolean=true
  roles:string[]=this.authService.getRoles()
  @Input() navigateTo:string='/'

  functionList = [
    { type: SpeechProcces.OpenDashboard, function: (event) => this.openDashboard(event) },
    { type: SpeechProcces.AssistantOpen, function: (event) => this.openChatbox() }
  ]

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private coreService: CoreService,
    private userService: UserService,
    private translate: TranslateService,
    private router: Router,
    private dashboardService: DashboardService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.firstName = this.authService.getUserFirstName();
    this.lastName = this.authService.getUserLastName();
    this.avatar = environment.imageUrl + this.authService.getUserAvatar();
    this.dashboardService.selectedDasboard.subscribe((res => {
      this.selectedDashboard = res
    }))
    this.permissions = this.authService.getPermissions()

    if(this.permissions.some(x=>x.toLowerCase().includes('management'))){
      this.visibleManagement=true
    }else{
      this.visibleManagement=false
    }

    console.log('this.permissions',this.permissions)
    if ('SpeechRecognition' in window|| 'webkitSpeechRecognition' in window) {
      this.micenable=true
    }else{
      this.micenable= false
    }
  }

  openSelfServisMain() {
    this.router.navigate(['selfservice'])
  }

  logout() {
    this.authService.logout().subscribe(result => {
      this.dashboardService.setCacheData({ authorizedDashboard: [], activeDashboard: [] })
      
    });
  }

  goManage() {
    // this.router.navigate(['/manage/user'])
    this.router.navigate(['/landing'])
  }

  showUserInfo() {
    let data = {
      type: "update",
      user: this.authService.getUser() as User,
      ownUser: true
    }
    this.modalService.openModal(data, CreateUserComponent, 'auto', '1000px').subscribe(result => {
      if (result) {
        this.getUserInfo();
        // console.log(result)
        // this.coreService.showSuccessMessage(result);
      }
    });
  }

  getUserInfo() {
    this.userService.getUserById(this.authService.getUserId()).subscribe(user => {
      this.authService.saveUserInfos(user);
      this.firstName = this.authService.getUserFirstName();
      this.avatar = environment.imageUrl + this.authService.getUserAvatar();
      // if (user.language == 'English') {
      //   this.translate.use('en');
      // } else {
      //   this.translate.use('tr');
      // }
    })
  }


  openDashboard(text: string) {
    var selecteddashboard = this.dashboardService.cachedData.getValue().authorizedDashboard.find(x => x.title.toLocaleLowerCase().includes(text.toLocaleLowerCase()))
    if (selecteddashboard)
      this.router.navigate(["/main"], { state: selecteddashboard });
  }

  openChatbox() {
    this.dialog.open(ChatbotComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'chat-dialog-container',
    });
  }



}
