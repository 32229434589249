import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { customBadgeProps } from 'src/app/models/globals/customDropdownProps';


@Component({
  selector: 'app-custom-badge-with-clear',
  templateUrl: './custom-badge-with-clear.component.html',
  styleUrls: ['./custom-badge-with-clear.component.scss']
})
export class CustomBadgeWithClearComponent implements OnInit {

  @Input() badges: customBadgeProps[] = []
  @Output() editSave: EventEmitter<any> = new EventEmitter<any>()

  inputvalue: string 
  constructor() { }

  ngOnInit(): void {
  }

  inputOpen(id) {
    this.inputvalue=id
    document.getElementById('input-' + id).style.width = id.length * 8 + 'px'
    document.getElementById('input-' + id).style.display = 'block'
    document.getElementById('open-' + id).style.display = 'none'
    document.getElementById('save-' + id).style.display = 'block'
    document.getElementById('label-' + id).style.display = 'none'


  }

  save(item) {
    document.getElementById('input-' + item.name).style.display = 'none'
    document.getElementById('open-' + item.name).style.display = 'block'
    document.getElementById('save-' + item.name).style.display = 'none'
    document.getElementById('label-' + item.name).style.display = 'block'
    this.editSave.emit({item:item,inputValue:this.inputvalue})
  }

}
