import { Component, OnInit, ViewChild } from '@angular/core';
import { NodeService } from 'src/app/services/node/node.service';
import { Node } from 'src/app/models/responses/nodeResponse';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActionDialogComponent } from 'src/app/components/action-dialog/action-dialog/action-dialog.component';
import { NodeFormComponent } from './node-form/node-form.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss']
})
export class NodeComponent implements OnInit {

  displayedColumns: string[] = ['icon', 'name', 'nodeId', 'host', 'port', 'action'];
  displayedColumnsName: string[] = [this.translate.instant("Common.Icon"), this.translate.instant("NodePage.Name"), this.translate.instant("NodePage.Id"), this.translate.instant("NodePage.Host"), this.translate.instant("NodePage.Port"), this.translate.instant("NodePage.Action")];
  nodes: Node[] = [];

  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private nodeService: NodeService,
    private modalService: ModalService,
    private translate: TranslateService,
    private rouuter: Router
  ) { }

  ngOnInit(): void {
    this.getNodes();
  }

  getNodes() {
    this.spinner.show();
    this.nodeService.getNodes().subscribe(result => {
      this.nodes = result.map(item => {
        return { ...item, icon: item.port == '7000' ? '/assets/images/icons/node7000.jpeg' : '/assets/images/icons/node.jpeg' }
      });
      this.spinner.hide();
    });
  }

  deleteNodeComponent(node: Node) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.nodeService.deleteNode(node).subscribe(result => {
          this.getNodes();
          this.spinner.hide();
        });
      }
    });
  }

  openNode(event: any) {
    let data = {
      type: event.type as string,
      node: event.data as Node
    }
    this.modalService.openModal(data, NodeFormComponent, 'auto', '1000px').subscribe(result => {
      if (result === true) {
        this.getNodes();
      }
    });
  }


  gotoArchPage() {
    this.rouuter.navigate(['/landing/architecture'])
  }

}
