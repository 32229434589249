import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { Widget } from 'src/app/models/responses/widgetLibraryResponse';
import { CoreService } from 'src/app/services/core/core.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-self-service-dashboard-form',
  templateUrl: './self-service-dashboard-form.component.html',
  styleUrls: ['./self-service-dashboard-form.component.scss']
})
export class SelfServiceDashboardFormComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      type: string,
      widget: Widget[],
      dashboard: Dashboard[]
    },
    private coreService: CoreService,
    private translate: TranslateService,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    private dialogRef: MatDialogRef<SelfServiceDashboardFormComponent>,
  ) {

  }
  pageForm: UntypedFormGroup;
  dashboard: Dashboard = {
    title: "",
    icon: "",
    dashboardStatus: 1,
    order: 1,
    description: "",
    layoutConfig: "",
    domain: "",
    access: 2,
    owner: "",
    creationDate: new Date(),
    updatedBy: "",
    updateDate: new Date(),
    colorPalette: "",
    filter: [],
    widgets: this.data.widget ?? [],//Widget,
    dimensions: [], //Dimension,
    messages: [], //BussionMessage
    auditEnabled: 0,
    autoRefresh: 1,
    refreshInterval: 30,
    isMainDashboard: 1,
    hideBorders: 'false',
    gridSize: 6,
    tags: "",
    css: "",
    isSelfService: 1,
    contentLanguage: '',
    hideAdvancedQueryPanel: 0,
    layout: '',
    colors: []
  }
  compareFunctionForLanguage = (o1: any, o2: any) => o1 === o2.toString();

  ngOnInit(): void {
    if (this.data.dashboard) {
      Object.assign(this.dashboard, this.data.dashboard)
    }

    this.pageForm = new UntypedFormGroup({
      title: new UntypedFormControl(this.dashboard.title, [Validators.required]),
      description: new UntypedFormControl(this.dashboard.description, [Validators.required]),
      contentLanguage: new UntypedFormControl(this.dashboard.description, [Validators.required]),
    });

  }
  saveDashboard() {
    if (this.pageForm.invalid) {
      this.coreService.showFormFillError();
      return;
    }
    this.spinner.show();

    this.dashboard.widgets = this.data.widget
    this.dashboard.icon = this.dashboard.icon || '/BussionImages/Dashboard/Dashboard.png'

    this.dashboardService.upsertDashboard(this.dashboard).subscribe(result => {
      this.spinner.hide();
      this.dialogRef.close(result);
    });
  }

  uploadIconImage(fileInput: any) {
    let file = <File>fileInput.target.files[0];
    if (file.size > 3145728) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.SmallPhoto"));
      return;
    }
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.WrongFormatPhoto"));
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.dashboard.icon = reader.result;
    }
  }
}
