<div class="exit-button" mat-dialog-close>X</div>
<div class="shareform-container">
    <div>
        <p class="text-h5">{{data.title|translate}}</p>
        <p class="text-thin">{{'UserCard.FindPeople'|translate}} </p>
        <app-search-input (changeText)="serversideSearch($event)" (filterData)="filterData($event)" [datas]="allUsers"
            [searchColumns]="searchColumns"></app-search-input>
    </div>
    <div class="user-list-container" *ngIf="FilteredUsers.length>0||selectedUserList.length>0">
        <div *ngIf="visibleSelected">
            <app-user-card (selectUser)="selectUser($event)" [user]="item"
                *ngFor="let item of selectedUserList"></app-user-card>
        </div>
        <div *ngIf="!visibleSelected">
            <app-user-card (selectUser)="selectUser($event)" [user]="item"
                *ngFor="let item of FilteredUsers"></app-user-card>
        </div>

    </div>
    <div class="anim-container" *ngIf="FilteredUsers.length<=0&&selectedUserList.length<=0">
        <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_rlmdrwm8.json" background="transparent"
            speed="1" style="width: 300px; height: 300px;" loop autoplay></lottie-player>
        <span>{{'UserCard.SearchPeople'|translate}}</span>
    </div>



</div>
<div class="user-modal-footer">
    <button mat-button (click)="saveSharedUsers()">
        {{'Save'|translate}}
    </button>
</div>