<div class="content">
    <app-title title="{{ 'LicensePage.Title' | translate }}"></app-title>
    <div>
        <div>
            <mat-form-field>
                <mat-label>{{ 'LicensePage.Customer' | translate }}</mat-label>
                <input matInput [(ngModel)]="license.customer" disabled>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'LicensePage.Domain' | translate }}</mat-label>
                <input matInput [(ngModel)]="license.domain" disabled>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'LicensePage.Host' | translate }}</mat-label>
                <input matInput [(ngModel)]="license.host" disabled>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'LicensePage.Scope' | translate }}</mat-label>
                <input matInput [(ngModel)]="license.scope" disabled>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'LicensePage.Type' | translate }}</mat-label>
                <input matInput [(ngModel)]="license.type" disabled>
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>{{ 'LicensePage.Modules' | translate }}</mat-label>
                <input matInput [(ngModel)]="license.bussionModules" disabled>
            </mat-form-field>
            <br>
            <div style="display: flex;gap: 10px;">
                <mat-form-field>
                    <mat-label>{{ 'LicensePage.adminUserCount' | translate }}</mat-label>
                    <input matInput [(ngModel)]="license.adminUserCount" disabled>
                </mat-form-field>
                
                <mat-form-field>
                    <mat-label>{{ 'LicensePage.UserCount' | translate }}</mat-label>
                    <input matInput [(ngModel)]="license.userCount" disabled>
                </mat-form-field>
            </div>

            <br>

            <mat-form-field>
                <mat-label>{{ 'LicensePage.DashboardCount' | translate }}</mat-label>
                <input matInput [(ngModel)]="license.dashboardCount" disabled>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'LicensePage.LicenseDate' | translate }}</mat-label>
                <input matInput [(ngModel)]="license.licenseDate" disabled>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'LicensePage.LicenseKey' | translate }}</mat-label>
                <textarea matInput [(ngModel)]="license.licenseKey" required></textarea>
            </mat-form-field>
            <br>
        </div>
    </div>
    <button mat-button class="b-btn b-success" (click)="upsertLicense()">{{ 'Common.Update' | translate }}</button>
</div>