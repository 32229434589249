<app-custom-modal *ngIf="!_notDialog" [title]="title" [subtitle]="'WidgetFormPage.widgetformtext'"
    img="/assets/images/widgetSelection.png">
    <div header-buttons style="display: flex;">
        <div class="preview-button-2">
            <div (click)="ChangePreviewScreen('data')" [ngClass]="{'active': this.selectedPreviewScreen=='data'}">
                {{ "WidgetFormPage.DataPreview" |translate}}
            </div>
            <div (click)="ChangePreviewScreen('widget')" [ngClass]="{'active': this.selectedPreviewScreen=='widget'}">
                {{"WidgetFormPage.ChartPreview" | translate}}
            </div>
        </div>
        <div style="display: flex;align-items: center;">
            <mat-slide-toggle (toggleChange)="toggleChange()">
                <small>
                    {{"WidgetFormPage.DisablePreview" |translate}}
                </small>
            </mat-slide-toggle>
        </div>
        <!-- <div class="preview-button-3">
            <div>
                <img src="/assets/images/icons/phone.svg" />
            </div>
            <div class="active">
                <img src="/assets/images/icons/iMac.svg" />
            </div>
            <div>
                <img src="/assets/images/icons/iPad.svg" />
            </div>

        </div> -->
    </div>
    <div class="widgetform-container" body>
        <div class="widgetform-cols">
            <div style="flex-basis: 25%;overflow: auto;padding-right: 20px;padding-left: 10px;min-width: 25%;">
                <div class="mini-action-section">
                    <div (click)="expandAllSettings()">
                        <mat-icon>fullscreen_exit</mat-icon>
                        <small>{{"Common.ExpandAll"|translate}}</small>
                    </div>
                    <div (click)="clearAllSelection()">
                        <mat-icon svgIcon="clear_all"></mat-icon>
                        <small>{{"Common.ClearAll"|translate}}</small>
                    </div>

                </div>

                <app-setting-section *ngFor="let item of allDropdownSettings"
                    (setSelectedSettingAreaFunction)="setSelectedSettingArea($event)"
                    [selectedSettingArea]="selectedSettingArea" [dropdownMenuData]="item.data"
                    [dropdownSettings]="item.setting" [tabs]="item?.tabs">
                    <div class="query-box" *ngIf="item.id=='aggregationSettings'" underbox>
                        <mat-checkbox class="example-margin query-checkbox" [checked]="useDirectQuery"
                            (change)="checkDirectQuery($event.checked)">
                            {{"WidgetFormPage.UseQuery"}}
                        </mat-checkbox>
                        <!-- <button *ngIf="useDirectQuery" (click)="openSqlCodeEditor()" mat-stoked-button
                                class="custom-primary-stroked-button sm-button"> -->
                        <img *ngIf="useDirectQuery" (click)="openSqlCodeEditor()" style="cursor: pointer;" width="30px"
                            src="/assets/images/sql.png" />
                        <!-- </button> -->
                    </div>
                </app-setting-section>

                <ng-template #calculated>
                    <form [formGroup]="calculatedForm" class="mb-10">
                        <mat-form-field>
                            <mat-label>{{ 'ScriptForm.FormulaName' | translate }}</mat-label>
                            <input matInput formControlName="name" (focusout)="controlNameForCalculated()" />
                        </mat-form-field>
                        <mat-error
                            *ngIf="calculatedFieldNameError">{{'ScriptForm.CalculatedError'|translate}}</mat-error>
                        <mat-form-field>
                            <mat-label>{{ 'ScriptForm.Formula' | translate }}</mat-label>
                            <textarea (focus)="optionselection.open()" matInput formControlName="formula"></textarea>
                        </mat-form-field>
                        <mat-select class="calculatedselect" (selectionChange)="onOptionSelected($event)"
                            #optionselection>
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
                                {{option.value}}
                            </mat-option>
                        </mat-select>
                        <small style="color: gray;">*{{"WidgetFormPage.CalculatedFieldDesc"|translate}}</small>
                        <div style="display: flex;justify-content: end;">
                            <button (click)="calculatedFieldUpdate?updateCalculatedField():AddCalculatedField()"
                                mat-stroked-button class="custom-primary-stroked-button sm-button">
                                {{calculatedFieldUpdate?( 'Common.Update' | translate):( 'Common.Add' | translate)}}
                            </button>
                        </div>

                    </form>
                    <div>
                        <div class="calculatedfield-elemeent" *ngFor="let item of calculatedFieldList">
                            <div class="calculated-text">
                                <div style="color:#5E6DA6;max-width: 100%;overflow: hidden;
                                    text-overflow: ellipsis;">{{item.name}} : <span
                                        style="color: gray;">{{item.formula}}</span></div>

                            </div>
                            <div style="display: flex;align-items: center;justify-content: center;">
                                <mat-icon (click)="editCalculatedField(item)"
                                    style="transform: scale(0.8);">edit</mat-icon>
                                <mat-icon (click)="deleteCalculatedField(item)"
                                    style="transform: scale(0.9);">clear</mat-icon>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template #paint>
                    <app-custom-color-picker [selectedColorsForUpdate]="this.data?.widget?.colors"
                        (selectColor)="addColorRule($event)"
                        [widgetData]="{data:widgetPreviewData.widgetData,dimension:widget.dimensions}"
                        (getPaletColors)="getPaletColors($event)" ></app-custom-color-picker>
                </ng-template>


            </div>
            <div style="flex-basis: 75%;position: relative;width: 100%;min-width: 70%;" class="widget-preview-area">
                <!-- <app-custom-loading *ngIf="loadingPreview"></app-custom-loading> -->
                <div *ngIf="selectedPreviewScreen=='widget'"
                    style="height: 10%;display: flex;align-items: center;justify-content: space-between;">

                    <app-custom-badge-with-clear (editSave)="changeMeasureLabel($event)"
                        [badges]="infoBadgeList"></app-custom-badge-with-clear>
                    <div style="display: flex;">
                        <app-image-selectbox></app-image-selectbox>
                        <div class="filter-container">
                            <div class="advanced-code-button preview-button-2">
                                <div style="position: relative;" (click)="closeFilterScreen()"
                                    [ngClass]="{'active': filterScreen}">
                                    <img width="20px" src="/assets/images/icons/filter-colored.png" />
                                </div>
                            </div>
                            <div class="filter-area" *ngIf="filterScreen">
                                <div class="flex-end"
                                    style="position: absolute;right: 10px;z-index: 1000;top: 10px;cursor: pointer;">
                                    <mat-icon (click)="closeFilterScreen('close')">clear</mat-icon>
                                </div>
                                <div>
                                    <mat-tab-group>
                                        <mat-tab [label]="'Common.Filters'|translate">
                                            <label class="switch" style="padding: 10px;">
                                                <input [(ngModel)]="freezeFilter" class="example-margin" type="checkbox"
                                                    checked>
                                                <span>
                                                    <em></em>
                                                    <strong></strong>
                                                    {{'WidgetFormPage.FreezeFilter' | translate}}
                                                </span>
                                            </label>
                                            <div
                                                style="display: grid; grid-template-columns: 1fr;margin-top: 1rem;padding: 10px;">
                                                <div style="grid-row-start: 1; grid-column-start: 1;">
                                                    <app-filter-form [clearButton]="true" *ngIf="isShownQueryForm"
                                                        [fields]="dataStoreColumnsForFilter" (add)="addFilter($event)">
                                                    </app-filter-form>
                                                    <div
                                                        style="margin-bottom: 16px;display: flex;align-items: center;justify-content: end;">
                                                        <button *ngIf="widget.filter.length > 0" mat-button
                                                            class="b-btn" (click)="applyFilter(true)">
                                                            {{ 'Common.Apply' |translate }}
                                                        </button>
                                                    </div>

                                                    <div class="filter-list-content" *ngIf="widget.filter.length > 0">
                                                        <app-filter-list [scrollarea]="true" [data]="widget.filter"
                                                            (delete)="deleteQuery($event)"></app-filter-list>
                                                    </div>
                                                    <br>
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <mat-tab [label]="'WidgetFormPage.IgnoreFilter'|translate">
                                            <div style="padding: 10px;">
                                                <form [formGroup]="ignoreForm" class="mb-10"
                                                    style="display: flex;align-items: center;gap: 10px;">
                                                    <mat-form-field>
                                                        <mat-label>{{ 'WidgetFormPage.IgnoreKey' | translate
                                                            }}</mat-label>
                                                        <input (keypress.enter)="addIgnoreKey()" matInput
                                                            formControlName="ignoreKey" />
                                                    </mat-form-field>
                                                    <div style="display: flex;justify-content: end;">
                                                        <button mat-stroked-button (click)="addIgnoreKey()"
                                                            class="custom-primary-stroked-button sm-button">
                                                            {{'Common.Add' | translate}}
                                                        </button>
                                                    </div>
                                                </form>
                                                <div *ngIf="widget.ignoreFilterList.length>0"
                                                    style="display: flex;flex-wrap: wrap;gap: 10px;">
                                                    <div *ngFor="let item of widget.ignoreFilterList"
                                                        class="ignorekeybadge">
                                                        <div class="ignorekey" [matTooltip]="item">{{item}}</div>
                                                        <mat-icon (click)="deleteIgnoreKey(item)" svgIcon="delete-box"
                                                            class="mr-10"></mat-icon>
                                                    </div>
                                                </div>

                                            </div>

                                        </mat-tab>
                                        <mat-tab [label]="'WidgetFormPage.JoinFilter'|translate">
                                            <div style="padding: 10px;">
                                                <form [formGroup]="joinForm" class="mb-10"
                                                    style="display: flex;align-items: center;gap: 10px;">
                                                    <mat-form-field>
                                                        <mat-label>{{ 'WidgetFormPage.Source' | translate
                                                            }}</mat-label>

                                                        <input matInput formControlName="source" />

                                                    </mat-form-field>
                                                    <mat-form-field>
                                                        <mat-label>{{ 'WidgetFormPage.Destination' | translate
                                                            }}</mat-label>

                                                        <mat-select formControlName="destination">
                                                            <mat-option
                                                                *ngFor="let option of dataStoreColumnsForDropdownMenu"
                                                                [value]="option.id">
                                                                {{option.id}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <div style="display: flex;justify-content: end;">
                                                        <button mat-stroked-button (click)="addJoinList()"
                                                            class="custom-primary-stroked-button sm-button">
                                                            {{'Common.Add' | translate}}
                                                        </button>
                                                    </div>
                                                </form>
                                                <div *ngIf="widget.widgetJoin.length>0"
                                                    style="display: flex;flex-wrap: wrap;gap: 10px;">
                                                    <div *ngFor="let item of widget.widgetJoin" class="ignorekeybadge">
                                                        <div class="ignorekey"
                                                            style="display: flex;align-items: center;width: 80%;justify-content: space-between;">
                                                            <span
                                                                style="display:flex ;align-items: center;flex-direction: column;max-width: 40%;">
                                                                <span class="text-overflow"> {{'WidgetFormPage.Source'|
                                                                    translate}}</span>
                                                                <!-- <mat-icon
                                                                    style="margin-right: 10px;">arrow_right_alt</mat-icon> -->
                                                                <small class="text-overflow"
                                                                    [matTooltip]="item.source">{{item.source}}</small>
                                                            </span>
                                                            <mat-icon>arrow_right_alt</mat-icon>
                                                            <span
                                                                style="display:flex ;align-items: center;flex-direction: column;max-width: 40%;">

                                                                <span class="text-overflow">
                                                                    {{'WidgetFormPage.Destination'|
                                                                    translate}}</span>

                                                                <small class="text-overflow"
                                                                    [matTooltip]="item.destination"
                                                                    tyle="margin-left 10px;">{{item.destination}}</small>
                                                            </span>

                                                        </div>
                                                        <mat-icon (click)="deleteJoinKey(item)" svgIcon="delete-box"
                                                            class="mr-10"></mat-icon>
                                                    </div>
                                                </div>

                                            </div>

                                        </mat-tab>
                                    </mat-tab-group>
                                </div>
                            </div>
                        </div>
                        <div class="advanced-code-button preview-button-2"
                            [ngClass]="{'preview-button-3': useDirectQuery}">
                            <div *ngIf="widgetPreviewData" (click)="codeScreen=false;sqlcodeScreen=false;showPreview()"
                                [ngClass]="{'active': !codeScreen&&!sqlcodeScreen}">
                                <img width="20px" src="/assets/images/icons/chart.svg" />
                            </div>
                            <div (click)="openWidgetCodeScreen()" [ngClass]="{'active': codeScreen}">
                                <img width="20px" src="/assets/images/icons/code.svg" />
                            </div>
                            <div (click)="openSqlCodeEditor()" *ngIf="useDirectQuery"
                                [ngClass]="{'active': sqlcodeScreen}">
                                <img width="20px" src="/assets/images/sql.png" />
                            </div>
                        </div>

                    </div>
                    <!-- <div class="filter-area" >
                        <div>slslldldl</div>
                    </div> -->
                </div>
                <div *ngIf="selectedPreviewScreen=='widget'&&widgetPreviewData || selectedPreviewScreen=='widget'&&codeScreen"
                    style="height: 90%;">
                    <div class="widget-wrapper" *ngIf="codeScreen||sqlcodeScreen">

                        <div style="height: 100%;">

                            <div style="display: flex;justify-content: end;">
                                <button mat-button *ngIf="!debugDisplay && editorOptions.language=='javascript'"
                                    (click)="resetCode()">
                                    {{ 'Common.Reset' | translate
                                    }}</button>
                                <button mat-button *ngIf=" editorOptions.language=='javascript'"
                                    (click)="openBiggerEditor()">{{ 'Common.ShowBiggerEditor' | translate
                                    }}</button>

                                <button mat-button *ngIf="!debugDisplay&& editorOptions.language=='javascript'"
                                    (click)="compileCode()">
                                    <mat-icon>play_arrow</mat-icon>
                                    {{ 'Common.Compile' | translate}}
                                </button>
                                <button mat-button *ngIf="debugDisplay && editorOptions.language=='javascript'"
                                    (click)="debugDisplay=!debugDisplay">{{
                                    'Common.Editor' |
                                    translate }}
                                </button>

                                <button mat-button *ngIf=" editorOptions.language=='sql'" (click)="runDirectQuery()">
                                    <mat-icon>play_arrow</mat-icon>
                                    {{ 'WidgetFormPage.ExecuteQuery' | translate}}
                                </button>
                            </div>
                            <app-console-screen style="height: 95%" *ngIf="debugDisplay"
                                [widget]="widget"></app-console-screen>
                            <ngx-monaco-editor *ngIf="!debugDisplay" style="height: 90%"
                                [style.height]="widgetPreviewData&&editorOptions.language=='sql'?'60%':'90%'"
                                [options]="editorOptions" (ngModelChange)="openCustomizeCode($event)"
                                [(ngModel)]="codeString" (onInit)="editorInit($event)">
                            </ngx-monaco-editor>
                            <app-ag-grid-table [height]="'30%'" [columnTypes]="dataPreviewColumnsForQuery"
                                [rowData]="widgetPreviewData?.widgetData"
                                *ngIf="widgetPreviewData&&editorOptions.language=='sql'&&widgetPreviewData?.widgetData.length>0"></app-ag-grid-table>
                        </div>
                    </div>
                    <div class="widget-wrapper" *ngIf="!codeScreen&&widgetPreviewData&&!sqlcodeScreen">
                        <div *ngIf="chartRerenderLoading"
                            style="height: 100% ;display: flex;align-items: center;justify-content: center;">
                            <mat-spinner [diameter]="30"></mat-spinner>
                        </div>

                        <app-preview-widget *ngIf="!codeScreen&&!chartRerenderLoading&&widgetPreviewData"
                            id="app-preview-widget" [data]="widgetPreviewData"></app-preview-widget>

                    </div>
                </div>
                <div *ngIf="selectedPreviewScreen=='data'" style="height: 100%;">
                    <app-ag-grid-table [columnTypes]="selectedDataPreviewColumns" [rowData]="sampleDataForDataStore"
                        *ngIf="selectedSettingArea=='dataSetting'||!widgetPreviewData"></app-ag-grid-table>
                    <app-ag-grid-table
                        [columnTypes]="useDirectQuery? dataPreviewColumnsForQuery:selectedDataPreviewColumnsForAggregation"
                        [rowData]="widgetPreviewData?.widgetData"
                        *ngIf="widgetPreviewData&&selectedSettingArea!=='dataSetting'"></app-ag-grid-table>
                </div>
                <div *ngIf="!widgetPreviewData&&selectedPreviewScreen=='widget'&&!codeScreen" class="empty-wrapper">
                    <img [src]="'/assets/images/widget-creator.png'" />
                    <div class="preview-text" *ngIf="!disablePreview">
                        {{"WidgetFormPage.PreviewText"|translate}}
                    </div>
                    <div class="preview-text" *ngIf="disablePreview" style="color: red;">
                        {{"WidgetFormPage.PreviewDisabled"|translate}}
                    </div>
                </div>
                <div class="modal-action">

                    <button style="margin-right: 10px;" *ngIf="data.type=='update'" mat-stroked-button
                        (click)="data.deleteWidget({designerContainer:data.widget})"
                        class="custom-primary-stroked-button">
                        {{ 'Common.Delete' | translate }}
                    </button>

                    <button mat-stroked-button (click)="closeDialog()" class="custom-primary-stroked-button">
                        {{ 'Common.Cancel' | translate }}
                    </button>
                    <div class="title-subtitle-area" *ngIf="titleBoxVisible">
                        <div class="flex-end">
                            <mat-icon (click)="titleBoxVisible=false">clear</mat-icon>
                        </div>
                        <div class="input-group">
                            <label>{{ 'WidgetFormPage.Title' | translate }}</label>
                            <input [(ngModel)]="widget.title" class="mb-10 h-35 gray-input" type="text" />
                        </div>
                        <div class="input-group">
                            <label> {{ 'WidgetFormPage.Subtitle' | translate }}</label>
                            <input [(ngModel)]="widget.subTitle" class="mb-10 h-35 gray-input" type="text" />
                        </div>
                        <div>
                            <mat-checkbox [(ngModel)]="widget.disableInMemoryCache" class="example-margin">{{
                                'WidgetFormPage.DisableInMemoryCache' | translate }}</mat-checkbox>
                        </div>
                        <div>
                            <mat-checkbox [(ngModel)]="widgetIsFilter" class="example-margin">{{
                                'WidgetFormPage.UseForFilter' | translate }}</mat-checkbox>
                        </div>
                        <div class="flex-end">
                            <button [disabled]="widget.title==''" (click)="upsertWidget();titleBoxVisible=false"
                                mat-stroked-button class="custom-primary-stroked-button">
                                {{ 'Common.Done' | translate }}
                            </button>
                        </div>

                    </div>
                    <button *ngIf="this.data?.widget" (click)="controlAndOpenTitleBox(true)" mat-raised-button
                        class="custom-primary-button" style="margin-left: 10px;">
                        <mat-icon>file_copy</mat-icon>
                        <span> {{ 'Common.SaveAs' | translate }}</span>
                    </button>
                    <button (click)="controlAndOpenTitleBox(false)" mat-raised-button class="custom-primary-button"
                        style="margin-left: 10px;">
                        <mat-icon>save</mat-icon>
                        <span> {{ 'Common.Save' | translate }}</span>
                    </button>

                </div>
            </div>


        </div>
    </div>
</app-custom-modal>

<!-- Modal olmayan alan -->
<div *ngIf="_notDialog" style="height: 80%;">


    <div header-buttons style="display: flex;">
        <div class="preview-button-2">
            <div (click)="ChangePreviewScreen('data')" [ngClass]="{'active': this.selectedPreviewScreen=='data'}">
                {{ "WidgetFormPage.DataPreview" |translate}}
            </div>
            <div (click)="ChangePreviewScreen('widget')" [ngClass]="{'active': this.selectedPreviewScreen=='widget'}">
                {{"WidgetFormPage.ChartPreview" | translate}}
            </div>
        </div>
        <div style="display: flex;align-items: center;">
            <mat-slide-toggle (toggleChange)="toggleChange()">
                <small>
                    {{"WidgetFormPage.DisablePreview" |translate}}
                </small>
            </mat-slide-toggle>
        </div>
        <!-- <div class="preview-button-3">
            <div>
                <img src="/assets/images/icons/phone.svg" />
            </div>
            <div class="active">
                <img src="/assets/images/icons/iMac.svg" />
            </div>
            <div>
                <img src="/assets/images/icons/iPad.svg" />
            </div>

        </div> -->
    </div>
    <div class="widgetform-container" body>
        <div class="widgetform-cols">
            <div style="flex-basis: 25%;overflow: auto;padding-right: 20px;padding-left: 10px;min-width: 25%;">
                <div class="mini-action-section">
                    <div (click)="expandAllSettings()">
                        <mat-icon>fullscreen_exit</mat-icon>
                        <small>{{"Common.ExpandAll"|translate}}</small>
                    </div>
                    <div (click)="clearAllSelection()">
                        <mat-icon svgIcon="clear_all"></mat-icon>
                        <small>{{"Common.ClearAll"|translate}}</small>
                    </div>

                </div>

                <app-setting-section *ngFor="let item of allDropdownSettings"
                    (setSelectedSettingAreaFunction)="setSelectedSettingArea($event)"
                    [selectedSettingArea]="selectedSettingArea" [dropdownMenuData]="item.data"
                    [dropdownSettings]="item.setting" [tabs]="item?.tabs"></app-setting-section>

                <ng-template #calculated>
                    <form [formGroup]="calculatedForm" class="mb-10">
                        <mat-form-field>
                            <mat-label>{{ 'ScriptForm.FormulaName' | translate }}</mat-label>
                            <input matInput formControlName="name" (focusout)="controlNameForCalculated()" />
                        </mat-form-field>
                        <mat-error
                            *ngIf="calculatedFieldNameError">{{'ScriptForm.CalculatedError'|translate}}</mat-error>
                        <mat-form-field>
                            <mat-label>{{ 'ScriptForm.Formula' | translate }}</mat-label>
                            <textarea (focus)="optionselection.open()" matInput formControlName="formula"></textarea>
                        </mat-form-field>
                        <mat-select class="calculatedselect" (selectionChange)="onOptionSelected($event)"
                            #optionselection>
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
                                {{option.value}}
                            </mat-option>
                        </mat-select>
                        <small style="color: gray;">*{{"WidgetFormPage.CalculatedFieldDesc"|translate}}</small>
                        <div style="display: flex;justify-content: end;">
                            <button (click)="calculatedFieldUpdate?updateCalculatedField():AddCalculatedField()"
                                mat-stroked-button class="custom-primary-stroked-button sm-button">
                                {{calculatedFieldUpdate?( 'Common.Update' | translate):( 'Common.Add' | translate)}}
                            </button>
                        </div>

                    </form>
                    <div>
                        <div class="calculatedfield-elemeent" *ngFor="let item of calculatedFieldList">
                            <div class="calculated-text">
                                <div style="color:#5E6DA6;max-width: 100%;overflow: hidden;
                                    text-overflow: ellipsis;">{{item.name}} : <span
                                        style="color: gray;">{{item.formula}}</span></div>

                            </div>
                            <div style="display: flex;align-items: center;justify-content: center;">
                                <mat-icon (click)="editCalculatedField(item)"
                                    style="transform: scale(0.8);">edit</mat-icon>
                                <mat-icon (click)="deleteCalculatedField(item)"
                                    style="transform: scale(0.9);">clear</mat-icon>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template #paint>
                    <app-custom-color-picker [selectedColorsForUpdate]="this.data?.widget?.colors"
                        (selectColor)="addColorRule($event)"
                        (getPaletColors)="getPaletColors($event)"></app-custom-color-picker>
                </ng-template>


            </div>
            <div style="flex-basis: 75%;position: relative;width: 100%;min-width: 70%;" class="widget-preview-area">
                <!-- <app-custom-loading *ngIf="loadingPreview"></app-custom-loading> -->
                <div *ngIf="selectedPreviewScreen=='widget'"
                    style="height: 10%;display: flex;align-items: center;justify-content: space-between;">

                    <app-custom-badge-with-clear (editSave)="changeMeasureLabel($event)"
                        [badges]="infoBadgeList"></app-custom-badge-with-clear>
                    <div style="display: flex;">
                        <app-image-selectbox></app-image-selectbox>
                        <div class="filter-container">
                            <div class="advanced-code-button preview-button-2">
                                <div style="position: relative;" (click)="closeFilterScreen()"
                                    [ngClass]="{'active': filterScreen}">
                                    <img width="20px" src="/assets/images/icons/filter-colored.png" />
                                </div>
                            </div>
                            <div class="filter-area" *ngIf="filterScreen">
                                <div class="flex-end"
                                    style="position: absolute;right: 10px;z-index: 1000;top: 10px;cursor: pointer;">
                                    <mat-icon (click)="closeFilterScreen('close')">clear</mat-icon>
                                </div>
                                <div>
                                    <mat-tab-group>
                                        <mat-tab [label]="'Common.Filters'|translate">
                                            <label class="switch" style="padding: 10px;">
                                                <input [(ngModel)]="freezeFilter" class="example-margin" type="checkbox"
                                                    checked>
                                                <span>
                                                    <em></em>
                                                    <strong></strong>
                                                    {{'WidgetFormPage.FreezeFilter' | translate}}
                                                </span>
                                            </label>
                                            <div
                                                style="display: grid; grid-template-columns: 1fr;margin-top: 1rem;padding: 10px;">
                                                <div style="grid-row-start: 1; grid-column-start: 1;">
                                                    <app-filter-form [clearButton]="true" *ngIf="isShownQueryForm"
                                                        [fields]="dataStoreColumnsForFilter" (add)="addFilter($event)">
                                                    </app-filter-form>
                                                    <div
                                                        style="margin-bottom: 16px;display: flex;align-items: center;justify-content: end;">
                                                        <button *ngIf="widget.filter.length > 0" mat-button
                                                            class="b-btn" (click)="applyFilter(true)">
                                                            {{ 'Common.Apply' |translate }}
                                                        </button>
                                                    </div>

                                                    <div class="filter-list-content" *ngIf="widget.filter.length > 0">
                                                        <app-filter-list [scrollarea]="true" [data]="widget.filter"
                                                            (delete)="deleteQuery($event)"></app-filter-list>
                                                    </div>
                                                    <br>
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <mat-tab [label]="'WidgetFormPage.IgnoreFilter'|translate">
                                            <div style="padding: 10px;">
                                                <form [formGroup]="ignoreForm" class="mb-10"
                                                    style="display: flex;align-items: center;gap: 10px;">
                                                    <mat-form-field>
                                                        <mat-label>{{ 'WidgetFormPage.IgnoreKey' | translate
                                                            }}</mat-label>
                                                        <input (keypress.enter)="addIgnoreKey()" matInput
                                                            formControlName="ignoreKey" />
                                                    </mat-form-field>
                                                    <div style="display: flex;justify-content: end;">
                                                        <button mat-stroked-button (click)="addIgnoreKey()"
                                                            class="custom-primary-stroked-button sm-button">
                                                            {{'Common.Add' | translate}}
                                                        </button>
                                                    </div>
                                                </form>
                                                <div *ngIf="widget.ignoreFilterList.length>0"
                                                    style="display: flex;flex-wrap: wrap;gap: 10px;">
                                                    <div *ngFor="let item of widget.ignoreFilterList"
                                                        class="ignorekeybadge">
                                                        <div class="ignorekey" [matTooltip]="item">{{item}}</div>
                                                        <mat-icon (click)="deleteIgnoreKey(item)" svgIcon="delete-box"
                                                            class="mr-10"></mat-icon>
                                                    </div>
                                                </div>

                                            </div>

                                        </mat-tab>
                                        <mat-tab [label]="'WidgetFormPage.JoinFilter'|translate">
                                            <div style="padding: 10px;">
                                                <form [formGroup]="joinForm" class="mb-10"
                                                    style="display: flex;align-items: center;gap: 10px;">
                                                    <mat-form-field>
                                                        <mat-label>{{ 'WidgetFormPage.Source' | translate
                                                            }}</mat-label>

                                                        <input matInput formControlName="source" />

                                                    </mat-form-field>
                                                    <mat-form-field>
                                                        <mat-label>{{ 'WidgetFormPage.Destination' | translate
                                                            }}</mat-label>

                                                        <mat-select formControlName="destination">
                                                            <mat-option
                                                                *ngFor="let option of dataStoreColumnsForDropdownMenu"
                                                                [value]="option.id">
                                                                {{option.id}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <div style="display: flex;justify-content: end;">
                                                        <button mat-stroked-button (click)="addJoinList()"
                                                            class="custom-primary-stroked-button sm-button">
                                                            {{'Common.Add' | translate}}
                                                        </button>
                                                    </div>
                                                </form>
                                                <div *ngIf="widget.widgetJoin.length>0"
                                                    style="display: flex;flex-wrap: wrap;gap: 10px;">
                                                    <div *ngFor="let item of widget.widgetJoin" class="ignorekeybadge">
                                                        <div class="ignorekey"
                                                            style="display: flex;align-items: center;width: 80%;justify-content: space-between;">
                                                            <span
                                                                style="display:flex ;align-items: center;flex-direction: column;max-width: 40%;">
                                                                <span class="text-overflow"> {{'WidgetFormPage.Source'|
                                                                    translate}}</span>
                                                                <!-- <mat-icon
                                                                    style="margin-right: 10px;">arrow_right_alt</mat-icon> -->
                                                                <small class="text-overflow"
                                                                    [matTooltip]="item.source">{{item.source}}</small>
                                                            </span>
                                                            <mat-icon>arrow_right_alt</mat-icon>
                                                            <span
                                                                style="display:flex ;align-items: center;flex-direction: column;max-width: 40%;">

                                                                <span class="text-overflow">
                                                                    {{'WidgetFormPage.Destination'|
                                                                    translate}}</span>

                                                                <small class="text-overflow"
                                                                    [matTooltip]="item.destination"
                                                                    tyle="margin-left 10px;">{{item.destination}}</small>
                                                            </span>

                                                        </div>
                                                        <mat-icon (click)="deleteJoinKey(item)" svgIcon="delete-box"
                                                            class="mr-10"></mat-icon>
                                                    </div>
                                                </div>

                                            </div>

                                        </mat-tab>
                                    </mat-tab-group>
                                </div>
                            </div>
                        </div>
                        <div class="advanced-code-button preview-button-2"
                            [ngClass]="{'preview-button-3': useDirectQuery}">
                            <div *ngIf="widgetPreviewData" (click)="codeScreen=false;sqlcodeScreen=false;showPreview()"
                                [ngClass]="{'active': !codeScreen&&!sqlcodeScreen}">
                                <img width="20px" src="/assets/images/icons/chart.svg" />
                            </div>
                            <div (click)="openWidgetCodeScreen()" [ngClass]="{'active': codeScreen}">
                                <img width="20px" src="/assets/images/icons/code.svg" />
                            </div>
                            <div (click)="openSqlCodeEditor()" *ngIf="useDirectQuery"
                                [ngClass]="{'active': sqlcodeScreen}">
                                <img width="20px" src="/assets/images/sql.png" />
                            </div>
                        </div>

                    </div>
                    <!-- <div class="filter-area" >
                        <div>slslldldl</div>
                    </div> -->
                </div>
                <div *ngIf="selectedPreviewScreen=='widget'&&widgetPreviewData || selectedPreviewScreen=='widget'&&codeScreen"
                    style="height: 90%;">
                    <div class="widget-wrapper" *ngIf="codeScreen||sqlcodeScreen">

                        <div style="height: 100%;">

                            <div style="display: flex;justify-content: end;">
                                <button mat-button *ngIf="!debugDisplay && editorOptions.language=='javascript'"
                                    (click)="resetCode()">
                                    {{ 'Common.Reset' | translate
                                    }}</button>
                                <button mat-button *ngIf=" editorOptions.language=='javascript'"
                                    (click)="openBiggerEditor()">{{ 'Common.ShowBiggerEditor' | translate
                                    }}</button>

                                <button mat-button *ngIf="!debugDisplay&& editorOptions.language=='javascript'"
                                    (click)="compileCode()">
                                    <mat-icon>play_arrow</mat-icon>
                                    {{ 'Common.Compile' | translate}}
                                </button>
                                <button mat-button *ngIf="debugDisplay && editorOptions.language=='javascript'"
                                    (click)="debugDisplay=!debugDisplay">{{
                                    'Common.Editor' |
                                    translate }}
                                </button>

                                <button mat-button *ngIf=" editorOptions.language=='sql'" (click)="runDirectQuery()">
                                    <mat-icon>play_arrow</mat-icon>
                                    {{ 'WidgetFormPage.ExecuteQuery' | translate}}
                                </button>
                            </div>
                            <app-console-screen style="height: 95%" *ngIf="debugDisplay"
                                [widget]="widget"></app-console-screen>
                            <ngx-monaco-editor *ngIf="!debugDisplay" style="height: 90%" [options]="editorOptions"
                                (ngModelChange)="openCustomizeCode($event)" [(ngModel)]="widget.code"
                                (onInit)="editorInit($event)">
                            </ngx-monaco-editor>
                        </div>
                    </div>
                    <div class="widget-wrapper" *ngIf="!codeScreen&&widgetPreviewData&&!sqlcodeScreen">
                        <div *ngIf="chartRerenderLoading"
                            style="height: 100% ;display: flex;align-items: center;justify-content: center;">
                            <mat-spinner [diameter]="30"></mat-spinner>
                        </div>

                        <app-preview-widget *ngIf="!codeScreen&&!chartRerenderLoading&&widgetPreviewData"
                            id="app-preview-widget" [data]="widgetPreviewData"></app-preview-widget>

                    </div>
                </div>
                <div *ngIf="selectedPreviewScreen=='data'" style="height: 100%;">
                    <app-ag-grid-table [columnTypes]="selectedDataPreviewColumns" [rowData]="sampleDataForDataStore"
                        *ngIf="selectedSettingArea=='dataSetting'||!widgetPreviewData"></app-ag-grid-table>
                    <app-ag-grid-table
                        [columnTypes]="useDirectQuery? dataPreviewColumnsForQuery:selectedDataPreviewColumnsForAggregation"
                        [rowData]="widgetPreviewData?.widgetData"
                        *ngIf="widgetPreviewData&&selectedSettingArea!=='dataSetting'"></app-ag-grid-table>
                </div>
                <div *ngIf="!widgetPreviewData&&selectedPreviewScreen=='widget'&&!codeScreen" class="empty-wrapper">
                    <img [src]="'/assets/images/widget-creator.png'" />
                    <div class="preview-text" *ngIf="!disablePreview">
                        {{"WidgetFormPage.PreviewText"|translate}}
                    </div>
                    <div class="preview-text" *ngIf="disablePreview" style="color: red;">
                        {{"WidgetFormPage.PreviewDisabled"|translate}}
                    </div>
                </div>
                <div class="modal-action">

                    <button style="margin-right: 10px;" *ngIf="data.type=='update'" mat-stroked-button
                        (click)="data.deleteWidget({designerContainer:data.widget})"
                        class="custom-primary-stroked-button">
                        {{ 'Common.Delete' | translate }}
                    </button>

                    <button mat-stroked-button (click)="closeDialog()" class="custom-primary-stroked-button">
                        {{ 'Common.Cancel' | translate }}
                    </button>
                    <div class="title-subtitle-area" *ngIf="titleBoxVisible">
                        <div class="flex-end">
                            <mat-icon (click)="titleBoxVisible=false">clear</mat-icon>
                        </div>
                        <div class="input-group">
                            <label>{{ 'WidgetFormPage.Title' | translate }}</label>
                            <input [(ngModel)]="widget.title" class="mb-10 h-35 gray-input" type="text" />
                        </div>
                        <div class="input-group">
                            <label> {{ 'WidgetFormPage.Subtitle' | translate }}</label>
                            <input [(ngModel)]="widget.subTitle" class="mb-10 h-35 gray-input" type="text" />
                        </div>
                        <div>
                            <mat-checkbox [(ngModel)]="widget.disableInMemoryCache" class="example-margin">{{
                                'WidgetFormPage.DisableInMemoryCache' | translate }}</mat-checkbox>
                        </div>
                        <div>
                            <mat-checkbox [(ngModel)]="widgetIsFilter" class="example-margin">{{
                                'WidgetFormPage.UseForFilter' | translate }}</mat-checkbox>
                        </div>
                        <div class="flex-end">
                            <button [disabled]="widget.title==''" (click)="upsertWidget();titleBoxVisible=false"
                                mat-stroked-button class="custom-primary-stroked-button">
                                {{ 'Common.Done' | translate }}
                            </button>
                        </div>

                    </div>
                    <button *ngIf="this.data?.widget" (click)="controlAndOpenTitleBox(true)" mat-raised-button
                        class="custom-primary-button" style="margin-left: 10px;">
                        <mat-icon>file_copy</mat-icon>
                        <span> {{ 'Common.SaveAs' | translate }}</span>
                    </button>
                    <button (click)="controlAndOpenTitleBox(false)" mat-raised-button class="custom-primary-button"
                        style="margin-left: 10px;">
                        <mat-icon>save</mat-icon>
                        <span> {{ 'Common.Save' | translate }}</span>
                    </button>

                </div>
            </div>


        </div>
    </div>
</div>

<div id="download" style="visibility: hidden;">
    <a #downloadLink></a>
</div>