import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  constructor() { }
  @Output() filterData = new EventEmitter<any>();
  @Output() changeText = new EventEmitter<any>();

  @Input() datas: any[] = []
  @Input() height: string = '40px'

  @Input() searchColumns: string[] = []


  ngOnInit(): void {
  }

  filterSearchData(event: any) {
    var filteredData = []
    this.changeText.emit((event.target as HTMLInputElement).value)
    if ((event.target as HTMLInputElement).value !== '') {
      const filterValue = (event.target as HTMLInputElement).value?.trim().toLowerCase();
      // this.dataSource.filter = filterValue.trim().toLowerCase();

      this.datas.map((data) => {
        return this.searchColumns.map(cl => {
          if (data[cl].toLowerCase().includes(filterValue))
            filteredData.push(data)
        })
      })

    } else {
      filteredData = this.datas
    }
    this.filterData.emit(filteredData)
  }

}
