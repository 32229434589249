<app-modal-title title="{{'MailForm.CreateSceduler'|translate}}"></app-modal-title>
<mat-dialog-content style="height: 100%;padding-top: 0.8rem;">
    <div *ngIf="!showcreateform" style="display: flex;flex-direction: column;min-height: 80%;">
        <div>
            <button mat-stroked-button (click)="openFormScreen() ">
                <mat-icon>library_add</mat-icon>{{ 'MailForm.AddScedule' |translate }}
            </button>
        </div>
        <div class="titles">
            <div>{{'MailForm.Subject'|translate}}</div>
            <div>{{'MailForm.ScedularType'|translate}}</div>
            <div>{{'MailForm.Receivers'|translate}}</div>
            <div>{{'MailForm.Time'|translate}}</div>
            <div></div>

        </div>
        <ul>
            <li *ngFor="let item of paginatedScedulers">
                <div class="list-inside">{{item.subject}}</div>
                <div class="custom-badge list-inside {{item.badgeClass}}">
                    {{item.scedularType}}</div>
                <div class="avatar-group list-inside">
                    <div class="avatar-container" *ngFor="let rc of item.receiverList" [matTooltip]="rc.mail">
                        <div>{{ rc.avatar !== undefined ? rc.avatar : rc.mail.substring(0, 2).toUpperCase() }}</div>
                    </div>
                </div>
                <div class="list-inside">{{item.hour}}H {{item.minute}}M</div>
                <div class="list-inside">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">

                        <button mat-menu-item (click)="openEditScreen(item)">{{'DashboardPage.Edit' |
                            translate }} <mat-icon>edit</mat-icon></button>
                        <button mat-menu-item (click)="deleteScedule(item)">{{'DashboardPage.Delete' | translate
                            }}

                            <mat-icon>delete</mat-icon></button>
                    </mat-menu>
                </div>
            </li>
        </ul>
        <mat-paginator *ngIf="Scedules.length" [length]="Scedules.length" [pageSize]="5" [pageSizeOptions]="[]"
            (page)="paginateItems($event)"></mat-paginator>
    </div>
    <div *ngIf="showcreateform">
        <div style="display: flex;align-items: center;">
            <button mat-stroked-button (click)="showcreateform=false">
                <mat-icon>keyboard_arrow_left</mat-icon>
                <span>{{"MailForm.TurnList"|translate}}</span>
            </button>
        </div>

        <form style="margin-top: 0.6rem;" [formGroup]="MailForm">
            <!-- <mat-form-field>
                <mat-label>{{ 'MailForm.Receiver' | translate }}</mat-label>
                 <input matInput type="text" required formControlName="receivers" (input)="writeEmail($event.target.value)"> 
            </mat-form-field> -->

            <mat-form-field>
                <mat-label>{{ 'MailForm.Receiver' | translate }}</mat-label>
                <!-- <input matInput type="text" required formControlName="receivers" (input)="writeEmail($event.target.value)"> -->
                <input matInput type="text" required formControlName="receivers" (change)="writeEmail()">
                <mat-error *ngIf="MailForm.controls.receivers.invalid && MailForm.controls.receivers.touched">
                    <span *ngIf="MailForm.controls.receivers.errors?.email">
                      {{"MailForm.ValidMail" | translate}}
                    </span>
                    <span *ngIf="MailForm.controls.receivers.errors && !MailForm.controls.receivers.errors.email">
                      {{"MailForm.InvalidMail" | translate}}
                    </span>
                  </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'MailForm.Subject' | translate }}</mat-label>
                <input matInput type="text" required formControlName="subject">

            </mat-form-field>
            <h5 style="width: 40%;font-weight: 400;">{{ 'MailForm.ShippingTime' | translate }}</h5>
            <div style="display: flex;">
                
                <mat-form-field>
                  <mat-label>{{ 'MailForm.Hour' | translate }}</mat-label>
                  <input matInput type="number" required formControlName="hour" min="0" max="23">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{ 'MailForm.Minute' | translate }}</mat-label>
                  <input matInput type="number" required formControlName="minute" min="0" max="59">
                </mat-form-field>
              </div>
            <mat-form-field>
                <mat-label>{{ 'MailForm.SharedDashboard' | translate }}</mat-label>
                <input matInput type="text" readonly="true" formControlName="link">

            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'MailForm.Format' | translate }}</mat-label>
                <mat-select matInput formControlName="format" required>
                    <mat-option *ngFor="let option of Formats" [value]="option.id">
                        {{option.type}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <mat-dialog-actions style="justify-content: flex-end;">
            <button class="b-btn" mat-dialog-close>{{"Common.Cancel"|translate}}</button>
            <button mat-button class="b-btn b-success" cdkFocusInitial (click)="saveScedule()">{{ 'Common.Save' |
                translate }}</button>
        </mat-dialog-actions>
    </div>
</mat-dialog-content>