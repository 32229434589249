import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BussionConnector, BParameter, RunPeriod, ScriptFlow } from 'src/app/models/responses/bussionConncetorResponse';
import { MatStepper } from '@angular/material/stepper';
import { NodeService } from 'src/app/services/node/node.service';
import { Node } from 'src/app/models/responses/nodeResponse';
import { ConnectorService } from 'src/app/services/connector/connector.service';
import { UntypedFormGroup, Validators, UntypedFormControl, ValidationErrors, FormControl } from '@angular/forms';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFunctionService } from 'src/app/services/global-function/global-function.service';
import { MatRadioChange } from '@angular/material/radio';
import { CodeEditorComponent } from 'src/app/components/code-editor/code-editor.component';
import { Observable } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';

declare var monaco: any;

@Component({
  selector: 'app-connector-form',
  templateUrl: './connector-form.component.html',
  styleUrls: ['./connector-form.component.scss']
})
export class ConnectorFormComponent implements OnInit {
  @ViewChild('passwordDialog') passwordDialog: TemplateRef<any>;
  passwordinputtype: string = 'password'
  password: string = ''
  dataPreview: any[] = []
  dataDataPreviewColumns: { fieldName: string, fieldType: string }[] = []
  previewScreen: boolean = false

  stepCount: number = 5;
  isCreateForm: boolean = true;
  title: string = "";
  @ViewChild('stepper') private formStepper: MatStepper;
  editorOptions = { theme: 'vs-dark', language: 'sql' };
  dataNodes: Node[] = [];
  connectors: Node[] = [];
  orgNodeId: string;
  showInMemory = false;
  checked = false;
  useConnectionString = false
  scriptFlows: ScriptFlow[] = []
  selectedScriptFlow: any
  bulkInsert: boolean = true
  externalEngineCodeTemp: string = ''
  externalEngineCodeTemplate: string = ''

  externalEnginesByConnector: any[] = []

  compareFunctionForTaskType = (o1: any, o2: any) => o1 === o2.toString();
  compareFunctionForTaskStatus = (o1: any, o2: any) => o1 === o2.toString();
  compareFunctionForTaskSourceType = (o1: any, o2: any) => o1 === o2.toString();
  compareFunctionForFlow = (o1: any, o2: any) => { o1 === o2.toString(); }
  compareFunctionForTaskFrequency = (o1: any, o2: any) => o1 === o2.toString();

  firstForm: UntypedFormGroup;
  secondForm: UntypedFormGroup;
  thirdForm: UntypedFormGroup;
  fourthForm: UntypedFormGroup;

  parameter: BParameter = {
    parameterName: '',
    parameterValue: ''
  };

  universes: string[] = [];
  collections: string[] = [];
  columns: any[] = [];

  task: BussionConnector = {
    status: 1, //0: Passive 1: Active
    ownerId: "",
    icon: "",
    name: "",
    domain: "",
    type: 0,
    state: 0,
    connectionString: "",
    sourceType: -1,
    sourceDatabase: "",
    sourceServer: "",
    sourceUser: "",
    sourcePassword: "",
    sourcePort: "",
    node: "",
    bussionConnector: "",
    universe: "",
    collection: "",
    query: "",
    queryParameter: [],
    keyField: "",
    reportEmails: "",
    updateField: "",
    keepExistingSchema: 0,
    commandTimeOut: 30,
    inMemory: 0,
    runAtPeriod: {
      type: -1,
      seconds: -1,
      minute: -1,
      hour: -1,
      day: -1,
      month: -1,
      year: -1
    },
    disableThreshold: 3,
    bufferSize: 10000,
    bulkInsert: 1,
    folder: ""

  }

  autoComplete = new FormControl('');
  columnAutoComplete = new FormControl('');
  columnAutoComplete2 = new FormControl('');
  folderAutoComplete = new FormControl('');



  filteredOptions: Observable<string[]>;
  filteredUniverseOption: Observable<string[]>;
  filteredCollectionOption: Observable<string[]>;
  filteredColumnOption: Observable<string[]>;
  filteredColumnOption2: Observable<string[]>;
  filteredFolderOptions: Observable<string[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      type: string,
      task: BussionConnector,
      folderList: string[]
    },
    private dialogRef: MatDialogRef<ConnectorFormComponent>,
    private nodeService: NodeService,
    private connectorService: ConnectorService,
    private coreService: CoreService,
    private translate: TranslateService,
    private functionService: GlobalFunctionService,
    private dialog: MatDialog,
    private dataStoreService: DataStoreService
  ) { }

  ngOnInit(): void {
    this.setupValidations();
    this.title = this.data.type == 'update' ? this.translate.instant("ConnectorFormPage.TitleUpdate") : this.translate.instant("ConnectorFormPage.TitleCreate");
    this.isCreateForm = this.data.type == 'update' ? false : true;

    if (this.data.task) {
      this.task = Object.assign({}, this.data.task);
      this.task.status = this.task.status == 'Active' ? 1 : 0;
      this.task.state = this.task.state == 'Working' ? 1 : 0;
      this.task.type = this.getTypeValue(this.task.type);
      this.stepCount = this.task.type === 2 ? 4 : 5;
      this.useConnectionString = this.task.connectionString.length > 0 ? true : false

      if (this.task.sourceType == 10) {
        this.stepCount = 4
        this.useConnectionString = false
        this.getExternalEngines();
        this.getExternalEngineCode()
        this.getExternalEngineCodeTemplate()
      }
      this.bulkInsert = this.task.bulkInsert == 1 ? true : false
      //this.runPeriod = this.task.runAtPeriod;
      if (this.task.sourceType == 9) {
        this.getDataFlows()
      }
    }
    this.getDataNodes();
    this.getBussionConnectors();

    this.filteredFolderOptions = this.folderAutoComplete.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.data.folderList)),
    );
  }

  private _filter(value: string, data: any[]): string[] {
    const filterValue = value.toLowerCase();

    return data.filter(option => option.toLowerCase().includes(filterValue));
  }

  editorInit(eventInit: any) {
    (window as any).monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: true
    });

    (window as any).monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES6,
      allowNonTsExtensions: true
    });

    this.getGlobalFunctionDefinitions();

  }

  getGlobalFunctionDefinitions() {
    this.functionService.getGlobalFunctionsDefinitions().subscribe(code => {
      (window as any).monaco.languages.typescript.javascriptDefaults.addExtraLib(code);
    })
  }

  getDataFlows() {
    this.connectorService.getDataFlows().subscribe(res => {
      this.scriptFlows = res
      this.selectedScriptFlow = this.scriptFlows.find(x => x.flowId == this.task.connectionString)
    })
  }

  selectFlow(selected: string) {
    this.selectedScriptFlow = this.scriptFlows.find(x => x.flowId == selected)


  }

  changeSecondFormValidation(event: any) {
    this.task.sourceType = parseInt(this.task.sourceType.toString())
    this.stepCount = this.task.sourceType == 8 || this.task.sourceType == 9 ? 3 : parseInt(this.task.type) !== 2 ? 5 : 4

    if (this.task.sourceType == 10) {
      this.stepCount = 4;
      this.useConnectionString = false
      if (!this.data.task)
        this.getExternalEngineCodeTemplate()
    }

    if (this.task.sourceType == 9) {
      this.getDataFlows()
    }

    if (this.task.sourceType == 0) {
      this.useConnectionString = true
    }

    if (this.useConnectionString) {
      this.secondForm.get('connectionString').setValidators(Validators.required);
      this.secondForm.get('sourceType').clearValidators();
      this.secondForm.get('sourceServer').clearValidators();
      this.secondForm.get('sourcePort').clearValidators();
      this.secondForm.get('sourceDatabase').clearValidators();
      this.secondForm.get('sourceUser').clearValidators();
      this.secondForm.get('sourcePassword').clearValidators();

      this.secondForm.get('connectionString').updateValueAndValidity();
      this.secondForm.get('sourceType').updateValueAndValidity();
      this.secondForm.get('sourceServer').updateValueAndValidity();
      this.secondForm.get('sourcePort').updateValueAndValidity();
      this.secondForm.get('sourceDatabase').updateValueAndValidity();
      this.secondForm.get('sourceUser').updateValueAndValidity();
      this.secondForm.get('sourcePassword').updateValueAndValidity();

    } else {
      this.secondForm.get('connectionString').clearValidators();
      this.secondForm.get('sourceType').setValidators(Validators.required);
      this.secondForm.get('sourceServer').setValidators(Validators.required);
      this.secondForm.get('sourcePort').setValidators(Validators.required);
      this.secondForm.get('sourceDatabase').setValidators(Validators.required);
      this.secondForm.get('sourceUser').setValidators(Validators.required);
      this.secondForm.get('sourcePassword').setValidators(Validators.required);


      this.secondForm.get('connectionString').updateValueAndValidity();
      this.secondForm.get('sourceType').updateValueAndValidity();
      this.secondForm.get('sourceServer').updateValueAndValidity();
      this.secondForm.get('sourcePort').updateValueAndValidity();
      this.secondForm.get('sourceDatabase').updateValueAndValidity();
      this.secondForm.get('sourceUser').updateValueAndValidity();
      this.secondForm.get('sourcePassword').updateValueAndValidity();

    }
    if (this.task.sourceType == 8 || this.task.sourceType == 9 || this.task.sourceType == 10 || this.task.sourceType == 4) {
      if (this.task.sourceType !== 4)
        this.useConnectionString = true
      this.secondForm.get('connectionString').setValidators(Validators.required);
      this.secondForm.get('sourceType').clearValidators();
      this.secondForm.get('sourceServer').clearValidators();
      this.secondForm.get('sourcePort').clearValidators();
      this.secondForm.get('sourceDatabase').clearValidators();
      this.secondForm.get('sourceUser').clearValidators();
      this.secondForm.get('sourcePassword').clearValidators();
      this.thirdForm.get('node').clearValidators();
      this.thirdForm.get('universe').clearValidators();
      this.thirdForm.get('collection').clearValidators();
      this.thirdForm.get('keepExistingSchema').clearValidators();


      this.secondForm.get('connectionString').updateValueAndValidity();
      this.secondForm.get('sourceType').updateValueAndValidity();
      this.secondForm.get('sourceServer').updateValueAndValidity();
      this.secondForm.get('sourcePort').updateValueAndValidity();
      this.secondForm.get('sourceDatabase').updateValueAndValidity();
      this.secondForm.get('sourceUser').updateValueAndValidity();
      this.secondForm.get('sourcePassword').updateValueAndValidity();
      this.thirdForm.get('node').updateValueAndValidity();
      this.thirdForm.get('universe').updateValueAndValidity();
      this.thirdForm.get('collection').updateValueAndValidity();
      this.thirdForm.get('keepExistingSchema').updateValueAndValidity();

    }
  }

  setupValidations() {
    this.firstForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.task.name, [Validators.required]),
    });

    this.secondForm = new UntypedFormGroup({
      sourceType: new UntypedFormControl(this.task.sourceType, [Validators.required]),
      sourceServer: new UntypedFormControl(this.task.sourceServer, [Validators.required]),
      sourcePort: new UntypedFormControl(this.task.sourcePort, [Validators.required]),
      sourceDatabase: new UntypedFormControl(this.task.sourceDatabase, [Validators.required]),
      sourceUser: new UntypedFormControl(this.task.sourceUser, [Validators.required]),
      sourcePassword: new UntypedFormControl(this.task.sourcePassword, [Validators.required]),
      connectionString: new UntypedFormControl(this.task.connectionString),
      commandTimeOut: new UntypedFormControl(this.task.commandTimeOut, [Validators.required]),
      disableThreshold: new UntypedFormControl(this.task.disableThreshold)

    });

    this.thirdForm = new UntypedFormGroup({
      node: new UntypedFormControl(this.task.node, [Validators.required]),
      universe: new UntypedFormControl(this.task.universe, [Validators.required]),
      collection: new UntypedFormControl(this.task.collection, [Validators.required]),
      keepExistingSchema: new UntypedFormControl(this.task.keepExistingSchema, [Validators.required]),
      bulkInsert: new UntypedFormControl(this.task.bulkInsert, [Validators.required]),
      bufferSize: new UntypedFormControl(this.task.bufferSize, [Validators.required])
    });
    if (this.task.node != "")
      this.orgNodeId = this.task.node;
    this.fourthForm = new UntypedFormGroup({
      bussionConnector: new UntypedFormControl(this.task.bussionConnector, [Validators.required]),
    });


  }

  getDataNodes() {
    this.nodeService.getDataNodes().pipe().subscribe(result => {
      this.dataNodes = result;
      var currentNode = this.dataNodes.find(x => x.nodeId == this.orgNodeId);
      if (currentNode.dataNodeType.toLowerCase() == "clickhouse")
        this.showInMemory = true;
      if (this.task.inMemory == 1)
        this.checked = true;
    });
  }

  getBussionConnectors() {
    this.connectorService.getConnectors().subscribe(result => {
      this.connectors = result;
    });
  }

  getCredentials() {
    if (this.task.sourceServer && this.task.sourcePort) {
      this.connectorService.getCredentials(this.task.sourceServer, this.task.sourcePort).subscribe(res => {
        if (res) {
          this.task.sourceUser = res.split(' ')[0]
          this.task.sourcePassword = res.split(' ')[1]
          this.password = this.task.sourcePassword

        }
      })
    }
  }

  saveTask(saveAs: boolean) {
    if (!this.checkValidation(5)) {
      this.coreService.showFormFillError();
      return
    }
    if (saveAs) {
      delete this.task.connectorId
      if (this.data.task.name == this.task.name) {
        this.task.name = this.task.name + '_Copy'
      }
    }
    this.task.bulkInsert = this.bulkInsert ? 1 : 0
    if (this.task.sourceType.toString() !== '8' && this.task.sourceType.toString() !== '9' && this.task.sourceType.toString() !== '10' &&
      this.task.sourceType.toString() !== '4')
      this.task.connectionString = this.useConnectionString ? this.task.connectionString : ''

    this.connectorService.upsertConnectorTask(this.task).subscribe(result => {
      this.dialogRef.close(true);
      if (this.task.sourceType == 10)
        this.upsertExternalEngine(this.task.bussionConnector, this.task.connectionString, this.externalEngineCodeTemp)
    });

  }



  addParameter() {
    this.task.queryParameter.push(this.parameter);
    this.parameter = {
      parameterName: '',
      parameterValue: ''
    }
  }

  deleteParameter(param: BParameter) {
    let index = this.task.queryParameter.findIndex(x => {
      return x.parameterName == param.parameterName && x.parameterValue == param.parameterValue;
    });
    if (index !== -1) {
      this.task.queryParameter.splice(index, 1);
    }
  }

  goToNextStep() {

    if (this.checkValidation(this.formStepper.selectedIndex)) {
      this.formStepper.next();
    }
    else {
      this.coreService.showFormFillError();
    }
  }

  backToPreviousStep() {
    this.formStepper.previous();
  }

  checkValidation(index: number): boolean {
    if (index == 0) {
      return this.firstForm.valid;
    }
    else if (index == 1) {
      return this.secondForm.valid;
    }
    else if (index == 2) {
      return this.thirdForm.valid;
    }
    else if (index == 3) {
      if (this.task.sourceType == 8)
        return this.checkSchuleValidation();
      else
        return this.fourthForm.valid;
    }
    else if (index == 5) {
      return this.checkSchuleValidation();
    }
    else {
      return true;
    }
  }

  periodTypeChanged(event: any) {
    this.task.runAtPeriod.seconds = -1;
    this.task.runAtPeriod.minute = -1;
    this.task.runAtPeriod.hour = -1;
    this.task.runAtPeriod.day = -1;
    this.task.runAtPeriod.month = -1;
    this.task.runAtPeriod.year = -1;
  }

  private getTypeValue(type: string): number {

    if (type == 'Batch') {
      return 0;
    }
    else if (type == 'Periodically') {
      return 1;
    }
    else if (type == 'Direct') {
      return 2;
    } else {
      return parseInt(type)
    }
  }

  private checkSchuleValidation(): boolean {
    if (this.task.type == 2) {
      return true;
    }
    else {
      if (this.task.runAtPeriod.type == -1) {
        return true;
      }
      else if (this.task.runAtPeriod.type == 0) {
        return this.task.runAtPeriod.seconds != -1;
      }
      else if (this.task.runAtPeriod.type == 1) {
        return this.task.runAtPeriod.minute != -1;
      }
      else if (this.task.runAtPeriod.type == 2) {
        return this.task.runAtPeriod.hour != -1;
      }
      else if (this.task.runAtPeriod.type == 3) {
        return this.task.runAtPeriod.day != -1 && this.task.runAtPeriod.hour != -1 && this.task.runAtPeriod.minute != -1;
      }
      else if (this.task.runAtPeriod.type == 4) {
        return this.task.runAtPeriod.day != -1 && this.task.runAtPeriod.hour != -1 && this.task.runAtPeriod.minute != -1;
      }
      else if (this.task.runAtPeriod.type == 5) {
        return this.task.runAtPeriod.day != -1 && this.task.runAtPeriod.hour != -1 && this.task.runAtPeriod.minute != -1;
      }
      else {
        return false;
      }
    }
  }

  taskTypeChanged(type) {
    this.stepCount = type === "2" ? 3 : 4;

  }

  openBiggerEditor() {
    const dialogRef = this.dialog.open(CodeEditorComponent, {
      height: '100%',
      width: '100%',
      data: {
        code: this.task.query,
        debug: () => { }
      },
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.task.query = result;
      }
    });
  }



  checkInMemoryInDataNodeChange(selecteddatanode: any) {
    if (this.dataNodes.length == 0)
      return true;
    var currentNode = this.dataNodes.find(x => x.nodeId == selecteddatanode.value);
    if (currentNode.dataNodeType.toLowerCase() == "clickhouse")
      this.showInMemory = true;
    else
      this.showInMemory = false;

    if (this.orgNodeId == undefined) {
      this.orgNodeId = this.task.node;
      if (this.task.inMemory == 1) {
        this.checked = true;
      }
      else
        this.checked = false;
    }
    if (currentNode == undefined) {
      this.task.inMemory = 0;
      this.checked = false;

    }

    if (currentNode.dataNodeType.toLowerCase() == "clickhouse") {
      if (currentNode.nodeId != this.orgNodeId) {
        this.task.inMemory = 0;
        this.checked = false;
        this.task.node = currentNode.nodeId;
      }


    } else {
      this.task.inMemory = 0;
      this.checked = false;
      this.task.node = currentNode.nodeId;
      //return this.checked;
      // return localStorage.getItem('myFormControlValue') !== null;
    }
    this.getUniverse()

  }
  checkInMemory() {
    if (this.checked)
      this.task.inMemory = 0;
    else
      this.task.inMemory = 1;
  }

  checkConnection(event) {

    if (event.checked) {
      this.useConnectionString = true;
    } else {
      this.useConnectionString = false;
      this.task.connectionString = "";

    }
    this.changeSecondFormValidation(event)
  }



  getExrenalEngineCode() {
    this.connectorService.GetExternalEngineCode(this.task.bussionConnector, this.task.connectionString).subscribe
  }

  getExternalEngineCodeTemplate() {
    if (this.task.bussionConnector !== '') {
      this.connectorService.GetExternalEngineTemplate(this.task.bussionConnector).subscribe(res => {
        this.externalEngineCodeTemp = res
        this.externalEngineCodeTemplate = res
      })
    }
  }
  loadingEngines: boolean = false
  getExternalEngines() {

    if (this.fourthForm.value.bussionConnector !== '' || this.task.bussionConnector !== '') {
      this.task.bussionConnector = this.fourthForm.value.bussionConnector ? this.fourthForm.value.bussionConnector : this.task.bussionConnector
      this.loadingEngines = true
      this.connectorService.GetExternalEngines(this.task.bussionConnector).pipe(
        catchError(error => {
          console.error('Hata:', error);
          this.loadingEngines = false;
          this.coreService.showErrorMessage(error)
          this.externalEnginesByConnector = [];
          this.filteredOptions = this.autoComplete.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '', this.externalEnginesByConnector)),
          );
          return error;
        })
      ).subscribe(res => {
        this.loadingEngines = false;
        this.externalEnginesByConnector = res;
        this.filteredOptions = this.autoComplete.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '', this.externalEnginesByConnector)),
        );

      });
    }
  }

  getExternalEngineCode() {
    if (this.externalEnginesByConnector.some(x => x == this.task.connectionString)) {
      this.connectorService.GetExternalEngineCode(this.task.bussionConnector, this.task.connectionString).subscribe(res => {
        this.externalEngineCodeTemp = res
      })
    }
    else {
      this.externalEngineCodeTemp = this.externalEngineCodeTemplate

    }
  }

  openCodeScreen() {


    const dialogRef = this.dialog.open(CodeEditorComponent, {
      height: '100%',
      width: '100%',
      data: {
        code: this.externalEngineCodeTemp,
        compileVisible: false,
        editorOptions: { theme: 'vs-dark', language: 'csharp' }
      },
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.externalEngineCodeTemp = result
      }
    });
  }

  upsertExternalEngine(nodeID: string, engine: string, code: string) {

    this.connectorService.UpsertExternalEngine(nodeID, engine, code).subscribe(res => {

    })
  }

  bussionConnectorChange() {

    this.getExternalEngines();
  }


  fillAutoCompleteArea() {


    this.filteredUniverseOption = this.thirdForm.get('node').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.universes)),
    );

    this.filteredCollectionOption = this.thirdForm.get('universe').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.collections)),
    );

    this.filteredColumnOption = this.columnAutoComplete.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.columns)),
    );

    this.filteredColumnOption2 = this.columnAutoComplete2.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.columns)),
    );


  }



  private getUniverse() {
    if (this.task.node)
      this.dataStoreService.getUniverses(this.task.node).pipe(
        catchError(res => {
          this.universes = []
          this.columns = []
          this.collections = []
          this.coreService.showErrorMessage(res)
          this.fillAutoCompleteArea()
          return res
        })
      ).subscribe((result: string[]) => {

        this.universes = result;

        this.fillAutoCompleteArea()

      });
  }

  universeSelection() {

    this.getCollections();
  }

  private getCollections() {
    this.task.universe = this.task.universe ? this.task.universe : this.thirdForm.value.universe
    if (this.task.node && this.universes.includes(this.task.universe))
      this.dataStoreService.getCollections(this.task.node, this.task.universe).pipe(
        catchError(res => {
          this.collections = []
          this.columns = []
          this.coreService.showErrorMessage(res)
          this.fillAutoCompleteArea()

          return res
        })
      ).subscribe(result => {
        this.collections = result;

        this.fillAutoCompleteArea()

      });
  }

  collectionSelection() {
    this.getColumns();
  }

  private getColumns() {
    this.task.collection = this.task.collection ? this.task.collection : this.thirdForm.value.collection

    if (this.task.node && this.universes.includes(this.task.universe) && this.collections.includes(this.task.collection))
      this.dataStoreService.getColumnsByNodeId(this.task.node, this.task.universe, this.task.collection)
        .pipe(
          catchError(res => {
            this.columns = []
            this.coreService.showErrorMessage(res)
            this.fillAutoCompleteArea()
            return res
          })
        )
        .subscribe(result => {
          this.columns = result.map(cl => {
            return cl.fieldName
          })
          this.fillAutoCompleteArea()

        });
  }

  runQuery() {
    this.connectorService.ExecuteQuery(this.task, this.password).subscribe(res => {
      this.dataPreview = res
      this.previewScreen = true
      this.dataDataPreviewColumns = Object.keys(this.dataPreview[0]).map(item => {
        return { fieldName: item, fieldType: typeof this.dataPreview[0][item] }
      })
    })
  }

  PassModal() {
    if (this.password) {
      this.runQuery()
    } else {
      var passDialogRef = this.dialog.open(this.passwordDialog);
      passDialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.runQuery()
        }
      })
    }

  }




}