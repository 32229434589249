<app-modal-title [title]="title"></app-modal-title>
<mat-dialog-content class="mat-typography">

    <app-title title="{{ 'GlobalFunctionFormPage.About' | translate }}"></app-title>
    <form [formGroup]="pageForm">
        <mat-form-field>
            <mat-label>{{ 'GlobalFunctionFormPage.Name' | translate }}</mat-label>
            <input matInput [(ngModel)]="visual.name" formControlName="name" required>
        </mat-form-field>
        <br>
    </form>

    <mat-form-field>
        <mat-label>{{ 'GlobalFunctionFormPage.Description' | translate }}</mat-label>
        <textarea matInput [(ngModel)]="visual.description"></textarea>
    </mat-form-field>
    <br>

    <mat-form-field *ngIf="!isCreateForm">
        <mat-label>{{ 'GlobalFunctionFormPage.Version' | translate }}</mat-label>
        <input matInput [(ngModel)]="visual.version" disabled>
    </mat-form-field>
    <br>

    <mat-form-field *ngIf="!isCreateForm">
        <mat-label>{{ 'GlobalFunctionFormPage.CreatedBy' | translate }}</mat-label>
        <input matInput [(ngModel)]="visual.createdby" disabled>
    </mat-form-field>
    <br>

    <mat-form-field *ngIf="!isCreateForm">
        <mat-label>{{ 'GlobalFunctionFormPage.UpdatedBy' | translate }}</mat-label>
        <input matInput [(ngModel)]="visual.updatedby" disabled>
    </mat-form-field>
    <br>

    <mat-form-field *ngIf="!isCreateForm">
        <mat-label>{{ 'GlobalFunctionFormPage.UpdateTime' | translate }}</mat-label>
        <input matInput value="{{visual.updateDate}}" disabled>
    </mat-form-field>
    <br>

    <app-title title="{{ 'Common.Code' | translate }}" buttonTitle="{{ 'Common.ShowBiggerEditor' | translate }}" (btnEvent)="openBiggerEditor()"></app-title>
    <div style="height: 500px">
        <ngx-monaco-editor style="height: 500px" [options]="editorOptions" [(ngModel)]="visual.code" (onInit)="editorInit($event)">
        </ngx-monaco-editor>
    </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-success" dkFocusInitial *ngIf="!isCreateForm"
        (click)="upsertGlobalFunction()">{{ 'Common.Update' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="isCreateForm"
        (click)="upsertGlobalFunction()">{{ 'Common.Create' | translate }}</button>
</mat-dialog-actions>