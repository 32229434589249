import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LeftMenuProps } from 'src/app/models/globals/customLeftMenuProps';

@Component({
  selector: 'app-custom-leftmenu',
  templateUrl: './custom-leftmenu.component.html',
  styleUrls: ['./custom-leftmenu.component.scss']
})
export class CustomLeftmenuComponent implements OnInit {

  _menuList: LeftMenuProps[] = []
  @Input() set menuList(value: LeftMenuProps[]) {
    this._menuList = value.filter(x => x.visible)
  }

  constructor(private router: Router) { }


  ngOnInit(): void {
    if (this._menuList.some(x => x.path == this.router.url))
      this._menuList.find(x => x.path == this.router.url).active = true
  }

  clickMenu(selectedItem: LeftMenuProps) {
    if (this._menuList.find(x => x.active))
      this._menuList.find(x => x.active).active = false

    if (this.router.url.includes(selectedItem.path)) {
      selectedItem.active = true
    }

    selectedItem.function()
  }
}
