import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllowedScript } from 'src/app/models/responses/allowedScriptResponse';
import { CoreService } from 'src/app/services/core/core.service';
import { ScriptsService } from 'src/app/services/scripts/scripts.service';

@Component({
  selector: 'app-script-form',
  templateUrl: './script-form.component.html',
  styleUrls: ['./script-form.component.scss']
})
export class ScriptFormComponent implements OnInit {

  pageForm: UntypedFormGroup;
  script: AllowedScript = {
    scriptName: ''
  }
  title: string = ''
  isCreateForm: boolean = false;
  newScripts: AllowedScript[] = []

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService,
    private coreService: CoreService, private spinner: NgxSpinnerService, private scriptService: ScriptsService,
    private dialogRef: MatDialogRef<ScriptFormComponent>,
  ) { }

  ngOnInit(): void {

    if (this.data.type == 'update') {
      this.script = Object.assign({}, this.data.script);
      this.isCreateForm = false
    } else {
      this.isCreateForm = true
    }

    this.pageForm = new UntypedFormGroup({
      scriptName: new UntypedFormControl(this.script.scriptName, [Validators.required]),
    });



    this.title = this.data.type == 'update' ? this.translate.instant("ScriptsPage.TitleUpdate") : this.translate.instant("ScriptsPage.TitleCreate");



  }

  upsertScript() {

    if (this.pageForm.invalid || (this.isCreateForm && this.newScripts.length <= 0)) {
      this.coreService.showFormFillError();
      return;
    }
    this.spinner.show();
    var upsertedScripts: AllowedScript[] = []

    if (this.data.script) {
      this.data.oldScripts.find(x => x.id == this.script.id).scriptName = this.pageForm.value.scriptName
    }


    var oldscriptsforinsert: AllowedScript[] = this.data.oldScripts.map(item => {
      return {
        scriptName: item.scriptName
      }
    })


    upsertedScripts = this.newScripts.concat(oldscriptsforinsert)


    this.scriptService.upsertScript(upsertedScripts).subscribe(result => {
      this.spinner.hide();
      this.dialogRef.close(true);
    });
  }

  addElement( listtype: string) {
    if (listtype === 'newScripts' && this.pageForm.get('scriptName').valid) {
      const scriptName = this.pageForm.get('scriptName').value;
      this.newScripts.push({ scriptName: scriptName.replace('\n', '') });
      // this.pageForm.get('scriptName').reset(); // Değeri sıfırlayınca newScript boş algılıyor validasyona takılıyor 
      
    }
  }
  deleteElement(selectedscript: AllowedScript, listtype: string) {
    if (listtype == 'newScripts') {
      this.newScripts = this.newScripts.filter(x => x.scriptName !== selectedscript.scriptName)
     
      // event.target.value = ''
    }
  }

}
