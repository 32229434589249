import { Component, Inject, OnInit, Optional, ViewChild, inject, ElementRef, AfterViewInit, AfterContentInit, AfterViewChecked } from '@angular/core';
import { LoginRequest } from 'src/app/models/requests/loginRequest';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { NgModel, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { User, UserForm } from 'src/app/models/responses/loginResponse';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment';
import { UserHomePage } from 'src/app/models/responses/usersResponse';
import { App } from 'src/app/models/responses/appsResponse';
@Component({
  selector: 'app-dynamic-login',
  templateUrl: './dynamic-login.component.html',
  // template: '',
  styleUrls: ['./dynamic-login.component.scss'],
  standalone: true
})
export class DynamicLoginComponent implements OnInit, AfterViewInit {
  constructor(
  ) {
    this.local = inject(LoginService)
    if (this.local) {
      this.loginRequest = this.local.getLoginRequest();
      this.authService = this.local.getAuthService();
      this.licenseService = this.local.getLicenseService()
      this.spinner = this.local.getSpinner();
      this.translate = this.local.getTranslate();
      this.router = this.local.getRouter();
      this.coreService = this.local.getCoreService();
    }


  }
  username: string
  password: string
  logo: string = environment.logo;
  spinner: any;
  authService: any;
  licenseService: any;
  chaptcaEnabled: boolean = false;
  captchaService: any;
  captchaStatus: any = null;
  warntext: string = '';
  translate: any;
  router: any;
  local: any;
  coreService: any;
  loginRequest: LoginRequest = {
    Username: '',
    Password: ''
  };
  passwordAgain: string;
  pageForm: UntypedFormGroup;
  isShownCreateAccount: boolean = false;
  loginForm: boolean = true;
  loginImage: string = ''
  user: UserForm = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",

  };

  config: any = {
    type: 1,
    length: 6,
    cssClass: 'custom',
    back: {
      stroke: "#2F9688",
      solid: "#f2efd2"
    },
    font: {
      color: "#000000",
      size: "35px"
    }
  };
  captchaText: string = '';
  ngOnInit(): void {

    this.getChaptcaEnabled()
    this.getLoginImage()
  }



  ngAfterViewInit(): void {


    this.createCaptcha();
    this.getLoginImage()

  }

  getLoginImage() {
    this.authService.getLoginImage('').subscribe(res => {
      this.loginImage = res

      document.getElementById('login-left-area').style.backgroundImage = "url(" + environment.baseUrl + this.loginImage + ")"
      document.getElementById('login-left-area').style.backgroundPosition = "center"
    })
  }

  getChaptcaEnabled() {
    this.authService.isChaptchaEnabled().subscribe((res) => {
      this.chaptcaEnabled = res
      if (this.chaptcaEnabled)
        document.getElementsByClassName("parent")[0].setAttribute('style', 'visibility:visible;height:auto')
    })

    this.captchaService.captchStatus.subscribe((status) => {
      this.captchaStatus = status;
      if (this.captchaStatus !== null && !this.captchaStatus)
        this.warntext = 'Please check code!'
      else
        this.warntext = ''
    });
  }
  code: string = ''
  check: boolean = false

  warnText: string = 'Your Text Incorrect!'

  createCaptcha() {
    //clear the contents of captcha div first 
    document.getElementById('captchacontainer').innerHTML = "";
    var charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lengthOtp = 6;
    var captcha = [];
    for (var i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    var canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 100;
    canv.height = 50;
    var ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha.join(""), 0, 30);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    this.code = captcha.join("");
    document.getElementById("captchacontainer").appendChild(canv); // adds the canvas to the body element
    if (!this.chaptcaEnabled)
      document.getElementsByClassName("parent")[0].setAttribute('style', 'visibility:hidden;height:0')
  }

  checkCaptcha() {
    var inputvalue = document.getElementById('cpatchaTextBox') as HTMLInputElement
    this.check = true

    if (inputvalue.value == '' || inputvalue.value !== this.code) {
      this.captchaStatus = false
    } else {
      document.getElementById('parent').classList.add('custom-blur')
      this.captchaStatus = true
    }

  }

  changePasswords(event: any) {
    this.password = event.target.value;
    this.loginRequest.Password = this.password;
  }
  updatePassword(event: any) {
    this.password = event.target.value;
    this.login();
  }

  changeUserName(event: any) {
    this.username = event.target.value;
    this.loginRequest.Username = this.username;
  }
  changeName(event: any) {
    this.user.firstName = event.target.value;
  }
  changeLastName(event: any) {
    this.user.lastName = event.target.value;
  }
  changeMail(event: any) {
    this.user.email = event.target.value;
  }
  changePasswordCreateAcount(event: any) {
    this.user.password = event.target.value;
  }
  // updatePasswordCreateAcount(event: any) {
  //   this.user.passwordAgain = event.target.value;
  //   this.createAccount();
  // }
  deneme(event: any) {
    this.passwordAgain = event.target.value;
  }

  login() {
    this.spinner.show();

    if (this.chaptcaEnabled && !this.captchaStatus) {
      this.spinner.hide();
      this.check = true
      return;
    }

    if (!this.username || !this.password || this.username == '' || this.password == '') {
      this.spinner.hide();
      this.coreService.showFormFillError()
      return;
    }

    this.loginRequest = {
      Username: this.username,
      Password: this.password
    };

    this.authService.login(this.loginRequest).subscribe((result: any) => {
      if (result.result) {
        this.spinner.hide();
        // if (result.message.language == 'English') {
        //   this.translate.use('en');
        // } else {
        //   this.translate.use('tr');
        // }
        this.authService.saveToken(result.message.token);
        this.licenseService.getLicense().subscribe(res => {
          if (res?.bussionModules)
            localStorage.setItem('licence', JSON.stringify(res.bussionModules.split(',')))

          if (result.message.homePage[0])
            this.navigateUserMainPage(result.message.homePage[0])
          else
            this.router.navigate(['/']);
        })

      }
    });
  }




  navigateUserMainPage(mainpage: UserHomePage) {

    if (mainpage) {
      if (mainpage.type == 1) {
        localStorage.setItem('app', mainpage.object)
        var appobj = JSON.parse(mainpage.object)
        if (appobj.appType == 0) {
          this.router.navigate(['/data-app/' + mainpage.objectId]);
        } else {
          window.location.replace(`${environment.baseUrl}/Apps/${appobj.applicationLink}`);

          // this.router.navigate(['/Apps/' + appobj.applicationLink]);
        }
      }
    } else {
      this.router.navigate(['/']);
    }
  }




  createAccount() {
    debugger;
    if (this.user.password == this.passwordAgain) {
      this.authService.createAccount(this.user).subscribe(result => {
        this.authService.saveToken(result.token);
        this.coreService.showSuccessMessage(this.translate.instant("Common.Success"))
        // this.router.navigate(['/']);
        window.location.href = window.location.origin + '/';
      })
    }
    else {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.PasswordNotSame"));
    }
  }


}
