<div class="avatar-group list-inside" [style]="'margin-top:'+top+';font-size:'+fontsize">
    <div class="avatar-container" [style]="'width:'+width+';height:'+width" *ngFor="let rc of list"
        [matTooltip]="avatarKeys.tooltip.split(' ').length>1?rc[avatarKeys.tooltip?.split(' ')[0]]+' '+rc[avatarKeys.tooltip?.split(' ')[1]]: rc[avatarKeys?.tooltip]">
        <div style="width: 100%;height: 100%;">
            <img *ngIf="!iserrorimage" [src]="env+rc[avatarKeys.avatar]" (error)="errorimage($event)" />
            <div class="avatar-text" *ngIf="iserrorimage">{{rc[avatarKeys.avatartext2]!==''?
                rc[avatarKeys.avatartext]?.substring(0,1).toUpperCase()+
                rc[avatarKeys.avatartext2]?.substring(0,1)?.toUpperCase():rc[avatarKeys.avatartext]?.substring(0,2)?.toUpperCase()}}
            </div>
        </div>
    </div>
</div>