<!-- <div class="content"> -->
<!-- <app-title title="{{'SystemParametersPage.LanguageSetting'|translate}}"></app-title> -->
<app-data-table addButtonText="{{ 'SystemParametersPage.AddLanguageButton' | translate }}"
    title="{{ 'SystemParametersPage.LanguageSetting' | translate }}"
    filterPlaceholder="{{ 'ColorPalettePage.FilterPlaceholder' | translate }}" [data]="languages"
    (deleteRow)="deleteLanguage($event)" [displayedColumns]="displayedColumns"
    [displayedColumnsName]="displayedColumnsName" (openModal)="openLanguageForm($event)" [columnWidth]="columnWidth">
    <!-- (openModal)="openColorPalette($event)"
      [columnWidth]="columnWidth" -->
</app-data-table>
<!-- </div> -->