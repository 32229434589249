import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Endpoints } from 'src/app/constants/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ArchitectureService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
  ) { }


  getBussionServers() {
    let param = {
      Token: this.authService.getToken()
    };
    return this.http.post(Endpoints.ArchGetBussionServers, param).pipe(
      map((res: any) => {
        return res
      })
    )
  }


  getBussionConnectors() {
    let param = {
      Token: this.authService.getToken()
    };
    return this.http.post(Endpoints.ArchBussionConnectors, param).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  refreshData() {
    let param = {
      Token: this.authService.getToken()
    };
    return this.http.post(Endpoints.ArchRefreshData, param).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  getBussionDataNodes() {
    let param = {
      Token: this.authService.getToken()
    };
    return this.http.post(Endpoints.ArchBussionDataNodes, param).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  getBussionDataFlows() {
    let param = {
      Token: this.authService.getToken()
    };
    return this.http.post(Endpoints.ArchDataFlows, param).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  getBussionDataStores() {
    let param = {
      Token: this.authService.getToken()
    };
    return this.http.post(Endpoints.ArchDataStores, param).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  getBussionConnectorTasks() {
    let param = {
      Token: this.authService.getToken()
    };
    return this.http.post(Endpoints.ArchConnectorTasks, param).pipe(
      map((res: any) => {
        return res
      })
    )
  }
}
