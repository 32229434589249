import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConnectorService } from 'src/app/services/connector/connector.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {

  public taskInterval = interval(2000);
  private subscriptionInterval;
  taskId: string;
  logs: string[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { visibleButton?: boolean , taskId: string },
    public dialogRef: MatDialogRef<LogComponent>,
    private connectorService: ConnectorService
  ) { }

  ngOnInit(): void {
    if (this.data.taskId) {
      this.taskId = this.data.taskId;
      this.runTaskInterval();
    }
  }

  ngOnDestroy(): void {
    this.subscriptionInterval.unsubscribe();
  }

  runTaskInterval() {
    this.subscriptionInterval = this.taskInterval.subscribe(() => this.getLogs());
  }

  getLogs() {
    this.connectorService.getTaskStatus(this.taskId).subscribe(logs => {
      if (logs.includes("EOF")) {
        this.subscriptionInterval.unsubscribe();
      }
      this.logs = logs;
    });
  }

  stopTask() {
    this.connectorService.stopTask(this.taskId).subscribe(result => {
      console.log("Task Stoped");
    });
  }

}
