import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'src/app/services/core/core.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-photo-upload',
  templateUrl: './photo-upload.component.html',
  styleUrls: ['./photo-upload.component.scss']
})
export class PhotoUploadComponent implements OnInit {


  @ViewChild('photoUpload') photoFile: ElementRef;
  fileData: File = null;
  avatar: any = "../../../../../assets/images/user.png";

  @Input() set photoUrl(value: string) {
    if(this.type=='icon'){
      if (value==''||value==null) {
        this.avatar = '/assets/images/defaultIcon.png';
      }else{
        if (value && !value.startsWith('data:image')) {
          this.avatar = environment.imageUrl + value;
        }
      }
    }else{
      if (value && !value.startsWith('data:image')) {
        this.avatar = environment.imageUrl + value;
      }
    }
  }

  @Input() type
  @Input() width


  @Output() photoLoaded = new EventEmitter<any>();

  constructor(
    private translate: TranslateService,
    private coreService: CoreService
  ) { }

  ngOnInit(): void {
  }

  uploadFile(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    if (this.fileData.size > 3145728) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.SmallPhoto"));
      return;
    }
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.WrongFormatPhoto"));
      this.photoFile.nativeElement.value = "";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.avatar = reader.result;
      // debugger;
      //let base64 = new String((reader.result as string).split(',')[1]);
      this.photoLoaded.emit(reader.result);
    }
  }


}
