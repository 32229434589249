import { Component, HostListener } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFunctionService } from './services/global-function/global-function.service';
import { ScriptLoaderService } from './services/script-loader/script-loader.service';
import { CoreService } from './services/core/core.service';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  title = 'Bussion';
  showHeader: boolean = false;
  isChatboxOpen: boolean = false;
  permissions: string[] = []
  roles: string[] = []
  currentRoute: string = ''
  licencePermissions = localStorage.getItem('licence') ?? ''

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private coreService: CoreService,
    private scriptService: ScriptLoaderService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.checkSession();
    this.setLangulage();
    this.coreService.loadCustomSVG();
    this.getHeaderStatus();


  }

  ngOnInit(): void {

    this.permissions = this.authService.getPermissions()
    this.roles = this.authService.getRoles()
    this.authService.setLanguage(this.authService.getUserLanguage())
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }


  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    // Toast mesajı dışında bir yere tıklandığında Snackbar'ı kapat
    if (!Array.from((event.target as HTMLElement).classList).some(x => x.includes('snack-bar')))
      this.snackBar.dismiss()
  }


  getHeaderStatus() {
    this.coreService.headerStatus().subscribe(status => {
      this.showHeader = status;
    })
  }
  // openChatbox() {
  //   this.dialog.open(ChatbotComponent, {
  //     width: '80%',
  //     height: '80%',
  //     panelClass: 'chat-dialog-container',
  //   });
  // }


  toggleChatbox() {
    if (this.isChatboxOpen) {
      this.dialog.closeAll(); // Tüm açık dialogları kapat
    } else {
      this.openChatbox();
    }
  }
  openChatbox() {
    this.isChatboxOpen = true;
    this.dialog.open(ChatbotComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'chat-dialog-container',
    }).afterClosed().subscribe(() => {
      this.isChatboxOpen = false;
    });
  }



  private setLangulage() {
    let lang = this.authService.getUserLanguage();
    this.translate.addLangs(['en', 'tr']);
    this.translate.setDefaultLang(lang);
  }

  checkSession() {
    this.authService.isLoggedIn().subscribe(result => {
      this.showHeader = result;
    });
  }

}
