import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { SelfService } from 'src/app/services/self-service/self.service';
import * as XLSX from 'xlsx';
import { CoreService } from 'src/app/services/core/core.service';
import { SelfServiceDatastoreFileComponent } from '../self-service-datastore-file/self-service-datastore-file/self-service-datastore-file.component';

import { CellClickedEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import { MatStepper } from '@angular/material/stepper';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DataStore } from 'src/app/models/responses/dataStoreResponse';
import { TranslateService } from '@ngx-translate/core';
import { ColumnSelectDialogComponent } from '../column-select-dialog/column-select-dialog.component';



@Component({
  selector: 'app-self-service-datastore-form',
  templateUrl: './self-service-datastore-form.component.html',
  styleUrls: ['./self-service-datastore-form.component.scss']
})
export class SelfServiceDatastoreFormComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  name = 'Bussion Analytics XLSX TO JSON CONVERTER';
  willDownload = false;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  modalTitle: string;
  showFileTypeSelection: boolean = true;
  showFileSelectModal: boolean = false;
  showHandsoneTable: boolean = false;
  public columnDefs: ColDef[] = [];
  rowData: any
  warntext: string = ''
  selectedCardIndex: number = -1;
  fileName: string = null;
  file: File;
  formData = new FormData()
  columnTypes: string[] = ['String', 'Number', 'DateTime'];
  columnTypeForm: FormGroup;
  columns: { FieldName: string, FieldType: string, FieldFilter: string }[] = [];
  userData = {
    Status: 1,
    UserDataId: "",
    NodeId: "",
    Name: "",
    CollectionName: "",
    DataDefinition: "",
    UserId: "",
    Columns: [],
  };

  fileAccept: string;
  acceptFileTypes: any[] = [
    { index: 0, title: 'XLS/XLSX', type: '.xlsx, .xls', img: '../../../../assets/images/xls.png' },
    { index: 1, title: 'JSON', type: '.json', img: '../../../../assets/images/json-3.png' },
    { index: 2, title: 'TEXT', type: '.txt', img: '../../../../assets/images/txt-file-symbol.png' },
    { index: 3, title: 'CSV', type: '.csv', img: '../../../../assets/images/csv-4.png' },
    { index: 4, title: 'XML', type: '.xml', img: '../../../../assets/images/xml-file-format-symbol.png' }


  ]
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    minWidth: 160
  };

  showLoading: boolean = false

  constructor(
    private selfService: SelfService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<SelfServiceDatastoreFormComponent>,
    private modalService: ModalService,
    private coreService: CoreService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: string,
      dataStore: any,
      onlyPreview: boolean
    },

  ) {
    this.columnTypeForm = this.formBuilder.group({
      type: ['']
    });
  }

  ngOnInit(): void {


    this.dataStoreForm = this.formBuilder.group({
      FileName: ['', Validators.required],
      append: ['', Validators.required],
      nodeId: [''],
      ApiKey: ['']
    });
    if (this.data.dataStore) {
      this.dataStoreForm.patchValue({
        FileName: this.data.dataStore.name,
        append: '0',
        nodeId: this.data.dataStore.nodeId,
        ApiKey: this.data.dataStore.apiKey
      })
      this.userData.UserDataId = this.data.dataStore.dataStoreId
      if (this.data.dataStore?.sampleData)
        this.rowData = this.data.dataStore?.sampleData

     
      if (this.rowData)
        this.columnDefs = Object.keys(this.rowData[0]).map((key) => {
          var icon = 'String'
          if (this.data.dataStore.columns.find(x => x.fieldName === key)?.fieldType)
            icon = this.data.dataStore.columns.find(x => x.fieldName === key)?.fieldType.toLowerCase().includes('string') ? 'String' : this.data.dataStore.columns.find(x => x.fieldName === key)?.fieldType.toLowerCase().includes('number') ? 'Number' : 'DateTime'
          return {
            headerName: key,
            sortable: true,
            headerComponentParams: {
              template: `
                        <div style="display:flex;align-items:center">
                        <img width="16px" src='/assets/images/icons/${icon}.png'/>
                          <span>
                            ${key}
                          </span>
                        </div>`},
            field: key
          };
        });

    }


  }


  // nextStep() {
  //   const currentIndex = this.stepper.selectedIndex;
  //   const nextIndex = currentIndex + 1;
  //   this.stepper.selectedIndex = nextIndex;

  // }
  nextStep() {
    const currentIndex = this.stepper.selectedIndex;
    const nextIndex = currentIndex + 1;

    if (currentIndex === 0) {
      if (this.dataStoreForm.controls.FileName.invalid || this.dataStoreForm.controls.append.invalid) {
        this.coreService.showFormFillError(this.translate.instant('ErrorMessages.FillFields'))
        return;
      }
    }
    if (this.stepper.selectedIndex === 1) {
      if (!this.data.dataStore) {
        if (!this.file) {
          this.coreService.showFormFillError(this.translate.instant('DataStoreFormPage.ChooseFileTypeParagraph'));
          return;
        }
      }


    }


    if (nextIndex < this.stepper.steps.length) {
      this.stepper.selectedIndex = nextIndex;
    }
  }


  backStep() {
    const currentIndex = this.stepper.selectedIndex;
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      this.stepper.selectedIndex = prevIndex;
    }
  }

  saveDataStoreFile() {
    if (!this.dataStoreForm.valid)
      this.coreService.showFormFillError(this.translate.instant('ErrorMessages.FillFields'))

    else {
      if (this.file)
        this.formData.append('File', this.file)

      this.formData.append('Name', this.dataStoreForm.value.FileName)
      this.formData.append('Token', this.authService.getToken())
      this.formData.append('NodeId', '')
      this.formData.append('append', this.dataStoreForm.value.append)
      if (this.data.type == 'update') {
        this.userData.UserDataId = this.data.dataStore.dataStoreId;
        if (!this.file)
          this.formData.append('File', '')
      }

      this.userData.Name = this.dataStoreForm.value.FileName
      this.userData.UserId = localStorage.getItem('userId')

      this.userData.Columns = this.columnDefs.map((colDef: ColDef) => {
        const columnData: {
          FieldFilter: Number
          FieldName: string
          FieldType: string
        } = this.userData.Columns.find(col => col.FieldName === colDef.field);

        var fieldType = ''
        if (columnData) {

          if (columnData.FieldType.includes('System')) {
            fieldType = columnData.FieldType
          } else {
            fieldType = 'System.' + columnData.FieldType
          }
        } else {
          fieldType = 'System.String'
        }

        return { FieldName: colDef.field, FieldType: fieldType, FieldFilter: 1 };
      });


      // if (!this.file)
      this.formData.append('UserData', JSON.stringify(this.userData))
      this.addDatastoreAsFile()

    }

  }


  getCardStyle(index: number) {
    return index === this.selectedCardIndex ? 'selected' : '';
  }



  upsertDataStore() {
  }
  dataStoreForm: FormGroup;




  openFileSelect(fileType: string, index: number): void {
    this.fileAccept = fileType;
    this.showFileTypeSelection = false;
    this.showFileSelectModal = true;
    this.selectedCardIndex = index;
    this.fileInput.nativeElement.accept = fileType

    this.fileInput.nativeElement.click();
  }



  // DefaultColDef sets props common to all Columns


  onFileChange(event: any): void {
    this.file = event.target.files[0];
    this.fileName = event.target.files[0].name;

    this.formData = new FormData()
    this.uploadFile()
  }




  uploadFile(): void {
    const extension = this.getFileExtension(this.fileName);
    this.userData.Name = this.dataStoreForm.value.FileName
    this.userData.UserId = localStorage.getItem('userId')

    if (this.file && extension) {
      if (extension === 'xls' || extension === 'xlsx') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = reader.result as ArrayBuffer;
          const arrayBuffer = new Uint8Array(data);
          const workbook = XLSX.read(arrayBuffer, { type: 'array' });

          const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);


          var types = Object.keys(jsonData[0]).map(item => {
            return { key: item, type: typeof jsonData[0][item] }
          })

          this.columnDefs = this.generateColumnDefs(jsonData[0], types);
          this.rowData = jsonData;

          this.showFileSelectModal = false;
          this.showHandsoneTable = true;

          const worksheetName: string = workbook.SheetNames[0];
          const worksheet: XLSX.WorkSheet = workbook.Sheets[worksheetName];


          // this.userData.Columns = Object.keys(jsonData[0]).map(column => {
          //   return { FieldName: column, FieldType: 'System.String', FieldFilter: 1 }
          // });
          // this.userData.Columns = JSON.stringify(jsonData);
          //  const userdata = JSON.stringify(jsonData);
          // this.formData.append('UserData', JSON.stringify(this.userData))


        };
        reader.readAsArrayBuffer(this.file);
      } else if (extension === 'csv') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const csvData = reader.result as string;
          var splitbracket = this.FindDelimiter(csvData)
          
          const jsonData = this.convertCsvToJson(csvData, splitbracket);
          

          var types = Object.keys(jsonData[0]).map(item => {
            return { key: item, type: 'System.String' }
          })

          this.userData.Columns = Object.keys(jsonData[0]).map(column => {
            return { FieldName: column, FieldType: types.find(x => x.key == column).type, FieldFilter: 1 }
          });
          // this.formData.append('UserData', JSON.stringify(this.userData))
          this.columnDefs = this.generateColumnDefs(jsonData[0], types);
          this.rowData = jsonData;
          // this.rowData = csvData;
          this.showFileSelectModal = false;
          this.showHandsoneTable = true;

        };
        reader.readAsText(this.file);

      } else if (extension === 'json') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const jsonData = JSON.parse(reader.result as string);

          // this.formData.append('UserData', JSON.stringify(this.userData))

          var types = Object.keys(jsonData[0]).map(item => {
            return { key: item, type: 'System.String' }
          })
          this.userData.Columns = Object.keys(jsonData[0]).map(column => {
            return { FieldName: column, FieldType: types.find(x => x.key == column).type, FieldFilter: 1 }
          });

          this.columnDefs = this.generateColumnDefs(jsonData[0], types);
          this.rowData = jsonData;

          this.showFileSelectModal = false;
          this.showHandsoneTable = true;
        };

        reader.readAsText(this.file);
      } else if (extension === 'txt') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const txtData = reader.result as string;

          // TXT ile ilgili işlemler ?

          this.showFileSelectModal = false;
          this.showHandsoneTable = true;
        };
        reader.readAsText(this.file);
      } else if (extension === 'xml') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const xmlData = reader.result as string;

          // XML ile ilgili işlemler ?

          this.showFileSelectModal = false;
          this.showHandsoneTable = true;
        };
        reader.readAsText(this.file);
      } else {
        // Unsupported file extension
        console.error('Unsupported file extension.');
      }
    } else {
      // File or extension is missing
      console.error('File or extension is missing.');
    }

  }

  private FindDelimiter(stringText: string) {
    var index = 0;
    var max = 0;
    var delimiterlist = [",", ";", " ", "\t", "|", "-"];
    for (var i = 0; i < delimiterlist.length; i++) {
      if (stringText.split(delimiterlist[i]).length > max) {
        index = i;
        max = stringText.split(delimiterlist[i]).length;
      }


    }
    return delimiterlist[index];
  }

  onGridReady(params: GridReadyEvent) {
    params.api.sizeColumnsToFit();
  }


  onCellClicked(event: CellClickedEvent): void {

    if (this.data.type == 'update') {
      return;
    }
    const column = event.colDef?.field;
    const oldValue = event.value;
    const columnType = event.colDef['fieldType']

    var data = {
      column,
      oldValue,
      types: this.columnTypes,
      columnType
    }

    const dialogRef = this.dialog.open(ColumnSelectDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((newType: string) => {
      if (newType) {
        const columnDefs = this.columnDefs.map((colDef: ColDef) => {
          if (colDef.field === column) {
            return {
              ...colDef,
              type: newType,
              sortable: true,
              minWidth: 160,
              headerComponentParams: {
                template: `
                          <div style="display:flex;align-items:center">
                          <img width="16px" src='/assets/images/icons/${newType ?? 'String'}.png'/>
                            <span>
                              ${colDef.field}
                            </span>
                          </div>`},

            };
          }
          return colDef;
        });
        this.columnDefs = columnDefs;

        const columnData = this.userData.Columns.find(col => col.FieldName === column);

        if (columnData) {
          columnData.FieldType = newType;
        } else {
          this.userData.Columns.push({ FieldName: column, FieldType: newType, FieldFilter: 1 });
        }
      }
    });
  }


  getFileExtension(fileName: string): string {
    const extension = fileName.split('.').pop();
    return extension.toLowerCase();
  }


  generateColumnDefs(data: any, types): ColDef[] {
    return Object.keys(data).map((key) => {
      var icon = 'String'
      icon = types.find(x => x.key == key)?.type?.toLowerCase().includes('string') ? 'String' : types.find(x => x.key == key)?.type?.toLowerCase().includes('number') ? 'Number' : 'DateTime'
      return {
        field: key,
        minWidth: 160,
        fieldType: icon,
        headerComponentParams: {
          template: `
                    <div style="display:flex;align-items:center">
                    <img width="16px" src='/assets/images/icons/${icon}.png'/>
                      <span>
                        ${key}
                      </span>
                    </div>`},
      };
    });
  }

  addDatastoreAsFile() {
    this.showLoading = true
    this.selfService.addDataStore(this.formData).subscribe(
      (response) => {
        this.dialogRef.close(true)
        this.showLoading = false
        this.coreService.showSuccessMessage(response)
        // Dosya yükleme işlemi başarılı 


      },
      (error) => {
        // Dosya yükleme işlemi sırasında hata oluştu
        this.showLoading = false
        console.error(error);
        this.coreService.showErrorMessage(error)
      }
    );
  }



  convertCsvToJson(csvData: string, delimiter: string): any[] {
    const lines = csvData.split('\n');
    const result = [];
    const headers = lines[0].replace(/\r/g, '').split(delimiter);

    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i].split(delimiter);

      for (let j = 0; j < headers.length; j++) {
        var cleanedData = currentLine[j]
        if (currentLine[j]?.includes('\r')) {
          cleanedData = currentLine[j].replace(/\r/g, '');
        }
        obj[headers[j]] = cleanedData;
      }

      result.push(obj);
    }

    return result;
  }


}