import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { languagePacts } from 'src/app/models/responses/languagePacts';


@Injectable({
  providedIn: 'root'
})
export class SystemParametersService {

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }
  getLogs(type) {
    let param = { Token: this.authService.getToken(), Type: type };
    return this.http.post(Endpoints.getLogs, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }
  getSystemParameters() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getSystemParameters, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }
  getBussionVersion() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getBussionVersion, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }
  changeDebugState(state) {
    let param = { Token: this.authService.getToken(), State: state };
    return this.http.post(Endpoints.debugeState, param).pipe(
      map((res: any) => {
        return res.result
      })
    )
  }
  setMaxIllegalTries(count) {
    let param = { Token: this.authService.getToken(), Count: count };
    return this.http.post(Endpoints.maxIllegalTries, param).pipe(
      map((res: any) => {
        return res.result
      })
    )
  }

  getCaptcha() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getCaptcha, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }

  setCaptcha(state) {
    let param = { Token: this.authService.getToken(), State: state };
    return this.http.post(Endpoints.setCaptcha, param).pipe(
      map((res: any) => {
        return res.result
      })
    )
  }

  backupBussionConfiguration() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.backupBussionConfiguration, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }

  restoreBussionConfiguration() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.restoreBussionConfiguration, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }
  downloadCurrentConfiguration() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.downloadCurrentConfiguration, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }

  uploadConfiguration(form) {
    return this.http.post(Endpoints.uploadConfiguration, form).pipe(
      map((res: any) => {
        return res
      })
    )
  }


  getLanguagePacts() {
    let param = { Token: this.authService.getToken() };


    return this.http.post(Endpoints.getLanguagePacts, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }

  upsertLanguagePacts(languagePack: languagePacts) {
    let param = {
      Token: this.authService.getToken(),
      LanguagePack: languagePack
    };


    return this.http.post(Endpoints.setLanguagePacts, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }


  getLanguageFile(LangName: string) {

    let param = {
      Token: this.authService.getToken(),
      LangName: LangName
    };


    return this.http.post(Endpoints.getLanguagePacts, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }

  deleteLanguage(LangName: string) {

    let param = {
      Token: this.authService.getToken(),
      LangName: LangName
    };


    return this.http.post(Endpoints.deleteLanguage, param).pipe(
      map((res: any) => {
        return res
      })
    )
  }
}