import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/constants/endpoints';
import { AuthService } from '../auth/auth.service';
import { map } from 'rxjs/operators';
import { UsersResponse, User } from 'src/app/models/responses/usersResponse';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);


  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getUsers() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getUsers, param).pipe(
      map((response: UsersResponse) => {
        this.users.next(response.message)
        return response.message;
      })
    );
  }

  

  getUsersWithSearchText(searchText: string) {
    let param = { Token: this.authService.getToken(), SearchName: searchText };
    return this.http.post(Endpoints.getUsers, param).pipe(
      map((response: UsersResponse) => {
        return response.message;
      })
    );
  }

  getUserById(userId: string) {
    let param = {
      Token: this.authService.getToken(),
      UserId: userId
    };
    return this.http.post(Endpoints.getUserId, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  upsertUser(user: User, role: string[], group: string[]) {
    let param = {
      Token: this.authService.getToken(),
      User: user,
      Roles: role,
      Groups: group
    };
    return this.http.post(Endpoints.upsertUser, param).pipe(
      map((response: UsersResponse) => {
        return response.message;
      })
    );
  }

  deleteUser(user: User) {
    let param = {
      Token: this.authService.getToken(),
      UserId: user.userId
    };
    return this.http.post(Endpoints.deleteUser, param).pipe(
      map((response: UsersResponse) => {
        return response.message;
      })
    );
  }
}
