import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SystemParametersService } from '../system-parameters/system-parameters.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateLoaderService implements TranslateLoader {

  constructor(private httpClient: HttpClient, private systemService: SystemParametersService) { }

  contentHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

  getTranslation(lang: string): Observable<any> {



    return this.httpClient.get(environment.baseUrl + `/Localization/${lang}.json`, { headers: this.contentHeader })
      .pipe(
        tap(res=>console.log(res)),
        map((res:any) => res),
        // tap((res) => { this.scriptLoader.languageObject.next(JSON.parse(res as string)) }),
        catchError(_ => this.httpClient.get( `/assets/i18n/${lang}.json`)),
        catchError(_ => this.httpClient.get(`/assets/i18n/tr.json`))


      );



  }



  // setTranslationObject() {
  //   var language = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'tr'
  //   // const apiAddress = environment.baseUrl + `/assets/language/${language?.toLowerCase()}.json`;

  //   const apiAddress = environment.baseUrl + `/Apps/Lex1234-74757766416345884368-528275/AppFiles/${language?.toLowerCase()}.json`
  //   return this.httpClient.get(apiAddress, { headers: this.contentHeader })
  //     .pipe(
  //       map((res) => JSON.parse(res as string)),
  //       catchError(_ => this.httpClient.get(environment.baseUrl + '/Apps/Lex1234-74757766416345884368-528275' + `/assets/i18n/${language?.toLocaleLowerCase()}.json`)),
  //       catchError(_ => this.httpClient.get(environment.baseUrl + '/Apps/Lex1234-74757766416345884368-528275' + `/assets/i18n/tr.json`))

  //       // tap((res) => { this.scriptLoader.languageObject.next(JSON.parse(res as string)) }),
  //     );
  // }
}
