<app-modal-title [title]="title"></app-modal-title>
<mat-dialog-content class="mat-typography">

    <app-title title="{{ 'NodeFormPage.About' | translate }}"
        [buttonTitle]="'NodeFormPage.CheckNodeConnectivity'|translate" (btnEvent)="checkNodeConnectivity()"></app-title>

    <form [formGroup]="pageForm">
        <mat-form-field>
            <mat-label>{{ 'NodeFormPage.Type' | translate }}</mat-label>
            <mat-select [(ngModel)]="node.nodeType" [compareWith]="compareFunctionForNodeType"
                (ngModelChange)="nodeTypeSelected()" [ngModelOptions]="{standalone: true}">
                <mat-option value="0">Node</mat-option>
                <mat-option value="1">Connector</mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <mat-form-field *ngIf="node.nodeType == 0">
            <mat-label>{{ 'NodeFormPage.SSL' | translate }}</mat-label>
            <mat-select [(ngModel)]="node.ssl" [compareWith]="compareFunctionForNodeType"
                [ngModelOptions]="{standalone: true}">
                <mat-option value="0">Disable</mat-option>
                <mat-option value="1">Enable</mat-option>
            </mat-select>
        </mat-form-field>
        <br *ngIf="node.nodeType == 0">

        <mat-form-field>
            <mat-label>{{ 'NodeFormPage.DataNode' | translate }}</mat-label>
            <mat-select (ngModelChange)="changedefaultdb($event)" [(ngModel)]="node.dataNodeType"
                [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let type of nodeTypes" value="{{type.name}}">{{type.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <mat-form-field>
            <mat-label>{{ 'NodeFormPage.Name' | translate }}</mat-label>
            <input matInput [(ngModel)]="node.name" formControlName="name" required>
        </mat-form-field>
        <br>

        <mat-form-filed>
            <mat-checkbox [(ngModel)]="selfServiceEnabled" [checked]="selfServiceEnabled"
                (change)="selfServiceToggle($event)">{{"NodeFormPage.SelfServiceEnabled"|translate}}</mat-checkbox>
        </mat-form-filed>
        <br>

        <mat-form-filed>
            <mat-checkbox [(ngModel)]="useConnectionString" [checked]="useConnectionString"
                (change)="checkConnection($event)">Use
                Connection String</mat-checkbox>
        </mat-form-filed>
        <br>
        <div *ngIf="useConnectionString">
            <mat-form-field>
                <mat-label>{{ 'NodeFormPage.ConnectionString' | translate }}</mat-label>
                <input matInput [(ngModel)]="node.connectionString" formControlName="connectionString" required>
            </mat-form-field>
            <br>
        </div>

        <div *ngIf="!useConnectionString">
            <mat-form-field>
                <mat-label>{{ 'NodeFormPage.Host' | translate }}</mat-label>
                <input matInput [(ngModel)]="node.host" formControlName="host" required>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'NodeFormPage.Port' | translate }}</mat-label>
                <input matInput [(ngModel)]="node.port" formControlName="port" required>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'NodeFormPage.TimeOut' | translate }}</mat-label>
                <input matInput type="number" [(ngModel)]="node.timeOut" formControlName="timeOut" required>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'NodeFormPage.Username' | translate }}</mat-label>
                <input matInput [(ngModel)]="node.userName" formControlName="userName" required>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'NodeFormPage.Password' | translate }}</mat-label>
                <input matInput [(ngModel)]="node.password" type="password" formControlName="password" required>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'NodeFormPage.DefaultDatabase' | translate }}</mat-label>
                <input matInput [(ngModel)]="node.defaultDatabase" formControlName="defaultDatabase" required>
            </mat-form-field>
            <br>
        </div>


    </form>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="!isCreateForm" (click)="saveNode()">{{
        'Common.Update' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="isCreateForm" (click)="saveNode()">{{
        'Common.Create' | translate }}</button>
</mat-dialog-actions>