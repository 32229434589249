<!-- <div class="toolbar" role="banner" *ngIf="enableTitle">
    <img src="/assets/images/bussionnewlogo.png" [routerLink]="['']" />
</div> -->

<div class="share-preview-content" [id]="contentIdKeyword+dashboard?.dashboardId" *ngIf="!notAuth" [ngStyle]="{'height': enableTitle ? '100%' : 'calc(100% - 8px)' }">
    <app-dashboard-preview [showAvatar]="false" *ngIf="dashboard" [showFilter]="enableFilter"
        [widgets]="widgets" [dashboard]="dashboard" [selectedDashboardInitialFilters]="filters"
        [isShownDashboardTitle]="enableTitle" [shareEncryptKey]="shareEncryptKey" [domain]="domain" [shareId]="shareId"
        [showExitButton]="data?.exitButton" [authType]="authType" [sharedToken]="sharedToken" [window]="data.window"></app-dashboard-preview>

    <app-preview-widget *ngIf="sharedWidget" id="app-preview-widget"
        [data]="sharedWidget" [shareObject]="shareObject"></app-preview-widget>



</div>

<app-not-auth [showExitButton]="data?.exitButton" *ngIf="notAuth"></app-not-auth>