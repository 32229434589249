import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Group } from 'src/app/models/responses/groupsResponse';
import { User } from 'src/app/models/responses/usersResponse';
import { UserService } from 'src/app/services/user/user.service';
import { GroupService } from 'src/app/services/group/group.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateService } from '@ngx-translate/core';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { BussionFilterDefinition, DataStore } from 'src/app/models/responses/dataStoreResponse';

import { App } from 'src/app/models/responses/appsResponse';
import { DeployAppService } from 'src/app/services/deploy-app/deploy-app.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Column } from 'ag-grid-community';

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss']
})
export class GroupFormComponent implements OnInit {

  pageForm: FormGroup;
  title: string = "";
  isCreateForm: boolean = false;
  isShownFilterForm: boolean = false;
  groupFilterFields: Column[] = []

  users: User[] = [];
  selectedUser: any[] = [];
  compareFunctionForUser = (o1: any, o2: any) => o1 === o2;

  filterMembersGroup = this.users

  dashboards: Dashboard[] = [];
  selectedDashboards: string[] = [];
  compareFunctionForDashboard = (o1: any, o2: any) => o1 === o2;


  dataStores: any[] = [];
  selectedDataStores: any[] = [];
  compareFunctionForDataStores = (o1: any, o2: any) => o1 === o2;




  filteredMembersGroup: User[] = [];
  paginatedMembersGroup: any[] = [];
  pageSize: number = 5;
  currentPage: number = 1;
  totalPages: number = 1;

  filterDashboardsGroup: Dashboard[] = [];
  paginatedDashboarGroup: Dashboard[] = [];
  totalPages1: number = 1;
  currentPage1: number = 1;
  sorting: boolean = false

  paginatedDataSource: DataStore[] = [];
  filterDataStores: DataStore[] = [];
  totalPages2: number = 1;
  currentPage2: number = 1;

  group: Group = {
    groupId: "",
    groupName: "",
    description: "",
    domain: "",
    createdBy: "",
    updatedBy: new Date().toLocaleString(),
    createdDate: new Date().toLocaleString(),
    updatedDate: new Date().toLocaleString(),
    users: [],
    dataSources: [],
    dashboards: [],
    isSelfRegistration: 0,
    homePage: [],
    filter: []
  }

  appList: App[] = []
  mainpage: string = ''
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GroupFormComponent>,
    private userService: UserService,
    private groupService: GroupService,
    private dashboardService: DashboardService,
    private dataSourceService: DataStoreService,
    private coreService: CoreService,
    private translate: TranslateService,
    private appService: DeployAppService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    this.pageForm = new FormGroup({
      groupName: new FormControl(this.group.groupName, [Validators.required]),
    });

    this.title = this.data.type == 'update' ? this.translate.instant("GroupFormPage.TitleUpdate") : this.translate.instant("GroupFormPage.TitleCreate");
    if (this.data.group) {
      this.group = Object.assign({}, this.data.group);
      this.selectedUser = this.group.users;
      this.selectedDashboards = this.group.dashboards;
      this.selectedDataStores = this.group.dataSources;
      if (this.group.homePage[0])
        this.mainpage = this.group.homePage[0].objectId
    }
    this.getUsers();
    this.getDashboards();
    this.getDatasources();
    this.getApps()
  }

  getApps() {
    this.appService.getApps().subscribe(res => {
      this.appList = res
    })
  }

  //filter MembersGroup
  applyFilterMembersOfGroup(filterValue: string) {
    this.filteredMembersGroup = this.users.filter((user) => {
      const fullName = user.firstName + " " + user.lastName;
      return fullName.toLowerCase().includes(filterValue.toLowerCase());
    });
    this.totalPages = Math.ceil(this.filteredMembersGroup.length / this.pageSize);
    this.currentPage = 1;
    this.updatePaginatedMembersGroup();
  }
  //pagination members Group
  updatePaginatedMembersGroup() {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;

    var list = this.filteredMembersGroup.map(item => {
      return { ...item, selected: this.selectedUser.some(x => x == item.userId) }
    })
    this.paginatedMembersGroup = list.slice(start, end);

  }
  previousPage() {
    this.currentPage--;
    this.updatePaginatedMembersGroup();

  }
  nextPage() {
    this.currentPage++;
    this.updatePaginatedMembersGroup();

  }


  changeselectedlist(event: any) {
    if (event.options[0]._selected) {
      this.selectedUser.push(event.options[0]._value)
    } else {
      this.selectedUser = this.selectedUser.filter(x => x !== event.options[0]._value)
    }
  }

  //---
  //filter dashboard group
  applyFilterDashboardOfGroup(filtervalue: string) {
    this.filterDashboardsGroup = this.dashboards.filter((dashboard) => {
      return dashboard.title.toLowerCase().includes(filtervalue.toLowerCase());
    });
    this.totalPages1 = Math.ceil(this.filterDashboardsGroup.length / this.pageSize);
    this.currentPage1 = 1;
    this.updatePaginateDashboardGroup();
  }

  updatePaginateDashboardGroup(allList?) {
    const start = (this.currentPage1 - 1) * this.pageSize;
    const end = start + this.pageSize;



    var list = this.changeSort()

    if (allList) {
      this.paginatedDashboarGroup = list.filter(x => x.selected)
    } else {
      this.paginatedDashboarGroup = list.slice(start, end);
    }


  }

  changeSort() {
    var unselectedList = this.filterDashboardsGroup.filter(x => !this.selectedDashboards.includes(x.dashboardId)).map(item => {
      return { ...item, selected: this.selectedDashboards.some(x => x == item.dashboardId) }
    })


    var selectedList = this.selectedDashboards.map(item => {
      var element = this.filterDashboardsGroup.find(x => x.dashboardId == item)
      return { ...element, selected: true }
    })

    // var selectedList = this.filterDashboardsGroup.filter(x => this.selectedDashboards.includes(x.dashboardId)).map(item => {

    //   return { ...item, selected: true };
    // })



    var list = selectedList.concat(unselectedList)
    return list
  }

  sortActive() {
    this.sorting = !this.sorting
    if (this.sorting) {
      this.updatePaginateDashboardGroup(true)
      this.currentPage1 = 1
    } else {
      this.saveCurrentSort(this.paginatedDashboarGroup)
      this.updatePaginateDashboardGroup()

    }
  }

  saveCurrentSort(sortedArray: Dashboard[]) {
    console.log('this.dashboards', sortedArray)
    // sortedArray.map((item, key) => {
    //   this.dashboards.splice(key, 1, item);
    // })

    console.log('this.dashboards', sortedArray)


    this.selectedDashboards = sortedArray.map(item => {
      return item.dashboardId
    })
  }

  drop(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.paginatedDashboarGroup, event.previousIndex, event.currentIndex);

  }


  previousPageDashboard() {
    this.currentPage1--;
    this.updatePaginateDashboardGroup();
  }
  nextPageDashboard() {
    this.currentPage1++;
    this.updatePaginateDashboardGroup();
  }

  changeselectedDahboardlist(event: any) {
    if (event.options[0]._selected) {
      this.selectedDashboards.push(event.options[0]._value)
    } else {
      this.selectedDashboards = this.selectedDashboards.filter(x => x !== event.options[0]._value)
    }

    this.updatePaginateDashboardGroup()
  }

  //DatasourceOfGroup filter
  applyFilterDatasourceOfGroup(filtervalue: string) {
    this.filterDataStores = this.dataStores.filter((data => {
      return data.name.toLowerCase().includes(filtervalue.toLowerCase())
    }));
    this.totalPages2 = Math.ceil(this.filterDataStores.length / this.pageSize);
    this.currentPage2 = 1;
    this.updatePaginateDataSource();

  }
  updatePaginateDataSource() {
    const start = (this.currentPage2 - 1) * this.pageSize;
    const end = start + this.pageSize;
    var list = this.filterDataStores.map(item => {
      return { ...item, selected: this.selectedDataStores.some(x => x == item.dataStoreId) }

    })
    this.paginatedDataSource = list.slice(start, end);
  }
  previousPageDataSource() {
    this.currentPage2--;
    this.updatePaginateDataSource();
  }
  nextPageDataSource() {
    this.currentPage2++;
    this.updatePaginateDataSource();
  }

  changeselectedDatalist(event: any) {
    if (event.options[0]._selected) {
      this.selectedDataStores.push(event.options[0]._value)
    } else {
      this.selectedDataStores = this.selectedDataStores.filter(x => x !== event.options[0]._value)
    }
  }


  private getUsers() {
    this.userService.getUsers().subscribe(result => {
      this.users = result;
      this.filteredMembersGroup = result;
      this.totalPages = Math.ceil(this.filteredMembersGroup.length / this.pageSize);
      this.updatePaginatedMembersGroup();
    });
  }

  private getDashboards() {
    this.dashboardService.getDashboards().subscribe(result => {
      this.dashboards = result
      //silinen dashboardlar hala listede ise hariç tutuyoruz
      this.selectedDashboards = this.group.dashboards.filter(item =>
        this.dashboards.some(x => x.dashboardId === item)
      );

      this.filterDashboardsGroup = result;
      this.totalPages1 = Math.ceil(this.filterDashboardsGroup.length / this.pageSize);
      this.updatePaginateDashboardGroup();
    });
  }

  private getDatasources() {
    this.dataSourceService.getDatasources().subscribe(result => {
      this.dataStores = result
      this.filterDataStores = this.dataStores;
      this.totalPages2 = Math.ceil(this.filterDataStores.length / this.pageSize);
      this.updatePaginateDataSource();
    });
  }

  upsertGroup() {
    if (this.pageForm.invalid) {
      this.coreService.showFormFillError();
      return;
    }
    if (this.mainpage) {
      var date = new Date();

      this.group.homePage = [{
        assignDate: date.toISOString(),
        assignedBy: this.authService.getUser()?.userId,
        objectId: this.mainpage,
        type: 1,
        object: JSON.stringify(this.appList.find(x => x.applicationId == this.mainpage))
      }]
    } else {
      this.group.homePage = []
    }
    this.group.isSelfRegistration = this.group.isSelfRegistration ? 1 : 0
    this.group.users = this.selectedUser;
    this.group.dashboards = this.selectedDashboards;
    this.group.dataSources = this.selectedDataStores;
    this.groupService.upsertGroup(this.group).subscribe(result => {
      this.dialogRef.close(true);
    });
  }

  addFilter(e: BussionFilterDefinition) {

    if (!this.group.filter.some(x => x.fieldName == e.fieldName && x.fieldValue == e.fieldValue)) {
      this.group.filter = [...this.group.filter, e]
    } else {
      this.coreService.showFormFillError(this.translate.instant("FilterComponent.SameValue"));
    }
  }

  deleteFilter(e: BussionFilterDefinition) {
    this.group.filter = this.group.filter.filter(x => !(x.fieldValue == e.fieldValue && e.fieldName == x.fieldName))
  }

}
