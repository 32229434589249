import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { NodeResponse, Node } from 'src/app/models/responses/nodeResponse';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getNodes() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getNodes, param).pipe(
      map((response: NodeResponse) => {
        return response.message;
      })
    );
  }

  getDataNodes() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getDataNodes, param).pipe(
      map((response: NodeResponse) => {
        return response.message;
      })
    );
  }

  getDataNodeTypes() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getNodesType, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  upsertNode(node: Node) {
    let param = {
      Token: this.authService.getToken(),
      Node: node
    };
    return this.http.post(Endpoints.upsertNode, param).pipe(
      map((response: NodeResponse) => {
        return response.message;
      })
    );
  }

  deleteNode(node: Node) {
    let param = {
      Token: this.authService.getToken(),
      NodeId: node.nodeId
    };
    return this.http.post(Endpoints.deleteNode, param).pipe(
      map((response: NodeResponse) => {
        return response.message;
      })
    );
  }

  checkNodeConnectivity(node: Node) {
    let param = {
      Token: this.authService.getToken(),
      Node: node
    };
    return this.http.post(Endpoints.CheckNodeConnectivity, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

}
