<div style="display: flex;">
    <div class="badge-container" *ngFor="let item of badges">
        <div class="badge-text">
            <div [id]="'label-'+item.name">{{item.name}}</div>
            <input [id]="'input-'+item.name" style="display: none;" (keyup.enter)="save(item)" [(ngModel)]="inputvalue">
            <small>{{item.detail[item.subTextKey]}}</small>
        </div>
        <mat-icon class="close-icon" (click)="inputOpen(item.name)" [id]="'open-'+item.name"
            *ngIf="!item.disableEdit">edit</mat-icon>
        <mat-icon class="close-icon" (click)="save(item)" [id]="'save-'+item.name"
            style="display: none;">save</mat-icon>


        <!-- <mat-icon class="close-icon">clear</mat-icon> -->
    </div>
</div>