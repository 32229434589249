<app-modal-title [title]="title">
    <button *ngIf="this.data?.compileVisible" mat-button (click)="compile()">
        <mat-icon>play_arrow</mat-icon>
        {{ debugDisplay? ('Common.Editor' |
        translate ):('Common.Compile' | translate) }}</button>
    <button mat-button (click)="action(true)">{{ 'Common.Done' | translate }}</button>
</app-modal-title>
<div mat-dialog-content class="dash-dialog" style="padding: 0 !important;overflow: hidden;">
    <div *ngIf="!debugDisplay">
        <ngx-monaco-editor style="height: 100vh" [options]="editorOptions" [(ngModel)]="code">
        </ngx-monaco-editor>
    </div>
    <app-console-screen *ngIf="debugDisplay" [widget]="this.data.widget"></app-console-screen>

</div>