<div class="content">
    <!-- 'Captcha'||'MaxTries'||'Debugger' -->
    <div class="parameters">
        <app-title title="{{'ManagePage.SystemParameters' | translate}}"></app-title>
        <!-- <mat-tab label="{{'ManagePage.SystemParameters' | translate}}"> -->
        <div class="form">

            <ul>
                <li *ngIf="permissions.includes('Debugger')">
                    <mat-slide-toggle (change)="saveDebugger($event)" [checked]="isDebugger" color="primary">
                        {{'SystemParametersPage.Debugstate'|translate}}</mat-slide-toggle>
                </li>
                <li *ngIf="permissions.includes('Captcha')">
                    <mat-slide-toggle (change)="saveCaptcha($event)" [checked]="isCaptcha" color="primary">
                        {{'SystemParametersPage.CaptcaState'|translate}}</mat-slide-toggle>
                </li>
                <li *ngIf="permissions.includes('MaxTries')">
                    <mat-form-field class="example-full-width">
                        <mat-label>{{'SystemParametersPage.MaxTries'|translate}}</mat-label>
                        <input matInput [(ngModel)]="maxTries" type="number" min="0" value="0">
                    </mat-form-field>
                </li>
            </ul>

        </div>
        <ul>
            <li>
                <button mat-button class="b-btn b-success" *ngIf="permissions.includes('MaxTries')"
                    (click)="saveMaxTries()">{{ 'Common.Update' | translate }}</button>
            </li>
            <li> <mat-label class="label">{{'SystemParametersPage.LastBuild'|translate}} {{version}}</mat-label>
            </li>
        </ul><br />
        <!-- </mat-tab> -->
        <!-- <mat-tab *ngIf="permissions.includes('SystemBackupRestore')"
            label="{{'SystemParametersPage.BussionAnalyticsConfiguration'|translate}}"> -->
    </div>
    <div class="configuration">
        <app-title *ngIf="permissions.includes('SystemBackupRestore')"
            title="{{'SystemParametersPage.BussionAnalyticsConfiguration'|translate}}"></app-title>

        <div *ngIf="permissions.includes('SystemBackupRestore')" class="buttonGroup">
            <!-- <button style="margin-left:2rem" mat-button (click)="backupBussionConfiguration()"
                    class="b-btn b-primary">{{'SystemParametersPage.BackupBussionConfiguration'|translate}}</button>
                <button style="margin-left:2rem" mat-button (click)="restoreBussionConfiguration()"
                    class="b-btn b-primary">{{'SystemParametersPage.RestoreBussionConfiguration'|translate}}</button>
                <button mat-button (click)="downloadZipFile()"
                    class="b-btn b-primary">{{'SystemParametersPage.DownloadCurrentConfiguration'|translate}}</button>
                <button mat-button class="b-btn b-primary"
                    (click)="openDashboard($event)">{{'SystemParametersPage.UploadConfiguration'|translate}}</button> -->
            <ul>
                <li><button style="margin-left:2rem; width: 18rem;height: 3rem;" mat-button
                        (click)="backupBussionConfiguration()" class="b-btn b-primary"><mat-icon
                            svgIcon="backup"></mat-icon>
                        {{'SystemParametersPage.BackupBussionConfiguration'|translate}}</button>
                </li>
                <li><button style="margin-left:2rem;width: 18rem;height: 3rem;" mat-button
                        (click)="restoreBussionConfiguration()" class="b-btn b-primary"><mat-icon
                            svgIcon="restore"></mat-icon>
                        {{'SystemParametersPage.RestoreBussionConfiguration'|translate}}</button>
                </li>
                <li><button style="margin-left:2rem;width: 18rem;height: 3rem;" mat-button (click)="downloadZipFile()"
                        class="b-btn b-primary"><mat-icon svgIcon="download"></mat-icon>
                        {{'SystemParametersPage.DownloadCurrentConfiguration'|translate}}</button>
                </li>
                <li><button style="margin-left:2rem;width: 18rem;height: 3rem;" mat-button class="b-btn b-primary"
                        (click)="openDashboard($event)"><mat-icon svgIcon="upload"></mat-icon>
                        {{'SystemParametersPage.UploadConfiguration'|translate}}</button>
                </li>
            </ul>
        </div>
    </div>
    <div *ngIf="permissions.includes('SystemBackupRestore')" class="bussionMain">
        <app-title *ngIf="permissions.includes('SystemBackupRestore')"
            title="{{'BussionMainPage.BussionConfiguration'|translate}}"></app-title>
       
            <div class="mainButtonGroup">

            <button style="width: 18rem;height: 3rem;" mat-button
                (click)="openBussionMain()" class="b-btn b-primary"><mat-icon svgIcon="main"></mat-icon>
                {{'BussionMainPage.BussionMainPage'|translate}}</button>

            <button style="margin-left:2rem;width: 18rem;height: 3rem;" mat-button (click)="openBussionLogin($event)"
                class="b-btn b-primary"><mat-icon svgIcon="login"></mat-icon>
                {{'BussionMainPage.BussionLoginPage'|translate}}</button>

            <button style="margin-left:2rem;width: 18rem;height: 3rem;" mat-button (click)="openLanguageSetting($event)"
                class="b-btn b-primary">
                <mat-icon>language</mat-icon>
                {{'SystemParametersPage.LanguageSetting'|translate}}</button>
        </div>
    </div>
    <!-- </mat-tab> -->
</div>