import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-context-menu',
  templateUrl: './custom-context-menu.component.html',
  styleUrls: ['./custom-context-menu.component.scss']
})
export class CustomContextMenuComponent implements OnInit {
  @Input() menuList:any[]=[]

  constructor() { }

  ngOnInit(): void {
  }

}
