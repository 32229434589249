<app-modal-title [title]="title"></app-modal-title>
<mat-dialog-content class="mat-typography">

    <app-title title="{{ 'WidgetLibraryFormPage.About' | translate }}"></app-title>

    <div class="icon-avatar">
        <div class="img">
            <img src="{{widgetLibrary.icon}}" onError="this.src ='../../../assets/images/chart.jpeg'"
                (click)="photoUpload.click()" />
        </div>
        <input hidden type="file" #photoUpload (change)="uploadIconImage($event)" />
    </div>

    <form [formGroup]="pageForm">
        <mat-form-field>
            <mat-label>{{ 'WidgetLibraryFormPage.Name' | translate }}</mat-label>
            <input matInput [(ngModel)]="widgetLibrary.name" formControlName="name" required>
        </mat-form-field>
        <br>

        <mat-form-field>
            <mat-label>{{ 'WidgetLibraryFormPage.Folder' | translate }}</mat-label>
            <input matInput [(ngModel)]="widgetLibrary.folder" [disabled]="!isCreateForm" formControlName="folder"
                required>
        </mat-form-field>
        <br>
    </form>

    <mat-form-field *ngIf="!isCreateForm">
        <mat-label>CreatedBy</mat-label>
        <input matInput [(ngModel)]="widgetLibrary.createdby" disabled>
    </mat-form-field>
    <br>

    <mat-form-field *ngIf="!isCreateForm">
        <mat-label>UpdatedBy</mat-label>
        <input matInput [(ngModel)]="widgetLibrary.updatedby" disabled>
    </mat-form-field>
    <br>

    <mat-form-field *ngIf="!isCreateForm">
        <mat-label>Update Time</mat-label>
        <input matInput value="{{widgetLibrary.updateDate}}" disabled>
    </mat-form-field>
    <br>


    <app-title title="Widget Files Path" buttonTitle="+ {{ 'WidgetLibraryFormPage.AddNewFile' | translate }}"
        (btnEvent)="fileUpload.click()" [isDisabled]="widgetLibrary.folder.length == 0"
        buttonTitle2="- {{ 'WidgetLibraryFormPage.DeleteAllFile' | translate }}" (btnEvent2)="deleteAllFile()"
        [isDisabled2]="this.filePaths.length == 0"></app-title>
    <input hidden type="file" #fileUpload (change)="uploadFile($event)" />

    <div class="listContent">
        <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="selectedPaths" class="example-list"
            (cdkDropListDropped)="drop($event)">
            <mat-selection-list [(ngModel)]="selectedPaths" (selectionChange)="onSelection($event)">
                <div *ngFor="let path of selectedPaths" class="deleteable-selection" cdkDrag>
                    <mat-list-option [value]="path">
                        {{path}}
                    </mat-list-option>
                </div>
            </mat-selection-list>
        </div>
    </div>

    <div class="listContent">
        <mat-selection-list #unselectedList (selectionChange)="onSelection($event)">
            <div *ngFor="let path of filePaths" class="deleteable-selection">
                <mat-list-option [value]="path">
                    {{path}}
                </mat-list-option>
                <mat-icon matListIcon color="warn" (click)="deleteFilePath(path)">delete_outline</mat-icon>
            </div>
        </mat-selection-list>
    </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="!isCreateForm" (click)="upsertWidgetLibrary()">{{
        'Common.Update' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="isCreateForm" (click)="upsertWidgetLibrary()">{{
        'Common.Create' | translate }}</button>
</mat-dialog-actions>