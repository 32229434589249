import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { SelfServiceDatastoreFormComponent } from '../../self-service-datastore-form/self-service-datastore-form.component';
import { DataList, DataStore } from 'src/app/models/responses/dataStoreResponse';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelfService } from 'src/app/services/self-service/self.service';
import { concatMap, tap } from 'rxjs/operators';
import { DatastoreFormComponent } from 'src/app/pages/manage/datastore/datastore-form/datastore-form.component';
import { SharePersonFormComponent } from '../../share-person-form/share-person-form.component';
import { CoreService } from 'src/app/services/core/core.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-self-service-data-store-list',
  templateUrl: './self-service-data-store-list.component.html',
  styleUrls: ['./self-service-data-store-list.component.scss']
})
export class SelfServiceDataStoreListComponent implements OnInit {

  constructor(
    private modalService: ModalService,
    private dataStoreService: DataStoreService,
    private spinner: NgxSpinnerService,
    private selfService: SelfService,
    private translate: TranslateService,
    private coreService: CoreService,
    private authService: AuthService
  ) { }

  dataStores: DataStore[] = [];
  formData = new FormData()
  formDatacolumns: { FieldName: string, FieldType: string, FieldFilter: string }[] = [];
  userData = {
    Status: 1,
    UserDataId: "",
    NodeId: "",
    Name: "",
    CollectionName: "",
    DataDefinition: "",
    UserId: "",
    Columns: [],
    Users: ""
  };
  dataList: DataList[] = []
  allDataStores: DataStore[] = []
  userId: string;
  currentPage = 0;
  itemsPerPage = 5;
  totalPages = 0;
  paginatedDataStores: DataStore[] = [];
  searchColumns: string[] = ['name'];
  routeList = [{ path: "/selfservice", name: this.translate.instant("SelfServicePage.SelfServiceHome") }, { path: "/selfservice/data-store-list", name: this.translate.instant("SelfServicePage.MyDatas") }]
  displayLoading = false; // Başlangıçta yüklenme ekranını gizle
  showNoDataStores = false;

  ngOnInit(): void {

    this.getDatastores();
  }
  avatarKeys = { avatar: 'avatar', tooltip: 'firstName lastName', avatartext: 'firstName', avatartext2: 'lastName' }
  columns: any[] = [{ title: 'DataStorePage.icon', width: '10%', selector: 'image', mobile: true }, { title: 'DataStorePage.title', width: '40%', selector: 'name', mobile: true },
  { title: 'DataStorePage.Description', width: '40%', selector: 'description', mobile: false }, { title: 'Shared', width: '15%', selector: 'avatar', avatarselector: 'users', avatarKeys: this.avatarKeys, mobile: true }, { title: '', width: '5%', selector: 'actionicon', mobile: true },
  { title: 'DataStorePage.Action', width: '5%', selector: 'action', mobile: true }]


  openDataStoreComponent(event: any, onlyPreview: boolean) {
    let data = {
      type: event.type as string,
      dataStore: event.data as DataStore,
      onlyPreview: onlyPreview
    }
    this.modalService.openModal(data, SelfServiceDatastoreFormComponent, '100%', '90%', 'fullScreen').subscribe(result => {

      if (result === true) {
        this.getDatastores();

        // this.selfService.addDataStore();
      }
    });
  }
  openEditModal(datastore: any, onlyPreview: boolean) {
    let data = {
      type: 'update',
      data: datastore,
      onlyPreview: onlyPreview
    }
    // this.modalService.openModal(data, DatastoreFormComponent, 'auto', '1000px').subscribe(result => {

    //   this.getDatastores();
    // });
    this.openDataStoreComponent(data, onlyPreview)

  }


  getDatastores() {
    this.displayLoading = true;
    this.selfService.getDataList().pipe(
      tap(res => this.dataList = res),
      tap(res => console.log('fi')),
      concatMap(res => this.dataStoreService.getDataSourceForUser())
    ).subscribe(result => {
      this.dataStores = result

      this.dataList.map(item => {
        var commonElement = this.dataStores.find(x => x.dataStoreId == item.userDataId)
        if (commonElement) {
          Object.assign(commonElement, item)
        }
      })

      this.userId = localStorage?.getItem('userId');
      let filteredDataStore = this.dataStores.filter(dataStore => dataStore.ownerId === this.userId && dataStore.isSelfService == 1);
      this.allDataStores = filteredDataStore
      this.dataStores = filteredDataStore.map(item => {
        return { ...item, users: item.users ? JSON.parse(item.users) : '' }
      });

      this.displayLoading = false;
      this.showNoDataStores = true;


      this.updatePagination();
    })

  }

  updatePagination() {
    this.totalPages = Math.ceil(this.dataStores.length / this.itemsPerPage);
    this.currentPage = 0;
    this.paginatedDataStores = this.dataStores.slice(
      this.currentPage * this.itemsPerPage,
      (this.currentPage + 1) * this.itemsPerPage
    );
  }

  paginateDataStores(event: any) {
    this.currentPage = event.pageIndex;
    this.paginatedDataStores = this.dataStores.slice(
      event.pageIndex * event.pageSize,
      (event.pageIndex + 1) * event.pageSize
    );
  }

  deleteDataStore(dataStore: DataStore) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.dataStoreService.deleteDataStore(dataStore).subscribe(result => {
          this.spinner.hide();
          this.getDatastores();
        });
      }
    });
  }

  updateDatastoreUser(datastore: DataStore) {
    this.formData = new FormData();
    this.formData.append('File', '')
    this.formData.append('Name', datastore.name)
    this.formData.append('Token', this.authService.getToken())
    this.formData.append('NodeId', '0')
    this.formData.append('append', '0')
    this.userData.UserDataId = datastore.dataStoreId;
    this.userData.Name = datastore.name
    this.userData.UserId = localStorage.getItem('userId')
    this.userData.Columns = this.formDatacolumns
    this.userData.Users = datastore.users

    this.formData.append('UserData', JSON.stringify(this.userData))

    this.selfService.addDataStore(this.formData).subscribe(result => {
      if (result) {
        this.coreService.showSuccessMessage(result);
        this.getDatastores();
      }

    })
  }




  filterData(filteredDatas: DataStore[]) {
    this.dataStores = filteredDatas

    this.updatePagination();
  }

  // getRouteElements() {
  //   return [{ path: "/selfservice", name: "Self Service Home" }, { path: "/selfservice/data-store-list", name: "My Datas" }]
  // }
  openShareForm(selected: DataStore) {
    
    var data = {
      selectedUsers: selected.users,
      title: 'UserCard.ShareDataStore'
    }
    this.modalService.openModalBeforeClosed(data, SharePersonFormComponent, '90%', '40%', 'responsive-modal').subscribe(result => {

      if (result.length > 0) {
        var datastore = { ...selected, users: JSON.stringify(result) }
        this.updateDatastoreUser(datastore)
      }else{
        var datastore = { ...selected, users: '[]' }
        this.updateDatastoreUser(datastore)
      }

    });
  }

  actionButtonClick(data: any) {
    if (data.type == 'edit')
      this.openEditModal(data.data, false)
    else if (data.type == 'delete')
      this.deleteDataStore(data.data)
    else if (data.type == 'share')
      this.openShareForm(data.data)
    else if (data.type == "preview") {
      this.openEditModal(data.data, true)
    }
  }
}
