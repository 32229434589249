import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BussionMainService } from 'src/app/services/bussion-main/bussion-main.service';
import { CoreService } from 'src/app/services/core/core.service';
import { CodeEditorComponent } from '../code-editor/code-editor.component';

@Component({
  selector: 'app-bussion-login',
  templateUrl: './bussion-login.component.html',
  // template:'',
  styleUrls: ['./bussion-login.component.scss']
})
export class BussionLoginComponent implements OnInit {

  constructor(
    private bussionMainService: BussionMainService,
    private coreService: CoreService,
    private dialog: MatDialog
  ) { }
  editorOptions = { theme: 'vs-dark', language: 'html' };
  code: string = 'function x() {\nconsole.log("Hello world!");\n}';
  fileType: string = 'html'
  ngOnInit(): void {
    this.getBussionLogin('html');
  }

  getBussionLogin(fileType: string) {
    this.bussionMainService.getBussionLogin(fileType).subscribe(res => {
      this.code = res;
    })
  }

  setBussionLogin(content) {
    this.bussionMainService.setBussionLogin(this.code, this.fileType).subscribe(res => {
      if (res)
        this.coreService.showSuccessMessage("Kod güncellendi")
      else {
        this.coreService.showErrorMessage("Hata oluştu.")
      }
    })
  }

  openBiggerEditor() {
    const dialogRef = this.dialog.open(CodeEditorComponent, {
      height: '100%',
      width: '100%',
      data: {
        code: this.code,
        widget: {},
        debug: () => { },
        editorOptions: { theme: 'vs-dark', language: 'html' }
      },
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.code = result;
      }
    });
  }


  changeEditor(fileType: string) {
    this.fileType = fileType
    this.editorOptions.language=fileType
    console.log(this.editorOptions)
    this.getBussionLogin(fileType)
  }

}
