import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-expand-table',
  templateUrl: './expand-table.component.html',
  styleUrls: ['./expand-table.component.scss']
})
export class ExpandTableComponent implements OnInit {

  @Input() title: string;
  @Input() addButtonText: string;
  @Input() secondButtonText: string;
  @Input() columnsWidth:any

  @Input() filterPlaceholder: string;
  @Input() displayedColumns: string[];
  @Input() displayedColumnsName: string[];
  @Input() columnWidth: string[] = [];
  @Input() pageSizeOptions: number[] = [10, 25, 100]

  @Input() listTypeButton:boolean=false

  listStyleButton:string='folder'

  @Input() showRunTask: boolean;
  @Input() showDesigner: boolean;
  @Input() showStopTask: boolean;
  @Input() buttonActive: boolean = true;
  @Input() paginationActive: boolean = true;
  @Input() butonIcon: string = 'add_dashboard';
  @Input() exportLogButton: boolean = false;
  _treeData: any[] = []
  @Input() set treedataSource(value) {
    this._treeData = value

    this._treeData.map(item => {
      item.children = this.createDataTable(item.children);
    })

  }

  @Input() imageWidth: string; // Görüntü genişliği için giriş özelliği
  @Input() imageHeight: string; // Görüntü yüksekliği için giriş özelliği
  @Input() set data(value: any) {
    if (value) {
      this.createDataTable(value);

    }
  }
  inputValue: string = ''

  @Output() openModal = new EventEmitter<any>();
  @Output() deleteRow = new EventEmitter<any>();
  @Output() runTaskEvent = new EventEmitter<any>();
  @Output() stopTaskEvent = new EventEmitter<any>();
  @Output() exportLog = new EventEmitter<any>();
  @Output() openDashboards = new EventEmitter<any>();
  @Output() secondButtonFunc = new EventEmitter<any>();
  @Output() changeListStyleFunction= new EventEmitter<any>();



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<any>;
  baseUrl = environment.imageUrl;

  panelOpenState: boolean = false

  constructor() { }

  ngOnInit(): void {

  }

  private createDataTable(result: any[]) {
    const dataTable = result;
    this.dataSource = new MatTableDataSource(dataTable);
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.inputValue) {
      var event = {
        target: {
          value: this.inputValue
        }
      }
      this.applyFilter(event)
    }
    return this.dataSource
  }

  applyFilter(event: any) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.inputValue = (event.target as HTMLInputElement).value;


    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

  open(data: any, type: string) {
    this.openModal.emit({ data: data, type: type });
  }
  openDashboard(item: any) {
    this.openDashboards.emit(item);
  }
  export(item: any) {
    this.exportLog.emit(item);
  }
  delete(item: any) {
    this.deleteRow.emit(item);
  }

  runTask(item: any) {
    this.runTaskEvent.emit(item);
  }

  stopTask(item: any) {
    this.stopTaskEvent.emit(item);
  }

  secondButton() {
    this.secondButtonFunc.emit()
  }

  changeListStyle(){
    this.changeListStyleFunction.emit(this.listStyleButton)
  }

}
