import { Component, OnInit, ViewChild } from '@angular/core';

import { UserService } from 'src/app/services/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateUserComponent } from './create-user/create-user.component';
import { User } from 'src/app/models/responses/usersResponse';
import { ActionDialogComponent } from 'src/app/components/action-dialog/action-dialog/action-dialog.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  displayedColumns: string[] = ['avatar','userId', 'firstName', 'lastName', 'email', 'lastLogin', 'action'];
  displayedColumnsName: string[] = ['Avatar',this.translate.instant("UsersPage.userId"), this.translate.instant("UsersPage.FirstName"), this.translate.instant("UsersPage.LastName"), this.translate.instant("UsersPage.Email"), this.translate.instant("UsersPage.LastLogin"), this.translate.instant("UsersPage.Action")];
  users: User[] = [];

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  init() {
    this.getUsers()
  }

  getUsers() {
    this.spinner.show();
    this.userService.getUsers().subscribe(result => {
      this.spinner.hide();
      this.users = result;
    });
  }

  openUser(event: any) {
    let data = {
      type: event.type as string,
      user: event.data as User
    }
    this.modalService.openModal(data, CreateUserComponent, 'auto', '1000px').subscribe(result => {
      if (result === true) {
        this.getUsers();
      }
    });
  }

  deleteUser(user: User) {

    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.userService.deleteUser(user).subscribe(result => {
          this.getUsers();
        });
      }
    });
  }

}
