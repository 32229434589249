import { Component, OnInit, Inject } from '@angular/core';
import { Node, NodeType } from 'src/app/models/responses/nodeResponse';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { NodeService } from 'src/app/services/node/node.service';
import { ConnectorService } from 'src/app/services/connector/connector.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateService } from '@ngx-translate/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-node-form',
  templateUrl: './node-form.component.html',
  styleUrls: ['./node-form.component.scss']
})
export class NodeFormComponent implements OnInit {

  pageForm: UntypedFormGroup;
  compareFunctionForNodeType = (o1: any, o2: any) => o1 === o2.toString();
  isCreateForm: boolean = true;
  title: string = "";
  nodeTypes: NodeType[] = [];
  useConnectionString: boolean = false
  selfServiceEnabled: boolean = false
  defaultdbvalues: any[] = [
    { db: 'PostgreSQL', default: 'postgres' },
    { db: 'MySQL', default: 'information_schema' },
    { db: 'Clickhouse', default: 'system' },
    { db: 'MongoDB', default: 'none' },
  ]
  node: Node = {
    nodeId: "",
    nodeType: 0,
    dataNodeType: "",
    name: "",
    host: "",
    port: "27017",
    userName: "",
    password: "",
    ssl: 0,
    timeOut: 5,
    defaultDatabase: "",
    connectionString: "",
    selfServiceEnabled: 0
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NodeFormComponent>,
    private spinner: NgxSpinnerService,
    private nodeService: NodeService,
    private connectorService: ConnectorService,
    private coreService: CoreService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.pageForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.node.name, [Validators.required]),
      host: new UntypedFormControl(this.node.host, [Validators.required]),
      port: new UntypedFormControl(this.node.port, [Validators.required]),
      timeOut: new UntypedFormControl(this.node.timeOut, [Validators.required, Validators.min(1)]),
      userName: new UntypedFormControl(this.node.userName, [Validators.required]),
      password: new UntypedFormControl(this.node.password, [Validators.required]),
      defaultDatabase: new UntypedFormControl(this.node.defaultDatabase, [Validators.required]),
      connectionString: new UntypedFormControl(this.node.connectionString)

    });
    this.title = this.data.type == 'update' ? this.translate.instant("NodeFormPage.TitleUpdate") : this.translate.instant("NodeFormPage.TitleCreate");
    this.isCreateForm = this.data.type == 'update' ? false : true;
    if (this.data.node) {
      this.node = Object.assign({}, this.data.node);
      this.selfServiceEnabled = this.node.selfServiceEnabled == 1 ? true : false

      if (this.node.defaultDatabase == "")
        this.node.defaultDatabase = this.defaultdbvalues.find(x => x.db.includes(this.data.node.dataNodeType)).default

      this.node.password = "";

      if (this.node.connectionString !== "") {
        this.useConnectionString = true
      }
      this.changeFormValidator()

    }
    this.getNodesType();
  }

  changedefaultdb(dataNodeType: string) {
    if (this.node.defaultDatabase == "")
      this.node.defaultDatabase = this.defaultdbvalues.find(x => x.db.includes(dataNodeType))?.default


    var selectednodetype = this.nodeTypes.find(x => x.name == dataNodeType)
    this.node.port = selectednodetype.port.toString()


  }

  getNodesType() {
    this.nodeService.getDataNodeTypes().subscribe((types: NodeType[]) => {

      this.nodeTypes = types
    })
  }

  nodeTypeSelected() {
    this.node.port = this.node.nodeType == 0 ? "27017" : "7000";
  }

  saveNode() {
    if (this.node.nodeType == 0) {
      this.upsertNode();
    } else {
      this.initiliazeConnector();
    }
  }

  upsertNode() {
    if (this.pageForm.invalid) {
      this.coreService.showFormFillError();
      return;
    }
    this.spinner.show();
    this.nodeService.upsertNode(this.node).subscribe(result => {
      this.spinner.hide();
      this.dialogRef.close(true);
    });
  }

  initiliazeConnector() {
    this.connectorService.initiliazeConnector(this.node.userName, this.node.password, this.node.port, this.node.host).subscribe(result => {
      this.upsertNode();
    });
  }

  changeFormValidator() {
    if (this.useConnectionString) {
      this.pageForm.controls["host"].clearValidators();
      this.pageForm.controls["port"].clearValidators();
      this.pageForm.controls["timeOut"].clearValidators();
      this.pageForm.controls["userName"].clearValidators();
      this.pageForm.controls["password"].clearValidators();
      this.pageForm.controls["defaultDatabase"].clearValidators();
      this.pageForm.controls["connectionString"].addValidators([Validators.required]);
    } else {
      this.pageForm.controls["host"].addValidators([Validators.required]);
      this.pageForm.controls["port"].addValidators([Validators.required]);
      this.pageForm.controls["timeOut"].addValidators([Validators.required]);
      this.pageForm.controls["userName"].addValidators([Validators.required]);
      this.pageForm.controls["password"].addValidators([Validators.required]);
      this.pageForm.controls["defaultDatabase"].addValidators([Validators.required]);
      this.pageForm.controls["connectionString"].clearValidators()
    }

    ['host', 'port', 'timeOut', 'userName', 'password', 'defaultDatabase', 'connectionString'].forEach(field => {
      this.pageForm.controls[field].updateValueAndValidity();
    });
  }


  checkNodeConnectivity() {
    if (this.node.password) {
      this.nodeService.checkNodeConnectivity(this.node).subscribe(res => {
        this.coreService.showSuccessMessage(res)
      })
    } else {
      this.coreService.showErrorMessage(this.translate.instant("NodeFormPage.EnterPassword"))
    }


  }

  checkConnection(con: MatCheckboxChange) {
    this.useConnectionString = con.checked
    if (!this.useConnectionString) {
      this.node.connectionString = ""
    }


    this.changeFormValidator()
  }

  selfServiceToggle(event) {
    this.node.selfServiceEnabled = event.checked ? 1 : 0
  }

}

