<app-custom-modal
    [title]="this.data.type.includes('edit') ? ('ScriptForm.AddScript'|translate):('ScriptForm.UpdateScript'|translate)"
    [subtitle]="'ScriptForm.ModalSubTitle'" img="/assets/images/hypertext.png">
    <div class="form-body" body>

        <div *ngIf="selectedTabIndex==0">
            <form [formGroup]="scriptForm">
                <div style="display: flex;align-items: center;justify-content: end;">
                    <button (click)="selectedTabIndex=1" mat-stroked-button class="custom-primary-stroked-button">
                        {{ 'Common.Code' | translate }}
                    </button>
                </div>
                <div class="icon-area">
                    <app-photo-upload type="icon"
                        [photoUrl]="data?.script?.icon ? data?.script?.icon : '/assets/images/defaultScript.png'"
                        (photoLoaded)="uploadIconImage($event)"></app-photo-upload>
                    <div style="width: 80%;">
                        <!-- fromflow -->
                        <div class="form-inputs">
                            <mat-form-field [class]="data.type=='fromflow'?'flex-100':'flex-50'">
                                <mat-label>{{ 'ScriptForm.Name' | translate }}</mat-label>
                                <input matInput formControlName="name">
                            </mat-form-field>
                            <mat-form-field [class]="data.type=='fromflow'?'flex-100':'flex-50'">
                                <mat-label>{{ 'ScriptForm.Package' | translate }}</mat-label>
                                <!-- <mat-select formControlName="package">
                                    <mat-option value="Data">Data</mat-option>
                                    <mat-option value="AI">AI</mat-option>
                                    <mat-option value="Script">Script</mat-option>
                                    <mat-option value="Default">Default</mat-option>
                                </mat-select>  -->
                                <input type="text" matInput formControlName="package" [matAutocomplete]="autoPackage">
                                <mat-autocomplete #autoPackage="matAutocomplete">
                                    <mat-option *ngFor="let option of pythonPackagesfilteredOptions | async"
                                        [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field [class]="data.type=='fromflow'?'flex-100':'flex-50'">
                                <mat-label>{{ 'ScriptForm.Timeout' | translate }}</mat-label>
                                <input matInput type="number" formControlName="timeout">
                            </mat-form-field>
                            <mat-form-field [class]="data.type=='fromflow'?'flex-100':'flex-50'">
                                <mat-label>{{ 'ScriptForm.PythonExecuteable' | translate }}</mat-label>
                                <input matInput formControlName="pythonExecuteable">
                            </mat-form-field>
                        </div>
                        <!-- <div style="display: flex;gap: 5px;">
                          
                        </div> -->
                    </div>



                </div>
                <mat-form-field>
                    <mat-label>{{ 'ScriptForm.Desc' | translate }}</mat-label>
                    <textarea matInput formControlName="description"></textarea>
                </mat-form-field>
                <div style="display: flex;align-items: center;justify-content: space-between;">
                    <mat-checkbox formControlName="stopIfFailed" class="example-margin ">
                        {{"ScriptForm.StopIfFailed"|translate}}
                    </mat-checkbox>
                </div>

            </form>

            <!-- parameter section -->
            <div *ngIf="!this.data?.type?.includes('flow')" style="max-width: 1000px;">
                <app-title [title]="'Parametreler'"
                    [buttonTitle]="visibleParameterForm ?'Parametre Listesi':'Parametre ekle'"
                    (btnEvent)="visibleParameterForm=!visibleParameterForm"></app-title>
                <!-- parameterList section -->
                <div class="parameter-List" *ngIf="!visibleParameterForm"
                    style="max-height: 220px;overflow: auto;padding: 10px;" cdkDropList
                    (cdkDropListDropped)="drop($event)">
                    <div *ngIf="parameterList.length<=0" style="text-align: center;">
                        <span>{{"FlowPage.EmptyParameter"|translate}}</span>
                    </div>
                    <mat-accordion *ngIf="parameterList.length>0">
                        <div *ngFor="let item of parameterList" style="margin-bottom: 5px;" cdkDrag>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{item?.key}}
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        <span *ngIf="item?.shown==1">{{item?.value}}</span>
                                        <div *ngIf="item?.shown==0">
                                            <span
                                                *ngFor="let star of [].constructor(getStarCount(item)); let i = index">*</span>
                                        </div>

                                    </mat-panel-description>
                                    <mat-icon (click)="deleteParameter($event,item)" svgIcon="delete-box"
                                        class="mr-10"></mat-icon>
                                    <mat-icon (click)="editParameter(item,$event)" svgIcon="edit-box"
                                        class="mr-10"></mat-icon>
                                </mat-expansion-panel-header>
                                <p>{{item?.description}}</p>
                            </mat-expansion-panel>
                        </div>
                    </mat-accordion>
                </div>
                <!-- parameter form section -->
                <form [formGroup]="parameterForm" *ngIf="visibleParameterForm">
                    <div class="parameter-form">
                        <div style="display: flex;align-items: center;justify-content: space-between;">
                            <div style="flex-basis: 45%;">
                                <label>{{"ScriptForm.Key"|translate}}*</label>
                                <!-- <input formControlName="key" class="custom-gray-input" /> -->



                                <!-- <mat-form-field class="example-full-width" appearance="fill"> -->
                                <!-- <mat-label>Number</mat-label> -->
                                <input type="text" aria-label="Number" matInput formControlName="key"
                                    class="custom-gray-input" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option (click)="selectIdKey()" *ngFor="let option of filteredOptions | async"
                                        [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                                <!-- </mat-form-field> -->
                            </div>
                            <div style="flex-basis: 45%;">
                                <label>{{"ScriptForm.Type"|translate}}*</label>
                                <mat-select formControlName="type" class="custom-gray-input">
                                    <mat-option value="System.String">String</mat-option>
                                    <mat-option value="System.DateTime">Date Time</mat-option>
                                    <mat-option value="System.Number">Number</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div style="display: flex;align-items: center;justify-content: space-between;">
                            <div style="flex-basis: 45%;">
                                <label>{{"ScriptForm.Value"|translate}}</label>
                                <textarea formControlName="value" class="custom-gray-input" [rows]="6"></textarea>
                            </div>
                            <div style="flex-basis: 45%;">
                                <label>{{"ScriptForm.Description"|translate}}</label>
                                <textarea formControlName="description" class="custom-gray-input" [rows]="6"></textarea>
                            </div>
                        </div>
                        <div style="display: flex;justify-content: space-between;">
                            <div>
                                <mat-checkbox formControlName="mandatory" class="example-margin mr-10">
                                    {{"ScriptForm.MandatoryParameter"|translate}}
                                </mat-checkbox>
                                <mat-checkbox formControlName="shown" class="example-margin ">
                                    {{"ScriptForm.ShowParameter"|translate}}
                                </mat-checkbox>
                            </div>

                            <button mat-stroked-button class="custom-primary-stroked-button"
                                (click)="addParameterElement()">
                                {{ this.parameterIsUpdate?('Common.Update' | translate):('Common.Add' |
                                translate) }}
                            </button>
                        </div>

                    </div>

                </form>

            </div>
        </div>
        <div *ngIf="selectedTabIndex==1" style="min-width: 90vw;">

            <div style="display: flex;justify-content: space-between;margin-bottom: 10px;align-items: center;">
                <div style="display: flex;align-items: center;">
                    <app-photo-upload *ngIf="data?.script?.icon" type="icon"
                        [photoUrl]="data?.script?.icon ? data?.script?.icon : '/assets/images/defaultScript.png'"
                        (photoLoaded)="uploadIconImage($event)" [width]="'25px'" class="mr-10"></app-photo-upload>
                    <div>{{scriptObject.name}}</div>

                </div>
                <div>
                    <button (click)="selectedTabIndex=0" mat-stroked-button class="custom-primary-stroked-button">
                        {{ 'Common.Form' | translate }}
                    </button>
                </div>

                <!-- <div class="d-flex">
                    <button mat-button *ngIf="!showConsole" (click)="openBiggerEditor()">{{
                        'Common.ShowBiggerEditor' | translate}}</button>
                    <button mat-button *ngIf="!showConsole" (click)="showConsole=true"> <mat-icon>play_arrow</mat-icon>{{ 'Common.Compile' |
                        translate}}</button>
                    <button mat-button *ngIf="showConsole" (click)="showConsole=false">{{'Common.Editor' |translate
                        }}</button>
                </div> -->


            </div>

            <div style="display: flex;justify-content: end;">
                <button mat-button *ngIf="!showConsole" (click)="openBiggerEditor()">{{
                    'Common.ShowBiggerEditor' | translate}}</button>
            </div>
            <!-- <app-console-screen style="height: 95%" *ngIf="showConsole" [widget]="scriptObject"></app-console-screen> -->
            <ngx-monaco-editor *ngIf="!showConsole" style="height: 60vh;width: 100%" [options]="editorOptions"
                [(ngModel)]="scriptObject.code" (onInit)="editorInit($event)">
            </ngx-monaco-editor>
        </div>



        <div style="display: flex;justify-content: end;margin-top: 10px;">
            <button (click)="deleteElement()" mat-stroked-button class="custom-primary-stroked-button mr-10">
                {{ 'Common.Delete' | translate }}
            </button>
            <button (click)="closeModal()" mat-stroked-button class="custom-primary-stroked-button mr-10">
                {{ 'Common.Cancel' | translate }}
            </button>
            <button *ngIf="this.data.script" (click)="upsertScript('saveAs')" mat-raised-button
                class="custom-primary-button mr-10">
                {{ 'Common.SaveAs' | translate }}
            </button>
            <button (click)="upsertScript()" mat-raised-button class="custom-primary-button ">
                {{this.data.script ? ('Common.Update' | translate) : ('Common.Save' | translate) }}
            </button>

        </div>

    </div>

</app-custom-modal>