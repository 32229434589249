<div class="grid-container"
    [ngStyle]="{'height':'100%' }">
    <gridster [options]="options">
        <gridster-item [item]="item" *ngFor="let item of dashboardWidgets">
            <app-widget-preview style="height: 100%;"  [showZoom]="true" [showShare]="true" [widget]="item"
            (expandEvent)="expandWidget($event)" (shareEvent)="openShareForm($event)"
            [selectedDashboard]="_dashboard" class="gridster-item">
            </app-widget-preview>
        </gridster-item>
    </gridster>
</div>