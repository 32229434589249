import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { SystemParametersService } from 'src/app/services/system-parameters/system-parameters.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-bussion-logs',
  templateUrl: './bussion-logs.component.html',
  styleUrls: ['./bussion-logs.component.scss']
})
export class BussionLogsComponent implements OnInit {

  constructor(
    private systemParametersService: SystemParametersService,
    private translate: TranslateService

  ) { }
  serverLogs: any[] = [];
  connectorLogs: any[] = [];
  displayedColumns = ['no', 'message'];
  displayedColumnsName: string[] = [this.translate.instant("LogsPage.No"), this.translate.instant("LogsPage.LogsMessage")]

  ngOnInit(): void {
    this.getServerLogs()
    this.getConnectorLogs()
  }
  refreshServerLogTable(event: any) {
    this.getServerLogs()
  }
  refreshConnectorLogTable(event: any) {
    this.getConnectorLogs()
  }
  getServerLogs() {
    this.systemParametersService.getLogs('Server').subscribe(res => {
      this.serverLogs = res.map((item, key) => {
        return { message: item, no: key + 1 }
      })

    })
  }
  getConnectorLogs() {
    this.systemParametersService.getLogs('Connector').subscribe(res => {
      this.connectorLogs = res.map((item, key) => {
        return { message: item, no: key + 1 }
      })
    })
  }

  exportToExcelServerLog() {
    const worksheet = XLSX.utils.json_to_sheet(this.serverLogs);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    FileSaver.saveAs(data, 'ServerLogs.xlsx');
  }
  exportToExcelConnector() {
    const worksheet2 = XLSX.utils.json_to_sheet(this.connectorLogs);
    const workbook2 = { Sheets: { 'data': worksheet2 }, SheetNames: ['data'] };
    const excelBuffer2 = XLSX.write(workbook2, { bookType: 'xlsx', type: 'array' });
    const data2 = new Blob([excelBuffer2], { type: 'application/octet-stream' });
    FileSaver.saveAs(data2, 'ConnectorLogs.xlsx');
  }

}
