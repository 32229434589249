import { Component, OnInit, Inject, ViewChild, ElementRef, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'src/app/services/message/message.service';
import { BussionMessage } from 'src/app/models/responses/messageResponse';
import { environment } from 'src/environments/environment';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  @ViewChild('messageList') private myScrollContainer: ElementRef;
  baseImageUrl: string = environment.imageUrl;
  dashboardId: string = ""
  @Input() set selectedDashboardId(value: string) {
    if (value) {
      this.dashboardId = value;
      this.getMessages();
    }
  }
  @Input() sidenavRef:any
  messages: BussionMessage[] = [];
  message: BussionMessage = {
    creationDate: new Date(),
    ownerAvatar: '',
    ownerId: '',
    ownerName: '',
    tags: [],
    text: '',
  };
  constructor(
    private messageService: MessageService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    /* if (this.data.dashboardId) {
      this.dashboardId = this.data.dashboardId;
      this.getMessages()
    } */
  }

  getMessages() {
    this.messageService.getMessages(this.dashboardId).subscribe(messages => {
      this.messages = messages;
      this.scrollToBottom();
    })
  }

  sendMessage() {
    this.messageService.upsertMessage(this.dashboardId, this.message).subscribe(result => {
      this.getMessages();
      this.message.text = '';
    });
  }

  deleteMessage(message: BussionMessage) {
    this.modalService.openDeleteConfirm().subscribe(selection => {
      if (selection) {
        this.messageService.deleteMessage(this.dashboardId, message.messageId).subscribe(result => {
          this.getMessages();
        })
      }
    })

  }

  private scrollToBottom(): void {

    try {
      setTimeout(() => {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      }, 500);
    } catch (err) { }
  }

}
