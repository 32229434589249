import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss']
})
export class DataListComponent implements OnInit, OnChanges {
  @Input() datas: any[] = []
  @Input() columns: any[] = []
  @Input() previewButton: boolean = false
  @Input() dragConfig:boolean=false

  @Output() changedSort:EventEmitter<any> = new EventEmitter()
  @Output() dataFunction: EventEmitter<any> = new EventEmitter()
  ismobile: boolean = this.device.isMobile()
  @Input() itemsPerPage: number = 5
  totalPages: number = 0
  currentPage: number = 0
  paginatedData: any[] = []
  constructor(private device: DeviceDetectorService) { }
  avatarKeys = { avatar: 'avatar', tooltip: '', avatartext: 'firstName', avatartext2: 'lastName' }
  env: string = environment.imageUrl


  ngOnInit(): void {


  }
  ngOnChanges(changes: SimpleChanges): void {
    this.updatePagination()
  }


  updatePagination() {
    this.totalPages = Math.ceil(this.datas.length / this.itemsPerPage);
    this.currentPage = 0;
    this.paginatedData = this.datas.slice(
      this.currentPage * this.itemsPerPage,
      (this.currentPage + 1) * this.itemsPerPage
    );
  }

  paginateDatas(event: any) {
    this.currentPage = event.pageIndex;
    this.paginatedData = this.datas.slice(
      event.pageIndex * event.pageSize,
      (event.pageIndex + 1) * event.pageSize
    );
  }

  sendDataToParent(data) {
    this.dataFunction.emit(data)
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.paginatedData, event.previousIndex, event.currentIndex);
    this.changedSort.emit(this.paginatedData)
  }

}
