import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor(private translate:TranslateService) { }

  open(title: string, text: string, icon, confirmbuttontext: string, callbackfunction: Function,showCancelButton?:boolean) {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      heightAuto:false,
      showCancelButton: showCancelButton||false,
      cancelButtonText:this.translate.instant('Common.Cancel'),
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmbuttontext,
      allowOutsideClick: icon == 'info' || icon == 'question' ? false : true
    }).then((result) => {
      callbackfunction(result)
    })
  }


}
