import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllowedScript } from 'src/app/models/responses/allowedScriptResponse';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ScriptsService } from 'src/app/services/scripts/scripts.service';
import { ScriptFormComponent } from './script-form/script-form/script-form.component';

@Component({
  selector: 'app-scripts',
  templateUrl: './scripts.component.html',
  styleUrls: ['./scripts.component.scss']
})
export class ScriptsComponent implements OnInit {

  constructor(private scriptService: ScriptsService, private modalService: ModalService,
    private translate: TranslateService, private spinner: NgxSpinnerService) { }

  displayedColumns: string[] = ['scriptName', 'action'];
  displayedColumnsName: string[] = [this.translate.instant("ScriptsPage.ScriptName"), this.translate.instant("RolesPage.Action")];
  allScripts: AllowedScript[] = []
  

  ngOnInit(): void {
    this.getAllAllowedScripts()
  }


  getAllAllowedScripts() {
    this.scriptService.getAllowedScripts().subscribe(res => {
      this.allScripts = res.map((item, key) => {
        return {
          scriptName: item.scriptName,
          id: key
        }
      })
    })
  }

  openScript(event: any) {
    let data = {
      type: event.type as string,
      script: event.data as AllowedScript,
      oldScripts: this.allScripts
    }

    this.modalService.openModal(data, ScriptFormComponent, 'auto', '1000px').subscribe(result => {
      if (result === true) {
        this.getAllAllowedScripts();
      }
    });
  }


  deleteScript(script: AllowedScript) {
    this.modalService.openDeleteConfirm().subscribe(selection => {
      if (selection === true) {
        this.spinner.show();
        var scriptsforupdate=this.allScripts.filter(x=>x.id!==script.id).map(item=>{
          return{
            scriptName:item.scriptName
          }
        })
        this.scriptService.upsertScript(scriptsforupdate).subscribe(result => {
          this.spinner.hide()
          this.getAllAllowedScripts()
        });
      }
    })
  }

}
