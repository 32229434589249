import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/constants/endpoints';
import { AuthService } from '../auth/auth.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { SpeechStatus } from 'src/app/core/speechTypes';
declare let window: any;



@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  recognition: any
  public speechStatus: BehaviorSubject<SpeechStatus> = new BehaviorSubject<SpeechStatus>(SpeechStatus.Stoped);
  constructor(
    private http: HttpClient,
    private authService: AuthService) {
    if ('SpeechRecognition' in window) {
      let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

      this.recognition = new SpeechRecognition();
      this.recognition.lang = `${this.authService.getUserLangName()}-${this.authService.getUserLangName().toUpperCase()}`
      if (localStorage.getItem('micActive') == 'true')
        this.openSpeech()
    }
  }


  getSuggesstion(message: string) {
    let param = {
      Token: this.authService.getToken(),
      Question: message,
    };

    return this.http.post(Endpoints.getSuggesstion, param).pipe(
      map((response: any) => {
        if (response.message.answer) {
          return response.message.answer;
        } else
          return response.message;


      })
    );


  }

  closeSpeech(value) {
    this.recognition.stop();
    localStorage.setItem('micActive', 'false')
  }


  openSpeech() {

    // this.recognition.lang = 'tr-TR';

    this.recognition.onstart = () => {
      localStorage.setItem('micActive', 'true')
    }

    try {
      this.recognition.start();
    } catch (error) {
      console.error(error)
    }


    return this.recognition
  }

  changeRecognitionLang(lang: string) {
    // var langname = this.authService.getShortCodeForLang(lang)
    this.recognition.lang = `${lang}-${lang.toUpperCase()}`
  }

}

