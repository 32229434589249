import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RoleService } from 'src/app/services/role/role.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Role } from 'src/app/models/responses/rolesResponse';
import { MatDialog } from '@angular/material/dialog';
import { RoleFormComponent } from './role-form/role-form.component';
import { ActionDialogComponent } from 'src/app/components/action-dialog/action-dialog/action-dialog.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {

  displayedColumns: string[] = ['roleName','roleId', 'description', 'action'];
  displayedColumnsName: string[] = [this.translate.instant("RolesPage.RoleName"), this.translate.instant("RolesPage.RoleId"),this.translate.instant("RolesPage.Description"), this.translate.instant("RolesPage.Action")];
  roles: Role[] = [];

  constructor(
    private roleService: RoleService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private permissionService: PermissionService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getRoles();
  }

  private getRoles(): void {
    this.spinner.show();
    this.roleService.getRoles().subscribe(roles => {
      this.roles = roles;
      this.spinner.hide();
    });
  }

  openRole(event: any) {
    let data = {
      type: event.type as string,
      role: event.data as Role
    }

    this.modalService.openModal(data, RoleFormComponent, 'auto', '1000px').subscribe(result => {
      if (result === true) {
        this.getRoles();
        this.permissionService.getUserPermissions(true).subscribe(r => { });
      }
    });
  }

  deleteRole(role: Role) {
    this.modalService.openDeleteConfirm().subscribe(selection => {
      if (selection === true) {
        this.spinner.show();
        this.roleService.deleteRole(role).subscribe(result => {
          this.getRoles();
        });
      }
    })
  }

}
