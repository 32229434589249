import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Endpoints } from 'src/app/constants/endpoints';
import { AllowedScript, AllowedScriptResponse } from 'src/app/models/responses/allowedScriptResponse';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ScriptsService {

  constructor(private http:HttpClient,private authService:AuthService) { }


  getAllowedScripts() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getAllowedScript, param).pipe(
      map((response: AllowedScriptResponse) => {
        return response.message;
      })
    );
  }

  upsertScript(scripts:AllowedScript[]){
    let param = { Token: this.authService.getToken(),AllowedScripts: scripts};
    return this.http.post(Endpoints.upsertAllowedScript, param).pipe(
      map((response: AllowedScriptResponse) => {
        return response.message;
      })
    );
  }

  deleteScript(role: AllowedScript[]) {
    let param = { 
      Token: this.authService.getToken(),
      scriptId: ''
    };
    return this.http.post(Endpoints.deleteRole, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }
}
