import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Role } from 'src/app/models/responses/rolesResponse';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/responses/usersResponse';
import { RoleService } from 'src/app/services/role/role.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss']
})
export class RoleFormComponent implements OnInit {

  pageForm: UntypedFormGroup;
  title: string = "";
  isCreateForm: boolean = false;
  users: User[] = []
  selectedUsers: any[] = [];
  selectedPermissons: any[] = [];
  permissions: string[] = [];
  compareFunctionForUser = (o1: any, o2: any) => o1 === o2;



  paginatedMembers: User[] = [];
  filteredMembers: User[] = [];
  totalPages1: number = 1;
  currentPage1: number = 1;
  pageSize: number = 5;

  paginatedPermission: { item: string, selected: boolean }[] = [];
  filteredPermissions: string[] = [];
  totalPages: number = 1;
  currentPage: number = 1;

  role: Role = {
    roleId: "",
    roleName: "",
    description: "",
    domain: "",
    createdBy: "",
    updatedBy: "",
    createdDate: "",
    updatedDate: "",
    users: [],
    permisions: []
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RoleFormComponent>,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private roleService: RoleService,
    private permissionService: PermissionService,
    private coreService: CoreService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.pageForm = new UntypedFormGroup({
      roleName: new UntypedFormControl(this.role.roleName, [Validators.required]),
    });

    this.title = this.data.type == 'update' ? this.translate.instant("RoleFormPage.TitleUpdate") : this.translate.instant("RoleFormPage.TitleCreate");
    if (this.data.role) {
      this.role = Object.assign({}, this.data.role);
      this.selectedUsers = this.role.users;
      this.selectedPermissons = this.role.permisions;

    }
    this.getUsers();
    this.getPermissions();
  }
  //PermissionOfRole search
  applyFilterPermission(filterValue: string) {
    this.filteredPermissions = this.permissions.filter((permission) => {
      return permission.toLowerCase().includes(filterValue.toLowerCase())
    });
    this.totalPages = Math.ceil(this.filteredPermissions.length / this.pageSize);
    this.currentPage = 1;
    this.updatePaginatedPermissionRole();
  }
  //pagination members Group
  updatePaginatedPermissionRole() {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;
    var list = this.filteredPermissions.map(item => {
      return { item, selected: this.selectedPermissons.some(x => x == item) }
    })
    this.paginatedPermission = list.slice(start, end);
  }
  previousPermission() {
    this.currentPage--;
    this.updatePaginatedPermissionRole();
  }
  nextPermission() {
    this.currentPage++;
    this.updatePaginatedPermissionRole();
  }


  changeselectedPermissionslist(event: any) {
    if (event.options[0]._selected) {
      this.selectedPermissons.push(event.options[0]._value)
    } else {
      this.selectedPermissons = this.selectedPermissons.filter(x => x !== event.options[0]._value)
    }

    // if (localStorage.getItem('user')) {
    //   var user = JSON.parse(localStorage.getItem('user'))
    //   user.permissions = this.selectedPermissons
    //   localStorage.setItem('user', JSON.stringify(user))
    // }
  }

  permissionSelectionChange(change: MatSelectionListChange) {
    // do something when permission selection changes
  }

  applyFilterMembers(filterValue: string) {
    this.filteredMembers = this.users.filter((user) => {
      const fullName = user.firstName + " " + user.lastName;
      return fullName.toLowerCase().includes(filterValue.toLowerCase());
    });
    this.totalPages1 = Math.ceil(this.filteredMembers.length / this.pageSize);
    this.currentPage1 = 1;
    this.updatePaginatedMembersRole();
  }
  //pagination members Group
  updatePaginatedMembersRole() {
    const start = (this.currentPage1 - 1) * this.pageSize;
    const end = start + this.pageSize;
    var list = this.filteredMembers.map(item => {
      return { ...item, selected: this.selectedUsers.some(x => x == item.userId) }
    })
    this.paginatedMembers = list.slice(start, end);
  }
  previousMembers() {
    this.currentPage1--;
    this.updatePaginatedMembersRole();
  }
  nextMembers() {
    this.currentPage1++;
    this.updatePaginatedMembersRole();
  }



  changeselectedlist(event: any) {
    if (event.options[0]._selected) {
      this.selectedUsers.push(event.options[0]._value)
    } else {
      this.selectedUsers = this.selectedUsers.filter(x => x !== event.options[0]._value)
    }
  }


  private getUsers() {
    this.userService.getUsers().subscribe(result => {
      this.users = result;
      this.filteredMembers = result;
      this.totalPages1 = Math.ceil(this.filteredMembers.length / this.pageSize);
      this.updatePaginatedMembersRole();
    });
  }

  private getPermissions() {
    this.permissionService.getPermissions().subscribe(permissions => {
      this.permissions = permissions;
      this.filteredPermissions = permissions;
      this.totalPages = Math.ceil(this.filteredPermissions.length / this.pageSize);
      this.updatePaginatedPermissionRole();
    })
  }

  upsertRole() {
    this.role.users = this.selectedUsers
    this.role.users = [...new Set(this.role.users)];
    this.role.permisions = this.selectedPermissons
    this.role.permisions = [...new Set(this.role.permisions)];

    // console.log("upsert",this.role)
    var user = JSON.parse(localStorage.getItem('user'))

    if (user) {
      if (user.roles.includes(this.role.roleName)) {
        user.permissions = this.role.permisions
      }
      localStorage.setItem('user', JSON.stringify(user))
    }

    if (this.pageForm.invalid) {
      this.coreService.showFormFillError();
      return;
    }
    this.spinner.show();
    this.roleService.upsertRole(this.role).subscribe(result => {
      console.log(result)
      if (result) {
        this.coreService.showSuccessMessage(this.translate.instant('Common.Success'))
        this.spinner.hide();
        this.dialogRef.close(true);
      }

    });
  }

}
