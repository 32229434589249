
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { DataStore } from 'src/app/models/responses/dataStoreResponse';
import { DashboardFormComponent } from 'src/app/pages/manage/dashboard/dashboard-form/dashboard-form.component';
import { DatastoreFormComponent } from 'src/app/pages/manage/datastore/datastore-form/datastore-form.component';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { SelfServiceDatastoreFormComponent } from '../self-service-datastore-form/self-service-datastore-form.component';
import { SelfService } from 'src/app/services/self-service/self.service';
import { SelfServiceDesignerComponent } from '../self-service-designer/self-service-designer.component';
import { MatDialogRef } from '@angular/material/dialog';
import { deleteCssFile } from 'src/app/core/utils';
import { MatPaginatorModule } from '@angular/material/paginator';
import { environment } from "src/environments/environment";


@Component({
  selector: 'app-self-service-main',
  templateUrl: './self-service-main.component.html',
  styleUrls: ['./self-service-main.component.scss']
})
export class SelfServiceMainComponent implements OnInit {

  constructor(
    private router:Router
   ) {}
  ngOnInit(): void {
    
  }

  redirectToDataStoreList(){
    this.router.navigate(['selfservice/data-store-list']);
  }

}
