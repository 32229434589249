import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { Endpoints } from 'src/app/constants/endpoints';
import { Share } from 'src/app/models/responses/shareResponse';
import * as CryptoJS from 'crypto-js';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  upsertShare(share: Share) {
    share.domain = this.authService.getUserDomain();
    let param = {
      Token: this.authService.getToken(),
      Share: share
    };
    return this.http.post(Endpoints.upsertShare, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getShareObject(shareKey: string) {
    let param = {
      ShareObjectId: shareKey
    };
    return this.http.post(Endpoints.getShare, param).pipe(
      map((response: any) => {
        return response.message as Share;
      })
    );
  }

  CreateSecureKeyForService(shareid, domain, filters) {



    var key = CryptoJS.enc.Utf8.parse('000000000000' + shareid);
    var iv = CryptoJS.enc.Utf8.parse('0000000000' + domain);
    var FltArr = filters.filter(x => x.fieldName == 'SecureKey')
    var Keyval = '';
    for (var i = 0; i < FltArr.length; i++) {
      Keyval += FltArr[i]['fieldValue'];
    }

    var encryptData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(Keyval), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });


    var json = {};
    json["fieldName"] = "SecureKey";
    json["operator"] = "=";
    json["fieldValue"] = encryptData.toString();
    FltArr.push(json);
    return JSON.stringify(FltArr);
  }





  getSharedDashboardWidgets(shareId: string, lang: string, domain: string, filters: BussionFilterDefinition[], shareEncryptKey: string, authType: number, sharedToken?: string) {
    filters = filters.filter(x => x.fieldName !== "SecureKey")


    var key = CryptoJS.enc.Utf8.parse('000000000000' + shareId);
    var iv = CryptoJS.enc.Utf8.parse('0000000000' + domain);

    if (filters.length <= 0)
      tmpKey = "NO_FILTER_DEFINED"
    else
      var tmpKey = "";

    for (let index = 0; index < filters.length; index++) {
      tmpKey += filters[index].fieldValue;
    }

    var encryptData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(tmpKey), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    // if (!filters.some(x => x.fieldName == "SecureKey") && shareEncryptKey)
    //   filters.push(
    //     { fieldName: "SecureKey", operator: "=", fieldValue: encryptData.toString() }
    //   )
    // else
    if (!filters.some(x => x.fieldName == "SecureKey")) {
      filters.push(
        { fieldName: "SecureKey", operator: "=", fieldValue: encryptData.toString() }
      )
    }




    let param: any = {
      ShareId: shareId,
      Domain: domain,
      Lang: lang ? lang : 'English',
      Filter: filters,
    };

    if (authType == 2) {
      param.Token = this.authService.getToken()
    } else if (authType == 1) {
      if (sharedToken) {
        var dynamictoken = sharedToken
        param.ShareRequestToken = dynamictoken;
      }
      // return this.getSharedDashboardWidgetsRequest(param)

    }



    return this.getSharedDashboardWidgetsRequest(param)
  }

  getSharedDashboardWidgetsRequest(param) {
    return this.http.post(Endpoints.getSharedDashboardWidgets, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getDynamicToken(shareObjectId: string) {
    let param = {
      Token: this.authService.getToken(),
      ShareObjectId: shareObjectId
    }
    return this.http.post(Endpoints.RequestDynamicalShareToken, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }


  removeDynamicToken(ShareRequestToken: string) {
    let param = {
      ShareRequestToken: ShareRequestToken
    }
    return this.http.post(Endpoints.RemoveDynamicToken, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  wordToByteArray(wordArray) {
    var byteArray = [], word, i, j;
    for (i = 0; i < wordArray.length; ++i) {
      word = wordArray[i];
      for (j = 3; j >= 0; --j) {
        byteArray.push((word >> 8 * j) & 0xFF);
      }
    }
    return byteArray;
  }

  byteArrayToString(byteArray) {
    var str = "", i;
    for (i = 0; i < byteArray.length; ++i) {
      str += escape(String.fromCharCode(byteArray[i]));
    }
    return str;
  }

  getDecrypt(encryptedData: string, sharedkey: string, domain: string) {
    // var encryptedData=url.split('encrypt_')[1]
    encryptedData = encryptedData.replace(/ /g, '+')
    var shareId = sharedkey;
    var domain = domain;
    var key = CryptoJS.enc.Utf8.parse('000000000000' + shareId);
    var iv = CryptoJS.enc.Utf8.parse('0000000000' + domain);
    // key = CryptoJS.enc.Base64.parse(key);
    // //key is now e8b7b40e031300000000da247441226a, length=32
    // iv = CryptoJS.enc.Base64.parse(iv);
    // //iv is now 987185c4436764b6e27a72f2fffffffd, length=32
    // var data = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
    // var bytes = CryptoJS.AES.decrypt(encryptedData);
    // var originalText = bytes.toString(CryptoJS.enc.Utf8);
    // console.log(originalText);
    var decrypted = CryptoJS.AES.decrypt(
      encryptedData,
      key,
      {
        iv: iv
      }
    );
    // var byteArray = this.wordToByteArray(decrypted.words)
    // var string=this.byteArrayToString(byteArray)
    return decrypted.toString(CryptoJS.enc.Utf8);
  }


  getSharedWidget(shareId: string, domain: string) {
    let param = {
      ShareId: shareId,
      Domain: domain,
    };
    return this.http.post(Endpoints.getWidgetForShare, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getMyShare(shareObjectId: string) {
    let param = {
      Token: this.authService.getToken(),
      ShareObjectId: shareObjectId
    };
    return this.http.post(Endpoints.getMyShares, param).pipe(
      map((response: any) => {
        return response.message.filter(x => x.shareType == 1 || x.sharedUserId == this.authService.getUserId());
      })
    );
  }

  deleteShare(shareId: string) {
    let param = {
      Token: this.authService.getToken(),
      SharedObjectId: shareId
    };
    return this.http.post(Endpoints.deleteMyShare, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }





}
