import { Component, OnInit } from '@angular/core';
import { MatTableDataSourcePageEvent } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ScriptFlow } from 'src/app/models/responses/bussionConncetorResponse';
import { Flow } from 'src/app/models/responses/flowScript';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CoreService } from 'src/app/services/core/core.service';
import { FlowScriptService } from 'src/app/services/flow-scripts/flow-script.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { SweetAlertService } from 'src/app/services/sweet-alert/sweet-alert.service';
import { FlowFormComponent } from '../flow-form/flow-form.component';

@Component({
  selector: 'app-flow-list',
  templateUrl: './flow-list.component.html',
  styleUrls: ['./flow-list.component.scss']
})
export class FlowListComponent implements OnInit {
  flowList: Flow[] = []
  paginatedFlowList: Flow[] = []
  currentUserId: string = this.authService.getUserId()
  currentUserRoles: string = this.authService.getRoles()

  cardKeys: { avatarKey: string, titleKey: string, descKey: string, smallKey: string, smallKey2: string, badgeKey: string } = { avatarKey: 'name', titleKey: 'name', descKey: 'description', smallKey: 'createDate', smallKey2: 'version', badgeKey: 'scripts' }
  imageAvatarKeys: { avatar: string, tooltip: string, avatartext: string, avatartext2: string } = { avatar: 'owner', tooltip: 'owner', avatartext: 'owner', avatartext2: 'owner' }
  cardIcons: { function: Function, icon: string, condition?: string }[] =
    [
      // { function: (e) => this.openEditDesigner(e), icon: 'edit-box' },
      // { function: (e) => this.openEditDesigner(e), icon: 'change-flow', condition: 'all' },
      { function: (e) => this.runFlow(e), icon: 'playgray', condition: 'all' },

      { function: (e) => this.editFlow(e), icon: 'edit-box', condition: 'all' },
      // { function: (e) => this.deleteFlowConfirm(e), icon: 'delete-box', condition: 'secret' },



    ]
  pageVariables = {
    pageCount: 0,
    itemPerPage: 12,
    currentPage: 0,
    listLength: 0
  }


  constructor(private flowScriptServive: FlowScriptService, private router: Router, private sweetalertService: SweetAlertService,
    private translate: TranslateService, private coreService: CoreService, private authService: AuthService,
    private dialogService: ModalService) { }

  ngOnInit(): void {
    this.getFlows()
  }


  getFlows() {
    this.flowScriptServive.getFlows().subscribe(res => {
      if (res) {
        this.flowList = res

        this.paginatedFlowList = this.flowList

        this.pageVariables.pageCount = Math.ceil(this.flowList.length / this.pageVariables.itemPerPage)
        this.pageVariables.listLength = this.flowList.length
        this.paginateData(this.paginatedFlowList)
      }
    })
  }

  paginateData(list: Flow[]) {
    var start = 0
    start = (this.pageVariables.currentPage) * this.pageVariables.itemPerPage
    this.paginatedFlowList = list.slice(start, start + this.pageVariables.itemPerPage)


  }

  handlePageEvent(e: MatTableDataSourcePageEvent) {
    this.pageVariables.currentPage = e.pageIndex
    console.log(e)
    this.paginateData(this.flowList)
  }


  routeToDesigner() {
    this.router.navigate(['/flow/flow-designer'])
  }

  openEditDesigner(selectedFlow: Flow) {
    const navigationExtras: NavigationExtras = {
      state: { flow: selectedFlow, flowId: selectedFlow.flowId }
    };
    this.router.navigate(['/flow/flow-designer', selectedFlow.flowId], navigationExtras)
  }

  deleteFlowConfirm(selectedFlow: Flow) {
    this.sweetalertService.open(
      this.translate.instant("ActionDialogComponent.Message"),
      this.translate.instant(selectedFlow.name + this.translate.instant('Common.DeleteConfirmText')),
      'question',
      this.translate.instant("Common.Done"),
      (res) => {
        if (res.isConfirmed)
          this.deleteFlow(selectedFlow)
      },
      true
    )

  }

  deleteFlow(selectedFlow: Flow) {
    this.flowScriptServive.deleteFlow(selectedFlow.flowId).subscribe(res => {
      this.getFlows()
      this.dialogService.dissmissAll()
    })
  }

  searchData(text: string) {

    this.pageVariables.currentPage = 0
    text = text.toLowerCase()

    var searcData = this.flowList.filter((x) => x.description.toLowerCase().includes(text) || x.name.toLowerCase().includes(text))

    if (text) {
      this.pageVariables.listLength = searcData.length
    } else {
      this.pageVariables.listLength = this.flowList.length
    }
    this.paginateData(searcData)


  }
  runFlow(selectedFlow: ScriptFlow) {
    if (selectedFlow.flowId)
      this.flowScriptServive.runFlow(selectedFlow.flowId, '').subscribe(res => {
        if (res.result)
          this.coreService.showSuccessMessage('Flow Çalıştırıldı.')
      })
  }

  createCardInfo(ownerId: string) {
    if (this.currentUserId == ownerId || this.currentUserRoles.includes('Admin')) {
      return this.cardIcons
    }
    else {
      var filtered = this.cardIcons.filter(x => x.condition == 'all')
      return filtered;
    }
  }


  editFlow(flow: Flow) {

    var data = flow ? flow : null

    this.dialogService.openModal({ flow: data, deleteFunction: (e) => this.deleteFlowConfirm(e) }, FlowFormComponent, '50%', '50%', 'overflow-hidden', 'center').subscribe(res => {
      if (res) {
        this.getFlows()
      }
    })


  }

}
