<app-modal-title [title]="title"></app-modal-title>
<mat-dialog-content class="mat-typography">

    <app-title title="{{ 'UserFormPage.AboutUser' | translate }}"></app-title>
    <app-photo-upload [photoUrl]="this.user.avatar" (photoLoaded)="photoUploaded($event)"></app-photo-upload>

    <div class="b-form-content">
        <div [formGroup]="pageForm">
            <mat-form-field>
                <mat-label>{{ 'UserFormPage.Name' | translate }}</mat-label>
                <input matInput [(ngModel)]="user.firstName" formControlName="firstName" required>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'UserFormPage.Surname' | translate }}</mat-label>
                <input matInput [(ngModel)]="user.lastName" formControlName="lastName" required>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'UserFormPage.Email' | translate }}</mat-label>
                <input matInput [(ngModel)]="user.email" formControlName="email" required>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'UserFormPage.Password' | translate }}</mat-label>
                <input matInput type="password" [(ngModel)]="user.password" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'UserFormPage.PasswordAgain' | translate }}</mat-label>
                <input matInput type="password" [(ngModel)]="passwordAgain" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'UserFormPage.Language' | translate }}</mat-label>
                <mat-select [(ngModel)]="user.language" [ngModelOptions]="{standalone: true}">
                    <mat-option [value]="item.name" *ngFor="let item of languages">{{item.name}}</mat-option>
                    <mat-option *ngIf="languages.length<=0" value="English">English</mat-option>
                    <mat-option *ngIf="languages.length<=0" value="Turkish">Türkçe</mat-option>
                </mat-select>
            </mat-form-field>
            <br>


            <div style="display: flex;align-items: center;">
                <mat-form-field>
                    <mat-label>{{ 'UserFormPage.MainPage' | translate }}</mat-label>
                    <mat-select [(ngModel)]="mainpage" [ngModelOptions]="{standalone: true}">
                        <mat-option [value]="">{{'Common.Select'|translate}}</mat-option>
                        <mat-option *ngFor="let item of appList" [value]="item.applicationId">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button (click)="mainpage=''" mat-stroked-button class="custom-primary-stroked-button">
                    {{ 'Common.Reset' | translate }}
                </button>
            </div>

            <br>

            <mat-form-field *ngIf="!isOwnUser">
                <mat-label>{{ 'UserFormPage.AccountStatus' | translate }}</mat-label>
                <mat-select [(ngModel)]="user.accountStatus" [compareWith]="compareFunctionForAccountStatus"
                    [ngModelOptions]="{standalone: true}">
                    <mat-option value="0">Passive</mat-option>
                    <mat-option value="1">Active</mat-option>
                </mat-select>
            </mat-form-field>
            <br>

            <mat-form-field *ngIf="isOwnUser">
                <mat-label>{{ 'UserFormPage.Organization' | translate }}</mat-label>
                <input matInput [(ngModel)]="user.organization" disabled [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'UserFormPage.Domain' | translate }}</mat-label>
                <input matInput [(ngModel)]="user.domain" disabled [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <br>
        </div>
    </div>


    <div *ngIf="!isOwnUser">
        <app-title title="{{ 'UserFormPage.UserGroups' | translate }}"></app-title>
        <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value,'group')" placeholder="Search">
        </mat-form-field>
        <mat-selection-list (selectionChange)="changeselected($event,'group')">
            <mat-list-option [selected]="group.selected" *ngFor="let group of pageVeriable['group'].paginated"
                [value]="group.item">
                {{group.item.groupName}}
            </mat-list-option>
        </mat-selection-list>
        <div class="pagination">
            <button class="pagination__button" [disabled]="pageVeriable['group'].currentPage === 1"
                (click)="previous('group')">«</button>
            <span class="pagination__page-number">{{ pageVeriable['group'].currentPage }}</span>
            <button class="pagination__button"
                [disabled]="pageVeriable['group'].currentPage === pageVeriable['group'].totalPages"
                (click)="next('group')">»</button>
        </div>
        <br>

        <app-title title="{{ 'UserFormPage.UserRoles' | translate }}"></app-title>
        <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value,'role')" placeholder="Search">
        </mat-form-field>
        <mat-selection-list (selectionChange)="changeselected($event,'role')">
            <mat-list-option [selected]="role.selected" *ngFor="let role of pageVeriable['role'].paginated"
                [value]="role.item">
                {{role.item.roleName}}
            </mat-list-option>
        </mat-selection-list>
        <div class="pagination">
            <button class="pagination__button" [disabled]="pageVeriable['role'].currentPage === 1"
                (click)="previous('role')">«</button>
            <span class="pagination__page-number">{{ pageVeriable['role'].currentPage }}</span>
            <button class="pagination__button"
                [disabled]="pageVeriable['role'].currentPage === pageVeriable['role'].totalPages"
                (click)="next('role')">»</button>
        </div>
        <br>
    </div>




</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-success" *ngIf="!isCreateForm" (click)="upsertUser()">{{ 'Common.Update' |
        translate }}</button>
    <button mat-button class="b-btn b-success" *ngIf="isCreateForm" (click)="upsertUser()">{{ 'Common.Create' |
        translate }}</button>
</mat-dialog-actions>