import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { VisualComp, VisualComponentResponse } from 'src/app/models/responses/visualComponentResponse';

@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionService {

  private loadedFunctions: any[] = [];
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getGlobalFunctions(domain?: string) {
    let param = {
      Token: domain ? "SHAREDOBJECT-" + domain : this.authService.getToken()
    };
    return this.http.post(Endpoints.getGlobalFunctions, param).pipe(
      map((response: VisualComponentResponse) => {
        this.loadedFunctions = response.message;
        return response.message;
      })
    );
  }

  upsertGlobalFunction(globalFunction: VisualComp) {
    let param = {
      Token: this.authService.getToken(),
      GlobalFunction: globalFunction
    };
    return this.http.post(Endpoints.upsertGlobalFunction, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  deleteGlobalFunction(globalFunction: VisualComp) {
    let param = {
      Token: this.authService.getToken(),
      GlobalFunctionId: globalFunction.visualComponentId
    };
    return this.http.post(Endpoints.deleteGlobalFunction, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  didFunctionLoad() {
    return this.loadedFunctions.length == 0 ? false : true;
  }

  getGlobalFunctionsDefinitions() {
    let param = {
      Token: this.authService.getToken(),
    };
    return this.http.post(Endpoints.getGlobalFunctionDefinitions, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

}
