<div style="height: 100%;">
    <div class="exit-button" close-button *ngIf="showExitButton"><!--  We add the card-body attribute here -->
        <mat-icon mat-dialog-close>clear</mat-icon>
    </div>
    <div class="not-auth-container">
        <h1>{{"Common.PermisionDenied"|translate}}</h1>
        <span>{{"Common.PleaseLogin"|translate}}</span>
        <img [src]="'/assets/images/401.png'">
        <button (click)="navigateToLogin()" mat-raised-button class="custom-primary-button" style="width: 300px;">
            <span> {{ 'LoginPage.LoginButton' | translate }}</span>
        </button>
    </div>
</div>