import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

  @Input() public title: string;
  @Input() buttonTitle: string;
  @Input() buttonTitle2: string;

  @Input() isDisabled: boolean = false;
  @Input() isDisabled2: boolean = false;

  @Output() btnEvent = new EventEmitter<any>();
  @Output() btnEvent2 = new EventEmitter<any>();



  constructor() { }

  ngOnInit(): void {
  }

  btnClick() {
    this.btnEvent.emit();
  }

  btnClick2() {
    this.btnEvent2.emit();
  }

}
