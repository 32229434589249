import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-custom-card-with-desc',
  templateUrl: './custom-card-with-desc.component.html',
  styleUrls: ['./custom-card-with-desc.component.scss']
})
export class CustomCardWithDescComponent implements OnInit {

  @Input() item: any
  @Input() CardInfo: {
    img: string, 
    smallText1: string, 
    smallText2: string, 
    title: string, 
    desc: string,
    icons: {function:Function,icon:string}[]
  } =
    {
      img: '',
      smallText1: '',
      smallText2: '',
      title: '',
      desc: '',
      icons: []
    }
    iserrorimage:boolean=false



  constructor() { }

  ngOnInit(): void {
  }

  errorimage(ev: any) {
    this.iserrorimage = true
  }
}
