// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  logo: '/assets/images/output-onlinepngtools.png',
  baseUrl: window.location.origin,
  imageUrl: window.location.origin,
  // baseUrl: 'http://10.0.3.225/' //tcdd
  // baseUrl: 'https://demo.bussion.com',
  // imageUrl: 'https://demo.bussion.com',
  // baseUrl:'http://152.89.36.234:5000',//hospitadent
  // imageUrl:'http://152.89.36.234:5000',
  // baseUrl: 'https://dev.bussion.com',
  // imageUrl: 'https://dev.bussion.com'
  // baseUrl: 'http://rapor.turkiyemaarif.org',
  // imageUrl: 'http://rapor.turkiyemaarif.org',
  // baseUrl: 'http://10.5.1.175',
  // imageUrl: 'http://10.5.1.175'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
