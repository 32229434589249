import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Sceduler } from 'src/app/models/requests/Sceduler';

import { CoreService } from 'src/app/services/core/core.service';
import { MailServiceService } from 'src/app/services/mail/mail-service.service';


@Component({
  selector: 'app-mail-scedule-form',
  templateUrl: './mail-scedule-form.component.html',
  styleUrls: ['./mail-scedule-form.component.scss']
})
export class MailSceduleFormComponent implements OnInit {
  MailForm: FormGroup;
  email: string = '';
  emailList: string[] = [];

  Sceduler: Sceduler = {
    scheduleId: "",
    status: 1,
    type: 0,
    hour: "",
    minute: "",
    link: '',
    receivers: "",
    subject: "",
    template: "",
    format: 2
  };
  currentPage: number = 0;
  showcreateform: boolean = false;
  Scedules: any[] = [];
  paginatedScedulers: any[] = [];
  emailError: string = ''; 
  Formats: any[] = [
    { id: 0, type: this.translate.instant('MailForm.Link'), text: 'Link', class: 'badge-warn' },
    { id: 1, type: this.translate.instant('MailForm.HTML'), text: 'Html', class: 'badge-prp' },
    { id: 2, type: this.translate.instant('MailForm.Picture'), text: 'Picture', class: 'badge-succes' },
    { id: 3, type: this.translate.instant('MailForm.Presentation'), text: 'Presentation', class: 'badge-info' }

  ];

  constructor(
    private mailService: MailServiceService,
    private translate: TranslateService,
    private coreService: CoreService,
    private dialogRef: MatDialogRef<MailSceduleFormComponent>,

  
    @Inject(MAT_DIALOG_DATA)
    public data: {
      url: string,
      dashboardId: string
    }
  ) {}

  ngOnInit(): void {
    this.MailForm = new FormGroup({
      hour: new FormControl("", [
        Validators.required,
        Validators.min(0),
        Validators.max(23)
      ]),
      minute: new FormControl("", [
        Validators.required,
        Validators.min(0),
        Validators.max(59)
      ]),
      link: new FormControl(this.data.url),
      receivers: new FormControl("", Validators.required),
      subject: new FormControl("", Validators.required),
      format: new FormControl("")
    });
    this.getSceduler(this.data.dashboardId);
  }

  writeEmail(): void {
    const receiversControl = this.MailForm.get('receivers');
    if (receiversControl && receiversControl.value) {
      const emails = receiversControl.value.split(',').map(email => email.trim());
      this.emailList = [];
      this.emailError = ''; // Hata mesajını sıfırla
  
      for (let i = 0; i < emails.length; i++) {
        const email = emails[i];
        if (Validators.email(new FormControl(email)) === null) {
          this.emailList.push(email);
        } else {
          this.emailError = 'Geçerli bir e-posta adresi giriniz.';
        }
      }
    }
  }

saveScedule() {
  this.emailError = ''; // Hata mesajını sıfırla

  if (this.MailForm.valid) {
    const receiversControl = this.MailForm.get('receivers');
    if (receiversControl && receiversControl.invalid) {
      this.emailError = 'Geçerli bir e-posta adresi giriniz.';
    } else {
      const hour = this.MailForm.get('hour').value;
      const minute = this.MailForm.get('minute').value;

      // Hour ve minute değerlerinin sınırların dışında olup olmadığını kontrol et
      if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
        this.emailError = 'Geçerli saat ve dakika değerleri giriniz.';
      } else {
        // E-posta alanı geçerli olduğunda işlemleri devam ettir
        const SceduleObject = { ...this.Sceduler, ...this.MailForm.value };
        SceduleObject.receivers = this.emailList.join(','); // Geçerli e-posta adreslerini kullanarak receivers değerini güncelle
        
        this.mailService.upsertMailSceduler(SceduleObject).subscribe(res => {
          if (res === 'OK') {
            this.getSceduler(this.data.dashboardId);
            this.coreService.showSuccessMessage(this.translate.instant('Common.Success'));
            this.showcreateform = false;
          } else {
            this.coreService.showErrorMessage(res);
          }
        });
      }
    }
  } else {
    this.coreService.showErrorMessage(this.translate.instant('Common.FormFill'));
  }
}


getSceduler(dashboardId: string) {
  this.MailForm.reset();
  this.MailForm.patchValue({
    link: this.data.url
  });
  this.mailService.getMailSceduler(dashboardId).subscribe((res) => {
    this.Scedules = res.map(item => {
      return {
        ...item,
        scedularType: this.Formats.find(x => x.id == item.format).text,
        badgeClass: this.Formats.find(x => x.id == item.format).class,
        receiverList: item.receivers ? item.receivers.split(',').map(rc => {
          return {
            mail: rc,
            avatar: rc.includes('.')
              ? rc.split('.')[0]?.substring(0, 1).toUpperCase() + rc.split('.')[1]?.substring(0, 1).toUpperCase()
              : rc.substring(0, 2).toUpperCase()
          };
        }) : []
      };
    });
    this.paginatedScedulers = this.Scedules.slice(0, 5);
  });
}

  paginateItems(event) {
    this.currentPage = event.pageIndex;
    this.paginatedScedulers = this.Scedules.slice(
      event.pageIndex * event.pageSize,
      (event.pageIndex + 1) * event.pageSize
    );
  }

  openEditScreen(item) {
    this.MailForm.patchValue({
      hour: item.hour,
      minute: item.minute,
      link: item.link,
      receivers: item.receivers,
      subject: item.subject,
      format: item.format
    });
    this.showcreateform = true;
    this.Sceduler = { ...item };
  }

  openFormScreen() {
    this.MailForm.reset();
    this.MailForm.patchValue({
      link: this.data.url
    });
    this.showcreateform = !this.showcreateform;
    this.Sceduler = {
      scheduleId: "",
      status: 1,
      type: 0,
      hour: "",
      minute: "",
      link: '',
      receivers: "",
      subject: "",
      template: "",
      format: 2
    };
  }

  deleteScedule(item) {
    this.mailService.deleteMailSceduler(item.scheduleId).subscribe(res => {
      if (res === 'OK') {
        this.getSceduler(this.data.dashboardId);
        this.coreService.showSuccessMessage(this.translate.instant('Common.Success'));
      } else {
        this.coreService.showErrorMessage(res);
      }
    });
  }
}