<div style="display: flex;width: 100%;height: 100%;">
    <div style="flex-basis: 5%;width: 5%;">
        <app-custom-leftmenu [menuList]="menuList"></app-custom-leftmenu>

    </div>
    <div style="flex-basis: 95%;width: 95%;">
        <app-custom-header [title]="breadcrumbTitle" [elements]="breadcrumbElementList"></app-custom-header>
        <div class="selfservice-main">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<div class="top-button">
    <div class="top-button-container">
        <div>
            <button class="lef-button-icon" [matTooltip]='"SelfServicePage.VisualAnalytics"|translate'
                (click)="showAnalyzePage()">
                <mat-icon style="color: white; margin:0.5rem" matListIcon svgIcon="statIcon"></mat-icon>
            </button>
        </div>
        <div style="font-size: large;">
            <button matTooltip="Add Data" (click)="showDataStoreTable()">
                <mat-icon style="color: white;margin:0.5rem" matListIcon svgIcon="dataStore"></mat-icon>
            </button>

        </div>
        <div>
            <button matTooltip="Dashboard Designer" (click)="showDashboardTable()">
                <mat-icon style="color: white; margin:0.5rem" matListIcon svgIcon="dashboardIcon"></mat-icon>
            </button>
        </div>
    </div>
</div>
<!-- <div style="display: flex;height: 100%;">
    <div style="display: contents;">
        <button class="lef-toggle-button">
            <div style="display: flex;flex-direction: column;align-items: end;justify-content: space-between;">
                <div>
                    <button class="lef-button-icon" [matTooltip]='"SelfServicePage.VisualAnalytics"|translate'
                        (click)="showAnalyzePage()">
                        <mat-icon style="color: white; margin:0.5rem" matListIcon svgIcon="statIcon"></mat-icon>
                    </button>
                </div>
                <div style="font-size: large;">
                    <button class="lef-button-icon" [matTooltip]='"SelfServicePage.MyDatas"|translate'
                        (click)="showDataStoreTable()">
                        <mat-icon style="color: white;margin:0.5rem" matListIcon svgIcon="dataStore"></mat-icon>
                    </button>

                </div>
                <div>
                    <button class="lef-button-icon" [matTooltip]='"SelfServicePage.DashboardList"|translate'
                        (click)="showDashboardTable()">
                        <mat-icon style="color: white; margin:0.5rem" matListIcon svgIcon="dashboardIcon"></mat-icon>
                    </button>
                </div>
            </div>

        </button>

       
    </div>
    <div class="selfservice-main">

        <router-outlet></router-outlet>
      
    </div>
</div> -->