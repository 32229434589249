import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { BussionMessage } from 'src/app/models/responses/messageResponse';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getMessages(dashboardId: string) {
    let param = {
      Token: this.authService.getToken(),
      DashboardId: dashboardId
    };
    return this.http.post(Endpoints.getMessages, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  upsertMessage(dashboardId: string, message: BussionMessage) {
    let param = {
      Token: this.authService.getToken(),
      DashboardId: dashboardId,
      Message: message
    };
    return this.http.post(Endpoints.upsertMessage, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  deleteMessage(dashboardId: string, messageId: string) {
    let param = {
      Token: this.authService.getToken(),
      DashboardId: dashboardId,
      MessageId: messageId
    };
    return this.http.post(Endpoints.deleteMessage, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }



}
