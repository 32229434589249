import { Component, OnInit, ViewChild } from '@angular/core';
import { WidgetLibraryService } from 'src/app/services/widget-library/widget-library.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { WidgetLibraryFormComponent } from './widget-library-form/widget-library-form.component';
import { MatDialog } from '@angular/material/dialog';
import { WidgetLibrary, WidgetLibraryResponse } from 'src/app/models/responses/widgetLibraryResponse';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionDialogComponent } from 'src/app/components/action-dialog/action-dialog/action-dialog.component';
import { environment } from 'src/environments/environment';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-widget-library',
  templateUrl: './widget-library.component.html',
  styleUrls: ['./widget-library.component.scss']
})
export class WidgetLibraryComponent implements OnInit {

  baseUrl = environment.imageUrl;
  displayedColumns: string[] = ['icon', 'name', 'widgetLibraryId','folder', 'action'];
  displayedColumnsName: string[] = [this.translate.instant("Common.Icon"), this.translate.instant("WidgetLibraryPage.Name"), this.translate.instant("WidgetLibraryPage.WidgetLibraryId"),this.translate.instant("WidgetLibraryPage.Folder"), this.translate.instant("WidgetLibraryPage.Action")];
  widgetLibraries: WidgetLibrary[] = [];

  constructor(
    private dialog: MatDialog,
    private widgetLibraryService: WidgetLibraryService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.getWidgetLibraries();
  }

  getWidgetLibraries() {
    this.spinner.show();
    this.widgetLibraryService.getWidgetLibraries().subscribe(result => {
      this.widgetLibraries = result;
      this.spinner.hide();
    })
  }

  openWidgetLibrary(event: any) {
    let data = {
      type: event.type as string,
      widgetLibrary: event.data as WidgetLibrary
    }

    this.modalService.openModal(data, WidgetLibraryFormComponent, 'auto', '1000px').subscribe(result => {
      if (result === true) {
        this.getWidgetLibraries();
      }
    });

  }

  deleteWidgetLibrary(widgetLibrary: WidgetLibrary) {
    this.modalService.openDeleteConfirm().subscribe(selection => {
      if (selection === true) {
        this.spinner.show();
        this.widgetLibraryService.deleteWidgetLibrary(widgetLibrary).subscribe(result => {
          this.spinner.hide();
          this.getWidgetLibraries();
        });
      }
    })
  }

}
