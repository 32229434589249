<!-- <app-modal-title title="Choose File Type"></app-modal-title> -->
<mat-vertical-stepper>
    <mat-step>
        <ng-template matStepLabel>Step 1</ng-template>
        <div *ngIf="showFileTypeSelection" style="width: 100%; min-height: 200px;">
            <div mat-dialog-content>
                <h3 style="margin-top:2%">Please select the file type you want to open.</h3>
            </div>

            <div>
                <ul class="card-list">
                    <li class="card" (click)="openFileSelect('.xls')">
                        <img style="width: 64%;" src="../../../../assets/images/xls.png" alt="let's go" />
                        <h2>XLS/XLSX</h2>
                    </li>

                    <li class="card" (click)="openFileSelect('.json')">
                        <img style="width: 60%; margin-top: 3%;" src="../../../../assets/images/json-3.png"
                            alt="The Beautiful Game" />
                        <h2>JSON</h2>
                    </li>

                    <li class="card" (click)="openFileSelect('.csv')">
                        <img style="width: 64%;" src="../../../../assets/images/csv-4.png" alt="Jane Doe" />
                        <h2>CSV</h2>
                    </li>
                    <li class="card" (click)="openFileSelect('.txt')">
                        <img style="width: 60%; margin-top: 3%;" src="../../../../assets/images/txt-file-symbol.png"
                            alt="The Beautiful Game" />
                        <h2>TXT</h2>
                    </li>

                    <li class="card" (click)="openFileSelect('.xml')">
                        <img style="width: 64%;" src="../../../../assets/images/xml-file-format-symbol.png" alt="Jane Doe" />
                        <h2>XML</h2>
                    </li>
                </ul>
            </div>
        </div>

    </mat-step>

<mat-step>
    <ng-template matStepLabel>Step 2</ng-template>
        <div *ngIf="showFileSelectModal"   style="width: 100%; min-height: 300px;" >
            <div mat-dialog-content >
                <h3 style="margin-top:2%">Please choose a file.</h3>
                <div>
                    <input type="file" accept="{{ fileAccept }}" (change)="onFileChange($event)" #fileInput>
                    <button class="button" (click)="fileInput.click()">Choose File</button>
                </div>

            </div>

            <div mat-dialog-actions>
                <button class="button" (click)="uploadFile()">Upload</button>
            </div>
        </div>
</mat-step>

<mat-step>
    <ng-template matStepLabel>Step 3</ng-template>
        <div *ngIf="showHandsoneTable">
            <!-- AG Grid Angular Component -->
            <ag-grid-angular style="width: 100%; height:100vh;overflow-x: scroll !important;" class="ag-theme-alpine" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowData]="rowData" [rowSelection]="'multiple'" [animateRows]="true"
                (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)"></ag-grid-angular>
        </div>
</mat-step>
    </mat-vertical-stepper>