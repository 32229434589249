<div style="display: flex;align-items: center;position: relative;" *ngIf="permissions.includes('AIUser')||permissions.includes('AIAdmin')">
    <div class="animation-area" *ngIf="this.router.url=='/'">
        <div id="bars" *ngIf="hearing">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
        </div>
    </div>

    <mat-icon class="mic-icon" (onmouseenter)="tooltip.hide()" *ngIf="!mic" (click)="startSpeech()"
        #tooltip="matTooltip" matTooltip="Metni Silmek için komut: Sil,
Anasayfaya Dönmek için komut: anasayfa,
Dashboardu yenilemek için komut: yenile,
Dinlemeyi kapatmak için komut: kapat,
Komutları görmek için komut: yardım" matTooltipPosition="below" aria-tooltip="Metni silmek için komut: sil,
Anasayfaya dönmek için komut: anasayfa,
Dashboardu yenilemek için komut: yenile,
Dinlemeyi kapatmak için komut: kapat,
Komutları görmek için komut: yardım" (onmouseenter)="tooltip.hide()">mic</mat-icon>
    <mat-icon class="mic-icon" *ngIf="mic" (click)="closeSpeech('kapat')" #tooltip="matTooltip" matTooltip="Metni Silmek için komut: Sil,
Anasayfaya Dönmek için komut: anasayfa,
Dashboardu yenilemek için komut: yenile,
Dinlemeyi kapatmak için komut: kapat,
Komutları görmek için komut: yardım" matTooltipPosition="below" aria-tooltip="Metni silmek için komut: sil,
Anasayfaya dönmek için komut: anasayfa,
Dashboardu yenilemek için komut: yenile,
Dinlemeyi kapatmak için komut: kapat,
Komutları görmek için komut: yardım">mic_off</mat-icon>

    <!-- <mat-spinner *ngIf="processingCommand" color="warn" style="margin-right: 5px;" [diameter]="30"></mat-spinner> -->

</div>
<div [style.MarginLeft]="hearing?'-170px':'-290px'" class="dialog-baloon"
    *ngIf="proccessText&&!activeAssistan&&this.router.url=='/'">{{proccessText}}</div>