<div class="openedClosedbtn" style="position:absolute">
    <button [ngStyle]="{'transform': opened ? 'translateX(300px)' : 'translateX(0)'}" mat-button (click)="toggleGridster()"> <mat-icon>menu</mat-icon> </button>
</div>
<div class="manage-page custom-page">
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav #sidenav mode="side" [(opened)]="opened">
           
            <mat-nav-list class="menu-nav">
                <mat-list-item [routerLink]="['user']" *ngIf="permissions.includes('UserManagement')">
                    <mat-icon matListIcon svgIcon="users"></mat-icon>
                    <a matLine>{{ 'ManagePage.Users' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item [routerLink]="['group']" *ngIf="permissions.includes('GroupManagement')">
                    <mat-icon matListIcon svgIcon="groups"></mat-icon>
                    <a matLine>{{ 'ManagePage.Groups' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item [routerLink]="['role']" *ngIf="permissions.includes('RoleManagement')">
                    <mat-icon matListIcon svgIcon="roles"></mat-icon>
                    <a matLine>{{ 'ManagePage.Roles' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item [routerLink]="['node']" *ngIf="permissions.includes('NodeManagement')">
                    <mat-icon matListIcon svgIcon="node"></mat-icon>
                    <a matLine>{{ 'ManagePage.Node' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item [routerLink]="['datastore']" *ngIf="permissions.includes('DataStoreManagement')">
                    <mat-icon matListIcon svgIcon="data_store"></mat-icon>
                    <a matLine>{{ 'ManagePage.DataStore' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

        

                <mat-list-item [routerLink]="['widget-library']"
                    *ngIf="permissions.includes('WidgetLibraryManagement')">
                    <mat-icon matListIcon svgIcon="widget_library"></mat-icon>
                    <a matLine>{{ 'ManagePage.WidgetLibrary' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item [routerLink]="['visual']" *ngIf="permissions.includes('VisualComponentManagement')">
                    <mat-icon matListIcon svgIcon="visual_component"></mat-icon>
                    <a matLine>{{ 'ManagePage.VisualComponent' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>


                <mat-list-item [routerLink]="['dashboard']" *ngIf="permissions.includes('DashboardDesign')">
                    <mat-icon matListIcon svgIcon="dashboard"></mat-icon>
                    <a matLine>{{ 'ManagePage.Dashboard' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item [routerLink]="['deploy-app']"
                *ngIf="permissions.includes('ApplicationManagement')&&licencePermissions.includes('Bussion Apps')">
                   <mat-icon matListIcon svgIcon="deploy_app"></mat-icon>
                   <a matLine>{{ 'ManagePage.DeployApp' | translate }}</a>
                   <mat-divider></mat-divider>
               </mat-list-item>

                <mat-list-item [routerLink]="['global-functions']"
                    *ngIf="permissions.includes('GlobalFunctionManagement')">
                    <mat-icon matListIcon svgIcon="global_function"></mat-icon>
                    <a matLine>{{ 'ManagePage.GlobalFunction' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>


                <mat-list-item [routerLink]="['connectors']" *ngIf="permissions.includes('BussionConnectorManagement')">
                    <mat-icon matListIcon svgIcon="bussion_connector"></mat-icon>
                    <a matLine>{{ 'ManagePage.BussionConnector' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item [routerLink]="['license']" *ngIf="permissions.includes('LicenseManagement')">
                    <mat-icon matListIcon>redeem</mat-icon>
                    <a matLine>{{ 'ManagePage.License' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>
                <!-- *ngIf="permissions.includes('LDAPManagement')" -->

                <mat-list-item [routerLink]="['ldap-config']" *ngIf="permissions.includes('SystemConfiguration')">
                    <mat-icon matListIcon>admin_panel_settings</mat-icon>
                    <a matLine>{{ 'ManagePage.LDAPConfig' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item [routerLink]="['password-policy']" *ngIf="permissions.includes('SystemConfiguration')">
                    <mat-icon matListIcon>security</mat-icon>
                    <a matLine>{{ 'ManagePage.PasswordPolicy' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

                <!-- <mat-list-item [routerLink]="['bussion-main']" *ngIf="permissions.includes('SystemConfiguration')">
                    <mat-icon matListIcon svgIcon="main"></mat-icon>
                    <a matLine>{{'BussionMainPage.BussionMain'|translate}}</a>
                    <mat-divider></mat-divider>
                </mat-list-item> -->

               
                
                <mat-list-item [routerLink]="['color-palette']" *ngIf="permissions.includes('ColorPaletteManagement')">
                    <mat-icon matListIcon svgIcon="color_palette"></mat-icon>
                    <a matLine>{{ 'ManagePage.ColorPalette' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item [routerLink]="['scripts']" *ngIf="permissions.includes('SystemConfiguration')">
                    <mat-icon matListIcon svgIcon="script"></mat-icon>
                    <a matLine>{{ 'ManagePage.Scripts' | translate }}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item [routerLink]="['bussion-logs']" *ngIf="permissions.includes('SystemConfiguration')">
                    <mat-icon matListIcon>folder</mat-icon>
                    <a matLine>{{'LogsPage.Logs'|translate}}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item [routerLink]="['system-parameters']"
                    *ngIf="this.permissions.includes('MaxTries')||this.permissions.includes('Debugger')||this.permissions.includes('Captcha')">
                    <mat-icon matListIcon>settings</mat-icon>
                    <a matLine>{{'ManagePage.SystemParameters' | translate}}</a>
                    <mat-divider></mat-divider>
                </mat-list-item>

            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content [style.marginLeft.px]="opened ? 290 : 0" style="overflow: scroll !important;" >
            <section *ngIf="showSection" class="banner">
                <div class="container" >
                    <div class="banner-text">
                        <h1>{{'LandingPage.ManagementPanel' | translate}}</h1>
                        <p style="text-align: center;"><strong
                                style="color: #70A9C1; font-size: 2rem;">{{'LandingPage.BUSSİONANALYTİC' |
                                translate}}</strong><br><br>{{'LandingPage.text' | translate}}</p>

                    </div>
                </div>
                <img class="banner-image" src="../../../assets/images/manage.jpeg" alt="monitoring">
                <!-- ../../../assets/images/clip-start-up.png -->
            </section>
            <router-outlet></router-outlet>
        </mat-sidenav-content>

    </mat-sidenav-container>

</div>