import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {
  progressValue: number = 0
  progressinterval: any

  @Output() closeEmitter: EventEmitter<any> = new EventEmitter<any>()

  constructor() { }


  ngOnInit(): void {

    this.progressinterval = setInterval(() => {
      this.progressValue = this.progressValue + 10
      console.log('girdi', this.progressValue)
      if (this.progressValue == 100) {
        clearInterval(this.progressinterval)
        this.closeEmitter.emit()
      }
    }, 500);


  }

}
