<div class="message-container" >
    <div class="messagebox-title" >
        <h2>{{'MainPage.MessageButton'|translate}}</h2>
        <h2 (click)="sidenavRef.toggle()">X</h2>

    </div>
    <div class="message-list" #messageList>
        <div class="message-card" *ngFor="let message of messages">
            <div style="display: flex;">
                <div style="display: flex;flex-direction: column;align-items: center;">
                    <img style="width: 32px;height: 32px;border-radius: 50px;" src="{{baseImageUrl + message.ownerAvatar}}" />
                    <span style="font-size: xx-small;">{{message.ownerName}}</span>
                </div>
                <div class="message-text-content">
                    <div class="message-text">
                        <div class="message-date">
                            <span>{{message.creationDate | date:'medium' }}</span>
                            <mat-icon matListIcon svgIcon="deletemessage" (click)="deleteMessage(message)"></mat-icon>
                        </div>
                        <p style="word-wrap: break-word;">{{message.text}}</p>
                    </div>
                    <!-- <div class="action-button">
                        <mat-icon matListIcon svgIcon="delete" (click)="deleteMessage(message)"></mat-icon>
                    </div> -->
                </div>
            </div>
            <!-- <div class="message-header">
                <div class="message-owner">
                    <img src="{{baseImageUrl + message.ownerAvatar}}" />
                    <span>{{message.ownerName}}</span>
                </div>
                <div class="message-date">
                    <span>{{message.creationDate | date:'medium' }}</span>
                </div>
            </div>
            <div class="message-text-content">
                <div class="message-text">
                    <p>{{message.text}}</p>
                </div>
                <div class="action-button">
                    <mat-icon matListIcon svgIcon="delete" (click)="deleteMessage(message)"></mat-icon>
                </div>
            </div> -->
        </div>
    </div>

    <div class="text-content">
        <div class="text-area">
            <textarea (keyup.enter)="message.text.length == 0?'':sendMessage()" [(ngModel)]="message.text" placeholder="{{ 'Common.TypeMessage' | translate }}"></textarea>
            <mat-icon [class]="message.text.length == 0?'':'send-icon'"
                (click)="message.text.length == 0?'':sendMessage()">send</mat-icon>
        </div>
        <!-- <div class="action-area">
        <button mat-button class="b-btn b-success" cdkFocusInitial (click)="sendMessage()"
            [disabled]="message.text.length == 0">{{ 'Common.Send' | translate }}</button>
    </div> -->
    </div>
</div>