<div style="display: flex;width: 100%;height: 100%;">
    <div [style.width]="!showScriptArea?'0':''">

        <button [matTooltip]=" 'FlowPage.Scripts' | translate " mat-stroked-button class="custom-primary-stroked-button"
            (click)="showScriptArea=!showScriptArea">
            <mat-icon color="#5E6DA6" svgIcon="scriptIcon"></mat-icon>
            <!-- {{ 'Common.Scripts' | translate }} -->
        </button>
        <div class="menu-area" *ngIf="showScriptArea">
            <div class="menu-title">
                <span> {{'ManagePage.Scripts' |translate}}</span>
                <mat-icon (click)="showScriptArea=false">clear</mat-icon>
            </div>
            <button (click)="openScriptModal()" mat-raised-button class="custom-primary-button button-md w-100 mt-10">
                <span>{{"FlowPage.AddScript"|translate}}</span>
            </button>
            <div class="mt-10 search-filter-area">
                <app-search-input class="search-input" [height]="'30px'"
                    (changeText)="filterScripts($event)"></app-search-input>
                <mat-icon [svgIcon]="'filter-outline'" (click)="packageselect.open()"></mat-icon>

                <mat-select (closed)="filterScripts(selectedPackages,'package')" [(ngModel)]="selectedPackages"
                    style="visibility: hidden;width: 0;" multiple #packageselect>
                    <mat-select-trigger>
                        {{scriptPackages || ''}}
                        <span *ngIf="(scriptPackages.length || 0) > 1" class="example-additional-selection">
                            (+{{(scriptPackages.length || 0) - 1}} {{scriptPackages.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <div style="padding: 5px;display: flex;gap: 5px;">
                        
                        <button (click)="filterScripts([],'package');packageselect.close()"
                            mat-stroked-button class="custom-primary-stroked-button button-md w-100">
                            <span>{{"Common.Clear"|translate}}</span>
                        </button>
                        <button (click)="filterScripts(selectedPackages,'package');packageselect.close()"
                            mat-button class="custom-primary-button button-md w-100">
                            <span>{{"Common.Apply"|translate}}</span>
                        </button>
                    </div>

                    <mat-option *ngFor="let package of scriptPackages" [value]="package">{{package}}</mat-option>

                </mat-select>
            </div>
            <div class="mt-10 card-list-container">
                <div style="cursor: move;" *ngFor="let item of filteredScriptList" [id]="item.scriptId" cdkDrag
                    draggable="true" (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnd($event,item)">
                    <app-custom-card-with-desc [item]="item"
                        [CardInfo]="createCardInfo(item)"></app-custom-card-with-desc>
                </div>
                <div *ngIf="filteredScriptList.length<=0">{{"Common.EmptyData"|translate}}</div>
            </div>
        </div>
    </div>
    <div style="width: 100%;">
        <app-flow-draw [titleClass]="showScriptArea?'':'scriptAreaInActive'" [flowTitle]="flowObject?.name"
            (getExecutionLogFunc)="getExecutionLog()" (runFlowEmitter)="runFlow($event)" [runlock]="runlock"
            (NodeSettingSelected)="nodeSettingSelected($event)" (onImport)="createConnectionForEdit()"
            [flowImportObject]="flowMetaData" (connectionRemove)="connectionRemoved($event)" [loaderValue]="loaderValue"
            (connectionSelected)="connectionSelected($event)" (SaveFlowEmitter)="saveFlow($event)" [Nodes]="nodeList"
            (NodeRemovedEmitter)="nodeRemoved($event)" (clearFunction)="clearFunction()"
            (stopButtonFunction)="stopFlow($event)"></app-flow-draw>
    </div>
</div>