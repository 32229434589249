<div class="modal-header" *ngIf="!loadingHeader" style="z-index: 10000000;    position: absolute;
top: 0;
padding: 4px !important;" [style.right]="right+'%'" [style.top]="top+'%'">
    <mat-icon style="width: 100%;height: 100%;" mat-dialog-close>clear</mat-icon>
</div>
<mat-dialog-content class="mat-typography">
    <div class="share-preview-content">
        <iframe *ngIf="iframeactive&&data?.app?.appType!==0" width="100%" height="100%" frameBorder="0"
            [src]="urlSafe"></iframe>
        <!-- [ngStyle]="{'height': false ? 'calc(100vh - 158px)' : 'calc(100vh - 8px)' }" -->

        <app-share-preview *ngIf="!iframeactive&&data?.app?.appType!==0"></app-share-preview>

        <app-data-app-design [ismodal]="true" *ngIf="data?.app?.appType==0" [data]="data"></app-data-app-design>
    </div>
</mat-dialog-content>
<!-- <mat-dialog-actions>
</mat-dialog-actions> -->