import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { BreadCrumElement } from 'src/app/models/globals/customLeftMenuProps';
import { User } from 'src/app/models/responses/loginResponse';
import { CreateUserComponent } from 'src/app/pages/manage/user/create-user/create-user.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CoreService } from 'src/app/services/core/core.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { SweetAlertService } from 'src/app/services/sweet-alert/sweet-alert.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent implements OnInit {

  @Input() title: string = "My Bussion"
  @Input() searchareaActive: boolean = false
  avatar: string = ""


  _elements: BreadCrumElement[] = [{ path: '', name: 'Home' }, { path: '', name: 'Home' }, { path: '', name: 'Analysis' }]

  @Input() set elements(value: any[]) {
    this._elements = value
  }
  roles: string[] = this.authService.getRoles()

  constructor(private authService: AuthService, private translate: TranslateService,
    private userService: UserService, private dashboardService: DashboardService,
    private sweetalertService: SweetAlertService,
    private router: Router, private modalService: ModalService, private coreService: CoreService) { }

  ngOnInit(): void {
    this.avatar = environment.imageUrl + this.authService.getUserAvatar();
  }


  routetoPage(item: BreadCrumElement) {
    this.router.navigate([item.path])
  }

  showUserInfo() {
    let data = {
      type: "update",
      user: this.authService.getUser() as User,
      ownUser: true
    }
    this.modalService.openModal(data, CreateUserComponent, 'auto', '1000px').subscribe(result => {
      if (result) {
        this.getUserInfo();
        this.coreService.showSuccessMessage(result);
      }
    });
  }

  getUserInfo() {
    this.userService.getUserById(this.authService.getUserId()).subscribe(user => {
      this.authService.saveUserInfos(user);

      this.avatar = environment.imageUrl + this.authService.getUserAvatar();
      // if (user.language == 'English') {
      //   this.translate.use('en');
      // } else {
      //   this.translate.use('tr');
      // }
    })
  }

  logout() {
    this.sweetalertService.open(
      this.translate.instant("HeaderComponent.LogoutConfirm"),
      this.translate.instant("HeaderComponent.LogoutConfirmText"),
      'question',
      this.translate.instant("Common.Yes"),
      (res) => {
        if (res.isConfirmed) {
          this.authService.logout().subscribe(result => {
            this.dashboardService.setCacheData({ authorizedDashboard: [], activeDashboard: [] })

          });
        }
      },
      true
    )

  }

  goHome(){
    this.router.navigate(['/'])
  }

  goManage() {
    // this.router.navigate(['/manage/user'])
    this.router.navigate(['/landing'])
  }

}
