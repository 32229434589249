<div class="user-card" (click)="select(user)">
    <div style="display: flex;width: 100%;">
        <div class="avatar">
            <img [src]="env+user.avatar" width="40px" height="40px">
        </div>
        <div class="user-info">
            <div style="display: flex; align-items: center;justify-content: space-between;">
                <div style="line-height: 1;">
                    <p> {{user.firstName}} {{user.lastName}}</p>
                    <p class="sm-thin-text">{{user.email}}</p>
                </div>
                <svg *ngIf="user.selected==1" width="30px" height="30px" viewBox="0 0 1024 1024" fill="#28c76f"
                    class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#28c76f">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="M439.2 680c9.6 8.8 25.6 8.8 35.2-0.8l300-309.6C784 360 784 344 773.6 334.4c-9.6-9.6-25.6-9.6-35.2 0.8L438.4 644.8l35.2-0.8-182.4-167.2c-10.4-9.6-25.6-8.8-35.2 1.6-9.6 10.4-8.8 25.6 1.6 35.2L439.2 680z"
                            fill=""></path>
                        <path
                            d="M515.2 1007.2c-276 0-500-224-500-500S239.2 7.2 515.2 7.2s500 224 500 500-224 500-500 500z m0-952C265.6 55.2 63.2 257.6 63.2 507.2s202.4 452 452 452 452-202.4 452-452S764.8 55.2 515.2 55.2z"
                            fill=""></path>
                    </g>
                </svg>
            </div>
            <div class="xsmtext">{{'UserCard.MemberOfGroup'|translate}}:</div>
            <div class="tags">
                <div class="tagitem" *ngFor="let item of user.groups">
                    {{item}}
                </div>

            </div>
        </div>
    </div>

</div>