import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';
import { DataStore } from 'src/app/models/responses/dataStoreResponse';
import { DatastoreFormComponent } from './datastore-form/datastore-form.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomModalComponent } from 'src/app/components/modals/custom-modal/custom-modal.component';
import { IndexFormComponent } from './index-form/index-form.component';

@Component({
  selector: 'app-datastore',
  templateUrl: './datastore.component.html',
  styleUrls: ['./datastore.component.scss']
})
export class DatastoreComponent implements OnInit {

  displayedColumns: string[] = ['icon', 'dataStoreId', 'name', 'description', 'node', 'universe', 'collection', 'action'];
  displayedColumnsName: string[] = [this.translate.instant("Common.Icon"), "DataStore Id", this.translate.instant("DataStorePage.Name"), this.translate.instant("DataStorePage.Description"), this.translate.instant("DataStorePage.Node"), this.translate.instant("DataStorePage.Universe"), this.translate.instant("DataStorePage.Collection"), this.translate.instant("DataStorePage.Action")];
  dataStores: DataStore[] = [];

  actionButtonsList: { icon: string, text: string, actionFunction: Function }[] = [{ icon: 'hash', text: 'Edit', actionFunction: (element) => this.openIndexForm(element) }]


  constructor(
    private spinner: NgxSpinnerService,
    private dataStoreService: DataStoreService,
    private modalService: ModalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getDatastores()
  }

  getDatastores() {
    this.dataStoreService.getDatasources().subscribe(result => {
      this.dataStores = result;
      this.dataStores.map(d => {
        d.icon = "/BussionImages/Pictures/DataStore.png";
        return d;
      })
    });
  }

  deleteDataStoreComponent(dataStore: DataStore) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.dataStoreService.deleteDataStore(dataStore).subscribe(result => {
          this.spinner.hide();
          this.getDatastores();
        });
      }
    });
  }

  openDataStoreComponent(event: any) {
    let data = {
      type: event.type as string,
      dataStore: event.data as DataStore
    }
    this.modalService.openModal(data, DatastoreFormComponent, 'auto', '1000px').subscribe(result => {
      if (result === true) {
        this.getDatastores();
      }
    });
  }


  openIndexForm(element) {
  
    this.modalService.openModal({dataStore:element}, IndexFormComponent, '70%', '50%','overflow-hidden').subscribe(result => {
      if (result === true) {
        this.getDatastores();
      }
    });
  }


}
