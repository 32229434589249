import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ColorPaletteService } from 'src/app/services/color-palette/color-palette.service';
import { ColorPaletteFormComponent } from './color-palette-form/color-palette-form.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ActionDialogComponent } from 'src/app/components/action-dialog/action-dialog/action-dialog.component';
import { ColorPalette } from 'src/app/models/responses/colorPaletteResponse';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss']
})
export class ColorPaletteComponent implements OnInit {

  displayedColumns: string[] = ['name', 'palettes','action'];
  columnWidth:string[]=['30%','40%','30%']
  displayedColumnsName: string[] = [this.translate.instant("ColorPalettePage.Name"),this.translate.instant("ColorPalettePage.Palettes"),this.translate.instant("ColorPalettePage.Action")];
  colorPalettes: ColorPalette[] = [];

  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private colorService: ColorPaletteService,
    private modalService: ModalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getColorPalettes()
  }

  getColorPalettes() {
    this.colorService.getColorPalettes().subscribe(result => {
      this.colorPalettes = result;
    });
  }

  deleteColorPalette(color: ColorPalette) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.colorService.deleteColorPalette(color).subscribe(result => {
          this.spinner.hide();
          this.getColorPalettes();
        });
      }
    });
  }

  openColorPalette(event: any) {
    let data = {
      type: event.type as string,
      color: event.data as ColorPalette
    }
    this.modalService.openModal(data, ColorPaletteFormComponent, '70%', '60%','overflow-hidden').subscribe(result => {
      if (result === true) {
        this.getColorPalettes();
      }
    });
  }


}
