<div class="custom-card-container">
    <div class="top-section">
        <div class="img-info">
            <div class="card-img">
                <img *ngIf="!iserrorimage" [src]="CardInfo.img" (error)="errorimage($event)"/>
                <img *ngIf="iserrorimage" src="/assets/images/python.svg" />
            </div>
            <div class="card-info">
                <!-- <span>{{item[CardInfo.smallText1]}}</span>
                <span>Created By: {{item[CardInfo.smallText2]}}</span> -->
                <span class="bottom-title">{{item[CardInfo.title]}}</span>
            </div>
        </div>
        <div class="action-area">
            <mat-icon *ngFor="let iconItem of CardInfo.icons" [svgIcon]="iconItem.icon"
                (click)="iconItem.function(item)"></mat-icon>
        </div>
    </div>
    <div class="bottom-section">
        <div class="bottom-text">
            <!-- <span class="bottom-title">{{item[CardInfo.title]}}</span> -->
            <div class="small-text">{{item[CardInfo.desc]}}</div>
        </div>
    </div>
</div>