<div class="toolbar" role="banner">
    <a [routerLink]="[navigateTo]">
        <img src="/assets/images/bussionnewlogo.png" />
    </a>
    <div style="display: flex; align-items: center;justify-content: center;">

        <!-- <div class="robot" style="padding:0.5rem;padding-right: 1rem;background-color: rgba(229,234,235,0.5);border-radius: 6rem;display: flex;margin-right: 1rem;cursor: pointer;" *ngIf="permissions.includes('ChatBot')" (click)="openChatbot()">
            <div style="background-color:rgba(136,187,203,1) ;padding: 0.4rem;border-radius: 2rem;display: flex;align-items: center;justify-content: center;">
                <mat-icon svgIcon='robot'></mat-icon>
            </div>
            <div style="display: flex;justify-content: flex-start;margin-left: 0.3rem;flex-direction: column;">
                <small style="color: rgba(136,187,203,1);">Bussion AI</small>
                <small style="color: rgba(92,102,168,1);font-size:xx-small;line-height: 5px;">With ChatBot</small>
            </div>
        </div> -->
        <div style="margin-right: 10px;" *ngIf="!disableMic">
            <app-speech-microphone *ngIf="micenable" [functionList]="functionList"></app-speech-microphone>
            <!-- <mat-icon class="mic-icon" *ngIf="!mic" (click)="openMic()">mic</mat-icon>
            <mat-icon class="mic-icon" *ngIf="mic" (click)="closeMic('kapat')">mic_off</mat-icon> -->
        </div>
        <div class="action-groups" [matMenuTriggerFor]="disableUserMenu?null: gridType">
            <div class="user-info">
                <span>{{ 'HeaderComponent.Hello' | translate }}, {{firstName}} {{lastName}}</span>
                <img src="{{avatar}}" />
            </div>

        </div>
        <!-- <img (click)="openChatbot()" style="width: 40px;" src="/assets/images/chatbot.png" alt=""> -->

    </div>
</div>

<mat-menu #gridType="matMenu">
    <span mat-menu-item (click)="showUserInfo()">{{ 'HeaderComponent.UpdateInfo' | translate }}</span>
    <span mat-menu-item *ngIf="visibleManagement" (click)="goManage()">{{ 'HeaderComponent.Manage' | translate
        }}</span>
    <span mat-menu-item (click)="logout()">{{ 'HeaderComponent.Logout' | translate }}</span>
</mat-menu>