import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/models/responses/usersResponse';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input() user: any
  @Output() selectUser: EventEmitter<any> = new EventEmitter()
  env: string = environment.imageUrl

  constructor() { }


  ngOnInit(): void {
    this.user.groups = this.user.groups.sort()
  }

  select(ev: any) {
    this.selectUser.emit(ev)
  }


}
