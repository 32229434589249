import { Component, Input } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-card',
    templateUrl: './app-card.component.html',
    styleUrls: ['./app-card.component.scss']
})

export class AppCardComponent { 
    @Input() item
    baseUrl = environment.imageUrl;
}