import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/constants/endpoints';
import { AuthService } from '../auth/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilterAiService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getSuggestionForDashboard(message: string, dashboardId) {
    let param = {
      Token: this.authService.getToken(),
      Question: message,
      DashboardId: dashboardId,
    };
    return this.http.post(Endpoints.getSuggestionForDashboard, param).pipe(
      map((response: any) => {
        return response.message;
     
      })
    );


  }

}
