<!-- <app-modal-title title="{{ 'ShareComponent.Query'| translate }}"></app-modal-title> -->
<app-custom-modal [title]="'ShareComponent.Title' | translate " [subtitle]="'ShareComponent.SubTitle'"
    img="/assets/images/share.png">
    <!-- <mat-dialog-content class="mat-typography"> -->
    <div body class="shareform-container">

        <mat-form-field>
            <mat-label>{{ 'ShareComponent.Name' | translate }}</mat-label>
            <input matInput [(ngModel)]="share.name" required>
        </mat-form-field>
        <br>

        <mat-form-field>
            <mat-label>{{ 'ShareComponent.ShowTitle' | translate }}</mat-label>
            <mat-select [(ngModel)]="share.enableTitle" [compareWith]="compareFunction">
                <mat-option value="1">True</mat-option>
                <mat-option value="0">False</mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <mat-form-field>
            <mat-label>{{ 'ShareComponent.ApplyFilter' | translate }}</mat-label>
            <mat-select [(ngModel)]="share.enableFilter" [compareWith]="compareFunction">
                <mat-option value="1">True</mat-option>
                <mat-option value="0">False</mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <mat-form-field>
            <mat-label>{{ 'ShareComponent.LockedFilter' | translate }}</mat-label>
            <mat-select [(ngModel)]="share.lockedFilter" [compareWith]="compareFunction">
                <mat-option value="1">True</mat-option>
                <mat-option value="0">False</mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <mat-form-field>
            <mat-label>{{ 'ShareComponent.shareType' | translate }}</mat-label>
            <mat-select [(ngModel)]="share.shareType" [compareWith]="compareFunction">
                <mat-option value="1">Public</mat-option>
                <mat-option value="0">Private</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'ShareComponent.AuthType' | translate }}</mat-label>
            <mat-select [(ngModel)]="share.authentication" [compareWith]="compareFunction">
                <mat-option value="0">No Authentication</mat-option>
                <mat-option value="1">OTP Authentication</mat-option>
                <mat-option value="2">Bussion Authentication</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="share.authentication==2">
            <mat-label>{{ 'ShareComponent.AuthUser' | translate }}</mat-label>
            <mat-select panelClass="no-scroll" [(ngModel)]="authenticatedUsers" [compareWith]="compareFunction" multiple
                #selectForclose>

                <div style="padding: 5px;margin: 5px; border: 1px solid gray;border-radius: 5px;">
                    <mat-label>{{ 'Common.Search' | translate }}</mat-label>
                    <input matInput (input)="searchUser($event)">
                </div>
                <div style="display: flex;align-items: center;justify-content: end;margin-right: 5px;">
                    <button mat-raised-button class="custom-primary-button button-sm w-100 mt-10"
                        (click)="selectForclose.close()">
                        <mat-icon>save</mat-icon>
                    </button>
                </div>
                <div style="overflow-y: scroll;
            max-height: 160px;
        ">

                    <mat-option *ngFor="let item of filteredUsers" [value]="item.userId">{{item.firstName+ ' '
                        +item.lastName}}</mat-option>
                </div>




            </mat-select>
        </mat-form-field>

        <!-- </mat-dialog-content> -->
        <mat-dialog-actions align="end" style="padding: 0 !important;">
            <button mat-stroked-button class="custom-primary-stroked-button" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
            <!-- <button mat-button class="b-btn" mat-dialog-close>Widget Share</button> -->
            <button mat-raised-button class="custom-primary-button" cdkFocusInitial (click)="createShare()">{{
                this.data?.data?.name?('Common.Update' | translate):('Common.Save' | translate) }}</button>
        </mat-dialog-actions>
    </div>
</app-custom-modal>