<app-modal-title [title]="data?.title?(data.title|translate):('Warning'|translate)"></app-modal-title>
<div mat-dialog-content>
  <p>{{ data?.text?(data.text|translate): ('ActionDialogComponent.Message'|translate) }}</p>
</div>
<div mat-dialog-actions *ngIf="data?.Buttons.length>0">

  <button *ngFor="let item of data?.Buttons" mat-button class="b-btn" (click)="action(false)">
    {{ item| translate }}
  </button>
</div>

<div mat-dialog-actions *ngIf="data?.Buttons.length<=0">
  <button mat-button class="b-btn" (click)="action(false)">{{ 'ActionDialogComponent.No'
    | translate }}</button>
  <button mat-button class="b-btn b-success" (click)="action(true)">{{
    'ActionDialogComponent.Yes' | translate
    }}</button>
</div>