import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { LoginRequest } from 'src/app/models/requests/loginRequest';
import { map } from 'rxjs/operators';
import { Endpoints } from 'src/app/constants/endpoints';
import { LoginResponse, User } from 'src/app/models/responses/loginResponse';
import { BehaviorSubject } from 'rxjs';
import { DashboardService } from '../dashboard/dashboard.service';
import { LanguagesShortCode } from 'src/app/models/globals/languages';
import { LicenseService } from '../license/license.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  storageKeys: string[] = [
    'user', 'token', 'domain', 'userId', 'name', 'lastName', 'avatar', 'micActive', 'licence', 'app', 'chatMessages','cachedData'
  ]

  constructor(
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private dialog:MatDialog,
    private translate: TranslateService
  ) { }

  checkSession() {
    let token = this.getToken();
    if (token) {
      this.loggedIn.next(true);
    }
    else {
      this.loggedIn.next(false);
    }
  }

  isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  isChaptchaEnabled() {
    let param = {
      Token: this.getToken()
    }
    return this.http.post(Endpoints.chaptcha, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  saveToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  logout() {
    let param = {
      Token: this.getToken()
    }
    return this.http.post(Endpoints.logout, param).pipe(
      map((response: any) => {
        this.loggedIn.next(false);
        this.clearStorage()
        // localStorage.clear();
       
        this.router.navigate(['/login']);
        this.snackBar.dismiss()
        this.dialog.closeAll()
        return response;
      })
    );

  }

  clearStorage() {
    this.storageKeys.map(key => {
      localStorage.removeItem(key)
    })
  }

  login(loginRequest: LoginRequest) {

    return this.http.post(Endpoints.login, loginRequest).pipe(
      map((response: LoginResponse) => {

        this.saveUserInfos(response.message);
        this.loggedIn.next(true);
        this.setLanguage(response.message.language)
        return response;
      })
    );
  }


  setLanguage(lang: string) {
    this.translate.use(lang);
  }



  createAccount(user: User) {
    return this.http.post(Endpoints.register, user).pipe(
      map((response: LoginResponse) => {
        this.saveUserInfos(response.message);
        this.loggedIn.next(true);
        return response.message;
      })
    );
  }

  getLoginImage(domain: string) {
    return this.http.post(Endpoints.GetLoginPagePicture, {}).pipe(
      map((response: string) => {
        return response;
      })
    );
  }

  saveUserInfos(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('domain', user.domain);
    localStorage.setItem('userId', user.userId);
    localStorage.setItem('name', user.firstName);
    localStorage.setItem('lastName', user.lastName);
    localStorage.setItem('avatar', user.avatar);

  }

  getuserOrganization() {
    let user = this.getUser();
    if (user) {
      var selectedOrg = user.organization
      return selectedOrg
    } else {
      return ''
    }
  }

  getUserDomain() {
    return localStorage.getItem('domain');
  }

  getUserId() {
    return localStorage.getItem('userId');
  }

  getUserFirstName() {
    return localStorage.getItem('name');
  }

  getUserLastName() {
    return localStorage.getItem('lastName');
  }

  getUserAvatar() {
    return localStorage.getItem('avatar');
  }

  getUserLanguage() {
    let user = this.getUser();
    if (user)
      return user.language;
    else return 'English'
  }

  getUserLangName() {
    let user = this.getUser();
    if (user) {
      var selectedLang = this.getShortCodeForLang(user.language)
      return selectedLang.code
    } else {
      return 'tr'
    }

  }

  getShortCodeForLang(lang) {
    return LanguagesShortCode.find(x => x.name == lang)
  }

  getUser() {
    let user = JSON.parse(localStorage.getItem('user'));
    return user;
  }

  getPermissions() {
    if (localStorage.getItem('user'))
      return JSON.parse(localStorage.getItem('user'))?.permissions
    else
      return []
  }

  getRoles() {
    if (localStorage.getItem('user'))
      return JSON.parse(localStorage.getItem('user'))?.roles
    else
      return []
  }

}
