import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { tap } from 'rxjs/operators';
import { DataStore } from 'src/app/models/responses/dataStoreResponse';
import { CoreService } from 'src/app/services/core/core.service';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';

@Component({
  selector: 'app-index-form',
  templateUrl: './index-form.component.html',
  styleUrls: ['./index-form.component.scss']
})
export class IndexFormComponent implements OnInit {

  compareFunctionForSelectList = (o1: any, o2: any) => o1 === o2;
  columns: any[] = [];
  loadingDatastores: boolean = false

  columnsForList = []
  indexedColumnsForList = []
  indexedColumnsForListinitValue = []
  dropIndexColumnList = []

  constructor(@Inject(MAT_DIALOG_DATA) public data: { dataStore: DataStore, dataStoreId: string },
    public dialogRef: MatDialogRef<IndexFormComponent>,
    private dataStoreService: DataStoreService, private coreService: CoreService) { }

  ngOnInit(): void {

    if (this.data.dataStore == null) {
      this.getDataStoreById()
    } else {
      this.getColumnInfo()
    }

  }

  getColumnInfo() {
    this.getColumns().toPromise().then(res => {
      this.getIndexedColumns()
    });
  }

  private getDataStoreById() {
    this.loadingDatastores = true
    this.dataStoreService.getDatasourceById(this.data.dataStoreId).subscribe(result => {
      this.loadingDatastores = false
      if (this.data.dataStoreId) {
        this.data.dataStore = result
        this.getColumnInfo()
      }
    });
  }


  private getColumns() {
    return this.dataStoreService.getColumnsByNodeId(this.data.dataStore.nodeId, this.data.dataStore.universe, this.data.dataStore.collection).pipe(
      tap(result => {
        this.columns = result;
        this.data.dataStore.columns = this.columns.filter(x => x.fieldName !== undefined)
      })
    );
  }

  getIndexedColumns() {
    this.indexedColumnsForList = []

    this.dataStoreService.getIndexColumns(this.data.dataStore.dataStoreId).subscribe(res => {
      res.map(col => {
        var indexnameprefix = `idx_${this.data.dataStore.collection}_`
        var selectedCol = this.data.dataStore.columns.find(x => indexnameprefix + x.fieldName == col)

        if (selectedCol) {
          this.indexedColumnsForList.push(selectedCol)
          this.indexedColumnsForListinitValue.push(selectedCol)
        } else {
          var externalcolumn = col

          if (col.includes(indexnameprefix)) {
            externalcolumn = col.split(indexnameprefix)[1]
            this.data.dataStore.columns.push({ fieldFilter: 0, fieldName: externalcolumn, fieldType: '' })
          }

          this.indexedColumnsForListinitValue.push({ fieldName: externalcolumn, fieldFilter: 0, fieldType: "System.String", visibleName: "" })
          this.indexedColumnsForList.push({ fieldName: externalcolumn, fieldFilter: 0, fieldType: "System.String", visibleName: "" })
        }

      })

      this.columnsForList = this.findDifference(this.columns, this.indexedColumnsForList)
    })
  }

  dropIndexes() {
    return this.dataStoreService.DropIndex(this.data.dataStore.dataStoreId, this.dropIndexColumnList)
      .subscribe(res => {
        if (res.result) {
          this.coreService.showSuccessMessage(res.message)
          this.dialogRef.close(true);
        } else {
          this.coreService.showErrorMessage(res.message)

        }
      })
  }


  createIndexes() {
    var currentindexed = this.indexedColumnsForList.map(item => { return item.fieldName })
    var initindexed = this.indexedColumnsForListinitValue.map(item => { return item.fieldName })

    var unselected = this.columnsForList.map(item => { return item.fieldName })
    var newaddedList = []

    let differentElements = this.findDifference(currentindexed, initindexed);
    differentElements.map(item => {
      if (!currentindexed.some(x => x == item)) {
        this.dropIndexColumnList.push(item)
      } else {
        newaddedList.push(item)
      }
    })
    if (this.dropIndexColumnList.length > 0) {
      this.dropIndexes()
    }
   


    if (newaddedList.length > 0) {
      this.dataStoreService.createIndex(this.data.dataStore.dataStoreId, currentindexed).subscribe(res => {
        if (res.result) {
          this.coreService.showSuccessMessage(res.message)
          this.dialogRef.close(true)
        } else {
          this.coreService.showErrorMessage(res.message)

        }
      })
    }
  }

  selectionChangeSelect(event: MatSelectionListChange) {


    this.columnsForList = this.columnsForList.filter(x => x.fieldName !== event.options[0].value)

    this.indexedColumnsForList.unshift({ fieldName: event.options[0].value, fieldFilter: 0, fieldType: "System.String", visibleName: "" })
  }

  selectionChangeUnSelect(event: MatSelectionListChange) {

    this.columnsForList.unshift({ fieldName: event.options[0].value, fieldFilter: 0, fieldType: "System.String", visibleName: "" })

    this.indexedColumnsForList = this.indexedColumnsForList.filter(x => x.fieldName !== event.options[0].value)
  }



  findDifference(arr1, arr2) {
    let difference = arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)));
    return difference;
  }

}
