<app-modal-title [title]="title"></app-modal-title>
<mat-dialog-content class="mat-typography">

    <mat-horizontal-stepper labelPosition="bottom" linear #stepper>
        <ng-template matStepperIcon="edit">
            <mat-icon>done</mat-icon>
        </ng-template>

        <mat-step [stepControl]="firstForm">
            <ng-template matStepLabel>{{ 'ConnectorFormPage.StepOne' | translate }}</ng-template>

            <form [formGroup]="firstForm">
                <mat-form-field>
                    <mat-label>{{ 'ConnectorFormPage.Name' | translate }}</mat-label>
                    <input matInput [(ngModel)]="task.name" formControlName="name" required>
                </mat-form-field>
                <br>
            </form>

            <mat-form-field>
                <mat-label>{{ 'ConnectorFormPage.Type' | translate }}</mat-label>
                <mat-select [(ngModel)]="task.type" [compareWith]="compareFunctionForTaskType"
                    (ngModelChange)="taskTypeChanged(task.type)">
                    <mat-option value="0">Batch</mat-option>
                    <mat-option value="1">Periodically</mat-option>
                    <mat-option value="2">Direct</mat-option>
                </mat-select>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'ConnectorFormPage.Status' | translate }}</mat-label>
                <mat-select [(ngModel)]="task.status" [compareWith]="compareFunctionForTaskStatus">
                    <mat-option value="0">Passive</mat-option>
                    <mat-option value="1">Active</mat-option>
                </mat-select>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'ConnectorFormPage.Folder' | translate }}</mat-label>
                <input matInput [formControl]="folderAutoComplete" [(ngModel)]="task.folder"
                    [matAutocomplete]="autoFolder">
                <mat-autocomplete #autoFolder="matAutocomplete">
                    <mat-option *ngFor="let option of filteredFolderOptions | async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </mat-step>

        <mat-step [stepControl]="secondForm">
            <ng-template matStepLabel>{{ 'ConnectorFormPage.StepTwo' | translate }}</ng-template>

            <form [formGroup]="secondForm">
                <mat-form-field>
                    <mat-label>{{ 'ConnectorFormPage.DataFrom' | translate }}</mat-label>
                    <mat-select [(ngModel)]="task.sourceType" (ngModelChange)="changeSecondFormValidation($event) "
                        [compareWith]="compareFunctionForTaskSourceType" formControlName="sourceType" required>
                        <mat-option value="0">Oracle</mat-option>
                        <mat-option value="1">Microsoft SQL Server</mat-option>
                        <mat-option value="2">My SQL</mat-option>
                        <mat-option value="3">Postgress</mat-option>
                        <mat-option value="4">ODBC</mat-option>
                        <mat-option value="5">Vertica</mat-option>
                        <mat-option value="6">Excel</mat-option>
                        <mat-option value="7">CSV</mat-option>
                        <mat-option value="8">Python Script</mat-option>
                        <mat-option value="9">Data Flow</mat-option>
                        <mat-option value="10">External Engine</mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                <form [formGroup]="fourthForm" *ngIf="task.sourceType == 10">
                    <mat-form-field>
                        <mat-label>{{ 'ConnectorFormPage.RunTaskIn' | translate }}</mat-label>
                        <mat-select (ngModelChange)="bussionConnectorChange()" [(ngModel)]="task.bussionConnector"
                            formControlName="bussionConnector" required>
                            <mat-option [value]="node.nodeId" *ngFor="let node of connectors">{{node.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <br>
                </form>
                <br>
                <div>
                    <mat-form-field *ngIf="task.sourceType==9">
                        <mat-label>{{ 'ConnectorFormPage.ScriptFlow' | translate }}</mat-label>
                        <mat-select formControlName="connectionString" [(ngModel)]="this.task.connectionString"
                            (ngModelChange)="selectFlow(this.task.connectionString)" required>
                            <mat-option *ngFor="let item of scriptFlows"
                                [value]="item.flowId">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="task.sourceType == 8 ||task.sourceType == 9||task.sourceType==10"
                        style="display: flex;align-items: start;justify-content: space-between;gap: 10px;">


                        <mat-form-field>
                            <mat-label *ngIf="task.sourceType == 8">{{ 'ConnectorFormPage.ScriptPath' | translate
                                }}</mat-label>
                            <mat-label *ngIf="task.sourceType == 9">{{ 'ConnectorFormPage.FlowId' | translate
                                }}</mat-label>
                            <mat-label *ngIf="task.sourceType == 10">{{ 'ConnectorFormPage.EngineName' | translate
                                }}</mat-label>

                            <input *ngIf="!(task.sourceType==10)&&useConnectionString" matInput
                                [(ngModel)]="task.connectionString" formControlName="connectionString" required
                                [readonly]="task.sourceType == 9">


                            <!-- <mat-select *ngIf="task.sourceType==10" [(ngModel)]="task.connectionString"
                                formControlName="connectionString" (ngModelChange)="getExternalEngineCode()" required>
                                <mat-option *ngFor="let item of externalEnginesByConnector"
                                    [value]="item">{{item}}</mat-option>
                            </mat-select> -->
                            <div *ngIf="task.sourceType==10">
                                <input [(ngModel)]="task.connectionString" formControlName="connectionString"
                                    (ngModelChange)="getExternalEngineCode()" type="text" placeholder="Pick one"
                                    aria-label="Number" matInput [formControl]="autoComplete" [matAutocomplete]="auto"
                                    [readonly]="this.task.bussionConnector==''">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>

                        </mat-form-field>

                        <mat-spinner *ngIf="loadingEngines" style="width: 30px;" [diameter]="20"></mat-spinner>

                        <br>
                        <button (click)="openCodeScreen()" *ngIf="task.sourceType == 10" mat-stroked-button
                            class="custom-primary-stroked-button">
                            {{ 'Common.Code' | translate }}
                        </button>
                    </div>
                    <small *ngIf="this.task.bussionConnector==''&&task.sourceType==10"
                        style="color: red;">*{{"ConnectorFormPage.selectNode"|translate}}</small>
                    <div *ngIf="(!(task.sourceType == 0 || task.sourceType == 4 || task.sourceType == 8||task.sourceType == 9)&&!useConnectionString)
                        ||(task.sourceType==10)">
                        <mat-form-field>
                            <mat-label>{{ 'ConnectorFormPage.Host' | translate }}</mat-label>
                            <input matInput (focusout)="getCredentials()" [(ngModel)]="task.sourceServer" formControlName="sourceServer" required>
                        </mat-form-field>
                        <br>

                        <mat-form-field>
                            <mat-label>{{ 'ConnectorFormPage.Port' | translate }}</mat-label>
                            <input matInput (focusout)="getCredentials()" [(ngModel)]="task.sourcePort" formControlName="sourcePort" required>
                        </mat-form-field>
                        <br>

                        <mat-form-field>
                            <mat-label>{{ 'ConnectorFormPage.Database' | translate }}</mat-label>
                            <input matInput [(ngModel)]="task.sourceDatabase" formControlName="sourceDatabase" required>
                        </mat-form-field>
                        <br>

                        <mat-form-field>
                            <mat-label>{{ 'ConnectorFormPage.Username' | translate }}</mat-label>
                            <input matInput [(ngModel)]="task.sourceUser" formControlName="sourceUser" required>
                        </mat-form-field>
                        <br>

                        <mat-form-field>
                            <mat-label>{{ 'ConnectorFormPage.Password' | translate }}</mat-label>
                            <input id="sourcePass" matInput [(ngModel)]="task.sourcePassword" type="password"
                                formControlName="sourcePassword" required (input)="password=$event.target.value">
                        </mat-form-field>
                        <br>
                    </div>



                    <mat-form-field *ngIf="task.sourceType == 0 || task.sourceType == 4">
                        <mat-label *ngIf="task.sourceType == 4">{{ 'ConnectorFormPage.DsnNumber' | translate
                            }}</mat-label>
                        <mat-label *ngIf="task.sourceType == 0">{{ 'ConnectorFormPage.ConnectionString' | translate }}
                        </mat-label>
                        <textarea matInput [(ngModel)]="task.connectionString" formControlName="connectionString"
                            required></textarea>
                    </mat-form-field>
                    <br>

                </div>
                <mat-form-filed
                    *ngIf="!(task.sourceType==8||task.sourceType==9||task.sourceType == 10||task.sourceType==4||task.sourceType==0)">
                    <div *ngIf="useConnectionString">
                        <mat-form-field>
                            <mat-label>{{ 'ConnectorFormPage.ConnectionString' | translate }}</mat-label>
                            <textarea matInput [(ngModel)]="task.connectionString" formControlName="connectionString"
                                required></textarea>
                        </mat-form-field>
                        <br>
                    </div>
                    <mat-checkbox [(ngModel)]="useConnectionString" [checked]="useConnectionString"
                        (change)="checkConnection($event)">{{ 'ConnectorFormPage.UseConnectionString' | translate }}</mat-checkbox>
                </mat-form-filed>


                <div class="flow-detail" *ngIf="task.sourceType==9&&selectedScriptFlow">
                    <span>
                        <mat-icon>description</mat-icon>
                        {{selectedScriptFlow.description}}
                    </span>
                    <span>
                        <mat-icon>person</mat-icon>
                        {{selectedScriptFlow.owner}}
                    </span>
                    <span>
                        <mat-icon>calendar_today</mat-icon>
                        {{selectedScriptFlow.createDate}}
                    </span>
                    <!-- <span>
                        {{ 'ConnectorFormPage.FlowId' | translate }}
                        {{selectedScriptFlow.flowId}}
                    </span> -->

                </div>


                <!-- <mat-radio-group formControlName="selectedScriptFlow" [(ngModel)]="selectedScriptFlow"
                        (change)="selectFlow($event)" aria-label="Select an option">
                        <mat-radio-button *ngFor="let item of scriptFlows"
                            [value]="item.flowId">{{item.name}}</mat-radio-button>
                    </mat-radio-group> -->
                <br>


                <mat-form-field>
                    <mat-label>{{ 'ConnectorFormPage.Timeout' | translate }}</mat-label>
                    <input matInput type="number" [min]="10" [defaultValue]="30" [(ngModel)]="task.commandTimeOut"
                        formControlName="commandTimeOut" required>
                </mat-form-field>
                <br>

                <mat-form-field>
                    <mat-label>{{ 'ConnectorFormPage.DisableThreshold' | translate }}</mat-label>
                    <input matInput type="number" [min]="0" [defaultValue]="3" [(ngModel)]="task.disableThreshold"
                        formControlName="disableThreshold" required>
                </mat-form-field>
                <br>

            </form>


        </mat-step>

        <mat-step [stepControl]="thirdForm" *ngIf="!(task.sourceType == 8||task.sourceType == 9)">
            <ng-template matStepLabel>{{ 'ConnectorFormPage.StepThree' | translate }}</ng-template>
            <form [formGroup]="thirdForm">
                <mat-form-field>
                    <mat-label>{{ 'ConnectorFormPage.DataNode' | translate }}</mat-label>
                    <mat-select (selectionChange)="checkInMemoryInDataNodeChange($event)" [(ngModel)]="task.node"
                        formControlName="node" required>
                        <mat-option [value]="node.nodeId" *ngFor="let node of dataNodes">{{node.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <br>

                <mat-form-field>
                    <mat-label>{{ 'ConnectorFormPage.Universe' | translate }}</mat-label>
                    <input (ngModelChange)="universeSelection()" [(ngModel)]="task.universe" matInput
                        formControlName="universe" [matAutocomplete]="autoUniverse">
                    <mat-autocomplete #autoUniverse="matAutocomplete">
                        <mat-option *ngFor="let option of filteredUniverseOption | async" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <br>

                <mat-form-field>
                    <mat-label>{{ 'ConnectorFormPage.Collection' | translate }}</mat-label>
                    <input (ngModelChange)="collectionSelection()" [(ngModel)]="task.collection" matInput
                        formControlName="collection" [matAutocomplete]="autoCollection">
                    <mat-autocomplete #autoCollection="matAutocomplete">
                        <mat-option *ngFor="let option of filteredCollectionOption | async" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <br>

                <mat-form-field *ngIf="task.type==0">
                    <mat-label>{{ 'ConnectorFormPage.keepExistingSchema' | translate }}</mat-label>
                    <mat-select [(ngModel)]="task.keepExistingSchema" formControlName="keepExistingSchema" required>
                        <mat-option [value]="0">Update Data Structure</mat-option>
                        <mat-option [value]="1">Keep Existing Data Structure</mat-option>

                    </mat-select>
                </mat-form-field>
                <br>
                <mat-checkbox color="primary" *ngIf="showInMemory" [checked]="checked" (change)="checkInMemory()">
                    {{'ConnectorFormPage.RunInMemory' | translate}}
                </mat-checkbox>
                <br>
                <!-- bulk insert -->

                <mat-form-filed>
                    <mat-checkbox style="margin-bottom:10px" [(ngModel)]="bulkInsert" [checked]="bulkInsert"
                        formControlName="bulkInsert">
                        {{'ConnectorFormPage.BulkInsert' | translate}}
                    </mat-checkbox>

                    <mat-form-field>
                        <mat-label>{{ 'ConnectorFormPage.BufferSize' | translate }}</mat-label>
                        <input matInput type="number" [min]="100" [max]="1000000000" [defaultValue]="10000"
                            [(ngModel)]="task.bufferSize" formControlName="bufferSize" required>
                        <br>
                    </mat-form-field>

                </mat-form-filed>
                <br>
                <!--  -->
            </form>
        </mat-step>

        <mat-step [stepControl]="fourthForm" *ngIf="!(task.sourceType==10)">
            <ng-template matStepLabel>{{ 'ConnectorFormPage.StepFour' | translate }}</ng-template>
            <form [formGroup]="fourthForm">
                <mat-form-field>
                    <mat-label>{{ 'ConnectorFormPage.RunTaskIn' | translate }}</mat-label>
                    <mat-select [(ngModel)]="task.bussionConnector" formControlName="bussionConnector" required>
                        <mat-option [value]="node.nodeId" *ngFor="let node of connectors">{{node.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
            </form>
        </mat-step>

        <mat-step *ngIf="!(task.sourceType == 8||task.sourceType ==9)">
            <ng-template matStepLabel>{{ 'ConnectorFormPage.StepFive' | translate }}</ng-template>
            <div style="display: flex;width: 100%;align-items: center;" *ngIf="task.type == 1">
                <mat-form-field>
                    <mat-label>{{ 'ConnectorFormPage.KeyField' | translate }}</mat-label>
                    <input matInput [formControl]="columnAutoComplete" [(ngModel)]="task.keyField"
                        [matAutocomplete]="autoColumn">
                    <mat-autocomplete #autoColumn="matAutocomplete">
                        <mat-option *ngFor="let option of filteredColumnOption | async" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-icon [matTooltip]="'ConnectorFormPage.KeyFieldText'|translate">info</mat-icon>
                <br>
            </div>
            <div style="display: flex;width: 100%;align-items: center;" *ngIf="task.type == 1">
                <mat-form-field>
                    <mat-label>{{ 'ConnectorFormPage.UpdateField' | translate }}</mat-label>
                    <input matInput [formControl]="columnAutoComplete2" [(ngModel)]="task.updateField"
                        [matAutocomplete]="autoColumn2">
                    <mat-autocomplete #autoColumn2="matAutocomplete">
                        <mat-option *ngFor="let option of filteredColumnOption2 | async" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-icon [matTooltip]="'ConnectorFormPage.DataFieldText'|translate">info</mat-icon>
                <br>
            </div>
            <div style="height: 500px">
                <div style="display: flex;justify-content: end;">

                    <button mat-button (click)="previewScreen?previewScreen=false:PassModal()">
                        {{previewScreen?('ConnectorFormPage.CodeScreen' | translate):('ConnectorFormPage.GetData' |
                        translate)}}
                    </button>
                    <button mat-button (click)="openBiggerEditor()">
                        {{ 'Common.ShowBiggerEditor' | translate}}
                    </button>

                </div>
                <div *ngIf="previewScreen" style="height:90%;">
                    <app-ag-grid-table [columnTypes]="dataDataPreviewColumns"
                        [rowData]="dataPreview"></app-ag-grid-table>
                </div>
                <ngx-monaco-editor *ngIf="!previewScreen" style="height: 500px" [options]="editorOptions"
                    [(ngModel)]="task.query" (onInit)="editorInit($event)">
                </ngx-monaco-editor>
            </div>



            <div class="direct-query-form" *ngIf="task.type == 2">
                <div class="filter-form">
                    <div class="filter-form-content">

                        <mat-form-field>
                            <mat-label>{{ 'ConnectorFormPage.ParameterKey' | translate }}</mat-label>
                            <input matInput [(ngModel)]="parameter.parameterName">
                        </mat-form-field>
                        <br>

                        <mat-form-field>
                            <mat-label>{{ 'ConnectorFormPage.ParameterValue' | translate }}</mat-label>
                            <input matInput [(ngModel)]="parameter.parameterValue">
                        </mat-form-field>
                        <br>


                    </div>
                    <div class="filter-action-content">
                        <button mat-button cdkFocusInitial
                            [disabled]="parameter.parameterName == '' && parameter.parameterValue == ''"
                            (click)="addParameter()">{{ 'Common.Add' | translate }}</button>
                    </div>
                </div>

                <div class="filter-list-content">
                    <div class="list-item" *ngFor="let parameter of task.queryParameter">
                        <span>{{parameter.parameterName}} {{parameter.parameterValue}}</span>
                        <mat-icon matListIcon color="warn" (click)="deleteParameter(parameter)">delete_outline
                        </mat-icon>
                    </div>
                </div>
                <br>
            </div>

        </mat-step>

        <mat-step *ngIf="task.type != 2">
            <ng-template matStepLabel>{{ 'ConnectorFormPage.StepSix' | translate }}</ng-template>

            <mat-form-field>
                <mat-label>{{"ConnectorFormPage.ScheduleTask" |translate}}</mat-label>
                <mat-select [(ngModel)]="task.runAtPeriod.type" (ngModelChange)="periodTypeChanged($event)" required>
                    <mat-option [value]="-1">{{"ConnectorFormPage.NotSceduled"|translate}}</mat-option>
                    <mat-option [value]="0">{{"ConnectorFormPage.PerSecond"|translate}}</mat-option>
                    <mat-option [value]="1">{{"ConnectorFormPage.PerMinute"|translate}}</mat-option>
                    <mat-option [value]="2">{{"ConnectorFormPage.PerHour"|translate}}</mat-option>
                    <mat-option [value]="3">{{"ConnectorFormPage.PerDay"|translate}}</mat-option>
                    <mat-option [value]="4">{{"ConnectorFormPage.PerWeek"|translate}}</mat-option>
                    <mat-option [value]="5">{{"ConnectorFormPage.PerMonth"|translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            <br>

            <div *ngIf="task.runAtPeriod.type == 0">
                <mat-form-field>
                    <mat-label>{{"ConnectorFormPage.EverySecond"|translate}}</mat-label>
                    <mat-select [(ngModel)]="task.runAtPeriod.seconds" required>
                        <mat-option [value]="5">5</mat-option>
                        <mat-option [value]="10">10</mat-option>
                        <mat-option [value]="15">15</mat-option>
                        <mat-option [value]="20">20</mat-option>
                        <mat-option [value]="25">25</mat-option>
                        <mat-option [value]="30">30</mat-option>
                        <mat-option [value]="35">35</mat-option>
                        <mat-option [value]="40">40</mat-option>
                        <mat-option [value]="45">45</mat-option>
                        <mat-option [value]="55">55</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="task.runAtPeriod.type == 1">
                <mat-form-field>
                    <mat-label>{{"ConnectorFormPage.EveryMinute"|translate}}</mat-label>
                    <mat-select [(ngModel)]="task.runAtPeriod.minute" required>
                        <mat-option [value]="5">5</mat-option>
                        <mat-option [value]="10">10</mat-option>
                        <mat-option [value]="15">15</mat-option>
                        <mat-option [value]="20">20</mat-option>
                        <mat-option [value]="25">25</mat-option>
                        <mat-option [value]="30">30</mat-option>
                        <mat-option [value]="35">35</mat-option>
                        <mat-option [value]="40">40</mat-option>
                        <mat-option [value]="45">45</mat-option>
                        <mat-option [value]="55">55</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="task.runAtPeriod.type == 2">
                <mat-form-field>
                    <mat-label>{{"ConnectorFormPage.EveryHour"|translate}}</mat-label>
                    <mat-select [(ngModel)]="task.runAtPeriod.hour" required>
                        <mat-option [value]="1">1</mat-option>
                        <mat-option [value]="2">2</mat-option>
                        <mat-option [value]="3">3</mat-option>
                        <mat-option [value]="4">4</mat-option>
                        <mat-option [value]="5">5</mat-option>
                        <mat-option [value]="6">6</mat-option>
                        <mat-option [value]="7">7</mat-option>
                        <mat-option [value]="8">8</mat-option>
                        <mat-option [value]="9">9</mat-option>
                        <mat-option [value]="10">10</mat-option>
                        <mat-option [value]="11">11</mat-option>
                        <mat-option [value]="12">12</mat-option>
                        <mat-option [value]="13">13</mat-option>
                        <mat-option [value]="14">14</mat-option>
                        <mat-option [value]="15">15</mat-option>
                        <mat-option [value]="16">16</mat-option>
                        <mat-option [value]="17">17</mat-option>
                        <mat-option [value]="18">18</mat-option>
                        <mat-option [value]="19">19</mat-option>
                        <mat-option [value]="20">20</mat-option>
                        <mat-option [value]="21">21</mat-option>
                        <mat-option [value]="22">22</mat-option>
                        <mat-option [value]="23">23</mat-option>
                        <mat-option [value]="24">24</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="task.runAtPeriod.type == 3">
                <mat-form-field>
                    <mat-label>{{"ConnectorFormPage.EveryDay"|translate}}</mat-label>
                    <mat-select [(ngModel)]="task.runAtPeriod.day" required>
                        <mat-option [value]="1">1</mat-option>
                        <mat-option [value]="2">2</mat-option>
                        <mat-option [value]="3">3</mat-option>
                        <mat-option [value]="4">4</mat-option>
                        <mat-option [value]="5">5</mat-option>
                        <mat-option [value]="6">6</mat-option>
                        <mat-option [value]="7">7</mat-option>
                        <mat-option [value]="8">8</mat-option>
                        <mat-option [value]="9">9</mat-option>
                        <mat-option [value]="10">10</mat-option>
                        <mat-option [value]="11">11</mat-option>
                        <mat-option [value]="12">12</mat-option>
                        <mat-option [value]="13">13</mat-option>
                        <mat-option [value]="14">14</mat-option>
                        <mat-option [value]="15">15</mat-option>
                        <mat-option [value]="16">16</mat-option>
                        <mat-option [value]="17">17</mat-option>
                        <mat-option [value]="18">18</mat-option>
                        <mat-option [value]="19">19</mat-option>
                        <mat-option [value]="20">20</mat-option>
                        <mat-option [value]="21">21</mat-option>
                        <mat-option [value]="22">22</mat-option>
                        <mat-option [value]="23">23</mat-option>
                        <mat-option [value]="24">24</mat-option>
                        <mat-option [value]="25">25</mat-option>
                        <mat-option [value]="26">26</mat-option>
                        <mat-option [value]="27">27</mat-option>
                        <mat-option [value]="28">28</mat-option>
                        <mat-option [value]="29">29</mat-option>
                        <mat-option [value]="30">30</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="task.runAtPeriod.type == 4">
                <mat-form-field>
                    <mat-label>{{"ConnectorFormPage.ThisWeekDay"|translate}}</mat-label>
                    <mat-select [(ngModel)]="task.runAtPeriod.day" required>
                        <mat-option [value]="1">{{"ConnectorFormPage.Monday"|translate}}</mat-option>
                        <mat-option [value]="2">{{"ConnectorFormPage.Tuesday"|translate}}</mat-option>
                        <mat-option [value]="3">{{"ConnectorFormPage.Wednesday"|translate}}</mat-option>
                        <mat-option [value]="4">{{"ConnectorFormPage.Thursday"|translate}}</mat-option>
                        <mat-option [value]="5">{{"ConnectorFormPage.Friday"|translate}}</mat-option>
                        <mat-option [value]="6">{{"ConnectorFormPage.Saturday"|translate}}</mat-option>
                        <mat-option [value]="0">{{"ConnectorFormPage.Sunday"|translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-form-field *ngIf="task.runAtPeriod.type == 5">
                <mat-label>{{"ConnectorFormPage.ThisMonthDay"|translate}}</mat-label>
                <mat-select [(ngModel)]="task.runAtPeriod.day">
                    <mat-option [value]="0">1</mat-option>
                    <mat-option [value]="0">2</mat-option>
                    <mat-option [value]="1">3</mat-option>
                    <mat-option [value]="2">4</mat-option>
                    <mat-option [value]="3">5</mat-option>
                    <mat-option [value]="3">6</mat-option>
                    <mat-option [value]="3">7</mat-option>
                    <mat-option [value]="0">8</mat-option>
                    <mat-option [value]="0">9</mat-option>
                    <mat-option [value]="10">10</mat-option>
                    <mat-option [value]="11">11</mat-option>
                    <mat-option [value]="12">12</mat-option>
                    <mat-option [value]="13">13</mat-option>
                    <mat-option [value]="14">14</mat-option>
                    <mat-option [value]="15">15</mat-option>
                    <mat-option [value]="16">16</mat-option>
                    <mat-option [value]="17">17</mat-option>
                    <mat-option [value]="18">18</mat-option>
                    <mat-option [value]="19">19</mat-option>
                    <mat-option [value]="20">20</mat-option>
                    <mat-option [value]="21">21</mat-option>
                    <mat-option [value]="22">22</mat-option>
                    <mat-option [value]="23">23</mat-option>
                    <mat-option [value]="24">24</mat-option>
                    <mat-option [value]="25">25</mat-option>
                    <mat-option [value]="26">26</mat-option>
                    <mat-option [value]="27">27</mat-option>
                    <mat-option [value]="28">28</mat-option>
                    <mat-option [value]="29">29</mat-option>
                    <mat-option [value]="30">30</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="hour-selection"
                *ngIf="task.runAtPeriod.type != -1 && (task.runAtPeriod.type == 3 || task.runAtPeriod.type == 4 || task.runAtPeriod.type == 5)">
                <mat-form-field>
                    <mat-label>{{'ConnectorFormPage.ThisHour'|translate}}</mat-label>
                    <mat-select [(ngModel)]="task.runAtPeriod.hour" required>
                        <mat-option [value]="0">00</mat-option>
                        <mat-option [value]="1">01</mat-option>
                        <mat-option [value]="2">02</mat-option>
                        <mat-option [value]="3">03</mat-option>
                        <mat-option [value]="4">04</mat-option>
                        <mat-option [value]="5">05</mat-option>
                        <mat-option [value]="6">06</mat-option>
                        <mat-option [value]="7">07</mat-option>
                        <mat-option [value]="8">08</mat-option>
                        <mat-option [value]="9">09</mat-option>
                        <mat-option [value]="10">10</mat-option>
                        <mat-option [value]="11">11</mat-option>
                        <mat-option [value]="12">12</mat-option>
                        <mat-option [value]="13">13</mat-option>
                        <mat-option [value]="14">14</mat-option>
                        <mat-option [value]="15">15</mat-option>
                        <mat-option [value]="16">16</mat-option>
                        <mat-option [value]="17">17</mat-option>
                        <mat-option [value]="18">18</mat-option>
                        <mat-option [value]="19">19</mat-option>
                        <mat-option [value]="20">20</mat-option>
                        <mat-option [value]="21">21</mat-option>
                        <mat-option [value]="22">22</mat-option>
                        <mat-option [value]="23">23</mat-option>
                        <mat-option [value]="24">24</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{"ConnectorFormPage.ThisMinute" |translate}}</mat-label>
                    <mat-select [(ngModel)]="task.runAtPeriod.minute" required>
                        <mat-option [value]="0">00</mat-option>
                        <mat-option [value]="5">05</mat-option>
                        <mat-option [value]="10">10</mat-option>
                        <mat-option [value]="15">15</mat-option>
                        <mat-option [value]="30">30</mat-option>
                        <mat-option [value]="45">45</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </mat-step>

    </mat-horizontal-stepper>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-primary" (click)="backToPreviousStep()" *ngIf="stepper.selectedIndex !== 0">{{
        'Common.Back' | translate }}</button>

    <button mat-button class="b-btn b-success" cdkFocusInitial
        *ngIf="(stepper.selectedIndex === stepCount||stepper.selectedIndex==0)&&task.connectorId"
        (click)="saveTask(true)">{{
        'Common.SaveAs' | translate }}</button>

    <button mat-button class="b-btn b-success" (click)="goToNextStep()" *ngIf="stepper.selectedIndex !== stepCount">{{
        'Common.Next' |
        translate }}</button>



    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="stepper.selectedIndex === stepCount"
        (click)="saveTask(false)">{{
        'Common.Done' | translate }}</button>

</mat-dialog-actions>



<ng-template #passwordDialog>
    <div class="password-dialog">
        <div class="di-header">
            <span>{{ 'ConnectorFormPage.EnterPassword' | translate }}</span>
            <mat-icon>clear</mat-icon>
        </div>
        <div class="di-body">
            <div class="di-form">
                <mat-form-field>
                    <mat-label>{{ 'ConnectorFormPage.Password' | translate }}</mat-label>
                    <input [type]="passwordinputtype" matInput [(ngModel)]="password">
                </mat-form-field>
                <mat-icon [svgIcon]="'eye-svgrepo-com'"
                    (click)="passwordinputtype= passwordinputtype=='password'?'text':'password'"></mat-icon>
            </div>
        </div>

        <button mat-button [matDialogClose]="false">{{"Common.Cancel"|translate}}</button>
        <button mat-button [matDialogClose]="true">{{"Common.Done"|translate}}</button>
    </div>
</ng-template>