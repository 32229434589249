import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { WidgetLibrary } from 'src/app/models/responses/widgetLibraryResponse';
import { WidgetLibraryService } from 'src/app/services/widget-library/widget-library.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ActionDialogComponent } from 'src/app/components/action-dialog/action-dialog/action-dialog.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'app-widget-library-form',
  templateUrl: './widget-library-form.component.html',
  styleUrls: ['./widget-library-form.component.scss']
})
export class WidgetLibraryFormComponent implements OnInit {

  pageForm: UntypedFormGroup;
  title: string = "";
  fileData: File = null;
  isCreateForm: boolean = true;
  selectedPaths: string[] = [];
  filePaths: string[] = [];
  iconImage;
  widgetLibrary: WidgetLibrary = {
    widgetLibraryId: "",
    name: "",
    icon: "",
    folder: "",
    javaScriptLibrary: [],
    javaScriptLibraryPaths: [],
    creationDate: new Date(),
    updateDate: new Date,
    createdby: "",
    updatedby: ""
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<WidgetLibraryFormComponent>,
    private widgetLibraryService: WidgetLibraryService,
    private spinner: NgxSpinnerService,
    private coreService: CoreService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.pageForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.widgetLibrary.name, [Validators.required]),
      folder: new UntypedFormControl(this.widgetLibrary.folder, [Validators.required])
    });

    this.title = this.data.type == 'update' ? this.translate.instant("WidgetLibraryFormPage.TitleUpdate") : this.translate.instant("WidgetLibraryFormPage.TitleCreate");
    if (this.data.widgetLibrary) {
      this.widgetLibrary = Object.assign({}, this.data.widgetLibrary);
      this.selectedPaths = this.widgetLibrary.javaScriptLibraryPaths;
      this.widgetLibrary.icon = environment.imageUrl + this.widgetLibrary.icon;
    }
    this.getFilePaths();
  }

  getFilePaths() {
    if (this.widgetLibrary.folder == "") { return; }
    this.spinner.show();
    this.widgetLibraryService.getJavascriptFiles(this.widgetLibrary.folder).subscribe(result => {
      this.spinner.hide();
      this.filePaths = this.mergeList(result);
    });
  }

  mergeList(folderFiles: string[]) {
    let paths = [];
    folderFiles.forEach(file => {
      let isExist = this.selectedPaths.includes(file);
      if (!isExist) paths.push(file);
    })
    return paths;
  }

  deleteFilePath(path: string) {

    const dialogRef = this.dialog.open(ActionDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        title: 'ActionDialogComponent.Warning',
        Buttons:[
        ],
        text:'ActionDialogComponent.Message'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      
      if (result === true) {
        this.spinner.show();
        this.widgetLibraryService.deleteFilePath(this.widgetLibrary.folder, [path]).subscribe(result => {
          this.getFilePaths();
        });
      }
    });
  }

  upsertWidgetLibrary() {
    if (this.pageForm.invalid) {
      this.coreService.showFormFillError();
      return;
    }
    this.widgetLibrary.javaScriptLibraryPaths = this.selectedPaths;
    // this.widgetLibrary.icon = this.widgetLibrary.icon.split(',')[1];
    this.widgetLibraryService.upsertWidgetLibrary(this.widgetLibrary).subscribe(result => {
      this.dialogRef.close(true);
    });
  }

  uploadFile(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    const formData = new FormData();
    formData.append('Files', this.fileData);
    formData.append('FileFolder', this.widgetLibrary.folder);
    if (this.fileData.size > 9145728) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.SmallFile"));
      return;
    }
    this.spinner.show();
    this.widgetLibraryService.uploadJavascriptFile(formData).subscribe(result => {
      this.getFilePaths();
    });
  }

  uploadIconImage(fileInput: any) {
    let file = <File>fileInput.target.files[0];
    if (file.size > 3145728) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.SmallPhoto"));
      return;
    }
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.WrongFormatPhoto"));
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.widgetLibrary.icon = reader.result;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

  }

  onSelection(event: MatSelectionListChange) {
    if (event.options[0].selected) {
      this.selectedPaths.push(event.options[0].value);
      let index = this.filePaths.indexOf(event.options[0].value);
      this.filePaths.splice(index, 1);

    }
    else {
      this.filePaths.push(event.options[0].value);
    }
  

  }

  deleteAllFile() {
    const dialogRef = this.dialog.open(ActionDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        title: 'ActionDialogComponent.Warning',
        Buttons:[
        ],
        text:'ActionDialogComponent.Message'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.widgetLibraryService.deleteAllWidgetLibrary(this.widgetLibrary.folder).subscribe(() => {
          this.spinner.hide()
          this.getFilePaths()
        })
      }
    })
  }

}
