export enum SpeechProcces {
    Delete = 1,
    Show = 2,
    Close = 3,
    AssistantOpen = 4,
    AssistantClose = 5,
    HomePage = 6,
    Refresh = 7,
    Help = 8,
    OpenDashboard = 9,
    FilterDashboard = 10,
    TextToChatBot=11
}

export enum SpeechStatus {
    Hearing='Hearing',
    Stoped='Stoped',
    Processing='Processing',
    Processed='Processed'

}
