import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { Columns } from 'src/app/models/responses/dataStoreResponse';

@Component({
  selector: 'app-ag-grid-table',
  templateUrl: './ag-grid-table.component.html',
  styleUrls: ['./ag-grid-table.component.scss']
})
export class AgGridTableComponent implements OnInit, OnChanges {

  _rowData: any[] = [
    { make: 'Toyota', model: 'Celica', price: 35000 },
    { make: 'Ford', model: 'Mondeo', price: 32000 },
    { make: 'Porsche', model: 'Boxster', price: 72000 }
  ];
  @Input() columnTypes: Columns[] = []
  columnDefs: ColDef[] = [];

  @Input() height:string='100%'

  @Input()
  set rowData(value: any) {
    // Değişkenin değeri değiştiğinde bu işlem çalışır
    this._rowData = value;

    // Değişiklikleri işle
    if (value[0])
      this.columnDefs = Object.keys(value[0]).map((key) => {
        var selectedtype = this.columnTypes.find(x => x.fieldName == key)?.fieldType?.toLowerCase()
        var icon = selectedtype?.includes('string') ? 'Text' : selectedtype?.includes('number') ? 'Numbers' : selectedtype?.includes('fx') ? 'fx' : 'Calendar'
        return {
          headerName: key,
          sortable: true,
          headerComponentParams: {
            template: `
                      <div style="display:flex;align-items:center">
                      <img width="16px" src='/assets/images/icons/${icon}.png'/>
                        <span>
                          ${key}
                        </span>
                      </div>`
          },
          field: key
        };
      });





  }





  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    suppressAutoSize: true,
    minWidth: 200
    // minWidth: 100,
  };


  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes)
  }

  // Example load data from server
  onGridReady(params: GridReadyEvent) {
    params.api.sizeColumnsToFit();
  }


}
