import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BussionConnector } from 'src/app/models/responses/bussionConncetorResponse';
import { DataStore } from 'src/app/models/responses/dataStoreResponse';
import { FlowScriptResponse, FlowSriptParameter } from 'src/app/models/responses/flowScript';
import { Node } from 'src/app/models/responses/nodeResponse';
import { ConnectorFormComponent } from 'src/app/pages/manage/connector/connector-form/connector-form.component';
import { DatastoreFormComponent } from 'src/app/pages/manage/datastore/datastore-form/datastore-form.component';
import { NodeFormComponent } from 'src/app/pages/manage/node/node-form/node-form.component';
import { ConnectorService } from 'src/app/services/connector/connector.service';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NodeService } from 'src/app/services/node/node.service';
import { PermissionService } from 'src/app/services/permission/permission.service';

@Component({
  selector: 'app-flow-node-parameter-form',
  templateUrl: './flow-node-parameter-form.component.html',
  styleUrls: ['./flow-node-parameter-form.component.scss']
})
export class FlowNodeParameterFormComponent implements OnInit {
  showValue: { key: string, show: boolean }[] = [{ key: '', show: false }]
  newParameters: FlowSriptParameter[] = []

  Idkeys = ['ConnectorId', 'NodeId', 'DataStoreId']
  dataStores: DataStore[] = []
  connectors: BussionConnector[] = []
  dataNodes: Node[] = [];

  permissions: string[] = [];

  options: any = { ConnectorId: [], NodeId: [], DataStoreId: [] }


  parameterFormGroup: FormGroup = new FormGroup({})

  constructor(@Inject(MAT_DIALOG_DATA) public data: FlowScriptResponse,
    private dialogRef: MatDialogRef<FlowNodeParameterFormComponent>,
    private dataStoreService: DataStoreService, private connectorService: ConnectorService,
    private nodeService: NodeService,
    private modalService: ModalService,
    private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.newParameters = Object.assign([], this.data.parameters)

    this.newParameters.map(item => {
      item.showForForm = item.shown == 0 ? false : true;
      item.typeForForm = item.type.toLowerCase().includes('string') ? 'text' : item.type.toLowerCase().includes('number') ? 'number' : 'date'
      this.parameterFormGroup.addControl(item.key, new FormControl(item.value, item.mandatory ? [Validators.required] : []))
    })
    if (this.data.parameters.some(x => x.key.includes('ConnectorId'))) {
      this.getConnectors()
    }
    if (this.data.parameters.some(x => x.key.includes('DataStoreId'))) {
      this.getDataStores();
    }
    if (this.data.parameters.some(x => x.key.includes('NodeId'))) {
      this.getNodes()
    }

    this.getUserPermissionList()

  }


  saveParameters() {
    var parameters = this.parameterFormGroup.value
    // this.newParameters = Object.assign([], this.data.parameters)

    // this.newParameters.map(item => {
    //   item.value = parameters[item.key]
    //   delete item["showForForm"];
    //   delete item["typeForForm"];
    // })
    this.dialogRef.close(parameters)
  }

  getConnectors() {
    if (this.connectors.length <= 0) {
      this.connectorService.getConnectorTasks().subscribe(res => {
        this.connectors = res
        this.createOptions(this.connectors, 'connectorId', 'name', 'ConnectorId')

      })
    } else {
      this.createOptions(this.connectors, 'connectorId', 'name', 'ConnectorId')
    }
  }

  getNodes() {
    if (this.dataNodes.length <= 0) {
      this.nodeService.getDataNodes().subscribe(res => {
        this.dataNodes = res
        this.createOptions(this.dataNodes, 'nodeId', 'name', 'NodeId')
      })
    } else {
      this.createOptions(this.dataNodes, 'nodeId', 'name', 'NodeId')

    }
  }

  createOptions(data, valueKey, optionKey, dataKey) {
    this.options[dataKey] = data.map(item => {
      return {
        key: item[optionKey],
        value: item[valueKey],
        data: dataKey
      }
    })
  }

  getDataStores() {
    if (this.dataStores.length <= 0)
      this.dataStoreService.getDataSourceForUser().subscribe(res => {
        this.dataStores = res
        this.createOptions(this.dataStores, 'dataStoreId', 'name', 'DataStoreId')

      })
    else {
      this.createOptions(this.dataStores, 'dataStoreId', 'name', 'DataStoreId')

    }

  }

  closeDialog() {
    this.dialogRef.close(false)

  }


  getUserPermissionList() {
    this.permissionService.getUserRoleList().subscribe(roleList => {
      this.permissions = roleList;
    });
  }


  openModal(modal, value) {
    value = value ? value : this.parameterFormGroup.value[modal]

    if (modal == "ConnectorId") {
      let data = {
        type: 'update',
        task: this.connectors.find(x => x.connectorId == value),
        folderList: Array.from(new Set(this.connectors.map(item => item.folder))).filter(x => x !== '')

      }
      this.modalService.openModal(data, ConnectorFormComponent, '900px', '1000px').subscribe(result => {
        if (result) {
          this.getConnectors()
        }
      });
    } else if (modal == "NodeId") {
      let data = {
        type: 'update',
        node: this.dataNodes.find(x => x.nodeId == value)
      }
      this.modalService.openModal(data, NodeFormComponent, 'auto', '1000px').subscribe(result => {
        if (result) {
          this.getNodes()
        }
      });
    } else {
      let data = {
        type: 'update',
        dataStore: this.dataStores.find(x => x.dataStoreId == value)
      }
      this.modalService.openModal(data, DatastoreFormComponent, 'auto', '1000px').subscribe(result => {
        if (result) {
          this.getDataStores()
        }
      });
    }
  }


}
