import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { App } from "src/app/models/responses/appsResponse";
import { ColorPalette } from "src/app/models/responses/colorPaletteResponse";
import { Dashboard } from "src/app/models/responses/dashboardResponse";
import { Group } from "src/app/models/responses/groupsResponse";
import { AuthService } from "src/app/services/auth/auth.service";
import { ColorPaletteService } from "src/app/services/color-palette/color-palette.service";
import { CoreService } from "src/app/services/core/core.service";
import { DashboardService } from "src/app/services/dashboard/dashboard.service";
import { DeployAppService } from "src/app/services/deploy-app/deploy-app.service";
import { GroupService } from "src/app/services/group/group.service";

@Component({
    selector: 'app-create-app',
    templateUrl: './create-app.component.html',
    styleUrls: ['./create-app.component.scss']
})

export class CreateAppComponent implements OnInit {
    isCreateForm: boolean = true;
    title: string = '';
    appForm: UntypedFormGroup;
    deployForm: UntypedFormGroup;
    selectedApp: App
    appStatus: number = 0
    appAccess: string
    appOpenerMethod: string
    image: any = ''
    selectedTabIndex: number = 0
    groups: Group[] = [];
    appType: string = '0'
    selectedGroups: Group[] = [];
    colorPalettes: ColorPalette[] = []
    paletcolors: string[] = []
    paletColorArea: boolean = false
    selectedColorPalette: ColorPalette
    compareFunctionForGroups = (o1: any, o2: any) => {
        o1 === o2
    };
    selectedDashboards: string[] = []
    compareFunctionForDashboard = (o1: any, o2: any) => o1 === o2;

    dashboards: Dashboard[] = []
    primaryColor: string = ''
    primaryColorControl: FormControl = new FormControl(this.primaryColor)

    appOptions: {
        windowWidth: number,
        windowHeight: number,
        type: string

    } =
        {
            windowWidth: 100,
            windowHeight: 100,
            type: 'centerWindow'
        }

    app: App = {
        name: '',
        appCode: '',
        icon: this.image,
        applicationStatus: 0,
        description: '',
        access: 0,
        applicationId: '',
        domain: '',
        owner: '',
        ownerId: '',
        groups: [],
        openerMethod: 0,
        applicationLink: '',
        dashboards: [],
        appType: 0,
        creationDate: '',
        appCSS: '',
        options: ''
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: { app: App, type: string }, private groupService: GroupService,
        private deployAppService: DeployAppService,
        private translate: TranslateService, private authService: AuthService,
        private spinner: NgxSpinnerService,
        private coreService: CoreService,
        private dialogRef: MatDialogRef<CreateAppComponent>,
        private colorPaletteService: ColorPaletteService,
        private dashboardService: DashboardService) {
    }

    ngOnInit(): void {
        this.title = this.data.type == 'update' ? this.translate.instant("AppFormPage.TitleUpdate") : this.translate.instant("AppFormPage.TitleCreate");
        this.isCreateForm = this.data.type == 'update' ? false : true;


       



        if (this.data.app) {



            this.selectedApp = this.data.app;
            this.app = Object.assign({}, this.data.app);
            this.appStatus = this.app.applicationStatus.toString()
            this.appAccess = this.app.access.toString()
            this.appOpenerMethod = this.app.openerMethod.toString()
            this.selectedGroups = this.app.groups
            this.selectedDashboards = this.app.dashboards
            this.appType = this.app.appType.toString()

            if (this.data.app.appCSS.includes('background-color')) {
                this.primaryColor = this.data.app.appCSS.split('background-color: ')[1]?.slice(0, 7)
                this.primaryColorControl.patchValue(this.primaryColor)
            }

            if (this.app.options == "") {
                this.appOptions = {
                    windowWidth: 0,
                    windowHeight: 0,
                    type: ''
                }
            } else {
                this.appOptions = JSON.parse(this.app.options)
            }
        }

        this.appForm = new UntypedFormGroup({
            // applicationId: new UntypedFormControl(this.app.applicationId, [Validators.required]),
            name: new UntypedFormControl(this.app.name, [Validators.required]),
            appCode: new UntypedFormControl(this.app.appCode, [Validators.required]),
            description: new UntypedFormControl(this.app.description, [Validators.required]),
            applicationLink: new UntypedFormControl(this.app.applicationLink),
            appAccess: new UntypedFormControl(this.app.access, [Validators.required]),
            applicationStatus: new UntypedFormControl(this.app.applicationStatus, [Validators.required]),
            appOpenerMethod: new UntypedFormControl(this.app.openerMethod, [Validators.required])
        });



        this.getGroups()
        this.getDashboards()
        this.getColorPalettes()
    }

    isSelected(group) {
        return this.app.groups.includes(group.groupId)
    }

    handleUpload(event: any) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.image = reader.result
        };
    }

    private getGroups() {
        this.groupService.getGroups().subscribe(result => {
            this.groups = result
        });
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.selectedTabIndex = tabChangeEvent.index
    }

    photoUploaded(photo: string) {
        // console.log(photo)
        // var imageStr = photo.split(',')[1] == undefined ? ' ' : photo.split(',')[1];
        this.app.icon = photo;
    }

    upsertApp() {
        if (!this.appForm.valid) {
            setTimeout(() => {
                this.coreService.showFormFillError();
            }, 100);
            return;
        }
        this.spinner.show()
        // var selectedGroups = this.app.groups.concat(this.selectedGroups)
        this.app.groups = this.selectedGroups;
        this.app.applicationStatus = this.appStatus;
        this.app.access = parseInt(this.appAccess)
        this.app.openerMethod = parseInt(this.appOpenerMethod)
        this.app.appType = parseInt(this.appType)
        this.app.ownerId = localStorage.getItem('serId')
        this.app.owner = localStorage.getItem('name') + localStorage.getItem('lastName')


        this.app.options = JSON.stringify(this.appOptions)


        if (this.primaryColor) {
            this.app.appCSS = `.application-dashboard .top-section {
                  background-color: ${this.primaryColor} !important;
                }`;
        } else {
            this.app.appCSS = ''
        }

        this.deployAppService.upSertApp(this.app).subscribe(response => {

            if (response.result) {
                this.deployAppService.getApps()
                // this.selectedTabIndex = '1'
                this.app.applicationLink = response.message.toString()
                this.spinner.hide()
                this.dialogRef.close(true)
                this.refreshStorage(this.app)
                // this.dashboardService.cacheData()
            }

        })
    }

    refreshStorage(app: App) {
        var cachedData = this.dashboardService.getCachedData();
        if (cachedData) {

            if (cachedData['authorizedApps']) {
                cachedData['authorizedApps'] = cachedData['authorizedApps'].filter(x => x.applicationId !== app.applicationId)
                cachedData['authorizedApps'].push(app)
                this.dashboardService.setCacheData(cachedData)
            }
        }
    }


    formData = new FormData()
    fileData: File;

    changeFile(fileInput: any) {
        this.fileData = <File>fileInput.target.files[0];
    }


    uploadFile() {
        this.spinner.show()
        this.formData.append('File', this.fileData)
        this.formData.append('Token', this.authService.getToken())
        this.formData.append('ApplicationLink', this.app.applicationLink)
        this.deployAppService.deployApp(this.formData).subscribe(response => {
            this.spinner.hide()
            this.coreService.showSuccessMessage(response);

        })
    }

    deleteEmptyDashboard() {
        this.app.dashboards.map(dash => {
            if (!this.dashboards.some(x => x.dashboardId == dash))
                this.app.dashboards = this.app.dashboards.filter(x => x !== dash)
        })
    }


    private getDashboards() {
        if (this.dashboardService.cachedData.getValue().authorizedDashboard.length > 0) {
            this.dashboards = this.dashboardService.cachedData.getValue().authorizedDashboard
            this.deleteEmptyDashboard()
        }
        else
            this.dashboardService.getAuthorizedDashboards().subscribe(result => {
                this.dashboards = result
                this.deleteEmptyDashboard()

            });
    }


    changeselectedDahboardlist(list: any[]) {

        this.app.dashboards = list

    }

    changeselectedGrouplist(list: any[]) {
        console.log(list)
        this.selectedGroups = list
    }

    changeColor(event: any) {
        this.primaryColor = event.target.value
        // this.primaryColor = event.color.hex
    }

    getColorPalettes() {
        this.colorPaletteService.getColorPalettes().subscribe(res => {
            this.colorPalettes = res
            this.paletcolors = []
            this.colorPalettes.map(item => {
                return item.palettes.map(c => {
                    return this.paletcolors.push(c)
                })
            })
        })
    }

    selectColor(color) {
        this.primaryColor = color

    }

    changeWindowOptions(optionType: string, event) {

        this.appOptions[optionType] = event

    }

    resetOptions() {
        this.appOptions = {
            windowWidth: 100,
            windowHeight: 100,
            type: 'centerWindow'
        }
        this.app.options = ""
    }


}