<div class="action-card-container" >
    <div class="top-area">

        <div (click)="cardClick(item)">
            <div class="avatar">{{ item[keys.avatarKey].slice(0, 2).toUpperCase() }}</div>
        </div>
        <div class="title-area" >
            <div class="title-flex" >
                <span (click)="cardClick(item)">{{item[keys.titleKey]}}</span>
                <div>
                    <mat-icon *ngFor="let iconItem of icons" [svgIcon]="iconItem.icon"
                        (click)="iconItem.function(item)"></mat-icon>
                </div>
            </div>
            <div class="small-text" >
                <small (click)="cardClick(item)">{{item[keys.smallKey]}}</small>
                <small>{{keys?.smallKey2}}:{{item[keys?.smallKey2]}}</small>

            </div>

        </div>
        <!-- <div>
            <mat-icon *ngFor="let iconItem of icons" [svgIcon]="iconItem.icon"
            (click)="iconItem.function(item)"></mat-icon>
        </div> -->
    </div>
    <div class="desc-text">
        {{item[keys.descKey]}}
    </div>
    <div style="display: flex;align-items: center;justify-content: space-between;">
        <app-avatar-group [fontsize]="'xx-small'" [width]="'25px'" [top]="0" [list]="[item]" [avatarKeys]="imageAvatarKeys"></app-avatar-group>
        <div class="badge">
            {{item[keys.badgeKey]?.length}} {{keys.badgeKey}}
        </div>
    </div>
</div>