<app-modal-title title="Dashboard Form"></app-modal-title>
<mat-dialog-content style="padding: 1rem;" class="mat-typography">

    <div class="icon-avatar">
        <div class="img">
            <img src="{{dashboard.icon}}" onError="this.src ='../../../assets/images/Dashboard.png'"
                (click)="photoUpload.click()" />
        </div>
        <input hidden type="file" #photoUpload (change)="uploadIconImage($event)" />
    </div>

    <form [formGroup]="pageForm">
        <mat-form-field>
            <mat-label>{{ 'DashboardFormPage.Title' | translate }}</mat-label>
            <input matInput [(ngModel)]="dashboard.title" formControlName="title" required>
        </mat-form-field>
        <br>
        <mat-form-field>
            <mat-label>{{ 'DashboardFormPage.Description' | translate }}</mat-label>
            <textarea matInput [(ngModel)]="dashboard.description" formControlName="description" ></textarea>
        </mat-form-field>
        <br>
        <mat-form-field>
            <mat-label>{{ 'DashboardFormPage.ContentLanguage' | translate }}</mat-label>
            <mat-select [(ngModel)]="dashboard.contentLanguage" [compareWith]="compareFunctionForLanguage" formControlName="contentLanguage">
                <mat-option value="en">{{ 'DashboardFormPage.en' | translate }}</mat-option>
                <mat-option value="tr">{{ 'DashboardFormPage.tr' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
        <br>
    </form> 

</mat-dialog-content>
<mat-dialog-actions align="end">
    <!-- [mat-dialog-close]="true" -->
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial (click)="saveDashboard()" >{{
        'Common.Save' | translate }}</button>
</mat-dialog-actions>