<!-- self-service-datastore-form.component.html -->

<app-modal-title [title]="(this.data.onlyPreview ?'DataStoreFormPage.DataPreview':'DataStorePage.AddNewData')|translate"></app-modal-title>
<mat-dialog-content style="overflow: hidden !important;">
  <div *ngIf="this.data.onlyPreview" style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
    <ag-grid-angular  style=" height: 100%;width: 100%; " class="ag-theme-alpine"
      [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData" [rowSelection]="'multiple'"
      [animateRows]="true" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)"></ag-grid-angular>
  </div>
  <mat-horizontal-stepper *ngIf="!this.data.onlyPreview" class="ds-form" #stepper [linear]="true">
    <!-- İlk Adım -->
    <mat-step [stepControl]="dataStoreForm">
      <ng-template matStepLabel>{{ 'DataStoreFormPage.About' | translate }}</ng-template>
      <form [formGroup]="dataStoreForm">


        <mat-form-field>
          <mat-label>{{ 'DataStoreFormPage.Name' | translate }}</mat-label>
          <input matInput type="text" formControlName="FileName" required>
        </mat-form-field>

        <mat-form-field>
          <br>
          <mat-label>{{ 'DataStoreFormPage.Append' | translate }}</mat-label>
          <mat-select formControlName="append" required>
            <mat-option value="0">{{ 'DataStoreFormPage.AddNewData' | translate }}</mat-option>
            <mat-option value="1">{{ 'DataStoreFormPage.Overwrite' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        <br>
        <mat-form-field *ngIf="this.data.type=='update'">
          <mat-label>{{ 'DataStoreFormPage.DsApiKey' | translate }}</mat-label>
          <input matInput type="text" formControlName="ApiKey" readonly="true" disabled="true">
        </mat-form-field>
      </form>
    </mat-step>

    <!-- İkinci Adım -->
    <mat-step [stepControl]="dataStoreForm">
      <ng-template matStepLabel>{{ 'DataStoreFormPage.ChooseFileType' | translate }}</ng-template>
      <!-- <app-self-service-datastore-file></app-self-service-datastore-file> -->
      <div style="width: 100%; min-height: 200px;margin-top: -35px;">
        <div>
          <h3 style="margin-top:2%">{{ 'DataStoreFormPage.ChooseFileTypeParagraph' | translate }}</h3>
          <span *ngIf="fileName">{{ 'DataStoreFormPage.YourFileName' | translate }}:{{fileName}}</span>
        </div>

        <div class="input_">
          <input style="visibility:hidden" type="file" [accept]="fileAccept" (change)="onFileChange($event)" #fileInput>
          <ul class="card-list" style="overflow-y: scroll !important;">
            <li *ngFor="let item of acceptFileTypes" class="card" (click)="openFileSelect(item.type,item.index)"
              [ngClass]="{'selected': selectedCardIndex === item.index}">
              <img style="width: 64%;" [src]="item.img" alt="let's go" />
              <h2>{{item.title}}</h2>
            </li>
          </ul>
        </div>

      </div>
    </mat-step>


    <!-- 3.adım -->

    <mat-step [stepControl]="dataStoreForm">
      <ng-template matStepLabel (click)="preventStepChange($event)">{{ 'DataStoreFormPage.DataPreview' | translate
        }}</ng-template>
      <div style="overflow-x: scroll !important;">
        <ag-grid-angular style=" height: 500px; " class="ag-theme-alpine" [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef" [rowData]="rowData" [rowSelection]="'multiple'" [animateRows]="true"
          (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)"></ag-grid-angular>
      </div>

    </mat-step>

  </mat-horizontal-stepper>
</mat-dialog-content>


<div class="button_group" style="margin-right: 5%;" *ngIf="!this.data.onlyPreview">
  <button class="b-btn" mat-dialog-close>{{ 'DataStoreFormPage.Cancel' | translate }}</button>
  <button class="b-btn b-success" (click)="backStep()" *ngIf="stepper?.selectedIndex !== 0">{{ 'DataStoreFormPage.Back' |
    translate }}</button>
  <button class="b-btn b-success" (click)="nextStep()" *ngIf="stepper?.selectedIndex !== stepper?.steps?.length - 1">{{
    'DataStoreFormPage.Next' | translate }}</button>
  <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="stepper?.selectedIndex === stepper?.steps?.length - 1"
    (click)="saveDataStoreFile()">{{ 'Common.Save' |
    translate }}</button>
</div>

<div class="loading-overlay" *ngIf="showLoading">
  <div class="loading-spinner"></div>
</div>