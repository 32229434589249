import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShareService } from 'src/app/services/share/share.service';
import { Share } from 'src/app/models/responses/shareResponse';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { GlobalFunctionService } from 'src/app/services/global-function/global-function.service';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { GridsterConfig, GridsterItem, GridType, CompactType, DisplayGrid } from 'angular-gridster2';
import { interval, Subscription } from 'rxjs';
import { WidgetService } from 'src/app/services/widget/widget.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ExpandedWidgetComponent } from 'src/app/components/modals/expanded-widget/expanded-widget.component';
import { ShareFormComponent } from 'src/app/components/modals/share-form/share-form.component';

@Component({
  selector: 'app-dashboard-opener',
  templateUrl: './dashboard-opener.component.html',
  styleUrls: ['./dashboard-opener.component.scss']
})
export class DashboardOpenerComponent implements OnInit, OnDestroy {
  isShownDashboardTitle: boolean = true
  isShownFilter: boolean = false
  dashboardId: string = "";
  widgets: any[] = [];
  dashboard: Dashboard;
  sharedWidget: any;
  dropdownData: any
  myShares: Share[] = [];
  domain: string = null;
  enableTitle: boolean = false;
  enableFilter: boolean = true;
  filters: any[] = [];
  selectedDashboardInitialFilters: BussionFilterDefinition[] = [];
  shareId: string = null;
  shareEncryptKey: string = ''
  options: GridsterConfig;
  dashboardWidgets: Array<GridsterItem> = [];
  isDeviceMobile: boolean = false;
  selectedDashboard: any;
  subscriptionCheckDashboardInterval: Subscription;
  isFirstForCheckDashboardData: boolean = true;
  checkDashboardInterval = interval(30000);
  dashboardChangesId: string = "";
  right = ((100 - parseInt('100')) / 2)
  constructor(
    private activatedRoute: ActivatedRoute,
    private shareService: ShareService,
    private dashboardService: DashboardService,
    private globalFunctionService: GlobalFunctionService,
    private scriptLoader: ScriptLoaderService,
    private widgetService: WidgetService,
    private modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data: {
      url: string,
      filter: any,
      param: string,
      drag: boolean
    },
  ) {

  }
  ngOnInit(): void {
    this.customizeDashboard();
    if (!this.data.drag) {
      this.options.draggable.enabled = false;
      this.options.resizable.enabled = false
      this.options.api?.optionsChanged();
    }
    if (this.activatedRoute.snapshot.paramMap.get('dashboardId'))
      this.dashboardId = this.activatedRoute.snapshot.paramMap.get('dashboardId');
    else
      this.dashboardId = this.data.param

    var urlfilter = ''

    if (this.activatedRoute.snapshot.queryParamMap.get('filter'))
      urlfilter = this.activatedRoute.snapshot.queryParamMap.get('filter')
    else
      this.filters = this.data.filter

    if (urlfilter)
      this.filters = JSON.parse(urlfilter);


    if (this.dashboardId)
      this.getDashboardById(this.dashboardId);

    this.selectedDashboardInitialFilters = this.filters


  }

  

  ngOnDestroy(): void {
    // Component yok edildiğinde aboneliği sonlandır
    if (this.subscriptionCheckDashboardInterval) {
      this.subscriptionCheckDashboardInterval.unsubscribe();
    }
  }


  openDashboardFilter() {
    this.isShownFilter = true;
  }

  getDashboardById(dashboardId: string) {
    this.dashboardService.getDashboardById(dashboardId).subscribe((response) => {

      this.selectedDashboard = response;
      this.dashboard = response
      this.getGlobalFunctions();
    })

  }

  createObjectList(filter: any) {
    if (filter && Object.keys(filter).length > 0) {
      let filterobj = ''
      let filterkey = []
      if (filter.includes('equal')) {
        filterobj = JSON.parse(filter.replace('equal', '='))
        filterkey = Object.keys(JSON.parse(filter.replace('equal', '=')))
      }
      let list = []
      filterkey.map((item) => {
        list.push(filterobj[item])
      })

      this.filters = list
    }
    return this.filters
  }

  getGlobalFunctions() {
    this.globalFunctionService.getGlobalFunctions(this.domain).subscribe(result => {
      result.forEach(f => {
        this.scriptLoader.runGlobalFunction(f.code);
      });
      this.getDashboardWidgets();
    });
  }


  getDashboardWidgets() {
    // this.options.minCols = this.selectedDashboard?.gridSize
    // console.log('dashboardbyId', this.dashboardWidgets)

    // this.options.minRows = this.selectedDashboard?.gridSize

    this.options.gridType = GridType[this.selectedDashboard.layout]
    this.options?.api?.optionsChanged()
    this.dashboardWidgets = []
    this.dashboardService.getWidgetsForDashboard(this.dashboardId, this.filters).subscribe(result => {
      this.widgets = result
      this.subscribeDashboardChanges(true);

      result.forEach(widget => {

        this.dashboardWidgets.push({

          cols: this.isDeviceMobile ? 1 : widget.widget.layout.cols,
          rows: this.isDeviceMobile ? 1 : widget.widget.layout.rows,
          y: widget.widget.layout.y,
          x: widget.widget.layout.x,
          designerContainer: widget
        });
        // this.createWidget(widget);
      })


    });

  }

  expandWidget(widgetData: any) {
    let data = {
      widgetData: widgetData.designerContainer,
      shareModalOpen: (e) => this.openShareForm({ ...e, filter: this.selectedDashboard.filter }),
      selectedFilters: this.selectedDashboard.filter,
      selectedDashboard: this.selectedDashboard
    }
    this.modalService.openModal(data, ExpandedWidgetComponent, '90%', '100%', 'overflow-hidden').subscribe(result => { });
  }

  openShareForm(shareData: any) {
    let data = {
      objectType: shareData.objectType,
      objectId: shareData.objectId,
      dashboardId: this.selectedDashboard.dashboardId,
      filter: this.selectedDashboard.filter,
      function: () => this.getMyShares()
    };

    this.modalService.openModal(data, ShareFormComponent, 'auto', '600px').subscribe(result => {
      if (result) {
      }
    })
  }

  getMyShares() {
    this.shareService.getMyShare(this.dropdownData.objectId).subscribe(myShares => {
      this.myShares = myShares;
    })
  }



  subscribeDashboardChanges(isFirstForCheckDashboardData) {
    if (this.subscriptionCheckDashboardInterval) {
      this.subscriptionCheckDashboardInterval.unsubscribe();
    }
    this.isFirstForCheckDashboardData = isFirstForCheckDashboardData;
    this.checkDashboardInterval = interval(this.selectedDashboard?.refreshInterval * 1000);
    this.subscriptionCheckDashboardInterval = this.checkDashboardInterval.subscribe(() => {
      this.checkDashboardChanges();
    });
  }

  private createWidget(widgetResponse: any) {
    this.scriptLoader.addScriptFileToHead(widgetResponse.widgetLibraries);
    this.scriptLoader.runWidgetCode(widgetResponse);
  }

  checkDashboardChanges() {
    this.dashboardService.checkDashboardUpdateTime(this.selectedDashboard.dashboardId).subscribe(res => {
      if (this.isFirstForCheckDashboardData) {
        this.isFirstForCheckDashboardData = false;
        this.dashboardChangesId = res.message;
        return;
      }
      if (this.dashboardChangesId != res.message) {
        // this.dashboardWidgets = [];
        //eğer herhangi bir widgetta değişiklik varsa update time değişiklik olan widgetları dönecek
        if (res?.changedWidgets?.length > 0)
          this.refreshWidget(res.changedWidgets)
        // this.getDashboardsWidgets(this.selectedDashboard.dashboardId);
        this.dashboardChangesId = res.message;
      }
    });
  }

  refreshWidget(changedWidgets) {
    var idlist = []
    // değişen widgetların idsini liste olarak atıroruz servise
    changedWidgets.map(item => {
      idlist.push(item.widgetId)
    })

    this.widgetService.getWidgetById(idlist, this.selectedDashboard.dashboardId, this.selectedDashboard.filter).
      subscribe(res => {
        res.map((item) => {

          var index = this.dashboardWidgets.findIndex(x => x.designerContainer.widgetId == item.widgetId)
          if (index !== undefined) {
            this.dashboardWidgets[index].designerContainer = item
            // this.createWidget(item);
            this.scriptLoader.runWidgetCode(item, true);


          }
        })
      })

  }



  getSharedWidget(sharedObject: Share) {
    this.shareService.getSharedWidget(sharedObject.shareId, sharedObject.domain).subscribe(widget => {
      this.sharedWidget = widget;
    })
  }

  closeFilterForm(event: any) {
    this.isShownFilter = false;
  }

  saveFilter(newFilters: BussionFilterDefinition[]) {
    this.closeFilterForm(null);
    this.filters = newFilters
    this.getDashboardWidgets();
    // console.log("Filters Enis: ", newFilters);
    // this.dashboard.filter = newFilters;
    // this.dashboardWidgets = [];
    // if (this.shareId) {
    //   this.shareService.getSharedDashboardWidgets(this.shareId, this.getUser().language, this.domain, newFilters, this.shareEncryptKey).subscribe(result => {
    //     result.forEach(widget => {
    //       this.dashboardWidgets.push({ cols: widget.widget.layout.cols, rows: widget.widget.layout.rows, y: widget.widget.layout.y, x: widget.widget.layout.x, designerContainer: widget });
    //       this.createWidget(widget);
    //     })
    //   })
    // } else
    //   this.dashboardService.getWidgetsForDashboard(this.dashboard.dashboardId, newFilters).subscribe(result => {
    //     result.forEach(widget => {
    //       this.dashboardWidgets.push({ cols: widget.widget.layout.cols, rows: widget.widget.layout.rows, y: widget.widget.layout.y, x: widget.widget.layout.x, designerContainer: widget });
    //       this.createWidget(widget);
    //     })
    //   })
  }

  getWidgets(shareObject: Share) {
    this.shareService.getSharedDashboardWidgets(shareObject.shareId, shareObject.lang, shareObject.domain, this.filters, shareObject.shareEncryptKey, shareObject.authentication).subscribe(result => {
      this.widgets = result;
    })
  }

  private customizeDashboard() {
    this.options = {
      gridType: GridType.ScrollVertical,
      compactType: CompactType.CompactUp,
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 640,
      minCols: 5,
      maxCols: 200,
      minRows: 5,
      maxRows: 200,
      maxItemCols: 200,
      minItemCols: 1,
      maxItemRows: 200,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      // fixedColWidth: 200,
      // fixedRowHeight: 200,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true,
      },
      resizable: {
        enabled: true,
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: true,
      displayGrid: DisplayGrid.None,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: true,

    };
  }

}