<app-modal-title [title]="title"></app-modal-title>
<mat-dialog-content class="mat-typography">

    <mat-tab-group>
        <mat-tab [label]="'DashboardFormPage.About' | translate ">
            <div class="icon-avatar">
                <div class="img">
                    <img src="{{dashboard.icon}}" onError="this.src ='../../../assets/images/Dashboard.png'"
                        (click)="photoUpload.click()" />
                </div>
                <input hidden type="file" #photoUpload (change)="uploadIconImage($event)" />
            </div>
            <button mat-stroked-button color="primary"
                (click)="jsonfileinput.click()">{{'DashboardFormPage.CompleteJson'|translate}}</button>
            <input accept="application/JSON" type="file" #jsonfileinput class="jsonfileinput"
                (change)="completeJson($event)" />
            <form [formGroup]="pageForm">
                <mat-form-field>
                    <mat-label>{{ 'DashboardFormPage.Title' | translate }}</mat-label>
                    <input matInput [(ngModel)]="dashboard.title" formControlName="title" required>
                </mat-form-field>
                <br>

                <mat-form-field>
                    <mat-label>{{ 'DashboardFormPage.Description' | translate }}</mat-label>
                    <textarea matInput [(ngModel)]="dashboard.description" formControlName="description" ></textarea>
                </mat-form-field>
                <br>

                <!-- <mat-form-field>
                    
                    <mat-select [(ngModel)]="dashboard.contentLanguage" [compareWith]="compareFunctionForLanguage"
                        formControlName="contentLanguage">
                        <mat-option value="en">{{ 'DashboardFormPage.en' | translate }}</mat-option>
                        <mat-option value="tr">{{ 'DashboardFormPage.tr' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <mat-form-field>
                    <mat-label>{{ 'DashboardFormPage.ContentLanguage' | translate }}</mat-label>
                    <mat-select [(ngModel)]="dashboard.contentLanguage" [compareWith]="compareFunctionForLanguage"
                    formControlName="contentLanguage">
                        <mat-option [value]="item.name" *ngFor="let item of languages">{{item.name}}</mat-option>
                        <mat-option *ngIf="languages.length<=0" value="English">English</mat-option>
                        <mat-option *ngIf="languages.length<=0" value="Turkish">Türkçe</mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
            </form>
            <mat-form-field>
                <mat-label>{{ 'DashboardFormPage.isCustomCss' | translate }}</mat-label>
                <mat-select [(ngModel)]="customcss">
                    <mat-option value="1">Yes</mat-option>
                    <mat-option value="0">No</mat-option>
                </mat-select>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'DashboardFormPage.isMainDashboard' | translate }}</mat-label>
                <mat-select [(ngModel)]="dashboard.isMainDashboard" [compareWith]="compareFunctionForMainDashboard">
                    <mat-option value="1">Yes</mat-option>
                    <mat-option value="0">No</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'DashboardFormPage.Access' | translate }}</mat-label>
                <mat-select [(ngModel)]="dashboard.access" [compareWith]="compareFunctionForAccessStatus">
                    <mat-option value="1">Private</mat-option>
                    <mat-option value="2">Public</mat-option>
                    <mat-option value="3">Open</mat-option>
                </mat-select>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'DashboardFormPage.Status' | translate }}</mat-label>
                <mat-select [(ngModel)]="dashboard.dashboardStatus" [compareWith]="compareFunctionForDashStatus">
                    <mat-option value="1">Active</mat-option>
                    <mat-option value="0">Passive</mat-option>
                </mat-select>
            </mat-form-field>
            <br>



            <!-- <mat-form-field>
                <mat-label>{{ 'DashboardFormPage.ColorPalette' | translate }}</mat-label>
                <mat-select [(ngModel)]="dashboard.colorPalette">
                    <mat-option *ngFor="let color of colorPalettes" [value]="color.colorPaletteId">
                        {{color.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br> -->

            <mat-form-field>
                <mat-label>{{ 'DashboardFormPage.HideBorders' | translate }}</mat-label>
                <mat-select [(ngModel)]="dashboard.hideBorders">
                    <mat-option value="false">{{ 'Common.Visible' | translate }}</mat-option>
                    <mat-option value="true">{{ 'Common.Hide' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'DashboardFormPage.GridSize' | translate}}</mat-label>
                <input [(ngModel)]="dashboard.gridSize" matInput type="number" placeholder="0" min="12" max="100">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'DashboardFormPage.Audit' | translate }}</mat-label>
                <mat-select [(ngModel)]="dashboard.auditEnabled">
                    <mat-option [value]="1">{{ 'Common.Yes' | translate }}</mat-option>
                    <mat-option [value]="0">{{ 'Common.No' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'DashboardFormPage.AutoRefresh' | translate }}</mat-label>
                <mat-select [(ngModel)]="dashboard.autoRefresh">
                    <mat-option [value]="1">{{ 'Common.Yes' | translate }}</mat-option>
                    <mat-option [value]="0">{{ 'Common.No' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <br>

            <form [formGroup]="pageForm">
                <mat-form-field>
                    <mat-label>{{ 'DashboardFormPage.RefreshInterval' | translate }}</mat-label>
                    <input matInput [(ngModel)]="dashboard.refreshInterval" formControlName="refreshInterval" required>
                    <mat-error *ngIf="this.pageForm.get('refreshInterval').hasError('min')">
                        {{ 'DashboardFormPage.IntervalErrorMessage' | translate }}
                    </mat-error>
                </mat-form-field>
                <br>
            </form>

            <mat-form-field appearance="fill">
                <mat-label>{{ 'DashboardFormPage.Tags' | translate }}</mat-label>
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let item of tags" (removed)="remove(item)">
                        {{item}}
                        <button matChipRemove style="background-color: transparent;border: none;">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <input matInput [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
            </mat-form-field>
            <br>


            <mat-form-field *ngIf="!isCreateForm">
                <mat-label>{{ 'DashboardFormPage.Owner' | translate }}</mat-label>
                <input matInput [(ngModel)]="dashboard.owner" disabled>
            </mat-form-field>
            <br>

            <mat-form-field *ngIf="!isCreateForm">
                <mat-label>{{ 'DashboardFormPage.CreateDate' | translate }}</mat-label>
                <input matInput [(ngModel)]="dashboard.creationDate" disabled>
            </mat-form-field>
            <br>

            <mat-form-field *ngIf="!isCreateForm">
                <mat-label>{{ 'DashboardFormPage.UpdatedBy' | translate }}</mat-label>
                <input matInput [(ngModel)]="dashboard.updatedBy" disabled>
            </mat-form-field>
            <br>

            <mat-form-field *ngIf="!isCreateForm">
                <mat-label>{{ 'DashboardFormPage.UpdateTime' | translate }}</mat-label>
                <input matInput value="{{dashboard.updateDate}}" disabled>
            </mat-form-field>
            <br>

            <app-title title="{{ 'DashboardFormPage.FilterForDashboard' | translate }}"
                buttonTitle="+ {{ 'Common.AddNewFilter' | translate }}"
                (btnEvent)="isShownQueryForm = !isShownQueryForm">
            </app-title>


            <app-filter-form *ngIf="isShownQueryForm" [fields]="dashboardFilterFields" (add)="addFilter($event)">
            </app-filter-form>

            <div class="filter-list-content" *ngIf="dashboard.filter.length > 0">
                <app-filter-list [data]="dashboard.filter" (delete)="deleteQuery($event)"></app-filter-list>
            </div>
            <br>

        </mat-tab>
        <mat-tab *ngIf="customcss=='1'" label="{{ 'DashboardFormPage.Code' | translate }}">
            <ng-template matTabContent>
                <div class="code-button-group">
                    <button mat-button (click)="openBiggerEditor()">{{ 'Common.ShowBiggerEditor' | translate }}</button>
                </div>
                <ngx-monaco-editor style="height: 500px" [options]="eeditorOptions" [(ngModel)]="dashboard.css"
                    (onInit)="editorInit($event)">
                </ngx-monaco-editor>


            </ng-template>
        </mat-tab>
    </mat-tab-group>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <!-- [mat-dialog-close]="true" -->
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="!isCreateForm" (click)="upsertDashboard(true)">{{
        'Common.SaveAs' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="!isCreateForm" (click)="upsertDashboard(false)">{{
        'Common.Update' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="isCreateForm" (click)="upsertDashboard(false)">{{
        'Common.Create' | translate }}</button>
</mat-dialog-actions>