import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';

@Component({
  selector: 'app-console-screen',
  templateUrl: './console-screen.component.html',
  styleUrls: ['./console-screen.component.scss']
})
export class ConsoleScreenComponent implements OnInit {
  widgetPreviewData:any
  @Input() widget:any

  constructor(private dashboardService:DashboardService,private scriptLoader:ScriptLoaderService) { }

  ngOnInit(): void {
    this.compileCode()
  }

  debugCodeResult: any = ''
  debugDisplay: boolean = false

  async compileCode() {
    this.debugCodeResult=''
    this.debugDisplay = true
    this.dashboardService.widgetPreview(this.widget).subscribe(result => {
      this.widgetPreviewData = result;
      try {
        this.scriptLoader.runwidgetCodeForCompiler(this.widgetPreviewData)

      } catch (error) {
        this.debugCodeResult = error
        console.log('error', error)
        this.DebugError(this.widget.code,error)
      }
    });

  }

  GetErrorPosition(error) {

    var line = error.stack.match(new RegExp("<anonymous>:" + "(.*)" + "\n"));
    if (line[0] != undefined) {
      line[0] = line[0].toString().replace("<anonymous>:", "").replace("\n", "");
    }
    var rst = [];
    rst = line[0].split(":");
    return rst;

  }

  DebugError(code, error) {
    debugger;
    var startIdex = 0;
    var endIndex = 0;
    var rst = this.GetErrorPosition(error);
    var lines = code.split("\n");
    if (rst[0] >= 4)
      startIdex = rst[0] - 4;

    rst[0] = startIdex + 2;
    endIndex = startIdex + 2;
    lines = lines.slice(startIdex, endIndex);

    var ErrorMessage = "";
    ErrorMessage += error.message;
    ErrorMessage += "Details";
    ErrorMessage += "Line : " + rst[0] + " Position : " + rst[1];
    ErrorMessage += `[${lines.join("\n ")}]`;
    
    this.debugCodeResult=ErrorMessage
  }
}
