import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionDialogComponent } from 'src/app/components/action-dialog/action-dialog/action-dialog.component';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { DashboardFormComponent } from './dashboard-form/dashboard-form.component';
import { environment } from 'src/environments/environment';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  baseUrl = environment.imageUrl;
  displayedColumns: string[] = ['icon', 'title', 'dashboardId', 'description', 'action'];
  displayedColumnsName: string[] = [this.translate.instant("Common.Icon"), this.translate.instant("DashboardPage.DashTitle"), this.translate.instant("DashboardPage.DashboardId"), this.translate.instant("DashboardPage.Description"), this.translate.instant("DashboardPage.Action")];
  dashboards: Dashboard[] = [];

  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService,
    private modalService: ModalService,
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getDashboards();
  }

  getDashboards() {
    this.dashboardService.getAllDashboards().subscribe(result => {
      this.dashboards = result;
    });
  }

  deleteDashboard(dashboard: Dashboard) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.dashboardService.deleteDashboard(dashboard).subscribe(result => {
          this.spinner.hide();
          this.getDashboards();
        })
      }
    });
  }

  openDashboard(event: any) {
    let data = {
      type: event.type as string,
      dashboard: event.data as Dashboard
    }
    this.modalService.openModal(data, DashboardFormComponent, 'auto', '1000px').subscribe(result => {
      if (result === true) {
        this.getDashboards();
      }
    });
  }

  open(event: any) {
    this.router.navigate(['landing/dashboard/designer', event.dashboardId]);
  }

  exportDashboard(dashboard: Dashboard) {
    this.downloadJson(dashboard)
  }

  downloadJson(dashboard: Dashboard) {
    var sJson = JSON.stringify(dashboard);
    var element = document.createElement('a');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', dashboard.title + ".json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

}
