import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupService } from 'src/app/services/group/group.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Group } from 'src/app/models/responses/groupsResponse';
import { GroupFormComponent } from './group-form/group-form.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

  displayedColumns: string[] = ['groupName','groupId', 'description', 'createdBy', 'action'];
  displayedColumnsName: string[] = [this.translate.instant("GroupsPage.GroupName"),this.translate.instant("GroupsPage.GroupId"), this.translate.instant("GroupsPage.Description"), this.translate.instant("GroupsPage.CreatedBy"), this.translate.instant("GroupsPage.Action")];
  groups: Group[] = [];

  constructor(
    private groupService: GroupService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getGroups();
  }

  getGroups() {
    this.spinner.show();
    this.groupService.getGroups().subscribe(groups => {
      this.groups = groups;
      this.spinner.hide();
    });
  }

  openGroup(event: any) {
    let data = {
      type: event.type as string,
      group: event.data as Group
    }

    this.modalService.openModal(data, GroupFormComponent, 'auto', '1000px').subscribe(closeData => {
      if (closeData === true) {
        this.getGroups();
      }
    })
  }

  deleteGroup(group: Group) {
    this.modalService.openDeleteConfirm().subscribe(selection => {
      if (selection === true) {
        this.spinner.show();
        this.groupService.deleteGroup(group.groupId).subscribe(result => {
          this.getGroups();
        });
      }
    })
  }

}
