import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { ActionDialogComponent } from 'src/app/components/action-dialog/action-dialog/action-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private dialog: MatDialog
  ) { }

  openModal(data: any, component: ComponentType<any>, height: string, width: string, customclassName?: string,position?:any) {

    const dialogRef = this.dialog.open(component, {
      height: height,
      width: width,
      data: data,
      panelClass:customclassName,
      position
    });

    return dialogRef.afterClosed();

  }

  openModalWithRef(data: any, component: ComponentType<any>, height: string, width: string, customclassName?: string,position?:any) {

    const dialogRef = this.dialog.open(component, {
      height: height,
      width: width,
      data: data,
      panelClass:customclassName,
      position
    });

    return dialogRef

  }

  openModalBeforeClosed(data: any, component: ComponentType<any>, height: string, width: string, customclassName?: string,position?:any) {

    const dialogRef = this.dialog.open(component, {
      height: height,
      width: width,
      data: data,
      panelClass:customclassName,
      position
    });

    return dialogRef.beforeClosed();

  }

  openConfirm() {
    const dialogRef = this.dialog.open(ActionDialogComponent, {
      data: {
        title: 'ActionDialogComponent.Warning',
        Buttons:[
          'ActionDialogComponent.Ok'
        ],
        text:'ActionDialogComponent.statementIdDuplicate'
      },
      height: '200px',
      width: '400px',
    });

    return dialogRef.afterClosed();
  }

  openDeleteConfirm() {
    const dialogRef = this.dialog.open(ActionDialogComponent, {
      height: '200px',
      width: '400px',
    });

    return dialogRef.afterClosed();
  }

  dissmissAll() {
    this.dialog.closeAll()
  }


}
