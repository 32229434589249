import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { Share } from 'src/app/models/responses/shareResponse';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShareService } from 'src/app/services/share/share.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { CoreService } from 'src/app/services/core/core.service';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/responses/loginResponse';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-share-form',
  templateUrl: './share-form.component.html',
  styleUrls: ['./share-form.component.scss']
})
export class ShareFormComponent implements OnInit {
  share: Share = {
    name: "",
    sharedObjectId: "",
    objectType: "",
    shareState: "",
    shareKey: "",
    sharedBy: "",
    sharedUserId: "",
    domain: "",
    sharedDate: new Date(),
    sharedData: "",
    dashboardId: "",
    lang: "English",
    enableFilter: 1,
    enableTitle: 0,
    filter: [],
    shareType: 0,
    lockedFilter: 0,
    shareEncryptKey: '',
    authenticatedUsers: '',
    authentication: 0
  }
  authenticatedUsers: string[] = []
  users: User[] = []
  filteredUsers: User[] = []

  compareFunction = (o1: any, o2: any) => o1 === o2.toString();
  compareFunction2 = (o1: any, o2: any) => o1 === o2.toString();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShareFormComponent>,
    private shareService: ShareService,
    private modalService: ModalService,
    private coreService: CoreService,
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.share.objectType = this.data.objectType;
      this.share.sharedObjectId = this.data.objectId;
      this.share.dashboardId = this.data.dashboardId;
      this.share.filter = this.data.filter;
    }
    this.authenticatedUsers.push(this.authService.getUserId())

    if (this.data.data) {
      Object.assign(this.share, this.data.data)
      this.authenticatedUsers = this.share.authenticatedUsers.split(',')
    }

    this.getUsers()
  }

  getUsers() {
    if (this.userService.users.getValue().length <= 0) {
      this.userService.getUsers().subscribe(res => {
        this.users = res
        this.filteredUsers = this.users
      })
    } else {
      this.users = this.userService.users.getValue()
      this.filteredUsers = this.userService.users.getValue()

    }
  }

  createShare() {
    if (this.share.name.length == 0) {
      this.coreService.showFormFillError();
      return;
    }
    if (this.authenticatedUsers.length > 0) {
      this.share.authenticatedUsers = this.authenticatedUsers.reduce(function (acc, current) {
        return acc + ',' + current;
      });
    } else {
      this.share.authenticatedUsers = ''
    }
    this.shareService.upsertShare(this.share).subscribe(shareResult => {

      let filter = shareResult.filter?.length > 0 ? this.filterString(shareResult.filter) : ''

      //window.open(`/#/share/${shareResult.shareKey}/${filter}`, '_blank');
      this.data.function()
      this.coreService.showSuccessMessage("İşlem başarılı...");
      this.dialogRef.close(true)
    })
  }

  filterString(filter: any[]) {
    let filterString = JSON.stringify(Object.assign({}, filter))
    var stringFilter = '[' + filter.map(item => {
      return JSON.stringify(item)
    }) + ']'
    return stringFilter
  }

  searchUser(event) {
    this.filteredUsers = this.users.filter(x => x.firstName.toLowerCase().includes(event.target.value.toLowerCase()) || x.lastName.toLowerCase().includes(event.target.value.toLowerCase()))

    if (event.target.value == '') {
      this.filteredUsers = this.users
    }
  }

}
