import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-table',
  templateUrl: './edit-table.component.html',
  styleUrls: ['./edit-table.component.scss']
})
export class EditTableComponent implements OnInit {
  splashVisible: boolean = true

  constructor() { }

  ngOnInit(): void {
  }


  closeSplash() {
    this.splashVisible = false
  }

}
