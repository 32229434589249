import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { Share } from 'src/app/models/responses/shareResponse';
import { CoreService } from 'src/app/services/core/core.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ShareService } from 'src/app/services/share/share.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MailSceduleFormComponent } from '../../mail-scedule-form/mail-scedule-form.component';

@Component({
  selector: 'app-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss']
})
export class ViewsComponent implements OnInit {

  mySharesCore: Share[] = []
  _mySharesForList: { core: Share, listitem: { small: string, text: string } }[] = []

  buttonsList: { icon: string, function: Function, tooltip: string, class: string, type: string }[] = [
    { icon: 'mail.svg', function: (e) => this.sendMail(e), tooltip: '', class: 'bg-light-success', type: 'img' },
    { icon: 'copy.svg', function: (e) => this.copyshareLink(e), tooltip: '', class: 'bg-light-info', type: 'img' },
    { icon: 'deleteShare.svg', function: (e) => this.deleteShare(e), tooltip: '', class: 'bg-light-danger', type: 'img' }
  ]




  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { myShares: Share[], applyFilterForViews: Function, getShares: Function },
    private shareService: ShareService,
    private modalService: ModalService,
    private clipboard: Clipboard,
    private coreService: CoreService) { }

  ngOnInit(): void {

    this._mySharesForList = this.data.myShares.map(item => {
      return { core: item, listitem: { small: item.sharedBy, text: item.name } }
    })

  }



  deleteShare(item: Share) {
    this.modalService.openDeleteConfirm().subscribe(isOk => {
      if (isOk) {
        this.shareService.deleteShare(item.shareId).subscribe(result => {
          this.data.getShares()
          this.closeModal()
        });
      }
    })
  }

  filterString(filter: any[]) {
    let filterString = JSON.stringify(Object.assign({}, filter))
    var stringFilter = '[' + filter.map(item => {
      return JSON.stringify(item)
    }) + ']'
    return stringFilter
  }

  copyshareLink(item: Share) {
    let filter = this.filterString(item.filter)
    var link = encodeURIComponent(window.origin + `/#/share/${item.shareKey}?filters=${filter}`)

    const result = this.clipboard.copy(link).valueOf()

    if (result)
      this.coreService.showSuccessMessage('Copied!');


  }


  applyFilter(item: Share) {
    this.data.applyFilterForViews(item)
    this.closeModal()
  }





  sendMail(item: Share) {
    var data = {
      url: window.origin + `/#/share/${item.shareKey}?filters=${this.filterString(item.filter)}`,
      dashboardId: item.dashboardId
    }
    this.modalService.openModal(data, MailSceduleFormComponent, '80%', '60%').subscribe(res => {

    })
  }

  closeModal() {
    this.modalService.dissmissAll()
  }


}
