import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-column-select-dialog',
  templateUrl: './column-select-dialog.component.html',
  styleUrls: ['./column-select-dialog.component.scss']
})
export class ColumnSelectDialogComponent {
  selectedType: string;

  constructor(
    public dialogRef: MatDialogRef<ColumnSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedType = data.oldType;
  }
  ngOnInit(): void {
    this.selectedType = this.data.oldType;
    this.selectedType=this.data.columnType
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}