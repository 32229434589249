<div class="splash-container">
    <div style="display: flex;flex-direction: column;">
        <div style="display: flex;align-items: center;justify-content: center;height: 150px;">
            <img src="/assets/images/logoWithoutTetx.png" />
            <mat-progress-spinner strokeWidth="5" class="splash-progress" [color]="'primary'" mode="determinate"
                [value]="progressValue">
            </mat-progress-spinner>
        </div>

        <div style="display: flex;flex-direction: column;align-items: center;">
            <h3>{{"Common.SplashTitle"|translate}}</h3>
            <h6>{{"Common.SplashText"|translate}} </h6>
        </div>
    </div>

</div>