import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-avatar-group',
  templateUrl: './avatar-group.component.html',
  styleUrls: ['./avatar-group.component.scss']
})
export class AvatarGroupComponent implements OnInit,OnChanges {
  @Input() list: any
  @Input() top:string='-12px'
  @Input() width:string='38px'
  @Input() fontsize:string=''
  @Input() avatarKeys: any = { avatar: 'avatar', tooltip: 'mail', avatartext: 'firstName', avatartext2: 'lastName' }
  env: string = environment.imageUrl
  iserrorimage: boolean = false
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(this.avatarKeys,this.list)
  }

  errorimage(ev: any) {
    this.iserrorimage = true
  }

}
