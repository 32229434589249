import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { customDropdownProps, customDropdownSettingProps } from 'src/app/models/globals/customDropdownProps';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-dropdown-menu-with-search',
  templateUrl: './custom-dropdown-menu-with-search.component.html',
  styleUrls: ['./custom-dropdown-menu-with-search.component.scss']
})
export class CustomDropdownMenuWithSearchComponent implements OnInit, OnChanges {
  _menudata: customDropdownProps[] = []
  _filteredMenuData: customDropdownProps[] = []
  _initialData: any
  baseUrl: string = environment.baseUrl
  @Input() error: { text: string, active: boolean } = { text: '', active: false }
  @Input() dragActive: boolean = false
  @Input() sortChangeDisable: boolean = false
  @Input() disableIconFilter: boolean = false

  @Input()
  set menudata(value: any) {

    // Değişkenin değeri değiştiğinde bu işlem çalışır
    this._menudata = value;
    // Değişiklikleri işle
    this._filteredMenuData = value

    if (this._menudata.length > 0)
      this.sortForSelectedEelement()
  }





  @Input() enableCheckBox: boolean = false
  @Input() enableSearch: boolean = true
  @Input() checkIcon: boolean = false

  @Input() imageCard: boolean = false




  @Input() height: number | string = 300
  // @Input() multipleSelection: boolean = false
  @Input() dropDownSettings: customDropdownSettingProps = {
    name: '',
    id: '',
    imageCard: false,
    placeholder: '',
    iconMenuActive: true,
    triggerMenuIcon: { icon: 'measure.png', tooltip: '' },
    multipleSelection: false,
    dragActive: false,
    searchActive: true,
    changeSortWithDrop: (e) => { },
    input: [{
      inputType: 'none',
      title: '',
      visible: true,
      getValueFunction: (e) => { console.log(e) },
      defaultValue: '',

    }],
    topButtons: [],
    checkIcon: { icon: '', function: (e) => { console.log(e) } },
    selectElement: (e) => { },
    progressValue: 0,
    errorValues: { text: '', active: false }


  }






  @Output() selectElement: EventEmitter<any> = new EventEmitter<any>()
  @Output() iconMenuEemitter: EventEmitter<any> = new EventEmitter<any>()
  @Output() endIconEmitter: EventEmitter<any> = new EventEmitter<any>()
  @Output() changeSortWithDrop: EventEmitter<any> = new EventEmitter<any>()



  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('dropDownSettings', this.dropDownSettings)
  }



  selection(selectedid: any, initial: boolean) {

    if (!this.dropDownSettings.multipleSelection) {
      if (this._menudata.some(x => x.selected == true)) {
        this._menudata.find(x => x.selected == true).selected = false
      }
    }

    if (this._menudata.find(x => x.id == selectedid)) {
      if (!initial) {
        this._menudata.find(x => x.id == selectedid).selected = !this._menudata.find(x => x.id == selectedid)?.selected
      }
      else {
        this._menudata.find(x => x.id == selectedid).selected = true

      }

    }

    this.sortForSelectedEelement()
  }



  sortForSelectedEelement() {

    if (!this.sortChangeDisable) {
      this._menudata.sort((a, b) => (b.selectedMenuItem ? 1 : 0) - (a.selectedMenuItem ? 1 : 0));
      this._menudata.sort((a, b) => (b.selected ? 1 : 0) - (a.selected ? 1 : 0));
    }

  }

  select(element: any) {
    if (this.selectElement.observers.length > 0) {
      this.selection(element.id, false)

      if (this.dropDownSettings.multipleSelection) {
        var selectedList = this._menudata.filter(x => x.selected == true).map(item => {
          return item.element
        })
        this.selectElement.emit({ selectedElement: element, selectedList: selectedList })
      }
      else {
        this.selectElement.emit(element)
      }
    }

  }

  iconMenuFunction(selectedElement: customDropdownProps, SelectedMenuItem: string) {
    this.iconMenuSelection(selectedElement, SelectedMenuItem, false)
    this.iconMenuEemitter.emit(this._menudata)
  }

  iconMenuFunction2(selectedElement: customDropdownProps, SelectedMenuItem: string) {
    this.iconMenuSelection2(selectedElement, SelectedMenuItem, false)
    this.iconMenuEemitter.emit(this._menudata)
  }

  iconMenuSelection2(selectedElement: customDropdownProps, SelectedMenuItem: string, initial: boolean) {
    var element = this._menudata.find(x => x.id == selectedElement.id).selectedMenuItem2
    if (element == SelectedMenuItem) {
      this._menudata.find(x => x.id == selectedElement.id).selectedMenuItem2 = ''
    } else
      this._menudata.find(x => x.id == selectedElement.id).selectedMenuItem2 = SelectedMenuItem



  }

  iconMenuSelection(selectedElement: customDropdownProps, SelectedMenuItem: string, initial: boolean) {
    var element = this._menudata.find(x => x.id == selectedElement.id).selectedMenuItem
    if (element == SelectedMenuItem && !initial) {
      this._menudata.find(x => x.id == selectedElement.id).selectedMenuItem = ''
    } else
      this._menudata.find(x => x.id == selectedElement.id).selectedMenuItem = SelectedMenuItem

    this.sortForSelectedEelement()
  }

  searchData(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    if (filterValue == '') {
      this._filteredMenuData = this._menudata
      return
    }
    var filteredList = this._menudata.filter(x => x.title.trim().toLowerCase().includes(filterValue))
    this._filteredMenuData = filteredList

  }

  endIconFunction(selectedElement: any) {
    this.endIconEmitter.emit(selectedElement)
  }

  toggleItem(item: any) {
    this._menudata.find(x => x.id == item.id).toggle = this._menudata.find(x => x.id == item.id).toggle ? !this._menudata.find(x => x.id == item.id).toggle : true

    this.dropDownSettings.checkIcon.function(item)
  }

  drop(event) {
    moveItemInArray(this._filteredMenuData, event.previousIndex, event.currentIndex);
    var selectedMenuList = this._filteredMenuData.filter(x => x.selected).map(item => {
      return item.element
    })
    this.changeSortWithDrop.emit(this._filteredMenuData)
  }

}
