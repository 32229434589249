<div class="console">

    <div class="console-history" id="console-history">
        > Checking code ...<br>
        <span *ngIf="debugCodeResult!==''" style='color:red'>
            Your code have errors.<br>
            {{debugCodeResult}}
        </span>
        <span *ngIf="debugCodeResult==''" style='color:green'>
            Code complied without errors.<br>
        </span>
    </div>
</div>