import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { Share } from 'src/app/models/responses/shareResponse';
import { CoreService } from 'src/app/services/core/core.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ShareService } from 'src/app/services/share/share.service';
import { MailSceduleFormComponent } from '../mail-scedule-form/mail-scedule-form.component';

@Component({
  selector: 'app-list-item-with-icon',
  templateUrl: './list-item-with-icon.component.html',
  styleUrls: ['./list-item-with-icon.component.scss']
})
export class ListItemWithIConComponent implements OnInit {

  @Input() item: { core: any, listitem: { text: string, small: string } }
  @Input() smallTitle = ''
  @Input() buttonsList: { icon: string, function: Function, tooltip: string, type: string }[] = [
    { icon: 'mail.svg', function: () => { }, tooltip: '', type: '' }
  ]

  @Output() itemOnclickEvent: EventEmitter<any> = new EventEmitter<any>()





  constructor() { }

  ngOnInit(): void {
console.log(this.buttonsList)
  }


  itemOnclick(item: any, event: any) {
    this.itemOnclickEvent.emit(item)
  }

}
