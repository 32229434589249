import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { customDropdownProps, customDropdownSettingProps } from 'src/app/models/globals/customDropdownProps';
import { Flow, FlowSession } from 'src/app/models/responses/flowScript';
import { CoreService } from 'src/app/services/core/core.service';
import { FlowScriptService } from 'src/app/services/flow-scripts/flow-script.service';
import { SweetAlertService } from 'src/app/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-flow-log',
  templateUrl: './flow-log.component.html',
  styleUrls: ['./flow-log.component.scss']
})
export class FlowLogComponent implements OnInit {

  flowLogDropdownSettings: customDropdownSettingProps = {
    name: 'Flow',
    multipleSelection:false,
    imageCard:false,
    dragActive:false,
    changeSortWithDrop:(e)=>{},
    id: 'flow-dropdown',
    iconMenuActive: false,
    searchActive:true,
    placeholder: "FlowPage.SearchSession",
    triggerMenuIcon: { icon: 'measure.png', tooltip: '' },

    checkIcon: { icon: 'filter.png', function: (e) => { }, tooltip: "WidgetFormPage.FieldFilter" },
    endIcons: [
      {
        icon: 'delete-box',
        clickFunction: (e) => this.deleteSession(e),
        enable: true,
        popover: ''
      }
    ],
    topButtons: [
      {
        title: "Common.ClearAll",
        icon: 'clear_all',
        id: 'reset',
        eventFunction: () => {

          this.deleteAllSession(this.flowLogDropdownMenu)


        }
      }
    ],
    selectElement: (e) => this.sessionSelected(e),
    progressValue:0,
    errorValues: { text: '', active: false }



  }

  flowLogDropdownMenu: customDropdownProps[] = []
  selectedSessions: FlowSession[] = []
  groupedData: any

  tabs: any[] = [
    // { title: 'Session Details', icon: 'activity', dataKey: '', messageKey: 'sessionLog', messageKey2: 'startDate', messageKey3: 'endDate', arrayfrom: '' },
    { title: 'Execution Log', icon: 'document', messageKey: 'message', messageKey2: '', arrayfrom: 'executionLog', messageTitle: 'scriptName' },
    { title: 'Explainations', icon: 'chat', messageKey: 'value', arrayfrom: 'explainations', messageTitle: 'scriptName' },
    { title: 'Variables', icon: 'variable', messageKey: 'value', arrayfrom: 'variables', messageTitle: 'scriptName', messageTitle2: 'key' }



  ]

  constructor(@Inject(MAT_DIALOG_DATA) public data: { session: { [key: string]: FlowSession[] }, flow: Flow },
    private dialogRef: MatDialogRef<FlowLogComponent>,
    private flowScriptService: FlowScriptService,
    private translate: TranslateService,
    private sweetalertService: SweetAlertService, private coreService: CoreService) { }

  ngOnInit(): void {
    this.flowScriptService.allExecutionLogs.subscribe(res => {
      var groupedData = this.groupData(res);
    })



  }

  createDropDownData(groupedData) {

    this.flowLogDropdownMenu = Object.keys(groupedData).map((item, key) => {

      return {
        id: groupedData[item][0].sessionId,
        title: groupedData[item][0].endDate.split('T')[0] + '/' + groupedData[item][0].endDate.split('T')[1].split('.')[0],
        subtitle: groupedData[item][0].sessionId,
        element: groupedData[item],
        icon: groupedData[item][0].executionResult == 1 ? 'error' : null
      }
    })

    this.flowLogDropdownMenu = this.flowLogDropdownMenu.sort((a, b) => b.title.localeCompare(a.title))

    // if(this.flowLogDropdownMenu.length<=0){
    //   this.dialogRef.close()
    // }
  }


  groupData(data: FlowSession[]) {


    data = data.map(item => {
      return {
        ...item, executionLog: item.executionLog.sort((a, b) => {
          if (a.logDate < b.logDate) {
            return -1;
          }
          if (a.logDate > b.logDate) {
            return 1;
          }
          return 0;
        })
      }
    }).sort((a, b) => a.sequenceId - b.sequenceId);


    const groupedData = data.reduce((acc, item) => {
      const sessionId = item.sessionId

      // Create an array for the sessionId if it doesn't exist
      acc[sessionId] = acc[sessionId] || [];

      // Push the current item to the array
      acc[sessionId].push(item);

      return acc;
    }, {});
    this.createDropDownData(groupedData)
    this.groupedData = groupedData
    return groupedData
  }

  getdesc(id, session) {


    var selectedscript = session.script.find(x => x.scriptId == id)

    return selectedscript.description
  }

  sessionSelected(session: { element: FlowSession[] }) {
    this.selectedSessions = session.element

    //todo burayı grupla
    // var groupedData: FlowSession[] = []

    // this.selectedSessions.map(session => {
    //   if (groupedData.some(x => x.executionLog[0].scriptId == session.executionLog[0].scriptId))
    //     groupedData.find(x => x.executionLog[0].scriptId).executionLog = groupedData.find(x => x.executionLog[0].scriptId).executionLog.concat(session.executionLog)
    //   else
    //     groupedData.push(session)


    // })

    // this.selectedSessions=groupedData
    // console.log(groupedData)



  }

  deleteAllSession(data: any[]) {
    this.sweetalertService.open(
      this.translate.instant("ActionDialogComponent.Message"),
      this.translate.instant(this.translate.instant('Common.DeleteAllConfirmText')),
      'question',
      this.translate.instant("Common.Done"),
      (res) => {
        if (res.isConfirmed) {
          data.map(session => {
            this.flowScriptService.deleteSession(session.element[0].sessionId).subscribe(res => {
              if (res) {
                this.coreService.showSuccessMessage(res)
                this.flowScriptService.getExecutionLogForFlow(session.element[0].flowId, 'flow').subscribe(res => {
                  var groupedData = this.groupData(res);

                })

              }
            })
          })

        }

      },
      true)
  }

  deleteSession(session: any) {

    this.sweetalertService.open(
      this.translate.instant("ActionDialogComponent.Message"),
      this.translate.instant(session.title + this.translate.instant('Common.DeleteConfirmText')),
      'question',
      this.translate.instant("Common.Done"),
      (res) => {
        if (res.isConfirmed) {
          this.flowScriptService.deleteSession(session.element[0].sessionId).subscribe(res => {
            if (res) {
              this.coreService.showSuccessMessage(res)
              this.flowScriptService.getExecutionLogForFlow(session.element[0].flowId, 'flow').subscribe(res => {
                var groupedData = this.groupData(res);
                if (res.length <= 0) {
                  this.dialogRef.close()
                }
              })
            }
          })
        }

      },
      true)


  }

  TimeConverter(time: string) {
    return time?.split('T')[0] + ' ' + time?.split('T')[1]?.split('.')[0]
  }


}
