<div class="content">
    <app-title title="{{ 'LDAPConfigPage.Title' | translate }}"></app-title>
    <div>
        <div [formGroup]="pageForm">
            <mat-form-field>
                <mat-label>{{ 'LDAPConfigPage.DomainName' | translate }}</mat-label>
                <input matInput [(ngModel)]="config.domainName" formControlName="domainName" required>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'LDAPConfigPage.Port' | translate }}</mat-label>
                <input matInput [(ngModel)]="config.port" formControlName="port" required>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'LDAPConfigPage.Server' | translate }}</mat-label>
                <input matInput [(ngModel)]="config.server" formControlName="server" required>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'LDAPConfigPage.Timeout' | translate }}</mat-label>
                <input matInput [(ngModel)]="config.timeout" formControlName="timeout" required>
            </mat-form-field>
            <br>


        </div>
    </div>
    <button mat-button class="b-btn b-success" (click)="updateLDAP()">{{ 'Common.Update' | translate }}</button>
</div>