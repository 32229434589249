<div class="search-area" [style.Height]="height">
    <div class="input-area">
        <input (input)="filterSearchData($event)" placeholder="{{'Search'|translate}}" />
    </div>
    <div class="search-icon">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
            tag="i" class="v-icon notranslate v-theme--light v-icon--size-default iconify iconify--tabler" width="1em"
            height="1em" viewBox="0 0 24 24">
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                d="M3 10a7 7 0 1 0 14 0a7 7 0 1 0-14 0m18 11l-6-6"></path>
        </svg>
    </div>
</div>