import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(
		private authService: AuthService
	) { }

	canActivate(): boolean {
		let token = localStorage.getItem('token');
		this.authService.checkSession();
		if (!token) {
			this.authService.logout().subscribe(result => {});;
			return false;
		}
		return true;
	}

}

@Injectable({
	providedIn: 'root'
})
export class LoginGuard implements CanActivate {

	constructor(
		private authService: AuthService,
		private router: Router
	) { }

	canActivate(): boolean {
		let token = localStorage.getItem('token');
		this.authService.checkSession();
		if (token) {
			//this.authService.logout().subscribe(result => {});;
			this.router.navigate(['']);
			return false;
		}
		return true;
	}

}