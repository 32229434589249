<app-custom-modal [title]="'Flow ekle'" [subtitle]="'WidgetFormPage.widgetformtext'"
    img="/assets/images/hypertext.png">
    <div class="form-body" body>
        <div>
            <form [formGroup]="flowForm">
                <div style="width: 100%;">
                    <mat-form-field>
                        <mat-label>{{ 'FlowForm.Name' | translate }}</mat-label>
                        <input matInput  formControlName="name">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ 'FlowForm.Connector' | translate }}</mat-label>
                        <mat-select formControlName="bussionConnectorId">
                            <mat-option [value]="item.nodeId" *ngFor="let item of connectors">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ 'FlowForm.Desc' | translate }}</mat-label>
                        <textarea matInput formControlName="description"></textarea>
                    </mat-form-field>
                    <mat-checkbox [checked]="flowForm.get('type').value" formControlName="type" class="example-margin mr-10">
                        {{"FlowForm.Type"|translate}}
                    </mat-checkbox>
                    <mat-checkbox  [checked]="flowForm.get('updateScriptsBeforeRun').value" formControlName="updateScriptsBeforeRun" class="example-margin ">
                        {{"FlowForm.updateScriptsBeforeRun"|translate}}
                    </mat-checkbox>
                </div>
            </form>
        </div>
        
        <div style="display: flex;justify-content: end;">
            <button *ngIf="data.deleteFunction!==null" (click)="deleteElement()" mat-stroked-button class="custom-primary-stroked-button mr-10">
                {{ 'Common.Delete' | translate }}
            </button>
            <button (click)="closeModal()" mat-stroked-button class="custom-primary-stroked-button mr-10">
                {{ 'Common.Cancel' | translate }}
            </button>
            <button *ngIf="data?.flow?.flowId"  mat-raised-button (click)="upsertFlow('copy')" class="custom-primary-button mr-10">
                {{ 'Common.SaveAs' | translate }}
            </button>
            <button  mat-raised-button (click)="upsertFlow()" class="custom-primary-button">
                {{ 'Common.Save' | translate }}
            </button>
        </div>

    </div>

</app-custom-modal>