<div>

    <div class="dropdown-header">
        <h4 class="notification-title mb-0 mr-auto">Views</h4>
        <h4 class="notification-title mb-0 mr-auto exit" (click)="closeModal()">X</h4>

    </div>

    <!-- (itemOnclickEvent)="applyFilterForViews($event)" -->
    <app-list-item-with-icon  (itemOnclickEvent)="applyFilter($event)"  [buttonsList]="buttonsList"
        *ngFor="let share of _mySharesForList" [item]="share" ></app-list-item-with-icon>
    <div *ngIf="data.myShares.length<=0" style="width: 100%;display:flex;align-items:center;justify-content: center;">
        <p class="media-heading">{{'ShareComponent.noShares'|translate}}</p>
    </div>



</div>

<!-- <div class="selection-list-with-icon">

    <mat-list-option (click)="changeFilters(s)"></mat-list-option>
    <mat-icon style="color:#0096c7" (click)="copyshareLink(s)">file_copy</mat-icon>
    <mat-icon svgIcon="delete" (click)="deleteShare(s)"></mat-icon>
</div> -->