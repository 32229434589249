import { Component, OnInit, Inject } from '@angular/core';
import { ColorPalette } from 'src/app/models/responses/colorPaletteResponse';
import { ColorPaletteService } from 'src/app/services/color-palette/color-palette.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateService } from '@ngx-translate/core';
import chroma from "chroma-js";

@Component({
  selector: 'app-color-palette-form',
  templateUrl: './color-palette-form.component.html',
  styleUrls: ['./color-palette-form.component.scss']
})
export class ColorPaletteFormComponent implements OnInit {

  pageForm: UntypedFormGroup;
  title: string = "";
  isShownForm: boolean = false;
  color: string = "";
  colorSuggestion: string[] = []
  colorSuggestionForPicker: string[] = []
  selectedColorsForSuggestion: string[] = []
  colorSuggestCount: number = 7

  isCreateForm: boolean = true;
  colorPalette: ColorPalette = {
    name: "",
    palettes: []
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ColorPaletteFormComponent>,
    private spinner: NgxSpinnerService,
    private colorService: ColorPaletteService,
    private translate: TranslateService,
    private coreService: CoreService
  ) { }

  ngOnInit(): void {
    this.pageForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.colorPalette.name, [Validators.required]),
    });

    this.title = this.data.type == 'update' ? this.translate.instant("ColorPaletteFormPage.TitleUpdate") : this.translate.instant("ColorPaletteFormPage.TitleCreate");
    this.isCreateForm = this.data.type == 'update' ? false : true;
    if (this.data.color) {
      this.colorPalette = Object.assign({}, this.data.color);
    }

    this.selectedColorsForSuggestion = [this.colorPalette.palettes[0], this.colorPalette.palettes[this.colorPalette.palettes.length - 1]]
    this.createSuggestion(this.colorSuggestCount)


  }


  createSuggestion(suggestionCount: number) {
    this.colorSuggestion = chroma.scale(this.selectedColorsForSuggestion)
      .mode('lch').colors(suggestionCount)
  }

  changeSuggestionForPicker() {
    var brigtlist = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5]
    var darklist = [0.5, 0.8, 1, 1.3, 1.8, 2, 2.5, 2.8, 3]

    this.colorSuggestionForPicker = darklist.map(item => {
      return chroma(this.color).darken(item).hex()
    })

    var brigthen = brigtlist.map(item => {
      return chroma(this.color).brighten(item).hex()
    })
    this.colorSuggestionForPicker = this.colorSuggestionForPicker.concat(brigthen)
  }

  selectColorForSuggestion(selectedcolor: string) {
    this.selectedColorsForSuggestion.push(selectedcolor)
    this.selectedColorsForSuggestion.shift()
    this.createSuggestion(this.colorSuggestCount)
  }

  upsertColorPalette() {
    if (this.pageForm.invalid) {
      this.coreService.showFormFillError();
      return;
    }
    this.spinner.show();
    this.colorService.upsertColorPalette(this.colorPalette).subscribe(result => {
      this.spinner.hide();
      this.dialogRef.close(true);
    });
  }

  addColor(color?: string) {
    if (color) {
      this.colorPalette.palettes.unshift(color)
    } else
      this.colorPalette.palettes.unshift(this.color)
  }

  deleteColor(index: number) {
    this.colorPalette.palettes.splice(index, 1);
  }

}
