import { Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { gridTypes } from 'angular-gridster2/lib/gridsterConfig.interface';
import { SpeechProcces } from 'src/app/core/speechTypes';
import { Dashboard, Dimension } from 'src/app/models/responses/dashboardResponse';
import { User } from 'src/app/models/responses/loginResponse';
import { Share } from 'src/app/models/responses/shareResponse';
import { CreateUserComponent } from 'src/app/pages/manage/user/create-user/create-user.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CoreService } from 'src/app/services/core/core.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { MailSceduleFormComponent } from '../mail-scedule-form/mail-scedule-form.component';
import { filterString } from 'src/app/core/utils';
import { Clipboard } from '@angular/cdk/clipboard';
import { ShareService } from 'src/app/services/share/share.service';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { ShareFormComponent } from '../modals/share-form/share-form.component';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})

export class DashboardHeaderComponent implements OnInit, OnChanges {
  firstName: string = "";
  lastName: string = "";
  avatar: string = "";
  mic: boolean = false
  permissions: string[] = []
  _mySharesForList: { core: Share, listitem: { text: string, small: string } }[] = []
  mySharesCore: Share[] = []
  @Input() isShownFilterAI: boolean = false
  @Input() changeViewList: boolean = false;
  @Input() selectedFilterGroup: any[] = []
  @Input() disableAvatar: boolean = false
  @Input() disableViews: boolean = false
  @Input() disableMessages: boolean = false
  @Input() disableFit: boolean = false
  @Input() disableFilter: boolean = false
  @Input() disableDashboardList: boolean = false
  @Input() selectedFilters: BussionFilterDefinition[] = []
  @Input() closeButton: boolean = false
  disableDownload: boolean = true
  @Input() redirection: string = '/'
  @Output() redirectionFunc: EventEmitter<any> = new EventEmitter<any>();

  roles: string[] = this.authService.getRoles()



  // @Input() myShares: any[] = []
  buttonsList: { icon: string, function: Function, tooltip: string, class: string }[] = [
    { icon: 'mail.svg', function: (e) => this.sendMail(e), tooltip: '', class: 'bg-light-success' },
    { icon: 'copy.svg', function: (e) => this.copyshareLink(e), tooltip: '', class: 'bg-light-info' },
    { icon: 'editShare.svg', function: (e) => this.updateShare(e), tooltip: '', class: 'bg-light-info' },
    { icon: 'deleteShare.svg', function: (e) => this.deleteShare(e), tooltip: '', class: 'bg-light-danger' }
  ]

  @Input()
  set myShares(value: Share[]) {
    this.mySharesCore = value
    this._mySharesForList = value.map(item => {
      return { core: item, listitem: { small: item.sharedBy, text: item.name } }
    })
  }
  micenable: boolean = true


  searchText: string = ''
  inputvalue: string = ''
  wordCommand: string = ''
  @ViewChild('tooltip') tooltip
  processingCommand: boolean = false
  isMobile: boolean = false

  @Input() groupedData: {
    [key: string]: {
      content: BussionFilterDefinition & { visibleName: string, parameter: string, lockedFilter: number }[];
      dimension: Dimension;
      priority: number;
      isinitial: number
    }
  }

  @Input() selectedDashboard: Dashboard
  @Input() dashboardList: Dashboard[] = []
  @Output() changeDashboardLayout: EventEmitter<any> = new EventEmitter<any>()
  @Output() getFilteredDashboardFunc: EventEmitter<any> = new EventEmitter<any>()
  @Output() showFullScreenFunc: EventEmitter<any> = new EventEmitter<any>()
  @Output() dashboardSelectedFunc: EventEmitter<any> = new EventEmitter<any>()
  @Output() openDashboardFilterFunc: EventEmitter<any> = new EventEmitter<any>()
  @Output() addActiveDashboardFunc: EventEmitter<any> = new EventEmitter<any>()
  @Output() openSideNavFunc: EventEmitter<any> = new EventEmitter<any>()
  @Output() getShares: EventEmitter<any> = new EventEmitter<any>()
  @Output() resetFilterFunc: EventEmitter<any> = new EventEmitter<any>()
  @Output() applyDropdownFilter: EventEmitter<any> = new EventEmitter<any>()
  @Output() clearSelectedFilterGroup: EventEmitter<any> = new EventEmitter<any>()
  @Output() downloadDashboardFunction: EventEmitter<any> = new EventEmitter<any>()






  functionList: { type: number, function: Function }[] = [
    { type: SpeechProcces.FilterDashboard, function: (text) => this.getFilteredDashboard(text) },
    { type: SpeechProcces.Delete, function: () => this.resetInput() },
    { type: SpeechProcces.Refresh, function: () => this.resetDashboard() }]





  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private coreService: CoreService,
    private userService: UserService,
    private translate: TranslateService,
    private router: Router,
    private clipboard: Clipboard,
    private shareService: ShareService,
    private dashboardService: DashboardService,
    private deviceService: DeviceDetectorService,
    private scriptLoader: ScriptLoaderService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile()
    this.firstName = this.authService.getUserFirstName();
    this.lastName = this.authService.getUserLastName();
    this.avatar = environment.imageUrl + this.authService.getUserAvatar();
    // this.dashboardService.selectedDasboard.subscribe((res => {
    //   this.selectedDashboard = res
    // }))
    console.log('selectedDashboard', this.selectedDashboard)
    this.permissions = this.authService.getPermissions()
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      this.micenable = true
    } else {
      this.micenable = false
    }

    if(this.permissions.includes('DashboardExport')){
      this.disableDownload=false
    }else{
      this.disableDownload=true
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  openSelfServisMain() {
    this.router.navigate(['selfservice'])
  }

  logout() {
    this.authService.logout().subscribe(result => {
      this.scriptLoader.selectedFilters.next([])
      this.dashboardService.setCacheData({ authorizedDashboard: [], activeDashboard: [] })

    });
  }

  goManage() {
    // this.router.navigate(['/manage/user'])
    this.router.navigate(['/landing'])
  }

  showUserInfo() {
    let data = {
      type: "update",
      user: this.authService.getUser() as User,
      ownUser: true
    }
    this.modalService.openModal(data, CreateUserComponent, 'auto', '1000px').subscribe(result => {
      if (result) {
        this.getUserInfo();
        this.coreService.showSuccessMessage(result);
      }
    });
  }

  getUserInfo() {
    this.userService.getUserById(this.authService.getUserId()).subscribe(user => {
      this.authService.saveUserInfos(user);
      this.firstName = this.authService.getUserFirstName();
      this.avatar = environment.imageUrl + this.authService.getUserAvatar();
      // if (user.language == 'English') {
      //   this.translate.use('en');
      // } else {
      //   this.translate.use('tr');
      // }
    })
  }

  changeDashboardLayoutFunc(layout: string) {
    this.changeDashboardLayout.emit(layout)
  }

  showFullScreen(status: boolean) {
    this.showFullScreenFunc.emit(status)
  }

  dashboardSelected(dashboard: Dashboard) {
    this.dashboardService.setLastDashboardId(dashboard.dashboardId)
    this.dashboardSelectedFunc.emit(dashboard)
  }

  resetFilter() {
    this.resetFilterFunc.emit([])
  }

  clearSelectedFilterGroupFunc(filter) {
    this.clearSelectedFilterGroup.emit(filter)
  }

  openDashboardFilter() {
    this.openDashboardFilterFunc.emit()
  }

  addActiveDashboard() {
    this.addActiveDashboardFunc.emit()
  }
  applyDropdownFilterFunc(filter: BussionFilterDefinition[]) {
    
    var editedfilterList = []

    filter?.map(item => {
      if (item.fieldValue == '' && item.operator == '') {
        return;
      } else {
        editedfilterList.push({ fieldName: item.fieldName, fieldValue: item.fieldValue, operator: item.operator })

      }
    })
    this.applyDropdownFilter.emit(editedfilterList)
  }

  openSideNav() {
    this.openSideNavFunc.emit()
  }

  getFilteredDashboard(event) {
    this.scriptLoader.selectedFilters.getValue()

    if (event.target) {
      this.getFilteredDashboardFunc.emit(event.target.value)
      event.target.value = ''

    }
    else {
      this.wordCommand = event
      this.inputvalue = event
      if (event.includes('sorgula'))
        event = event.replace('sorgula', '')
      if (event.includes('sorgul'))
        event = event.replace('sorgul', '')
      else if (event.includes('sorgul'))
        event = event.replace('sorgu', '')
      else if (event.includes('sorg'))
        event = event.replace('sorg', '')

      this.getFilteredDashboardFunc.emit(event)
    }

  }

  resetInput() {
    this.inputvalue = ''
    // this.getFilteredDashboard('')
  }

  resetDashboard() {
    this.getFilteredDashboard('')
  }


  sendMail(item: Share) {
    var data = {
      url: window.origin + `/#/share/${item.shareKey}?filters=${filterString(item.filter)}`,
      dashboardId: item.dashboardId
    }
    this.modalService.openModal(data, MailSceduleFormComponent, '80%', '60%').subscribe(res => {

    })
  }

  copyshareLink(item: Share) {
    item.filter = item.filter.map(item => {
      return {
        fieldName: item.fieldName,
        fieldValue: item.fieldValue,
        operator: item.operator
      }
    })
    let filter = filterString(item.filter)
    var filterencode = encodeURIComponent(filter)
    const result = this.clipboard.copy(window.origin + `/#/share/${item.shareKey}?filters=` + filterencode).valueOf()

    if (result)
      this.coreService.showSuccessMessage('Copied!');




  }

  deleteShare(item: Share) {
    this.modalService.openDeleteConfirm().subscribe(isOk => {
      if (isOk) {
        this.shareService.deleteShare(item.shareId).subscribe(result => {
          this.getShares.emit();
        });
      }
    })
  }

  updateShare(item: Share) {
    let data = {
      objectType: item.objectType,
      objectId: item.sharedObjectId,
      dashboardId: this.selectedDashboard.dashboardId,
      filter: this.selectedDashboard.filter,
      function: () => {
        this.changeViewList = !this.changeViewList
      },
      data: item
    };

    this.modalService.openModal(data, ShareFormComponent, 'auto', '600px').subscribe(result => {
      if (result) {
        // this.getShares.emit();
      }
    })
  }

  applyFilterForViews(share: Share) {
    this.scriptLoader.setFilter(share.filter, false)
  }


  downloadDashboard(type:string) {
    this.downloadDashboardFunction.emit(type)
  }

  redirectHomePage() {
    if (this.redirectionFunc.observers.length > 0) {
      this.redirectionFunc.emit()
    } else {
      this.router.navigate([this.redirection])
    }
  }
}
