import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {

  @Input() title:string=''
  @Input() subtitle:string=''
  @Input() img:string=''



  constructor(
    private dialogRef: MatDialogRef<CustomModalComponent>) { }


  ngOnInit(): void {

  }

  closeDialog() {
    this.dialogRef.close()
  }







}
