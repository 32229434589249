import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import { GridsterConfig, GridsterItem, DisplayGrid, CompactType, GridType, GridsterComponent } from 'angular-gridster2';
import { ActivatedRoute, Router } from '@angular/router';
import { Dashboard, Dimension } from 'src/app/models/responses/dashboardResponse';
import { WidgetFormComponent } from './widget-form/widget-form.component';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ActionDialogComponent } from 'src/app/components/action-dialog/action-dialog/action-dialog.component';
import { DashboardFilterComponent } from '../dashboard-filter/dashboard-filter.component';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { ExpandedWidgetComponent } from 'src/app/components/modals/expanded-widget/expanded-widget.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { GlobalFunctionService } from 'src/app/services/global-function/global-function.service';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { ShareFormComponent } from 'src/app/components/modals/share-form/share-form.component';
import { deleteCssFile } from 'src/app/core/utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AvailableWidgetSelectionComponent } from 'src/app/components/available-widget-selection/available-widget-selection.component';
import { GridsterWidget, Widget, WidgetPreview } from 'src/app/models/responses/widgetLibraryResponse';
import { gridTypes } from 'angular-gridster2/lib/gridsterConfig.interface';
import { concatMap, tap } from 'rxjs/operators';
import { Share } from 'src/app/models/responses/shareResponse';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-designer',
  templateUrl: './dashboard-designer.component.html',
  styleUrls: ['./dashboard-designer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class DashboardDesignerComponent implements OnInit, OnDestroy {
  @ViewChild(GridsterComponent) gridster: GridsterComponent;
  isShownFilter: boolean = false;
  options: GridsterConfig;
  opened: boolean = true;
  dashboard: Dashboard;
  dashboardId: string = "";
  dashboardWidgets: Array<GridsterItem> = [];
  unvisibleWidgets: Array<GridsterItem> = [];
  unvisibleSelectedList: any[] = []
  private widgetList: WidgetPreview[] = [];
  visibletoggle: boolean = false
  mobileDesign: boolean = false;

  permissions = this.authService.getPermissions()

  changeViewList: boolean = false
  constructor(
    private activatedRoute: ActivatedRoute,
    private scriptLoader: ScriptLoaderService,
    private dashboardService: DashboardService,
    private globalFunctionService: GlobalFunctionService,
    private modalService: ModalService,
    private deviceService: DeviceDetectorService,
    private authService: AuthService
  ) {
    this.dashboardId = this.activatedRoute.snapshot.paramMap.get('dashboardId');
  }

  ngOnInit() {
    this.visibletoggle = this.deviceService.isTablet() || this.deviceService.isMobile() ? true : false

    let didFunctionLoad = this.globalFunctionService.didFunctionLoad();
    if (didFunctionLoad) {
      this.customizeDashboard();
      this.designDashboard();
      this.getDashboardsWidgets();
    } else {
      this.getGlobalFunction();
    }

    this.dashboardService.openedClosedbtn.subscribe(res => {
      this.changedOptions()
    });


  }

  ngOnDestroy(): void {
    deleteCssFile(this.dashboard.dashboardId)
  }

  DragModeChange() {
    this.options.draggable.enabled = !this.options.draggable.enabled
    this.options?.api?.optionsChanged()

  }

  cerateCssFile(css: string) {
    var style = document.createElement('style');
    style.type = 'text/css';
    style.id = this.dashboard.dashboardId
    style.innerHTML = css;
    document.getElementsByTagName('head')[0].appendChild(style);
  }



  private getGlobalFunction() {
    this.globalFunctionService.getGlobalFunctions().subscribe(result => {
      result.forEach(f => {
        this.scriptLoader.runGlobalFunction(f.code);
      });
      this.customizeDashboard();
      this.designDashboard();
      this.getDashboardsWidgets();
    })
  }


  getDashboard() {
    return this.dashboardService.getDashboard(this.dashboardId).pipe(
      tap(res => this.dashboard = res),
      tap(res => this.dashboardService.getAuthorizedDashboards(1)),
      tap(res => this.dashboardService.getUserActiveDashboards(1))
    )
  }

  optionsChange() {
    if (this.dashboard?.gridSize) {
      this.options.minCols = this.dashboard?.gridSize
      this.options.minRows = this.dashboard?.gridSize
      this.options.maxCols = this.dashboard?.gridSize
    }
    if (this.mobileDesign) {
      this.options.draggable.enabled = true
      this.options.displayGrid = DisplayGrid.Always
      this.options.mobileBreakpoint = 0
      this.options.margin = 5
      this.options.maxRows = 1000
      // this.options.compactType=CompactType.None

      this.options.gridType = GridType.ScrollVertical
    } else {
      if (this.dashboard?.gridSize) {
        this.options.defaultItemCols = Math.round(this.dashboard?.gridSize / 2)
        this.options.defaultItemRows = Math.round(this.dashboard?.gridSize / 2)
      }
      if (this.dashboard.layout) {
        this.options.gridType = GridType[this.dashboard.layout]
      }
    }
    this.options?.api?.optionsChanged()

  }

  private designDashboard() {
    this.getDashboard().subscribe(result => {
      // this.dashboard = result;
      this.dashboardService.selectedDasboard.next(this.dashboard)
      if (this.dashboard.css !== "") {
        // this.cerateCssFile(this.dashboard.css);
      }
      this.optionsChange();
    });
  }

  private getDashboardsWidgets() {
    this.dashboardService.getWidgetsForDesigner(this.dashboardId).subscribe((result: WidgetPreview[]) => {
      this.widgetList = result;
      result.forEach(widget => {
        if (widget.widget.isFilter) {
          this.unvisibleWidgets.push({ cols: widget.widget.layout.cols, rows: widget.widget.layout.rows, y: widget.widget.layout.y, x: widget.widget.layout.x, designerContainer: widget })
        } else {
          this.dashboardWidgets.push({ cols: widget.widget.layout.cols, rows: widget.widget.layout.rows, y: widget.widget.layout.y, x: widget.widget.layout.x, designerContainer: widget });
          this.createWidget(widget);
        }

      })


    });
  }

  changedOptions() {
    this.options?.api.optionsChanged();
  }

  openAvailableWidgetScreen() {
    let data = {
      saveselectedWidgets: (widgets) => this.saveselectedWidgets(widgets)
    }
    this.modalService.openModal(data, AvailableWidgetSelectionComponent, '90%', '90%', 'overflow-hidden').subscribe(result => {
      this.removeFocusAllButton()

    });
  }

  removeFocusAllButton() {
    Array.from(document.getElementsByTagName('button')).map(item => {
      item.blur()
    })
  }

  saveselectedWidgets(items: GridsterWidget[]) {
    this.dashboardWidgets = this.dashboardWidgets.concat(items)


    this.dashboardWidgets.map(item => {
      this.createWidget(item.designerContainer);

    })
    items.map(async (item) => {
      //Bu servisi çağıracağız
      await this.dashboardService.upsertWidgetToDashboard(item.designerContainer.widget, this.dashboardId).subscribe(result => {

      });
    })


  }



  openWidgetForm(event: { widget: Widget, type: string }) {

    var dashboardfilter = this.dashboard.filter.map(item => {
      return { fieldValue: item.fieldValue, fieldName: item.fieldName, operator: item.operator }
    })

    let data = {
      type: event.type,
      widget: event.widget,
      dashboardId: this.dashboard.dashboardId,
      widgetList: this.widgetList,
      dashboardFilter: dashboardfilter,
      deleteWidget: (e) => this.removeWidget(e)
    }
    this.modalService.openModal(data, WidgetFormComponent, '960px', '90%', 'overflow-hidden').subscribe(result => {
      if (result) {

        if (result.type == 'update' && event.widget.isFilter == 0) {

          let index = this.dashboardWidgets.findIndex(w => w.designerContainer.widgetId == event.widget.widgetId);
          this.dashboardWidgets.splice(index, 1);
        }

        setTimeout(() => {
          let layout = result.widget.widget.layout;

          if (result.widget.widget.isFilter) {
            this.unvisibleWidgets = this.unvisibleWidgets.filter(x => x.designerContainer.widgetId !== result.widget.widgetId)
            this.unvisibleWidgets.push({
              cols: layout.cols,
              rows: layout.rows,
              y: layout.y,
              x: layout.x,
              designerContainer: result.widget
            });
          } else {
            this.dashboardWidgets.push({
              cols: layout.cols,
              rows: layout.rows,
              y: layout.y,
              x: layout.x,
              designerContainer: result.widget
            });
            this.createWidget(result.widget);
            this.saveDashboard();
          }

          this.getDashboard().subscribe(res => { })
        }, 1000);
      }

      this.removeFocusAllButton()
    });
  }

  openDashboardDimensions() {
    this.isShownFilter = true;
    /*     let data = {
          dimensions: this.dashboard.dimensions,
          filters: this.dashboard.filter
        }
    
        this.modalService.openModal(data, DashboardFilterComponent, 'auto', '90%').subscribe(result => {
          if (result) {
            this.dashboard.filter = result;
            this.dashboardService.upsertDashboard(this.dashboard).subscribe(result => {
              this.getDashboard();
              this.dashboardWidgets = [];
              this.getDashboardsWidgets();
            })
          }
        }); */
  }

  saveFilter(newFilters: BussionFilterDefinition[]) {
    this.closeFilterForm(null);
    this.dashboard.filter = newFilters;
    this.dashboardService.upsertDashboard(this.dashboard).subscribe(result => {
      this.dashboardService.selectedDasboard.next(this.dashboard)
      this.getDashboard().subscribe(res => { });
      this.dashboardWidgets = [];
      this.getDashboardsWidgets();
    })
  }

  openShareForm(shareData: any) {
    let data = {
      objectType: shareData.objectType,
      objectId: shareData.objectId,
      dashboardId: this.dashboard.dashboardId,
      filter: this.dashboard.filter,
      function: (filters) => {
        // this.dashboard=this.dashboard
        this.changeViewList = !this.changeViewList
      }
    };

    this.modalService.openModal(data, ShareFormComponent, 'auto', '600px').subscribe(result => {
      if (result) {

      }
    })
  }




  closeFilterForm(event: any) {
    this.isShownFilter = false;
  }

  expandWidget(widgetData) {
    let data = {
      widgetData: widgetData.designerContainer,
      shareModalOpen: (e) => this.openShareForm({ ...e, filter: this.dashboard.filter }),
      selectedFilters: this.dashboard.filter,
      selectedDashboard: this.dashboard

    }
    this.modalService.openModal(data, ExpandedWidgetComponent, '600px', '90%', 'overflow-hidden').subscribe(result => { });
  }

  private createWidget(widgetResponse: any) {
    this.scriptLoader.addScriptFileToHead(widgetResponse.widgetLibraries);
    this.scriptLoader.runWidgetCode(widgetResponse);
  }

  hideDimension(dim: Dimension) {
    this.dashboard.widgets.map((widget: Widget) => {
      if (widget.dimensions.some(x => x.fieldName == dim.name))
        widget.dimensions.find(x => x.fieldName == dim.name).fieldFilter = 0
    })
    this.dashboard.dimensions = this.dashboard.dimensions.filter(x => x.dimensionId !== dim.dimensionId)
    this.dashboardService.upsertDashboard(this.dashboard).subscribe(result => {
    });
  }

  saveDashboard() {
    var ismobile = 0
    if (this.mobileDesign) {
      ismobile = 1
    }
    this.dashboardService.updateDashboardLayout(this.dashboard.dashboardId, this.dashboardWidgets, ismobile).subscribe(result => {
    });
  }

  saveDashboardForDimensionVisibleName(dimensions: Dimension[]) {
    this.dashboard.dimensions = dimensions
    this.dashboardService.upsertDashboard(this.dashboard).subscribe(result => {
    });
  }

  saveQueryPanelState(queryPanelState: boolean) {
    this.dashboard.hideAdvancedQueryPanel = queryPanelState ? 1 : 0

    this.dashboardService.upsertDashboard(this.dashboard).subscribe(result => {
    });
  }

  removeWidget(item: any) {
    if (this.dashboardWidgets.some(x => x.designerContainer.widgetId == item.designerContainer.widgetId))
      item = this.dashboardWidgets.find(x => x.designerContainer.widgetId == item.designerContainer.widgetId)
    else
      item = this.unvisibleWidgets.find(x => x.designerContainer.widgetId == item.designerContainer.widgetId)

    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.dashboardService.deleteWidgetFromDashboard(this.dashboard.dashboardId, item.designerContainer.widgetId).subscribe(result => {
          if (this.dashboardWidgets.indexOf(item) !== -1) {
            this.dashboardWidgets.splice(this.dashboardWidgets.indexOf(item), 1);
            this.modalService.dissmissAll();
          } else {
            this.unvisibleWidgets = this.unvisibleWidgets.filter(x => x.designerContainer.widgetId !== item.designerContainer.widgetId)
            this.modalService.dissmissAll();
          }
        });
      }
    });
  }



  changeGrid(type) {
    this.options.gridType = GridType[type]
    this.options.api.optionsChanged()
    this.dashboard.layout = type
    this.dashboardService.upsertDashboard(this.dashboard).subscribe(result => {
    });
  }

  applyFilterForViews(share: Share) {
  }


  redesign() {
    this.mobileDesign = !this.mobileDesign
    let selectedLayoutkey = 'layout'

    if (this.mobileDesign) {
      if (this.widgetList.filter(x => x.widget.isFilter == 0)[0].widget.mobileLayout.cols > 1) {
        selectedLayoutkey = 'mobileLayout'
      }
      this.options.draggable.enabled = true
      this.options.displayGrid = DisplayGrid.Always
      this.options.mobileBreakpoint = 0
      this.options.margin = 5
      this.options.maxRows = 550

      this.options.gridType = GridType.ScrollVertical
      // displayGrid: DisplayGrid.OnDragAndResize,
      this.options.api.optionsChanged();
    } else {
      this.optionsChange()
    }
    this.dashboardWidgets = []

    this.widgetList.filter(x => x.widget.isFilter == 0).map(widget => {
      this.dashboardWidgets.push({ cols: widget.widget[selectedLayoutkey].cols, rows: widget.widget[selectedLayoutkey].rows, y: widget.widget[selectedLayoutkey].y, x: widget.widget[selectedLayoutkey].x, designerContainer: widget });
      this.createWidget(widget);

    })
  }




  private customizeDashboard() {
    this.options = {
      gridType: GridType.ScrollVertical,
      compactType: CompactType.None,
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 640,
      minCols: 24,
      maxCols: 100,
      minRows: 24,
      maxRows: 100,
      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 20,
      defaultItemRows: 20,
      fixedColWidth: 105,
      fixedRowHeight: 205,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true,
      },
      resizable: {
        enabled: true,
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: false,
      displayGrid: DisplayGrid.OnDragAndResize,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,
    };
  }
}
