import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';

@Component({
  selector: 'app-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss']
})
export class DashboardFilterComponent implements OnInit {

  dimensions: any[] = [];
  field: any;
  values: any[] = [];
  filters: BussionFilterDefinition[] = [];
  filtersForList: string[] = [];
  initialFilters: BussionFilterDefinition[] = [];

  filter: BussionFilterDefinition = {
    fieldName: '',
    fieldValue: '',
    operator: ''
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DashboardFilterComponent>,
  ) { }

  ngOnInit(): void {
    if (this.data.dimensions) {
      this.dimensions = [...this.data.dimensions];
      this.filters = [...this.data.filters];
      this.initialFilters = this.data.initialFilters;
      this.filters.forEach(f => {
        let filterConcat = f.fieldName + "**" + f.fieldValue;
        this.filtersForList.push(filterConcat);
      })
    }
  }

  selectFilterItem(field: any, val: string): void {
    this.filter = {
      fieldName: field.name,
      fieldValue: val,
      operator: '='
    }
    let index = this.filters.findIndex(f => f.fieldName == this.filter.fieldName && f.fieldValue == this.filter.fieldValue);
    if (index != -1) {
      this.removeFilter(index);
    } else {
      this.addFilter();
    }

  }

  addFilter() {
    this.filters.push(this.filter);
    let filterConcat = this.filter.fieldName + "**" + this.filter.fieldValue;
    this.filtersForList.push(filterConcat);
    this.filter = {
      fieldName: '',
      fieldValue: '',
      operator: ''
    }
  }

  removeFilter(index) {
    this.filters.splice(index, 1);
  }

  saveFilters() {
    this.dialogRef.close(this.filters);
  }

  clearAllFilter() {
    this.filters = [];
    this.filtersForList = [];
  }

  resetFilter() {
    this.filters = this.data.initialFilters.slice();
    this.filtersForList = [];
    this.filters.forEach(f => {
      let filterConcat = f.fieldName + "**" + f.fieldValue;
      this.filtersForList.push(filterConcat);
    })
  }

  clearDimensionFilter(dimension: any) {
    let f = this.filters.filter(f => { return f.fieldName != dimension.name });
    let fx = this.filtersForList.filter(fx => {
      if (!fx.includes(dimension.name)) {
        return fx
      }
    });
    this.filtersForList = fx;
    this.filters = f;
  }

}
