import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LDAPConfig } from 'src/app/models/requests/ldapConfig';
import { CoreService } from 'src/app/services/core/core.service';
import { LicenseService } from 'src/app/services/license/license.service';

@Component({
  selector: 'app-ldap-config',
  templateUrl: './ldap-config.component.html',
  styleUrls: ['./ldap-config.component.scss']
})
export class LdapConfigComponent implements OnInit {

  pageForm: UntypedFormGroup;
  config: LDAPConfig = {
    domainName: "",
    port: "",
    server: "",
    timeout: 3000
  }

  constructor(
    private licenseService: LicenseService,
    private coreService: CoreService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getLDAPConfig()

    this.pageForm = new UntypedFormGroup({
      domainName: new UntypedFormControl(this.config.domainName, [Validators.required]),
      port: new UntypedFormControl(this.config.port, [Validators.required]),
      server: new UntypedFormControl(this.config.server, [Validators.required]),
      timeout: new UntypedFormControl(this.config.domainName, [Validators.required]),
    });
  }

  private getLDAPConfig() {
    this.licenseService.getLDAPConfig().subscribe(result => {
      this.config = result;
    })
  }

  updateLDAP() {
    if (this.pageForm.valid) {
      this.licenseService.updateLDAP(this.config).subscribe(result => {
        this.coreService.showSuccessMessage(this.translate.instant("ErrorMessages.LDAPUpdated"));
      })
    } else {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.FillFields"));
    }

  }

}
