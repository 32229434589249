<app-custom-modal [subtitle]="'LanguageForm.SubTitle'" [title]="'LanguageForm.Title'"
    img="/assets/images/widgetSelection.png">
    <div body class="modal-body">
        <form [formGroup]="pageForm">
            <mat-form-field>
                <mat-label>{{ 'LanguageForm.Name' | translate }}</mat-label>
                <input [(ngModel)]="language.name" matInput formControlName="name" required>
            </mat-form-field>
            <br>
            <!-- <mat-form-field>
                <mat-label>{{ 'LanguageForm.Code' | translate }}</mat-label>
                <input [(ngModel)]="language.code" matInput formControlName="code" required>
            </mat-form-field>
            <br> -->

            <mat-form-field>
                <mat-label>{{ 'LanguageForm.Type' | translate }}</mat-label>

                <mat-select [(value)]="language.type" name="type" required>
                    <mat-option [value]="'Client'">Client</mat-option>
                    <mat-option [value]="'Server'">Server</mat-option>
                </mat-select>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'LanguageForm.Content' | translate }}</mat-label>
                <textarea [(ngModel)]="language.content" rows="15" matInput formControlName="content" required
                    id="json-text"></textarea>
            </mat-form-field>
            <br>

            <span style="font-size: small;color: green;"
                *ngIf="language.content.length>0&& validationResponse">{{"LanguageForm.ValidJson"|translate}}</span>
            <span style="font-size: small;color: red;"
                *ngIf="language.content.length>0&&!validationResponse">{{"LanguageForm.InValidJson"|translate}}</span>

            <!-- <div style="display: flex;align-items: center;justify-content: end;">
                
            </div> -->

        </form>

        <div class="action-footer" style="display: flex;align-items: center;justify-content: space-between;">
            <button (click)="beautifyJSON(language.content)" mat-stroked-button class="custom-primary-stroked-button"
                style="margin-left: 10px;">
                <mat-icon>repeat</mat-icon>
                <span> {{ 'LanguageForm.ValidateJson' | translate }}</span>
            </button>
            <button (click)="upsertLanguage()" mat-raised-button class="custom-primary-button"
                style="margin-left: 10px;">
                <mat-icon>save</mat-icon>
                <span> {{ 'Common.Save' | translate }}</span>
            </button>
            <!-- <button mat-button class="b-btn b-success" cdkFocusInitial>{{
                'Common.Create' | translate }}</button> -->
        </div>
    </div>
</app-custom-modal>