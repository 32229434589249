<app-modal-title title="{{'SystemParametersPage.UploadConfiguration'|translate}}"></app-modal-title>
<mat-form-field #deneme>
    <diV style="display: flex;justify-content: space-between;">
        <div>
            *{{ 'AppFormPage.Hint' | translate }}
            <img style="flex-basis: 50%;" (click)="fileUploader.click()"
                src="https://i.pinimg.com/originals/ab/bf/0e/abbf0e7180467fe9daa94a5314428040.gif" />
        </div>
        <div style="display: flex;flex-direction: column;">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{'SystemParametersPage.FileName'|translate}} : {{filename}}</mat-label>
                <input matInput readonly>
            </mat-form-field>
            <!-- <mat-label>File name: {{filename}}</mat-label> -->
            <input hidden #fileUploader (change)="changeFile($event)" type="file" />
            <button (click)="uploadFile()" style="margin-left: 10px;margin-top: 5px;" class="b-btn b-success" mat-button
                color="primary">{{'SystemParametersPage.Update'|translate}}</button>
        </div>
    </diV>
</mat-form-field>