<div class="dropdown-menu-container" [style.Height]="height.toString().includes('%')?height: height+'px'">
    <div class="error-icon" *ngIf="error.active">
        <svg matTooltip="Lütfen bir seçenek seçiniz" width="20px" height="20px" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 16.99V17M12 7V14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                stroke="red" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>
    <div style="padding: 5px;">
        <div class="search-area">
            <div *ngIf="!dropDownSettings.searchActive" class="area-title">{{dropDownSettings.name|translate}}</div>
            <Input *ngIf="dropDownSettings.searchActive" type="string" [placeholder]="dropDownSettings.placeholder|translate"
                (input)="searchData($event)" />
            <mat-icon *ngIf="dropDownSettings.searchActive" svgIcon="search"></mat-icon>
        </div>
    </div>
    <div class="menu-list">
        <ul *ngIf="!dropDownSettings?.imageCard" cdkDropList (cdkDropListDropped)="drop($event)">
            <!-- input varsa listenin en üstüne eklenir -->
            <div class="mini-action-area" *ngFor="let button of dropDownSettings?.topButtons">
                <div class="mini-action-button" (click)="button.eventFunction($event.target)">
                    <mat-icon [svgIcon]="button.icon"></mat-icon>
                    <small>{{button.title|translate}}</small>
                </div>

            </div>
            <ng-container *ngFor="let input of dropDownSettings?.input">
                <li [style.Height]="'35px'" *ngIf="input.visible">

                    <div class="li-title" *ngIf="input.inputType!=='button'">
                        <div [matTooltip]="input?.tooltip"> {{input.title|translate}}</div>
                    </div>
                    <input [style.Width]="input?.width?input.width:''" [id]="input?.inputId"
                        [value]="_initialData?.inputValue?_initialData?.inputValue:input.defaultValue"
                        *ngIf="input.inputType!=='select'&&input.inputType!=='toggle'&&input.inputType!=='button'&&input.inputType!=='button-toggle'&&input.visible"
                        class="menu-input" [type]="input.inputType"
                        (blur)="input.getValueFunction($event.target.value)" />

                    <select [style.Width]="input?.width?input.width:''" [id]="input?.inputId"
                        [disabled]="input.options.length<=0" *ngIf="input.inputType=='select'&&input.visible"
                        class="menu-input" (change)="input.getValueFunction($event.target.value)">
                        <option value="0">{{"Common.Select"|translate}}</option>
                        <option *ngFor="let opt of input.options" [value]="opt.value"
                            [selected]="_initialData ? _initialData[input.id]==opt.value:input.defaultValue==opt.value">
                            {{opt.key}}
                        </option>

                    </select>

                    <mat-slide-toggle [id]="input?.inputId" [checked]="input.defaultValue" [disabled]="input.disabled"
                        *ngIf="input.inputType=='toggle'&&input.visible"
                        (change)="input.getValueFunction($event)"></mat-slide-toggle>

                    <mat-button-toggle-group [value]="input.defaultValue" [style.Width]="input?.width?input.width:''"
                        class="custom-button-toggle" *ngIf="input.inputType=='button-toggle'&&input.visible"
                        [id]="input?.inputId" [name]="input?.inputId">
                        <mat-button-toggle (change)="input.getValueFunction($event.value)"
                            *ngFor="let opt of input.options" [value]="opt.value">{{opt.key}}</mat-button-toggle>
                    </mat-button-toggle-group>
                    <!-- <button *ngIf="input.inputType=='button'" mat-stroked-button  class="custom-primary-stroked-button"> {{input.title}}</button> -->
                </li>
            </ng-container>

            <li cdkDrag [cdkDragDisabled]="!dragActive" [style.Height]="item.subtitle?'60px':'35px'"
                *ngFor="let item of _filteredMenuData" [class.selected-item]="item.selected"
                [class.selected-item-no-filter]="disableIconFilter">
                <!-- öncesinde ikon varsa -->
                <div (click)="select(item)"
                    style="max-width: 70%;display: flex;align-items: center; flex-basis: 100%;height: 100%;">
                    <div *ngIf="item.icon" style="max-width: 15%;">
                        <img width="20" height="20" class="before-icon" [src]="'/assets/images/icons/'+item.icon+'.png'"
                            [matTooltip]="item.icon|translate" />
                    </div>
                    <div class="li-title" style="max-width: 85%;">
                        <div> {{item.title|translate}}</div>
                        <small *ngIf="item.subtitle">{{item.subtitle}}</small>
                    </div>
                </div>




                <!-- sonrasında ikon veya checkbox varsa -->
                <div *ngIf="dropDownSettings?.endIcons?.length>0||(dropDownSettings.iconMenuActive&&item?.menu2?.length>0&&!item.menu2Disable)||
                (dropDownSettings.iconMenuActive&&item?.menu?.length>0&&!item.selected)||checkIcon&&item.selected"
                    style="max-width: 30%;display: flex;flex-direction: row-reverse;">
               
                    <mat-icon *ngFor="let endicon of dropDownSettings.endIcons"
                        [style.Display]="endicon.enable?'':'none'" [svgIcon]="endicon.icon"
                        (click)="endicon.clickFunction(item)" [matTooltip]=" endicon.popover |translate"></mat-icon>



                    <!-- sonrasında checkbox ve ikon varsa -->
                    <img [class.selected-icon]="item.selectedMenuItem"
                        *ngIf="dropDownSettings.iconMenuActive&&item?.menu?.length>1&&!item.selected" width="20"
                        height="20" class="before-icon"
                        [src]="'/assets/images/icons/'+dropDownSettings.triggerMenuIcon.icon" [matMenuTriggerFor]="menu"
                        [matTooltip]="item.selectedMenuItem?item.selectedMenuItem:dropDownSettings.triggerMenuIcon.tooltip|translate" />

                    <img [class.selected-icon]="item.selectedMenuItem"
                        *ngIf="dropDownSettings.iconMenuActive&&item?.menu?.length==1&&item?.menu[0]==''&&!item.selected"
                        width="20" height="20" class="before-icon" (click)="iconMenuFunction(item,item.title)"
                        [src]="'/assets/images/icons/'+dropDownSettings.triggerMenuIcon.icon"
                        [matTooltip]="'WidgetFormPage.UseMeasurement'|translate" />



                    <mat-checkbox *ngIf="enableCheckBox"></mat-checkbox>
                    <img [class.selected-filter-icon]="item.toggle" (click)="toggleItem(item)"
                        *ngIf="checkIcon&&item.selected" width="20" height="20" class="before-icon"
                        [src]="'/assets/images/icons/'+dropDownSettings.checkIcon.icon"
                        [matTooltip]="dropDownSettings.checkIcon.tooltip|translate" />


                    <mat-menu class="custom-mat-menu" #menu="matMenu">
                        <button mat-menu-item *ngFor="let m of item.menu" (click)="iconMenuFunction(item,m)"
                            [class.selected-item]="item.selectedMenuItem==m"
                            [class.selected-item-no-filter]="disableIconFilter">{{m}}</button>
                    </mat-menu>

                    <mat-menu class="custom-mat-menu" #menu2="matMenu">
                        <button mat-menu-item *ngFor="let m of item.menu2;let i = index"
                            (click)="iconMenuFunction2(item,m)"
                            [class.selected-item]="item.selectedMenuItem2==m||item.selectedMenuItem2==i"
                            [class.selected-item-no-filter]="disableIconFilter">{{m}}</button>
                    </mat-menu>

                    <!-- groupby ikonu -->
                    <img [class.selected-filter-icon]="item.selectedMenuItem2"
                        *ngIf="dropDownSettings.iconMenuActive&&item?.menu2?.length>0&&!item.menu2Disable" width="20"
                        height="20" class="before-icon" [src]="'/assets/images/icons/groupBy.png'"
                        [matMenuTriggerFor]="menu2" [matTooltip]="dropDownSettings.triggerMenuIcon.tooltip|translate" />

                </div>




            </li>
        </ul>
        <div *ngIf="dropDownSettings.imageCard" class="menulistcard">
            <!-- içerikte img varsa -->
            <div [matTooltip]="item.title|translate" *ngFor="let item of _filteredMenuData" class="item-with-img"
                [class.selected-item-imgcard]="item.selected" (click)="select(item)">
                <img [src]="baseUrl+item.img" />
                <!-- <div class="card-title">
                    <div> {{item.title|translate}}</div>
                </div> -->
            </div>

        </div>

    </div>
</div>