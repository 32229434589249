<div class="bd-container">
    <div class="bd-element" *ngFor="let item of _elements">
        <span (click)="redirectPage(item)"> {{item.name|translate}}</span>
        <svg style="margin-left:0.3rem" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd" stroke="currentColor" stroke-width="1"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
        </svg>
    </div>
    <div class="bd-title">
        <span> {{lastelement.name|translate}}</span>
    </div>

</div>