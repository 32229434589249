import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DrawflowConnection, DrawflowExport, DrawflowModuleData, DrawflowNode } from 'drawflow';
import { ExecutionRule, Flow, FlowScriptResponse, FlowSession, FlowSriptParameter } from 'src/app/models/responses/flowScript';
import { FlowFormComponent } from '../flow-form/flow-form.component';
import { ScriptFormComponent } from '../script-form/script-form.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { FlowScriptService } from 'src/app/services/flow-scripts/flow-script.service';
import { FlowLineRuleFormComponent } from '../flow-line-rule-form/flow-line-rule-form.component';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FlowNodeParameterFormComponent } from '../flow-node-parameter-form/flow-node-parameter-form.component';
import { SweetAlertService } from 'src/app/services/sweet-alert/sweet-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'src/app/services/core/core.service';
import { FlowLogComponent } from '../flow-log/flow-log.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { ConnectorService } from 'src/app/services/connector/connector.service';
import { LogComponent } from 'src/app/components/modals/log/log.component';

@Component({
  selector: 'app-flow-designer',
  templateUrl: './flow-designer.component.html',
  styleUrls: ['./flow-designer.component.scss']
})
export class FlowDesignerComponent implements OnInit, OnDestroy {

  scriptList: FlowScriptResponse[] = []
  filteredScriptList: FlowScriptResponse[] = []
  changedData: boolean = false

  showScriptArea: boolean = true
  currentUserId: string = this.authService.getUserId()
  currentUserRoles: string = this.authService.getRoles()
  executionInterval: any
  loaderInterval: any
  currentSessionId: string = ''

  ruleList: { rule: ExecutionRule, input_id: string, output_id: string, ruleid: number }[] = []
  selectedFlowId: string = ''
  updetedScriptList: FlowScriptResponse[] = []
  flowMetaData: string = ''
  runningFlow: boolean = false
  loaderValue: number = 0
  scriptPackages: string[] = []
  selectedPackages: string[] = []

  executionLogs: FlowSession[] = []
  allExecutionLogs: FlowSession[] = []

  flowObject: Flow = {
    flowId: '',
    name: '',
    description: '',
    bussionConnectorId: '',
    type: 0,//0: Private 1: Public
    version: 0,
    owner: '',
    ownerId: '',
    updateDate: '',
    createDate: '',
    updatedBy: '',
    updateScriptsBeforeRun: 0,
    duration: 0,
    scripts: [],
    flowMetaData: ''
  }

  flowScripts: FlowScriptResponse[] = []

  cardInfo: {
    img: string,
    smallText1: string,
    smallText2: string,
    title: string,
    desc: string,
    icons: { function: Function, icon: string, condition?: string }[]
  } =
    {
      img: '/assets/images/python.svg',
      smallText1: 'createDate',
      smallText2: 'owner',
      title: 'name',
      desc: 'description',
      icons: [
        // { icon: 'delete-box', function: (e) => this.deleteConfirm(e), condition: 'secret' },
        { icon: 'edit-box', function: (e) => this.openScriptModal(e), condition: 'all' },
      ]
    }





  nodeList: { item: any, pos_x: number, pos_y: number, nodeId: number }[] = []
  selecteddragelement: HTMLElement
  runlock: boolean = false

  constructor(private dialogService: ModalService, private flowScriptService: FlowScriptService, private coreService: CoreService,
    private activerouter: ActivatedRoute, private location: Location, private router: Router, private authService: AuthService, private sweetalertService: SweetAlertService, private translate: TranslateService,
    private connectorService: ConnectorService) { }

  ngOnInit(): void {
    this.getScripts();

    var locationstate = this.location.getState() as { flow: any };

    if (this.activerouter.snapshot.paramMap.get('flowId'))
      this.selectedFlowId = this.activerouter.snapshot.paramMap.get('flowId')

    if (locationstate?.flow) {
      // this.flowObject = history.state
      this.flowObject = locationstate?.flow
      this.flowMetaData = this.flowObject.flowMetaData
      this.flowScripts = this.flowObject.scripts

      this.setNodeListForUpdate()
      this.getExecutionLogAndChangeBg()


    } else {
      this.getSelectedFlowData()
    }

    this.getScriptPackages()



  }

  getScriptPackages() {
    this.flowScriptService.GetPythonPackages().subscribe(res => {
      this.scriptPackages = res
    })
  }

  ngOnDestroy(): void {
    clearInterval(this.executionInterval)
    clearInterval(this.loaderInterval)

  }



  executionLogTime(selectedFlow: Flow, sessionId: string) {
    var interval = 10
    if (selectedFlow.duration == 0) {
      interval = 10
    } else if (selectedFlow.duration > 1200) {
      interval = 1 * 60
    }


    this.executionInterval = setInterval(() => this.executedNodeChangeBg(sessionId),
      interval * 1000);

    this.loaderInterval = setInterval(() => this.loaderIncrement(selectedFlow), 1000);
  }

  loaderIncrement(selectedFlow: Flow) {
    var incrementValue = 100 / (selectedFlow.duration);


    this.loaderValue = this.loaderValue + incrementValue
  }


  lastRunnedObject: FlowScriptResponse
  executedNodeChangeBg(sessionId: string) {

    this.flowScriptService.getExecutionLogForFlow(sessionId, 'session').subscribe((res: FlowSession[]) => {
      var element
      this.runlock = true
      var selectedSessionList = res.filter(x => x.sessionId == sessionId)
      var executedNodes = selectedSessionList.sort((a, b) => b.sequenceId - a.sequenceId);
      this.currentSessionId = sessionId
      var lastIndex = executedNodes.length - 1




      console.log(this.flowObject)

      if (executedNodes.length > 0 && executedNodes[0].script.nextStep == -1 && executedNodes[0].state == 0) {
        //flow sona gelmişse durdurur
        clearInterval(this.executionInterval)
        clearInterval(this.loaderInterval)

        element = document.getElementById(executedNodes[0].script.nodeId)
        this.runlock = false
      } else if (executedNodes.length > 0 && executedNodes[0]?.script?.nextStep == -1 && executedNodes[0].state == 1) {
        // flow devam ediyorsa
        element = document.getElementById(executedNodes[0].script.nodeId)
      }
      else {
        element = document.getElementById(this.flowObject.scripts[0].nodeId)
      }

      //yeşil
      if (element) {
        var currentRunnedObject = this.flowObject.scripts.find(x => x.nodeId == element.id)

        if (currentRunnedObject) {
          element = document.getElementById(currentRunnedObject.nodeId)
          element.style.setProperty('background', '#D3F6DB', 'important');
          element.classList.add('colored-node')
        }

        if (this.lastRunnedObject) {
          var calisacak = this.lastRunnedObject?.nextExecutions?.find(x => x.scriptId == currentRunnedObject.nodeId)?.exitCode

          this.lastRunnedObject?.nextExecutions?.map(item => {
            if (item?.exitCode == calisacak) {
              var el = document.getElementById(item.scriptId)
              el.style.setProperty('background', '#D3F6DB', 'important');
              el.classList.add('colored-node')
            }
          })
        } else {
          element.style.setProperty('background', '#D3F6DB', 'important');
          element.classList.add('colored-node')
        }


        if (currentRunnedObject && currentRunnedObject.parameters.some(x => x.key == 'ConnectorId')) {
          this.addConnectorLogIcon(element, currentRunnedObject.parameters.find(x => x.key == 'ConnectorId').value)
        }



        this.lastRunnedObject = currentRunnedObject
      }

      executedNodes.map(node => {
        var selectednode = document.getElementById(node.script.nodeId)
        if (node.state == 0) {
          if (selectednode.getElementsByClassName('conectorlogeye')) {
            selectednode.getElementsByClassName('conectorlogeye')[0]?.remove()
          }

          if (node.executionResult == 0) {
            //mavi
            document.getElementById(node.script.nodeId).style.setProperty('background', '#92D5E6', 'important');
            document.getElementById(node.script.nodeId).classList.add('colored-node')
          } else {
            //kırmızı
            document.getElementById(node.script.nodeId).style.setProperty('background', '#FFA69E', 'important');
            document.getElementById(node.script.nodeId).classList.add('colored-node')

          }
        }

      })

    })
  }

  removeConnectorIcon(element: any) {
    var removeElement = element.getElementById('conectorlogeye')
    if (removeElement)
      removeElement.remove()
  }

  addConnectorLogIcon(element: any, connectorId: string) {
    var connectorLog = document.createElement('div')
    connectorLog.id = "conectorlogeye"
    connectorLog.classList.add("conectorlogeye")


    if (Array.from(element.getElementsByClassName('conectorlogeye')).length <= 0) {
      connectorLog.innerHTML = '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M22 15C22 18.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22" stroke="#5E6DA6" stroke-width="1.5" stroke-linecap="round"></path> <path d="M10 2C6.22876 2 4.34315 2 3.17157 3.17157C2 4.34315 2 5.22876 2 9" stroke="#5E6DA6" stroke-width="1.5" stroke-linecap="round"></path> <path d="M12 7C9.07268 7 7.08037 8.56222 5.89242 9.94021C5.29747 10.6303 5 10.9754 5 12C5 13.0246 5.29748 13.3697 5.89243 14.0598C7.08038 15.4378 9.07268 17 12 17C14.9273 17 16.9196 15.4378 18.1076 14.0598C18.7025 13.3697 19 13.0246 19 12C19 10.9754 18.7025 10.6303 18.1076 9.94021C17.5723 9.31933 16.8738 8.66106 16 8.12513" stroke="#5E6DA6" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="12" cy="12" r="2" stroke="#5E6DA6" stroke-width="1.5"></circle> <path d="M10 22C9.65081 22 9.31779 22 9 21.9991M2 15C2 18.7712 2 19.6569 3.17157 20.8284C3.82475 21.4816 4.69989 21.7706 6 21.8985" stroke="#5E6DA6" stroke-width="1.5" stroke-linecap="round"></path> <path d="M14 2C14.3492 2 14.6822 2 15 2.00093M22 9C22 5.22876 22 4.34315 20.8284 3.17157C20.1752 2.51839 19.3001 2.22937 18 2.10149" stroke="#5E6DA6" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>'
      element.getElementsByClassName('action-area')[0].appendChild(connectorLog)
      connectorLog.onclick = () => {
        this.openTaskLogs(connectorId)

      }
    }

  }

  private openTaskLogs(taskId: string) {
    let data = {
      taskId: taskId,
      visibleButton: false

    }
    this.dialogService.openModal(data, LogComponent, '600px', '600px', 'overflow-hidden', 'center').subscribe(status => {
      // this.getBussionConnectorTasks();
    });
  }

  setNodeListForUpdate() {
    try {
      const jsonMeta = JSON.parse(this.flowMetaData)?.flow?.drawflow?.Home?.data;

      this.nodeList = []; // Mevcut listeyi temizle

      if (jsonMeta) {
        // Ara bir dizi oluşturup atama yap
        this.nodeList = Object.keys(jsonMeta).map(item => ({
          item: jsonMeta[item].data,
          pos_x: jsonMeta[item].pos_x,
          pos_y: jsonMeta[item].pos_y,
          nodeId: jsonMeta[item].id
        }));
      }
    } catch (error) {
      console.error("setNodeListForUpdate sırasında hata oluştu:", error);
    }
  }


  createConnectionForEdit() {
    var metadata = JSON.parse(this.flowObject.flowMetaData)
    if (metadata.ruleList) {
      this.ruleList = metadata.ruleList
      this.ruleList = this.ruleList.filter(x => !x.ruleid).length > 0 ? this.ruleList.map((item, key) => {
        return { ...item, ruleid: key + 1 }
      }) : this.ruleList
    }


    this.ruleList.map(item => {
      let label1 = document.querySelector('.connection.node_in_node-' + item.input_id + '.node_out_node-' + item.output_id + '.output_1.input_1');


      this.addLabelText(label1, item.rule.name + ' (exitCode=' + item.rule.exitCode + ')');
    })



  }

  getSelectedFlowData() {
    if (this.selectedFlowId)
      this.flowScriptService.getFlowById(this.selectedFlowId).subscribe(res => {
        if (res) {
          this.flowObject = res
          this.flowMetaData = this.flowObject.flowMetaData;
          this.flowScripts = this.flowObject.scripts
          this.setNodeListForUpdate()
          this.getExecutionLogAndChangeBg()
        }
      })
  }



  openScriptModal(selectedElement?: FlowScriptResponse) {
    var data = selectedElement ? selectedElement : null
    this.dialogService.openModal({
      script: data,
      type: data ? 'fromscriptinsert' : 'fromscriptedit',
      deleteFunction: (e) => this.deleteConfirm(e)
    }, ScriptFormComponent, 'auto', 'auto', 'overflow-hidden', 'center').subscribe(res => {
      if (res) {
        this.getScripts()
        this.getScriptPackages()

      }
    })
  }

  deleteConfirm(selectedElement?: FlowScriptResponse) {
    this.sweetalertService.open(
      this.translate.instant("ActionDialogComponent.Message"),
      this.translate.instant(selectedElement.name + this.translate.instant('Common.DeleteConfirmText')),
      'question',
      this.translate.instant("Common.Done"),
      (res) => {
        if (res.isConfirmed) {
          this.deleteScript(selectedElement.scriptId);
          this.dialogService.dissmissAll()
        }
      },
      true
    )
  }

  deleteScript(scriptId: string) {
    this.flowScriptService.deleteFlowScript(scriptId).subscribe(res => {
      if (res.result) {
        this.coreService.showSuccessMessage(res.message)
        this.getScripts();
      }

    })
  }



  dragStarted(event) {
    this.selecteddragelement = event.source.element.nativeElement.getBoundingClientRect()
    event.source.element.nativeElement.style.position = 'absolute'

  }

  getScripts() {
    this.flowScriptService.getFlowScripts().subscribe(res => {
      if (res) {
        this.scriptList = res
        this.filteredScriptList = res
      }
    })
  }

  filterScripts(text: any, filterkey?: string) {
    if (!filterkey) {
      if (text == '')
        this.filteredScriptList = this.scriptList
      else
        this.filteredScriptList = this.scriptList.filter(x => x.name.toLowerCase().includes(text.toLowerCase()) || x.description.toLowerCase().includes(text.toLowerCase()))
    } else {
      if (text.length > 0) {
        this.filteredScriptList = this.scriptList.filter(script =>
          text.some(txt =>
            script.package.includes(txt)
          )
        );
      } else {
        this.selectedPackages = []
        this.filteredScriptList = this.scriptList
      }
    }
  }



  dragEnd(event: any, item: FlowScriptResponse) {
    if (event.event.target.className == 'drawflow') {
      this.nodeList = [...this.nodeList, { item: item, pos_x: event.dropPoint.x, pos_y: event.dropPoint.y, nodeId: this.nodeList.length <= 0 ? 1 : this.nodeList[this.nodeList.length - 1].nodeId + 1 }]
    }
    document.getElementById(event.source.element.nativeElement.id).style.position = 'relative'
    event.source._dragRef.reset();


  }


  openFlowModal(selectedElement: Flow, openModal: boolean) {
    var data = { flow: selectedElement ? selectedElement : null, deleteFunction: null }
    if (openModal) {
      this.dialogService.openModal(data, FlowFormComponent, '50%', '50%', 'overflow-hidden', 'center').subscribe(res => {
        if (res) {
          this.flowScriptService.getFlows().subscribe((response: Flow[]) => {
            if (response) {
              const navigationExtras: NavigationExtras = {
                state: { flow: response.find(x => x.flowId == res), flowId: response.find(x => x.flowId == res).flowId },
                skipLocationChange: false,
              };
              if (this.router.url !== `/flow/flow-designer/${res}`)
                this.router.navigate(['/flow/flow-designer', res], navigationExtras).then(res => {
                  window.location.reload()
                })

            }
          })
        }
      })
    } else {
      if (selectedElement.flowId)
        this.flowScriptService.upsertFlow(selectedElement).subscribe(res => {
          if (res) {
            this.getSelectedFlowData()
          }
        })
    }
  }

  nodeRemoved(selectedId: number) {
    this.ruleList = this.ruleList.filter(x => x.input_id !== selectedId.toString() && x.output_id !== selectedId.toString())

  }

  connectionRemoved(
    selectedConnection: {
      output_id: string,
      input_id: string,
      output_class: string,
      input_class: string
    }
  ) {
    var deletedİndex = this.ruleList.findIndex(x => x.input_id == selectedConnection.input_id && x.output_id == selectedConnection.output_id)
    if (deletedİndex > -1) { // only splice array when item is found
      this.ruleList.splice(deletedİndex, 1); // 2nd parameter means remove one item only
    }


  }

  createCardInfo(item: FlowScriptResponse) {
    if (this.currentUserId == item.ownerId || this.currentUserRoles.includes('Admin')) {
      if (item.icon) {
        this.cardInfo.img = environment.baseUrl + item.icon
      }
      return this.cardInfo
    }
    else {
      var filtered = { ...this.cardInfo, icons: this.cardInfo.icons.filter(x => x.condition == 'all') }
      if (item.icon) {
        filtered.img = environment.baseUrl + item.icon
      }
      return filtered;
    }
  }

  clearFunction() {
    //burada clear ediyoruz renkleri sileceğiz
    // this.ruleList = []
    Array.from(document.getElementsByClassName('colored-node')).forEach(item => {
      (item as HTMLElement).style.setProperty('background', 'white', 'important');
    })
  }


  saveFlow(flow: DrawflowExport | any, openmodal: boolean = true) {

    var scriptList: FlowScriptResponse[] = []
    var firstElementList: FlowScriptResponse[] = []
    var flowData = flow.drawflow.Home.data
    var connectionCount = 0

    //üstüne kural tanımlanmamış line varsa hata verecek
    Object.keys(flowData).map(item => {
      connectionCount = connectionCount + flowData[item].outputs.output_1.connections.length
    })

    if (connectionCount !== this.ruleList.length) {
      this.sweetalertService.open(
        this.translate.instant("FlowPage.NoLineRule"),
        this.translate.instant("FlowPage.NoLineRuleText"),
        'warning',
        this.translate.instant("Common.Done"),
        (res) => { },
        false
      )
      return;
    }

    if (Object.keys(flowData)) {
      Object.keys(flowData).map(item => {
        var rules = this.ruleList.filter(x => x.output_id == flowData[item].id.toString())
        if (this.updetedScriptList.some(x => x.nodeId == flowData[item].name)) {
          flowData[item].data = this.updetedScriptList.find(x => x.nodeId == flowData[item].name)
        }

        flowData[item].data.nextExecutions = rules.map(ru => {
          ru.rule.scriptId = 'node-' + ru.input_id
          return ru.rule
        })



        //listede akışın ilk elemanını bulup başa atıyoruz
        if (flowData[item].inputs.input_1.connections.length <= 0) {
          firstElementList.push({ ...flowData[item].data, nodeId: 'node-' + flowData[item].id, dependency: [] })

          // var addedIndex = firstElementList.indexOf(flowData[item].data)
          // flowData[item].name = 'sequence-' + (addedIndex+1)

        }
        else {
          scriptList.push({
            ...flowData[item].data, nodeId: 'node-' + flowData[item].id, dependency: flowData[item].inputs.input_1.connections.map(con => {
              return 'node-' + con.node
            }) || []
          })

          // var addedIndex = scriptList.indexOf(flowData[item].data)
          // console.log(firstElementList.length,addedIndex)
          // flowData[item].name = 'sequence-' + (firstElementList.length + addedIndex)
        }

      })
      // console.log('scriptListilk', scriptList)
      scriptList = firstElementList.concat(scriptList);
      // scriptList.unshift(firstElement);


      this.flowObject.scripts = scriptList

    }


    var metaData = {
      flow: flow,
      ruleList: this.ruleList
    }

    this.flowObject.flowMetaData = JSON.stringify(metaData)

    this.openFlowModal(this.flowObject, openmodal)


  }

  addLabelText(bgPath, labelText) {
    if (bgPath) {
      if (bgPath.getElementsByTagName('text').length > 0) {
        Array.from(bgPath.getElementsByTagName('text')).forEach(function (element: HTMLElement) {
          element.remove();
        });
      }
      const newid = [bgPath.classList].join().replace(/\s/g, '');
      bgPath.childNodes[0].id = newid;
      let textElem = document.createElementNS(bgPath.namespaceURI, "text");
      let textElemPath = document.createElementNS(bgPath.namespaceURI, "textPath");
      textElemPath.setAttribute("href", `#${newid}`);
      textElemPath.setAttribute("text-anchor", "middle");
      textElemPath.setAttribute("startOffset", "50%");
      textElemPath.classList.add("label-text");
      textElemPath.textContent = labelText;
      textElem.setAttribute("font-size", "15"); // Varsayılan 12px
      textElem.setAttribute("fill", "#black");
      textElem.setAttribute("y", "20");
      textElem.appendChild(textElemPath);

      bgPath.appendChild(textElem);
    }
  }

  connectionSelected(selectedelement: {
    selectedConnection: {
      output_id: string,
      input_id: string,
      output_class: string,
      input_class: string
    }, flow: DrawflowExport,
    conName?: string
  }) {

    var selectedRule = this.ruleList.find(x => x.input_id == selectedelement.selectedConnection.input_id && x.output_id == selectedelement.selectedConnection.output_id) || null
    if (selectedelement?.conName == 'default') {
      // ruleList'i ruleid'ye göre sırala
      this.ruleList.sort((a, b) => a.ruleid - b.ruleid);

      // En son sıradaki öğeyi al
      const lastRuleId = this.ruleList[this.ruleList.length - 1]?.ruleid || 0;
      var newExecRule: ExecutionRule = {
        exitCode: 0,
        name: 'rule-' + (lastRuleId + 1),
        scriptId: selectedelement.flow.drawflow.Home.data[selectedelement.selectedConnection.input_id].data.scriptId
      }
      this.addNewRule(newExecRule, selectedelement.selectedConnection)

    } else {

      this.dialogService.openModal({ selectedRule: selectedRule, selectedConnection: selectedelement.selectedConnection, ruleList: this.ruleList }, FlowLineRuleFormComponent, '30%', '20%', 'responsive-modal', 'center').subscribe((res: ExecutionRule) => {
        if (res) {
          res.scriptId = selectedelement.flow.drawflow.Home.data[selectedelement.selectedConnection.input_id].data.scriptId

          if (selectedRule)
            this.ruleList = this.ruleList.filter(x => !(x.input_id == selectedRule.input_id && x.output_id == selectedRule.output_id))


          this.addNewRule(res, selectedelement.selectedConnection)

        }
      })
    }

  }

  addNewRule(newExecutionRule: ExecutionRule, selectedConnection: {
    output_id: string,
    input_id: string,
    output_class: string,
    input_class: string
  }) {
    this.ruleList.push({ rule: newExecutionRule, input_id: selectedConnection.input_id, output_id: selectedConnection.output_id, ruleid: this.ruleList.length > 0 ? this.ruleList[this.ruleList.length - 1].ruleid + 1 : 0 })

    let label1 = document.querySelector('.connection.node_in_node-' + selectedConnection.input_id + '.node_out_node-' + selectedConnection.output_id + '.output_1.input_1');
    this.addLabelText(label1, newExecutionRule.name + ' (exitCode=' + newExecutionRule.exitCode + ')');
  }



  nodeSettingSelected(data: { selectedNode: DrawflowNode, selectedNodeId: string, flowData: DrawflowModuleData, type: string, Nodefunction?: Function, drawFlowExport: DrawflowExport }) {

    if (data.type.includes('parameter')) {
      if (data.selectedNode.data.parameters.length <= 0) {
        this.sweetalertService.open(
          this.translate.instant("FlowPage.NoParameters"),
          this.translate.instant("FlowPage.NoParametersText"),
          'warning',
          this.translate.instant("Common.Done"),
          (res) => { },
          false
        )
      } else {

        var newData = this.updetedScriptList.find(x => x.nodeId == data.selectedNodeId) ?? data.selectedNode.data;

        this.dialogService.openModal(newData, FlowNodeParameterFormComponent, 'auto', '30%', 'padding-0-modal', 'center').subscribe(res => {
          if (res) {
            var changedobj = this.flowScripts.find(x => x.nodeId == data.selectedNodeId)
            changedobj = {
              ...changedobj, parameters: changedobj.parameters.map(item => {
                return { ...item, value: res[item.key] }
              })
            };

            if (this.updetedScriptList.some(x => x.nodeId == newData.nodeId)) {
              this.updetedScriptList = this.updetedScriptList.filter(x => x.nodeId !== newData.nodeId)
            }
            this.updetedScriptList.push(changedobj)

            var savedata = { drawflow: { Home: { data: data.flowData } } }
            this.saveFlow(savedata, false)

          }
        })
      }
    } else {
      this.dialogService.openModal({ script: data.selectedNode.data, type: 'fromflow' }, ScriptFormComponent, 'auto', 'auto', 'overflow-hidden', 'center').subscribe((res: FlowScriptResponse) => {
        if (res) {
          //burada flowdaki script set ediliyor

          data.selectedNode.data = res
          document.querySelector('#' + data.selectedNodeId + ' .drawflow_content_node' + ' .bottom-title').innerHTML = res.name
          document.querySelector('#' + data.selectedNodeId + ' .drawflow_content_node' + ' .small-text').innerHTML = res.description

          this.nodeList.find(x => 'node-' + x.nodeId == data.selectedNodeId).item = res
          Object.keys(data.flowData).map(item => {
            if (data.flowData[item].id == data.selectedNodeId) {
              data.flowData[item].data = res
            }
          })
          var htmldiv = document.querySelector('#' + data.selectedNodeId + ' .drawflow_content_node .custom-card-container')
          data.Nodefunction({ html: htmldiv, id: data.selectedNodeId })
          var savedata = { drawflow: { Home: { data: data.flowData } } }
          this.saveFlow(savedata, false)
        }

      })
    }
  }

  runFlow(e) {
    if (this.flowObject.flowId) {
      this.lastRunnedObject = null
      Array.from(document.getElementsByClassName('colored-node')).forEach(item => {
        (item as HTMLElement).style.setProperty('background', 'white', 'important');
      })
      this.flowScriptService.runFlow(this.flowObject.flowId, '').subscribe(res => {
        if (res.result) {
          this.coreService.showSuccessMessage(this.translate.instant("FlowPage.RunnedFlow"))
          this.executionLogTime(this.flowObject, res.message)
          this.executedNodeChangeBg(res.message)
        }
      })
    }
  }

  stopFlow(taskId: string) {

    this.connectorService.stopTask(this.currentSessionId, this.flowObject.bussionConnectorId).subscribe(res => {
      this.runlock = false
      clearInterval(this.executionInterval)
      clearInterval(this.loaderInterval)
    })


  }

  getExecutionLogAndChangeBg() {
    if (this.flowObject.flowId)
      this.flowScriptService.getExecutionLogForFlow(this.flowObject.flowId, 'flow').subscribe((res) => {
        this.executionLogs = res

        var selectedSessionList = res.filter(x => x.sessionId == this.executionLogs[this.executionLogs.length - 1].sessionId)
        var executedNodes = selectedSessionList.sort((a, b) => b.sequenceId - a.sequenceId);

        if (executedNodes.length > 0 && executedNodes[0].script.nextStep !== -1 || executedNodes[0].state == 1) {
          this.runlock = true
          this.executionLogTime(this.flowObject, this.executionLogs[this.executionLogs.length - 1].sessionId)
          this.executedNodeChangeBg(this.executionLogs[this.executionLogs.length - 1].sessionId)
        } else {
          this.runlock = false
        }
      })
  }



  getExecutionLog(openmodal: boolean = true) {
    if (this.flowObject.flowId)
      this.flowScriptService.getExecutionLogForFlow(this.flowObject.flowId, 'flow').subscribe((res) => {
        this.allExecutionLogs = res
        this.executionLogs = res

        if (res.length > 0 && openmodal)
          this.dialogService.openModal({ session: this.allExecutionLogs, flow: this.flowObject }, FlowLogComponent, '100%', '100%', 'overflow-hidden', 'center').subscribe(res => {

          })
        else
          this.coreService.showFormFillError(this.translate.instant("FlowPage.NoSessionText"))
      })
  }



}
