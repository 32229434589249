<div class="designer-container">

    <!-- <app-bread-crumb [elements]="routeList"></app-bread-crumb> -->
    <div
        style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;height: 5%;margin-bottom: 10px;">
        <h2 style="margin-bottom:0;color:rgb(98, 115, 174)">{{dashboard.title}}</h2>
        <div class="button-group">

            <mat-slide-toggle *ngIf="visibletoggle" [class.custom-active-toggle]="options.draggable.enabled"
                (change)="DragModeChange()" [checked]="this.options?.draggable?.enabled"
                labelPosition="before">{{'DashboardDesignerPage.EditMode'|translate}}</mat-slide-toggle>


            <button (click)="openAvailableWidgetScreen()" mat-stroked-button class="custom-primary-stroked-button">
                {{ 'DashboardDesignerPage.AvailableWidget' | translate }}
            </button>

            <button (click)="openWidgetForm({widget: null, type: 'create'})" mat-raised-button
                class="custom-primary-button button-md w-100 mt-10">
                <mat-icon>add</mat-icon>
                <span>{{'DashboardDesignerPage.AddNewWidget'|translate}}</span>
            </button>

            <button (click)="openSaveDashboardModal()" mat-raised-button class="custom-primary-button">
                <mat-icon>save</mat-icon>
                <!-- <span> {{(this.data?.type=='create' ?'Common.Save' : 'Common.Save' )|translate }}</span> -->
            </button>

            <button [matMenuTriggerFor]="gridType" mat-raised-button class="custom-primary-button">
                <mat-icon svgIcon="scale_screen" style="transform: scale(0.7);"></mat-icon>
            </button>
            <!-- <button mat-button class="button" (click)="openWidgetForm({widget: null, type: 'create'})">{{
                'DashboardDesignerPage.AddNewWidget' | translate }}</button>

            <button mat-button class="button" (click)="openAvailableWidgetScreen()">
                {{ 'DashboardDesignerPage.AvailableWidget' | translate }}</button>

            <button (click)="openSaveDashboardModal($event)" mat-button class="button" cdkFocusInitial>
                {{(this.data?.type=='create' ?'Common.Save' : 'Common.Save' )|translate }}
            </button>


            <button class="button" mat-button [matMenuTriggerFor]="gridType"
                style="display: flex;align-items: center;justify-content: center;min-width: auto;">
                <mat-icon svgIcon="scale_screen"></mat-icon>
            </button> -->

        </div>
    </div>




    <div *ngIf="dashboardWidgets.length>0"
        style="width: 100%; height: calc(90% - 10px) !important;overflow: scroll !important;">
        <gridster [options]="options">
            <gridster-item [item]="item" *ngFor="let item of dashboardWidgets">
                <app-widget-preview [showZoom]="true" [showShare]="true" [widget]="item" [showEdit]="true"
                    [showDelete]="true" (deleteWidget)="removeWidget($event)" (expandEvent)="expandWidget($event)"
                    (shareEvent)="openShareForm($event)" (openForm)="openWidgetForm($event)"
                    [selectedDashboard]="dashboard">
                </app-widget-preview>
            </gridster-item>
        </gridster>

    </div>
    <div style="width: 100%; height: 70% !important;display: flex;align-items: center;justify-content: center;flex-direction: column;"
        *ngIf="dashboardWidgets.length<=0&&!loadingWidgets">
        <img width="40%" src="/assets/images/design.png" />

        <p style="color:rgb(134,153,217);font-weight: bold;font-size: 1rem;"> {{ 'DashboardDesignerPage.Paragraph' |
            translate }} </p>
    </div>
</div>
<div class="loading-overlay" *ngIf="loadingWidgets">
    <div class="loading-spinner"></div>
</div>

<!-- </mat-dialog-content> -->


<mat-menu #gridType="matMenu">
    <span [ngClass]="{'selectedmenuitem': this.dashboard?.layout=='Fit'}" (click)="changeGrid('Fit')" mat-menu-item>{{
        'MainPage.FitToScreen' | translate
        }}</span>
    <span [ngClass]="{'selectedmenuitem': this.dashboard?.layout=='Fixed'}" mat-menu-item
        (click)="changeGrid('Fixed')">{{ 'MainPage.FullScreen' | translate }}</span>

    <span [ngClass]="{'selectedmenuitem': this.dashboard?.layout=='ScrollVertical'}" mat-menu-item
        (click)="changeGrid('ScrollVertical')">
        {{ 'MainPage.ScrollVertical' |translate }}
    </span>

    <span [ngClass]="{'selectedmenuitem': this.dashboard?.layout=='VerticalFixed'}" mat-menu-item
        (click)="changeGrid('VerticalFixed')">{{ 'MainPage.VerticalFixed' | translate }}</span>

    <span [ngClass]="{'selectedmenuitem': this.dashboard?.layout=='ScrollHorizontal'}" mat-menu-item
        (click)="changeGrid('ScrollHorizontal')">{{ 'MainPage.ScrollHorizontal' | translate }}</span>

    <span [ngClass]="{'selectedmenuitem': this.dashboard?.layout=='HorizontalFixed'}" mat-menu-item
        (click)="changeGrid('HorizontalFixed')">{{ 'MainPage.HorizontalFixed' | translate }}</span>



</mat-menu>