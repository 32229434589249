<app-title [title]="title" *ngIf="dragable"
    [buttonTitle]="!dragEnable?('Common.DragEnable'|translate):('Common.DragDisable'|translate)"
    (btnEvent)="openCloseDrag()"></app-title>
<app-title [title]="title" *ngIf="!dragable"></app-title>

<mat-form-field>
    <input matInput type="text" placeholder="Search" (keyup)="applyFilter($event.target.value)">
</mat-form-field>
<mat-selection-list cdkDropList (selectionChange)="changeselectedListFunc($event)" [compareWith]="compareFunction"
    (cdkDropListDropped)="drop($event)">
    <mat-list-option [selected]="item.selected" *ngFor="let item of paginatedData" [value]="item[keys.valueKey]" cdkDrag
        [cdkDragDisabled]="!dragEnable">
        {{item[keys.titleKey]}}
    </mat-list-option>
</mat-selection-list>
<!-- <br> -->

<div class="pagination">
    <button class="pagination__button" [disabled]="currentPage === 1" (click)="previousPage()">«</button>
    <span class="pagination__page-number">{{ currentPage }}</span>
    <button class="pagination__button" [disabled]="currentPage === totalPage" (click)="nextPage()">»</button>
</div>