<div class="card">
    <div class="additional">
      <div class="user-card">
        <div class="level center">
          {{item.owner}}
        </div>
        <div class="points center">
          {{item.creationDate}}
        </div>
        <img  class="center" width="50px" height="50px" src="{{baseUrl}}{{item?.icon}}" onError="this.src ='/assets/images/defaultIcon.png'" />
      </div>
      <div class="more-info">
        <h1>{{item.name}}</h1>
        <div class="coords">
          <!-- <span>Group Name</span>
          <span>Joined January 2019</span> -->
        </div>
        <div class="coords">
          <!-- <span>Position/Role</span>
          <span>City, Country</span> -->
        </div>
        <div class="stats">
          <div>
            <div class="title">Awards</div>
            <i class="fa fa-trophy"></i>
            <div class="value">2</div>
          </div>
          <div>
            <div class="title">Matches</div>
            <i class="fa fa-gamepad"></i>
            <div class="value">27</div>
          </div>
          <div>
            <div class="title">Pals</div>
            <i class="fa fa-group"></i>
            <div class="value">123</div>
          </div>
          <div>
            <div class="title">Coffee</div>
            <i class="fa fa-coffee"></i>
            <div class="value infinity">∞</div>
          </div>
        </div>
      </div>
    </div>
    <div class="general">
      <h1 *ngIf="item.name">{{item.name}}</h1>
      <h1 style="overflow: hidden;" *ngIf="item.title">{{item.title}}</h1>
      <p>{{item.description}}</p>
    </div>
  </div>
