import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WidgetPreview } from 'src/app/models/responses/widgetLibraryResponse';
import { IndexFormComponent } from 'src/app/pages/manage/datastore/index-form/index-form.component';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-dashboard-performance-info',
  templateUrl: './dashboard-performance-info.component.html',
  styleUrls: ['./dashboard-performance-info.component.scss']
})
export class DashboardPerformanceInfoComponent implements OnInit {
  list: { listitem: { small: string | number, text: string }, core: WidgetPreview, open: boolean }[] = []

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    widgets: WidgetPreview[],
  }, private modalService: ModalService, private dataStoreService: DataStoreService) { }

  buttonsList: { icon: string, function: Function, tooltip: string, class: string, type: string }[] = [
    { icon: 'dataStore', function: (e) => this.openIndexForm(e), tooltip: '', class: 'bg-light-success', type: 'mat-icon' },
    { icon: 'chevron-down', function: (e) => this.openCollapse(e), tooltip: '', class: 'bg-light-info', type: 'mat-icon' }
  ]

  ngOnInit(): void {

    this.list = this.data.widgets.filter(x => x.widget.isFilter == 0).map(item => {
      return { core: item, listitem: { small: item.executionTimeInMiliSeconds, text: item.widget.title }, open: false }
    })
    this.list = this.list.sort((a, b) => b.core.executionTimeInMiliSeconds - a.core.executionTimeInMiliSeconds)
  }

  clickItem() {

  }

  openCollapse(e) {
    // console.log(e)

    this.list.find(x => x.core.widgetId == e.widgetId).open = !this.list.find(x => x.core.widgetId == e.widgetId).open
  }
  openIndexForm(element) {

    this.modalService.openModal({ dataStore: null, dataStoreId: element.widget.dataStoreId }, IndexFormComponent, '70%', '50%', 'overflow-hidden').subscribe(result => {
      if (result === true) {
        // this.getDatastores();
      }
    });
  }

  expandAll() {
    this.list.map(item => {
      item.open = false
    })



  }
}
