import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShareService } from 'src/app/services/share/share.service';
import { Share } from 'src/app/models/responses/shareResponse';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { GlobalFunctionService } from 'src/app/services/global-function/global-function.service';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { Widget } from 'src/app/models/responses/widgetLibraryResponse';
import { deleteCssFile, idKeyword } from 'src/app/core/utils';

@Component({
  selector: 'app-share-preview',
  templateUrl: './share-preview.component.html',
  styleUrls: ['./share-preview.component.scss']
})
export class SharePreviewComponent implements OnInit, OnDestroy {

  shareKey: string = "";
  shareObject: any
  widgets: any[] = [];
  loading: boolean = false
  dashboard: Dashboard;
  sharedWidget: any;
  domain: string = null;
  enableTitle: boolean = false;
  enableFilter: boolean = false;
  filters: BussionFilterDefinition[] = [];
  selectedDashboardInitialFilters: BussionFilterDefinition[] = this.dashboardService.selectedDasboard.getValue().filter;
  shareId: string = null;
  authType: number = 0
  shareEncryptKey: string = ''
  uniqTimeStemp: number = 0
  notAuth: boolean = false
  sharedToken: string = ''
  currentModalFilters: BussionFilterDefinition[] = []
  contentIdKeyword = idKeyword.dashboard

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      exitButton: boolean,
      showAvatar: boolean,
      dashboard: Share,
      clickedWidget: Widget,
      currentModalIndex: number,
      window: { height: string, width: string }
    },
    private activatedRoute: ActivatedRoute,
    private shareService: ShareService,
    private dashboardService: DashboardService,
    private globalFunctionService: GlobalFunctionService,
    private scriptLoader: ScriptLoaderService,
  ) {
  }
  ngOnInit(): void {
    this.uniqTimeStemp = Date.now()
    var decryptedText = ''

    this.shareKey = this.activatedRoute.snapshot.paramMap.get('shareId');
    if (!this.shareKey) {
      this.shareKey = this.scriptLoader.url?.split('/')[5]
    }

    if (this.shareKey.includes('?')) {
      this.shareKey = this.shareKey.split('?')[0]
    }

    this.shareId = this.shareKey?.split("-")[0];
    this.domain = this.shareKey?.split("-")[1];
    if (this.domain.includes('?')) {
      this.domain = this.domain.split('?')[0]
    }


    if (this.activatedRoute.snapshot.queryParamMap.get('filters') || this.scriptLoader.url.split('filters=')[1]) {

      if (this.activatedRoute.snapshot.queryParamMap.get('filters')?.includes('encrypt')) {
        decryptedText = this.shareService.getDecrypt(this.activatedRoute.snapshot.queryParamMap.get('filters').split('encrypt_')[1], this.shareId, this.domain)
        if (decryptedText) {
          this.filters = JSON.parse(decryptedText)
        }
      } else {
        if (this.activatedRoute.snapshot.queryParamMap.get('filters') == null) {
          this.filters = JSON.parse(this.scriptLoader.url.split('filters=')[1])

        } else {
          this.filters = JSON.parse(this.activatedRoute.snapshot.queryParamMap.get('filters'))
          if (this.scriptLoader.url.split('filters=')[1]) {
            var scripturlfiltre = JSON.parse(this.scriptLoader.url.split('filters=')[1])
            if (scripturlfiltre)
              this.filters = this.filters.concat(scripturlfiltre)
          }
        }
      }
    }

    if (this.activatedRoute.snapshot.queryParamMap.get('sharedToken')) {
      this.sharedToken = this.activatedRoute.snapshot.queryParamMap.get('sharedToken')
    }
    // else {

    // }
    this.currentModalFilters = Object.assign([], this.filters)
    this.addCurrentFilterOpenModal()


    // this.deleteCurrentFilterOnCloseModal()
    // if (this.scriptLoader.url.includes('sharedToken=')) {
    //   this.sharedToken = this.scriptLoader.url.split('sharedToken=')[1]
    //   console.log('this.sharedToken', this.sharedToken)
    // }


    // if (!this.shareKey) {
    //   this.shareKey = this.scriptLoader.url?.split('/')[5]
    // }
    // let filter = this.activatedRoute.snapshot.paramMap.get('filter');

    // if (this.activatedRoute.snapshot.paramMap.get('filter') == null)
    //   filter = this.scriptLoader.url.split('filters=')[1]

    // console.log('parammap', this.scriptLoader.url.split('filters=')[1], filter)

    // if (decryptedText) {
    //   this.filters = JSON.parse(decryptedText)
    // }
    // if (this.shareKey.indexOf("filters=") != -1) {
    //   filter = JSON.parse(this.shareKey?.split("filters=")[1]);
    //   this.shareKey = this.shareKey?.split("filters=")[0]?.split("?")[0];
    // }

    // console.log('typeof', Object.keys(filter))
    // if (filter) {
    //   this.filters = typeof (filter) == 'string' ? JSON.parse(filter) : Object.keys(filter)[0] == '0' ? filter : this.createObjectList(filter)
    // }

    // this.domain = this.shareKey?.split('-')[1];
    this.getGlobalFunctions();



  }

  ngOnDestroy(): void {
    if (this.dashboard?.dashboardId)
      deleteCssFile(this.dashboard.dashboardId)
  }

  addCurrentFilterOpenModal() {
    var allOpenedModals = this.scriptLoader.openedModals.getValue()



    var selecteddashboardFilter = this.scriptLoader.selectedFilters.getValue().map(item => {
      return { fieldValue: item.fieldValue, operator: item.operator, fieldName: item.fieldName }
    })

    if (this.data.clickedWidget && this.data.clickedWidget.drillDown.dashboardFilter) {
      selecteddashboardFilter.map(item => {
        if (!this.filters.some(x => x.fieldName === item.fieldName && x.fieldValue === item.fieldValue)
          && !this.currentModalFilters.some(x => x.fieldName == item.fieldName))
          this.filters.push(item)
      })
      // this.filters = this.filters.concat(selecteddashboardFilter.filter(item =>
      //   !this.filters.some(x => x.fieldName === item.fieldName && x.fieldValue === item.fieldValue)
      // ))

      if (this.data.currentModalIndex > 1) {
        var insideModalFilter = allOpenedModals.find(x => x.modal == this.data.currentModalIndex - 1).filter
        insideModalFilter.map(item => {
          if (!this.filters.some(x => x.fieldName === item.fieldName && x.fieldValue === item.fieldValue)
            && !this.currentModalFilters.some(x => x.fieldName == item.fieldName))
            this.filters.push(item)
        })
      }
    }



    // var selecteddashboardFilter = this.selectedDashboardInitialFilters.map(item => {
    //   return { fieldValue: item.fieldValue, operator: item.operator, fieldName: item.fieldName }
    // })

    // this.filters = this.filters.concat(selecteddashboardFilter.filter(item =>
    //   !this.filters.some(x => x.fieldName === item.fieldName && x.fieldValue === item.fieldValue)
    // ));

    // if (this.data.clickedWidget && this.data.clickedWidget.drillDown.dashboardFilter) {
    //   this.filters = this.filters.concat(this.scriptLoader.modalFilters.getValue().filter(item =>
    //     !this.filters.some(x => x.fieldName === item.fieldName && x.fieldValue === item.fieldValue)
    //   ))
    // }

    // this.scriptLoader.modalFilters.next(this.filters)
  }

  // deleteCurrentFilterOnCloseModal() {
  //   if (this.dialogRef)
  //     this.dialogRef.afterClosed().subscribe(res => {
  //       if (this.data.clickedWidget && this.data.clickedWidget.drillDown.dashboardFilter) {
  //         this.currentModalFilters.map(item => {
  //           if (this.filters.some(x => x.fieldName == item.fieldName && x.fieldValue == item.fieldValue)) {
  //             this.filters = this.filters.filter(x => !(x.fieldName == item.fieldName && x.fieldValue == item.fieldValue))
  //           }
  //         })
  //         this.scriptLoader.modalFilters.next(this.filters)
  //       }
  //     })
  // }


  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    // Tarayıcı sekmesi kapatıldığında bu fonksiyon çağrılır
    this.removeToken()
  }

  removeToken() {
    if (this.sharedToken)
      this.shareService.removeDynamicToken(this.sharedToken).subscribe()
  }


  createObjectList(filter: any) {
    if (filter && Object.keys(filter).length > 0) {
      let filterobj = ''
      let filterkey = []
      if (filter.includes('equal')) {
        filterobj = JSON.parse(filter.replace('equal', '='))
        filterkey = Object.keys(JSON.parse(filter.replace('equal', '=')))
      }
      let list = []
      filterkey.map((item) => {
        list.push(filterobj[item])
      })

      this.filters = list
    }
    return this.filters
  }

  getGlobalFunctions() {
    this.globalFunctionService.getGlobalFunctions(this.domain).subscribe(result => {
      result.forEach(f => {
        this.scriptLoader.runGlobalFunction(f.code);
      });
      this.getShareObject();
    });
  }

  async getShareObject() {

    if (!this.data?.dashboard) {
      this.shareService.getShareObject(this.shareKey).subscribe(shareObject => {
        (shareObject.objectType);
        this.enableFilter = shareObject.enableFilter == 0 ? false : true;
        this.enableTitle = shareObject.enableTitle == 0 ? false : true;
        this.shareId = shareObject.shareId
        this.authType = shareObject.authentication
        this.shareObject = shareObject


        this.shareEncryptKey = shareObject.shareEncryptKey
        if (shareObject.objectType == 'Dashboard') {
          this.getDashboard(shareObject.sharedObjectId).pipe(
            tap(res => this.getWidgets(shareObject))
          ).subscribe();
        }
        else {
          this.getSharedWidget(shareObject);
        }
      });
    } else {
      this.enableFilter = this.data.dashboard.enableFilter == 0 ? false : true;
      this.enableTitle = this.data.dashboard.enableTitle == 0 ? false : true;
      this.shareId = this.data.dashboard.shareId
      this.shareEncryptKey = this.data.dashboard.shareEncryptKey
      this.authType = this.data.dashboard.authentication


      if (this.authType == 1 && this.sharedToken == '') {
        this.sharedToken = await this.shareService.getDynamicToken(this.shareId).toPromise()
      }

      if (this.data.dashboard.objectType == 'Dashboard') {
        this.getDashboard(this.data.dashboard.sharedObjectId).pipe(
          tap(res => this.getWidgets(this.data.dashboard))
        ).subscribe();
        // await this.getWidgets(this.data.dashboard);
      }
      else {
        await this.getSharedWidget(this.data.dashboard);
      }
    }
  }

  getSharedWidget(sharedObject: Share) {

    this.shareService.getSharedWidget(sharedObject.shareId, sharedObject.domain).subscribe(widget => {
      this.sharedWidget = widget;
    })
  }

  getDashboard(dashboardId: string) {
    return this.dashboardService.getDashboard(dashboardId, this.domain).pipe(
      tap(dashboard => {
        this.dashboard = dashboard;
        // this.dashboard.filter = [...this.filters];
      })
    )

    // .subscribe(dashboard => {
    //   this.dashboard = dashboard;
    //   this.dashboard.filter = [...this.filters];
    // })
  }

  getWidgets(shareObject: Share) {
    this.loading = true

    this.shareService.getSharedDashboardWidgets(shareObject.shareId, shareObject.lang, shareObject.domain, this.filters, shareObject.shareEncryptKey, shareObject.authentication, this.sharedToken).pipe(
      catchError((error: any) => {
        this.loading = false
        this.notAuth = true
        return error
      })
    ).subscribe(result => {
      this.loading = false
    
      this.widgets = result.map(item => {
        return { ...item, widgetId: this.uniqTimeStemp + '-' + item.widgetId, widget: { ...item.widget, widgetId: this.uniqTimeStemp + '-' + item.widgetId } }
      });
 


      this.data.currentModalIndex
      this.scriptLoader.openedModals.getValue().find(x => x.modal == this.data.currentModalIndex).widgets = this.widgets
    })
  }

}