import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {

  @Input() title: string;
  @Input() addButtonText: string;
  @Input() secondButtonText: string;

  @Input() filterPlaceholder: string;
  @Input() displayedColumns: string[];
  @Input() displayedColumnsName: string[];
  @Input() columnWidth: string[] = [];
  @Input() pageSizeOptions: number[] = [10, 25, 100]
  @Input() listTypeButton: boolean = false

  @Input() showRunTask: boolean;
  @Input() showDesigner: boolean;
  @Input() showStopTask: boolean;
  @Input() buttonActive: boolean = true;
  @Input() paginationActive: boolean = true;
  @Input() butonIcon: string = 'add_dashboard';
  @Input() exportLogButton: boolean = false;
  @Input() imageWidth: string; // Görüntü genişliği için giriş özelliği
  @Input() imageHeight: string; // Görüntü yüksekliği için giriş özelliği
  @Input() set data(value: any) {
    if (value) {
      this.createDataTable(value);

    }
  }
  inputValue: string = ''

  @Input() actionButtonsList: { icon: string, text: string, actionFunction: Function }[] = []

  @Output() openModal = new EventEmitter<any>();
  @Output() deleteRow = new EventEmitter<any>();
  @Output() runTaskEvent = new EventEmitter<any>();
  @Output() stopTaskEvent = new EventEmitter<any>();
  @Output() exportLog = new EventEmitter<any>();
  @Output() openDashboards = new EventEmitter<any>();
  @Output() secondButtonFunc = new EventEmitter<any>();
  @Output() changeListStyleFunction = new EventEmitter<any>();


  listStyleButton: string = 'list'


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<any>;
  baseUrl = environment.imageUrl;
  constructor() { }

  ngOnInit(): void {

  }

  private createDataTable(result: any[]) {
    const dataTable = result;
    this.dataSource = new MatTableDataSource(dataTable);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.inputValue) {
      var event = {
        target: {
          value: this.inputValue
        }
      }
      this.applyFilter(event)
    }
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.inputValue = (event.target as HTMLInputElement).value;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  open(data: any, type: string) {
    this.openModal.emit({ data: data, type: type });
  }
  openDashboard(item: any) {
    this.openDashboards.emit(item);
  }
  export(item: any) {
    this.exportLog.emit(item);
  }
  delete(item: any) {
    this.deleteRow.emit(item);
  }

  runTask(item: any) {
    this.runTaskEvent.emit(item);
  }

  stopTask(item: any) {
    this.stopTaskEvent.emit(item);
  }

  secondButton() {
    this.secondButtonFunc.emit()
  }

  changeListStyle() {
    this.changeListStyleFunction.emit(this.listStyleButton)
  }

}
