import { BussionFilterDefinition } from "../models/responses/dataStoreResponse";

export enum idKeyword {
  dashboard = 'dashboard',
  app = 'app'
}

export function cerateCssFile(css: string, dashboardid: string, keyword: string) {
  var style = document.createElement('style');
  style.type = 'text/css';
  var csstext = '#' + keyword + dashboardid + '{' + css + '}'
  style.innerHTML = csstext;
  style.id = dashboardid;

  document.getElementsByTagName('head')[0].appendChild(style);
}


export function deleteCssFile(styleid: string) {
  var selectedStyle = document.getElementsByTagName('style')
  if (selectedStyle) {
    if (Array.from(selectedStyle).find(x => x.id == styleid))
      Array.from(selectedStyle).find(x => x.id == styleid).remove()
  }
}


export function filterString(filter: BussionFilterDefinition[]) {
  let filterString = JSON.stringify(Object.assign({}, filter))
  var stringFilter = '[' + filter.map(item => {
    return JSON.stringify(item)
  }) + ']'
  return stringFilter
}



export function dateEditor(timeISOString: string, type: string) {
  var time = new Date(timeISOString);
  var dateTime = new Date(time);
  if (type == 'end') {
    dateTime.setHours(23, 59, 59, 0);
  } else {
    dateTime.setHours(0, 0, 0, 0);
  }
  dateTime.setMinutes(dateTime.getMinutes() - dateTime.getTimezoneOffset())

  var timeString = dateTime.toISOString();
  var editedDate = timeString.split('.')[0] + 'Z'

  return editedDate;
}
