import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }


  getLicense() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getLicense, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  upsertLicense(key: string) {
    let param = { 
      Token: this.authService.getToken(),
      LicenseKey: key
    };
    return this.http.post(Endpoints.upsertLicense, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getLDAPConfig() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getldapconfig, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  updateLDAP(ldapConfig: any) {
    let param = { 
      Token: this.authService.getToken(),
      LdapConfig: ldapConfig
    };
    return this.http.post(Endpoints.updateldapconfig, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }
  
  getPasswordPolicy() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getPasswordPolicy, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  updatePasswordPolicy(passwordPolicies: any) {
    let param = { 
      Token: this.authService.getToken(),
      PasswordPolicies: passwordPolicies
    };
    return this.http.post(Endpoints.updatePasswordPolicy, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

}
