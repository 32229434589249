import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CoreService } from '../services/core/core.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

export enum BussionErrorTypes {
	Server = "ServerError"
}

@Injectable({
	providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {

	constructor(
		private injector: Injector,
		private zone: NgZone
	) { }

	handleError(error: Error | HttpErrorResponse) {
		const coreService = this.injector.get(CoreService);
		const spinner = this.injector.get(NgxSpinnerService);
		const authService = this.injector.get(AuthService);
		if (error instanceof HttpErrorResponse) {
			spinner.hide();
			let er = new Error();
			er.message = error.message;
			er.name = error.name;
			er.stack = error.error;
		


		} else {
			spinner.hide();
	

			if (error.name === BussionErrorTypes.Server) {
				this.zone.run(() => coreService.showErrorMessage(error.message));
				if (error.stack === "19511952") {
					authService.logout().subscribe(result => {});;
				}
			}else{
				if (error.stack.includes("19511952") ) {
					authService.logout().subscribe(result => {});;
				}
			}
		}
		return;
	}
}
