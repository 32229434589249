<div class="content">
    <app-title title="{{'LogsPage.ServerLogs'|translate}}"></app-title>
    <div class="data-table-content">
        <mat-tab-group dynamicHeight>
            <mat-tab label="{{'LogsPage.ServerLogs'|translate}}">
                <div>
                    <!-- <button class="exportButton" mat-button (click)="exportToExcel()">
                        <mat-icon svgIcon="export"></mat-icon>
                    </button> -->
                    <app-data-table [exportLogButton]="true" [butonIcon]="'refresh'"
                        (openModal)="refreshServerLogTable($event)" (exportLog)="exportToExcelServerLog()"
                        [data]="serverLogs" [paginationActive]="false" title="{{'LogsPage.ServerLogs'|translate}}"
                        [displayedColumnsName]="displayedColumnsName"
                        [displayedColumns]="displayedColumns"></app-data-table>
                </div>

            </mat-tab>
            <mat-tab label="{{'LogsPage.ConnectorLogs'|translate}}">
                <div>
                    <!-- <button class="exportButton" mat-button (click)="exportToExcelConnector()">
                        <mat-icon svgIcon="export"></mat-icon>
                    </button> -->
                    <app-data-table [butonIcon]="'refresh'" [exportLogButton]="true"
                        (openModal)="refreshConnectorLogTable($event)" (exportLog)="exportToExcelConnector()"
                        [paginationActive]="false" [data]="connectorLogs" title="{{'LogsPage.ConnectorLogs'|translate}}"
                        [displayedColumnsName]="displayedColumnsName"
                        [displayedColumns]="displayedColumns"></app-data-table>

                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>