import { Component, OnInit, Inject } from '@angular/core';
import { CodeEditorComponent } from 'src/app/components/code-editor/code-editor.component';
import { WidgetLibrary } from 'src/app/models/responses/widgetLibraryResponse';
import { VisualComp } from 'src/app/models/responses/visualComponentResponse';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { VisualFormComponent } from '../../visual/visual-form/visual-form.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { GlobalFunctionService } from 'src/app/services/global-function/global-function.service';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateService } from '@ngx-translate/core';

declare var monaco: any;

@Component({
  selector: 'app-function-form',
  templateUrl: './function-form.component.html',
  styleUrls: ['./function-form.component.scss']
})
export class FunctionFormComponent implements OnInit {

  pageForm: UntypedFormGroup;
  editorOptions = { theme: 'vs-dark', language: 'javascript' };
  code: string = 'function x() {\nconsole.log("Hello world!");\n}';
  title: string = "";
  libraries: WidgetLibrary[] = [];
  selectedLibraries: any[] = [];
  compareFunctionForLibraries = (o1: any, o2: any) => o1 === o2;

  isCreateForm: boolean = true;
  visual: VisualComp = {
    name: "",
    description: "",
    version: 0,
    icon: "",
    createdby: "",
    updatedby: "",
    creationDate: new Date(),
    updateDate: new Date(),
    code: "",
    domain: "",
    widgetLibraries: [],
    revisions: [],
    access:1,
    config:[]
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<VisualFormComponent>,
    private dialog: MatDialog,
    private globalFunction: GlobalFunctionService,
    private spinner: NgxSpinnerService,
    private coreService: CoreService,
    private translate: TranslateService,
    private functionService: GlobalFunctionService
  ) { }

  ngOnInit(): void {
    this.pageForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.visual.name, [Validators.required]),
    });

    this.title = this.data.type == 'update' ? this.translate.instant("GlobalFunctionFormPage.TitleUpdate") : this.translate.instant("GlobalFunctionFormPage.TitleCreate");
    this.isCreateForm = this.data.type == 'update' ? false : true;
    if (this.data.visual) {
      this.visual = Object.assign({}, this.data.visual);
      this.selectedLibraries = this.visual.widgetLibraries;
      this.visual.icon = environment.imageUrl + this.visual.icon;
    }
  }

  editorInit(eventInit: any) {
    (window as any).monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: true
    });

    (window as any).monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES6,
      allowNonTsExtensions: true
    });

    this.getGlobalFunctionDefinitions();

  }

  getGlobalFunctionDefinitions() {
    this.functionService.getGlobalFunctionsDefinitions().subscribe(code => {
      (window as any).monaco.languages.typescript.javascriptDefaults.addExtraLib(code);
    })
  }

  upsertGlobalFunction() {
    if (this.pageForm.invalid) {
      this.coreService.showFormFillError();
      return;
    }
    this.spinner.show();
    this.visual.widgetLibraries = this.selectedLibraries;
    // this.visual.icon = this.visual.icon.split(',')[1];
    this.globalFunction.upsertGlobalFunction(this.visual).subscribe(result => {
      this.spinner.hide();
      this.dialogRef.close(true);
    });
  }

  uploadIconImage(fileInput: any) {
    let file = <File>fileInput.target.files[0];
    if (file.size > 3145728) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.SmallPhoto"));
      return;
    }
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.WrongFormatPhoto"));
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.visual.icon = reader.result;
    }
  }

  openBiggerEditor() {
    const dialogRef = this.dialog.open(CodeEditorComponent, {
      height: 'auto',
      width: '90%',
      data: {
        code: this.visual.code,
      },
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.visual.code = result;
      }
    });
  }

}
