import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/services/modal/modal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-not-auth',
  templateUrl: './not-auth.component.html',
  styleUrls: ['./not-auth.component.scss']
})
export class NotAuthComponent implements OnInit {

  @Input() showExitButton = false

  env: string = environment.imageUrl

  constructor(private router: Router, private dialogService: ModalService) { }

  ngOnInit(): void {
  }

  navigateToLogin() {
    this.router.navigate(['/login'])
    this.dialogService.dissmissAll()
  }

}
