<app-custom-title-box [title]="dropdownSettings.name" [element]="dropdownSettings.id" [status]="true"
    [controlValue]="dropdownSettings.progressValue" (clickIcon)="setSelectedSettingArea($event)"></app-custom-title-box>

<div [id]="dropdownSettings.id" *ngIf="selectedSettingArea==dropdownSettings.id">
    <ng-content select="[underbox]"></ng-content>
    <div class="setting-menu-tab" *ngIf="_tabs && _tabs.length>0">
        <div (click)="selectTab(item)" *ngFor="let item of _tabs" [ngClass]="{'selected-tab': item.selected}">
            <mat-icon [svgIcon]="item.icon"></mat-icon>
        </div>
    </div>
    <app-custom-dropdown-menu-with-search *ngIf="_tabs.length<=0" [enableSearch]="dropdownSettings.searchActive"
        [multipleSelection]="dropdownSettings.multipleSelection" [error]="dropdownSettings.errorValues"
        [dropDownSettings]="dropdownSettings" [menudata]="dropdownMenuData"
        (selectElement)="dropdownSettings.selectElement($event)" [dragActive]="dropdownSettings.dragActive"
        (changeSortWithDrop)="dropdownSettings.changeSortWithDrop($event)"
        (iconMenuEemitter)="dropdownSettings.iconMenuEmitter($event)"
        [imageCard]="dropdownSettings.imageCard"></app-custom-dropdown-menu-with-search>

    <app-custom-dropdown-menu-with-search *ngIf="_tabs.length>0 && selectedTab.dropdown"
        [enableSearch]="dropdownSettings.searchActive"
        [multipleSelection]="selectedTab.dropdown.setting.multipleSelection" [error]="dropdownSettings.errorValues"
        [dropDownSettings]="selectedTab.dropdown.setting" [menudata]="selectedTab.dropdown.data"
        (selectElement)="selectedTab.dropdown.setting.selectElement($event)"
        [dragActive]="selectedTab.dropdown.setting.dragActive"
        (changeSortWithDrop)="selectedTab.dropdown.setting.changeSortWithDrop($event)"
        (iconMenuEemitter)="selectedTab.dropdown.setting.iconMenuEmitter($event)"
        [imageCard]="dropdownSettings.imageCard"></app-custom-dropdown-menu-with-search>

    <ng-container *ngIf="selectedTab?.contentTemplate">
        <ng-container *ngTemplateOutlet="selectedTab.contentTemplate"></ng-container>
    </ng-container>


</div>