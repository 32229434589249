import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { VisualComponentResponse, VisualComp } from 'src/app/models/responses/visualComponentResponse';

@Injectable({
  providedIn: 'root'
})
export class VisualService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getVisualComponents() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getVisualComponents, param).pipe(
      map((response: VisualComponentResponse) => {
        return response.message;
      })
    );
  }

  upsertVisualComponent(visualComponent: VisualComp) {
    let param = { 
      Token: this.authService.getToken(),
      VisualComponent: visualComponent
    };
    return this.http.post(Endpoints.upsertVisualComponent, param).pipe(
      map((response: VisualComponentResponse) => {
        return response.message;
      })
    );
  }

  deleteComponent(visualComponent: VisualComp) {
    let param = { 
      Token: this.authService.getToken(),
      VisualComponentId: visualComponent.visualComponentId
    };
    return this.http.post(Endpoints.deleteVisualComponent, param).pipe(
      map((response: VisualComponentResponse) => {
        return response.message;
      })
    );
  }

}