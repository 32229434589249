import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SettingAreas } from 'src/app/constants/enums';

@Component({
  selector: 'app-custom-title-box',
  templateUrl: './custom-title-box.component.html',
  styleUrls: ['./custom-title-box.component.scss']
})

export class CustomTitleBoxComponent implements OnInit {
  @Input() title: string = ''
  @Input() status: boolean = false
  @Input() element: string = ''
  @Input() controlValue: number = 0


  @Output() clickIcon: EventEmitter<any> = new EventEmitter<any>()
  constructor() { }

  ngOnInit(): void {
    document.getElementById(this.element).style.display = this.status ? 'block' : 'none'


  }

  clickIconEmitter() {
    // document.getElementById(this.element).style.display = document.getElementById(this.element).style.display == 'none' ? 'block' : 'none'
    // this.closeOther()

    console.log('burdayım1')
    this.clickIcon.emit(this.element)
  }

  closeOther() {
    Object.keys(SettingAreas).map(item => {
      if (this.element !== SettingAreas[item] && document.getElementById(SettingAreas[item])) {

        document.getElementById(SettingAreas[item]).style.display = 'none'
        document.getElementById('img-' + SettingAreas[item]).classList.add('close-icon')


      } else {
        if (document.getElementById('img-' + SettingAreas[item]).classList.contains('close-icon'))
          document.getElementById('img-' + SettingAreas[item]).classList.remove('close-icon')
        else
          document.getElementById('img-' + SettingAreas[item]).classList.add('close-icon')


      }

    })
  }
}
