import { Component, OnInit, Inject, Input } from '@angular/core';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { Share } from 'src/app/models/responses/shareResponse';
import { Widget } from 'src/app/models/responses/widgetLibraryResponse';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';

@Component({
  selector: 'app-preview-widget',
  templateUrl: './preview-widget.component.html',
  styleUrls: ['./preview-widget.component.scss']
})
export class PreviewWidgetComponent implements OnInit {

  widget: Widget
  groupedData = {};
  groupedDataKeys: string[] = []

  selectedDashboard: Dashboard

  @Input() set data(value: any) {
    if (value) {
      this.widget = value.widget
      this.createWidget(value);
    }
  }
  @Input() shareObject: Share
  canvasId: string = "id";
  constructor(
    private dashboardService: DashboardService,
    private scriptLoader: ScriptLoaderService
  ) { }

  ngOnInit(): void {
    this.getDashboard()
    
  }

  private createWidget(widgetResponse: any) {
    widgetResponse.widget.widgetId = "widget-" + this.canvasId;
    this.scriptLoader.addScriptFileToHead(widgetResponse.widgetLibraries);
    this.scriptLoader.runWidgetCode(widgetResponse);
  }

  getDashboard() {
    
    if (this.shareObject?.dashboardId)
      this.dashboardService.getDashboardById(this.shareObject.dashboardId).subscribe(res => {
        this.selectedDashboard = res
        this.groupFilters()
      })
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }


  groupFilters() {
    if (this.widget.filter)
      this.widget.filter.forEach(item => {
        var visibleName = this.selectedDashboard.dimensions.find(x => x.name == item.fieldName)?.visibleName ?? item.fieldName
        if (!this.groupedData[visibleName]) {
          this.groupedData[visibleName] = {};
        }



        // this.groupedData[item.fieldName].push(item);

        if (!this.groupedData[visibleName][item.operator]) {
          this.groupedData[visibleName][item.operator] = [];
        }
        this.groupedData[visibleName][item.operator].push(item);

      });


    this.groupedDataKeys = Object.keys(this.groupedData)
  }


}
