import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';

@Component({
  selector: 'app-grid-analytics',
  templateUrl: './grid-analytics.component.html',
  styleUrls: ['./grid-analytics.component.scss']
})
export class GridAnalyticsComponent implements OnInit {
  selectedDsId: string = ''
  selectedDataPreviewColumns: any[] = []
  sampleDataForDataStore: any[] = []
  samplecount:number=50

  constructor(private activatedRoute: ActivatedRoute, private dataStoreService: DataStoreService) { }

  ngOnInit(): void {

    this.selectedDsId = this.activatedRoute.snapshot.paramMap.get('dataStoreId')
    this.getDatas()
  }

  getDatas() {
    return this.dataStoreService.getSampleData(this.selectedDsId,this.samplecount).subscribe(sampleres => {
      this.dataStoreService.getColomnsForDataStore(this.selectedDsId).subscribe(res => {
        this.selectedDataPreviewColumns = res
        this.sampleDataForDataStore = sampleres

      })
    })
  }

}
