import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { languagePacts } from 'src/app/models/responses/languagePacts';
import { CoreService } from 'src/app/services/core/core.service';
import { SystemParametersService } from 'src/app/services/system-parameters/system-parameters.service';

@Component({
  selector: 'app-language-form',
  templateUrl: './language-form.component.html',
  styleUrls: ['./language-form.component.scss']
})
export class LanguageFormComponent implements OnInit {
  pageForm: FormGroup
  language: languagePacts = {
    name: '',
    content: '',
    type: ''
  }
  validationResponse: boolean = true

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    language: languagePacts,
    type: string
  }, public dialogRef: MatDialogRef<LanguageFormComponent>,
    private systemService: SystemParametersService,
    private coreService: CoreService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    Object.assign(this.language, this.data.language)

    this.pageForm = new FormGroup({
      name: new UntypedFormControl(this.language.name, [Validators.required]),
      type: new UntypedFormControl(this.language.type, [Validators.required]),
      content: new UntypedFormControl(this.beautifyJSON(this.language.content), [Validators.required])
     
    })

  }


  upsertLanguage() {
    if (this.beautifyJSON(this.language.content)) {
      this.systemService.upsertLanguagePacts(this.language).subscribe(res => {
        if (res.name) {
          this.dialogRef.close(res)
          this.coreService.showSuccessMessage(this.translate.instant("Common.Success"))
        } else {
          this.coreService.showErrorMessage(this.translate.instant("Common.Error"))
        }
      })
    } else {
      this.coreService.showErrorMessage(this.translate.instant("LanguageForm.InValidJson"))

    }

  }

  beautifyJSON(jsonData) {

    try {
      this.validationResponse = true
      const beautifiedJSON = JSON.stringify(JSON.parse(jsonData), null, 4);
      this.language.content = beautifiedJSON
      return beautifiedJSON;
    } catch (error) {
      console.error("Invalid JSON data:", error);
      this.validationResponse = false

      return null;
    }

  }



}
