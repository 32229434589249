import { Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from '@angular/core';
import "@angular/compiler";
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HomeComponent, Msg } from 'src/app/pages/home/home.component';
import { Group } from 'src/app/models/responses/groupsResponse';
import { forkJoin } from 'rxjs';
import { User } from 'src/app/models/responses/usersResponse';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dynamic-home',
  // templateUrl: './dynamic-home.component.html',
  template: '',
  styleUrls: ['./dynamic-home.component.scss'],
  standalone: true
})

export class DynamicHomeComponent implements OnInit {
  @ViewChild(HomeComponent) home: HomeComponent;
  constructor() {
  }


  saveUserStorageCallBack: (selectedOption) => void;
  selectedPage: any[] = []
  selectedPageAllData: any[] = []
  selectedList: any[] = []
  Dashboards: Dashboard[] = []
  baseUrl: string = ''
  dashboardService: any;
  scriptLoader: any;
  router: any;
  cssFolder: any;
  page: number = 1
  groups: Group[] = []
  Applications: any[]
  ActiveDashboards: Dashboard[] = []
  classNameAll: string = 'filter-active'
  classNameDash: string = ''
  classNameApp: string = ''
  roles: string[] = []
  licencePermissions = localStorage.getItem('licence') ?? ''
  showFirst: HTMLElement
  cacheddata: any
  passVisible: boolean = false
  loading: boolean = true
  selectedGroupOption: string | number = 'All'
  savedHomeOption: string | number = 'All'



  selectedMenu = 'All'

  ngOnInit(): void {
    this.baseUrl = environment.imageUrl;
    this.roles = this.getPermissions()
    this.setCachedPage();

    this.dashboardService = Msg.DashboardService;
    this.scriptLoader = Msg.ScriptLoader;
    this.router = Msg.Router;

  }

  saveUserStorage() {
    if (this.saveUserStorageCallBack) {
      this.saveUserStorageCallBack(this.selectedGroupOption);
    }
  }

  getPermissions() {
    if (localStorage.getItem('user'))
      return JSON.parse(localStorage.getItem('user'))?.permissions
    else
      return []
  }

  setCachedPage(ishome?: string) {

    var homeData = localStorage.getItem('selectedHome')
    var user = localStorage.getItem('user')
    var userObj: User = user ? JSON.parse(user) : null



    if (ishome == 'home') {
      history.replaceState({ apply: 'nolocalstorage' }, '')

    }

    if (history.state?.apply == 'nolocalstorage' && userObj.defaultDashboardAppId) {
      this.savedHomeOption = userObj.defaultDashboardAppId
      this.changeGroupSelection(userObj.defaultDashboardAppId)
    } else if (homeData) {
      var jsonhome: { 'selectedTab': string, 'selectedOption': string } = JSON.parse(homeData)
      if (history.state?.apply == 'nolocalstorage' || jsonhome.selectedOption == userObj?.defaultDashboardAppId) {
        this.savedHomeOption = jsonhome.selectedOption
      }
      this.select(jsonhome.selectedOption);
      this.changeGroupSelection(jsonhome.selectedOption)
    } else {
      this.select('All')
    }


  }



  saveLocalStorage() {
    var storageItem = { 'selectedTab': this.selectedMenu, 'selectedOption': this.selectedGroupOption }
    localStorage.setItem('selectedHome', JSON.stringify(storageItem))
  }

  changeGroupSelection(event) {
    var selectedValue = event?.target?.value || event
    this.selectedGroupOption = selectedValue
    console.log('first bura neden çalışıyor222')

    if (this.selectedMenu !== 'Dashboard')
      this.select('Dashboard')

    if (selectedValue == 'AppView') {
      this.selectedPage = this.groups
      this.selectedPageAllData = this.groups
    } else if (selectedValue == 'All') {
      this.selectedList = this.Dashboards.concat(this.Applications)
      this.selectedPage = this.Dashboards.concat(this.Applications)
      this.selectedPageAllData = this.Dashboards.concat(this.Applications)
    } else if (selectedValue == 'My') {
      var userId = localStorage.getItem('userId')
      this.selectedList = this.Dashboards.filter(x => x.ownerId == userId)
      this.selectedPage = this.Dashboards.filter(x => x.ownerId == userId)
      this.selectedPageAllData = this.Dashboards.filter(x => x.ownerId == userId)

    } else if (selectedValue == 'Favorite') {
      this.selectedList = this.ActiveDashboards
      this.selectedPage = this.ActiveDashboards
      this.selectedPageAllData = this.ActiveDashboards

    } else {
      this.selectedPage = []
      this.selectedList = []
      this.selectedPageAllData = []

      var groupSelecteddashboards = this.groups.find(x => x.groupId == selectedValue)?.dashboards || []
      groupSelecteddashboards.map(item => {
        var selectedDash = this.Dashboards.find(x => x.dashboardId == item)
        if (selectedDash) {
          this.selectedPage.push(selectedDash)
          this.selectedList.push(selectedDash)
          this.selectedPageAllData.push(selectedDash)
        }
      })

    }

    this.saveLocalStorage()

  }

  searchElements(e) {
    // var key = 'title'
    // if (this.selectedPageAllData[0]?.groupName) {
    //   key = 'groupName'
    // } else if (this.selectedPageAllData[0]?.name) {
    //   key = 'name'

    // }


    this.selectedPage = this.selectedPageAllData.filter(x => x?.name?.toLowerCase().includes(e.target.value.toLowerCase())
      || x?.title?.toLowerCase().includes(e.target.value.toLowerCase())
      || x?.groupName?.toLowerCase().includes(e.target.value.toLowerCase()))

  }

  navigation(item: any) {
    var anchor = document.createElement('a');
    // this.setCacheddata()
    if (item.groupId) {
      this.changeGroupSelection(item.groupId)
    } else if (item.applicationLink) {
      localStorage.setItem('app', JSON.stringify(item))
      let options = {
        type: 'centerWindow', //newWindow,centerWindow,rightWindow,leftWindow
        windowWidth: '100%',
        windowHeight: '100%',
        isapp: 'true',
        app: item
      }

      if (item.options) {
        options = JSON.parse(item.options)
        options.windowHeight = options.windowHeight + '%'
        options.windowWidth = options.windowWidth + '%'

        options['isapp'] = 'true'
        options['app'] = item

      }
      if (item.appType == 0) {
        this.scriptLoader.openWindow(`${this.baseUrl}/Apps/${item.applicationLink}`, options)
        return
      }

      if (item.openerMethod == 1) {//internal
        window.open(`${this.baseUrl}/Apps/${item.applicationLink}`, "_blank");
      } else if (item.openerMethod == 2) {//newtab
        anchor.href = `${this.baseUrl}/Apps/${item.applicationLink}`;
        anchor.target = "_blank";
        anchor.click();

      } else if (item.openerMethod == 3) {//popup
        this.scriptLoader.openWindow(`${this.baseUrl}/Apps/${item.applicationLink}`, options)
      }
    } else {
      // this.cookieService.setItem('lastDashboards',)
      // this.dashboardService.setLastDashboardId(item.dashboardId)
      this.router.navigate(["/main"], { state: item });
    }
  }

  pagination(pageNumber: number) {
    this.selectedPage = this.selectedList.slice((pageNumber - 1) * 8, ((pageNumber - 1) * 8) + 8)
  }

  openSelfServisMain() {
    this.router.navigate(['selfservice'])
  }



  select(type: string) {
    this.selectedMenu = type
    if (type === 'All') {
      this.selectedList = this.Dashboards.concat(this.Applications)
      this.selectedPage = this.Dashboards.concat(this.Applications)
      this.selectedPageAllData = this.Dashboards.concat(this.Applications)
      // this.pagination(this.page = 1)
      this.classNameAll = 'filter-active'
      this.classNameDash = ''
      this.classNameApp = ''
      this.selectedGroupOption = 'All'
    } else if (type === 'Dashboard') {
      this.selectedList = this.Dashboards
      this.selectedPage = this.Dashboards
      this.selectedPageAllData = this.Dashboards
      // this.pagination(this.page = 1)
      this.classNameAll = ''
      this.classNameApp = ''
      this.classNameDash = 'filter-active'
    } else if (type === 'App') {
      this.selectedList = this.Applications
      this.selectedPage = this.Applications
      this.selectedPageAllData = this.Applications
      // this.pagination(this.page = 1)
      this.classNameDash = ''
      this.classNameAll = ''
      this.classNameApp = 'filter-active'
    }
  }




}
