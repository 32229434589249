import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  @Input() set data(value: any) {
    console.log('değişti',value)
    if (value) {
      this.sourceData = value;
      this.createDataTable(value);
    }
  }
  @Input() scrollarea: boolean = false
  @Input() disableDelete = false

  @Output() delete = new EventEmitter<any>();
  private sourceData = [];
  dataSource: MatTableDataSource<any>;
  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  private createDataTable(result: any[]) {
    const dataTable = result;
    this.dataSource = new MatTableDataSource(dataTable);
  }


  deleteFilter(query: any) {
    this.delete.emit(query);
    this.createDataTable(this.sourceData);
  }

  changeName(type: string): string {
    if ("=" == type) {
      return this.translate.instant("FilterComponent.Equal");
    }
    else if ("!=" == type) {
      return this.translate.instant("FilterComponent.NotEqual");
    }
    else if (">" == type) {
      return this.translate.instant("FilterComponent.GreaterThan");
    }
    else if (">=" == type) {
      return this.translate.instant("FilterComponent.GreaterThanEqual");
    }
    else if ("<" == type) {
      return this.translate.instant("FilterComponent.Little");
    }
    else if ("<=" == type) {
      return this.translate.instant("FilterComponent.LittleEqual");
    }
    else if ("starts" == type) {
      return this.translate.instant("FilterComponent.StartsWith");
    }
    else if ("ends" == type) {
      return this.translate.instant("FilterComponent.EndsWith");
    }
    else if ("in" == type) {
      return this.translate.instant("FilterComponent.Contains");
    }
    else if ("nin" == type) {
      return this.translate.instant("FilterComponent.NotContains");
    }
    else if ("empty" == type) {
      return this.translate.instant("FilterComponent.IsEmpty");
    }
    else if ("nempty" == type) {
      return this.translate.instant("FilterComponent.NotEmpty");
    }
    else if (type == "today") {
      return this.translate.instant("FilterComponent.Today");
    }
    else if (type == "yesterday") {
      return this.translate.instant("FilterComponent.Yesterday");
    }
    else if (type == "thishour") {
      return this.translate.instant("FilterComponent.ThisHour");
    }
    else if (type == "thisweek") {
      return this.translate.instant("FilterComponent.ThisWeek");
    }
    else if (type == "thismonth") {
      return this.translate.instant("FilterComponent.ThisMonth");
    }
    else if (type == "thisyear") {
      return this.translate.instant("FilterComponent.ThisYear");
    }
    else if (type == "lasthour") {
      return this.translate.instant("FilterComponent.LastHour");
    }
    else if (type == "lastweek") {
      return this.translate.instant("FilterComponent.LastWeek");
    }
    else if (type == "lastmonth") {
      return this.translate.instant("FilterComponent.LastMonth");
    }
    else if (type == "lastyear") {
      return this.translate.instant("FilterComponent.LastYear");
    }
    else if (type == "beforendays") {
      return this.translate.instant("FilterComponent.BeforeNDays");
    }
    else if (type == "lastnadays") {
      return this.translate.instant("FilterComponent.LastNDays");
    }
    else if (type == "lastnhours") {
      return this.translate.instant("FilterComponent.LastNHours");
    }
    else if (type == "lastnminutes") {
      return this.translate.instant("FilterComponent.LastNMinutes");
    }
    else {
      return type;
    }
  }

}

/*

this.translate.instant("FilterComponent.Today; }
this.translate.instant("FilterComponent.Yesterday; }
else if (type =="thishour") { this.translate.instant("FilterComponent.This Hour; }
else if (type =="thisweek") { this.translate.instant("FilterComponent.This Week; }
else if (type =="thismonth") { this.translate.instant("FilterComponent.This Month; }
else if (type =="thisyear") { this.translate.instant("FilterComponent.This Year; }
else if (type =="lasthour") { this.translate.instant("FilterComponent.Last Hour; }
else if (type =="lastweek") { this.translate.instant("FilterComponent.Last Week; }
else if (type =="lastmonth") { this.translate.instant("FilterComponent.Last Month; }
else if (type =="lastyear") { this.translate.instant("FilterComponent.Last Year; }
else if (type =="beforendays") { this.translate.instant("FilterComponent.Before N Days ; }
else if (type =="lastnadays") { this.translate.instant("FilterComponent.Last N Days; }
else if (type =="lastnhours") { this.translate.instant("FilterComponent.Last N Hours; }
else if (type =="lastnminutes") { this.translate.instant("FilterComponent.Last N Minutes; }


"Today";
"Yesterday":
"ThisHour":
"ThisWeek":
"ThisMonth":
"ThisYear":
"LastHour":
"LastWeek":
"LastMonth":
"LastYear":
"BeforeNDays":
"LastNDays":
"LastNHours":
"LastNMinutes":

 */
