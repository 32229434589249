import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TooltipPosition } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, merge, of } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';
import { dateEditor } from 'src/app/core/utils';
import { Dashboard, Dimension, cacheDashboard } from 'src/app/models/responses/dashboardResponse';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { CoreService } from 'src/app/services/core/core.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-filter-selection',
  templateUrl: './filter-selection.component.html',
  styleUrls: ['./filter-selection.component.scss']
})
export class FilterSelectionComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @ViewChild('dateInput', { read: MatInput }) dateInput: MatInput;
  autoComplateControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  options: string[] = [];




  filter: BussionFilterDefinition = {
    fieldName: '',
    fieldValue: '',
    operator: '='
  }

  @Input() filters: BussionFilterDefinition[];
  @Input() initialFilters: BussionFilterDefinition[];
  @Input() dimensions: Dimension[] = [];
  @Input() isHeaderShown: boolean
  @Input() hideButtonActive: boolean
  @Input() selectedDashboardId: string;
  @Input() selectedDashboard: string;


  @Input() manage: boolean = false;
  @Input() savebuttonvisible = true
  @ViewChild('editinput', { static: false }) inputEl: ElementRef;

  @Output() delete = new EventEmitter<any>();
  @Output() save = new EventEmitter<BussionFilterDefinition[]>();
  @Output() close = new EventEmitter<any>();
  @Output() openShareForm = new EventEmitter<any>();
  @Output() saveDimensions = new EventEmitter<any>();
  @Output() hideDimension = new EventEmitter<any>();


  @Output() saveQueryPanelState = new EventEmitter<any>();
  @Output() resetFilter = new EventEmitter<any>();



  selectedDimension: Dimension = {
    dimensionId: '',
    name: '',
    type: '',
    value: [],
    visibleName: '',
    lockedFilter: 0,
    showAllValues: 0,
    pintoPanel: 0,
    isFilterExist: true,
    isOrdered: 0,
    allowSearch: 0


  };
  selectedDimensionFilter: BussionFilterDefinition[] = [];
  isDimensionSelected: boolean = false;
  isShownFieldValueForm: boolean = true;
  selectedDimensionId: string = "";
  isDeviceMobile: boolean = false;
  dimensiondragactive: boolean = false



  queryFilterFieldType: string = "System.String";
  selectedFilterType: string = "System.String";

  constructor(private dashboardService: DashboardService, private translate: TranslateService,
    private deviceService: DeviceDetectorService, private coreService: CoreService) { }

  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new UntypedFormControl(this.positionOptions[2]);
  newDimensionValue = new UntypedFormControl();

  selectedDimensi: string

  ngOnInit(): void {
    var uniqFilter = []
    console.log('this.selectedFilters', this.filters)

    this.filters.filter(x => (x.operator.length <= 2 && x.fieldValue !== '') || (x.operator.length > 3 && x.operator !== '')).map(item => {
      if (!uniqFilter.some(x => x.fieldValue == item.fieldValue && x.fieldName == item.fieldName && x.operator == item.operator)) {
        uniqFilter.push(item)
      }
    })
    this.filters = uniqFilter
    // if (this.initialFilters)
    //   this.filters = this.initialFilters.filter(x => x.fieldValue !== '')



    this.checkFilterExist();

    this.isDeviceMobile = this.deviceService.isMobile();


    console.log('this.selectedFilters', this.filters)
  }




  addFilter() {

    let fCopy = Object.assign({}, this.filter);

    if (!(this.filters.some(x => x.fieldValue == fCopy.fieldValue && x.fieldName == fCopy.fieldName && fCopy.operator && fCopy.operator == x.operator)) && (this.isShownFieldValueForm && fCopy.fieldValue !== '') || (!this.isShownFieldValueForm && fCopy.fieldValue == '')) {

      if (this.queryFilterFieldType == 'System.Number' && !(fCopy.operator == 'empty' || fCopy.operator == 'nempty')) {
        if (!this.isNumeric(fCopy.fieldValue)) {
          this.coreService.showErrorMessage(this.translate.instant('FilterComponent.NumericField'))
          return
        } else {
          fCopy.fieldValue = parseInt(fCopy.fieldValue)
        }
      }

      if (fCopy.operator == 'between') {
        const [startDate, endDate] = fCopy.fieldValue.split('/');
        const startFilter = {
          fieldName: fCopy.fieldName,
          fieldValue: startDate,
          operator: '>'
        };
        const endFilter = {
          fieldName: fCopy.fieldName,
          fieldValue: endDate,
          operator: '<'
        };
        this.filters.push(startFilter, endFilter);
      } else {
        this.filters.push({
          fieldName: fCopy.fieldName,
          fieldValue: fCopy.fieldValue,
          operator: fCopy.operator
        });
      }



      this.clearFilterForm();
      this.checkFilterExist();
      this.selectedDimensionFilter = this.filters.filter(f => f.fieldName === fCopy.fieldName);
      if (this.autocomplete !== undefined) {
        this.autocomplete.closePanel();
      }
    } else {
      // this.coreService.showErrorMessage(this.translate.instant("FilterComponent.PleaseSelectDifValue"))
    }
  }

  isNumeric(input) {
    return !isNaN(parseFloat(input)) && isFinite(input);
  }

  deleteFilter(deletedFilter: BussionFilterDefinition) {

    let index = this.filters.findIndex(f => {
      return f.fieldName === deletedFilter.fieldName &&
        f.operator === deletedFilter.operator &&
        f.fieldValue === deletedFilter.fieldValue;
    });
    if (index !== -1) {
      this.filters.splice(index, 1);
    }
    this.selectedDimensionFilter = this.filters.filter(f => f.fieldName == this.filter.fieldName);
    this.checkFilterExist();
  }

  selectDimension(d: Dimension) {
    this.isDimensionSelected = true;
    this.selectedDimension = d;
    if (!this.manage && this.selectedDimension.lockedFilter == 1)
      this.autoComplateControl.disable()
    else
      this.autoComplateControl.enable()


    this.filter.fieldName = d.name;
    this.clearFilterForm();
    this.options = this.selectedDimension.value.filter(x => x !== null);
    this.selectedDimensionFilter = this.filters.filter(f => f.fieldName == d.name);
    this.queryFilterFieldType = d.type;



    this.filteredOptions = this.autoComplateControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );




  }

  areListsDifferent(list1, list2) {
    // Uzunlukları kontrol ediyoruz
    if (list1.length !== list2.length) {
      return false;
    }

    // Elemanların sırasını kontrol ediyoruz
    for (let i = 0; i < list1.length; i++) {
      if (list1[i] !== list2[i]) {
        return true;
      }
    }

    // Tüm elemanlar aynı sırayla bulunuyorsa, listeler aynıdır
    return false;
  }

  closedMenu() {
    this.filteredOptions.subscribe(res => {
      const result = this.areListsDifferent(this.dimensions.find(x => x.name == this.selectedDimension.name).value, res);
      if (this.selectedDimension.isOrdered == 1 && result) {
        this.dimensions.find(x => x.name == this.selectedDimension.name).value = res
        this.saveDimensions.emit(this.dimensions)
      }
    })
  }


  drop(event: CdkDragDrop<string[]>, type: string) {
    if (type == 'option') {
      this.filteredOptions.subscribe(res => {
        moveItemInArray(res, event.previousIndex, event.currentIndex);
        this.filteredOptions = of(res)
      })
    } else {
      moveItemInArray(this.dimensions, event.previousIndex, event.currentIndex);
    }
  }

  saveDimensionSorting() {
    this.dimensiondragactive = !this.dimensiondragactive
    if (!this.dimensiondragactive)
      this.saveDimensions.emit(this.dimensions)
  }

  searchDimensionValue() {
    var selecteddimension = this.dimensions.find(x => x.dimensionId == this.selectedDimension.dimensionId)

    if (this.newDimensionValue.value == '') {
      this.filteredOptions = of(selecteddimension.value)

    } else {
      var filtereddim = selecteddimension.value.filter(x => x.toLowerCase().includes(this.newDimensionValue.value.toLowerCase()))
      this.filteredOptions = of(filtereddim)
    }


  }

  addDimensionValue() {
    if (this.newDimensionValue.value && this.newDimensionValue.value !== '') {
      var selecteddimension = this.dimensions.find(x => x.dimensionId == this.selectedDimension.dimensionId)
      if (!selecteddimension.value.some(x => x == this.newDimensionValue.value)) {
        selecteddimension.value.push(this.newDimensionValue.value)
        this.filteredOptions = of(this.selectedDimension.value)
        this.newDimensionValue.patchValue('')
        this.saveDimensions.emit(this.dimensions)
      } else {
        this.coreService.showErrorMessage(this.translate.instant('FilterComponent.SameValue'))
      }

    }

  }


  deleteDimensionValue(deleteText: string) {
    var selecteddimension = this.dimensions.find(x => x.dimensionId == this.selectedDimension.dimensionId)
    selecteddimension.value = selecteddimension.value.filter(x => x.toString() !== deleteText)
    this.filteredOptions = of(this.selectedDimension.value)
    this.saveDimensions.emit(this.dimensions)
  }


  checkFilterExist() {
    this.dimensions.forEach(d => {
      d.isFilterExist = false;
      this.filters.forEach(f => {
        if (d.name == f.fieldName) {
          d.isFilterExist = true;
        }
      })
    });
  }

  private clearFilterForm() {
    this.filter.fieldValue = '';
    this.filter.operator = '=';
    if (this.selectedDimension.type == 'System.DateTime' && this.dateInput != undefined) {
      this.dateInput.value = '';
    }
    this.isShownFieldValueForm = true
  }

  clearAllFilter() {
    this.filters = [];
    this.checkFilterExist();
  }

  resetFilterFunc() {
    this.resetFilter.emit()
    this.filters = this.initialFilters;
    this.checkFilterExist();
  }

  openSelected(d: string) {
    this.selectedDimensionId = d
    setTimeout(() => {
      this.inputEl.nativeElement.focus()
    }, 100);

  }

  lockFilter(dim: Dimension) {
    var selectedLocked = this.dimensions.find(x => x.dimensionId == dim.dimensionId).lockedFilter
    this.dimensions.find(x => x.dimensionId == dim.dimensionId).lockedFilter = selectedLocked == 0 ? 1 : 0
    this.saveDimensions.emit(this.dimensions)

  }

  deleteDimension(d: Dimension) {
    this.hideDimension.emit(d)
  }

  saveSortState(dim: Dimension) {
    var selectedOrdered = this.dimensions.find(x => x.dimensionId == dim.dimensionId).isOrdered
    this.dimensions.find(x => x.dimensionId == dim.dimensionId).isOrdered = selectedOrdered == 0 ? 1 : 0

    this.saveDimensions.emit(this.dimensions)
    this.selectedDimensionId = ''
  }

  hidePanel(event: MatSlideToggleChange) {
    this.saveQueryPanelState.emit(event.checked)
  }

  pinToPanel(d: Dimension) {
    var selecteddimension = this.dimensions.find(x => x.dimensionId == d.dimensionId)
    this.dimensions.find(x => x.dimensionId == d.dimensionId).pintoPanel = selecteddimension.pintoPanel == 0 ? 1 : 0

    this.saveDimensions.emit(this.dimensions)
  }

  showAllValues(d: Dimension) {
    var selecteddimension = this.dimensions.find(x => x.dimensionId == d.dimensionId)
    this.dimensions.find(x => x.dimensionId == d.dimensionId).showAllValues = selecteddimension.showAllValues == 0 ? 1 : 0


    this.saveDimensions.emit(this.dimensions)
  }

  saveVisibleName(e: any, dim: Dimension) {
    this.dimensions.find(x => x.dimensionId == dim.dimensionId).visibleName = e.target.value

    this.saveDimensions.emit(this.dimensions)
    this.selectedDimensionId = ''
  }


  closeFilter() {
    this.close.emit(this.filters);
  }

  makeQuery() {
    this.save.emit(this.filters);
  }

  rangeStart: string = ''
  rangeEnd: string = ''

  //TO DO
  // dateSelected(date: MatDatepickerInputEvent<Date>, range: boolean, rangeType?: string) {
  //   var startTimeISOString = date.value.toISOString();

  //   var time = new Date(startTimeISOString);
  //   time = new Date(time.getTime() - (time.getTimezoneOffset() * 60000));
  //   if (range) {
  //     if (rangeType == 'start') {
  //       this.rangeStart = time.toISOString()
  //     } else {
  //       this.rangeEnd = time.toISOString()
  //       this.filter.fieldValue = this.rangeStart + '/' + this.rangeEnd
  //     }
  //   } else {
  //     this.filter.fieldValue = time.toISOString();
  //   }

  // }
  dateSelected(date: MatDatepickerInputEvent<Date>, range: boolean, rangeType?: string) {
    var startTimeISOString = date.value.toISOString();
    var time = new Date(startTimeISOString);

    if (range) {
      if (rangeType == 'start') {

        this.rangeStart = dateEditor(startTimeISOString, 'start')
      } else {

        this.rangeEnd = dateEditor(startTimeISOString, 'end')

        this.filter.fieldValue = this.rangeStart + '/' + this.rangeEnd;
      }
    } else {
      // time = new Date(time.getTime() - (time.getTimezoneOffset() * 60000));
      this.filter.fieldValue = dateEditor(startTimeISOString, 'start')
    }
  }

  private setFieldValueFormDisplayStatus(operator: string) {

    if (operator == "empty" || operator == "nempty") {
      this.isShownFieldValueForm = false;
      return;
    }

    if (this.getSelectedFieldType(this.filter.fieldName) == 'System.DateTime') {
      if (operator == "today" ||
        operator == "yesterday" ||
        operator == "thishour" ||
        operator == "thisweek" ||
        operator == "thismonth" ||
        operator == "thisyear" ||
        operator == "lasthour" ||
        operator == "lastweek" ||
        operator == "lastmonth" ||
        operator == "lastyear") {
        this.filter.fieldValue = '';
        this.isShownFieldValueForm = false;
      }
      else if (operator == "beforendays" ||
        operator == "lastnadays" ||
        operator == "lastnhours" ||
        operator == "lastnminutes") {
        this.queryFilterFieldType = "System.Number";
        this.isShownFieldValueForm = true;
      }

      else {
        this.queryFilterFieldType = "System.DateTime";
        this.isShownFieldValueForm = true;
      }
    }
    else {
      this.isShownFieldValueForm = true;
    }
  }

  getSelectedFieldType(fieldName: string): string {
    let field = this.dimensions.find(c => c.name == fieldName);
    return field.type;
  }

  operatorSelected(operator: string) {
    this.filter.fieldValue = '';
    this.setFieldValueFormDisplayStatus(operator);
  }

  openShareModal(shareData: any) {
    this.openShareForm.emit(shareData)
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    if (typeof (this.options[0]) == 'string')
      return this.options.filter(option => option.toLowerCase().includes(filterValue));
    else return this.options
  }

  disableSearch(d: Dimension) {
    var selecteddimension = this.dimensions.find(x => x.dimensionId == d.dimensionId)
    this.dimensions.find(x => x.dimensionId == d.dimensionId).allowSearch = selecteddimension.allowSearch == 0 ? 1 : 0

    // this.refreshCache(this.dimensions)


    this.saveDimensions.emit(this.dimensions)
  }

  // refreshCache(newDimensions) {
  //   this.dashboardService.refreshCache(newDimensions,this.selectedDashboardId,'dimensions')
  // }



}
