import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/models/responses/usersResponse';
import { DashboardService } from '../dashboard/dashboard.service';
import { DeployAppService } from '../deploy-app/deploy-app.service';
import { App } from 'src/app/models/responses/appsResponse';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private permissions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private authApps: App[] = []


  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private appService: DeployAppService,
    private router: Router
  ) { }



  getUserRoleList() {
    if (this.permissions.value.length == 0) {
      this.getUserPermissions().subscribe(r => { });
    }
    return this.permissions.asObservable();
  }

  getUserPermissions(isNew?: boolean) {
    if (this.permissions.value.length != 0 && !isNew) { return this.permissions.asObservable(); }

    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getUserPermissions, param).pipe(
      map((response: any) => {
        this.permissions.next(response.message);
        return response.message;
      })
    );
  }

  getPermissions() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getPermissions, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getPermissionListValue() {
    return this.permissions.value;
  }

  clearPermissions() {
    this.permissions.next([]);
  }

  getUserPermissionForApp() {
    var user: User = this.authService.getUser()
    var authApp = this.authApps
    if (user.homePage.length > 0)
      authApp = this.authApps.filter(x => x.applicationId !== user.homePage[0].objectId)

    if (user.homePage.length > 0 && authApp.length <= 0) {
      //kendine tanımlanan app homepageden başkasına yetkisi yok
      return false
    } else {
      return true
    }

  }

  appIdPermission(appId: string) {
    var user: User = this.authService.getUser()
    var authApp = this.authApps
    if (user.homePage.length > 0)
      authApp = this.authApps.filter(x => x.applicationId !== user.homePage[0].objectId)

    if (user.homePage.length > 0 && authApp.length <= 0 && user.homePage[0].objectId == appId) {
      //kendine tanımlanan app homepageden başkasına yetkisi yok
      return true
    } else if (user.homePage.length <= 0 && authApp.some(x => x.applicationId == appId)) {
      return true
    } else {
      false
    }

  }

  getUserHomePage() {
    var user: User = this.authService.getUser()
    var appObject: App = JSON.parse(user.homePage[0].object)
    if (appObject.appType == 0) {
      this.router.navigate(['/data-app/' + appObject.applicationId]);
      return '/data-app/' + appObject.applicationId;
    } else {
      window.location.replace(environment.baseUrl + '/Apps/' + appObject.applicationLink)
      return environment.baseUrl + '/Apps/' + appObject.applicationLink
    }
  }

  getAuthApp() {
    return this.appService.GetAuthorizedApp().pipe(
      tap(res => {
        console.log(res)
        this.authApps = res
      })
    )
  }


  getPermissionPath() {
    if (this.permissions.value.includes('UserManagement')) {
      return '/landing/user';
    }
    else if (this.permissions.value.includes('GroupManagement')) {
      return '/manage/group';
    }
    else if (this.permissions.value.includes('RoleManagement')) {
      return '/manage/role';
    }
    else if (this.permissions.value.includes('DeployAppManagement')) {
      return '/manage/deploy-app';
    }
    else if (this.permissions.value.includes('WidgetLibraryManagement')) {
      return '/manage/widget-library';
    }
    else if (this.permissions.value.includes('VisualComponentManagement')) {
      return '/manage/widget-library';
    }
    else if (this.permissions.value.includes('DataStoreManagement')) {
      return '/manage/datastore';
    }
    else if (this.permissions.value.includes('NodeManagement')) {
      return '/manage/node';
    }
    else if (this.permissions.value.includes('ColorPaletteManagement')) {
      return '/manage/color-palette';
    }
    else if (this.permissions.value.includes('DashboardDesign')) {
      return '/manage/dashboard';
    }
    else if (this.permissions.value.includes('GlobalFunctionManagement')) {
      return '/manage/global-functions';
    }
    else if (this.permissions.value.includes('BussionConnectorManagement')) {
      return '/manage/visual';
    }
    else if (this.permissions.value.includes('LicenseManagement')) {
      return '/manage/license';
    }
    else if (this.permissions.value.includes('SystemConfiguration')) {
      return '/manage/ldap-config';
    }
    else if (this.permissions.value.includes('SystemConfiguration')) {
      return '/manage/password-policy';
    }
    else {
      return '/landing/user';
    }
  }
}
