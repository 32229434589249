import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { RoleResponse, Role } from 'src/app/models/responses/rolesResponse';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getRoles() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getRoles, param).pipe(
      map((response: RoleResponse) => {
        return response.message;
      })
    );
  }

  getUsersRoles(userID: string) {
    let param = { 
      Token: this.authService.getToken(),
      UserId: userID
    };
    return this.http.post(Endpoints.getUserRoles, param).pipe(
      map((response: RoleResponse) => {
        return response.message;
      })
    );
  }

  upsertRole(role: Role) {
    let param = { 
      Token: this.authService.getToken(),
      Role: role
    };
    return this.http.post(Endpoints.upsertRole, param).pipe(
      map((response: RoleResponse) => {
        return response.message;
      })
    );
  }

  deleteRole(role: Role) {
    let param = { 
      Token: this.authService.getToken(),
      RoleId: role.roleId
    };
    return this.http.post(Endpoints.deleteRole, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }
}
