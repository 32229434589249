import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { Share } from 'src/app/models/responses/shareResponse';

@Injectable({
  providedIn: 'root'
})
export class MailServiceService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  upsertMailSceduler(item: any) {
    let param = {
      Token: this.authService.getToken(),
      Scheduler:item
    }

    return this.http.post(Endpoints.sceduleDashboard, param).pipe(
      map((response: any) => {
        return response.message;


      })
    );


  }

  getMailSceduler(DashboardId: any) {
    let param = {
      Token: this.authService.getToken(),
      DashboardId:DashboardId
    }

    return this.http.post(Endpoints.getSceduleDashboard, param).pipe(
      map((response: any) => {
        return response.message;


      })
    );


  }

  deleteMailSceduler(SceduleId: any) {
    let param = {
      Token: this.authService.getToken(),
      ScheduleId:SceduleId
    }

    return this.http.post(Endpoints.DeleteDashboardScheduler, param).pipe(
      map((response: any) => {
        return response.message;


      })
    );


  }
}
