<div class="performance-modal">
    <div class="perf-title">
        <span> {{"DataStoreFormPage.DashboardPerformance"|translate}}</span>
        <mat-icon mat-dialog-close>clear</mat-icon>
    </div>
    <div class="mini-action-section">

        <div (click)="expandAll()">
            <mat-icon>fullscreen_exit</mat-icon>
            <small>{{"Common.ExpandAll"|translate}}</small>
        </div>
    </div>
    <div class="perf-body">

        <div class="perf-list-item" *ngFor="let item of list">
            <app-list-item-with-icon [item]="item" (itemOnclickEvent)="clickItem()" [buttonsList]="buttonsList"
                smallTitle="Execution time"></app-list-item-with-icon>
            <div class="perf-detail" *ngIf="item.open">
                <div style="display: flex;justify-content: space-between;">
                    <div style="flex: 1 1 0;" *ngIf="item.core.widget.measures.length>0">
                        <div class="perf-detail-title">
                            <mat-icon [svgIcon]="'measure'"></mat-icon>
                            <div>{{"WidgetFormPage.StepThree"|translate}}</div>
                        </div>
                        <span *ngFor="let measure of item.core.widget.measures">{{measure.name}}</span>
                    </div>
                    <div style="flex: 1 1 0;" *ngIf="item.core.widget.dimensions.length>0">
                        <div class="perf-detail-title">
                            <mat-icon [svgIcon]="'dimension'"></mat-icon>
                            <div>{{"WidgetFormPage.StepTwo"|translate}}</div>
                        </div>
                        <span *ngFor="let dimension of item.core.widget.dimensions">{{dimension.fieldName}}</span>
                    </div>
                </div>


            </div>

        </div>
    </div>

</div>