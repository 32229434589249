<div class="datastore-list" style="overflow: hidden;">
    <div class="container">
        <!-- <h2 style="margin-left:40px">{{ 'DataStorePage.MyData' | translate }} </h2> -->
        <div class="fixed-top">
            <!-- <app-bread-crumb [elements]="routeList"></app-bread-crumb> -->


            <div style="display: flex;align-items: center;justify-content: flex-end;">
                <div class="respnsive-search" style="flex-basis:40%;margin-right: 1rem;">
                    <app-search-input [height]="'36px'" (filterData)="filterData($event)" [datas]="allDataStores"
                        [searchColumns]="searchColumns"></app-search-input>
                </div>
                <!-- <button (click)="openDataStoreComponent($event,false)" class="add-datastore-button"
                    style="color:white">{{'DataStorePage.AddNewData' | translate }}</button> -->
                <button (click)="openDataStoreComponent($event,false)" mat-raised-button
                    class="custom-primary-button button-md w-100">
                    <span>{{'DataStorePage.AddNewData' | translate }}</span>
                </button>
            </div>
        </div>
        <div class="list-container">

            <app-data-list [previewButton]="true" [datas]="dataStores" [columns]="columns"
                (dataFunction)="actionButtonClick($event)">
            </app-data-list>

            <div class="loading-overlay" *ngIf="displayLoading">
                <div class="loading-spinner"></div>
            </div>


            <div style="text-align: center;width: 100%;height: 100%;color:rgb(134,153,217);font-weight: bold;font-size: 1rem;box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);min-height: 50vh;display: flex;align-items: center;justify-content: center;flex-direction: column;"
                *ngIf="!paginatedDataStores.length&&!displayLoading">

                <svg style="width:5rem" fill="#8699d9" viewBox="0 0 32 32" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" stroke="#8699d9">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="M11.086 5.5l2.457 2.414 0.629 0.586h15.829v18h-28v-21h9.086zM12 3.5h-10c-1.105 0-2 0.896-2 2v21c0 1.105 0.895 2 2 2h28c1.105 0 2-0.895 2-2v-18c0-1.104-0.895-2-2-2h-15z">
                        </path>
                    </g>
                </svg>

                <p style="font-size:1.5rem">{{ 'DataStorePage.NoData' | translate }}</p>
                <p style="font-weight: lighter;">{{ 'DataStorePage.UploadData' | translate }}</p>
            </div>
            <!-- <mat-paginator *ngIf="paginatedDataStores.length" [length]="dataStores.length" [pageSize]="itemsPerPage"
                [pageSizeOptions]="[]" (page)="paginateDataStores($event)"></mat-paginator> -->
        </div>

    </div>
</div>