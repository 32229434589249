import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';
import Drawflow from 'drawflow';
import { concatMap } from 'rxjs/operators';
import { DataStore } from 'src/app/models/responses/dataStoreResponse';
import { Flow } from 'src/app/models/responses/flowScript';
import { Node } from 'src/app/models/responses/nodeResponse';
import { ArchitectureService } from 'src/app/services/architecture/architecture.service';




interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  data: any;
}


@Component({
  selector: 'app-server-arch',
  templateUrl: './server-arch.component.html',
  styleUrls: ['./server-arch.component.scss']
})


export class ServerArchComponent implements OnInit {

  TREE_DATA: {
    name: string;
    children?: any[];
    icon: string,
  }[] = [];

  bussionServers: string[] = []
  bussionConnectors: any[] = []
  bussionDataNodes: Node[] = []
  dataFlows: Flow[] = []
  dataStores: DataStore[] = []



  private _transformer = (node: {
    name: string;
    children?: any[];
  }, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      data: node
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private archService: ArchitectureService) {

  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;


  ngOnInit(): void {
    this.getTree()
  }

  getTree() {


    this.archService.refreshData().pipe(
      concatMap(response => {
        if (response.result === true) {
          console.log('refreshed')
        }
        return this.archService.getBussionServers();
      }),
      concatMap(response => {
        if (response.result === true) {
          this.bussionServers = response.message
        }
        return this.archService.getBussionConnectors();
      }),
      concatMap(response => {
        if (response.result === true) {
          this.bussionConnectors = response.message
        }
        return this.archService.getBussionDataNodes();
      }),
      concatMap(response => {
        if (response.result === true) {
          this.bussionDataNodes = response.message
        }
        return this.archService.getBussionDataFlows();
      }),
      concatMap(response => {
        if (response.result === true) {
          this.dataFlows = response.message
        }
        return this.archService.getBussionDataStores();
      }),
      concatMap(response => {
        if (response.result === true) {
          this.dataStores = response.message
        }
        return this.archService.getBussionConnectorTasks();
      })
    ).subscribe(res => {

      this.bussionServers.map(item => {
        this.TREE_DATA.push({
          name: item,
          icon: 'server',
          children: this.bussionConnectors.map(con => {
            return {
              name: con.name,
              icon: 'connection',
              children: this.dataFlows.filter(x => x.bussionConnectorId == con.nodeId).map(flow => {
                return {
                  name: flow.name,
                  icon: 'flow',
                  children: []
                }
              })
            }
          }).concat(
            this.bussionDataNodes.map(nd => {
              return {
                name: nd.name,
                icon: 'node',
                children: this.dataStores.map(ds => {
                  return {
                    name: ds.name,
                    icon: 'dataStore',
                    children: []
                  }
                })
              }
            })

          )
        })
      })
     
      this.dataSource.data = this.TREE_DATA;
    })
  }

  
}
