<app-custom-modal [title]="'FlowPage.FlowLog'|translate" [subtitle]="''" img="/assets/images/hypertext.png">
    <div class="form-body" body>
        <div class="content-area">
            <div class="empty-wrapper" *ngIf="flowLogDropdownMenu.length<=0">
                <img src="/assets/images/log-icon.png" />
                <span>{{"FlowPage.NoSessionText"|translate}}</span>
            </div>
            <div class="list-area" *ngIf="flowLogDropdownMenu.length>0">
                <app-custom-dropdown-menu-with-search [sortChangeDisable]="true"  (endIconEmitter)="deleteSession($event)" [height]="'90%'"
                    [dropDownSettings]="flowLogDropdownSettings" [menudata]="flowLogDropdownMenu"
                    (selectElement)="sessionSelected($event)" [multipleSelection]="flowLogDropdownSettings.multipleSelection" [disableIconFilter]="true">
                </app-custom-dropdown-menu-with-search>
            </div>
            <div class="nav-area" *ngIf="flowLogDropdownMenu.length>0">
                <div *ngIf="selectedSessions.length<=0" class="empty-wrapper">
                    <img src="/assets/images/log-icon.png" />
                    <span>{{"FlowPage.NoLogText"|translate}}</span>
                </div>
                <mat-tab-group *ngIf="selectedSessions.length" style="height: 90%;">
                    <mat-tab *ngFor="let item of tabs">
                        <ng-template mat-tab-label>
                            <mat-icon [svgIcon]="item.icon" style="margin-right: 5px;"></mat-icon>
                            {{item.title}}
                        </ng-template>
                        <div class="tab-content-area">
                            <div *ngFor="let selectedSession of selectedSessions">
                                <div *ngIf="!item.arrayfrom" class="message-text">
                                    <div>
                                        <h3>{{data?.flow?.name}}</h3>
                                    </div>
                                    <div>
                                        <mat-icon [svgIcon]="'calendar'"></mat-icon>
                                        <div
                                            style="display: flex;align-items: center;flex-direction: column;font-size: x-small;line-height: 1;">
                                            <span> {{TimeConverter(selectedSession[item.messageKey2])}}</span>
                                            <span>{{TimeConverter(selectedSession[item.messageKey3])}}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <mat-icon
                                            *ngIf="selectedSession[item.messageKey].includes('error')&&!selectedSession[item.messageKey].includes('without error')"
                                            [svgIcon]="'error'"></mat-icon>
                                        <mat-icon
                                            *ngIf="selectedSession[item.messageKey].includes('success')||selectedSession[item.messageKey].includes('without error')"
                                            [svgIcon]="'success'"></mat-icon>
                                        <span>{{selectedSession[item.messageKey]}}</span>
                                    </div>
                                </div>
                                <div *ngIf="item.arrayfrom">
                                    <div>

                                        <!-- <div *ngIf="selectedSession[item.arrayfrom].length<=0" class="empty-wrapper">
                                            <span>{{"FlowPage.NoLogTextNav"|translate}}</span>
                                        </div> -->

                                        <ul class="sessions" *ngIf="selectedSession[item.arrayfrom].length>0">
                                            <li *ngFor="let arritem of selectedSession[item.arrayfrom]"
                                                [ngClass]="{success: arritem[item.messageKey]?.includes('success')||arritem[item.messageKey]?.includes('without error')}">
                                                <div class="time" *ngIf="item.title!=='Execution Log'">
                                                    {{arritem[item.messageKey2]?
                                                    arritem[item.messageKey2]+' @ '+arritem[item.messageTitle]:
                                                    arritem[item.messageTitle]}}</div>

                                                <div class="time" *ngIf="item.title=='Execution Log'">
                                                    {{selectedSession.script.description}}</div>

                                                <p style="font-size: large;">
                                                    <span class="key-text" *ngIf="item.messageTitle2">
                                                        {{arritem[item.messageTitle2]}}
                                                        :</span>
                                                    {{ arritem[item.messageKey] }}
                                                </p>
                                            </li>

                                        </ul>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>

        </div>

        <!-- <div *ngFor="let item of data">
            <mat-accordion>

                <mat-expansion-panel style="margin-top: 5px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="bold-text">Session Details</span>
                        </mat-panel-title>
                        <mat-panel-description>
                            <mat-icon
                                *ngIf="item.sessionLog.includes('error')&&!item.sessionLog.includes('without error')"
                                [svgIcon]="'error'"></mat-icon>
                            <mat-icon
                                *ngIf="item.sessionLog.includes('success')||item.sessionLog.includes('without error')"
                                [svgIcon]="'success'"></mat-icon>

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="log-text">
                        <mat-icon class="small-icon"
                            *ngIf="item.sessionLog.includes('error')&&!item.sessionLog.includes('without error')"
                            [svgIcon]="'error'"></mat-icon>
                        <mat-icon class="small-icon"
                            *ngIf="item.sessionLog.includes('success')||item.sessionLog.includes('without error')"
                            [svgIcon]="'success'"></mat-icon>

                        <div style="margin-left: 10px;"> {{item.sessionLog}}</div>
                    </div>
                    <div *ngIf="item.executionLog.length>0">
                        <div class="bold-text area-title" style="margin-top: 10px;">Execution Log</div>
           
                        <div>
                            <div *ngFor="let ex of item.executionLog" class="log-text">
                                <div>
                                    <mat-icon class="small-icon"
                                        *ngIf="ex.message.includes('error')&&!item.sessionLog.includes('without error')"
                                        [svgIcon]="'error'"></mat-icon>
                                    <mat-icon class="small-icon"
                                        *ngIf="ex.message.includes('success')||item.sessionLog.includes('without error')"
                                        [svgIcon]="'success'"></mat-icon>

                                </div>

                                <div style="margin-left: 10px;">

                                    <span class="bold-text">{{ex.scriptName}}</span> {{ex.message}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>

                    <div *ngIf="item.explainations.length>0">
                        <div class="bold-text area-title" style="margin-top: 10px;">Explanations</div>
                   
                        <div *ngFor="let explan of item.explainations" class="explan-text">
                            <mat-icon [svgIcon]="'arrow-right'"></mat-icon>
                            <span class="bold-text">{{explan.scriptName}}</span>
                            {{explan.value}}
                        </div>

                    </div>
                    <br>
                    <div *ngIf="item.variables.length>0">
                        <div class="bold-text area-title" style="margin-top: 10px;">Variables</div>
                        
                        <div *ngFor="let variable of item.variables" class="explan-text">
                            <mat-icon [svgIcon]="'arrow-right'"></mat-icon>

                            <span class="bold-text">{{variable.scriptName}}</span>
                            {{variable.key}}: {{variable.value}}

                        </div>
                    </div>


                </mat-expansion-panel>
            </mat-accordion>

        </div>
 -->
    </div>



</app-custom-modal>