import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'src/app/services/core/core.service';
import { tap, switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { SystemParametersService } from 'src/app/services/system-parameters/system-parameters.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DashboardFormComponent } from '../dashboard/dashboard-form/dashboard-form.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { UploadConfigurationFormComponent } from 'src/app/components/upload-configuration-form/upload-configuration-form.component';
import { BussionLoginComponent } from 'src/app/components/bussion-login/bussion-login.component';
import { BussionMainService } from 'src/app/services/bussion-main/bussion-main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-system-parameters',
  templateUrl: './system-parameters.component.html',
  styleUrls: ['./system-parameters.component.scss']
})
export class SystemParametersComponent implements OnInit {

  constructor(
    private systemParametersService: SystemParametersService,
    private formBuilder: UntypedFormBuilder,
    private coreService: CoreService,
    private permissionService: PermissionService,
    private translate: TranslateService,
    private http: HttpClient,
    private modalService: ModalService,
    private bussionMainService: BussionMainService,
    private router:Router,
  ) { }
  isDebugger: boolean;
  isCaptcha: boolean;
  pageForm: UntypedFormGroup;
  maxTries: string;
  version: string;
  permissions: string[] = [];
  backupConfiguration: string;
  restoreConfiguration: string;
  downloadConfiguration: string;

  ngOnInit(): void {
    this.pageForm = new UntypedFormGroup({
      debuggerControl: new UntypedFormControl(this.isDebugger),
      maxTriesControl: new UntypedFormControl(this.maxTries),
      captchaControl: new UntypedFormControl(this.isCaptcha)
    })

    this.getSystemParameters()
    this.getBussionVersion()

    this.getCaptcha()

    this.getUserRoleList()
  }
  openBussionLogin(event) {
    this.router.navigate(['/landing/bussion-login'])
  }

  openLanguageSetting(event){
    this.router.navigate(['/landing/language-setting'])

  }
  
  openBussionMain() {
    if(this.permissions.includes('SystemConfiguration')){
      this.router.navigate(['/landing/bussion-main'])
    }
  }
  
  getCaptcha() {
    this.systemParametersService.getCaptcha().subscribe((res) => {
      if (res == false) {

        this.isCaptcha = false
      } else {
        this.isCaptcha = true
      }
      this.pageForm.patchValue({ captchaControl: this.isCaptcha })
    })
  }

  getBussionVersion() {
    this.systemParametersService.getBussionVersion().subscribe((res) => {
      this.version = res;
    })
  }

  getSystemParameters() {
    this.systemParametersService.getSystemParameters().subscribe((res: any) => {
      if (res[0].DebuggerEnabled == 'False') {
        this.isDebugger = false;
      } else {
        this.isDebugger = true;
      }
      this.pageForm.patchValue({ debuggerControl: this.isDebugger })

      if (res[0].MaxIllegalTriesForDataOps) {
        this.maxTries = res[0].MaxIllegalTriesForDataOps;
        this.pageForm.patchValue({ maxTriesControl: this.maxTries })
      }

    })
  }

  getUserRoleList() {
    this.permissionService.getUserRoleList().subscribe(roleList => {
      this.permissions = roleList;
    });
  }

  saveCaptcha(event: MatSlideToggleChange) {
    this.isCaptcha = event.checked;

    this.systemParametersService.setCaptcha(this.isCaptcha)
      .subscribe(res => {
        if (res) {
          this.coreService.showSuccessMessage(this.translate.instant('SystemParametersPage.SystemParametersConfig'))
        }
        else {
          this.coreService.showErrorMessage(this.translate.instant('SystemParametersPage.SystemParametersConfigFaild'))
        }
      });
  }

  saveDebugger(event: MatSlideToggleChange) {
    this.isDebugger = event.checked;

    this.systemParametersService.changeDebugState(this.isDebugger ? 'TRUE' : 'FALSE')
      .subscribe(res => {
        if (res) {
          this.coreService.showSuccessMessage(this.translate.instant('SystemParametersPage.SystemParametersConfig'))
        }
        else {
          this.coreService.showErrorMessage(this.translate.instant('SystemParametersPage.SystemParametersConfigFaild'))
        }
      });
  }

  saveMaxTries() {
    this.systemParametersService.setMaxIllegalTries(this.maxTries)
      .subscribe(res => {
        if (res) {
          this.coreService.showSuccessMessage(this.translate.instant('SystemParametersPage.SystemParametersConfig'))
        }
        else {
          this.coreService.showErrorMessage(this.translate.instant('SystemParametersPage.SystemParametersConfigFaild'))
        }
      });
  }

  backupBussionConfiguration() {
    this.systemParametersService.backupBussionConfiguration().subscribe(res => {
      this.backupConfiguration = res
    })
  }
  restoreBussionConfiguration() {
    this.systemParametersService.restoreBussionConfiguration().subscribe(res => {
      this.restoreConfiguration = res
    })
  }
  downloadCurrentConfiguration() {
    this.systemParametersService.downloadCurrentConfiguration().subscribe(res => {
      this.downloadConfiguration = res
    })
  }

  downloadZipFile() {
    this.systemParametersService.downloadCurrentConfiguration().subscribe(res => {
      // this.downloadConfiguration = res
      const downloadUrl = environment.baseUrl + res;
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/zip');
      const options = { headers: headers, responseType: 'blob' as 'json' };
      this.http.get<Blob>(downloadUrl, options).subscribe(blob => {
        const downloadLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = 'backup.zip';
        downloadLink.click();
        URL.revokeObjectURL(url);
      });
    })
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
  }
  openDashboard(event: any) {
    let data = {
      type: event.type as string,
    }
    this.modalService.openModal(data, UploadConfigurationFormComponent, 'auto', '800px').subscribe(result => {
      if (result === true) {

      }
    });
  }
}