<app-custom-modal [title]="'FilterComponent.WidgetSelectionForm'" [subtitle]="'FilterComponent.WidgetSelectionText'"
    img="/assets/images/widgetSelection.png">
    <div body style="height: 100%;">
        <div class="search-area">
            <div class="action-button-group">
                <button mat-stroked-button color="primary" *ngIf="selectedWidgets.length>0"
                    (click)="saveSelectedItems()">{{"Common.Add" |translate}}</button>
                <button mat-stroked-button *ngIf="selectedWidgets.length>1" (click)="clearAllSelectedItems()">
                    {{"FilterComponent.ClearAllSelection" |translate}}</button>
            </div>
            <div class="searchbox-container">
                <Input ngDefaultControl (keyup.enter)="getWidgets()" [(ngModel)]="query" type="text"
                    placeholder="Widgetlar içinde ara" class="searchinput" />
                <mat-icon *ngIf="query.length>0" (click)="clearTextBox() ">clear</mat-icon>

                <mat-icon (click)="getWidgets()">search</mat-icon>
            </div>
        </div>
        <div class="widget-area">
            <div class="empty-widget" *ngIf="allWidgets.length<=0&&selectedWidgets.length<=0&&!selectedElement">
                <div *ngIf="!loading&&allWidgets.length<=0&&selectedWidgets.length<=0">
                    <img style="width: 40%;" src="/assets/images/searchWidget.jpg" />
                    <small>{{"FilterComponent.WidgetSelectionImgText"|translate}}</small>
                </div>
                <div *ngIf="loading">
                    <mat-spinner [diameter]="60"></mat-spinner>
                </div>
            </div>
            <div class="selection-panel" *ngIf="allWidgets.length>0||selectedWidgets.length>0">
                <div [class]="getclasname(item)+' selection-inside'"
                    *ngFor="let item of allWidgets.length>0?allWidgets:selectedWidgets"
                    style="width: 90%;height: 250px;">
                    <div style="display: flex;justify-content: end;align-items: center;">
                        <mat-icon style="transform: scale(0.6);" matListIcon svgIcon="zoom"
                            (click)="zoomWidget(item)"></mat-icon>

                        <mat-checkbox [checked]="getclasname(item)=='none'?false:true"
                            (change)="selectWidget($event,item)">
                        </mat-checkbox>

                    </div>

                    <app-widget-preview style="display: block;height: 90%;" [showMenu]="false" [showZoom]="false"
                        [showShare]="false" [widget]="item" [showEdit]="false" [showDelete]="false"
                        (expandEvent)="zoomWidget($event)"></app-widget-preview>
                </div>
            </div>
            <div class="widget-cont" [style.width]="allWidgets.length<=0?'100%':'75%'" *ngIf="selectedElement"
                [class]="getclasname(selectedElement)">
                <div style="display: flex;justify-content: end;align-items: center;" *ngIf="!loading">

                    <mat-checkbox [checked]="getclasname(selectedElement)=='none'?false:true"
                        (change)="selectWidget($event,selectedElement)">
                    </mat-checkbox>

                </div>
                <app-widget-preview  *ngIf="!loading" style="height: 100%;" [showMenu]="false" [showZoom]="false" [showShare]="false"
                    [widget]="selectedElement" [showEdit]="false" [showDelete]="false"></app-widget-preview>
            </div>
        </div>
    </div>
</app-custom-modal>