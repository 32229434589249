import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridsterWidget, Widget } from 'src/app/models/responses/widgetLibraryResponse';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { WidgetService } from 'src/app/services/widget/widget.service';


@Component({
  selector: 'app-available-widget-selection',
  templateUrl: './available-widget-selection.component.html',
  styleUrls: ['./available-widget-selection.component.scss']
})
export class AvailableWidgetSelectionComponent implements OnInit {

  allWidgets: GridsterWidget[] = []
  query: string = ''
  selectedElement: GridsterWidget
  selectedWidgets: GridsterWidget[] = []
  loading: boolean = false
  // @Output() saveselectedWidgets:EventEmitter<any>=new EventEmitter<any>()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      saveselectedWidgets: Function
    }, private dialogRef: MatDialogRef<AvailableWidgetSelectionComponent>, private widgetService: WidgetService, private scriptLoader: ScriptLoaderService) { }


  ngOnInit(): void {

  }

  getWidgets() {
    this.loading = true

    this.widgetService.getWidgetRepository(this.query).subscribe(res => {
      this.loading = false

      res.map((widget, key) => {
        if (document.getElementById(widget.widgetId)) {
          document.getElementById(widget.widgetId).remove()
        }

        console.log(this.allWidgets)
        if (!this.allWidgets.some(x => x.designerContainer.widgetId == widget.widgetId)) {
          this.allWidgets.push({ cols: widget.widget.layout.cols, rows: widget.widget.layout.rows, y: widget.widget.layout.y, x: widget.widget.layout.x, designerContainer: widget });
          this.createWidget(widget);
        }
      })
    })
  }


  private createWidget(widgetResponse: any) {
    this.scriptLoader.addScriptFileToHead(widgetResponse.widgetLibraries);
    this.scriptLoader.runWidgetCode(widgetResponse);
  }

  closeDialog() {
    this.dialogRef.close()
  }

  clearTextBox() {
    this.query = ''
    this.selectedElement = null
    this.allWidgets = []
  }

  selectWidget(event: HTMLElement, widget: GridsterWidget) {
    if (this.selectedWidgets.some(x => x.designerContainer.widgetId == widget.designerContainer.widgetId)) {
      this.selectedWidgets = this.selectedWidgets.filter(x => x.designerContainer.widgetId !== widget.designerContainer.widgetId)
    } else {
      this.selectedWidgets.push(widget)
    }
  }

  getclasname(widget: any) {
    if (this.selectedWidgets.some(x => x.designerContainer.widgetId == widget.designerContainer.widgetId)) {
      return 'selected-Widget'
    } else {
      return 'none'
    }
  }

  clearAllSelectedItems() {
    this.selectedWidgets = []
  }

  saveSelectedItems() {
    this.closeDialog()
    this.data.saveselectedWidgets(this.selectedWidgets)
  }

  zoomWidget(widget: GridsterWidget) {
    this.loading = true
    if (this.selectedElement)
      this.allWidgets.push(this.selectedElement)

    this.allWidgets = this.allWidgets.filter(x => x.designerContainer.widgetId !== widget.designerContainer.widgetId)
    this.selectedElement = widget

    this.allWidgets.map(item => {
      this.createWidget(item.designerContainer)
    })

    this.createWidget(this.selectedElement.designerContainer)
    this.loading = false
  }
}
