<div style="height: 100%;padding: 5px;">
    <div class="widget-header">
        <div *ngIf="shareObject&&shareObject?.enableTitle" class="title">
            <span>{{widget.title}}</span>
        </div>
        <div class="sub-title" *ngIf="widget.subTitle.length != 0">
            {{widget.subTitle}}</div>

        <div class="sub-title filter-area"
            *ngIf="groupedDataKeys.length>0&&shareObject&&this.shareObject?.enableFilter">
            <mat-icon [svgIcon]="'filter'"></mat-icon>
            <div class="filter-container" *ngFor="let item of getObjectKeys(groupedData)">
                <span class="filternamespan">
                    {{item}}</span>
                <div style="display: flex;" *ngFor="let op of getObjectKeys(groupedData[item])">
                    <span style="font-weight: bold;margin-right: 5px;">{{("FilterComponent."+op|translate)}}</span>
                    <div *ngFor="let d of groupedData[item][op];let i = index">
                        {{d.fieldValue}}<span>,</span>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="widget-preview" id="widget-{{this.canvasId}}"></div>

</div>

<!-- <div class="sub-title filter-area" *ngIf="groupedDataKeys.length>0&&showFilter">
    <mat-icon [svgIcon]="'filter'"></mat-icon>
    <div class="filter-container" *ngFor="let item of getObjectKeys(groupedData)">
        <span class="filternamespan">
            {{item}}</span>
        <div style="display: flex;" *ngFor="let op of getObjectKeys(groupedData[item])">
            <span style="font-weight: bold;margin-right: 5px;">{{("FilterComponent."+op|translate)}}</span>
            <div *ngFor="let d of groupedData[item][op];let i = index">
                {{d.fieldValue}}<span>,</span>
            </div>
        </div>
    </div>
</div> -->