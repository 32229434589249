import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-selection-list-with-search',
  templateUrl: './selection-list-with-search.component.html',
  styleUrls: ['./selection-list-with-search.component.scss']
})
export class SelectionListWithSearchComponent implements OnInit {

  constructor() { }
  currentPage: number = 1
  _data: any[] = []
  @Input() pageSize: number = 5
  @Input() title: string = ''
  @Input() selectedDatas: string[] = []


  @Input() dragable: boolean = false
  dragEnable: boolean = false


  totalPage: number = 1
  @Input() set data(value: any[]) {
    this._data = value;
    this.filteredData = value
    this.totalPage = Math.ceil(this.filteredData.length / this.pageSize);

    this.updatePaginate(this.pageSize)
  }

  @Input() keys: { valueKey: string, titleKey: string } = { valueKey: '', titleKey: '' }
  compareFunction = (o1: any, o2: any) => { o1 === o2 };

  filteredData: any[] = []
  paginatedData: any[] = []

  @Output() changeselectedList: EventEmitter<any> = new EventEmitter<any>()




  ngOnInit(): void {
  }

  previousPage() {
    this.currentPage--;
    this.updatePaginate(this.pageSize);
  }
  nextPage() {
    this.currentPage++;
    this.updatePaginate(this.pageSize);
  }

  updatePaginate(pageSize: number) {
    const start = (this.currentPage - 1) * pageSize;
    const end = start + pageSize;
    var list = []
    var selectedList = []

    this.filteredData.map(item => {
      if (!this.selectedDatas.some(x => x == item[this.keys.valueKey]))
        list.push({ ...item, selected: this.selectedDatas.some(x => x == item[this.keys.valueKey]) })
      // else
      //   selectedList.push({ ...item, selected: this.selectedDatas.some(x => x == item[this.keys.valueKey]) })
    })

    this.selectedDatas.map(item => {
      var selectedObject = this.filteredData.find(x => x[this.keys.valueKey] == item)
      selectedList.push({ ...selectedObject, selected: true })
    })

    list = selectedList.concat(list)
    this.paginatedData = list.slice(start, end);
  }


  applyFilter(filtervalue: string) {
    this.filteredData = this._data.filter((dashboard) => {
      return dashboard.title.toLowerCase().includes(filtervalue.toLowerCase());
    });
    this.totalPage = Math.ceil(this.filteredData.length / this.totalPage);
    this.currentPage = 1;
    this.updatePaginate(this.pageSize);
  }


  changeselectedListFunc(event: any) {
    if (event.options[0]._selected) {
      this.selectedDatas.push(event.options[0]._value)
    } else {
      this.selectedDatas = this.selectedDatas.filter(x => x !== event.options[0]._value)
    }
    this.changeselectedList.emit(this.selectedDatas)
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.filteredData, event.previousIndex, event.currentIndex);
    moveItemInArray(this.paginatedData, event.previousIndex, event.currentIndex);
    this.saveSort()
  }

  saveSort() {
    var sortedList = []
    

    this.paginatedData.map(d => {
      if (d.selected)
        sortedList.push(d[this.keys.valueKey])
    })

    this.selectedDatas = sortedList
    this.changeselectedList.emit(sortedList)
  }

  openCloseDrag() {
    this.dragEnable = !this.dragEnable;
    if (this.dragEnable) {
      this.updatePaginate(this._data.length)
    } else {
      this.saveSort()
      this.updatePaginate(this.pageSize)
    }

  }

}
