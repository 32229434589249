import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {

  constructor(private router: Router) { }
  _elements: any[] = [{ path: '', name: 'Home' }, { path: '', name: 'Home' }, { path: '', name: 'Title' }]
  lastelement: any
  @Input('elements')
  set in(elements) {
    this.lastelement = elements[elements.length - 1]
    this._elements = elements.slice(0, -1);
  }

  ngOnInit(): void {


  }

  redirectPage(selectedEelement) {
    this.router.navigate([selectedEelement.path])
  }

}
