import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { License } from 'src/app/models/responses/licenseResponse';
import { User } from 'src/app/models/responses/usersResponse';
import { CoreService } from 'src/app/services/core/core.service';
import { LicenseService } from 'src/app/services/license/license.service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss']
})
export class LicenseComponent implements OnInit {

  title: string = "License Update";

  license: License = {
    customer: '',
    dashboardCount: 0,
    domain: '',
    host: '',
    licenseDate: '',
    licenseKey: '',
    scope: '',
    type: '',
    userCount: 0,
    adminUserCount: 0,
    bussionModules: ''
  }

  constructor(
    private licenseService: LicenseService,
    private coreService: CoreService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getLicense();
  }

  getLicense() {
    this.licenseService.getLicense().subscribe(license => {
      this.license = license;
      if (license?.bussionModules)
        localStorage.setItem('licence', JSON.stringify(license.bussionModules.split(',')))
    });
  }

  upsertLicense() {
    this.licenseService.upsertLicense(this.license.licenseKey).subscribe(result => {
      this.coreService.showSuccessMessage(this.translate.instant("ErrorMessages.LicenseUpdate"));
    });
  }

}
