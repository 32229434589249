import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PermissionService } from '../permission/permission.service';
import { CoreService } from '../core/core.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxCaptchaService } from '@binssoft/ngx-captcha';
import { HttpClient } from '@angular/common/http';
import { BussionMainService } from '../bussion-main/bussion-main.service';
import { LoginRequest } from 'src/app/models/requests/loginRequest';
import { DashboardService } from '../dashboard/dashboard.service';
import { LicenseService } from '../license/license.service';
import { DeployAppService } from '../deploy-app/deploy-app.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loginRequest: LoginRequest = {
    Username: '',
    Password: ''
  };

  constructor(private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private permissionService: PermissionService,
    private coreService: CoreService,
    private translate: TranslateService,
    private appService: DeployAppService,
    private licenseService: LicenseService,
    private captchaService: NgxCaptchaService,
    private http: HttpClient,
    private bussionMainService: BussionMainService,) { }


  getAuthService() {
    return this.authService;
  }
  // getDashboardService() {
  //   return this.dashboardService;
  // }

  getAppService() {
    return this.appService;
  }
  getLicenseService() {
    return this.licenseService
  }
  getRouter() {
    return this.router;
  }
  getSpinner() {
    return this.spinner;
  }
  getTranslate() {
    return this.translate
  }
  getLoginRequest() {
    return this.loginRequest;
  }
  getCoreService() {
    return this.coreService;
  }


}
