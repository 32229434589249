<div class="left-menu-container">
    <div class="logo-area">
        <a [routerLink]="['/']">
            <img src="/assets/images/logoWithoutTetx.png" />
        </a>
    </div>
    <div class="menu-area">
        <div *ngFor="let item of _menuList" [ngClass]="{'active-menu': item.active}" class="menu-item">
            <div matTooltipPosition="right" [matTooltip]="item.name|translate" (click)="clickMenu(item)">
                <mat-icon style="color: #5E6DA6;" matListIcon [svgIcon]="item.icon"></mat-icon>
            </div>
        </div>
    </div>
</div>