<div class="dashboard-list">

    <div class="container">
        <div class="fixed-top">
            <!-- <app-bread-crumb [elements]="routeList"></app-bread-crumb> -->
            <div style="display: flex;align-items: center;justify-content: flex-end;">
                <div class="respnsive-search"  style="flex-basis:40%;margin-right: 1rem;">
                    <app-search-input height="36px" (filterData)="filterData($event)" [datas]="allDashboards"
                        [searchColumns]="searchColumns"></app-search-input>
                </div>
                <!-- <button (click)="openDesigner()" class="add-dashboard-button"
                    style="color:white">{{"DashboardPage.AddNewDesign" | translate
                    }}
                </button> -->
                <button (click)="openDesigner()" mat-raised-button
                class="custom-primary-button button-md w-100">
                <span>{{"DashboardPage.AddNewDesign"  | translate }}</span>
            </button>
            </div>
        </div>

        <div class="list-container">
            <app-data-list [datas]="dashboards" [columns]="columns"
                (dataFunction)="actionButtonsClick($event)"></app-data-list>

            <div class="loading-overlay" *ngIf="displayLoading">
                <div class="loading-spinner"></div>
            </div>


            <div style="text-align: center;width: 100%;height: 100%;color:rgb(134,153,217);font-weight: bold;font-size: 1rem;box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);min-height: 50vh;display: flex;align-items: center;justify-content: center;flex-direction: column;"
                *ngIf="showNoDashboards && !paginatedDashboards.length">

                <svg style="width: 5%;" viewBox="0 0 24 24" id="meteor-icon-kit__regular-dashboard" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M3 2C2.44772 2 2 2.44772 2 3V6C2 6.55228 2.44772 7 3 7H6C6.55228 7 7 6.55228 7 6V3C7 2.44772 6.55228 2 6 2H3ZM3 0H6C7.65685 0 9 1.34315 9 3V6C9 7.65685 7.65685 9 6 9H3C1.34315 9 0 7.65685 0 6V3C0 1.34315 1.34315 0 3 0ZM14 16H21C22.6569 16 24 17.3431 24 19V21C24 22.6569 22.6569 24 21 24H14C12.3431 24 11 22.6569 11 21V19C11 17.3431 12.3431 16 14 16ZM14 18C13.4477 18 13 18.4477 13 19V21C13 21.5523 13.4477 22 14 22H21C21.5523 22 22 21.5523 22 21V19C22 18.4477 21.5523 18 21 18H14ZM3 11H6C7.65685 11 9 12.3431 9 14V21C9 22.6569 7.65685 24 6 24H3C1.34315 24 0 22.6569 0 21V14C0 12.3431 1.34315 11 3 11ZM3 13C2.44772 13 2 13.4477 2 14V21C2 21.5523 2.44772 22 3 22H6C6.55228 22 7 21.5523 7 21V14C7 13.4477 6.55228 13 6 13H3ZM21 0C22.6569 0 24 1.34315 24 3V11C24 12.6569 22.6569 14 21 14H14C12.3431 14 11 12.6569 11 11V3C11 1.34315 12.3431 0 14 0H21ZM13 3V11C13 11.5523 13.4477 12 14 12H21C21.5523 12 22 11.5523 22 11V3C22 2.44772 21.5523 2 21 2H14C13.4477 2 13 2.44772 13 3Z"
                            fill="#8699d9"></path>
                    </g>
                </svg>

                <p style="font-size:1.5rem;margin-top: 0.5rem;">{{'DashboardPage.NoDashboardsYet' | translate
                    }}
                </p>
                <p style="font-weight: lighter;">{{'DashboardPage.NoDashboardsYetParagraph' | translate
                    }}</p>
            </div>
        </div>
        <!-- <mat-paginator *ngIf="dashboards.length" [length]="dashboards.length" [pageSize]="itemsPerPage"
            [pageSizeOptions]="[]" (page)="paginateDashboards($event)"></mat-paginator> -->
        <!-- 
            <app-self-service-designer [data]="{'type':'insert', 'dashboard':''}"></app-self-service-designer> -->
    </div>
</div>