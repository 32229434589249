import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MatStepper } from '@angular/material/stepper';
import { FormGroup, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-self-service-datastore-file',
  templateUrl: './self-service-datastore-file.component.html',
  styleUrls: ['./self-service-datastore-file.component.scss']
})
export class SelfServiceDatastoreFileComponent implements OnInit {
  @ViewChild('stepper') private formStepper: MatStepper;

  firstForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;
  modalTitle: string;
  showFileTypeSelection: boolean = true;
  showFileSelectModal: boolean = false;
  showHandsoneTable: boolean = false;
  fileAccept: string;
  file: File;
  formData = new FormData();
  fileName: string;
  rowData: any[];

  constructor(
    public dialogRef: MatDialogRef<SelfServiceDatastoreFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      Buttons: string[],
      text: string
    },
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    // this.modalTitle = this.data.title;
  }

  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
  };

  // Example load data from server
  onGridReady(params: GridReadyEvent) {
    params.api.sizeColumnsToFit();
  }

  // Example of consuming Grid Event
  onCellClicked(e: CellClickedEvent): void {
    console.log('cellClicked', e);
  }

  openFileSelect(fileType: string): void {
    this.fileAccept = fileType;
    this.showFileTypeSelection = false;
    this.showFileSelectModal = true;
  }

  onFileChange(event: any): void {
    this.file = event.target.files[0];
    this.fileName = event.target.files[0].name;
  }

  getFileExtension(fileName: string): string {
    const extension = fileName.split('.').pop();
    return extension.toLowerCase();
  }

  uploadFile(): void {
    const extension = this.getFileExtension(this.fileName);
  
    if (this.file && extension) {
      if (extension === 'xls' || extension === 'xlsx') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = reader.result as ArrayBuffer;
          const arrayBuffer = new Uint8Array(data);
          const workbook = XLSX.read(arrayBuffer, { type: 'array' });
  
          const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
  
          this.columnDefs = this.generateColumnDefs(jsonData[0]);
          this.rowData = jsonData;
  
          this.showFileSelectModal = false;
          this.showHandsoneTable = true;
        };
        reader.readAsArrayBuffer(this.file);
      } else if (extension === 'csv') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const csvData = reader.result as string;
          const jsonData = this.convertCsvToJson(csvData);
  
          this.columnDefs = this.generateColumnDefs(jsonData[0]);
          this.rowData = jsonData;
  
          this.showFileSelectModal = false;
          this.showHandsoneTable = true;
        };
        reader.readAsText(this.file);
      } else if (extension === 'json') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const jsonData = JSON.parse(reader.result as string);
  
          this.columnDefs = this.generateColumnDefs(jsonData[0]);
          this.rowData = jsonData;
  
          this.showFileSelectModal = false;
          this.showHandsoneTable = true;
        };
        reader.readAsText(this.file);
      } else if (extension === 'txt') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const txtData = reader.result as string;
  
          // TXT ile ilgili işlemler ?
  
          this.showFileSelectModal = false;
          this.showHandsoneTable = true;
        };
        reader.readAsText(this.file);
      } else if (extension === 'xml') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const xmlData = reader.result as string;
  
          // XML ile ilgili işlemler ?
  
          this.showFileSelectModal = false;
          this.showHandsoneTable = true;
        };
        reader.readAsText(this.file);
      } else {
        // Unsupported file extension
        console.error('Unsupported file extension.');
      }
    } else {
      // File or extension is missing
      console.error('File or extension is missing.');
    }
  }

  generateColumnDefs(data: any): ColDef[] {
    if (data) { // Add null check here
      return Object.keys(data).map((key) => {
        return { field: key };
      });
    } else {
      return [];
    }
  }
  convertCsvToJson(csvData: string): any[] {
    const lines = csvData.split('\n');
    const result = [];
    const headers = lines[0].split(',');

    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }

      result.push(obj);
    }

    return result;
  }


}