import { Component, OnInit } from '@angular/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DashboardPreviewComponent } from 'src/app/components/dashboard-preview/dashboard-preview.component';
import { BehaviorSubject } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ChatbotComponent } from 'src/app/components/chatbot/chatbot.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  tile: string = 'lightgreen';
  links = ["Users", "Groups", "Roles"];
  opened: boolean = true;
  permissions: string[] = [];
  showSection = false;
  licencePermissions = localStorage.getItem('licence')??''



  constructor(
    private permissionService: PermissionService,
    private route: ActivatedRoute, private router: Router,
    private dashboardService: DashboardService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // this.showSection = true;
    this.getUserRoleList();
    // this.permissionService.openedClosedbtn$.subscribe(openedClosedbtn => this.opened = openedClosedbtn);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        if (url === '/landing') {
          this.showSection = true;
        } else {
          this.showSection = false;
        }
      }
    });


    // Check if the current route is the same as the component's route
    const currentUrl = this.router.url;
    if (currentUrl === '/landing') {
      this.showSection = true;
    }
  }

  toggleGridster() {
    this.opened = !this.opened

    this.dashboardService.openedClosedbtn.next(false)
  }

  getUserRoleList() {
    this.permissionService.getUserRoleList().subscribe(roleList => {
      this.permissions = roleList;
    });
  }
  openChatbox() {
    this.dialog.open(ChatbotComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'chat-dialog-container',
    });
  }


}

