import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'src/app/services/core/core.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { environment } from 'src/environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-image-selectbox',
  templateUrl: './image-selectbox.component.html',
  styleUrls: ['./image-selectbox.component.scss']
})
export class ImageSelectboxComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('imageDialog') imageDialog: TemplateRef<any>;
  formData = new FormData();
  images: string[] = []
  imageBaseUrl: string = environment.imageUrl
  selectedImage: string = ''

  _albums = [
    {
      src: 'path-to-your-image.jpg',
      caption: 'Image caption',
      thumb: 'path-to-your-thumbnail.jpg'
    }
  ];


  constructor(private dashboardService: DashboardService, private coreService: CoreService,
    private translate: TranslateService, private clipboard: Clipboard, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getImages()
  }


  getImages() {
    this.dashboardService.ListPictures().subscribe(res => {
      this.images = res
      this._albums[0].src = res[0]
    })
  }

  imageSelect(fileInput) {
    var fileData = <File>fileInput.target.files[0];
    if (fileData.size > 3145728) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.SmallPhoto"));
      return;
    }
    var mimeType = fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.WrongFormatPhoto"));
      this.fileInput.nativeElement.value = "";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(fileData);
    this.addImage(fileData)
    // reader.onload = (_event) => {
    //   var avatar = reader.result;
    //   // debugger;
    //   //let base64 = new String((reader.result as string).split(',')[1]);
    //   // this.photoLoaded.emit(reader.result);
    //   this.addImage(reader.result)
    // }
  }

  addImage(result) {
    this.dashboardService.addPictures(result).subscribe(res => {
      this.getImages();
    })
  }

  copyLink(link) {
    const result = this.clipboard.copy(link)

    if (result)
      this.coreService.showSuccessMessage('Copied!');
  }

  deleteImage(item) {
    this.dashboardService.deletePictures(item?.split('/')[2]).subscribe(res => {
      this.getImages();

    })
  }


  openImage(image: string) {
    this.selectedImage = this.imageBaseUrl+image
    console.log(this.selectedImage)
    let dialogRef = this.dialog.open(this.imageDialog);
    dialogRef.afterClosed().subscribe(result => {
      // Note: If the user clicks outside the dialog or presses the escape key, there'll be no result
      if (result !== undefined) {
        if (result === 'yes') {
          // TODO: Replace the following line with your code.
          console.log('User clicked yes.');
        } else if (result === 'no') {
          // TODO: Replace the following line with your code.
          console.log('User clicked no.');
        }
      }
    })
  }


}
