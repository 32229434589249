import { Component, OnDestroy, OnInit, ɵcompileComponent, NO_ERRORS_SCHEMA, ViewContainerRef } from '@angular/core';

import { LoginRequest } from '../../models/requests/loginRequest';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { User, UserHomePage } from 'src/app/models/responses/usersResponse';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxCaptchaService } from '@binssoft/ngx-captcha';
import { Subject, Subscription } from 'rxjs';
import { concatMap, takeUntil, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BussionMainService } from 'src/app/services/bussion-main/bussion-main.service';
import { DynamicLoginComponent } from 'src/app/components/dynamic-login/dynamic-login.component';
import { CommonModule, NgFor, NgIf, NgStyle } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, NgModel, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LicenseService } from 'src/app/services/license/license.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  // templateUrl: './login.component.html',
  template: '',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: boolean = true;
  loginRequest: LoginRequest = {
    Username: '',
    Password: ''
  };
  dynamicContent: string;
  chaptcaEnabled: boolean;



  user: User = {
    avatar: "",
    userId: "",
    lastLogin: new Date(),
    accountStatus: 1,
    language: "",
    activeDashboards: [],
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    domain: "",
    token: "",
    organization: "",
    homePage: []
  };

  passwordAgain: string = "";
  isShownCreateAccount: boolean = false;
  warntext: string = ''
  captchaStatus: any = null;
  captchaConfig: any = {
    type: 1,
    length: 6,
    cssClass: 'custom',
    back: {
      stroke: "#2F9688",
      solid: "#f2efd2"
    },
    font: {
      color: "#000000",
      size: "35px"
    }
  };

  swiperList = [
    {
      id: 1,
      title: 'NEXT GENERATION DASHBOARDS',
      text: 'Create beautiful & visually appealing dashboards with drilldown and collabration capabilities.',
      img: '/assets/images/stats (1).png'
    },
    {
      id: 2,
      title: 'KPI MANAGEMENT',
      text: 'Track all the metrics that matter to your team, customers and bussinessPrevious.',
      img: '/assets/images/stats.png'
    },
    {
      id: 3,
      title: 'INSTANT ANALYTICS',
      text: 'Take the advantage of hot trends, oppurtunities, promotions and more. Identify events, patterns or run anomaly detection routines.',
      img: '/assets/images/pie-chart.png'
    }
  ]

  currentSwiper = 1
  displayLoading = 'flex'
  loginImage: string = ''


  constructor(
    private authService: AuthService,
    private licenseService: LicenseService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private permissionService: PermissionService,
    private coreService: CoreService,
    private translate: TranslateService,
    private captchaService: NgxCaptchaService,
    private http: HttpClient,
    private bussionMainService: BussionMainService,
    private viewContainerRef: ViewContainerRef
  ) {
    this.captchaStatus = false;
    this.permissionService.clearPermissions();
    this.getMultiTenantStatus();
    this.getChaptcaEnabled();
    this.myinterval = setInterval(() => {
      this.displayLoading = 'none'
      if (this.displayLoading == 'none') {
        clearInterval(this.myinterval);
      }
    }, 2000);
    this.getBussionLoginCss();
    this.getLoginRequest();
    this.getLoginRequest();
    this.getLoginImage()
  }

  myinterval: any

  getAuthService() {
    return this.authService;
  }
  getLicenseService() {
    return this.licenseService
  }
  getRouter() {
    return this.router;
  }
  getSpinner() {
    return this.spinner;
  }
  getTranslate() {
    return this.translate
  }
  getLoginRequest() {
    return this.loginRequest;
  }
  getCoreService() {
    return this.coreService;
  }
  getLoginImageForDynamic() {
    return this.loginImage
  }
  getLoginImage() {
    this.authService.getLoginImage('').subscribe(res => {
      this.loginImage = res
    })
  }
  pageForm: UntypedFormGroup
  ngOnInit(): void {
    this.pageForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
    });

  }

  setLogo(img: string) {
    environment.logo = img
  }

  getBussionLoginCss() {
    this.bussionMainService.getBussionLogin('javascript').pipe(
      tap(res => {
        this.createLoginJsFunction(res)
      }),
      concatMap(res => this.bussionMainService.getBussionLogin('html')),
    ).subscribe(res => {
      this.dynamicContent = res;
      this.createLoginPage();
    })
  }

  createLoginJsFunction(code) {
    try {
      var loginFunction = new Function('loginPage', code)
      var returnedValue = loginFunction(this)
    } catch (error) {
      console.log(error)
    }

  }







  createLoginPage() {
    ɵcompileComponent(DynamicLoginComponent, {
      // jit:true,
      template: this.dynamicContent,
      standalone: true,
      imports: [NgFor, NgIf, NgStyle, TranslateModule, MaterialModule, CommonModule, MatIconModule, ReactiveFormsModule, FormsModule],
      providers: [],
      schemas: [NO_ERRORS_SCHEMA],

    });

    this.viewContainerRef.clear();
    this.viewContainerRef.createComponent(DynamicLoginComponent);
  }

  ngOnDestroy() {
    if (this.displayLoading == 'none') {
      clearInterval(this.myinterval);
    }
  }

  getMultiTenantStatus() {
    this.coreService.getMultiTenantStatus().subscribe(status => {
      this.isShownCreateAccount = status;
    });
  }

  getChaptcaEnabled() {
    this.authService.isChaptchaEnabled().subscribe((res) => {
      this.chaptcaEnabled = res
    })
    this.captchaService.captchStatus.subscribe((status) => {
      this.captchaStatus = status;
      if (this.captchaStatus !== null && !this.captchaStatus)
        this.warntext = 'Please check code!'
      else
        this.warntext = ''
    });
  }




  login() {
    this.spinner.show();
    this.getChaptcaEnabled()
    if (this.chaptcaEnabled && !this.captchaStatus) {
      this.spinner.hide();
      this.warntext = 'Please check code!'
      return
    }
    this.authService.login(this.loginRequest).subscribe(result => {
      if (result.result) {
        this.spinner.hide();
        // if (result.message.language == 'English') {
        //   this.translate.use('en');
        // } else {
        //   this.translate.use('tr');
        // }
        this.authService.saveToken(result.message.token);
        this.navigateUserMainPage(result.message.homePage[0])
      }
    });
    this.captchaService.setCaptchaStatus('')
  }


  navigateUserMainPage(mainpage: UserHomePage) {
    if (mainpage) {
      if (mainpage.type == 1) {
        this.router.navigate(['/data-app/' + mainpage.objectId]);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  createAccount() {
    if (this.user.password == this.passwordAgain) {
      this.authService.createAccount(this.user).subscribe(result => {
        this.authService.saveToken(result.token);
        this.router.navigate(['/']);
      })
    }
    else {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.PasswordNotSame"));
    }
  }

  redirect(url: string) {
    window.location.href = url
  }

}

