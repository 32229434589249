import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { VisualService } from 'src/app/services/visual/visual.service';
import { VisualComp } from 'src/app/models/responses/visualComponentResponse';
import { MatDialog } from '@angular/material/dialog';
import { VisualFormComponent } from './visual-form/visual-form.component';
import { ActionDialogComponent } from 'src/app/components/action-dialog/action-dialog/action-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-visual',
  templateUrl: './visual.component.html',
  styleUrls: ['./visual.component.scss']
})
export class VisualComponent implements OnInit {

  baseUrl: string = environment.imageUrl;
  displayedColumns: string[] = ['icon', 'name','visualComponentId', 'description', 'action'];
  displayedColumnsName: string[] = [this.translate.instant("Common.Icon"), this.translate.instant("VisualCompPage.Name"),this.translate.instant("VisualCompPage.VisualComponentId"), this.translate.instant("VisualCompPage.Description"), this.translate.instant("VisualCompPage.Action")];
  visualComps: VisualComp[] = [];

  constructor(
    private dialog: MatDialog,
    private visualService: VisualService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getVisualComponents()
  }

  getVisualComponents() {
    this.spinner.show();
    this.visualService.getVisualComponents().subscribe(result => {
      this.visualComps = result;
      this.spinner.hide();
    });
  }

  deleteVisualComponent(visualComp: VisualComp) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.visualService.deleteComponent(visualComp).subscribe(result => {
          this.getVisualComponents();
        });
      }
    });
  }

  openVisualComponent(event: any) {
    let data = {
      type: event.type as String,
      visual: event.data as VisualComp
    }

    this.modalService.openModal(data, VisualFormComponent, '90%', '70%','with-action-area').subscribe(result => {
      if (result === true) {
        this.getVisualComponents();
      }
    });
  }

}
