import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { GroupsResponse, Group } from 'src/app/models/responses/groupsResponse';
import { DashboardService } from '../dashboard/dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private dashboardService: DashboardService
  ) { }

  getGroups() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getGroups, param).pipe(
      map((response: GroupsResponse) => {
        return response.message;
      })
    );
  }

  getUserGroups(userId: string) {
    let param = {
      Token: this.authService.getToken(),
      UserId: userId
    };
    return this.http.post(Endpoints.getUserGroups, param).pipe(
      map((response: GroupsResponse) => {
        return response.message;
      })
    );
  }

  upsertGroup(group: Group) {
    let param = {
      Token: this.authService.getToken(),
      Group: group
    };
    return this.http.post(Endpoints.upsertGroup, param).pipe(
      map((response: GroupsResponse) => {
        this.dashboardService.getUserActiveDashboards(1).subscribe()
        this.dashboardService.getAuthorizedDashboards(1).subscribe()
        return response.message;
      })
    );
  }

  deleteGroup(groupId: string) {
    let param = {
      Token: this.authService.getToken(),
      GroupId: groupId
    };
    return this.http.post(Endpoints.deleteGroup, param).pipe(
      map((response: GroupsResponse) => {
        return response.message;
      })
    );
  }

}
