import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { WidgetLibraryResponse, WidgetLibrary } from 'src/app/models/responses/widgetLibraryResponse';

@Injectable({
  providedIn: 'root'
})
export class WidgetLibraryService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getWidgetLibraries() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getWidgetLibraries, param).pipe(
      map((response: WidgetLibraryResponse) => {
        if (response.result === true) {
          return response.message;
        } else {
          return [];
        }
      })
    );
  }

  upsertWidgetLibrary(widgetLibrary: WidgetLibrary) {
    let param = {
      Token: this.authService.getToken(),
      WidgetLibrary: widgetLibrary
    };
    return this.http.post(Endpoints.upsertWidgetLibrary, param).pipe(
      map((response: WidgetLibraryResponse) => {
        return response.message;
      })
    );
  }

  deleteWidgetLibrary(widgetLibrary: WidgetLibrary) {
    let param = {
      Token: this.authService.getToken(),
      WidgetLibraryId: widgetLibrary.widgetLibraryId
    };
    return this.http.post(Endpoints.deleteWidgetLibrary, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  deleteAllWidgetLibrary(widgetFolder: string) {
    let param = {
      Token: this.authService.getToken(),
      Folder: widgetFolder
    };
    return this.http.post(Endpoints.clearWidgetFolder, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  uploadJavascriptFile(formData: FormData) {
    formData.append('Token', this.authService.getToken());
    return this.http.post(Endpoints.uploadJavascriptFile, formData).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getJavascriptFiles(folder: string) {
    let param = {
      Token: this.authService.getToken(),
      FileFolder: folder
    };
    return this.http.post(Endpoints.getFilesPathOfLibrary, param).pipe(
      map((response: any) => {
        return response.message as string[];
      })
    );
  }

  deleteFilePath(folder: string, files: string[]) {
    let param = {
      Token: this.authService.getToken(),
      Folder: folder,
      Files: files
    };
    return this.http.post(Endpoints.deleteFilePath, param).pipe(
      map((response: any) => {
        return response.message as string[];
      })
    );
  }

}
