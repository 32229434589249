<div class="color-picker-container">
    <div class="picker-title">{{"WidgetFormPage.Color"|translate}}</div>
    <!-- <div class="favorite-colors">
        <div class="color-area" [ngClass]="{'selected-color': item.selected}" *ngFor="let item of colorList"
            (click)="selectColorFunc(item,'favorite')" [style.BackgroundColor]="item.color" [id]="item.key">
            <mat-icon style="color:#61C478;transform: scale(0.8);" *ngIf="item.selected">check_circle_outline</mat-icon>
        </div>
    </div> -->
    <div>
        <div class="picker-subtitle">
            <span >{{"ColorPaletteFormPage.ColorType"|translate}}</span>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;">
            <div (click)="selectColorType(item)" class="icon-border" *ngFor="let item of colorTypes" [ngClass]="{selectedColorType: selectedColorType==item.value}">
                <mat-icon [svgIcon]="item.type"></mat-icon>
            </div>
        </div>

    </div>
    <div class="picker-subtitle">
        <span *ngIf="!colorPalettesVisible">{{"WidgetFormPage.SpecialColor"|translate}}</span>
        <span *ngIf="colorPalettesVisible">{{"ColorPaletteFormPage.ColorPalettes"|translate}}</span>

        <span>
            <mat-icon (click)="colorPalettesVisible=!colorPalettesVisible"
                matTooltip="{{'ColorPaletteFormPage.ColorPalettes'|translate}}" [svgIcon]="'color-palette'"></mat-icon>
        </span>
    </div>
    <div *ngIf="colorPalettesVisible">

        <mat-select multiple [(ngModel)]="selectedColorPalettes" (valueChange)="changeColorPaletteSelect($event)"
            [placeholder]="'Common.Select'|translate" class="color-type-select">
            <mat-option class="color-type-option" *ngFor="let item of colorPalettes" [value]="item">
                {{item.name}}
                <div class="color-palette-prev">
                    <div *ngFor="let color of item.palettes" [style.BackgroundColor]="color">&nbsp;</div>
                </div>
            </mat-option>
        </mat-select>
        <div class="palette-colors" *ngFor="let palet of selectedColorPalettes">
            <div class="color-area" [ngClass]="{'selected-color': isSelected(palet.colorPaletteId,color)}"
                (click)="selectColorFunc({color:color,key:palet.colorPaletteId},'palettes')"
                *ngFor="let color of palet.palettes" [style.BackgroundColor]="color" [id]="color">
                <mat-icon style="color:#61C478;transform: scale(0.8);"
                    *ngIf="isSelected(palet.colorPaletteId,color)">check_circle_outline</mat-icon>
            </div>
        </div>
    </div>
    <div class="custom-color" *ngIf="!colorPalettesVisible">
        <select class="color-type-select" (change)="colorType=$event.target.value">
            <option value="rgb" (select)="colorType='rgb'">RGB</option>
            <option value="hex" (select)="colorType='hex'">HEX</option>
        </select>
        <form [formGroup]="rgbForm" *ngIf="colorType=='rgb'" class="color-inputs">
            <input formControlName="r" type="number" [max]="255" placeholder="255" class="color-type-select" />
            <input formControlName="g" type="number" [max]="255" placeholder="255" class="color-type-select" />
            <input formControlName="b" type="number" [max]="255" placeholder="255" class="color-type-select" />

            <mat-icon class="add-button" (click)="changeColor($event)">library_add</mat-icon>

        </form>
        <form [formGroup]="hexForm" *ngIf="colorType=='hex'" style="display: flex;align-items: center;">
            <input placeholder="#FFFFF" formControlName="hex" class="color-type-input" />
            <mat-icon class="add-button" (click)="changeColor($event)">library_add</mat-icon>
        </form>
    </div>
    <div class="favorite-colors">
        <div class="color-area" [ngClass]="{'selected-color': item.selected}" (click)="selectColorFunc(item,'created')"
            *ngFor="let item of createdColors" [style.BackgroundColor]="item.color" [id]="item.key">
            <mat-icon style="color:#61C478;transform: scale(0.8);" *ngIf="item.selected">check_circle_outline</mat-icon>
        </div>
    </div>
</div>