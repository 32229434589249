import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map, tap } from 'rxjs/operators';
import { DashboardResponse, Dashboard, cacheDashboard } from 'src/app/models/responses/dashboardResponse';
import { Widget } from 'src/app/models/responses/widgetLibraryResponse';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { cerateCssFile, deleteCssFile, idKeyword } from 'src/app/core/utils';
import { App } from 'src/app/models/responses/appsResponse';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  selectedDasboard: BehaviorSubject<Dashboard> = new BehaviorSubject<Dashboard>({
    dashboardId: '',
    title: '',
    icon: '',
    auditEnabled: 0,
    dashboardStatus: 0,
    order: 1,
    description: '',
    layoutConfig: '',
    domain: '',
    access: 0,
    owner: '',
    creationDate: '',
    updatedBy: '',
    updateDate: '',
    colorPalette: '',
    filter: [],
    widgets: [],//Widget,
    dimensions: [], //Dimension,
    messages: [], //BussionMessage
    autoRefresh: 0,
    refreshInterval: 0,
    isMainDashboard: 0,
    hideBorders: '',
    contentLanguage: '',
    gridSize: 0,
    tags: '',
    css: '',
    hideAdvancedQueryPanel: 0,
    layout: '',
    colors: []
  });

  openedClosedbtn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  cachedData: BehaviorSubject<{ authorizedDashboard: Dashboard[], activeDashboard: Dashboard[], authorizedApps: App[] }> = new BehaviorSubject<{ authorizedDashboard: Dashboard[], activeDashboard: Dashboard[], authorizedApps: App[] }>({ authorizedDashboard: [], activeDashboard: [], authorizedApps: [] })

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private cookieService: CookieService
  ) { }

  toggleGridster() {
    this.openedClosedbtn.next(false);
  }

  setLastDashboardId(dashboardId: string) {
    this.cookieService.set('lastDashboardId', dashboardId);
  }

  getLastDashboardId(): string {
    return this.cookieService.get('lastDashboardId');
  }

  getDashboards() {
    let param = { Token: this.authService.getToken() };
    return this.http.post(Endpoints.getDashboards, param).pipe(
      map((response: DashboardResponse) => {
        return response.message;
      })
    );
  }

  getAllDashboards() {
    let param = { Token: this.authService.getToken(), ShowAll: 1 };
    return this.http.post(Endpoints.getDashboards, param).pipe(
      map((response: DashboardResponse) => {
        return response.message;
      })
    );
  }

  getUserActiveDashboards(summary: number = 0) {
    let param = {
      Token: this.authService.getToken(),
      Summary: summary
    };

    return this.http.post(Endpoints.getUserActiveDashboards, param).pipe(
      map((response: DashboardResponse) => {
        this.setCacheData({ ...this.cachedData.getValue(), activeDashboard: response.message.filter(x => x.isMainDashboard === 1) });
        return response.message.filter(x => x.isMainDashboard === 1);
      })
    );
  }

  setCacheData(newValue: any) {
    this.cachedData.next(newValue);
    // this.cacheData(newValue)

  }

  getCachedDataObservable() {
    this.cachedData.next(this.cachedData.getValue())
    return this.cachedData.asObservable()
  }



  getAuthorizedDashboards(summary: number = 0) {
    let param = {
      Token: this.authService.getToken(),
      Summary: summary
    };

    return this.http.post(Endpoints.getAuthorizedDashboards, param).pipe(
      map((response: DashboardResponse) => {
        this.setCacheData({ ...this.cachedData.getValue(), authorizedDashboard: response.message })
        return response.message;
      })
    );
  }

  getCachedData() {
    if (this.cachedData)
      return this.cachedData.getValue();
    else
      return null

  }

  cacheData(cacheData) {
    try {
      localStorage.setItem('cachedData', JSON.stringify(cacheData))

    } catch (error) {
      console.log('error', error)
    }
  }

  refreshCache(newValue, dashboardId, changedKey) {
    var cacheData: cacheDashboard = this.getCachedData();
    if (cacheData) {
      if (changedKey !== '') {
        if (cacheData.activeDashboard.find(x => x.dashboardId == dashboardId))
          cacheData.activeDashboard.find(x => x.dashboardId == dashboardId)[changedKey] = newValue
        if (cacheData.authorizedDashboard.find(x => x.dashboardId == dashboardId))
          cacheData.authorizedDashboard.find(x => x.dashboardId == dashboardId)[changedKey] = newValue
      } else {
        if (cacheData.activeDashboard.some(x => x.dashboardId == dashboardId)) {
          cacheData.activeDashboard = cacheData.activeDashboard.filter(x => x.dashboardId !== dashboardId);
          cacheData.activeDashboard.push(newValue)
        }
        if (cacheData.authorizedDashboard.some(x => x.dashboardId == dashboardId)) {
          cacheData.authorizedDashboard = cacheData.authorizedDashboard.filter(x => x.dashboardId !== dashboardId);
          cacheData.authorizedDashboard.push(newValue)
        }
      }
      this.setCacheData(cacheData)
    }
  }

  upsertDashboard(dashboard: Dashboard) {
    let param = {
      Token: this.authService.getToken(),
      Dashboard: dashboard
    };
    return this.http.post(Endpoints.upsertDashboard, param).pipe(
      map((response: any) => {
        if (dashboard.dashboardId)
          this.refreshCache(dashboard, dashboard.dashboardId, '')
        return response;
      })
    );
  }

  deleteDashboard(dashboard: Dashboard) {
    let param = {
      Token: this.authService.getToken(),
      DashboardId: dashboard.dashboardId
    };
    return this.http.post(Endpoints.deleteDashboard, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  upsertWidgetToDashboard(widget: Widget, dashboardId: string) {
    let param = {
      Token: this.authService.getToken(),
      Widget: widget,
      DashboardId: dashboardId
    };
    return this.http.post(Endpoints.upsertWidgetToDashboard, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  widgetPreview(widget: Widget) {
    let param = {
      Token: this.authService.getToken(),
      Widget: widget,
    };
    return this.http.post(Endpoints.previewWidget, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  widgetPreviewFast(widget: Widget, WidgetData: any) {
    let param = {
      Token: this.authService.getToken(),
      WidgetData: JSON.stringify(WidgetData),
      Widget: widget
    };
    return this.http.post(Endpoints.PreviewWidgetFast, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getDashboard(dashboardId: string, domain?: string) {
    let param = {
      Token: domain ? "SHAREDOBJECT-" + domain : this.authService.getToken(),
      DashboardId: dashboardId
    };

    return this.http.post(Endpoints.getDashboardById, param).pipe(
      map((response: any) => {

        cerateCssFile(response.message.css, response.message.dashboardId, idKeyword.dashboard);

        return response.message;
      })
    );
  }


  getDashboardById(dashboardId: string) {
    let param = {
      Token: this.authService.getToken(),
      DashboardId: dashboardId
    };

    return this.http.post(Endpoints.getDashboardById, param).pipe(
      map((response: any) => {
        cerateCssFile(response.message.css, response.message.dashboardId, idKeyword.dashboard);
        return response.message;
      })
    );
  }
  // getCssDashboard(dashboardId: string, domain?: string) {
  //   let param = {
  //     Token: domain ? "SHAREDOBJECT-" + domain : this.authService.getToken(),
  //     DashboardId: dashboardId
  //   };

  //   return this.http.post(Endpoints.getDashboardById, param).pipe(
  //     map((response: any) => {
  //       cerateCssFile(response.message.css,response.message.dashboardId);
  //       console.log("response.message.css",response.message)
  //       return response.message;
  //     })
  //   );
  // }

  getWidgetsForDesigner(dashboardId: string, domain?: string, endUser?: boolean) {
    var enduserlocal = !this.authService.getRoles().includes('Admin') && endUser

    let param = {
      Token: domain ? domain : this.authService.getToken(),
      DashboardId: dashboardId
    };

    let paramUser = {
      Token: domain ? domain : this.authService.getToken(),
      DashboardId: dashboardId,
      EndUserScreen: true
    };

    return this.http.post(Endpoints.getWidgetsForDesigner, enduserlocal ? paramUser : param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  addDashboardToUser(ids: string[]) {
    let param = {
      Token: this.authService.getToken(),
      UserId: this.authService.getUserId(),
      DashboardIds: ids
    };
    return this.http.post(Endpoints.addDashboardToUser, param).pipe(
      map((response: any) => {
        //burası
        this.getUserActiveDashboards(1).subscribe()
        return response.message;
      })
    );
  }



  getWidgetsForDashboard(dashboardId, filter: any[]) {
    var key = CryptoJS.enc.Utf8.parse('000000000000' + dashboardId);
    var iv = CryptoJS.enc.Utf8.parse('0000000000' + this.authService.getUserDomain());
    var tmpKey = "";

    if (filter.length <= 0)
      tmpKey = "NO_FILTER_DEFINED"
    else
      for (let index = 0; index < filter.length; index++) {
        tmpKey += filter[index].fieldValue;
      }

    var encryptData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(tmpKey), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    filter = filter.filter(x => x.fieldName !== "SecureKey")

    // if (!filter.some(x => x.fieldName == "SecureKey")) {
    filter.push(
      { fieldName: "SecureKey", operator: "=", fieldValue: encryptData.toString() }
    )
    // }

    let param = {
      Token: this.authService.getToken(),
      DashboardId: dashboardId,
      Filter: filter
    };
    return this.http.post(Endpoints.getWidgetsForDashboard, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getWidgetsForDashboardShare(dashboardId, filter: any[]) {

    let param = {
      Token: this.authService.getToken(),
      DashboardId: dashboardId,
      Filter: filter
    };
    return this.http.post(Endpoints.getWidgetsForDashboard, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  updateDashboardLayout(dashboardId: string, layoutList: any[], ismobile: number) {
    let param = {
      Token: this.authService.getToken(),
      DashboardId: dashboardId,
      WidgetLayout: layoutList,
      IsMobileLayout: ismobile
    };
    return this.http.post(Endpoints.updateDashboardLayout, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  deleteWidgetFromDashboard(dashboardId: string, widgetId: string) {
    let param = {
      Token: this.authService.getToken(),
      DashboardId: dashboardId,
      WidgetId: widgetId

    };
    return this.http.post(Endpoints.deleteWidgetFromDashboard, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  checkDashboardUpdateTime(dashboardId: string, domain?: string) {
    let param = {
      Token: domain ? domain : this.authService.getToken(),
      DashboardId: dashboardId
    };
    return this.http.post(Endpoints.checkDashboardUpdateTime, param).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getUserLibraries() {
    let param = {
      Token: this.authService.getToken()
    }
    return this.http.post(Endpoints.getUserLibraries, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  ListPictures() {
    let param = {
      Token: this.authService.getToken()
    }
    return this.http.post(Endpoints.ListPictures, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  addPictures(photo: string) {
    var formData = new FormData()
    formData.append('File', photo)
    formData.append('Token', this.authService.getToken())

    return this.http.post(Endpoints.AddPicture, formData).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }


  deletePictures(photo: string) {
    let param = {
      Token: this.authService.getToken(),
      FileName: photo
    }

    return this.http.post(Endpoints.DeletePicture, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }
}
