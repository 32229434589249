<app-modal-title [title]="title"></app-modal-title>
<mat-dialog-content class="mat-typography">
    <div *ngIf="!showTable">
        <app-title title="{{ 'DataStoreFormPage.About' | translate }}" (btnEvent)="notifyDs()" buttonTitle="{{
            'DataStoreFormPage.Notify' | translate }}"></app-title>


        <form [formGroup]="pageForm">
            <mat-form-field>
                <mat-label>{{ 'DataStoreFormPage.Name' | translate }}</mat-label>
                <input matInput [(ngModel)]="dataStore.name" formControlName="name" required>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'DataStoreFormPage.Description' | translate }}</mat-label>
                <textarea matInput [(ngModel)]="dataStore.description" [ngModelOptions]="{standalone: true}"></textarea>
            </mat-form-field>
            <br>

            <mat-form-field *ngIf="!isCreateForm">
                <mat-label>{{ 'DataStoreFormPage.CreatedBy' | translate }}</mat-label>
                <input matInput [(ngModel)]="dataStore.createdBy" [ngModelOptions]="{standalone: true}" disabled>
            </mat-form-field>
            <br>

            <mat-form-field *ngIf="!isCreateForm">
                <mat-label>{{ 'DataStoreFormPage.CreateTime' | translate }}</mat-label>
                <input matInput [(ngModel)]="dataStore.creationDate" [ngModelOptions]="{standalone: true}" disabled>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'DataStoreFormPage.Nodes' | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedNode" (ngModelChange)="nodeSelection($event)"
                    [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let node of nodes" [value]="node">
                        {{node.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br>


            <mat-form-field>
                <mat-label>{{ 'DataStoreFormPage.Type' | translate }}</mat-label>
                <mat-select [(ngModel)]="dataStore.type" [ngModelOptions]="{standalone: true}">
                    <mat-option value="Master">Master</mat-option>
                    <mat-option value="View">View</mat-option>
                    <mat-option value="External">External</mat-option>
                    <mat-option value="Direct">Direct</mat-option>
                    <mat-option value="Query">Query</mat-option>
                </mat-select>
            </mat-form-field>
            <br>



            <mat-form-field>
                <mat-label>{{ 'DataStoreFormPage.Universes' | translate }}</mat-label>
                <mat-select [(ngModel)]="dataStore.universe" (ngModelChange)="universeSelection()"
                    [disabled]="dataStore.node == ''" [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let universe of universes" [value]="universe">
                        {{universe}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'DataStoreFormPage.Collections' | translate }}</mat-label>
                <mat-select [(ngModel)]="dataStore.collection" (ngModelChange)="collectionSelection()"
                    [disabled]="dataStore.universe == ''" [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let collection of collections" [value]="collection">
                        {{collection}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br>

            <mat-form-field>
                <mat-label>{{ 'DataStoreFormPage.Columns' | translate }}</mat-label>
                <mat-select [(ngModel)]="dataStore.columns" [disabled]="dataStore.collection == ''" multiple
                    [compareWith]="compareFunctionForColumns" [ngModelOptions]="{standalone: true}">
                    <!-- <div class="selectalloption">
                    <mat-checkbox class="example-margin" color="primary">Tümünü seç</mat-checkbox>
                </div> -->
                    <mat-option *ngFor="let column of columns" [value]="column">
                        {{column.fieldName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br>

            <div style="display: flex;flex-direction:row">
                <mat-checkbox color="primary" [(ngModel)]="dataStore.apiEnable" class="example-margin"
                    [checked]="dataStore.apiEnable" (change)="check($event.checked,'apiEnable')"
                    [ngModelOptions]="{standalone: true}">
                    {{ 'DataStoreFormPage.Enable' | translate }}
                </mat-checkbox>
            </div>

            <br>
            <div style="display: flex;flex-direction:row">
                <mat-checkbox color="primary" [(ngModel)]="dataStore.plainQueryEnabled" class="example-margin"
                    [checked]="dataStore.plainQueryEnabled" (change)="check($event.checked,'plainQueryEnabled')"
                    [ngModelOptions]="{standalone: true}">
                    {{ 'DataStoreFormPage.EnablePlainQuery' | translate }}
                </mat-checkbox>
            </div>
            <br>
            <div *ngIf="dataStore.apiEnable" style="display: flex;flex-direction:row">
                <mat-checkbox color="primary" [(ngModel)]="dataStore.apiEncrypted" class="example-margin"
                    [checked]="dataStore.apiEncrypted" (change)="check($event.checked,'apiEncrypted')"
                    [ngModelOptions]="{standalone: true}">
                    {{ 'DataStoreFormPage.Encrypt' | translate }}
                </mat-checkbox>
            </div>
            <br>
            <div *ngIf="dataStore.apiEncrypted" class="apikeyarea">
                <mat-form-field>
                    <mat-label>{{ 'DataStoreFormPage.ApiKey' | translate }}</mat-label>
                    <input [type]="visibleKey?'text':'password'" matInput [(ngModel)]="dataStore.apiKey"
                        [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <mat-icon svgIcon="eye-svgrepo-com" (click)="visibleKey=!visibleKey"></mat-icon>
                <br>
            </div>

            <div style="display: flex;flex-direction:row">
                <mat-checkbox color="primary" [(ngModel)]="dataStore.wbListingEnable" class="example-margin"
                    [checked]="dataStore.wbListingEnable" (change)="check($event.checked,'wbListingEnable')"
                    [ngModelOptions]="{standalone: true}">
                    {{ 'DataStoreFormPage.wbListingEnable' | translate }}
                </mat-checkbox>
            </div>
            <br>
            <div *ngIf="dataStore.wbListingEnable" style="display: flex;justify-content: space-between;">
                <div style="flex-basis:45%">
                    <mat-accordion>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{'DataStoreFormPage.whiteList' | translate}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>{{"DataStoreFormPage.createwhitelist" |translate}}</mat-label>
                                <textarea matInput placeholder="Ex. Select * From Table..."
                                    (keyup.enter)="addElement($event,'whitelist')"></textarea>
                            </mat-form-field>
                            <div class="querylist" *ngFor="let item of querylists.whitelist">
                                <span class="bg-light-info">{{"DataStoreFormPage.new" |translate}}</span>
                                {{item}}
                                <mat-icon (click)="deleteElement(item,'whitelist')">highlight_off</mat-icon>
                            </div>
                            <div class="querylist" *ngFor="let item of dataStore.whiteLists;index as i">
                                {{i+1}}-) {{item}}
                                <mat-icon (click)="deleteOldElement(item,'whiteLists')">highlight_off</mat-icon>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                </div>
                <div style="flex-basis:45%">
                    <mat-accordion>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{"DataStoreFormPage.blackList" |translate}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>{{"DataStoreFormPage.createblacklist" |translate}}</mat-label>
                                <textarea matInput placeholder="Ex. Drop Table..."
                                    (keyup.enter)="addElement($event,'blacklist')"></textarea>
                            </mat-form-field>
                            <div class="querylist" *ngFor="let item of querylists.blacklist">
                                <span class="bg-light-info">{{"DataStoreFormPage.new" |translate}}</span>
                                {{item}}
                                <mat-icon (click)="deleteElement(item,'whitelist')">highlight_off</mat-icon>
                            </div>
                            <div class="querylist" *ngFor="let item of dataStore.blackLists;index as i">
                                {{i+1}}-) {{item}}
                                <mat-icon (click)="deleteOldElement(item,'blackLists')">highlight_off</mat-icon>
                            </div>

                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            <br>
            <div style="display: flex;flex-direction:row">
                <mat-checkbox color="primary" [(ngModel)]="dataStore.preparedStatementEnable" class="example-margin"
                    [checked]="dataStore.preparedStatementEnable"
                    (change)="check($event.checked,'preparedStatementEnable')" [ngModelOptions]="{standalone: true}">
                    {{ 'DataStoreFormPage.preparedStatementEnable' | translate }}
                </mat-checkbox>
            </div>
            <br>
            <div *ngIf="dataStore.preparedStatementEnable" style="display: flex;justify-content: space-between;">
                <div style="flex-basis:100%;width: 100%;">
                    <mat-accordion>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{'DataStoreFormPage.queries' | translate}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-form-field>
                                <mat-label>{{"DataStoreFormPage.queryId" |translate}}</mat-label>
                                <input (keyup.enter)="queryarea.focus()" [disabled]="selectedItem.statementId!==''"
                                    [value]="selectedItem?selectedItem.statementId:''" matInput #staticqueryid />
                            </mat-form-field>
                            <br>
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>{{"DataStoreFormPage.createQuery" |translate}}</mat-label>
                                <textarea [value]="selectedItem?selectedItem.query:''" matInput
                                    placeholder="Ex. Select * From Table..."
                                    (keyup.enter)="addElement($event,'preparedQueryList')" #queryarea></textarea>
                            </mat-form-field>
                            <br>
                            <div
                                style="width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-bottom: 0.6rem;">
                                <button style="font-size: 0.6rem; 
                             min-width: 0.6rem !important;" *ngIf="selectedItem.statementId!==''" (click)="clearArea()"
                                    mat-button class="b-btn">
                                    {{ 'Common.Clear' | translate }}
                                </button>
                            </div>
                            <div class="querylist" *ngFor="let item of querylists.preparedQueryList">
                                <span class="bg-light-info">{{"DataStoreFormPage.new" |translate}}</span>
                                ( {{item.statementId}} ){{item.query}}
                                <mat-icon (click)="deleteElement(item,'preparedQueryList')">highlight_off</mat-icon>
                            </div>
                            <div class="querylist" *ngFor="let item of dataStore.preparedStatement;index as i">
                                <span *ngIf="updatedList.includes(item.statementId)"
                                    class="bg-light-danger">{{"DataStoreFormPage.updated" |translate}}</span>
                                {{i+1}}-( {{item.statementId}} )
                                {{item.query}}
                                <mat-icon (click)="deleteOldElement(item,'preparedStatement')">highlight_off</mat-icon>
                                <mat-icon (click)="editOldElement(item)">edit</mat-icon>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                </div>
            </div>

        </form>



        <app-title title="{{ 'DataStoreFormPage.FilterForDataStore' | translate }}"
            buttonTitle="+ {{ 'Common.AddNewFilter' | translate }}" (btnEvent)="isShownQueryForm = !isShownQueryForm">
        </app-title>

        <div class="modal-form-container" *ngIf="isShownQueryForm">
            <div class="modal-form-content">
                <mat-form-field>
                    <mat-label>Field</mat-label>
                    <mat-select [(ngModel)]="queryFilter.fieldName" (ngModelChange)="filterFieldSelected($event)">
                        <mat-option *ngFor="let column of dataStore.columns" [value]="column.fieldName">
                            {{column.fieldName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>

                <mat-form-field>
                    <mat-label>Operator</mat-label>
                    <mat-select [(ngModel)]="queryFilter.operator">
                        <mat-option value="=">Equal</mat-option>
                        <mat-option value="!=">Not Equal</mat-option>
                        <mat-option value="in">Contains</mat-option>
                        <mat-option value=">">gt</mat-option>
                        <mat-option value=">=">gt and equals</mat-option>
                        <mat-option value="<">little</mat-option>
                        <mat-option value="<=">little and equals</mat-option>
                    </mat-select>
                </mat-form-field>
                <br>

                <mat-form-field *ngIf="queryFilterFieldType != 'System.DateTime'">
                    <mat-label>Value</mat-label>
                    <input matInput [(ngModel)]="queryFilter.fieldValue"
                        type="{{queryFilterFieldType == 'System.String' ? 'text' : 'number'}}" [matAutocomplete]="auto"
                        (ngModelChange)='searchValuesForQueryFilter($event)' required>

                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of searchValues" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field *ngIf="queryFilterFieldType == 'System.DateTime'">
                    <mat-label>Value</mat-label>
                    <input matInput [matDatepicker]="picker" (dateInput)="dateSelected($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <br>

            </div>
            <div class="modal-form-action-content">
                <button mat-button class="b-btn" (click)="isShownQueryForm = !isShownQueryForm">Cancel</button>
                <button mat-button class="b-btn b-success" cdkFocusInitial (click)="addFilter()">Add</button>
            </div>
        </div>

        <div class="filter-list-content" *ngIf="dataStore.query.length > 0">
            <app-filter-list [data]="dataStore.query" (delete)="deleteQuery($event)"></app-filter-list>
        </div>
        <br>
    </div>
    <div *ngIf="showTable" style="height: 500px;">
        <app-ag-grid-table [columnTypes]="selectedDataPreviewColumns"
            [rowData]="sampleDataForDataStore"></app-ag-grid-table>
    </div>



</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="showTable?showTable=false:getsampleData()"
        *ngIf="dataStore.collection&&dataStore.universe&&dataStore.node" mat-button class="b-btn b-secondary">
        <div *ngIf="!showTable" style="display: flex;align-items: center;">
            <mat-icon style="transform: scale(0.7);" [svgIcon]="'file-preview'"></mat-icon>
            {{ 'DataStoreFormPage.DataPreview' | translate }}
        </div>
        <div *ngIf="showTable">
            {{ 'Common.Form' | translate }}
        </div>
    </button>
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="!isCreateForm" (click)="upsertDataStore()">{{
        'Common.Update' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="isCreateForm" (click)="upsertDataStore()">{{
        'Common.Create' | translate }}</button>
</mat-dialog-actions>