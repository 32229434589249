export enum SettingAreas {
    visual = 'visaulSetting',
    data = 'dataSetting',
    event = 'eventSetting',
    agregation='aggregationSettings'

}

export const dropdownMenus = {
    visual_component: 'Visual Components',
    data_store:'Data Store',
    data_store_column:'Data Store Column',
    sort_and_limit:'Sort And Limit',
    dril_down:'Dril Down',
    config:'Config'
};