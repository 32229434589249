<div class="filter-table-content" [ngClass]="{'scrolltable': scrollarea}" style="min-width: 500px;">
    <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="position">
            <mat-header-cell *matHeaderCellDef>{{ 'FilterComponent.Field' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.fieldName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> {{ 'FilterComponent.Operator' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{changeName(element.operator)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="weight">
            <mat-header-cell *matHeaderCellDef> {{ 'FilterComponent.Value' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.fieldValue}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="symbol">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let element" class="action-cell">
                <mat-icon *ngIf="!disableDelete" matListIcon color="warn" (click)="deleteFilter(element)" svgIcon="delete-box"></mat-icon>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>