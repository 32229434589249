import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-with-actions',
  templateUrl: './card-with-actions.component.html',
  styleUrls: ['./card-with-actions.component.scss']
})
export class CardWithActionsComponent implements OnInit {
  @Input() item: any
  @Input() icons: { function: Function, icon: string }[] = []
  @Input() imageAvatarKeys: { avatar: string, tooltip: string, avatartext: string, avatartext2: string } = { avatar: 'ownerId', tooltip: 'mail', avatartext: 'firstName', avatartext2: 'lastName' }
  @Input() keys: { avatarKey: string, titleKey: string, descKey: string, smallKey: string, smallKey2: string, badgeKey: string } = { avatarKey: 'deneme deneme', descKey: '', titleKey: '', smallKey: '', smallKey2: '', badgeKey: '' }
  @Output() cardTitleClick: EventEmitter<any> = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }


  cardClick(selected:any) {
    this.cardTitleClick.emit(selected);
  }

}
