<div class="content">
    <div>
        <div *ngFor="let p of passwordPolicies" style="margin-bottom: 30px;">

            <app-title title="{{p.type}} {{ 'PasswordPolicyPage.Title' | translate }}"></app-title>
            <mat-form-field>
                <mat-label>{{ 'PasswordPolicyPage.MinLength' | translate }}</mat-label>
                <input matInput type="number" [(ngModel)]="p.minLength">
            </mat-form-field>
            <br>

            <mat-checkbox class="example-margin" [(ngModel)]="p.hasBigLetter" (change)="p.hasBigLetter = $event.checked ? 1 : 0">{{ 'PasswordPolicyPage.HasBigLetter' | translate }}</mat-checkbox>
            <mat-checkbox class="example-margin" [(ngModel)]="p.hasNumber" (change)="p.hasNumber = $event.checked ? 1 : 0">{{ 'PasswordPolicyPage.HasNumber' | translate }}</mat-checkbox>
            <mat-checkbox class="example-margin" [(ngModel)]="p.hasSmallLetter" (change)="p.hasSmallLetter = $event.checked ? 1 : 0">{{ 'PasswordPolicyPage.HasSmallLetter' | translate }}</mat-checkbox>
            <mat-checkbox class="example-margin" [(ngModel)]="p.hasSpecialChar" (change)="p.hasSpecialChar = $event.checked ? 1 : 0">{{ 'PasswordPolicyPage.HasSpecialChar' | translate }}</mat-checkbox>

        </div>
    </div>
    <button mat-button class="b-btn b-success" (click)="updatePasswordPolicy()">{{ 'Common.Update' | translate
        }}</button>
</div>