import { Component, OnInit, Inject, ViewChild, Input, Optional, ElementRef, HostListener, OnDestroy, TemplateRef, AfterViewInit } from '@angular/core';
import { VisualService } from 'src/app/services/visual/visual.service';
import { VisualComp } from 'src/app/models/responses/visualComponentResponse';
import { environment } from 'src/environments/environment';
import { Widget, WidgetMeasure, ColorRuleItem, WidgetPreview } from 'src/app/models/responses/widgetLibraryResponse';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';
import { DataStore, Columns, BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { PreviewWidgetComponent } from 'src/app/components/preview-widget/preview-widget.component';
import { MatStepper } from '@angular/material/stepper';
import { CodeEditorComponent } from 'src/app/components/code-editor/code-editor.component';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFunctionService } from 'src/app/services/global-function/global-function.service';
import { SelfService } from 'src/app/services/self-service/self.service';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Dashboard, Dimension } from 'src/app/models/responses/dashboardResponse';
import { ShareService } from 'src/app/services/share/share.service';
import { Share } from 'src/app/models/responses/shareResponse';
import { filterString } from 'src/app/core/utils';
import { customBadgeProps, customDropdownProps, customDropdownSettingProps } from 'src/app/models/globals/customDropdownProps';
import { SweetAlertService } from 'src/app/services/sweet-alert/sweet-alert.service';
import { SettingAreas, dropdownMenus } from 'src/app/constants/enums';
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';
import { WidgetConfig } from 'src/app/models/responses/widgetConfig';
import * as _ from 'lodash';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { Router } from '@angular/router';
import { map, startWith, takeWhile, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatSelect } from '@angular/material/select';
declare let monaco: any;



@Component({
  selector: 'app-widget-form',
  templateUrl: './widget-form.component.html',
  styleUrls: ['./widget-form.component.scss']
})
export class WidgetFormComponent implements OnInit, OnDestroy, AfterViewInit {
  _notDialog: boolean = false
  @ViewChild('optionselection') optionselection?: MatSelect;



  @Input() set notDialog(value) {

    this._notDialog = value
    if (value)
      this.dataStoresDropdownSettings.endIcons.push({
        icon: 'open',
        clickFunction: (e) => this.openEditTable(e),
        enable: true,
        popover: 'WidgetFormPage.DataEdit'
      })
    else
      this.dataStoresDropdownSettings.endIcons = this.dataStoresDropdownSettings.endIcons.filter(x => x.icon !== 'open')


  }

  freezeFilter: boolean = false
  widgetIsFilter: boolean = false


  saveAsActive: boolean = false
  settingAreaIds: string[] = [SettingAreas.visual, SettingAreas.data, SettingAreas.event]
  loadingPreview: boolean = false
  isFirstLoad: boolean = true;
  title: string = ""
  disablePreview: boolean = false
  widgetPreviewData: WidgetPreview;

  searchValues = [];
  dateGroupByTextList = ['None', 'Yearly', 'Monthly', 'Daily', 'Hourly', 'Minutely']
  editorOptions = { theme: 'vs-dark', language: 'javascript' };
  useDirectQuery: boolean = false
  sqlcodeScreen: boolean = false

  isShownQueryForm: boolean = true;
  queryFilter: BussionFilterDefinition = {
    fieldName: '',
    fieldValue: '',
    operator: ''
  }
  widget: Widget = {
    visualComponentId: "",
    title: "",
    subTitle: "",
    footNote: "",
    access: 1,
    dateGroup: 0,
    customizeCode: 0,
    type: "",
    dataStoreId: "",
    owner: null,
    includeZeroValues: 1,
    domain: "",
    limitData: "500",
    code: "",
    sharedData: null,
    sort: {
      sortField: "",
      sortOrder: "",
      sortType: ""
    },
    colors: {
      type: 0,
      colors: []
    },
    dimensions: [],
    measures: [],
    filter: [],
    widgetParameters: [],
    dimensionsData: [],
    layout: {
      cols: 1,
      rows: 1,
      x: 0,
      y: 0,
      designerContainer: null
    },
    mobileLayout: {
      cols: 0,
      rows: 1,
      x: 0,
      y: 0,
      designerContainer: null
    },
    freezeFilter: 0,
    drillDownDashboard: '',
    drillDownLink: '',
    drillDown: {
      dashboard: '',
      dashboardFilter: 0,
      height: 0,
      width: 0,
      link: '',
      openerMethod: '',
      widgetFilter: 0,
      shareKey: ''

    },
    config: [],
    params: {},
    calculatedFields: [],
    isFilter: 0,
    offSet: -1,
    disableInMemoryCache: 0,
    ignoreFilterList: [],
    widgetJoin: [],
    widgetQuery: '',

  };

  infoBadgeList: customBadgeProps[] = []
  queryFilterFieldType: string = "System.String";

  baseImageUrl = environment.imageUrl;
  visualComponents: VisualComp[] = [];
  visualComponentsForDropdownMenu: customDropdownProps[] = [];
  visualComponentsDropdownSettings: customDropdownSettingProps = {
    name: 'Visual Components',
    imageCard: true,
    multipleSelection: false,
    id: SettingAreas.visual,
    iconMenuActive: false,
    searchActive: true,
    placeholder: "WidgetFormPage.SearchComponentType",
    dragActive: false,
    changeSortWithDrop: (e) => { },
    selectElement: (e) => this.changevisualComponent(e),
    progressValue: 0,
    errorValues: { text: '', active: false }


  }
  oldWidget: WidgetPreview

  dataStores: DataStore[] = [];
  dataStoresForDropdownMenu: customDropdownProps[] = []
  dataStoresDropdownSettings: customDropdownSettingProps = {
    name: 'WidgetFormPage.DataSettings',
    multipleSelection: false,
    dragActive: false,
    imageCard: false,
    searchActive: true,
    changeSortWithDrop: (e) => { },
    endIcons: [
      {
        icon: 'file-preview',
        clickFunction: (e) => this.showPreviewData(e),
        enable: true,
        popover: 'WidgetFormPage.DataPreview'

      }],
    id: SettingAreas.data,
    iconMenuActive: false,
    placeholder: "WidgetFormPage.SearchDataStore",
    selectElement: (e) => this.dataStoreSelected(e),
    progressValue: 0,
    errorValues: { text: '', active: false }


  }

  sortAndLimitDropdownSettings: customDropdownSettingProps = {
    name: "WidgetFormPage.SortAndLimit",
    imageCard: false,
    id: dropdownMenus['Sort And Limit'],
    iconMenuActive: true,
    searchActive: false,
    iconMenuEmitter: (e) => this.sortAndLimitSelection(e),
    placeholder: 'Bir Data Limit Değeri Giriniz',
    triggerMenuIcon: { icon: 'menu-icon.svg', tooltip: '' },
    selectElement: (e) => { },
    dragActive: false,
    changeSortWithDrop: (e) => { },
    multipleSelection: false,
    topButtons: [{
      title: "Common.ClearAll",
      icon: 'clear_all',
      id: 'reset',
      eventFunction: () => {

        (document.getElementById('limitData') as HTMLInputElement).value = '500';
        document.querySelectorAll('#sortType mat-button-toggle').forEach(item => {
          item.classList.remove('mat-button-toggle-checked')
        })
        document.querySelectorAll('#sortOrder mat-button-toggle').forEach(item => {
          item.classList.remove('mat-button-toggle-checked')
        })



        this.widget.sort = {
          sortField: "",
          sortOrder: "",
          sortType: ""
        }
        this.widget.limitData = '500'

        this.controlForPreview(this.widget)
      }
    }],
    input: [
      {
        inputType: 'number',
        defaultValue: this.data?.widget?.limitData,
        inputId: 'limitData',
        title: this.translate.instant('WidgetFormPage.LimitData'),
        visible: true,
        getValueFunction: (e) => {
          if (e.length > 0) {
            this.widget.limitData = e
            this.controlForPreview(this.widget)
          }
        }
      },
      {
        inputType: 'button-toggle',
        defaultValue: this.data?.widget?.sort?.sortType ? this.data?.widget?.sort?.sortType : null,
        width: '90%',
        inputId: 'sortType',
        visible: false,
        options: [{ key: 'Alph', value: '0' }, { key: 'Num', value: '1' }],
        title: this.translate.instant('WidgetFormPage.SortType'),
        getValueFunction: (e) => {
          this.widget.sort.sortType = e
          this.controlForPreview(this.widget)
        }
      },
      {
        inputType: 'select',
        defaultValue: this.data?.widget?.sort?.sortField ?? null,
        width: '90%',
        inputId: 'sortField',
        visible: true,
        options: [],
        title: this.translate.instant('WidgetFormPage.SortField'),
        getValueFunction: (e) => {
          this.widget.sort.sortField = e
        }
      },
      {
        inputType: 'button-toggle',
        defaultValue: this.data?.widget?.sort?.sortOrder ? this.data?.widget?.sort?.sortOrder : null,
        width: '90%',
        inputId: 'sortOrder',
        options: [{ key: 'Asc', value: '0' }, { key: 'Desc', value: '1' }],
        title: this.translate.instant('WidgetFormPage.SortOrder'),
        visible: true,
        getValueFunction: (e) => {
          this.widget.sort.sortOrder = e
          this.controlForPreview(this.widget)

        }
      },
    ],
    progressValue: 0,
    errorValues: { text: '', active: false }


  }




  sortAndLimitForDropdownMenu: customDropdownProps[] = []



  sampleDataForDataStore: any[] = []
  dataStoreColumns: Columns[] = [];
  dataStoreColumnsForDropdownMenu: customDropdownProps[] = []
  dataStoreColumnsDropdownSettings: customDropdownSettingProps = {
    name: 'WidgetFormPage.aggregationSettings',
    imageCard: false,
    id: SettingAreas.agregation,
    iconMenuActive: true,
    searchActive: true,
    iconMenuEmitter: (e) => this.addMeasurement(e),
    multipleSelection: true,
    dragActive: true,
    changeSortWithDrop: (e) => this.changeDimensionSort(e),
    placeholder: "WidgetFormPage.SearchColumn",
    triggerMenuIcon: { icon: 'measure.png', tooltip: '' },
    selectElement: (e) => this.dimensionSelected(e),
    checkIcon: { icon: 'filter.png', function: (e) => this.changeDimensionFilterStatus(e), tooltip: "WidgetFormPage.FieldFilter" },
    progressValue: 0,
    errorValues: { text: '', active: false }


  }



  selectedPreviewScreen: string = 'widget'
  selectedDataPreviewColumns: Columns[] = []

  selectedDataPreviewColumnsForAggregation: Columns[] = []
  dataPreviewColumnsForQuery: Columns[] = []
  queryColumnsForDropdownMenu: customDropdownProps[] = []


  codeScreen: boolean = false
  chartRerenderLoading: boolean = false

  filterScreen: boolean = false
  codeString: string = ''





  configDropdownSettings: customDropdownSettingProps = {
    name: "WidgetFormPage.Config",
    imageCard: false,
    id: dropdownMenus['Config'],
    iconMenuActive: false,
    placeholder: 'Config',
    dragActive: false,
    searchActive: false,
    changeSortWithDrop: (e) => { },
    multipleSelection: false,
    triggerMenuIcon: { icon: 'menu-icon.svg', tooltip: '' },
    input: [],
    selectElement: (e) => { },
    progressValue: 0,
    errorValues: { text: '', active: false }



  }
  calculatedFieldList: { name: string, formula: string, id: number }[] = []
  calculatedFieldNameError: boolean = false
  calculatedFieldUpdate: { name: string, formula: string, id: number } = null


  dataStoreColumnsForFilter: Columns[] = [];
  isCreateForm: boolean = true;


  widgetMeasure: WidgetMeasure = {
    label: "",
    name: "",
    function: "",
    formula: "",
    isCalculatedField: 0
  }

  colorRule: ColorRuleItem = {
    colorCode: "",
    operator: "",
    text: "",
    value: ""
  }

  dashboardId: string = "";
  widgetList = [];


  calculatedForm: UntypedFormGroup;
  ignoreForm: UntypedFormGroup;
  joinForm: UntypedFormGroup;


  color: string = "";
  selectedColorDimension: string = "";
  colorDimensionData: string[] = [];

  filterParam: number
  dashboards: Dashboard[] = []
  selectedDashboardForViews: Dashboard
  myShares: Share[] = []
  selectedShare: Share
  dashboardShares: Share[] = []
  dynamicFilterForChart: BussionFilterDefinition = {
    fieldName: '',
    fieldValue: 'Selected',
    operator: ''
  }
  dataSettingsTab: boolean = true
  useDynamicFilter: boolean = false
  titleBoxVisible: boolean = false

  drilDownDropdownSettings: customDropdownSettingProps = {
    name: 'WidgetFormPage.EventSettings',
    imageCard: false,
    id: SettingAreas.event,
    iconMenuActive: false,
    multipleSelection: false,
    dragActive: false,
    searchActive: false,
    changeSortWithDrop: (e) => { },
    placeholder: 'Bir Data Limit Değeri Giriniz',
    selectElement: (e) => { },
    topButtons: [{
      title: "Common.ClearAll",
      icon: 'clear_all',
      id: 'reset',
      eventFunction: () => {
        (document.getElementById('dashboardSelect1') as HTMLSelectElement).selectedIndex = 0;
        (document.getElementById('viewsSelect1') as HTMLSelectElement).selectedIndex = 0;
        this.drilDownDropdownSettings.input.map(item => {
          item.defaultValue = ''
        })

        this.resetDrillDown();
        this.controlProgressValue();
        this.updateAllDropdownSettings();

      }
    }],
    input: [
      {
        inputType: 'select',
        id: 'dashboard',
        width: '80%',
        visible: true,
        inputId: 'dashboardSelect1',
        title: this.translate.instant('WidgetFormPage.Dashboard'),
        getValueFunction: (e) => {
          this.widget.drillDownDashboard = e
          this.widget.drillDown.dashboard = e
          this.widget.drillDown.link = ''
          this.widget.drillDownLink = ''
          if (this.dashboardId == e)
            this.drilDownDropdownSettings.input.find(x => x.id == 'modalStyle').options.push(
              { key: 'Self Window', value: 'selfWindow' }
            )

          this.getDashboardViews(e)
          this.changePreviewData()
          this.controlProgressValue()

          this.drilDownDropdownSettings.input.find(x => x.id == 'dashboard').defaultValue = this.widget.drillDownDashboard;
          this.updateAllDropdownSettings();
        },
        defaultValue: this.data?.widget?.drillDownDashboard,
        options: []
      },
      {
        inputType: 'select',
        id: 'views',
        width: '80%',
        visible: true,
        inputId: 'viewsSelect1',
        title: this.translate.instant('WidgetFormPage.Views'),
        getValueFunction: (e) => {
          // this.controlProgressValue()
          this.changeView(e)
          this.drilDownDropdownSettings.input.find(x => x.id == 'dynamicFilter').disabled = false
          document.getElementById('dynamicFilterToggle1').classList.remove('mat-checked')
          this.widget.drillDown.widgetFilter = 0

          this.drilDownDropdownSettings.input.find(x => x.id == 'dynamicFilter2').disabled = false
          this.controlProgressValue()

          this.drilDownDropdownSettings.input.find(x => x.id == 'views').defaultValue = this.widget.drillDown.shareKey;
          this.updateAllDropdownSettings();
        },
        defaultValue: this.data?.widget?.drillDown?.shareKey,
        options: []
      },
      {
        inputType: 'select',
        id: 'modalStyle',
        width: '80%',
        visible: true,
        inputId: 'modalSelect',
        title: this.translate.instant('WidgetFormPage.WindowType'),
        getValueFunction: (e) => {
          this.widget.drillDown.openerMethod = e
          this.changePreviewData()
          this.controlProgressValue()

          this.drilDownDropdownSettings.input.find(x => x.id == 'modalStyle').defaultValue = this.widget.drillDown.openerMethod;
          this.updateAllDropdownSettings();

        },
        defaultValue: this.data?.widget?.drillDown?.openerMethod,
        options: [
          { key: 'Center', value: 'centerWindow' },
          { key: 'Left Window', value: 'leftWindow' },
          { key: 'Right Window', value: 'rightWindow' },
          { key: 'New Tab', value: 'newWindow' },

        ]
      },
      {
        inputType: 'number',
        id: 'windowWidth',
        visible: true,
        inputId: 'windowWidth',
        disabled: false,
        title: this.translate.instant('WidgetFormPage.WindowWidth') + ' %',
        getValueFunction: (e) => {
          this.widget.drillDown.width = e
          this.changePreviewData()

          this.drilDownDropdownSettings.input.find(x => x.id == 'windowWidth').defaultValue = this.widget.drillDown.width;
          this.updateAllDropdownSettings();

        },
        defaultValue: this.data?.widget?.drillDown?.width ? this.data?.widget?.drillDown?.width : 0,
      },
      {
        inputType: 'number',
        id: 'windowHeight',
        visible: true,
        inputId: 'windowHeight',
        disabled: false,
        title: this.translate.instant('WidgetFormPage.WindowHeight') + ' %',
        getValueFunction: (e) => {
          this.widget.drillDown.height = e
          this.changePreviewData()

          this.drilDownDropdownSettings.input.find(x => x.id == 'windowHeight').defaultValue = this.widget.drillDown.height;
          this.updateAllDropdownSettings();

        },
        defaultValue: this.data?.widget?.drillDown?.height ? this.data?.widget?.drillDown?.height : 0,
      },
      {
        inputType: 'toggle',
        id: 'dynamicFilter',
        visible: true,
        inputId: 'dynamicFilterToggle1',
        disabled: true,
        title: this.translate.instant('WidgetFormPage.useWidgetFilter'),
        getValueFunction: (e) => {

          this.widget.drillDown.widgetFilter = e.checked ? 1 : 0

          this.ChangeUrlForDynamic(e.checked, 'widget')
          console.log('this.widget.drillDown.widgetFilter', this.widget.drillDown.widgetFilter, this.widget.drillDown.widgetFilter == 1 ? true : false)
          this.drilDownDropdownSettings.input.find(x => x.id == 'dynamicFilter').defaultValue = this.widget.drillDown.widgetFilter == 1 ? true : false;
          this.updateAllDropdownSettings();

        },
        defaultValue: this.data?.widget?.drillDown?.widgetFilter && this.data.widget.drillDown.widgetFilter == 1 ? true : false,
        options: []
      },
      {
        inputType: 'toggle',
        id: 'dynamicFilter2',
        visible: true,
        inputId: 'dynamicFilterToggle2',
        disabled: true,
        title: this.translate.instant('WidgetFormPage.useDashboardFilter'),
        getValueFunction: (e) => {

          this.widget.drillDown.dashboardFilter = e.checked ? 1 : 0
          this.ChangeUrlForDynamic(e.checked, 'dashboard')

          this.drilDownDropdownSettings.input.find(x => x.id == 'dynamicFilter2').defaultValue = this.widget.drillDown.dashboardFilter == 1 ? true : false;
          this.updateAllDropdownSettings();

        },
        defaultValue: this.data?.widget?.drillDown?.dashboardFilter && this.data.widget.drillDown.dashboardFilter == 1 ? 'true' : 'false',
        options: []
      }
    ],
    progressValue: 0,
    errorValues: { text: '', active: false }


  }



  // progressValues: any = {
  //   [SettingAreas.data]: 0,
  //   [SettingAreas.event]: 0,
  //   [SettingAreas.visual]: 0,
  //   [SettingAreas.agregation]: 0

  // }
  selectedSettingArea = SettingAreas.data



  filteredOptions: Observable<{ value: string, desc: string }[]>;
  options: { value: string, desc: string }[] = [{ value: 'sum()', desc: '' }, { value: 'avg()', desc: '' }, { value: 'max()', desc: '' }, { value: 'min()', desc: '' }, { value: 'percent()', desc: '' }, { value: '+', desc: '' }, { value: '-', desc: '' }, { value: '/', desc: '' }, { value: '*', desc: '' }];
  allOptions: { value: string, desc: string }[] = [{ value: 'sum()', desc: '' }, { value: 'avg()', desc: '' }, { value: 'max()', desc: '' }, { value: 'min()', desc: '' }, { value: 'percent()', desc: '' }, { value: '+', desc: '' }, { value: '-', desc: '' }, { value: '/', desc: '' }, { value: '*', desc: '' }];

  @ViewChild('calculated') calculated: TemplateRef<any>;
  @ViewChild('paint') paint: TemplateRef<any>;

  dataStoreColumnsSettingTabs = [
    { name: 'measure', icon: 'measure', selected: true, dropdown: { setting: this.dataStoreColumnsDropdownSettings, data: this.dataStoreColumnsForDropdownMenu } },
    { name: 'limit', icon: 'sort2', selected: false, dropdown: { setting: this.sortAndLimitDropdownSettings, data: this.sortAndLimitForDropdownMenu } },
    { name: 'calculated', icon: 'fx', contentTemplate: null, selected: false, },
  ]

  visualSettingTabs = [
    { name: 'chart', icon: 'chart2', selected: true, dropdown: { setting: this.visualComponentsDropdownSettings, data: this.visualComponentsForDropdownMenu } },
    { name: 'paint', icon: 'paint', selected: false, contentTemplate: null },
    { name: 'config', icon: 'custom', selected: false, dropdown: { setting: this.configDropdownSettings, data: [] } },
  ]

  allDropdownSettings: {
    id: string, setting: customDropdownSettingProps, data: customDropdownProps[],
    tabs: any
  }[] = [
      { id: SettingAreas.data, setting: this.dataStoresDropdownSettings, data: this.dataStoresForDropdownMenu, tabs: [] },
      { id: SettingAreas.agregation, setting: this.dataStoreColumnsDropdownSettings, data: this.dataStoreColumnsForDropdownMenu, tabs: this.dataStoreColumnsSettingTabs },
      { id: SettingAreas.visual, setting: this.visualComponentsDropdownSettings, data: this.visualComponentsForDropdownMenu, tabs: this.visualSettingTabs },
      { id: SettingAreas.event, setting: this.drilDownDropdownSettings, data: [], tabs: [] }
    ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      widget: Widget,
      dashboardId: string,
      widgetList: any[],
      type: string,
      dashboardFilter: BussionFilterDefinition[],
      deleteWidget: Function
    },
    private dialog: MatDialog,
    @Optional() private dialogRef: MatDialogRef<WidgetFormComponent>,
    private spinner: NgxSpinnerService,
    private visualService: VisualService,
    private dataStoreService: DataStoreService,
    private dashboardService: DashboardService,
    private coreService: CoreService,
    private translate: TranslateService,
    private functionService: GlobalFunctionService,
    private scriptLoader: ScriptLoaderService,
    private shareService: ShareService,
    private sweetalertService: SweetAlertService
  ) { }

  ngAfterViewInit(): void {
    this.dataStoreColumnsSettingTabs.find(x => x.name == 'calculated').contentTemplate = this.calculated
    this.visualSettingTabs.find(x => x.name == 'paint').contentTemplate = this.paint

  }

  ngOnInit(): void {



    this.setupValidation();
    this.updateInsertFormSetting()
    this.getVisualComponents();
    this.getDataStores();
    this.getDashboards()

    this.changedConfig()

  }

  updateInsertFormSetting() {

    this.dashboardId = this.data.dashboardId;
    this.widgetList = this.data.widgetList;

    if (this.data.type == 'update') {

      this.title = "WidgetFormPage.TitleUpdate";
      this.isCreateForm = false;
    } else {
      this.isCreateForm = true;
      this.title = "WidgetFormPage.TitleCreate";
    }

    if (this.data.widget) {
      this.calculatedFieldList = this.data.widget.calculatedFields.map((item, key) => {
        return { ...item, id: key + 1 }
      })

      if (this.data.widget.drillDownLink) {
        // this.data.widget.drilDownShareKey = this.data.widget.drillDownLink.split('?')[0].split('/')[1]


        if (this.dashboardId == this.data.widget.drillDownDashboard)
          this.drilDownDropdownSettings.input.find(x => x.id == 'modalStyle').options.push({ key: 'Self Window', value: 'selfWindow' })
      }
      if (this.data.widget.dataStoreId) {
        this.freezeFilter = this.data.widget.freezeFilter == 0 ? false : true
        this.widgetIsFilter = this.data.widget.isFilter == 0 ? false : true
        this.getSelectedDsColumns(this.data.widget.dataStoreId)
        this.getSampleData(this.data.widget.dataStoreId)
      }
      if (this.data.widget.measures) {
        this.infoBadgeList = this.data.widget.measures.map(item => { return { name: item.label, detail: item, subTextKey: 'function', disableEdit: item.isCalculatedField == 1 ? true : false } })
      }

      if (this.data.widget.widgetQuery) {
        this.useDirectQuery = true;
        this.allDropdownSettings.find(x => x.id == SettingAreas.agregation).tabs = [];
      }



      this.widget = Object.assign({}, this.data.widget);
      this.sortAndLimitDropdownSettings.input.find(x => x.inputId == 'sortField').options = this.widget.dimensions.map(dim => {
        return { key: dim.fieldName, value: dim.fieldName }
      })
      this.sortFieldVisible();
      this.controlForPreview(this.data.widget)

      //this.dataStoreSelected();
      if (this.widget.dimensions.length > 0)
        this.dynamicFilterForChart = {
          fieldName: this.widget.dimensions[0].fieldName,
          fieldValue: 'Selected',
          operator: '='
        }
      this.changeCalculatedOptions();
      this.getDashboardViews(this.data.widget.drillDownDashboard)
    }
  }

  setSelectedSettingArea(settingArea: SettingAreas) {
    if (settingArea == SettingAreas.agregation && !this.widget.dataStoreId) {
      return;
    }
    if (settingArea == SettingAreas.visual && this.widget.dimensions.length == 0 && this.widget.measures.length == 0) {
      return;

    }
    if (settingArea == SettingAreas.event && !this.widget.visualComponentId) {
      return;

    }
    this.selectedSettingArea = settingArea
  }

  private _filter(value: string): { value: string, desc: string }[] {
    const filterValue = value.toLowerCase(); // Küçük harfe dönüştürerek filtreleme işlemi yapıyoruz
    return this.options.filter(option => option.value.toLowerCase().includes(filterValue)); // Seçenekleri filtreliyoruz
  }



  onOptionSelected(event: any) {


    var selectedValue = event.value;

    const lastSpaceIndex = this.calculatedForm.value.formula.lastIndexOf(' ');
    var firstPart = this.calculatedForm.value.formula

    if (lastSpaceIndex !== -1) {
      firstPart = this.calculatedForm.value.formula.substring(0, lastSpaceIndex); // Boşluktan önceki kısmı alır
    }


    this.calculatedForm.patchValue({
      formula: firstPart + ' ' + selectedValue
    })

    this.optionselection.writeValue(null);
  }

  changedConfig() {

    this.scriptLoader.changedConfigs.pipe(
      takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
    ).subscribe(res => {

      if (res.config)
        this.widget[res.configtype] = res.config
      // console.log('girdi', this.widget, res.config)
    })
  }
  alive = true
  ngOnDestroy(): void {
    this.scriptLoader._selectedTabForClick.next('')
    this.alive = false
  }



  changePreviewData() {
    if (this.widgetPreviewData)
      this.widgetPreviewData['widget'].drillDown = this.widget.drillDown

  }




  getDashboards() {
    if (this.dashboardService.cachedData.getValue().authorizedDashboard.length > 0) {
      this.dashboards = this.dashboardService.cachedData.getValue().authorizedDashboard
      this.selectedDashboardForViews = this.dashboards.find(x => x.dashboardId == this.data.widget?.drillDownDashboard)

      this.drilDownDropdownSettings.input.find(x => x.id == 'dashboard').options = this.dashboards.map(dash => {
        return { key: dash.title, value: dash.dashboardId }
      })
    } else {
      this.dashboardService.getAuthorizedDashboards(1).pipe(
        takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
      ).subscribe(res => {
        this.dashboards = res
        this.selectedDashboardForViews = this.dashboards.find(x => x.dashboardId == this.data.widget?.drillDownDashboard)
        this.drilDownDropdownSettings.input.find(x => x.id == 'dashboard').options = this.dashboards.map(dash => {
          return { key: dash.title, value: dash.dashboardId }
        })
      })
    }
  }

  getDashboardViews(dashboardId: string) {
    this.selectedDashboardForViews = this.dashboards.find(x => x.dashboardId == dashboardId)
    this.dashboardShares = []
    var selectedShares = this.myShares.filter(x => x.dashboardId == dashboardId)
    if (selectedShares.length > 0) {
      this.dashboardShares = selectedShares
      if (this.data.widget.drillDown.shareKey) {
        this.setSelectedView(this.data.widget.drillDown.shareKey)
      }
    } else {
      this.shareService.getMyShare(dashboardId).pipe(
        takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
      ).subscribe((myShares: Share[]) => {
        myShares = myShares.map(share => {
          let filter = filterString(share.filter)
          return { ...share, url: `/${share.shareKey}?filters=${filter}` }
          // window.origin +/#/share
        })
        this.drilDownDropdownSettings.input.find(x => x.id == 'views').options = myShares.map(share => {
          return { key: share.name, value: share.shareKey }
        })
        this.myShares = this.myShares.concat(myShares)
        this.dashboardShares = this.myShares.filter(x => x.dashboardId == dashboardId)
        if (this.data?.widget && this.data.widget.drillDown.shareKey) {
          this.setSelectedView(this.data.widget.drillDown.shareKey)
          // this.changeView(this.data.widget.drilDownShareKey)
        }
      })
    }
  }

  setSelectedView(sharekey: string) {
    this.drilDownDropdownSettings.input.find(x => x.id == 'dynamicFilter').disabled = false
    this.drilDownDropdownSettings.input.find(x => x.id == 'dynamicFilter2').disabled = false
    this.selectedShare = this.dashboardShares.find(x => x.shareKey == sharekey)
    this.updateAllDropdownSettings()
  }

  openEditTable(ds: customDropdownProps) {
    var baseUrl = environment.baseUrl
    var link = document.createElement('a');
    // link.href = `/#/selfservice/analyze/grid-analytics/${ds.id}`;
    link.href = baseUrl + `/Apps/gridAnalytics-47681312178742151388-155152/?${ds.id}`
    link.target = "_blank";
    var event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    link.dispatchEvent(event);
  }


  ChangeUrlForDynamic(use: boolean, type: string) {
    let filter = this.selectedShare.filter

    if (this.widget.drillDown?.link?.includes('filters')) {
      filter = JSON.parse(this.widget.drillDown?.link.split('filters=')[1])
    }
    var filterstringText = filterString(filter)

    if (type == 'widget') {
      if (use) {

        if (!filter.some(x => x.fieldValue == "Selected")) {
          this.dynamicFilterForChart = {
            fieldName: this.widget.dimensions[0].fieldName,
            fieldValue: 'Selected',
            operator: '='
          }
          filter.push(this.dynamicFilterForChart)
        }

      } else {
        if (filter.some(x => x.fieldValue == "Selected")) {
          filter = filter.filter(x => x.fieldValue !== "Selected")
          this.selectedShare.filter = filter
        }
      }
    }

    filterstringText = filterString(filter)
    var createdUrl = `/${this.selectedShare.shareKey}?filters=${filterstringText}`

    this.selectedShare.filter = filter
    this.widget.drillDown.link = createdUrl
    this.widget.drillDownLink = createdUrl
    this.changePreviewData();

  }

  changeView(shareKey: string) {
    // this.widget.drilDownShareKey = shareKey
    this.widget.drillDown.shareKey = shareKey

    this.selectedShare = this.dashboardShares.find(x => x.shareKey == shareKey)

    // if (this.widget.drillDownLink) {
    this.widget.drillDownLink = this.selectedShare.url
    this.widget.drillDown.link = this.selectedShare.url
    this.drilDownDropdownSettings.input.find(x => x.id == 'dynamicFilter').disabled = false
    this.drilDownDropdownSettings.input.find(x => x.id == 'dynamicFilter2').disabled = false
    this.widget.drillDown.dashboard = this.widget.drillDownDashboard

    // }
    this.changePreviewData()

    if (this.selectedShare.url.includes('Selected')) {
      this.useDynamicFilter = true
    }



  }

  resetDrillDown() {
    // this.widget.drilDownShareKey = ''
    this.widget.drillDownDashboard = ''
    this.widget.drillDownLink = ''
    this.widget.drillDown = {
      dashboard: '',
      dashboardFilter: 0,
      height: 0,
      width: 0,
      link: '',
      openerMethod: '',
      widgetFilter: 0,
      shareKey: ''

    }
    this.useDynamicFilter = false
    this.selectedShare = {
      shareId: '',
      name: '',
      sharedObjectId: '',
      objectType: '',
      dashboardId: '',
      shareState: '',
      shareKey: '',
      sharedBy: '',
      sharedUserId: '',
      lang: '',
      domain: '',
      sharedDate: '',
      sharedData: '',
      enableFilter: 0,
      enableTitle: 0,
      filter: [],
      shareType: 0,
      shareEncryptKey: '',
      url: '',
      lockedFilter: 0,
      authenticatedUsers: '',
      authentication: 0
    }
  }



  editorInit(eventInit: any) {
    (window as any).monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: true
    });

    (window as any).monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES6,
      allowNonTsExtensions: true
    });

    this.getGlobalFunctionDefinitions();

  }

  getGlobalFunctionDefinitions() {
    this.functionService.getGlobalFunctionsDefinitions().pipe(
      takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
    ).subscribe(code => {
      (window as any).monaco.languages.typescript.javascriptDefaults.addExtraLib(code);
    })
  }


  private setupValidation() {


    this.calculatedForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      formula: new UntypedFormControl('', [Validators.required]),
    });
    this.ignoreForm = new UntypedFormGroup({
      ignoreKey: new UntypedFormControl('', [Validators.required]),
    });

    this.joinForm = new UntypedFormGroup({
      source: new UntypedFormControl('', [Validators.required]),
      destination: new UntypedFormControl('', [Validators.required])
    });



    this.filteredOptions = this.calculatedForm.controls.formula.valueChanges.pipe(
      startWith(''),
      map(value => {
        var splittedValue = value.split(' '); // Boşluklara göre ayır

        if (value.includes('(') && !value.includes(')')) {
          splittedValue = value.split('(')
        }
        return this._filter(splittedValue[splittedValue.length - 1] || '')
      }),
    );
  }

  changeCalculatedOptions() {
    this.options = this.options.filter(x => x.desc == '')
    this.widget.measures.filter(x => x.isCalculatedField == 0).map(item => {
      if (this.options.some(x => x.value !== item.name))
        this.options.push({ desc: item.formula, value: item.name })
      else {
        this.options.find(x => x.value == item.name).value = item.name
      }

    })
    this.widget.dimensions.map(item => {
      if (this.options.some(x => x.value !== item.fieldName))
        this.options.push({ desc: item.fieldName, value: item.fieldName })
      else {
        this.options.find(x => x.value == item.fieldName).value = item.fieldName
      }
    })
  }

  AddCalculatedField() {
    if (this.calculatedForm.valid && !this.calculatedFieldList.some(x => x.name == this.calculatedForm.value.name)) {
      var id = this.calculatedFieldList.length > 0 ? (this.calculatedFieldList[this.calculatedFieldList.length - 1].id + 1) : 1
      this.calculatedFieldList.push({ ...this.calculatedForm.value, id: id })
      this.widget.calculatedFields = this.calculatedFieldList.map(item => {
        return { name: item.name, formula: item.formula }
      })

      this.getCalculatedFieldsForDropdown().map(item => {
        if (!this.dataStoreColumnsForDropdownMenu.some(x => x.id == item.id))
          this.dataStoreColumnsForDropdownMenu.push(item)
        else
          this.dataStoreColumnsForDropdownMenu.find(x => x.id == item.id).element = item.element
      })

      this.controlForPreview(this.widget)
      this.calculatedForm.reset()
    }
  }

  deleteCalculatedField(field: { name: string, formula: string, id: number }) {
    if (this.calculatedFieldUpdate?.id == field.id) {
      this.calculatedFieldUpdate = null
    }
    this.calculatedFieldList = this.calculatedFieldList.filter(x => x.name !== field.name)
    this.widget.calculatedFields = this.calculatedFieldList.map(item => {
      return { name: item.name, formula: item.formula }
    })

    this.dataStoreColumnsForDropdownMenu = this.dataStoreColumnsForDropdownMenu.filter(x => x.id !== field.name)
    this.widget.measures = this.widget.measures.filter(x => x.name !== field.name)

    this.controlForPreview(this.widget)
  }

  editCalculatedField(field: { name: string, formula: string, id: number }) {
    this.calculatedFieldUpdate = field
    this.calculatedForm.patchValue({
      name: field.name,
      formula: field.formula
    })
  }

  updateCalculatedField() {
    if (this.calculatedFieldUpdate.id) {
      this.calculatedFieldList.find(x => x.id == this.calculatedFieldUpdate.id).formula = this.calculatedForm.value.formula
      this.calculatedFieldList.find(x => x.id == this.calculatedFieldUpdate.id).name = this.calculatedForm.value.name

      this.widget.calculatedFields = this.calculatedFieldList.map(item => {
        return { name: item.name, formula: item.formula }
      })

      if (this.widget.measures.some(x => x.name == this.calculatedForm.value.name)) {
        this.widget.measures.find(x => x.name == this.calculatedForm.value.name).formula = this.calculatedForm.value.formula
        this.controlForPreview(this.widget)
      }

      this.calculatedForm.reset()
    }


  }



  controlNameForCalculated() {
    if (this.calculatedFieldList.some(x => x.name == this.calculatedForm.value.name)) {
      this.calculatedFieldNameError = true
    } else {
      this.calculatedFieldNameError = false

    }
  }

  private getDataStores() {
    this.dataStoreService.getDataSourceForUser().pipe(
      takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
    ).subscribe(result => {
      this.dataStores = result;
      this.dataStoresForDropdownMenu = this.dataStores.map(item => {
        return {
          id: item.dataStoreId,
          title: item.name,
          subtitle: item.description == '' ? item.creationDate?.split(' ')[0] : item.description,
          element: item,
          selected: this.widget.dataStoreId == item.dataStoreId ? true : false

        }
      })
      this.updateAllDropdownSettings();

    });
  }

  private getVisualComponents() {
    this.visualService.getVisualComponents().pipe(
      takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
    ).subscribe(result => {
      this.visualComponents = result;

      if (this.data?.widget?.visualComponentId) {
        var selected = this.visualComponents.find(x => x.visualComponentId == this.data.widget.visualComponentId)
        this.settingForVisualComponentConfig(selected)
      }


      this.visualComponentsForDropdownMenu = this.visualComponents.map(item => {
        return {
          id: item.visualComponentId,
          title: item.name,
          img: item.icon,
          element: item,
          selected: this.widget.visualComponentId == item.visualComponentId ? true : false

        }
      })


      this.visualSettingTabs.find(x => x.name == 'chart').dropdown.data = this.visualComponentsForDropdownMenu

      this.updateAllDropdownSettings()



    });
  }

  ChangePreviewScreen(screenName: string) {
    this.selectedPreviewScreen = screenName;

  }

  getCalculatedFieldsForDropdown() {
    return this.widget.calculatedFields.map(item => {
      var measure = {
        label: item.name,
        name: item.name,
        function: '',
        formula: item.formula,
        isCalculatedField: 1
      }
      return {
        id: item.name,
        title: item.name,
        subtitle: item.formula,
        element: measure,
        menu: [''],
        menu2: [],
        icon: 'fx',
        toggle: false,
        selectedMenuItem2: '',
        selectedMenuItem: this.widget.measures.some(x => x.name == item.name) ? item.name : '',
        selected: false
      }
    })
  }


  getSelectedDsColumns(dataStoreId: string) {

    this.dataStoreService.getColomnsForDataStore(dataStoreId).pipe(
      takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
    ).subscribe(result => {
      this.dataStoreColumns = result;
      this.dataStoreColumnsForFilter = [...result];
      if (!this.isFirstLoad) {
        this.widget.dimensions = [];
      }
      this.isFirstLoad = false;
      this.dataStoreColumnsForDropdownMenu = this.createDropdownDataForColumns(this.dataStoreColumns, this.widget.dimensions, this.widget.measures)

      this.dataStoreColumnsForDropdownMenu = this.dataStoreColumnsForDropdownMenu.concat(this.getCalculatedFieldsForDropdown())
      this.dataStoreColumnsSettingTabs.find(x => x.name == 'measure').dropdown.data = this.dataStoreColumnsForDropdownMenu;


    });
  }


  dataStoreSelected(selected: { element: DataStore }) {
    this.widget.dataStoreId = selected.element.dataStoreId
    //Datastore seçimi değişitiğinde diğer elemanları sıfırlamalıyız ki preview oluşmasın
    this.widget.measures = []
    this.widget.dimensions = []

    this.dataStoreColumnsForDropdownMenu = []
    this.dataStoreColumns = []
    // this.sortAndLimitForDropdownMenu.find(x => x.id == 'sortField').menu = []


    this.controlForPreview(this.widget)
    //datastore seç,ildiğinde sample datayı set edmeliyiz ki kullanıcıya gösterelim
    this.getSampleData(this.widget.dataStoreId)
    this.selectedPreviewScreen = 'data'

    if (this.widget.dataStoreId == '') {
      return;
    }
    this.getSelectedDsColumns(this.widget.dataStoreId)
    //to do
    this.widget.filter = []
  }

  sortFieldVisible() {
    if (this.widget.measures.length > 0) {
      this.sortAndLimitDropdownSettings.input.find(x => x.inputId == 'sortField').visible = false
      this.sortAndLimitDropdownSettings.input.find(x => x.inputId == 'sortType').visible = true

    } else {
      this.sortAndLimitDropdownSettings.input.find(x => x.inputId == 'sortField').visible = true
      this.sortAndLimitDropdownSettings.input.find(x => x.inputId == 'sortType').visible = false

    }
  }

  addMeasurement(dataList: customDropdownProps[]) {
    if (dataList.some(x => x.selectedMenuItem2)) {
      var selectedmenuitem2 = dataList.find(x => x.selectedMenuItem2).selectedMenuItem2
      this.widget.dateGroup = dataList.find(x => x.selectedMenuItem2).menu2.findIndex(x => x == selectedmenuitem2)
      this.dataStoreColumnsForDropdownMenu.filter(x => x.selectedMenuItem2 == '').map(item => {
        item.menu2Disable = true
      })
    } else {
      this.widget.dateGroup = 0
      this.dataStoreColumnsForDropdownMenu.map(item => {
        item.menu2Disable = false
      })
    }

    var calculatedElements = dataList.filter(x => x.selectedMenuItem == x.title)
    var calculatedmeasures = []
    if (calculatedElements.length > 0) {
      dataList = dataList.filter(x => x.selectedMenuItem !== x.title)
      calculatedmeasures = calculatedElements.map(item => {
        return {
          label: item.id.toString(),
          name: item.id.toString(),
          function: '',
          formula: item.element.formula,
          isCalculatedField: 1
        }
      })
    }

    var measurments = dataList.filter(x => x.selectedMenuItem).map(item => {
      return {
        label: item.id.toString(),
        name: item.id.toString(),
        function: item.selectedMenuItem == 'Count Distinct' ? 'cdisct' : item.selectedMenuItem,
        formula: "",
        isCalculatedField: 0
      }
    })

    if (calculatedmeasures.length > 0) {
      measurments = measurments.concat(calculatedmeasures)
    }
    this.infoBadgeList = measurments.map(item => { return { name: item.label, detail: item, subTextKey: 'function', disableEdit: item.isCalculatedField == 1 ? true : false } })

    this.widget.measures = measurments
    this.changeCalculatedOptions()
    this.sortFieldVisible()
    this.controlForPreview(this.widget)
    // this.widget.measures.push(this.widgetMeasure);
    // this.widgetMeasure = {
    //   label: "",
    //   name: "",
    //   function: "",
    //   formula: "",
    // }
  }


  deleteMeasure(measure: WidgetMeasure) {
    let index = this.widget.measures.findIndex(x => {
      return x.formula === measure.formula && x.function === measure.function && x.name === measure.name;
    });
    if (index !== -1) {
      this.widget.measures.splice(index, 1);
    }
  }

  upsertWidget() {
    //  this.widget.freezeFilter = this.fifthForm.value.FreezeFilter == false ? 0 : 1
    this.spinner.show()
    if (this.saveAsActive) {
      delete this.widget.widgetId;

    }

    this.widget.freezeFilter = this.freezeFilter == false ? 0 : 1
    this.widget.isFilter = this.widgetIsFilter == false ? 0 : 1
    this.widget.widgetQuery = this.useDirectQuery ? this.widget.widgetQuery : '';
    this.widget.disableInMemoryCache = this.widget.disableInMemoryCache ? 1 : 0;

    //seçilen renkleri sırasına göre isimlerle eşleştiriyoruz
    this.widget.colors.colors = this.widget.colors.colors.map((item, key) => {
      var selectedItem = this.widgetPreviewData.widgetData[key]
      var text = ''
      if (selectedItem && selectedItem[this.widget.dimensions[0].fieldName])
        text = selectedItem[this.widget.dimensions[0].fieldName]

      return { ...item, text: text }
    })

    this.dashboardService.upsertWidgetToDashboard(this.widget, this.dashboardId).pipe(
      takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
    ).subscribe(async (result) => {
      if (result.widgetId) {
        await this.coreService.showSuccessMessage(this.translate.instant('Common.Success'));

        await this.dialogRef.close({ type: this.data.widget ? this.saveAsActive ? 'saveas' : 'update' : 'insert', widget: result })
        this.dashboardService.getDashboard(this.dashboardId).pipe(
          takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
        ).subscribe((result: Dashboard) => {
          this.dashboardService.selectedDasboard.next(result)
          if (this.dashboardService.cachedData.getValue().activeDashboard.some(x => x.dashboardId == result.dashboardId)) {
            this.dashboardService.cachedData.getValue().activeDashboard.find(x => x.dashboardId == result.dashboardId).dimensions = result.dimensions;
            this.dashboardService.cachedData.getValue().authorizedDashboard.find(x => x.dashboardId == result.dashboardId).dimensions = result.dimensions;
            this.dashboardService.cachedData.getValue().activeDashboard.find(x => x.dashboardId == result.dashboardId).colors = result.colors;
            this.dashboardService.cachedData.getValue().authorizedDashboard.find(x => x.dashboardId == result.dashboardId).colors = result.colors;
          }
        });
      } else {
        this.coreService.showErrorMessage(this.translate.instant('Common.Error'));
      }
      this.saveAsActive = false
      this.spinner.hide()
    });
  }

  changevisualComponent(element: customDropdownProps) {
    if (this.widget.visualComponentId !== element.id) {
      this.widget.params = {}
      this.widget.config = []
    }
    var selectedvisualcomp = this.visualComponents.find(x => x.visualComponentId == element.id)
    this.settingForVisualComponentConfig(selectedvisualcomp)
    this.widget.code = selectedvisualcomp.code
    this.widget.visualComponentId = element.id.toString();
    this.controlForPreview(this.widget);
  }

  settingForVisualComponentConfig(selectedvisualcomp: VisualComp) {

    this.configDropdownSettings.input = selectedvisualcomp.config.map(item => {
      return {
        getValueFunction: (e) => this.createWidgetConfig(e, item),
        inputType: item.values.length > 1 ? 'select' : item.type.toLowerCase() == 'boolean' ? 'toggle' : item.type,
        options: item.values.length > 1 ? item.values.map(opt => { return { key: opt, value: opt } }) : [],
        title: item.visibleName,
        id: item.name,
        visible: true,
        tooltip: item.description,
        defaultValue:
          this.data?.widget?.config.some(x => x.name == item.name) ?
            this.data?.widget?.config.find(x => x.name == item.name).value :
            item.type.toLowerCase() == 'boolean' && typeof (item.value) == 'string' ? item.value == 'true' ? true : false : item.value,

      }
    })


    this.visualSettingTabs.find(x => x.name == 'config').dropdown.setting = this.configDropdownSettings
    this.updateAllDropdownSettings();

  }

  createWidgetConfig(e, item: WidgetConfig) {

    if (this.widget.config.some(x => x.name == item.name))
      this.widget.config = this.widget.config.filter(x => x.name !== item.name)

    if (e.source) {
      item.value = e.checked
    } else {
      item.value = e
    }

    this.widget.config.push(item)

    this.configDropdownSettings.input.find(x => x.id == item.name).defaultValue = this.widget?.config.find(x => x.name == item.name).value

    this.widget.params = { ...this.widget.params, [item.name]: item.value }

    this.controlForPreview(this.widget, true)
  }

  upsertDashboard() {

  }

  addFilter(filter: any) {
    this.widget.filter.push(filter);
    this.widget.filter = [...this.widget.filter];
    this.queryFilter = {
      fieldName: '',
      fieldValue: '',
      operator: ''
    }
  }

  deleteQuery(query: any) {
    let index = this.widget.filter.findIndex(x => {
      return x.fieldName === query.fieldName && x.operator === query.operator && x.fieldValue === query.fieldValue;
    });
    if (index !== -1) {
      this.widget.filter.splice(index, 1);
    }
    this.applyFilter(false)
  }

  searchValuesForQueryFilter(searchText: string) {
    if (searchText.length < 2) { return };
    this.dataStoreService.getValuesForField(this.widget.dataStoreId, this.queryFilter.fieldName, searchText).pipe(
      takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
    ).subscribe(result => {
      this.searchValues = result;
    })
  }

  debugCodeResult: any = ''
  debugDisplay: boolean = false

  async compileCode() {
    this.debugDisplay = true
  }

  filterFieldSelected(fieldName: string) {
    this.queryFilter.fieldValue = '';
    this.queryFilter.operator = '';
    let column = this.dataStoreColumns.find(c => c.fieldName == fieldName);
    this.queryFilterFieldType = column.fieldType;
  }

  dateSelected(date: MatDatepickerInputEvent<Date>) {
    this.queryFilter.fieldValue = date.value.toISOString();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.isExistDateInDimension();
  }

  private isExistDateInDimension() {
    if (this.widget.dimensions.length == 0) { return };
    if (this.widget.dimensions[0].fieldType != "System.DateTime") {
      this.widget.dateGroup = 0;
      if (this.dataStoreColumnsForDropdownMenu.some(x => x.selectedMenuItem2))
        this.dataStoreColumnsForDropdownMenu.find(x => x.selectedMenuItem2).selectedMenuItem2 = ''
    }
  }

  dimensionSelected(element: { selectedElement: { element: Columns }, selectedList: Columns[] }) {
    // if (!element.selectedList.some(x => x.fieldType.toLowerCase().includes('date'))) {
    //   this.widget.dateGroup = 0
    // }


    this.widget.measures.map(item => {
      if (element.selectedList.some(x => x.fieldName == item.name)) {
        this.widget.measures = this.widget.measures.filter(x => x.name !== item.name)
        this.dataStoreColumnsForDropdownMenu.find(x => x.title == item.name).selectedMenuItem = ''

      }
    })
    // if(this.widget.measures.some(x=>x.function==))

    this.widget.dimensions = element.selectedList
    this.sortAndLimitDropdownSettings.input.find(x => x.inputId == 'sortField').options = this.widget.dimensions.map(dim => {
      return { key: dim.fieldName, value: dim.fieldName }
    })

    this.isExistDateInDimension()
    this.controlForPreview(this.widget)
    this.changeCalculatedOptions()
  }

  changeDimensionSort(list: any[]) {
    //dimension sırlama
    this.widget.dimensions = list.filter(x => x.selected).map(item => {
      return item.element
    })
    var newlist = list.filter(x => !x.selected)

    this.widget.measures = newlist.filter(x => x.selectedMenuItem !== '').map(item => {
      return {
        label: item.id.toString(),
        name: item.id.toString(),
        function: item.selectedMenuItem == 'Count Distinct' ? 'cdisct' : item.selectedMenuItem,
        formula: "",
        isCalculatedField: 0
      }
    })
    // this.widget.dimensions = list
    this.controlForPreview(this.widget)
  }

  areObjectsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }

  isEqualWithOrder(arr1, arr2) {
    return _.isEqualWith(arr1, arr2, (val1, val2) => {
      if (_.isArray(val1) && _.isArray(val2)) {
        return _.isEqual(val1, val2); // Derin karşılaştırma
      }
      return undefined; // Sıralama dikkate alınacak
    });
  };

  createWidgetDataColumns(widgetPreview: WidgetPreview) {
    this.selectedDataPreviewColumnsForAggregation = []

    if (widgetPreview && widgetPreview.widgetData.length > 0) {
      Object.keys(widgetPreview.widgetData[0]).map(item => {
        if (widgetPreview.widget.calculatedFields.some(x => x.name == item)) {
          this.selectedDataPreviewColumnsForAggregation.push({ fieldFilter: 0, fieldName: item, fieldType: 'fx' })
        } else {
          // var otherColumn = this.selectedDataPreviewColumns.find(x => x.fieldName == item)
          var otherColumn = this.dataStoreColumns.find(x => x.fieldName == item)
          this.selectedDataPreviewColumnsForAggregation.push(otherColumn)

        }
      })
    }
  }


  showPreview(fastpreview?: boolean) {
    // this.filterScreen = false

    fastpreview = fastpreview ? fastpreview : false

    if (!this.disablePreview) {

      //rerender for chart
      if (fastpreview) {
        this.chartRerenderLoading = true
        this.dashboardService.widgetPreviewFast(this.widget, this.widgetPreviewData.widgetData).pipe(
          takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
        ).subscribe(res => {

          this.widgetPreviewData = res
          this.createWidgetDataColumns(this.widgetPreviewData)
          this.oldWidget = res
          // if (this.widget.widgetId)
          //   this.widget = this.widgetPreviewData.widget


          // this.widget = Object.assign(this.widget, this.widgetPreviewData.widget)

          setTimeout(() => {
            this.chartRerenderLoading = false
          }, 1000);
        })
        return;
      }


      this.loadingPreview = true
      // this.spinner.show();
      this.chartRerenderLoading = true

      this.dashboardService.widgetPreview(this.widget).pipe(
        takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
      ).subscribe(result => {
        this.widgetPreviewData = result;
        this.createWidgetDataColumns(this.widgetPreviewData)




        this.widget.config = this.widgetPreviewData.widget.config
        this.widget.params = this.widgetPreviewData.widget.params

        if (this.widget.widgetQuery) {
          this.queryColumnsForDropdownMenu = this.createDropdownDataForColumns(this.widgetPreviewData.widget.dimensions, this.widgetPreviewData.widget.dimensions, this.widgetPreviewData.widget.measures)
          var measureToColumn: Columns[] = this.widgetPreviewData.widget.measures.map(item => {
            return { fieldFilter: 0, fieldName: item.name, fieldType: 'fx' }
          })
          var dropdownMeasure = this.createDropdownDataForColumns(measureToColumn, measureToColumn, this.widgetPreviewData.widget.measures)
          this.queryColumnsForDropdownMenu = this.queryColumnsForDropdownMenu.concat(dropdownMeasure)
          this.dataPreviewColumnsForQuery = measureToColumn.concat(this.widgetPreviewData.widget.dimensions)

          this.dataStoreColumnsSettingTabs.find(x => x.name == 'measure').dropdown.data = this.queryColumnsForDropdownMenu
          this.allDropdownSettings.find(x => x.id == SettingAreas.agregation).data = this.queryColumnsForDropdownMenu
          this.allDropdownSettings.find(x => x.id == SettingAreas.agregation).tabs = [];



        }

        setTimeout(() => {
          this.loadingPreview = false
          this.chartRerenderLoading = false
          // this.spinner.hide();

        }, 1000);
        //this.openWidgetPreview(result);
      });
    }
  }

  createDropdownDataForColumns(columns: Columns[], selectedDimensions: Columns[], selectedmeasure: WidgetMeasure[]) {
    var list = columns.map(item => {
      var currentDimension = selectedDimensions.find(x => x.fieldName == item.fieldName)
      return {
        id: item.fieldName,
        title: item.fieldName,
        element: currentDimension ? currentDimension : item,
        menu: item.fieldType.toLowerCase().includes('number') ? ['Count', 'Count Distinct', 'Sum', 'Avg', 'Max', 'Min'] : ['count', 'count distinct'],
        menu2: item.fieldType.toLowerCase().includes('date') ? this.dateGroupByTextList : [],
        icon: item.fieldType.toLowerCase().includes('string') ? 'Text' : item.fieldType.toLowerCase().includes('number') ? 'Numbers' : item.fieldType.toLowerCase().includes('fx') ? 'fx' : 'Calendar',
        toggle: currentDimension?.fieldFilter == 0 ? false : true,
        selectedMenuItem2: this.dateGroupByTextList[this.widget.dateGroup],
        selectedMenuItem: selectedmeasure.find(x => x.name == item.fieldName) ? selectedmeasure.find(x => x.name == item.fieldName).function : '',
        selected: selectedDimensions.find(x => x.fieldName == item.fieldName) ? true : false

      }
    })

    return list;
  }

  openCustomizeCode(value) {
    this.codeString = value
    if (this.editorOptions.language == 'javascript') {
      if (this.widget.customizeCode == 0) {
        this.widget.customizeCode = 1
      }
      this.widget.code = this.codeString
    } else {
      this.widget.widgetQuery = this.codeString
    }
  }

  openWidgetCodeScreen() {
    this.codeScreen = true
    this.sqlcodeScreen = false
    this.filterScreen = false
    this.codeString = this.widget.code
    this.editorOptions = { theme: 'vs-dark', language: 'javascript' };
  }





  openBiggerEditor() {
    const dialogRef = this.dialog.open(CodeEditorComponent, {
      height: '100%',
      width: '100%',
      data: {
        code: this.widget.code,
        widget: this.widget,
        debug: () => this.compileCode()
      },
    });


    dialogRef.afterClosed().pipe(
      takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
    ).subscribe(result => {
      if (result) {
        this.widget.code = result;
      }
    });
  }

  addColorRule(selectedColor: { colorelement: { color: string, key: string, selected: boolean }[], selectedPaletColor: { paletId: string, color: string }[], colorType: number }) {


    // if (this.colorRule.text == '' || this.colorRule.colorCode == '') {
    //   this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.SelectColor"));
    //   return;
    // }
    if (this.widget.colors.type !== selectedColor.colorType) {
      this.widget.colors.type = selectedColor.colorType
      // this.showPreview()
    }
    if (selectedColor?.selectedPaletColor?.length > 0) {
      this.widget.colors.colors = selectedColor.selectedPaletColor.map(item => {
        return {
          color: item.color,
          text: item.paletId,
          palette: item.paletId,

        }
      })
      // this.showPreview()
    } else if (selectedColor?.colorelement) {
      this.widget.colors.colors = selectedColor.colorelement.map(item => {
        return {
          color: item.color,
          text: item.key,
          palette: item.key
        }
      })
      // this.showPreview()
    }

    this.showPreview(true)

    // var colorRule = {
    //   colorCode: selectedColor.colorelement.key,
    //   operator: "",
    //   text: "",
    //   value: selectedColor.colorelement.color
    // }


    // this.widget.colors.colorRuleItems.push(colorRule);



    // this.colorRule = {
    //   colorCode: "",
    //   operator: "",
    //   text: "",
    //   value: ""
    // }
  }

  // removeColorRule(rule: ColorRuleItem) {
  //   let index = this.widget.colors.colors.findIndex(x => {
  //     return x.text === rule.text && x.colorCode === rule.colorCode;
  //   });
  //   if (index !== -1) {
  //     this.widget.colors.colorRuleItems.splice(index, 1);
  //   }
  // }

  // colorRuleSelected() {
  //   if (this.widget.colors.type == 0) {
  //     this.widget.colors.colorCode = "";
  //     this.widget.colors.colorRuleItems = [];
  //   }
  // }

  showCopyIDText() {
    this.coreService.showSuccessMessage("ID kopyalandı!");
  }

  colorDimensionSelected() {
    let dimensionData = this.widget.dimensionsData.find(d => d.name == this.selectedColorDimension);
    if (dimensionData) {
      this.colorDimensionData = dimensionData.value;
    }
  }



  changeDimensionFilterStatus(selected: customDropdownProps) {
    var dimension = selected.element
    this.widget.dimensions.find(x => x.fieldName == selected.id).fieldFilter = selected.toggle ? 1 : 0
    // dimension.fieldFilter = dimension.fieldFilter === 1 ? 0 : 1;
    // this.widget.dimensions = [...this.widget.dimensions];
    // this.filterParam = dimension.fieldFilter;
  }

  closeDataSettingsTab() {
    this.dataSettingsTab = !this.dataSettingsTab
  }

  getSampleData(datastoreid: string) {
    return this.dataStoreService.getSampleData(datastoreid).pipe(
      takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
    ).subscribe(sampleres => {
      this.dataStoreService.getColomnsForDataStore(datastoreid).pipe(
        takeWhile(() => this.alive) // Aboneliği sonlandırmak için bayrağı kontrol et
      ).subscribe(res => {
        this.selectedDataPreviewColumns = res
        this.sampleDataForDataStore = sampleres

      })
    })
  }

  showPreviewData(selectedDataStore: customDropdownProps) {
    this.getSampleData(selectedDataStore.id.toString())
    this.selectedPreviewScreen = 'data'
  }


  controlForPreview(widgetObject: Widget, fastpreview?: boolean, updateDropdown: boolean = true) {
    fastpreview = fastpreview ? fastpreview : false
    this.controlProgressValue(updateDropdown)

    if (this.allDropdownSettings.some(x => x.setting.errorValues.active == true))
      this.controlAndOpenTitleBox(false, false)

    if (widgetObject.dataStoreId && widgetObject.visualComponentId) {
      this.showPreview(fastpreview)
      this.selectedPreviewScreen = 'widget'

    } else {
      this.selectedPreviewScreen = 'data'
      this.widgetPreviewData = null
    }

  }

  expandAllSettings() {
    this.settingAreaIds.map(item => {
      if (document.getElementById(item))
        document.getElementById(item).style.display = 'none'
    })
  }

  clearAllSelection() {
    this.widget = {
      visualComponentId: "",
      title: "",
      subTitle: "",
      footNote: "",
      access: 1,
      dateGroup: 0,
      customizeCode: 0,
      type: "",
      dataStoreId: "",
      owner: null,
      includeZeroValues: 1,
      domain: "",
      limitData: "500",
      code: "",
      sharedData: null,
      sort: {
        sortField: "",
        sortOrder: "",
        sortType: ""
      },
      colors: {
        type: 0,
        colors: []
      },
      dimensions: [],
      measures: [],
      filter: [],
      widgetParameters: [],
      dimensionsData: [],
      layout: {
        cols: 1,
        rows: 1,
        x: 0,
        y: 0,
        designerContainer: null
      },
      mobileLayout: {
        cols: 0,
        rows: 1,
        x: 0,
        y: 0,
        designerContainer: null
      },
      freezeFilter: 0,
      drillDownDashboard: '',
      drillDownLink: '',
      drillDown: {
        dashboard: '',
        dashboardFilter: 0,
        height: 0,
        width: 0,
        link: '',
        openerMethod: '',
        widgetFilter: 0,
        shareKey: ''

      },
      config: [],
      params: {},
      calculatedFields: [],
      isFilter: 0,
      offSet: -1,
      disableInMemoryCache: 0,
      ignoreFilterList: [],
      widgetJoin: [],
      widgetQuery: '',
    };
    this.controlProgressValue()
    if (this.dataStoresForDropdownMenu.find(x => x.selected == true))
      this.dataStoresForDropdownMenu.find(x => x.selected == true).selected = false

    if (this.dataStoreColumnsForDropdownMenu.find(x => x.selected == true))
      this.dataStoreColumnsForDropdownMenu.find(x => x.selected == true).selected = false

    this.dataStoreColumnsForDropdownMenu.filter(x => x.selectedMenuItem !== '').map(item => {
      item.selectedMenuItem = ''
    })
    if (this.visualComponentsForDropdownMenu.find(x => x.selected == true))
      this.visualComponentsForDropdownMenu.find(x => x.selected == true).selected = false


  }

  sortAndLimitSelection(event: customDropdownProps[]) {
    event.map(item => {
      if (item.selectedMenuItem) {
        if (item.id == "sortType" || item.id == "sortOrder") {
          this.widget.sort[item.id] = item.menu.findIndex(x => x == item.selectedMenuItem).toString()
        } else
          this.widget.sort[item.id] = item.selectedMenuItem
      } else {
        this.widget.sort[item.id] = ''
      }
    })
    this.controlForPreview(this.widget)
  }



  getPaletColors(paletcolors) {

    this.scriptLoader._paletColors.next(paletcolors)
  }

  checkDirectQuery(value) {
    this.useDirectQuery = value

    if (!value) {
      this.sqlcodeScreen = false;
      this.codeScreen = false;
      this.dataStoreColumnsSettingTabs.find(x => x.name == 'measure').dropdown.data = this.dataStoreColumnsForDropdownMenu
      this.updateAllDropdownSettings()
    } else {
      this.openSqlCodeEditor()
      this.dataStoreColumnsSettingTabs.find(x => x.name == 'measure').dropdown.data = []
      this.allDropdownSettings.find(x => x.id == SettingAreas.agregation).tabs = [];
      this.allDropdownSettings.find(x => x.id == SettingAreas.agregation).data = [];
      this.allDropdownSettings.find(x => x.id == SettingAreas.agregation).data = this.queryColumnsForDropdownMenu

    }



  }

  addIgnoreKey() {
    var ignoreValue = this.ignoreForm.get('ignoreKey').value
    if (ignoreValue.length > 0 && !this.widget.ignoreFilterList.some(x => x == ignoreValue)) {
      this.widget.ignoreFilterList.push(ignoreValue)
      this.ignoreForm.reset()
    } else {
      this.coreService.showFormFillError()
    }
  }

  deleteIgnoreKey(key) {
    this.widget.ignoreFilterList = this.widget.ignoreFilterList.filter(x => x !== key)

  }

  addJoinList() {

    if (!this.widget.widgetJoin.some(x => x.source == this.joinForm.value.source && x.destination == this.joinForm.value.destination)) {
      this.widget.widgetJoin.push(this.joinForm.value)
      this.joinForm.reset()
    } else {
      this.coreService.showErrorMessage(this.translate.instant("FilterComponent.PleaseSelectDifValue"))
    }
  }

  deleteJoinKey(joinItem: { source: string, destination: string }) {
    this.widget.widgetJoin = this.widget.widgetJoin.filter(x => !(x.destination == joinItem.destination && x.source == joinItem.source))

  }

  openSqlCodeEditor() {
    this.sqlcodeScreen = true
    this.codeScreen = false
    this.filterScreen = false
    this.codeString = this.widget.widgetQuery
    this.editorOptions = { theme: 'vs-dark', language: 'sql' };

  }



  controlProgressValue(updateDropdown: boolean = true) {
    var selectioncount = 0
    if (this.widget.dataStoreId) {
      this.widget.dataStoreId !== '' ? selectioncount++ : ''

      this.dataStoresDropdownSettings.progressValue = (100 * selectioncount) / 1
    } else {

      this.dataStoresDropdownSettings.progressValue = 0
    }

    if (this.widget.measures.length || this.widget.dimensions.length) {
      this.widget.measures.length > 0 ? selectioncount++ : ''
      this.widget.dimensions.length > 0 ? selectioncount++ : ''

      this.dataStoreColumnsDropdownSettings.progressValue = (100 * selectioncount) / 2

    } else {

      this.dataStoreColumnsDropdownSettings.progressValue = 0


    }

    if (this.widget.visualComponentId) {
      this.widget.visualComponentId !== '' ? selectioncount++ : ''

      this.visualComponentsDropdownSettings.progressValue = (100 * selectioncount) / 1

    } else {

      this.visualComponentsDropdownSettings.progressValue = 0

    }

    if (this.widget.drillDown.shareKey || this.widget.drillDownDashboard) {
      this.widget.drillDown.shareKey !== '' ? selectioncount++ : ''
      this.widget.drillDownDashboard !== '' ? selectioncount++ : ''
      this.drilDownDropdownSettings.progressValue = (100 * selectioncount) / 2
    }
    else {
      this.drilDownDropdownSettings.progressValue = 0

    }
    if (updateDropdown)
      this.updateAllDropdownSettings();

  }

  runDirectQuery() {
    this.openSqlCodeEditor()
    if (!this.widget.visualComponentId) {
      this.widget.visualComponentId = this.visualComponentsForDropdownMenu[0].id.toString()
    }
    if (this.widget.widgetQuery.length > 0) {
      this.controlForPreview(this.widget, false, false)

    } else {
      this.coreService.showFormFillError()
    }
  }


  updateAllDropdownSettings() {

    this.allDropdownSettings.find(x => x.id == SettingAreas.data).setting = this.dataStoresDropdownSettings;
    this.allDropdownSettings.find(x => x.id == SettingAreas.data).data = this.dataStoresForDropdownMenu;

    this.allDropdownSettings.find(x => x.id == SettingAreas.agregation).setting = this.dataStoreColumnsDropdownSettings;
    this.allDropdownSettings.find(x => x.id == SettingAreas.agregation).data = this.dataStoreColumnsForDropdownMenu;
    this.allDropdownSettings.find(x => x.id == SettingAreas.agregation).tabs = this.dataStoreColumnsSettingTabs;


    this.allDropdownSettings.find(x => x.id == SettingAreas.event).setting = this.drilDownDropdownSettings;

    this.allDropdownSettings.find(x => x.id == SettingAreas.visual).setting = this.visualComponentsDropdownSettings;
    this.allDropdownSettings.find(x => x.id == SettingAreas.visual).data = this.visualComponentsForDropdownMenu;
    this.allDropdownSettings.find(x => x.id == SettingAreas.visual).tabs = this.visualSettingTabs;


    if (this.widget.widgetQuery && this.useDirectQuery) {
      this.allDropdownSettings.find(x => x.id == SettingAreas.agregation).tabs = [];
      this.allDropdownSettings.find(x => x.id == SettingAreas.agregation).data = this.queryColumnsForDropdownMenu
    }



  }


  controlAndOpenTitleBox(saveas: boolean, opentitleBox: boolean = true) {
    this.saveAsActive = saveas
    if (this.widget.dataStoreId == '') {
      this.dataStoresDropdownSettings.errorValues = { text: 'Lütfen Bir DataStore seçiniz', active: true }

    } else {
      this.dataStoresDropdownSettings.errorValues = { text: 'Lütfen Bir DataStore seçiniz', active: false }


    }
    if (this.widget.measures.length <= 0) {
      this.dataStoreColumnsDropdownSettings.errorValues = { text: 'Lütfen Bir Ölçü seçiniz', active: true }

    } else {
      this.dataStoreColumnsDropdownSettings.errorValues = { text: 'Lütfen Bir Ölçü seçiniz', active: false }


    }
    if (this.widget.dimensions.length <= 0) {
      this.dataStoreColumnsDropdownSettings.errorValues = { text: 'Lütfen Bir dimension seçiniz', active: true }
    } else {
      this.dataStoreColumnsDropdownSettings.errorValues = { text: 'Lütfen Bir dimension seçiniz', active: false }
    }
    if (this.widget.visualComponentId == '') {
      this.visualComponentsDropdownSettings.errorValues = { text: 'Lütfen Bir dimension seçiniz', active: true }
    } else {
      this.visualComponentsDropdownSettings.errorValues = { text: 'Lütfen Bir dimension seçiniz', active: false }

    }


    if (opentitleBox) {
      if (this.widget.dataStoreId == '' || this.widget.visualComponentId == '') {
        this.sweetalertService.open(this.translate.instant("ErrorMessages.MissinfFields"), this.translate.instant("ErrorMessages.FillFields"), 'error', this.translate.instant("Common.Done"), (res) => { console.log(res) })
        return
      } else {
        this.filterScreen = false
        this.titleBoxVisible = true
      }
    }

  }

  applyFilter(closescreen: boolean) {
    this.showPreview()
    if (closescreen)
      this.filterScreen = false
  }

  toggleChange() {
    this.disablePreview = !this.disablePreview;
    if (this.disablePreview) {
      this.selectedPreviewScreen = 'widget'
      this.widgetPreviewData = null
    }
    else
      this.controlForPreview(this.widget)
  }

  @ViewChild('downloadLink') downloadLink: ElementRef;
  downloadLoading: boolean = false

  downloadWidget(type) {
    if (type == 'image') {
      this.downloadLoading = true
      var screen = document.getElementsByClassName('widget-preview')[0] as HTMLElement
      html2canvas(screen).then(canvas => {
        this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        this.downloadLink.nativeElement.download = 'widget' + '.png';
        this.downloadLink.nativeElement.click();
        this.downloadLoading = false

      });
    } else {
      this.downloadLoading = true
      var sJson = JSON.stringify(this.widgetPreviewData.widgetData);
      this.downloadLink.nativeElement.href = "data:text/json;charset=UTF-8," + encodeURIComponent(sJson)
      this.downloadLink.nativeElement.download = 'widget' + '.json';
      this.downloadLink.nativeElement.click();
      this.downloadLoading = false

    }

  }

  closeDialog() {
    this.dialogRef.close()
  }

  changeMeasureLabel(element: { item: any, inputValue: string }) {

    this.widget.measures.find(x => x.name == element.item.name).label = element.inputValue
    this.infoBadgeList = this.widget.measures.map(item => { return { name: item.label, detail: item, subTextKey: 'function', disableEdit: item.isCalculatedField == 1 ? true : false } })

  }


  closeFilterScreen(type?) {
    if (type && 'close') {
      this.filterScreen = false
    } else
      this.filterScreen = !this.filterScreen;

    this.titleBoxVisible = false
    if (!this.filterScreen && this.widget.filter.length > 0) {
      this.controlForPreview(this.widget)
    }
  }

  resetCode() {
    var selectedvisualcomp = this.visualComponents.find(x => x.visualComponentId == this.widget.visualComponentId)
    this.widget.code = selectedvisualcomp.code
    this.widget.customizeCode = 0
  }

}
