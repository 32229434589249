import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-code-editor',
    templateUrl: './code-editor.component.html',
    styleUrls: ['./code-editor.component.scss']
})
export class CodeEditorComponent implements OnInit {

    code: string = '';
    title: string = "Editor"
    editorOptions = { theme: 'vs-dark', language: 'javascript' };
    debugDisplay: boolean = false
    message: [
        [
            {
                "name": "Function",
                "value": "function callfilter(key:string,val:string):void"
            }
        ],
        [
            {
                "name": "Function",
                "value": "function ChartLangTurkish(chart:object):void"
            }
        ],
        [
            {
                "name": "Function",
                "value": "function IsResponsiveActive(widget:object):bool"
            }
        ],
        [
            {
                "name": "Function",
                "value": "function AsAmChartData(widget, data):object"
            }
        ],
        [
            {
                "name": "Function",
                "value": "function BigDataPrefixes():void"
            }
        ],
        [
            {
                "name": "Function",
                "value": "function ColorLuminance(hex,lum):string"
            }
        ],
        [
            {
                "name": "Function",
                "value": "function CreateSeries(chart:object, widget:object, serietype:string, index:number,color:string):object"
            }
        ]
    ];
    constructor(
        public dialogRef: MatDialogRef<CodeEditorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        //   debugger;
        monaco.languages.typescript.typescriptDefaults.addExtraLib("this.message")
        if (this.data) {
            this.code = this.data.code;
            if (this.data.editorOptions) {
                this.editorOptions = this.data.editorOptions
            }
        }
    }

    action(type: boolean): void {
        this.dialogRef.close(this.code);
    }
    compile() {
        this.data.widget.code = this.code
        this.debugDisplay = !this.debugDisplay
    }


}
