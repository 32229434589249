import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { FlowScriptRequest, FlowSession } from 'src/app/models/responses/flowScript';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlowScriptService {

  constructor(private authService: AuthService, private http: HttpClient) { }
  public pythonScriptTemplate: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public executionLogs: BehaviorSubject<FlowSession[]> = new BehaviorSubject<FlowSession[]>([]);
  public allExecutionLogs: BehaviorSubject<FlowSession[]> = new BehaviorSubject<FlowSession[]>([]);





  upsertScript(script: FlowScriptRequest) {
    let param =
    {
      Token: this.authService.getToken(),
      PythonScript: script
    };
    return this.http.post(Endpoints.UpsertFlowScript, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getFlowScripts() {
    let param =
    {
      Token: this.authService.getToken()
    };
    return this.http.post(Endpoints.GetFlowScripts, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getFlowById(flowId: string) {
    let param =
    {
      Token: this.authService.getToken(),
      FlowId: flowId

    };
    return this.http.post(Endpoints.GetFlowById, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  deleteFlow(flowId: string) {
    let param =
    {
      Token: this.authService.getToken(),
      FlowId: flowId

    };
    return this.http.post(Endpoints.DeleteFlow, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  upsertFlow(flow) {
    let param =
    {
      Token: this.authService.getToken(),
      ScriptFlow: flow
    };
    return this.http.post(Endpoints.UpsertFlow, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getFlows() {
    let param =
    {
      Token: this.authService.getToken(),
    };
    return this.http.post(Endpoints.GetFlows, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  deleteFlowScript(scriptId: string) {
    let param =
    {
      Token: this.authService.getToken(),
      ScriptId: scriptId
    };
    return this.http.post(Endpoints.DeletePythonScript, param).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  runFlow(flowId: string, SessionId: string) {
    let param =
    {
      Token: this.authService.getToken(),
      FlowId: flowId,
      SessionId: SessionId

    };
    return this.http.post(Endpoints.RunFlowById, param).pipe(
      map((response: any) => {
        return response;
      })
    );
  }



  GetPythonPackages() {
    let param =
    {
      Token: this.authService.getToken()

    };
    return this.http.post(Endpoints.GetPythonPackages, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getExecutionLogForFlow(flowId: string, idType: string) {
    let param =
    {
      Token: this.authService.getToken(),
      [idType == 'flow' ? 'FlowId' : 'SessionId']: flowId,
    };
    return this.http.post(Endpoints.GetSessions, param).pipe(
      map((response: any) => {
        this.executionLogs.next(response.message)
        response.message.map(item => {
          var list = this.allExecutionLogs.getValue()
          if (this.allExecutionLogs.getValue().some(x => x.sessionId == item.sessionId)) {

            list = list.filter(x => x.sessionId !== item.sessionId)
          }

          list.push(item)
          this.allExecutionLogs.next(list)

        })
        console.log(this.allExecutionLogs.getValue())

        return response.message;
      })
    );
  }

  deleteSession(sessionId: string) {
    let param =
    {
      Token: this.authService.getToken(),
      SessionId: sessionId,
    };
    return this.http.post(Endpoints.DeleteSessionById, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  GetPythonScriptTemplate() {
    let param =
    {
      Token: this.authService.getToken()
    };
    return this.http.post(Endpoints.GetPythonScriptTemplate, param).pipe(
      map((response: any) => {
        if (response.result) {
          this.pythonScriptTemplate.next(response.message)
        }
        return response.message;
      })
    );
  }
}
