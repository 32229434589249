import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SelfService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
  ) { }

  addDataStore(formdata: FormData) {
    // let param = {
    //   File:File,
    //   NodeId: NodeId,
    //   Token: this.authService.getToken(),
    //   UserData: UserData,
    //   append:append,
    // }
    return this.http.post(Endpoints.addDatastoreFile, formdata).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }
  getDataList() {
    let param = {
      Token: this.authService.getToken()
    }
    return this.http.post(Endpoints.getDataList, param).pipe(
      map((res: any) => {
        return res.message
      })
    )
  }
}
