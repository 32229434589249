<app-modal-title title="Dashboard Selection"></app-modal-title>
<mat-dialog-content class="mat-typography">
    <app-title title="Your Dashboards"></app-title>
    <mat-selection-list [(ngModel)]="selectedDashboards" [compareWith]="compareFunctionForDashboard">
        <mat-list-option *ngFor="let dash of dashboards" [value]="dash.dashboardId">
            {{dash.title}}
        </mat-list-option>
    </mat-selection-list>
    <br>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial (click)="addSelection()">{{ 'Common.Update' | translate }}</button>
</mat-dialog-actions>