import { environment } from 'src/environments/environment';

export const ServerURLs = {
    bussion: environment.baseUrl + '/api/V3/',
    connector: window.location.protocol + '//' + window.location.hostname + ':**port**/api/V3/',
    connectorUrl: environment.baseUrl + ':7000/api/V3/'
}

export const Endpoints = {
    login: ServerURLs.bussion + 'Logon/Login',
    register: ServerURLs.bussion + 'Register/CreateAccount',
    getUsers: ServerURLs.bussion + 'Users/GetUsers',
    getUserId: ServerURLs.bussion + 'Users/GetUserById',
    upsertUser: ServerURLs.bussion + 'Users/UpsertUser',
    deleteUser: ServerURLs.bussion + 'Users/DeleteUser',
    uploadAvatar: ServerURLs.bussion + 'FileOps/UploadImage',
    getGroups: ServerURLs.bussion + 'Groups/GetGroups',
    getUserGroups: ServerURLs.bussion + 'Groups/GetGroupsOfUserByUserId',
    upsertGroup: ServerURLs.bussion + 'Groups/UpsertGroup',
    deleteGroup: ServerURLs.bussion + 'Groups/DeleteGroupById',
    getRoles: ServerURLs.bussion + 'Roles/GetRoles',
    getUserRoles: ServerURLs.bussion + 'Roles/GetRolesOfUserByUserId',
    upsertRole: ServerURLs.bussion + 'Roles/UpsertRole',
    deleteRole: ServerURLs.bussion + 'Roles/DeleteRoleById',
    getDashboards: ServerURLs.bussion + 'Dashboards/GetDashboards',
    getUserActiveDashboards: ServerURLs.bussion + 'Dashboards/GetUserActiveDashboards',
    getAuthorizedDashboards: ServerURLs.bussion + 'Dashboards/GetAuthorizedDashboards',
    getDashboardById: ServerURLs.bussion + 'Dashboards/GetDashboardById',
    upsertDashboard: ServerURLs.bussion + 'Dashboards/UpsertDashboard',
    deleteDashboard: ServerURLs.bussion + 'Dashboards/DeleteDashboardById',
    upsertWidgetToDashboard: ServerURLs.bussion + 'Dashboards/UpsertWidgetInDesigner',
    previewWidget: ServerURLs.bussion + 'Widgets/PreviewWidget',
    getWidgetsForDesigner: ServerURLs.bussion + 'Dashboards/GetWidgetsForDesigner',
    addDashboardToUser: ServerURLs.bussion + 'Dashboards/AddDashboardToUser',
    getWidgetsForDashboard: ServerURLs.bussion + 'Dashboards/GetWidgetsForDashboard',
    updateDashboardLayout: ServerURLs.bussion + 'Dashboards/UpdateDashboardLayout',
    deleteWidgetFromDashboard: ServerURLs.bussion + 'Dashboards/DeleteWidgetFromDashboard',
    checkDashboardUpdateTime: ServerURLs.bussion + 'Dashboards/CheckDashboardUpdateTime',
    getDataStore: ServerURLs.bussion + 'DataStore/GetDataStores',
    getDataStoreById: ServerURLs.bussion + 'DataStore/GetDataStoreById',
    getDataStoreForUser: ServerURLs.bussion + 'DataStore/GetDataSourceForUser',
    upsertDataStore: ServerURLs.bussion + 'DataStore/UpsertDataStore',
    deleteDataStore: ServerURLs.bussion + 'DataStore/DeleteDataStore',
    getUniverses: ServerURLs.bussion + 'DataStore/GetUniverses',
    getCollections: ServerURLs.bussion + 'DataStore/GetCollections',
    getColumns: ServerURLs.bussion + 'DataStore/GetColumnsForDataSource',
    getColumnsByNode: ServerURLs.bussion + 'DataStore/GetColumnsForDataSourceByNodeId',
    getColumnsForDataSourceById: ServerURLs.bussion + 'DataStore/GetColumnsForDataSourceById',
    getValuesForField: ServerURLs.bussion + 'DataStore/GetValuesForField',
    getValuesForFieldByUniverse: ServerURLs.bussion + 'DataStore/GetValuesForFieldByUniverse',
    getWidgetLibraries: ServerURLs.bussion + 'WidgetLibrary/GetWidgetLibraries',
    upsertWidgetLibrary: ServerURLs.bussion + 'WidgetLibrary/UpsertWidgetLibrary',
    deleteWidgetLibrary: ServerURLs.bussion + 'WidgetLibrary/DeleteWidgetLibrary',
    uploadJavascriptFile: ServerURLs.bussion + 'FileOps/UploadLibraryFiles',
    getFilesPathOfLibrary: ServerURLs.bussion + 'FileOps/GetFilesPathOfLibrary',
    deleteFilePath: ServerURLs.bussion + 'WidgetLibrary/DeleteFileFromLibrary',
    getVisualComponents: ServerURLs.bussion + 'VisualComponent/GetVisualComponents',
    upsertVisualComponent: ServerURLs.bussion + 'VisualComponent/UpsertVisualComponent',
    deleteVisualComponent: ServerURLs.bussion + 'VisualComponent/DeleteVisualComponent',
    getNodes: ServerURLs.bussion + 'Node/GetNodes',
    getNodesType: ServerURLs.bussion + 'Node/GetDataNodeTypes',
    getDataNodes: ServerURLs.bussion + 'Node/GetDataNodes',
    upsertNode: ServerURLs.bussion + 'Node/UpsertNode',
    deleteNode: ServerURLs.bussion + 'Node/DeleteNodeById',
    CheckNodeConnectivity: ServerURLs.bussion + 'Node/CheckNodeConnectivity',
    getAllColorPalettes: ServerURLs.bussion + 'ColorPalette/GetColorPalettes',
    upsertColorPalette: ServerURLs.bussion + 'ColorPalette/UpsertColorPalette',
    deleteColorPalette: ServerURLs.bussion + 'ColorPalette/DeleteColorPalette',
    getGlobalFunctions: ServerURLs.bussion + 'GlobalFunctions/GetGlobalFunctions',
    upsertGlobalFunction: ServerURLs.bussion + 'GlobalFunctions/UpsertGlobalFunction',
    deleteGlobalFunction: ServerURLs.bussion + 'GlobalFunctions/DeleteGlobalFunction',
    getGlobalFunctionDefinitions: ServerURLs.bussion + 'GlobalFunctions/GetFunctionDefinitions',
    initiliazeConnector: ServerURLs.bussion + 'Connector/InitiliazeConnection',
    getBussionConnector: ServerURLs.bussion + 'Connector/GetBussionConnectors',
    deleteBussionConnector: ServerURLs.bussion + 'Connector/DeleteBussionConnectors',
    getConnectorTasks: ServerURLs.bussion + 'Connector/GetConnectorTasks',
    executeQuery: ServerURLs.bussion + 'Connector/ExecuteQuery',
    upsertConnectorTask: ServerURLs.bussion + 'Connector/UpsertConnectorTask',
    deleteConnectorTask: ServerURLs.bussion + 'Connector/DeleteConnectorTask',
    runConnectorTask: ServerURLs.bussion + 'Connector/RunConnectorTask',
    stopConnectorTask: ServerURLs.bussion + 'Connector/StopConnectorTask',
    getTaskLogs: ServerURLs.bussion + 'Connector/GetTaskStatus',
    upsertShare: ServerURLs.bussion + 'ShareObject/UpsertShare',
    getShare: ServerURLs.bussion + 'ShareObject/GetShareByKey',
    getSharedDashboardWidgets: ServerURLs.bussion + 'ShareObject/GetWidgetsForDashboard',
    RequestDynamicalShareToken: ServerURLs.bussion + 'ShareObject/RequestDynamicalShareToken',
    RemoveDynamicToken: ServerURLs.bussion + 'ShareObject/RemoveSharedToken',
    getWidgetForShare: ServerURLs.bussion + 'ShareObject/GetWidgetForShare',
    getMyShares: ServerURLs.bussion + 'ShareObject/GetShareOfObjects',
    deleteMyShare: ServerURLs.bussion + 'ShareObject/DeleteShare',
    getMessages: ServerURLs.bussion + 'Message/GetMessages',
    upsertMessage: ServerURLs.bussion + 'Message/UpsertMessage',
    deleteMessage: ServerURLs.bussion + 'Message/DeleteMessageById',
    getUserPermissions: ServerURLs.bussion + 'Roles/GetUserPermissions',
    getPermissions: ServerURLs.bussion + 'Roles/GetPermissions',
    upsertLicense: ServerURLs.bussion + 'License/UpsertLicense',
    getLicense: ServerURLs.bussion + 'License/GetLicense',
    sendAuditLogs: ServerURLs.bussion + 'Applications/AddAuditLog',
    getMultiTenantStatus: ServerURLs.bussion + 'Applications/MultiTenantEnabled',
    getldapconfig: ServerURLs.bussion + 'Applications/GetLdapConfig',
    updateldapconfig: ServerURLs.bussion + 'Applications/UpdateLdapConfig',
    getPasswordPolicy: ServerURLs.bussion + 'Applications/GetPasswordPolicy',
    updatePasswordPolicy: ServerURLs.bussion + 'Applications/UpdatePasswordPolicy',
    logout: ServerURLs.bussion + 'Logon/Logout',
    getUserLibraries: ServerURLs.bussion + 'Dashboards/GetUserLibrariesPreLoad',
    getApps: ServerURLs.bussion + 'Applications/GetApplications',
    deleteApps: ServerURLs.bussion + 'Applications/DeleteApplicationById',
    upsertApp: ServerURLs.bussion + 'Applications/UpsertApplication',
    deployApp: ServerURLs.bussion + 'Applications/DeployBussionApplication',
    getAuthorizedApps: ServerURLs.bussion + 'Applications/GetAuthorizedApplications',
    chaptcha: ServerURLs.bussion + 'Applications/CaptchaEnabled',
    clearWidgetFolder: ServerURLs.bussion + 'WidgetLibrary/ClearFolder',
    getWidgetById: ServerURLs.bussion + 'Dashboards/GetWidgetData',
    getAllowedScript: ServerURLs.bussion + 'AI/GetScriptsAllowed',
    upsertAllowedScript: ServerURLs.bussion + 'AI/UpsertScriptsAllowed',
    setParam: ServerURLs.bussion + 'Applications/SaveParam',
    getParam: ServerURLs.bussion + 'Applications/GetParam',
    deleteParam: ServerURLs.bussion + 'Applications/DeleteParam',
    debugeState: ServerURLs.bussion + 'Applications/ChangeDebugState',
    getBussionVersion: ServerURLs.bussion + 'Applications/GetBussionVersion',
    maxIllegalTries: ServerURLs.bussion + 'Applications/SetMaxIllegalTriesForDataOps',
    getSystemParameters: ServerURLs.bussion + 'Applications/GetSystemParameters',
    getCaptcha: ServerURLs.bussion + 'Applications/CaptchaEnabled',
    setCaptcha: ServerURLs.bussion + 'Applications/EnableCaptcha',
    getLogs: ServerURLs.bussion + 'Applications/GetBussionLogs',
    backupBussionConfiguration: ServerURLs.bussion + 'Applications/BackupBussionConfiguration',
    restoreBussionConfiguration: ServerURLs.bussion + 'Applications/RestoreBussionConfiguration',
    downloadCurrentConfiguration: ServerURLs.bussion + 'Applications/DownloadBussionConfiguration',
    uploadConfiguration: ServerURLs.bussion + 'Applications/UploadBussionConfiguration',
    getBussionMain: ServerURLs.bussion + 'Applications/GetBussionMain',
    getLanguagePacts: ServerURLs.bussion + 'Applications/GetLanguagePacts',
    deleteLanguage: ServerURLs.bussion + 'Applications/DeleteLanguagePacts',
    setLanguagePacts: ServerURLs.bussion + 'Applications/SetLanguagePacts',
    setBussionMain: ServerURLs.bussion + 'Applications/SetBussionMain',
    getBussionLogin: ServerURLs.bussion + 'Applications/GetBussionLogin',
    setBussionLogin: ServerURLs.bussion + 'Applications/SetBussionLogin',
    getSuggesstion: ServerURLs.bussion + 'AI/GetSuggestion',
    getSuggestionForDashboard: ServerURLs.bussion + 'AI/GetSuggestionForDashboard',
    addDatastoreFile: ServerURLs.bussion + 'SelfService/AddDataAsFile',
    getDataList: ServerURLs.bussion + 'SelfService/GetDataList',
    startScript: ServerURLs.bussion + 'AI/StartScript',
    sceduleDashboard: ServerURLs.bussion + 'Dashboards/UpsertDashboardScheduler',
    getSceduleDashboard: ServerURLs.bussion + 'Dashboards/GetDashboardScheduler',
    DeleteDashboardScheduler: ServerURLs.bussion + 'Dashboards/DeleteDashboardScheduler',
    GetWidgetRepository: ServerURLs.bussion + 'Dashboards/GetWidgetRepository',
    NotifyDataStore: ServerURLs.bussion + 'Datastore/NotifyDataStore',
    GetSampleDataForDataStore: ServerURLs.bussion + 'DataStore/GetSampleDataForDataStore',
    CreateIndex: ServerURLs.bussion + 'DataStore/CreateIndex',
    GetIndex: ServerURLs.bussion + 'DataStore/GetIndex',
    DropIndex: ServerURLs.bussion + 'DataStore/DropIndex',
    GetScriptFlows: ServerURLs.bussion + 'DataAnalytics/GetScriptFlows',
    UpsertFlowScript: ServerURLs.bussion + 'DataAnalytics/UpsertPythonScript',
    GetFlowScripts: ServerURLs.bussion + 'DataAnalytics/GetPythonScripts',
    UpsertFlow: ServerURLs.bussion + 'DataAnalytics/UpsertScriptFlow',
    GetFlows: ServerURLs.bussion + 'DataAnalytics/GetScriptFlows',
    GetFlowById: ServerURLs.bussion + 'DataAnalytics/GetScriptFlowId',
    DeleteFlow: ServerURLs.bussion + 'DataAnalytics/DeleteFlowById',
    DeletePythonScript: ServerURLs.bussion + 'DataAnalytics/DeletePythonScriptById',
    RunFlowById: ServerURLs.bussion + 'DataAnalytics/RunFlowById',
    GetPythonPackages: ServerURLs.bussion + 'DataAnalytics/GetPythonPackages',
    GetSessions: ServerURLs.bussion + 'DataAnalytics/GetSessionsForFlowById',
    GetPythonScriptTemplate: ServerURLs.bussion + 'DataAnalytics/GetPythonScriptTemplate',
    DeleteSessionById: ServerURLs.bussion + 'DataAnalytics/DeleteSessionById',
    PreviewWidgetFast: ServerURLs.bussion + 'Widgets/PreviewWidget',
    GetLoginPagePicture: ServerURLs.bussion + 'Pictures/GetLoginPagePicture',
    GetExternalEngineTemplate: ServerURLs.bussion + 'BussionConnector/GetExternalEngineTemplate',
    UpsertExternalEngine: ServerURLs.bussion + 'BussionConnector/UpsertExternalEngine',
    DeleteExternalEngine: ServerURLs.bussion + 'BussionConnector/DeleteExternalEngine',
    GetExternalEngines: ServerURLs.bussion + 'BussionConnector/GetExternalEngines',
    GetExternalEngineCode: ServerURLs.bussion + 'BussionConnector/GetExternalEngineCode',
    ArchRefreshData: ServerURLs.bussion + 'Arctitecture/RefreshData',
    ArchGetBussionServers: ServerURLs.bussion + 'Arctitecture/GetBussionServers',
    ArchBussionDataNodes: ServerURLs.bussion + 'Arctitecture/BussionDataNodes',
    ArchConnectorTasks: ServerURLs.bussion + 'Arctitecture/ConnectorTasks',
    ArchDataStores: ServerURLs.bussion + 'Arctitecture/DataStores',
    ArchBussionConnectors: ServerURLs.bussion + 'Arctitecture/BussionConnectors',
    ArchDataFlows: ServerURLs.bussion + 'Arctitecture/DataFlows',
    GetCredidentials: ServerURLs.bussion + 'Connector/GetCredidentials',
    ListPictures: ServerURLs.bussion + 'Dashboard/ListPictures',
    AddPicture: ServerURLs.bussion + 'Dashboard/AddPicture',
    DeletePicture: ServerURLs.bussion + 'Dashboard/DeletePicture'









};