<div class="table-header">
    <div [style]="'flex-basis:'+item.width" *ngFor="let item of columns">
        <div *ngIf="(ismobile&&item?.mobile)||!ismobile">
            {{item.title | translate}}

        </div>
    </div>
</div>
<ul *ngIf="datas.length" class="responsive-table" cdkDropList (cdkDropListDropped)="drop($event)">

    <li class="table-row" *ngFor="let item of paginatedData" cdkDrag [cdkDragDisabled]="!dragConfig">
        <div [style]="'flex-basis:'+cl.width" *ngFor="let cl of columns"
            [class]="cl.selector=='avatar'?'data-list-avatar':''">
            <div style="display: flex;" *ngIf="(ismobile&&cl?.mobile)||!ismobile">
                <div *ngIf="cl.selector=='image'">
                    <div class="avatar">{{ item[columns[1].selector].slice(0, 2).toUpperCase() }}</div>
                </div>
                <div style="margin-top:-12px" *ngIf="cl.selector=='icon'">
                    <img class="dashboardImg " [src]="env+item['icon']" alt="">
                </div>
                <div  style="margin-top: -12px;" *ngIf="cl.selector=='actionicon'&&previewButton">
                    <button mat-icon-button (click)="sendDataToParent({type:'preview',data:item})">
                        <mat-icon>remove_red_eye</mat-icon>
                    </button>
                </div>
                <div style="margin-top: -12px;" *ngIf="cl.selector=='actionicon'">
                    <button mat-icon-button (click)="sendDataToParent({type:'share',data:item})">
                        <mat-icon>share</mat-icon>
                    </button>
                </div>
                
                <div style="margin-top: -12px;" *ngIf="cl.selector=='action'">

                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button (click)="sendDataToParent({type:'edit',data:item})" mat-menu-item>{{
                            'DataStorePage.Edit' |
                            translate }}
                            <mat-icon>edit</mat-icon></button>
                        <button (click)="sendDataToParent({type:'delete',data:item})" mat-menu-item>{{
                            'DataStorePage.Delete' | translate }}
                            <mat-icon>delete</mat-icon></button>
                    </mat-menu>
                </div>
                <div *ngIf="cl.selector=='avatar'">
                    <app-avatar-group [list]="item[cl.avatarselector]" [avatarKeys]="cl.avatarKeys"></app-avatar-group>
                </div>

                <div
                    *ngIf="cl.selector!=='action'&&cl.selector!=='icon'&&cl.selector!=='avatar'&&cl.selector!=='image'">
                    {{item[cl.selector]}}
                </div>
            </div>
        </div>

    </li>
</ul>
<mat-paginator *ngIf="datas.length&&datas.length>itemsPerPage" [length]="datas.length" [pageSize]="itemsPerPage" [pageSizeOptions]="[]"
    (page)="paginateDatas($event)"></mat-paginator>