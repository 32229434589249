<app-modal-title [title]="title"></app-modal-title>
<mat-dialog-content class="mat-typography">

    <app-title title="{{ 'ScriptsPage.About' | translate }}"></app-title>
    <form [formGroup]="pageForm" style="padding: 3rem;">
        <mat-form-field>
            <mat-label>{{ 'ScriptsPage.Name' | translate }}</mat-label>
            <input  matInput  formControlName="scriptName" required>
            <button  mat-button matSuffix (click)="addElement('newScripts')">Add</button>
        </mat-form-field>
        <br>
        <div  class="querylist" *ngFor="let item of newScripts;index as i">
            {{i+1}}-){{item.scriptName}}
            <mat-icon (click)="deleteElement(item,'newScripts')">highlight_off</mat-icon>
        </div>

    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="!isCreateForm" (click)="upsertScript()">{{
        'Common.Update' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial  *ngIf="isCreateForm" 
    [disabled]="newScripts.length === 0" (click)="upsertScript()">{{
        'Common.Create' | translate }}</button>
</mat-dialog-actions>