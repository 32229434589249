<app-modal-title title="{{ 'FilterComponent.Title' | translate }}"></app-modal-title>
<mat-dialog-content class="mat-typography">

    <div class="container">
        <div class="field" *ngFor="let item of dimensions">
            <app-title title="{{item.name}}" (btnEvent)="clearDimensionFilter(item)" buttonTitle="{{ 'FilterComponent.Clear' | translate }}"></app-title>
            <div class="section-list-content">
                <mat-selection-list>
                    <mat-list-option *ngFor="let val of item.value" [value]="item.name+'**'+val"
                        [selected]="filtersForList.includes(item.name+'**'+val)" (click)="selectFilterItem(item, val)">
                        {{val}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions>
    <div class="btn-left">
        <button mat-button class="b-btn" (click)="resetFilter()">{{ 'FilterComponent.Reset' | translate }}</button>
        <button mat-button class="b-btn b-danger" (click)="clearAllFilter()">{{ 'FilterComponent.ClearAll' | translate }}</button>
    </div>
    <div class="btn-right">
        <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
        <button mat-button class="b-btn b-success" (click)="saveFilters()">{{ 'Common.Save' | translate }}</button>
    </div>
</mat-dialog-actions>