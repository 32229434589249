import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { CustomHttpInterceptor } from './core/http.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CustomErrorHandler } from './core/error.handler';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { ColorPickerModule } from 'ngx-color-picker';
import { GridsterModule } from 'angular-gridster2';

import { LoginComponent } from './pages/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { ManageComponent } from './pages/manage/manage.component';
import { HeaderComponent } from './components/header/header.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { UserComponent } from './pages/manage/user/user.component';
import { GroupComponent } from './pages/manage/group/group.component';
import { RoleComponent } from './pages/manage/role/role.component';
import { CreateUserComponent } from './pages/manage/user/create-user/create-user.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RoleFormComponent } from './pages/manage/role/role-form/role-form.component';
import { GroupFormComponent } from './pages/manage/group/group-form/group-form.component';
import { ActionDialogComponent } from './components/action-dialog/action-dialog/action-dialog.component';
import { WidgetLibraryComponent } from './pages/manage/widget-library/widget-library.component';
import { WidgetLibraryFormComponent } from './pages/manage/widget-library/widget-library-form/widget-library-form.component';
import { VisualComponent } from './pages/manage/visual/visual.component';
import { VisualFormComponent } from './pages/manage/visual/visual-form/visual-form.component';
import { DatastoreComponent } from './pages/manage/datastore/datastore.component';
import { DatastoreFormComponent } from './pages/manage/datastore/datastore-form/datastore-form.component';
import { NodeComponent } from './pages/manage/node/node.component';
import { NodeFormComponent } from './pages/manage/node/node-form/node-form.component';
import { ColorPaletteComponent } from './pages/manage/color-palette/color-palette.component';
import { ColorPaletteFormComponent } from './pages/manage/color-palette/color-palette-form/color-palette-form.component';
import { DashboardComponent } from './pages/manage/dashboard/dashboard.component';
import { DashboardFormComponent } from './pages/manage/dashboard/dashboard-form/dashboard-form.component';
import { DashboardDesignerComponent } from './pages/manage/dashboard/dashboard-designer/dashboard-designer.component';
import { WidgetFormComponent } from './pages/manage/dashboard/dashboard-designer/widget-form/widget-form.component';
import { PreviewWidgetComponent } from './components/preview-widget/preview-widget.component';
import { DashboardFilterComponent } from './pages/manage/dashboard/dashboard-filter/dashboard-filter.component';
import { CodeEditorComponent } from './components/code-editor/code-editor.component';
import { ExpandedWidgetComponent } from './components/modals/expanded-widget/expanded-widget.component';
import { GlobalFunctionComponent } from './pages/manage/global-function/global-function.component';
import { FunctionFormComponent } from './pages/manage/global-function/function-form/function-form.component';
import { ToastComponent } from './components/toast/toast.component';
import { ConnectorComponent } from './pages/manage/connector/connector.component';
import { ConnectorFormComponent } from './pages/manage/connector/connector-form/connector-form.component';
import { TitleComponent } from './components/title/title.component';
import { FilterListComponent } from './components/filter-list/filter-list.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { PhotoUploadComponent } from './components/photo-upload/photo-upload.component';
import { DashboardSelectionComponent } from './components/dashboard-selection/dashboard-selection.component';
import { ShareFormComponent } from './components/modals/share-form/share-form.component';
import { SharePreviewComponent } from './pages/share-preview/share-preview.component';
import { DashboardPreviewComponent } from './components/dashboard-preview/dashboard-preview.component';
import { MessagesComponent } from './components/modals/messages/messages.component';
import { LogComponent } from './components/modals/log/log.component';
import { WidgetPreviewComponent } from './components/widget-preview/widget-preview.component';
import { ModalTitleComponent } from './components/modal-title/modal-title.component';
import { RegisterComponent } from './pages/register/register.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FilterFormComponent } from './components/filter-form/filter-form.component';
import { LicenseComponent } from './pages/manage/license/license.component';
import { FilterSelectionComponent } from './components/filter-selection/filter-selection.component';
import { CookieService } from 'ngx-cookie-service';
import { LdapConfigComponent } from './pages/manage/ldap-config/ldap-config.component';
import { PasswordPolicyComponent } from './pages/manage/password-policy/password-policy.component';
import { DeployApp } from './pages/manage/deploy-app/deploy-app.component';
import { CreateAppComponent } from './pages/manage/deploy-app/create-app/create-app.component';
import { HomeComponent } from './pages/home/home.component';
import { AppCardComponent } from './components/app-card/app-card.component';
import { SharePreviewModalComponent } from './components/share-preview-modal/share-preview-modal.component';
import { NgxCaptchaModule } from '@binssoft/ngx-captcha';
import { LoadingComponent } from './components/loading/loading.component';
import { ViewsComponent } from './components/modals/views/views.component';
import { ScriptsComponent } from './pages/manage/scripts/scripts.component';
import { ScriptFormComponent } from './pages/manage/scripts/script-form/script-form/script-form.component';
import { ScriptFormComponent as SelfServiceScriptFormComponent } from './components/self-service/self-service-flow/script-form/script-form.component';


import { LandingComponent } from './pages/manage/landing/landing/landing.component';
import { SystemParametersComponent } from './pages/manage/system-parameters/system-parameters.component';
import { BussionLogsComponent } from './pages/manage/bussion-logs/bussion-logs.component';
import { UploadConfigurationFormComponent } from './components/upload-configuration-form/upload-configuration-form.component';
import { BussionMainComponent } from './components/bussion-main/bussion-main.component';
import { MaterialModule } from './material.module';
import { MatIconModule } from '@angular/material/icon';
import { BussionLoginComponent } from './components/bussion-login/bussion-login.component';

import { SelfServiceMainComponent } from './components/self-service-main/self-service-main.component';
import { SelfServiceDesignerComponent } from './components/self-service-designer/self-service-designer.component';
import { SelfServiceDatastoreFormComponent } from './components/self-service-datastore-form/self-service-datastore-form.component';
import { SelfServiceDashboardFormComponent } from './components/self-service-dashboard-form/self-service-dashboard-form.component';
import { SelfServiceDataStoreListComponent } from './components/self-service-dataStoreList/self-service-data-store-list/self-service-data-store-list.component';
import { SelfServiceDashboardListComponent } from './components/self-service-dashboardList/self-service-dashboard-list/self-service-dashboard-list.component';
import { SelfServiceComponent } from './pages/self-service/self-service.component';
import { SelfServiceDatastoreFileComponent } from './components/self-service-datastore-file/self-service-datastore-file/self-service-datastore-file.component';
import { AgGridModule } from 'ag-grid-angular';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorSliderModule } from 'ngx-color/slider';
import { ColorChromeModule } from 'ngx-color/chrome';
import { DashboardOpenerComponent } from './components/dashboard-opener/dashboard-opener.component';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { ConsoleScreenComponent } from './components/console-screen/console-screen.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { MailSceduleFormComponent } from './components/mail-scedule-form/mail-scedule-form.component';
import { SharePersonFormComponent } from './components/share-person-form/share-person-form.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { AvatarGroupComponent } from './components/avatar-group/avatar-group.component';
import { DataListComponent } from './components/data-list/data-list.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColumnSelectDialogComponent } from './components/column-select-dialog/column-select-dialog.component';
import { FilterBadgeComponent } from './components/filter-badge/filter-badge.component';
import { DashboardHeaderComponent } from './components/dashboard-header/dashboard-header.component';
import { SpeechMicrophoneComponent } from './components/speech-microphone/speech-microphone.component';
import { AvailableWidgetSelectionComponent } from './components/available-widget-selection/available-widget-selection.component';
import { ListItemWithIConComponent } from './components/list-item-with-icon/list-item-with-icon.component';
import { CustomModalComponent } from './components/modals/custom-modal/custom-modal.component';
import { CustomDropdownMenuWithSearchComponent } from './components/custom-dropdown-menu-with-search/custom-dropdown-menu-with-search.component';
import { CustomTitleBoxComponent } from './components/custom-title-box/custom-title-box.component';
import { CustomBadgeWithClearComponent } from './components/custom-badge-with-clear/custom-badge-with-clear.component';
import { AgGridTableComponent } from './components/ag-grid-table/ag-grid-table.component';
import { CustomColorPickerComponent } from './components/custom-color-picker/custom-color-picker.component';
import { PopoverModule } from './components/popover/popover.module';
import { CustomLoadingComponent } from './components/custom-loading/custom-loading.component';
import { SelfServiceAnalyzeComponent } from './components/self-service/self-service-analyze/self-service-analyze.component';
import { CustomHeaderComponent } from './components/custom-header/custom-header.component';
import { CustomLeftmenuComponent } from './components/custom-leftmenu/custom-leftmenu.component';
import { SelfServiceFlowComponent } from './components/self-service/self-service-flow/self-service-flow.component';
import { FlowDrawComponent } from './components/flow-draw/flow-draw.component';
import { CustomCardWithDescComponent } from './components/custom-card-with-desc/custom-card-with-desc.component';
import { FlowFormComponent } from './components/self-service/self-service-flow/flow-form/flow-form.component';
import { FlowDesignerComponent } from './components/self-service/self-service-flow/flow-designer/flow-designer.component';
import { CardWithActionsComponent } from './components/card-with-actions/card-with-actions.component';
import { FlowLineRuleFormComponent } from './components/self-service/self-service-flow/flow-line-rule-form/flow-line-rule-form.component';
import { FlowNodeParameterFormComponent } from './components/self-service/self-service-flow/flow-node-parameter-form/flow-node-parameter-form.component';
import { FlowLogComponent } from './components/self-service/self-service-flow/flow-log/flow-log.component';
import { SelectionListWithSearchComponent } from './components/selection-list-with-search/selection-list-with-search.component';
import { GridsterComponent } from './components/gridster/gridster.component';
import { DataAppDesignComponent } from './components/data-app-design/data-app-design.component';
import { NotAuthComponent } from './pages/not-auth/not-auth.component';
import { ExpandTableComponent } from './components/expand-table/expand-table.component';
import { ViewButtonComponent } from './components/view-button/view-button.component';
import { LanguageManagementComponent } from './components/language-management/language-management.component';
import { LanguageFormComponent } from './components/language-form/language-form.component';
import { CustomTranslateLoaderService } from './services/translate/custom-translate-loader.service';
import { ServerArchComponent } from './pages/manage/node/server-arch/server-arch.component';
import { HotTableModule } from '@handsontable/angular';
import { EditTableComponent } from './components/edit-table/edit-table.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { GridAnalyticsComponent } from './pages/self-service/grid-analytics/grid-analytics.component';

import { SettingSectionComponent } from './pages/manage/dashboard/dashboard-designer/widget-form/setting-section/setting-section.component';

import { ImageSelectboxComponent } from './components/image-selectbox/image-selectbox.component';
import { IndexFormComponent } from './pages/manage/datastore/index-form/index-form.component';
import { DashboardPerformanceInfoComponent } from './components/dashboard-performance-info/dashboard-performance-info.component';
import { CustomContextMenuComponent } from './components/custom-context-menu/custom-context-menu.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    HomeComponent,
    ManageComponent,
    CreateAppComponent,
    HeaderComponent,
    SubHeaderComponent,
    UserComponent,
    GroupComponent,
    RoleComponent,
    CreateUserComponent,
    RoleFormComponent,
    GroupFormComponent,
    ActionDialogComponent,
    WidgetLibraryComponent,
    WidgetLibraryFormComponent,
    VisualComponent,
    VisualFormComponent,
    DatastoreComponent,
    DatastoreFormComponent,
    NodeComponent,
    NodeFormComponent,
    ColorPaletteComponent,
    ColorPaletteFormComponent,
    DashboardComponent,
    DashboardFormComponent,
    DashboardDesignerComponent,
    WidgetFormComponent,
    PreviewWidgetComponent,
    DashboardFilterComponent,
    CodeEditorComponent,
    ExpandedWidgetComponent,
    GlobalFunctionComponent,
    FunctionFormComponent,
    ToastComponent,
    ConnectorComponent,
    ConnectorFormComponent,
    TitleComponent,
    FilterListComponent,
    DataTableComponent,
    PhotoUploadComponent,
    DashboardSelectionComponent,
    ShareFormComponent,
    SharePreviewComponent,
    DashboardPreviewComponent,
    MessagesComponent,
    LogComponent,
    WidgetPreviewComponent,
    ModalTitleComponent,
    RegisterComponent,
    FilterFormComponent,
    LicenseComponent,
    FilterSelectionComponent,
    LdapConfigComponent,
    PasswordPolicyComponent,
    DeployApp,
    AppCardComponent,
    SharePreviewModalComponent,
    LoadingComponent,
    ViewsComponent,
    ScriptsComponent,
    ScriptFormComponent,
    LandingComponent,
    SystemParametersComponent,
    BussionLogsComponent,
    UploadConfigurationFormComponent,
    BussionMainComponent,
    BussionLoginComponent,
    SelfServiceMainComponent,
    SelfServiceDesignerComponent,
    SelfServiceDataStoreListComponent,
    SelfServiceDashboardListComponent,
    SelfServiceDatastoreFormComponent,
    DashboardOpenerComponent,
    BreadCrumbComponent,
    ChatbotComponent,
    SelfServiceDashboardFormComponent,
    SelfServiceDataStoreListComponent,
    SelfServiceDashboardListComponent,
    SelfServiceComponent,
    SelfServiceDatastoreFileComponent,
    ConsoleScreenComponent,
    SearchInputComponent,
    BreadCrumbComponent,
    MailSceduleFormComponent,
    SharePersonFormComponent,
    UserCardComponent,
    AvatarGroupComponent,
    DataListComponent,
    ViewsComponent,
    ColumnSelectDialogComponent,
    FilterBadgeComponent,
    DashboardHeaderComponent,
    SpeechMicrophoneComponent,
    AvailableWidgetSelectionComponent,
    ListItemWithIConComponent,
    CustomModalComponent,
    CustomDropdownMenuWithSearchComponent,
    CustomTitleBoxComponent,
    CustomBadgeWithClearComponent,
    AgGridTableComponent,
    CustomColorPickerComponent,
    CustomLoadingComponent,
    SelfServiceAnalyzeComponent,
    CustomHeaderComponent,
    CustomLeftmenuComponent,
    SelfServiceFlowComponent,
    FlowDrawComponent,
    CustomCardWithDescComponent,
    SelfServiceScriptFormComponent,
    FlowFormComponent,
    FlowDesignerComponent,
    CardWithActionsComponent,
    FlowLineRuleFormComponent,
    FlowNodeParameterFormComponent,
    FlowLogComponent,
    SelectionListWithSearchComponent,
    GridsterComponent,
    DataAppDesignComponent,
    NotAuthComponent,
    ExpandTableComponent,
    ViewButtonComponent,
    LanguageManagementComponent,
    LanguageFormComponent,
    ServerArchComponent,
    EditTableComponent,
    SplashScreenComponent,
    GridAnalyticsComponent,
    SettingSectionComponent,
    ImageSelectboxComponent,
    IndexFormComponent,
    DashboardPerformanceInfoComponent,
    CustomContextMenuComponent



  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PopoverModule,
    

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useFactory: HttpLoaderFactory,
        useClass: CustomTranslateLoaderService,
        deps: [HttpClient]
      }
    }),
    MonacoEditorModule.forRoot(),
    HttpClientModule,
    NgxSpinnerModule,
    ColorPickerModule,
    GridsterModule,
    NgxCaptchaModule,
    MaterialModule,
    MatIconModule,
    AgGridModule,
    ColorSketchModule,
    ColorSliderModule,
    ColorChromeModule,
    HotTableModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    CookieService,
    LoginComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
