<!-- <div class="modal-header" style="z-index: 10000000;" [style.right]="right+'%'">
    <mat-icon style="width: 100%;height: 100%;" mat-dialog-close>clear</mat-icon>
</div>

<div class="toolbar" role="banner" *ngIf="enableTitle">
    <img src="/assets/images/bussionnewlogo.png" [routerLink]="['']" />
</div>
<div class="dashboard-header" *ngIf="isShownDashboardTitle||enableFilter">
    <div class="dashboard-title" *ngIf="isShownDashboardTitle">
        <span class="title-text">{{dashboard?.title}}</span>
    </div>
    <div class="dashboard-button-groups">
        <button *ngIf="enableFilter" class="button" mat-button
            (click)="openDashboardFilter()">
            {{ 'Common.Filters' | translate }}
            <mat-icon svgIcon="filter"></mat-icon>
        </button>
    </div>
    
</div>
<div class="filter-contentx" *ngIf="isShownFilter">
    <app-filter-selection [dimensions]="dashboard.dimensions" [filters]="dashboard.filter" [savebuttonvisible]="false"
        [initialFilters]="selectedDashboardInitialFilters" [isHeaderShown]="false" (save)="saveFilter($event)" (close)="closeFilterForm($event)">
    </app-filter-selection>
</div> -->

<app-dashboard-preview [showAvatar]="false" 
*ngIf="dashboard" [showFilter]="enableFilter" 
[widgets]="widgets" [dashboard]="dashboard" 
[selectedDashboardInitialFilters]="filters" [isShownDashboardTitle]="enableTitle" 
[shareEncryptKey]="shareEncryptKey" [domain]="domain" [shareId]="shareId"
 [showExitButton]="true" [authType]=""></app-dashboard-preview>

<!-- <div class="share-preview-content" [ngStyle]="{'height': enableTitle ? 'calc(100vh - 158px)' : 'calc(100vh - 8px)' }">
    <div #dashboardPage class="grid-container">
        <gridster [options]="options" >
            <gridster-item [item]="item" *ngFor="let item of dashboardWidgets">
                <app-widget-preview  [showZoom]="true" [showShare]="true" [widget]="item"
                (expandEvent)="expandWidget($event)" (shareEvent)="openShareForm($event)"
                [selectedDashboard]="selectedDashboard" class="gridster-item">
                </app-widget-preview>
            </gridster-item>
        </gridster>
    </div>
</div> -->