<div class="connector-page custom-page">
    <app-data-table *ngIf="liststyle=='list'" [listTypeButton]="false"
        [secondButtonText]="roles.includes('DataEngineer')? ('SelfServicePage.DataFlow'|translate):''"
        (secondButtonFunc)="navigateToFlow()" addButtonText="{{ 'ConnectorPage.AddButton' | translate }}"
        title="{{ 'ConnectorPage.Title' | translate }}"
        filterPlaceholder="{{ 'ConnectorPage.FilterPlaceholder' | translate }}" (openModal)="openConnector($event)"
        (deleteRow)="deleteConnectorComponent($event)" [displayedColumnsName]="displayedColumnsName"
        [displayedColumns]="displayedColumns" (changeListStyleFunction)="changeListStyle($event)" [data]="connectors"
        showRunTask="true" showStopTask="true" (runTaskEvent)="runTask($event)" (stopTaskEvent)="stopTask($event)"
        [pageSizeOptions]="[20, 50, 100]">
    </app-data-table>

    <!-- <app-expand-table *ngIf="liststyle=='folder'" [listTypeButton]="true"
        [secondButtonText]="roles.includes('DataEngineer')? ('SelfServicePage.DataFlow'|translate):''"
        (secondButtonFunc)="navigateToFlow()" addButtonText="{{ 'ConnectorPage.AddButton' | translate }}"
        title="{{ 'ConnectorPage.Title' | translate }}"
        filterPlaceholder="{{ 'ConnectorPage.FilterPlaceholder' | translate }}" (openModal)="openConnector($event)"
        (deleteRow)="deleteConnectorComponent($event)" [displayedColumnsName]="displayedColumnsName"
        [displayedColumns]="displayedColumns" (changeListStyleFunction)="changeListStyle($event)" [data]="connectors"
        showRunTask="true" showStopTask="true" (runTaskEvent)="runTask($event)" (stopTaskEvent)="stopTask($event)" [treedataSource]="treeDataSource"
        [pageSizeOptions]="[20, 50, 100]" [columnsWidth]="columnsWidth">
    </app-expand-table> -->
</div>