import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { NodeResponse } from 'src/app/models/responses/nodeResponse';
import { BussionConnector } from 'src/app/models/responses/bussionConncetorResponse';
import { User } from 'src/app/models/responses/loginResponse';

@Injectable({
  providedIn: 'root'
})
export class ConnectorService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  initiliazeConnector(username: string, password: string, port: string, node: string) {
    let param = {
      UserName: username,
      Password: password,
      UserId: username,
      Domain: this.authService.getUserDomain(),
      Port: port,
      Node: node
    };
    let url = Endpoints.initiliazeConnector
    return this.http.post(url, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getConnectors() {
    let param = {
      Token: this.authService.getToken(),
    };

    return this.http.post(Endpoints.getBussionConnector, param).pipe(
      map((response: NodeResponse) => {
        return response.message;
      })
    );

  }

  deleteConnector(connectorId: string) {
    let param = {
      Token: this.authService.getToken(),
      BussionConnectorId: connectorId
    };

    return this.http.post(Endpoints.deleteBussionConnector, param).pipe(
      map((response: NodeResponse) => {
        return response.message;
      })
    );
  }

  getCredentials(host: string, port: string) {
    let param = {
      Token: this.authService.getToken(),
      Host: host,
      Port: port
    };

    return this.http.post(Endpoints.GetCredidentials, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getConnectorTasks() {
    let param = {
      Token: this.authService.getToken(),
    };

    return this.http.post(Endpoints.getConnectorTasks, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  upsertConnectorTask(task: BussionConnector) {
    task.domain = this.authService.getUserDomain();
    let param = {
      Token: this.authService.getToken(),
      ConnectorTask: task
    };

    return this.http.post(Endpoints.upsertConnectorTask, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  deleteConnectorTask(taskId: string) {
    let param = {
      Token: this.authService.getToken(),
      ConnectorTaskId: taskId
    };

    return this.http.post(Endpoints.deleteConnectorTask, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  runTask(taskId: string) {
    let param = {
      Token: this.authService.getToken(),
      ConnectorTaskId: taskId
    };

    return this.http.post(Endpoints.runConnectorTask, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  stopTask(taskId: string, ConnectorNodeId?: string) {
    var param: any = {
      Token: this.authService.getToken(),
      TaskId: taskId
    };
    if (ConnectorNodeId) {
      param = {
        Token: this.authService.getToken(),
        TaskId: taskId,
        ConnectorNodeId: ConnectorNodeId
      }
    }


    return this.http.post(Endpoints.stopConnectorTask, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getTaskStatus(taskId: string) {
    let param = {
      Token: this.authService.getToken(),
      TaskId: taskId
    };

    let url = Endpoints.getTaskLogs;

    return this.http.post(url, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  getDataFlows() {
    let param = {
      Token: this.authService.getToken()
    };

    return this.http.post(Endpoints.GetScriptFlows, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  GetExternalEngineCode(nodeId: string, engine: string) {
    let param = {
      Token: this.authService.getToken(),
      NodeId: nodeId,
      Engine: engine
    };

    return this.http.post(Endpoints.GetExternalEngineCode, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  GetExternalEngineTemplate(nodeId: string) {
    let param = {
      Token: this.authService.getToken(),
      NodeId: nodeId
    };

    return this.http.post(Endpoints.GetExternalEngineTemplate, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  UpsertExternalEngine(nodeId: string, engine: string, code: string) {
    let param = {
      Token: this.authService.getToken(),
      NodeId: nodeId,
      Engine: engine,
      Code: code
    };

    return this.http.post(Endpoints.UpsertExternalEngine, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }


  DeleteExternalEngine(nodeId: string, engine: string) {
    let param = {
      Token: this.authService.getToken(),
      NodeId: nodeId,
      Engine: engine
    };

    return this.http.post(Endpoints.DeleteExternalEngine, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  GetExternalEngines(nodeId: string) {
    let param = {
      Token: this.authService.getToken(),
      NodeId: nodeId
    };

    return this.http.post(Endpoints.GetExternalEngines, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }

  ExecuteQuery(connector: BussionConnector, password: string) {
    connector.type = -1
    connector.sourcePassword = password
    let param = {
      Token: this.authService.getToken(),
      Connector: connector
    };

    return this.http.post(Endpoints.executeQuery, param).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }
}
