import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverContainerComponent } from './popover-container/popover-container.component';
import { PopoverDirective } from './popover.directive';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { PopoverService } from './popover.service';



@NgModule({
  declarations: [
    PopoverContainerComponent,
    PopoverDirective
  ],
  imports: [
    CommonModule,
    OverlayModule
  ],
  exports: [PopoverDirective, PopoverContainerComponent],
  providers: [Overlay, PopoverService]
})
export class PopoverModule { }
