<!-- <div style="margin-left:2rem;margin-top:2.5rem;">
    <app-title style="margin-top:2rem" title="Bussion login"></app-title>
</div> -->



<div class="bussion-main">
    <div style="width: 90%;height: 90%;">
        <div style="display: flex;justify-content: space-between;">

            <button *ngIf="fileType=='html'" mat-button (click)="changeEditor('javascript')">{{'javascript'}}</button>

            <button *ngIf="fileType=='javascript'" mat-button (click)="changeEditor('html')">{{'Html'}}</button>

            <button mat-button (click)="openBiggerEditor()">{{
                'Common.ShowBiggerEditor' | translate}}</button>

        </div>
        <ngx-monaco-editor style="width: 100%;height: 90%;" class="editor" [options]="editorOptions" [(ngModel)]="code">
        </ngx-monaco-editor>
    </div>
    <div style="width: 90%;display: flex;justify-content: end;">
        <button (click)="setBussionLogin($event)" mat-button
            class="b-btn b-success">{{'SystemParametersPage.Update'|translate}}</button>
    </div>

</div>