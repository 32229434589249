import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CompactType, DisplayGrid, GridType, GridsterConfig, GridsterItem } from 'angular-gridster2';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { ExpandedWidgetComponent } from '../modals/expanded-widget/expanded-widget.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ShareFormComponent } from '../modals/share-form/share-form.component';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { WidgetPreview } from 'src/app/models/responses/widgetLibraryResponse';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-gridster',
  templateUrl: './gridster.component.html',
  styleUrls: ['./gridster.component.scss']
})
export class GridsterComponent implements OnInit {

  options: GridsterConfig={
    itemChangeCallback: (opened) => {
      this.options?.api.optionsChanged()
    },
    gridType: GridType.ScrollVertical,
    compactType: CompactType.None,
    margin: 10,
    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    useTransformPositioning: true,
    mobileBreakpoint: 600,
    minCols: 2,
    maxCols: 100,
    minRows: 100,
    maxRows: 100,
    maxItemCols: 100,
    minItemCols: 1,
    maxItemRows: 100,
    minItemRows: 1,
    maxItemArea: 2500,
    minItemArea: 1,
    defaultItemCols: 1,
    defaultItemRows: 1,
    fixedColWidth: 105,
    fixedRowHeight: 105,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
    scrollSensitivity: 10,
    scrollSpeed: 20,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: false,
    enableEmptyCellDrag: false,
    enableOccupiedCellDrop: false,
    emptyCellDragMaxCols: 50,
    emptyCellDragMaxRows: 50,
    ignoreMarginInRow: false,
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    swap: false,
    pushItems: true,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushDirections: { north: true, east: true, south: true, west: true },
    pushResizeItems: false,
    displayGrid: DisplayGrid.OnDragAndResize,
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: false,
  };
  dashboardWidgets: Array<GridsterItem> = [];
  _dashboard: Dashboard

  @Input() set dashboard(value: Dashboard) {
    this._dashboard = value
    this.options.gridType = value.layout ? GridType[value.layout] : GridType.ScrollVertical
    this.options?.api?.optionsChanged()

    this.getWidgets(value.dashboardId)
  }
  @Input() showFilter: boolean = false
  isDeviceMobile: boolean = false

  // [ngStyle]="{'height':showFilter?'calc(100vh - 90px)': 'calc(100vh - 50px)' }"

  constructor(private modalService: ModalService, private scriptLoader: ScriptLoaderService, private dashboardService: DashboardService) { }

  ngOnInit(): void {

    this.customizeDashboard();


    this.isDeviceMobile = window.innerWidth < 600;
  }

  getWidgets(dashboardId: string) {
    this.dashboardWidgets = []
    this.dashboardService.getWidgetsForDesigner(dashboardId, '', true).subscribe(result => {
      result.forEach((widget, index) => {
        var wd = {

          cols: widget.widget.layout.cols,
          rows: widget.widget.layout.rows,
          y: widget.widget.layout.y,
          x: widget.widget.layout.x,
          designerContainer: widget
        }
        this.dashboardWidgets.push(wd);
        this.createWidget(widget)
      })
    });
  }




  expandWidget(widgetData: any) {
    let data = {
      widgetData: widgetData.designerContainer,
      shareModalOpen: (e) => this.openShareForm({ ...e, filter: this.dashboard.filter}),
      selectedFilters:this.dashboard.filter,
      selectedDashboard:this.dashboard

    }
    this.modalService.openModal(data, ExpandedWidgetComponent, '90%', '100%', 'overflow-hidden').subscribe(result => { });
  }

  openShareForm(shareData: any) {
    let data = {
      objectType: shareData.objectType,
      objectId: shareData.objectId,
      dashboardId: this.dashboard.dashboardId,
      filter: this.dashboard.filter,
      function: () => { }
    };

    this.modalService.openModal(data, ShareFormComponent, 'auto', '600px').subscribe(result => {
      if (result) {
      }
    })
  }



  private createWidget(widgetResponse: any) {
    this.scriptLoader.addScriptFileToHead(widgetResponse.widgetLibraries);
    this.scriptLoader.runWidgetCode(widgetResponse);
  }


  private customizeDashboard() {


    this.options = {
      itemChangeCallback: (opened) => {
        this.options?.api.optionsChanged()
      },
      gridType: GridType.ScrollVertical,
      compactType: CompactType.None,
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 600,
      minCols: 2,
      maxCols: 100,
      minRows: 100,
      maxRows: 100,
      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 105,
      fixedRowHeight: 105,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: false,
      },
      resizable: {
        enabled: false,
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: false,
      displayGrid: DisplayGrid.OnDragAndResize,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,
    };
  }

}
