import { NO_ERRORS_SCHEMA, AfterViewInit, Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewContainerRef, ɵcompileComponent, ComponentRef } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { cerateCssFile, idKeyword } from "src/app/core/utils";
import { App } from "src/app/models/responses/appsResponse";
import { Dashboard, DashboardResponse } from "src/app/models/responses/dashboardResponse";
import { User } from "src/app/models/responses/usersResponse";
import { BussionMainService } from "src/app/services/bussion-main/bussion-main.service";
import { DashboardService } from "src/app/services/dashboard/dashboard.service";
import { DeployAppService } from "src/app/services/deploy-app/deploy-app.service";
import { ScriptLoaderService } from "src/app/services/script-loader/script-loader.service";
import { UserService } from "src/app/services/user/user.service";
import { environment } from "src/environments/environment";
import { BrowserModule, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { DynamicHomeComponent } from "src/app/components/dynamic-home/dynamic-home.component";
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { MaterialModule } from "src/app/material.module";
import { MatIconModule } from "@angular/material/icon";
import { GroupService } from "src/app/services/group/group.service";
import { Group } from "src/app/models/responses/groupsResponse";
import { forkJoin } from "rxjs";
import { concatMap, filter, finalize, map, take, tap } from "rxjs/operators";

@Component({

    selector: 'app-home',
    template: '',
    // templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    page: number = 1
    // dynamicContent: string;
    roles = []
    classNameAll: string = 'filter-active'
    classNameDash: string = ''
    classNameApp: string = ''
    dframe: string = 'none'
    dlist: string = 'flex'
    users: User[]
    selectedGroupOption: string = 'All'
    groups: Group[] = []
    selectedDashboard: any;
    selectedPage: any[] = []
    Applications: any[] = []
    Dashboards: Dashboard[] = []
    ActiveDashboards: Dashboard[] = []
    selectedPageAllData: any[] = []
    openerMethod = [
        { 1: 'internal' },
        { 2: 'newtab' },
        { 3: 'pupop' }

    ]

    savedHomeOption: string | number = 'All'



    constructor(private deployAppService: DeployAppService,
        private dashboardService: DashboardService,
        private spinner: NgxSpinnerService,
        private groupService: GroupService,
        private router: Router,
        private userService: UserService,
        private bussionMainService: BussionMainService,
        private scriptLoader: ScriptLoaderService, private cookieService: CookieService,
        private viewContainerRef: ViewContainerRef
    ) { }
    baseUrl = environment.imageUrl;
    dynamicComponentRef: ComponentRef<DynamicHomeComponent>
    src: string
    dynamicContent: string;
    loading: boolean = true
    selectedMenu: string = 'All'
    ngOnInit(): void {
        //   AOS.init();
        // this.getApps()
        // this.getDashboards()

        this.getAllDatasFromHttp()
        // this.getDashboard()
        // if(this.dashboard?.css)


    }

    getAllDatasFromHttp() {
        this.spinner.show();

        forkJoin([
            this.getApps(),
            this.getUserActiveDashboards().pipe(concatMap(res => { return this.getDashboards() })),
            this.getGroups()
        ]).subscribe({
            next: ([appsResult, dashboardsResult, groupsResult]) => {
                this.spinner.hide();

                this.Dashboards = dashboardsResult
                this.Applications = appsResult
                this.groups = groupsResult

                this.groupAddDashImage();



                this.getBussionMain();




                this.setCachedPage()

            },
            error: (err) => {
                this.spinner.hide();
                console.error('Bir hata oluştu:', err);
            }
        });

    }

    setCachedPage(ishome?: string) {

        var homeData = localStorage.getItem('selectedHome')
        var user = localStorage.getItem('user')
        var userObj: User = user ? JSON.parse(user) : null



        if (ishome == 'home') {
            history.replaceState({ apply: 'nolocalstorage' }, '')

        }

        if (history.state?.apply == 'nolocalstorage' && userObj.defaultDashboardAppId) {
            this.savedHomeOption = userObj.defaultDashboardAppId
            this.changeGroupSelection(userObj.defaultDashboardAppId)
        } else if (homeData) {
            var jsonhome: { 'selectedTab': string, 'selectedOption': string } = JSON.parse(homeData)
            if (history.state?.apply == 'nolocalstorage') {
                this.savedHomeOption = jsonhome.selectedOption
            }
            this.select(jsonhome.selectedOption);
            this.changeGroupSelection(jsonhome.selectedOption)
        } else {
            this.select('All')
        }


    }

    getData() {
        debugger;
        Msg.DataHandler = this.selectedPage;
        // Msg.BaseUrl = this.baseUrl;

        //return s.selectedPagthie;
    }


    groupAddDashImage() {

        this.groups = this.groups.map(item => {
            var groupDashboard = this.Dashboards.filter(x => item.dashboards.includes(x.dashboardId))
            return {
                ...item,
                createdDate: item.createdDate?.split(' ')?.length > 0 ? item.createdDate.split(' ')[0] : '',
                dashImages: groupDashboard.map(dash => { return dash.icon }).slice(0, 5),
                dashCount: groupDashboard.length > 5 ? groupDashboard.length - 5 : 0
            }
        });
    }



    getGroups() {
        return this.groupService.getGroups().pipe(
            map(res => {
                var selectedUser = localStorage.getItem('user')
                var selectedUserGroups = []

                if (selectedUser) {
                    selectedUserGroups = JSON.parse(selectedUser).groups
                    console.log('first', selectedUserGroups)
                    this.groups = res.filter(x => selectedUserGroups.includes(x.groupName))
                }

                const sortedGroups = this.groups.filter(x => x.showInAppView == 1).sort((a, b) => {
                    if (a.displayOrder === 0 && b.displayOrder !== 0) {
                        return 1; // `a` sona gider
                    } else if (b.displayOrder === 0 && a.displayOrder !== 0) {
                        return -1; // `b` sona gider
                    } else {
                        return a.displayOrder - b.displayOrder; // Normal sıralama
                    }
                });

                return sortedGroups

            })
        )

    }

    changeGroupSelection(event) {
        var selectedValue = event?.target?.value || event
        this.selectedGroupOption = selectedValue
        console.log('first bura neden çalışıyor')

        if (this.selectedMenu !== 'Dashboard')
            this.select('Dashboard')

        if (selectedValue == 'AppView') {
            this.selectedPage = this.groups
            this.selectedPageAllData = this.groups
        } else if (selectedValue == 'All') {
            this.selectedList = this.Dashboards.concat(this.Applications)
            this.selectedPage = this.Dashboards.concat(this.Applications)
            this.selectedPageAllData = this.Dashboards.concat(this.Applications)
        } else if (selectedValue == 'My') {
            var userId = localStorage.getItem('userId')
            this.selectedList = this.Dashboards.filter(x => x.ownerId == userId)
            this.selectedPage = this.Dashboards.filter(x => x.ownerId == userId)
            this.selectedPageAllData = this.Dashboards.filter(x => x.ownerId == userId)

        } else if (selectedValue == 'Favorite') {
            this.selectedList = this.ActiveDashboards
            this.selectedPage = this.ActiveDashboards
            this.selectedPageAllData = this.ActiveDashboards

        } else {
            this.selectedPage = []
            this.selectedList = []
            this.selectedPageAllData = []

            var groupSelecteddashboards = this.groups.find(x => x.groupId == selectedValue)?.dashboards || []
            groupSelecteddashboards.map(item => {
                var selectedDash = this.Dashboards.find(x => x.dashboardId == item)
                if (selectedDash) {
                    this.selectedPage.push(selectedDash)
                    this.selectedList.push(selectedDash)
                    this.selectedPageAllData.push(selectedDash)
                }
            })

        }

        this.saveLocalStorage()

    }

    saveLocalStorage() {
        var storageItem = { 'selectedTab': this.selectedMenu, 'selectedOption': this.selectedGroupOption }
        localStorage.setItem('selectedHome', JSON.stringify(storageItem))
    }

    saveUserStorage(selectedOption?: string) {
        var user = localStorage.getItem('user')
        if (user) {
            var userObj: User = JSON.parse(user);
            userObj.defaultDashboardAppId = selectedOption ?? this.selectedGroupOption
            this.userService.upsertUser(userObj, [], [], 1).subscribe(res => {
                if (res) {
                    localStorage.setItem('user', JSON.stringify(userObj))
                }
            })

        }
    }

    getBussionMain() {
        this.bussionMainService.getBussionMain('html').subscribe((res: any) => {
            this.dynamicContent = res
            this.createMainPage();
            // this.dynamicContent = this.sanitizer.bypassSecurityTrustHtml(res) as SafeHtml
        })
    }

    openSelfServisMain() {
        this.router.navigate(['selfservice'])
    }



    createMainPage() {

        ɵcompileComponent(DynamicHomeComponent, {
            //jit:true,
            template: this.dynamicContent,
            standalone: true,
            imports: [NgFor, NgIf, NgClass, TranslateModule, MaterialModule, CommonModule, MatIconModule],
            providers: [],
            schemas: [NO_ERRORS_SCHEMA]
        });

        this.viewContainerRef.clear();


        this.dynamicComponentRef = this.viewContainerRef.createComponent(DynamicHomeComponent);
        this.dynamicComponentRef.instance.groups = this.groups
        this.dynamicComponentRef.instance.Dashboards = this.Dashboards
        this.dynamicComponentRef.instance.Applications = this.Applications
        this.dynamicComponentRef.instance.ActiveDashboards = this.ActiveDashboards

        Msg.DashboardService = this.dashboardService;
        Msg.ScriptLoader = this.scriptLoader;
        Msg.Router = this.router;

        this.dynamicComponentRef.instance.saveUserStorageCallBack = (selectedOption) => {
            this.saveUserStorage(selectedOption)
        }

    }







    getLastApp() {
        let lastusedapp = JSON.parse(localStorage.getItem('app'))
        return lastusedapp;
    }

    getApps() {

        if (this.dashboardService.cachedData.getValue().authorizedApps.length > 0) {
            return this.dashboardService.getCachedDataObservable().pipe(
                take(1),
                map(res => {
                    return res.authorizedApps.map(x => {
                        return { ...x, creationDate: x.creationDate.split(' ')[0] || '' }
                    })
                })
            )
        } else {
            return this.deployAppService.GetAuthorizedApp().pipe(
                map(res => {
                    var filtered = res.map(x => {
                        return { ...x, creationDate: x.creationDate.split(' ')[0] || '' }
                    })
                    return filtered;
                }))
        }
    }

    selectedList: any[] = []
    contentIdKeyword = idKeyword.dashboard


    getUserActiveDashboards() {
        if (this.dashboardService.cachedData.getValue().activeDashboard.length > 0) {
            return this.dashboardService.getCachedDataObservable().pipe(
                take(1),
                map(res => {
                    var filtered = res.activeDashboard.filter(x => x.isMainDashboard === 1).map(x => {
                        return { ...x, creationDate: x.creationDate.split(' ')[0] || '' }
                    })
                    this.ActiveDashboards = filtered
                    return filtered
                }))
        } else {
            return this.dashboardService.getUserActiveDashboards().pipe(map(res => {
                var filtered = res.filter(x => x.isMainDashboard === 1).map(x => {
                    return { ...x, creationDate: x.creationDate.split(' ')[0] || '' }
                })
                this.ActiveDashboards = filtered
                return filtered
            }))
        }
    }

    getDashboards() {
        if (this.dashboardService.cachedData.getValue().authorizedDashboard.length > 0) {
            return this.dashboardService.getCachedDataObservable().pipe(
                take(1),
                map(res => {
                    return res.authorizedDashboard.filter(x => x.isMainDashboard === 1).map(x => {
                        return { ...x, creationDate: x.creationDate.split(' ')[0] || '' }
                    })
                })
            )
        } else {
            return this.dashboardService.getAuthorizedDashboards(1).pipe(
                map(res => {
                    var filtered = res.filter(x => x.isMainDashboard === 1).filter(x => x.isMainDashboard === 1).map(x => {
                        return { ...x, creationDate: x.creationDate.split(' ')[0] || '' }
                    })
                    return filtered;
                }))
        }

        // if (this.dashboardService.cachedData.getValue().authorizedDashboard.length > 0) {

        //     this.Dashboards = this.dashboardService.cachedData.getValue().authorizedDashboard.filter(x => x.isMainDashboard === 1).map(x => {
        //         return { ...x, creationDate: x.creationDate.split(' ')[0] || '' }
        //     });;


        //     this.selectedList = this.Dashboards.concat(this.Applications)
        //     this.selectedPage = this.Dashboards.concat(this.Applications)
        //     this.pagination(this.page)
        //     this.spinner.hide()
        //     this.loading = false
        //     debugger;
        //     Msg.DataHandler = this.selectedPage;
        //     Msg.SelectList = this.selectedList;
        //     Msg.Application = this.Applications;
        //     Msg.BaseUrl = this.baseUrl;
        //     Msg.DashboardService = this.dashboardService;
        //     Msg.ScriptLoader = this.scriptLoader;
        //     Msg.Router = this.router;
        //     Msg.Loading = this.loading
        //     Msg.CssFolder = cerateCssFile(this.dashboard?.css, this.selectedDashboard?.dashboardId, this.contentIdKeyword);
        //     this.getBussionMain();
        // } else
        //     this.dashboardService.getAuthorizedDashboards(1).subscribe(result => {
        //         this.Dashboards = result.filter(x => x.isMainDashboard === 1).map(x => {
        //             return { ...x, creationDate: x.creationDate.split(' ')[0] || '' }
        //         });
        //         this.selectedList = this.Dashboards.concat(this.Applications)
        //         this.selectedPage = this.Dashboards.concat(this.Applications)
        //         this.pagination(this.page)
        //         this.spinner.hide()
        //         debugger;
        //         Msg.DataHandler = this.selectedPage;

        //         this.loading = false
        //         Msg.Dashboards = this.Dashboards
        //         Msg.SelectList = this.selectedList;
        //         Msg.Application = this.Applications;
        //         Msg.BaseUrl = this.baseUrl;
        //         Msg.DashboardService = this.dashboardService;
        //         Msg.ScriptLoader = this.scriptLoader;
        //         Msg.Router = this.router;
        //         Msg.Loading = this.loading

        //         Msg.CssFolder = cerateCssFile(this.dashboard?.css, this.selectedDashboard?.dashboardId, this.contentIdKeyword);
        //         this.getBussionMain();

        //     });

    }

    select(type: string) {
        this.selectedMenu = type
        if (type === 'All') {
            this.selectedList = this.Dashboards.concat(this.Applications)
            this.selectedPage = this.Dashboards.concat(this.Applications)
            this.pagination(this.page = 1)
            this.classNameAll = 'filter-active'
            this.classNameDash = ''
            this.classNameApp = ''
        } else if (type === 'Dashboard') {
            this.selectedList = this.Dashboards
            this.selectedPage = this.Dashboards
            this.pagination(this.page = 1)
            this.classNameAll = ''
            this.classNameApp = ''
            this.classNameDash = 'filter-active'
        } else if (type === 'App') {
            this.selectedList = this.Applications
            this.selectedPage = this.Applications
            this.pagination(this.page = 1)
            this.classNameDash = ''
            this.classNameAll = ''
            this.classNameApp = 'filter-active'
        }
    }


    searchElements(e) {
        // var key = 'title'
        // if (this.selectedPageAllData[0]?.groupName) {
        //   key = 'groupName'
        // } else if (this.selectedPageAllData[0]?.name) {
        //   key = 'name'

        // }


        this.selectedPage = this.selectedPageAllData.filter(x => x?.name?.toLowerCase().includes(e.target.value.toLowerCase())
            || x?.title?.toLowerCase().includes(e.target.value.toLowerCase())
            || x?.groupName?.toLowerCase().includes(e.target.value.toLowerCase()))

    }

    navigation(item: any) {
        if (item.groupId) {
            this.changeGroupSelection(item.groupId)
        } else if (item.applicationLink) {
            localStorage.setItem('app', JSON.stringify(item))
            if (item.openerMethod == 1) {//internal
                window.open(`${this.baseUrl}/Apps/${item.applicationLink}`, "_blank");
            } else if (item.openerMethod == 2) {//newtab
                window.open(`${this.baseUrl}/Apps/${item.applicationLink}`, "_blank");
            } else if (item.openerMethod == 3) {//popup
                let options = {
                    type: 'leftWindow', //newWindow,centerWindow,rightWindow,leftWindow
                    windowWidth: '100%',
                    windowHeight: '100%',
                    isapp: 'true',
                }
                this.scriptLoader.openWindow(`${this.baseUrl}/Apps/${item.applicationLink}`, options)
            }
        } else {
            // this.cookieService.setItem('lastDashboards',)
            this.dashboardService.setLastDashboardId(item.dashboardId)
            this.router.navigate(["/main"], { state: item });
        }
    }



    pagination(pageNumber: number) {
        // if (pageNumber * 6 <= this.selectedList.length) {
        this.selectedPage = this.selectedList.slice((pageNumber - 1) * 8, ((pageNumber - 1) * 8) + 8)

        // }

    }
    getUserImage(ownerId: string) {
        let avatar = this.users.find(x => x.userId == ownerId)?.avatar
        return this.baseUrl + avatar
    }


    returnSavedHome() {
        this.setCachedPage()
    }
}



export class Msg {
    public static BaseUrl: string;
    public static DataHandler;
    public static DashboardService;
    public static ScriptLoader;
    public static Router;
    public static CssFolder;
    public static Pagination;
    public static SelectList;
    public static Application;
    public static Dashboards;
    public static Loading;


}