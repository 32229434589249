<div style="padding: 30px;">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>
            <div class="tree-box">
                <mat-icon [svgIcon]="node.data.icon"></mat-icon>
                <span>{{node.name}}</span>
            </div>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <button class="tree-button" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
           
            <div class="tree-box">
                <mat-icon [svgIcon]="node.data.icon"></mat-icon>
                <span>{{node.name}}</span>
            </div>
        </mat-tree-node>
    </mat-tree>
</div>