import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { PermissionService } from '../services/permission/permission.service';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { CoreService } from '../services/core/core.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

    constructor(
        private permissionService: PermissionService,
        private router: Router,
        private coreService: CoreService,
        private translate: TranslateService
    ) { }

    localpermissions: any[] = []


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.permissionService.getAuthApp().pipe(
            mergeMap(res => this.permissionService.getUserPermissions()),
            map(permissions => {
                console.log(route)
                let hasPermission = this.checkPermissions(route.routeConfig.path, permissions, route);
                if (!hasPermission) {
                    this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.NoPermission"));
                    if (!this.permissionService.getUserPermissionForApp()) {
                        this.permissionService.getUserHomePage()
                        // this.router.navigate([this.permissionService.getUserHomePage()]);
                    } else
                        this.router.navigate(['']);
                }
                return hasPermission
            })
        )
    }


    checkPermissions(path: string, permissions: string[], route: ActivatedRouteSnapshot): boolean {


        if (path == 'group') {
            return permissions.includes('GroupManagement');
        }
        else if (path == 'user') {
            return permissions.includes('UserManagement');
        }
        else if (path == 'role') {
            return permissions.includes('RoleManagement');
        }
        else if (path == 'visual') {
            return permissions.includes('VisualComponentManagement');
        }
        else if (path == 'connectors') {
            return permissions.includes('BussionConnectorManagement');
        }
        else if (path == 'color-palette') {
            return permissions.includes('ColorPaletteManagement');
        }
        else if (path == 'dashboard') {
            return permissions.includes('DashboardDesign');
        }
        else if (path == 'datastore') {
            return permissions.includes('DataStoreManagement');
        }
        else if (path == 'global-functions') {
            return permissions.includes('GlobalFunctionManagement');
        }
        else if (path == 'widget-library') {
            return permissions.includes('WidgetLibraryManagement');
        }
        else if (path == 'widget-library') {
            return permissions.includes('ApplicationManagement');
        }
        else if (path == 'dashboard/designer/:dashboardId') {
            return permissions.includes('DashboardDesign');
        }
        else if (path == 'license') {
            return permissions.includes('LicenseManagement');
        }
        else if (path == 'ldap-config') {
            return permissions.includes('SystemConfiguration');
        }
        else if (path == 'password-policy') {
            return permissions.includes('SystemConfiguration');
        }
        else if (path == 'scripts') {
            return permissions.includes('SystemConfiguration');
        }
        else if (path == 'deploy-app') {
            return permissions.includes('ApplicationManagement') && this.bussionModulePermission('Bussion Apps');
        }
        else if (path == 'node') {
            return permissions.includes('NodeManagement');
        }
        else if (path.includes('flow')) {
            return permissions.includes('DataEngineer') && this.bussionModulePermission('Data Flow');
        }
        else if (path.includes('selfservice') || path.includes('analyze') || path.includes('dashboard-list') || path.includes('data-store-list')) {
            return permissions.includes('DataEngineer') || permissions.includes('SelfService');

        } else if (path.includes('system-parameters')) {

            return permissions.includes('MaxTries') || permissions.includes('Debugger') || permissions.includes('Captcha')
        } else if (path.includes("bussion-logs")) {

            return permissions.includes('SystemConfiguration')
        }
        else if (path.includes('data-app')) {
            return true
        } else if (path.includes('main/:appId')) {
            return this.permissionService.appIdPermission(route.params['appId'])
        }
        else if (path == "" || path.includes('landing')) {

            if (this.managementPanelRoles.some(eleman1 => permissions.includes(eleman1))) {
                return true
            } else {
                return this.permissionService.getUserPermissionForApp() && true
            }
        }
        else {
            return true;
        }
    }

    managementPanelRoles: string[] = ['GroupManagement',
        'UserManagement',
        'RoleManagement',
        'VisualComponentManagement',
        'BussionConnectorManagement',
        'ColorPaletteManagement',
        'DashboardDesign',
        'DataStoreManagement',
        'GlobalFunctionManagement',
        'WidgetLibraryManagement',
        'ApplicationManagement',
        'DashboardDesign',
        'LicenseManagement',
        'SystemConfiguration',
        'NodeManagement',
        'DataEngineer',
        'MaxTries',
        'Debugger',
        'Captcha',
        'Data Flow',
        'SelfService'
    ]




    bussionModulePermission(keyword) {
        var licencePermissions = localStorage.getItem('licence')
        if (licencePermissions) {
            // licencePermissions = JSON.parse(localStorage.getItem('licence'));
            return licencePermissions.includes(keyword)
        }
        else return false
    }
}