<app-modal-title [title]="title"></app-modal-title>
<mat-dialog-content class="mat-typography">

    <app-title title="{{ 'RoleFormPage.About' | translate }}"></app-title>
    <form [formGroup]="pageForm">
        <mat-form-field>
            <mat-label>{{ 'RoleFormPage.Name' | translate }}</mat-label>
            <input matInput [(ngModel)]="role.roleName" formControlName="roleName" required>
        </mat-form-field>
        <br>

        <mat-form-field>
            <mat-label>{{ 'RoleFormPage.Description' | translate }}</mat-label>
            <textarea matInput [(ngModel)]="role.description" [ngModelOptions]="{standalone: true}"></textarea>
        </mat-form-field>
        <br>
    </form>

    <app-title title="{{ 'RoleFormPage.PermissionOfRole' | translate }}"></app-title>
    <mat-form-field>
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilterPermission($event.target.value)" placeholder="Search">
    </mat-form-field>
    <mat-selection-list (selectionChange)="changeselectedPermissionslist($event)"  #permissionList [(ngModel)]="role.permisions">
        <mat-list-option [selected]="p.selected"  *ngFor="let p of paginatedPermission" [value]="p.item">
            {{p.item}}
        </mat-list-option>
    </mat-selection-list>
    <div class="pagination">
        <button class="pagination__button" [disabled]="currentPage === 1" (click)="previousPermission()">«</button>
        <span class="pagination__page-number">{{ currentPage }}</span>
        <button class="pagination__button" [disabled]="currentPage === totalPages" (click)="nextPermission()">»</button>
    </div>
    <br>

    <app-title title="{{ 'RoleFormPage.MembersOfRole' | translate }}"></app-title>
    <mat-form-field>
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilterMembers($event.target.value)" placeholder="Search">
    </mat-form-field>
    <mat-selection-list (selectionChange)="changeselectedlist($event)" [(ngModel)]="role.users" [compareWith]="compareFunctionForUser">
        <mat-list-option [selected]="user.selected" *ngFor="let user of paginatedMembers" [value]="user.userId">
            {{user.firstName}} {{user.lastName}}
        </mat-list-option>
    </mat-selection-list>
    <div class="pagination">
        <button class="pagination__button" [disabled]="currentPage1 === 1" (click)="previousMembers()">«</button>
        <span class="pagination__page-number">{{ currentPage1 }}</span>
        <button class="pagination__button" [disabled]="currentPage1 === totalPages1" (click)="nextMembers()">»</button>
    </div>
    <br>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="!isCreateForm" (click)="upsertRole()">{{
        'Common.Update' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="isCreateForm" (click)="upsertRole()">{{
        'Common.Create' | translate }}</button>
</mat-dialog-actions>