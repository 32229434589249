import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BussionMainService } from 'src/app/services/bussion-main/bussion-main.service';
import { CoreService } from 'src/app/services/core/core.service';
import { CodeEditorComponent } from '../code-editor/code-editor.component';

@Component({
  selector: 'app-bussion-main',
  templateUrl: './bussion-main.component.html',
  styleUrls: ['./bussion-main.component.scss']
})
export class BussionMainComponent implements OnInit {

  constructor(
    private bussionMainService: BussionMainService,
    private coreService: CoreService,
    private dialog: MatDialog
  ) { }

  editorOptions = { theme: 'vs-dark', language: 'html' };
  code: string = 'function x() {\nconsole.log("Hello world!");\n}';
  fileType: string = 'html'

  ngOnInit(): void {
    this.getBussionMain(this.fileType)

  }

  getBussionMain(fileType: string) {
    this.bussionMainService.getBussionMain(fileType).subscribe((res: any) => {
      if (res)
        this.code = res
    })
  }


  setBussionMain(setCode) {
    this.bussionMainService.setBussionMain(this.code, this.fileType).subscribe((res: any) => {
      if (res)
        this.coreService.showSuccessMessage("Kod güncellendi")
      else {
        this.coreService.showErrorMessage("Hata oluştu.")
      }

    })
  }


  openBiggerEditor() {
    const dialogRef = this.dialog.open(CodeEditorComponent, {
      height: '100%',
      width: '100%',
      data: {
        code: this.code,
        widget: {},
        debug: () => { },
        editorOptions: { theme: 'vs-dark', language: 'html' }
      },
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.code = result;
      }
    });
  }

  changeEditor(fileType: string) {
    this.fileType = fileType
    this.editorOptions.language = fileType
    this.getBussionMain(fileType)
  }
}
