import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalFunctionService } from 'src/app/services/global-function/global-function.service';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { VisualService } from 'src/app/services/visual/visual.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { VisualComp } from 'src/app/models/responses/visualComponentResponse';
import { ActionDialogComponent } from 'src/app/components/action-dialog/action-dialog/action-dialog.component';
import { FunctionFormComponent } from './function-form/function-form.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-global-function',
  templateUrl: './global-function.component.html',
  styleUrls: ['./global-function.component.scss']
})
export class GlobalFunctionComponent implements OnInit {

  baseUrl: string = environment.imageUrl;
  displayedColumns: string[] = ['icon', 'name', 'visualComponentId','description', 'action'];
  displayedColumnsName: string[] = [this.translate.instant("Common.Icon"), this.translate.instant("GlobalFunctionPage.Name"), this.translate.instant("GlobalFunctionPage.GlobalFunctionId"), this.translate.instant("GlobalFunctionPage.Description") , this.translate.instant("GlobalFunctionPage.Action")];
  functionList: VisualComp[] = [];

  constructor(
    private dialog: MatDialog,
    private globalFunctionService: GlobalFunctionService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getGlobalFunctions()
  }

  getGlobalFunctions() {
    this.spinner.show();
    this.globalFunctionService.getGlobalFunctions().subscribe(result => {
      this.functionList = result;
      this.spinner.hide();
    });
  }

  deleteVisualComponent(globalFunction: VisualComp) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.globalFunctionService.deleteGlobalFunction(globalFunction).subscribe(result => {
          this.getGlobalFunctions();
        });
      }
    });
  }

  openVisualComponent(event) {
    let data = {
      type: event.type as string,
      visual: event.data as VisualComp
    }
    this.modalService.openModal(data, FunctionFormComponent, 'auto', '1000px').subscribe(result => {
      if (result === true) {
        this.getGlobalFunctions();
      }
    });
  }

}
