<app-custom-modal [title]="'Connector Status'" [subtitle]="''" img="/assets/images/list.png">
    <div class="form-body" body>

        <div style="overflow: auto;padding: 20px;padding-top: 0px !important;">
            <div class="logs" *ngFor="let log of logs">
                <p>{{log}}</p>
            </div>
        </div>

        <div *ngIf="data?.visibleButton"
            style="display: flex;justify-content: end;margin-top: 10px; margin-right: 20px;gap: 5px;">
            <button mat-stroked-button class="custom-primary-stroked-button" (click)="stopTask()">{{ 'Common.Stop' |
                translate }}</button>
            <button mat-raised-button class="custom-primary-button" mat-dialog-close>{{ 'Common.Done' | translate
                }}</button>
        </div>

    </div>

</app-custom-modal>
<!-- 
<app-modal-title title="Connector Status"></app-modal-title>
<mat-dialog-content class="mat-typography">

    <div class="logs" *ngFor="let log of logs">
        <p>{{log}}</p>
    </div>

</mat-dialog-content> -->